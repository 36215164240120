// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// utils
import color from "styles/color";
import styled from "styled-components";
import shopItemUtils from "utils/shopItemUtils";

const Wrapper = styled.ul`
  all: unset;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  li {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    height: 40px;
    color: ${color.grey.middle};

    &:not(:first-child) {
      margin-left: 10px;
    }

    div {
      font-size: 20px;

      span.value {
        color: ${color.grey.strong} !important;
      }
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const SpaceshipItemsWidget = () => {
  const spaceshipCounter = useSelector(s.spaceshipItemsCounter());

  function hasItem(type: SpaceshipItemEnum): boolean {
    return getItemQuantity(type) > 0;
  }

  function getItemQuantity(type: SpaceshipItemEnum): number {
    return countItems(type);
  }

  function countItems(type: SpaceshipItemEnum): number {
    return spaceshipCounter[type]?.quantity || 0;
  }

  function getWidgetIcon(type: SpaceshipItemEnum) {
    const Entity = shopItemUtils.getEntity(type);
    return new Entity();
  }

  return (
    <Wrapper>
      {Object.keys(spaceshipCounter).length > 0 ? (
        Object.keys(spaceshipCounter).map((key, i) => {
          const item = spaceshipCounter[key];
          const { type } = item;

          if (!hasItem(type)) return null;

          return (
            <li key={`${key}_${i}`}>
              <div>
                <span className="min_separator">[</span>
                <span className="value">{getItemQuantity(type)}</span>
                <span className="min_separator">]</span>
              </div>

              <div className="image">
                {getWidgetIcon(type).getIcon({
                  width: "20",
                  height: "20",
                })}
              </div>
            </li>
          );
        })
      ) : (
        <li>
          <p>[...]</p>
        </li>
      )}
    </Wrapper>
  );
};

export default SpaceshipItemsWidget;
