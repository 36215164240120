// components
import {
  defaultColor,
  defaultWidth,
  defaultHeight,
  AvatarInvadersProps,
} from "handlers/AvatarInvaderHandler";

// components
import LinearGradientEffectSvg from "svg/LinearGradientEffectSvg";

// utils
import { v4 as uuidv4 } from "uuid";

const AvatarInvaderFive = ({
  gradient = false,
  width = defaultWidth,
  height = defaultHeight,
}: AvatarInvadersProps) => {
  const id = uuidv4();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 575.279 575.279"
    >
      {gradient && (
        <defs>
          <LinearGradientEffectSvg id={id} />
        </defs>
      )}

      <g>
        <path
          d="M520.2 189.72H459v-48.96h-55.08V85.68h-55.08v55.08H232.56V85.68h-55.08v55.08H122.4v48.96H61.2v61.2H0v171.36h61.2V312.12h61.2v55.08h55.08v55.08H122.4v67.32H232.559V367.199h116.28V489.6H459v-67.32h-55.08V367.2H459v-55.08h61.2v110.16h55.079V250.92H520.2v-61.2zm-281.52 91.8h-61.2v-61.2h61.2v61.2zm171.36 0h-61.2v-61.2h61.2v61.2z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default AvatarInvaderFive;
