// interfaces
import { TipProps } from "interfaces/tip";

function find(tips: TipProps[], code: string): TipProps | undefined {
  return tips.find((tip) => match(tip, removeWhiteSpace(code)));
}

function remove(tips: TipProps[], tipIdToBeRemoved: string): TipProps[] {
  return tips.filter((tip) => tip.id !== tipIdToBeRemoved);
}

// private

function match(tip: TipProps, code: string): boolean {
  if (!tip.match) return false;

  const len = code.length;
  const match = removeWhiteSpace(tip.match);
  const sub = code.substring(len - match.length, len);

  return match === sub;
}

function removeWhiteSpace(code: string): string {
  return code.replace(/\s/g, "");
}

const tipUtils = {
  find,
  remove,
};

export default tipUtils;
