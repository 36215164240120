// interfaces
import { FlyingObjectPositionProps } from "interfaces/spaceInvaders/flyingObject";
import { SpaceshipEntityProps } from "interfaces/spaceInvaders/spaceship";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";
import AbstractFlyingObjectEntity from "entities/spaceInvaders/AbstractFlyingObjectEntity";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
  FlyingObjectDamageType,
} from "enums/spaceInvaders/flyingObjectEnum";

// utils
import color from "styles/color";

export default class SpaceshipEntity
  extends AbstractFlyingObjectEntity
  implements SpaceshipEntityProps
{
  life: number;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.hp = PlayerHpEntity.hpNotSetValue;
    this.type = FlyingObjectType.SpaceShip;
    this.life = PlayerHpEntity.lifeNotSetValue;
    this.subtype = FlyingObjectSubType.SpaceShip;
    this.damage = FlyingObjectDamageType.high;
    this.explosionColor = color.white;
  }

  toJson(): SpaceshipEntityProps {
    return {
      type: this.type,
      subtype: this.subtype,
      hp: this.hp,
      damage: this.damage,
      position: this.position,
      explosionColor: this.explosionColor,
      life: this.life,
    };
  }

  hit(damage: number): void {
    this.hp -= damage;
    this.decLife(this.hp);
  }

  setHp(hp: number): void {
    this.hp = hp;
  }

  setLife(life: number): void {
    this.life = life;
  }

  private decLife(hp: number) {
    if (hp > 0) return;
    this.life -= 1;
  }
}
