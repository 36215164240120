// react
import { useRef, useEffect, useState } from "react";

// components
import AvatarJarvis from "components/avatars/AvatarJarvis";
import AvatarZarvox from "components/avatars/AvatarZarvox";
import AvatarRavians from "components/avatars/AvatarRavians";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import React from "react";
import c from "styles/color";
import styled from "styled-components";

const Wrapper = styled(ButtonRippleEffect)<{ top: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 6000;
  width: 50px;
  height: 45px;
  top: ${({ top }) => top};
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 3px;
`;

interface AvatarButtonProps {
  onClick(): void;
  customClassName: string;
  avatar: React.ReactNode;
  onAnimationEnd(animateName: string): void;
  top?: string;
  color?: string;
}

const AvatarButton = ({
  avatar,
  onClick,
  top = "6px",
  customClassName,
  onAnimationEnd,
  color = c.yellow,
}: AvatarButtonProps) => {
  return (
    <Wrapper
      top={top}
      color={color}
      onClick={onClick}
      className={customClassName}
      onAnimationEnd={onAnimationEnd}
    >
      {avatar}
    </Wrapper>
  );
};

export enum AvatarButtonType {
  Jarvis = 1,
  Zarvox,
  Ravians,
}

interface AvatarButtonsHandlerProps {
  show: boolean;
  onClick(): void;
  type: AvatarButtonType;
}

const AvatarButtonsHandler = ({
  show,
  type,
  onClick,
}: AvatarButtonsHandlerProps) => {
  const hasAvatarAlreadyShow = useRef(false);
  const [showAvatar, setShowAvatar] = useState(false);

  useEffect(handleFirstTimeShowAvatar, [show]);

  function handleFirstTimeShowAvatar() {
    if (!show) return;
    if (hasAvatarAlreadyShow.current) return;

    hasAvatarAlreadyShow.current = true;
    setTimeout(() => setShowAvatar(show), 250);
  }

  function onAnimationEnd(animateName: string) {
    if (animateName !== "backOutLeft") return;
    hasAvatarAlreadyShow.current = false;
  }

  return (
    <>
      {showAvatar && type === AvatarButtonType.Jarvis && (
        <AvatarButton
          onClick={onClick}
          onAnimationEnd={onAnimationEnd}
          customClassName={`animate__animated ${
            show
              ? "animate__backInUp"
              : hasAvatarAlreadyShow.current
              ? "animate__backOutLeft"
              : "d_none"
          }`}
          avatar={<AvatarJarvis customClassName="animate_y_infinite" />}
        />
      )}

      {showAvatar && type === AvatarButtonType.Zarvox && (
        <AvatarButton
          top="0"
          color={c.green}
          onClick={onClick}
          onAnimationEnd={onAnimationEnd}
          customClassName={`animate__animated ${
            show
              ? "animate__backInUp"
              : hasAvatarAlreadyShow.current
              ? "animate__backOutLeft"
              : "d_none"
          }`}
          avatar={<AvatarZarvox customClassName="animate_y_infinite" />}
        />
      )}

      {showAvatar && type === AvatarButtonType.Ravians && (
        <AvatarButton
          top="15px"
          color={c.green}
          onClick={onClick}
          onAnimationEnd={onAnimationEnd}
          customClassName={`animate__animated ${
            show
              ? "animate__backInUp"
              : hasAvatarAlreadyShow.current
              ? "animate__backOutLeft"
              : "d_none"
          }`}
          avatar={<AvatarRavians customClassName="animate_y_infinite" />}
        />
      )}
    </>
  );
};

export default AvatarButtonsHandler;
