import React, { useState } from "react";

// components
import Board from "components/Board";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// svgs
import SpaceshipSvg from "svg/SpaceshipSvg";

// enums
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;

  .spaceships {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .row {
      display: flex;
      padding: 10px;

      .spaceship_button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin: 5px;
        padding: 10px;
        border: 2px solid ${color.dark.bg};

        &.spaceship_button_red.selected {
          border: 2px dashed ${color.red};
        }

        &.spaceship_button_green.selected {
          border: 2px dashed ${color.green};
        }

        &.spaceship_button_blue.selected {
          border: 2px dashed ${color.blue};
        }

        &.spaceship_button_purple.selected {
          border: 2px dashed ${color.purple};
        }

        &.spaceship_button_orange.selected {
          border: 2px dashed ${color.orange};
        }

        &.spaceship_button_yellow.selected {
          border: 2px dashed ${color.yellow};
        }

        &:hover {
          &.spaceship_button_red {
            border: 2px dashed ${color.red};
          }

          &.spaceship_button_green {
            border: 2px dashed ${color.green};
          }

          &.spaceship_button_blue {
            border: 2px dashed ${color.blue};
          }

          &.spaceship_button_purple {
            border: 2px dashed ${color.purple};
          }

          &.spaceship_button_orange {
            border: 2px dashed ${color.orange};
          }

          &.spaceship_button_yellow {
            border: 2px dashed ${color.yellow};
          }
        }
      }
    }
  }
`;

const Spaceship = ({ color }: { color: string }) => {
  return <SpaceshipSvg color={color} width="50" height="50" />;
};

interface SelectSpaceshipProps {
  onSelect(color: SpaceshipColorType): void;
  text?: React.ReactNode;
}

const SelectSpaceship = ({
  onSelect,
  text = (
    <p>
      Selecione <span className="nerdfy_yellow">agora</span> uma{" "}
      <span className="nerdfy_pink">espaçonave</span>.
    </p>
  ),
}: SelectSpaceshipProps) => {
  const [colorsTable, setColorsTable] = useState<{ [key: string]: boolean }>(
    {}
  );

  function select(color: SpaceshipColorType) {
    setColorsTable({ [color]: true });
    setTimeout(() => onSelect(color));
  }

  return (
    <Wrapper>
      <div className="body_header">
        <Board>{text}</Board>
      </div>

      <div className="spaceships">
        <div className="row">
          <ButtonRippleEffect
            color={color.red}
            onClick={select.bind(null, SpaceshipColorType.Red)}
            className={`spaceship_button spaceship_button_red ${
              colorsTable[SpaceshipColorType.Red] && "selected"
            }`}
          >
            <Spaceship color={color.red} />
          </ButtonRippleEffect>

          <ButtonRippleEffect
            color={color.green}
            onClick={select.bind(null, SpaceshipColorType.Green)}
            className={`spaceship_button spaceship_button_green ${
              colorsTable[SpaceshipColorType.Green] && "selected"
            }`}
          >
            <Spaceship color={color.green} />
          </ButtonRippleEffect>
        </div>

        <div className="row">
          <ButtonRippleEffect
            color={color.purple}
            onClick={select.bind(null, SpaceshipColorType.Purple)}
            className={`spaceship_button spaceship_button_purple ${
              colorsTable[SpaceshipColorType.Purple] && "selected"
            }`}
          >
            <Spaceship color={color.purple} />
          </ButtonRippleEffect>

          <ButtonRippleEffect
            color={color.blue}
            onClick={select.bind(null, SpaceshipColorType.Blue)}
            className={`spaceship_button spaceship_button_blue ${
              colorsTable[SpaceshipColorType.Blue] && "selected"
            }`}
          >
            <Spaceship color={color.blue} />
          </ButtonRippleEffect>
        </div>

        <div className="row">
          <ButtonRippleEffect
            color={color.orange}
            onClick={select.bind(null, SpaceshipColorType.Orange)}
            className={`spaceship_button spaceship_button_orange ${
              colorsTable[SpaceshipColorType.Orange] && "selected"
            }`}
          >
            <Spaceship color="orange" />
          </ButtonRippleEffect>

          <ButtonRippleEffect
            color={color.yellow}
            onClick={select.bind(null, SpaceshipColorType.Yellow)}
            className={`spaceship_button spaceship_button_yellow ${
              colorsTable[SpaceshipColorType.Yellow] && "selected"
            }`}
          >
            <Spaceship color="yellow" />
          </ButtonRippleEffect>
        </div>
      </div>
    </Wrapper>
  );
};

export default SelectSpaceship;
