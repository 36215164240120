// entities
import { InvaderThreeEntity } from "entities/spaceInvaders/InvaderEntity";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const SpaceInvaderThreeSvg = ({
  width = "18",
  height = "18",
  color = InvaderThreeEntity.color,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 587.52 587.52"
    >
      <g>
        <path
          d="M483.896 444.868v.092l.122-.092h-.122zM484.013 444.875a3.276 3.276 0 0 0-.116.085h.116v-.085zM103.501 444.868l.122.092v-.092h-.122zM483.479 91.8h-55.08v48.96h55.08V91.8z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M532.439 244.799h-48.96v-55.08h-55.08v-48.96H373.32v48.96H214.199v-48.96h-55.08v48.96h-55.08v55.08h-48.96v-104.04H-.001v201.96h48.96v55.08h55.08v47.393c-13.941.196-27.155.178-41.102.367-2.956.037-7.858.007-7.858.007v50.153h48.439c0-17.778.686-32.896.729-48.96h54.872v-48.96h269.28v48.96h54.879c.036 16.064.728 31.182.728 48.96h48.434v-50.153s-4.901.03-7.857-.007c-13.947-.184-27.161-.165-41.103-.367v-47.393h55.08v-55.08h48.96v-201.96h-55.08l-.001 104.04zM214.2 293.76v30.6h-55.08V275.4h55.08v18.36zm214.199 0v30.6H373.32V275.4h55.079v18.36z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M104.04 91.8v48.96h55.08V91.8h-55.08zM103.507 444.967h.116s-.122-.092-.116-.086v.086z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default SpaceInvaderThreeSvg;
