// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesDataProps,
  SpaceshipResourcesSourceDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";
import { ChallengeCommonProps } from "interfaces/challenge";
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// components
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// entities
import {
  AsteroidWaterEntity,
  AsteroidMineralGoldEntity,
  AsteroidMineralSandEntity,
  AsteroidStrongForceEntity,
  AsteroidMineralSilverEntity,
  AsteroidMineralCopperEntity,
  AsteroidMineralEstelinEntity,
  AsteroidMineralBauxiteEntity,
} from "entities/spaceInvaders/AsteroidEntity";
import AbstractFlyingObjectEntity from "entities/spaceInvaders/AbstractFlyingObjectEntity";

// handlers
import SpaceshipItemsRemoveHandler from "handlers/spaceshipResources/SpaceshipItemsRemoveHandler";
import SpaceshipResourceShiftHandler from "handlers/spaceshipResources/SpaceshipResourceShiftHandler";
import RefineMachineLifeCyclesHandler from "handlers/spaceshipResources/RefineMachineLifeCyclesHandler";
import SpaceShipResourcesTransformHandler from "handlers/spaceshipResources/SpaceShipResourcesTransformHandler";
import SpaceshipResourcesExpirationHandler from "handlers/spaceshipResources/SpaceShipResourcesExpirationHandler";

// utils
import isEmpty from "lodash/isEmpty";
import numberUtils from "utils/numberUtils";
import { Timestamp } from "firebase/firestore";
import { AllToOptional } from "utils/transformTypeUtils";

export interface AlertContentDataProps {
  turns: number;
  resource: string;
  quantity: number;
  turnsToTransform: number;
}

export interface AlertContentProps {
  type: AlertFactoryType;
  content: string;
}

export type SpaceshipResourceReducedProps = {
  [key: string]: SpaceshipResourcesSourceDataProps;
};

export type SpaceshipResourcesLoopOutputProps =
  AllToOptional<SpaceshipResourcesUpdateFirebaseProps>;

type SpaceshipResourcesHashOriginalDataProps = {
  [key: string]: SpaceshipResourcesSourceDataProps;
};

const ALERT_DELAY = 500;
const TWO_DAYS_TIMESTAMP = 172800000;

interface SpaceshipResourcesHandlerProps {
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

const SpaceshipResourcesHandler = ({
  wrapper,
  challenge,
}: SpaceshipResourcesHandlerProps) => {
  function recursiveAlerts(
    data: SpaceshipResourcesLoopOutputProps,
    getAlertContent: (content: AlertContentDataProps) => AlertContentProps,
    mapAlert?: (
      resource: keyof SpaceshipResourcesProps,
      data: SpaceshipResourceReducedProps
    ) => AlertContentDataProps[]
  ) {
    if (isEmpty(data)) return;

    const resourceKeys = Object.keys(data) as Array<
      keyof SpaceshipResourcesProps
    >;
    const resourceKey = resourceKeys[0];
    const t = data[resourceKey] as SpaceshipResourceReducedProps;
    const quantity = t ? Object.keys(t).length : 0;

    if (!resourceKey) return;
    if (!quantity) return;

    const mapped = mapAlert
      ? mapAlert(resourceKey, t)
      : mapAlertDefault(resourceKey, quantity);

    handleAlerts(mapped, getAlertContent, () => {
      setTimeout(() => {
        recursiveAlerts(
          removeResource(data, resourceKey),
          getAlertContent,
          mapAlert
        );
      });
    });
  }

  function handleAlerts(
    data: AlertContentDataProps[],
    getContent: (content: AlertContentDataProps) => AlertContentProps,
    finished: () => void
  ) {
    if (isEmpty(data)) return finished();

    const content = data.pop();
    if (!content) return finished();

    alert(getContent(content));

    setTimeout(() => {
      handleAlerts(data, getContent, finished);
    }, ALERT_DELAY);
  }

  function alert(alert: AlertContentProps) {
    if (!wrapper) return;

    const { clientWidth, clientHeight } = wrapper;

    alertAnimate({
      wrapper: wrapper as HTMLDivElement,
      factories: [
        AlertFactory({
          slower: true,
          type: alert.type,
          content: alert.content,
        }),
      ],
      bounds: {
        top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
      },
    });
  }

  function mapAlertDefault(
    resource: keyof SpaceshipResourcesProps,
    quantity: number
  ): AlertContentDataProps[] {
    return [
      {
        turns: 0, // turns is not used in this scenario
        quantity,
        resource: getResourceAlias(resource),
        turnsToTransform: getResourceTurnsToTransform(resource),
      },
    ];
  }

  function removeResource(
    data: SpaceshipResourcesLoopOutputProps,
    resourceToNotInclude: string
  ): SpaceshipResourcesLoopOutputProps {
    return Object.keys(data).reduce((acc, key) => {
      if (key === resourceToNotInclude) return acc;

      const k = key as keyof SpaceshipResourcesProps;
      acc[k] = { ...data[k] };

      return acc;
    }, {} as SpaceshipResourcesLoopOutputProps);
  }

  function loopResourceSource(
    resources: SpaceshipResourcesProps,
    reduce: (
      data: SpaceshipResourcesHashOriginalDataProps,
      turnsToTransform: number
    ) => SpaceshipResourceReducedProps
  ): SpaceshipResourcesLoopOutputProps {
    const keys = Object.keys(resources) as Array<keyof SpaceshipResourcesProps>;

    return keys.reduce((acc, key: keyof SpaceshipResourcesProps) => {
      const resource = resources[key] as SpaceshipResourcesDataProps;
      const reduced = reduce(resource.source, getResourceTurnsToTransform(key));

      if (isEmpty(reduced)) return acc;

      acc[key] = reduced;
      return acc;
    }, {} as SpaceshipResourcesLoopOutputProps);
  }

  function getEntity(
    key: keyof SpaceshipResourcesProps
  ): typeof AbstractFlyingObjectEntity {
    if (key === AsteroidWaterEntity.key) return AsteroidWaterEntity;
    if (key === AsteroidMineralGoldEntity.key) return AsteroidMineralGoldEntity;
    if (key === AsteroidMineralSandEntity.key) return AsteroidMineralSandEntity;
    if (key === AsteroidStrongForceEntity.key) return AsteroidStrongForceEntity;
    if (key === AsteroidMineralSilverEntity.key)
      return AsteroidMineralSilverEntity;
    if (key === AsteroidMineralCopperEntity.key)
      return AsteroidMineralCopperEntity;
    if (key === AsteroidMineralEstelinEntity.key)
      return AsteroidMineralEstelinEntity;
    if (key === AsteroidMineralBauxiteEntity.key)
      return AsteroidMineralBauxiteEntity;

    return AsteroidWaterEntity;
  }

  function getResourceAlias(key: keyof SpaceshipResourcesProps): string {
    return getEntity(key).alias;
  }

  function getResourceTurnsToTransform(
    key: keyof SpaceshipResourcesProps
  ): number {
    return getEntity(key).turnsToTransform;
  }

  function hasExpired(d: SpaceshipResourcesSourceDataProps): boolean {
    const milliseconds = d.timestamp * 1000;
    if (!milliseconds) return false;

    return (
      Timestamp.now() >= Timestamp.fromMillis(milliseconds + TWO_DAYS_TIMESTAMP)
    );
  }

  return (
    <>
      <SpaceshipResourceShiftHandler
        challenge={challenge}
        hasExpired={hasExpired}
        loop={loopResourceSource}
        recursiveAlerts={recursiveAlerts}
        getResourceAlias={getResourceAlias}
        getResourceTurnsToTransform={getResourceTurnsToTransform}
      />

      <SpaceShipResourcesTransformHandler
        challenge={challenge}
        hasExpired={hasExpired}
        loop={loopResourceSource}
        recursiveAlerts={recursiveAlerts}
      />

      <SpaceshipResourcesExpirationHandler
        challenge={challenge}
        hasExpired={hasExpired}
        loop={loopResourceSource}
        recursiveAlerts={recursiveAlerts}
      />

      <SpaceshipItemsRemoveHandler alert={alert} challenge={challenge} />

      <RefineMachineLifeCyclesHandler
        challenge={challenge}
        hasExpired={hasExpired}
        loop={loopResourceSource}
        recursiveAlerts={recursiveAlerts}
      />
    </>
  );
};

export default SpaceshipResourcesHandler;
