// react
import { useState, useEffect } from "react";

// interfaces
// import { ChallengeProps } from 'interfaces/challenge';

// components
import Code from "components/Code";
import FormHeader from "components/form/FormHeader";

// utils
import color from "styles/color";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  .body {
    flex: 1;
  }
`;

interface LabsPresentationProps {
  code: string;
  show: boolean;
  language: string;
  onClose(): void;
  animationEnd(): void;
}

const LabsPresentation = ({
  show,
  code,
  onClose,
  language,
  animationEnd,
}: LabsPresentationProps) => {
  const [animation, setAnimation] = useState("");

  useEffect(handleShow, [show]);

  function handleShow() {
    if (!show) return;
    setTimeout(() => setAnimation("animate__bounceInUp"));
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
    setTimeout(onClose);
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLElement>) {
    e.stopPropagation();
    if (e.animationName === "bounceOutLeft") animationEnd();
  }

  return (
    <>
      {!!animation && (
        <Content
          onAnimationEnd={onAnimationEnd}
          className={`animate__animated no-swipe ${animation}`}
        >
          <FormHeader close={close} />

          <div className="body">
            <Code
              code={code}
              tomorrowStyle
              language={language}
              style={{ margin: 0 }}
              showLineNumbers={false}
            />
          </div>
        </Content>
      )}
    </>
  );
};

export default LabsPresentation;
