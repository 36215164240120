// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
}

const LaserPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      x="0"
      y="0"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M210.82 150.59h30.12v150.59h-30.12zM301.18 271.06h30.11v30.12h-30.11v30.11h-60.24v-30.11h30.12V150.59h-30.12v-30.12h60.24v30.12h30.11v30.12h-30.11zM210.82 120.47v30.12H60.24V60.24h60.23v30.11H90.35v30.12h30.12V90.35h30.12v30.12zM90.35 240.94h60.24v30.12H90.35zM150.59 210.82h30.12v30.12h-30.12zM90.35 180.71h60.24v30.11H90.35zM60.24 210.82h30.11v30.12H60.24zM481.88 210.82H512v30.12h-30.12zM481.88 120.47H512v30.12h-30.12zM481.88 301.18h-30.12v30.11h-30.11v30.12h30.11v30.12h30.12v-30.12H512v-30.12h-30.12zM451.76 240.94h30.12v30.12h-30.12zM451.76 180.71h30.12v30.11h-30.12zM391.53 210.82h60.23v30.12h-60.23zM361.41 150.59h30.12v-30.12h30.12V90.35h-30.12V60.24h-30.12v30.11h-30.12v30.12h30.12zM331.29 421.65h30.12v30.11h-30.12zM331.29 331.29h30.12v30.12h-30.12z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M361.41 210.82v30.12h30.12v30.12h-60.24v-90.35h60.24v30.11zM301.18 391.53h30.11v30.12h-30.11zM180.71 391.53h-30.12v-60.24H90.35v90.36H60.24V301.18h150.58v30.11h-30.11z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M180.71 421.65v-30.12h30.11v60.23H90.35v-30.11zM30.12 271.06h30.12v30.12H30.12zM30.12 150.59h30.12v30.12H30.12zM0 180.71h30.12v90.35H0z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default LaserPixelIcon;
