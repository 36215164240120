// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";
import Icon, { faChevronLeft, faChevronRight } from "components/Icon";

// utils
import styled from "styled-components";

const Wrapper = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

interface ButtonDuolingoIconProps {
  onClick(): void;
  customClassName?: string;
  backward?: boolean;
  disabled?: boolean;
}

const ButtonDuolingoArrowIcon = ({
  onClick,
  backward,
  disabled,
  customClassName,
}: ButtonDuolingoIconProps) => {
  return (
    <Wrapper onClick={onClick} className={customClassName} disabled={disabled}>
      {backward ? (
        <Icon icon={faChevronLeft} />
      ) : (
        <Icon icon={faChevronRight} />
      )}
    </Wrapper>
  );
};

export default ButtonDuolingoArrowIcon;
