// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.footer`
  font-family: "Pixelify Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  position: relative;
  margin: 0;
  padding: 0;
  background: ${color.grey.dark};
  color: ${color.grey.stronger};

  p {
    all: unset;
    font-size: 1.2em;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <p>
        Feito com <span className="nerdfy_red">♥</span> pelos nerds.
      </p>
    </Wrapper>
  );
};

export default Footer;
