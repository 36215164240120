// redux
import { useEffect, useRef } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { walkieTalkieActions } from "redux/slices/walkieTalkieSlice";
import { collection, query, orderBy, where } from "firebase/firestore";

// redux
import { useDispatch } from "react-redux";

// interfaces
import { WalkieTalkieBroadcastFirebaseProps } from "interfaces/walkieTalkie";

// components
import { hasPendingWritesDocType } from "firebaseSync/FirebaseSync";

// parsers
import walkieTalkieParser from "parsers/walkieTalkieParser";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// firestore
import { Timestamp } from "firebase/firestore";

interface WalkieTalkieFirebaseSyncProps {
  languageId: number;
  userId: number | string;
  isCachedData: (docs: hasPendingWritesDocType) => boolean;
}

const WalkieTalkieFirebaseSync = ({
  userId,
  languageId,
  isCachedData,
}: WalkieTalkieFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const nowRef = useRef(Timestamp.now());
  const [snapshot] = useCollection(
    query(
      collection(
        FirebaseCoreService.getDatabase(),
        `broadcast/chats/${languageId}`
      ),
      where("createdAt", ">", nowRef.current),
      orderBy("createdAt", "desc")
    )
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    const docs = snapshot?.docs;
    if (!docs) return;

    const messages = docs.reduce((acc, doc) => {
      if (isCachedData(doc)) return acc;

      const value = doc.data() as WalkieTalkieBroadcastFirebaseProps;
      if (value.userId === userId) return acc;

      acc.push(walkieTalkieParser.map(doc.id, value));
      return acc;
    }, [] as any[]);

    dispatch(walkieTalkieActions.broadcast(messages));
  }

  return null;
};

export default WalkieTalkieFirebaseSync;
