// interfaces
import {
  JarvisChatUpdateProps,
  JarvisChatFirebaseProps,
} from "interfaces/jarvisChat";

// utils
import isEmpty from "lodash/isEmpty";

function list(data: JarvisChatFirebaseProps[]): JarvisChatUpdateProps[] {
  if (isEmpty(data)) return [];
  return data.map(map);
}

function map(data: JarvisChatFirebaseProps): JarvisChatUpdateProps {
  return {
    id: data.id,
    answerUp: !!data.up,
    answerRead: !!data.read,
    answerDown: !!data.down,
    questionClicked: !!data.questionClicked,
  };
}

const jarvisChatsParser = {
  list,
  map,
};

export default jarvisChatsParser;
