// interfaces
import { ShopItemFirebaseProps } from "interfaces/shopFirebase";

class ShopItemDataFirebaseServiceEntity {
  private readonly _id: number;

  constructor({ id }: { id: number }) {
    this._id = id;
  }

  get readValues(): ShopItemFirebaseProps {
    return {
      read: true,
      id: this._id,
    };
  }
}

export default ShopItemDataFirebaseServiceEntity;
