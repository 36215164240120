// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { PlayerEventsProps } from "interfaces/player";

// utils
import isEmpty from "lodash/isEmpty";

class PlayerEventsDataFirebaseService {
  private _resource = "players";
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async update(
    userId: number | string,
    data: PlayerEventsProps
  ): Promise<void> {
    if (!userId) return Promise.reject();
    if (isEmpty(data.events)) return Promise.reject();

    const path = `${this._resource}/${userId}`;
    return this._firebase.set(path, data);
  }
}

export default PlayerEventsDataFirebaseService;
