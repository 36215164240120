// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 100;
  height: 140px;
  display: none;

  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .content {
      all: unset;
      opacity: 0.5;
      color: ${color.grey.stronger};
    }
  }

  &.spaceship_dropzone {
    all: unset;
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    height: 84px;
    display: none;
  }

  &.border_dashed_grey {
    display: block;
    border: 2px dashed ${color.grey.middle};
  }

  &.border_dashed_white {
    display: block;
    border: 2px dashed ${color.white} !important;
  }

  &.border_dashed_blue {
    display: block;
    border: 2px dashed ${color.blue} !important;
  }

  &.border_dashed_yellow {
    display: block;
    border: 2px dashed ${color.yellow} !important;
  }

  &.border_dashed_purple {
    display: block;
    border: 2px dashed ${color.purple} !important;
  }

  &.border_dashed_brown {
    display: block;
    border: 2px dashed ${color.brown} !important;
  }

  &.border_dashed_green {
    display: block;
    border: 2px dashed ${color.green} !important;
  }

  &.border_dashed_red {
    display: block;
    border: 2px dashed ${color.red} !important;
  }

  &.border_dashed_orange {
    display: block;
    border: 2px dashed ${color.orange} !important;
  }

  &.border_dashed_acai {
    display: block;
    border: 2px dashed ${color.acai} !important;
  }

  &.border_dashed_wine {
    display: block;
    border: 2px dashed ${color.wine} !important;
  }

  &.border_dashed_navy {
    display: block;
    border: 2px dashed ${color.navy} !important;
  }

  &.border_dashed_pink {
    display: block;
    border: 2px dashed ${color.pink} !important;
  }
`;

export type dropZoneBorderColorType =
  | "yellow"
  | "blue"
  | "red"
  | "purple"
  | "green"
  | "brown"
  | "orange"
  | "grey"
  | "acai"
  | "pink"
  | "navy"
  | "wine"
  | "white";

export const DropZoneTag = () => {
  return <Wrapper className="tag_dropzone"></Wrapper>;
};

interface DropZoneAttentionWhoreProps {
  dropZoneClassName?: string;
}

interface DropZoneDragWhoreProps {
  content?: string;
  dropZoneClassName?: string;
}

export const DropZoneDragWhore = ({
  content,
  dropZoneClassName = "drag_whore_dropzone",
}: DropZoneDragWhoreProps = {}) => {
  return (
    <Wrapper className={dropZoneClassName}>
      <div className="body">
        {!!content && <p className="content">{content}</p>}
      </div>
    </Wrapper>
  );
};

export const DropZoneAttentionWhore = ({
  dropZoneClassName = "attention_whore_dropzone",
}: DropZoneAttentionWhoreProps) => {
  return <Wrapper className={dropZoneClassName}></Wrapper>;
};

export const DropZoneSpaceship = () => {
  return <Wrapper className="spaceship_dropzone"></Wrapper>;
};
