// react
import { useState, useEffect } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// handlers
import AmateurRadioWhoreHandler from "handlers/amateurRadio/AmateurRadioWhoreHandler";
import AmateurRadioBroadcastMessagesHandler from "handlers/amateurRadio/AmateurRadioBroadcastMessagesHandler";

interface WalkieTalkieHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

const AmateurRadioHandler = ({
  paused,
  wrapper,
  challenge,
}: WalkieTalkieHandlerProps) => {
  const spaceshipItemsCounter = useSelector(s.spaceshipItemsCounter());
  const [hasAmateurRadioItem, setHasAmateurRadioItem] = useState(false);

  useEffect(handleHasAmateurRadioItem, [spaceshipItemsCounter]);

  function handleHasAmateurRadioItem() {
    const amateurRadio = spaceshipItemsCounter[SpaceshipItemEnum.AmateurRadio];
    setHasAmateurRadioItem(amateurRadio && amateurRadio.quantity > 0);
  }

  return (
    <>
      <AmateurRadioWhoreHandler
        paused={paused}
        wrapper={wrapper}
        challenge={challenge}
        hasAmateurRadioItem={hasAmateurRadioItem}
      />

      <AmateurRadioBroadcastMessagesHandler
        paused={paused}
        wrapper={wrapper}
        challenge={challenge}
        hasAmateurRadioItem={hasAmateurRadioItem}
      />
    </>
  );
};

export default AmateurRadioHandler;
