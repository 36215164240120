// firestore
import { DocumentReference } from "firebase/firestore";

// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { FeedbackClassRoomRatingsFirebaseProps } from "interfaces/feedback";

class GlobalClassRoomRatingFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  add(
    classRoomId: number,
    data: FeedbackClassRoomRatingsFirebaseProps
  ): Promise<DocumentReference<any> | void> {
    const path = `classRooms/${classRoomId}/ratings`;
    return this._firebase.add(path, data);
  }
}

export default GlobalClassRoomRatingFirebaseService;
