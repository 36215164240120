// react
import React, { Suspense } from "react";

interface ImageProps {
  src: string;
  item?: boolean;
  alt?: string;
  style?: { [key: string]: string };
}

const IonImage = ({ src, item, style, alt }: ImageProps) => {
  const imgSrc = src
    ? src
    : item
    ? "assets/imgs/default_img.png"
    : "assets/imgs/default_user.png";

  return (
    <img
      style={style}
      src={imgSrc}
      alt={alt}
      onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = item
          ? "assets/imgs/default_img.png"
          : "assets/imgs/default_user.png";
      }}
    />
  );
};

const Image = function ({ src, item, style, alt }: ImageProps) {
  return (
    <Suspense fallback={<div>...</div>}>
      <IonImage src={src} item={item} style={style} alt={alt} />
    </Suspense>
  );
};

function areEqual(prev: ImageProps, next: ImageProps): boolean {
  if (!prev.src) return true;
  if (!next.src) return true;

  return prev.src === next.src;
}

export default React.memo(Image, areEqual);
