// react
import { useState } from "react";

// interfaces
import { UserProps } from "interfaces/user";
import { PlayerSpaceshipDataProps } from "interfaces/player";
import { DataCacheProps } from "components/presentations/Customization";

// components
import Loading from "components/Loading";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import NerdXFooter from "components/presentations/spaceship/NerdXFooter";
import SpaceshipOrder, {
  OrderProps,
} from "components/presentations/spaceship/SpaceshipOrder";

// services
import UserService from "services/UserService";
import PlayerSpaceshipDataFirebaseService from "services/firebase/player/PlayerSpaceshipDataFirebaseService";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface SpaceshipOrderWrapperProps {
  close(): void;
  goBack(): void;
  user: UserProps;
  cache: DataCacheProps;
  getSpaceshipColor(): string;
  getSpaceshipClassNameColor(): string;
  finished(data: DataCacheProps): void;
  demo?: boolean;
}

const SpaceshipOrderWrapper = ({
  user,
  demo,
  cache,
  close,
  goBack,
  finished,
  getSpaceshipColor,
  getSpaceshipClassNameColor,
}: SpaceshipOrderWrapperProps) => {
  const [error, setError] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  async function save() {
    if (isSaved) return;
    if (!user.id) return;

    try {
      setLoading(true);
      setError("");

      await Promise.all(getPromises());

      setIsSaved(true);
      finished(cache);
      close();
    } catch (_err: unknown) {
      setError("Ops, ocorreu um erro...");
    } finally {
      setLoading(false);
    }
  }

  function getPromises(): Promise<unknown>[] {
    const userResource = new UserService();
    const playerSpaceshipResource = new PlayerSpaceshipDataFirebaseService();

    const promises: Promise<unknown>[] = [
      playerSpaceshipResource.update(user.id, mapSpaceshipFeatures(cache)),
    ];

    if (!demo) promises.push(userResource.updateGenre({ genre: cache.genre }));

    return promises;
  }

  function mapSpaceshipFeatures(
    cache: DataCacheProps
  ): PlayerSpaceshipDataProps {
    return {
      bullets: cache.bullets,
      reload: cache.reload,
      radar: cache.radar,
      color: cache.spaceshipColor,
      engraving: cache.spaceshipEngraving,
    };
  }

  function getOrder(): OrderProps {
    return {
      radar: cache.radar,
      reload: cache.reload,
      bullets: cache.bullets,
      engraving: cache.spaceshipEngraving,
    };
  }

  return (
    <Wrapper>
      <Loading loading={loading} />

      <FormHeader
        save={save}
        back={goBack}
        disabled={isSaved}
        disabledBack={isSaved}
      />

      <SpaceshipOrder
        order={getOrder()}
        getSpaceshipColor={getSpaceshipColor}
        getSpaceshipClassNameColor={getSpaceshipClassNameColor}
        description={
          <p>
            Olá{" "}
            <span className={cache.genre === 0 ? "nerdfy_blue" : "nerdfy_pink"}>
              {cache.codename}
            </span>
            , confira agora o <span className="nerdfy_gold">resumo</span> do seu{" "}
            <span className={getSpaceshipClassNameColor()}>pedido</span>
            ...
          </p>
        }
      />

      {error && <ErrorFeedback err={error} />}

      <NerdXFooter />
    </Wrapper>
  );
};

export default SpaceshipOrderWrapper;
