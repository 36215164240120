// interfaces
import {
  InvaderProps,
  InvadersInfoProps,
} from "interfaces/spaceInvaders/invader";
import { FlyingObjectRefProps } from "interfaces/spaceInvaders/flyingObject";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// handlers
import Invaders from "handlers/invaders/Invaders";
import InvadersFriendly from "handlers/invaders/InvadersFriendly";

// enums
import { InvaderMonsterFactory } from "factories/spaceInvaders/InvaderFactory";

// utils
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

export const INVADER_SPEED = 2;
export const INVADER_MARGIN_X = 10;
export const INVADER_HIDE_TOP = -100;
export const INVADER_MOVEMENT_DELAY_SPEED = 12;
export const INVADER_WIDTH = SPACE_INVADERS.invader.width;
export const INVADER_HALF_WIDTH = INVADER_WIDTH / 2;
export const INVADER_HEIGHT = SPACE_INVADERS.invader.height;
export const INVADER_HALF_HEIGHT = INVADER_HEIGHT / 2;
export const RANDOM_NUMBER_TO_INVADER_APPEAR = 4;
export const INVADER_REMAINING_HIDE_BOTTOM = 150;

const CONTAINER_START_LEFT = 0;
const CONTAINER_START_TOP = 80;

export interface ContainerRefProps {
  list: HTMLDivElement[];
  hash: { [key: string]: boolean };
}

export interface InvadersCommonProps {
  paused: boolean;
  freezedRef: { current: boolean };
  getTopPosition(el: HTMLDivElement): number;
  setFirstPosition(container: HTMLDivElement): void;
  clearInvaders(
    containerId: string,
    invadersRef: { current: FlyingObjectRefProps[] }
  ): void;
  removeInvaders(
    containerId: string,
    invadersRef: { current: FlyingObjectRefProps[] }
  ): void;
  removeContainer(
    container: HTMLDivElement,
    containerRef: { current: ContainerRefProps }
  ): void;
  createEls(
    container: HTMLDivElement,
    invadersInfo: InvadersInfoProps,
    top: number,
    left: number,
    add: (object: InvaderProps, el: HTMLDivElement) => void,
    pausedRef: { current: boolean | null }
  ): void;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface InvadersProps {
  paused: boolean;
  freezedRef: { current: boolean };
  invadersRef: { current: FlyingObjectRefProps[] };
  invaderBulletsRef: { current: FlyingObjectRefProps[] };
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
}

const InvadersHandler = ({
  paused,
  freezedRef,
  wrapperRef,
  invadersRef,
  invaderBulletsRef,
}: InvadersProps) => {
  function createEls(
    container: HTMLDivElement,
    invadersInfo: InvadersInfoProps,
    top: number,
    left: number,
    add: (object: InvaderProps, el: HTMLDivElement) => void,
    pausedRef: { current: boolean }
  ) {
    const { length, type, subtype } = invadersInfo;
    const Invader = spaceInvaderUtils.getEntity(subtype);
    const invader = new Invader({ x: left, y: top }).toJson();

    for (let i = 0; i < length; i++) {
      const invaderEl = createEl(top, left, invadersInfo, container, pausedRef);
      const position = { x: left, y: top };

      left += INVADER_WIDTH + INVADER_MARGIN_X;

      add({ ...invader, type, subtype, position }, invaderEl);
      container.appendChild(invaderEl);
    }
  }

  function createEl(
    top: number,
    left: number,
    invadersInfo: InvadersInfoProps,
    container: HTMLDivElement,
    pausedRef: { current: boolean }
  ): HTMLDivElement {
    const invaderEl = InvaderMonsterFactory(container, invadersInfo, pausedRef);

    invaderEl.style.display = "block";

    if (top) invaderEl.style.top = `${top}px`;
    if (left) invaderEl.style.left = `${left}px`;

    return invaderEl;
  }

  function setFirstPosition(container: HTMLDivElement) {
    container.style.display = "flex";
    container.style.left = `${CONTAINER_START_LEFT}px`;
    container.style.top = `${CONTAINER_START_TOP}px`;
  }

  function removeContainer(
    container: HTMLDivElement,
    containerRef: { current: ContainerRefProps }
  ) {
    container.style.display = "none";
    container.classList.add("container_removed");
    container.remove();

    containerRef.current.hash[container.id] = false;
    containerRef.current.list = containerRef.current.list.filter(
      (c) => c.id !== container.id
    );
  }

  function removeInvaders(
    containerId: string,
    invadersRef: { current: FlyingObjectRefProps[] }
  ) {
    invadersRef.current = invadersRef.current.map(({ el, object }) => {
      if (hasId(el, containerId)) el.style.display = "none";
      return { el, object };
    });
  }

  function clearInvaders(
    containerId: string,
    invadersRef: { current: FlyingObjectRefProps[] }
  ) {
    invadersRef.current = invadersRef.current.filter(
      ({ el }) => !hasId(el, containerId)
    );
  }

  function hasId(el: HTMLDivElement, id: string): boolean {
    return el.id.includes(id);
  }

  function getTopPosition(el: HTMLDivElement): number {
    return Number(el.style.top.replace("px", ""));
  }

  function getLeftPosition(el: HTMLDivElement): number {
    return Number(el.style.left.replace("px", ""));
  }

  return (
    <>
      <Invaders
        hasId={hasId}
        paused={paused}
        freezedRef={freezedRef}
        createEls={createEls}
        wrapperRef={wrapperRef}
        invadersRef={invadersRef}
        clearInvaders={clearInvaders}
        getTopPosition={getTopPosition}
        removeInvaders={removeInvaders}
        getLeftPosition={getLeftPosition}
        removeContainer={removeContainer}
        setFirstPosition={setFirstPosition}
        invaderBulletsRef={invaderBulletsRef}
      />

      <InvadersFriendly
        paused={paused}
        freezedRef={freezedRef}
        createEls={createEls}
        wrapperRef={wrapperRef}
        clearInvaders={clearInvaders}
        getTopPosition={getTopPosition}
        removeInvaders={removeInvaders}
        removeContainer={removeContainer}
        setFirstPosition={setFirstPosition}
      />
    </>
  );
};

// function createSquareEls(quantity: number, container: HTMLDivElement) {
//   let top = 0;

//   for (let i = 0; i < quantity; i++) {
//     createEls(quantity, container, INVADER_MARGIN_X, top);
//     top += INVADER_HEIGHT + INVADER_MARGIN_Y;
//   }
// }

// function createBarEls(
//   quantity: number,
//   container: HTMLDivElement,
//   top = INVADER_MARGIN_Y
// ) {
//   for (let i = 0; i < quantity; i++) {
//     const invaderEl = createEl(top, 0);
//     top += INVADER_HEIGHT + INVADER_MARGIN_Y;

//     add(
//       {
//         type: FlyingObjectType.Invader,
//         subtype: FlyingObjectSubType.InvaderBug,
//       },
//       invaderEl
//     );

//     container.appendChild(invaderEl);
//   }
// }

export default InvadersHandler;
