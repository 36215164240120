// react
import { IonProgressBar } from "@ionic/react";

// utils
import styled from "styled-components";

const Progress = styled(IonProgressBar)`
  height: 6px;
`;

interface ProgressBarProps {
  value: number;
  color?: string;
}

const ProgressBar = ({ value, color = "primary" }: ProgressBarProps) => {
  return <Progress value={value} mode="ios" color={color} />;
};

export default ProgressBar;
