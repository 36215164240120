// react
import { useState, useEffect } from "react";

// components
import DragWhores from "components/dragWhores/DragWhores";
import ChallengeButton from "handlers/challengeButtons/ChallengeButton";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  SudoFlowProps,
  CurrentDragWhoreProps,
} from "handlers/challengeButtons/sudo/SudoHandler";
import { DragWhoreProps } from "interfaces/dragWhore";
import { DragWhoreSudoTemplateEl } from "handlers/challengeButtons/sudo/SudoInit";

// utils
import color from "styles/color";
import interact from "interactjs";
import animationUtils from "utils/animationUtils";
import dragWhoreUtils from "utils/dragWhoreUtils";

const SudoStarted = ({
  paused,
  challenge,
  getWhores,
  getContent,
}: SudoFlowProps) => {
  const { flowStarted, flowFinished } = challenge;
  const [animation, setAnimation] = useState("");
  const [whores, setWhores] = useState<DragWhoreProps[][]>([]);
  const [whore, setWhore] = useState<CurrentDragWhoreProps>(
    dragWhoreUtils.fake()
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [flowStarted]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(buttonZoomOut, [flowFinished]);

  function bootstrap() {
    if (!flowStarted) return;
    setTimeout(displayButton, 1000);
  }

  function buttonZoomOut() {
    if (!animation) return;
    if (!flowFinished) return;
    if (animation === "animate__zoomOut") return;

    setAnimation("animate__zoomOut");
  }

  function displayWhores() {
    setWhores([...whores, getWhores("sudo", onDragWhoreCall)]);
  }

  function onDragWhoreCall(whore: DragWhoreProps) {
    setWhore({ ...whore, show: true });
  }

  function hideBubble() {
    if (!whore) return;
    if (whore.last) displayButton();

    setWhore({
      ...whore,
      show: false,
    });
  }

  function displayButton() {
    setAnimation(animationUtils.getRandom());
  }

  function onClick() {
    if (animation === "animate__zoomOut") return;

    displayWhores();
    setTimeout(() => setAnimation("animate__zoomOut"));
  }

  return (
    <>
      {!!animation && (
        <ChallengeButton
          onClick={onClick}
          color={color.white}
          label={<span>sudo</span>}
          customClassName={`animate__animated no-swipe ${animation}`}
        />
      )}

      {whores.map((whores, i: number) => (
        <DragWhores
          key={i}
          whores={whores}
          paused={paused}
          clear={challenge.flowDone}
          dropZoneBorderColor="white"
          disabledDrop={!!whore.show}
          TemplateEl={DragWhoreSudoTemplateEl}
          draggableClassName="drag_whore_dropzone_sudo"
          droppableAcceptClassName="drag_whore_droppable_sudo"
          interactDraggable={interact(".drag_whore_dropzone_sudo")}
          interactDropZone={interact(".drag_whore_dropzone_sudo")}
        />
      ))}

      <BubbleHandler
        hide={hideBubble}
        show={!!whore.show}
        type={whore.bubbleType}
        content={getContent(whore)}
      />
    </>
  );
};

export default SudoStarted;
