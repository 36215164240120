// redux
import { appActions } from "redux/slices/appSlice";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { InvadersInfoProps } from "interfaces/spaceInvaders/invader";

// enums
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

const add = createAction(
  "@async/invaders/add",
  ({
    isMad,
    length,
    subtype,
  }: {
    length: number;
    subtype: FlyingObjectSubType;
    isMad?: boolean;
  }) => ({
    payload: { length, subtype, isMad },
  })
);

const initialState: InvadersInfoProps[] = [];

export const invadersSlice = createSlice({
  name: "invaders",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<InvadersInfoProps>) => {
      return [...state, action.payload];
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const invadersActions = {
  async: {
    add,
  },
  ...invadersSlice.actions,
};

export default invadersSlice.reducer;
