// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector } from "react-redux";

// interfaces
import { InvaderBulletProps } from "interfaces/spaceInvaders/invaderBullet";
import { FlyingObjectRefProps } from "interfaces/spaceInvaders/flyingObject";

// components
import { InvaderBulletFactory } from "factories/spaceInvaders/InvaderBulletFactory";

// utils
import { forever } from "async";
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

const BULLET_SPEED = 2;
const BULLET_MOVEMENT_DELAY_SPEED = 8;

interface InvaderBulletsProps {
  wrapper: HTMLDivElement | null;
  invaderBulletsRef: { current: FlyingObjectRefProps[] };
  paused?: boolean;
}

const InvaderBullets = ({
  paused,
  wrapper,
  invaderBulletsRef,
}: InvaderBulletsProps) => {
  const bullets = useSelector(s.invaderBullets());
  const pausedRef = useRef(paused);
  const killForeverProcess = useRef(false);

  useEffect(() => destroyComponent, []);
  useEffect(listenPaused, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [bullets]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(play, [paused]);

  function listen() {
    if (pausedRef.current) return;
    if (!wrapper) return;

    const bullet = bullets[bullets.length - 1];
    if (!bullet) return;

    const bulletEl = InvaderBulletFactory(bullet.subtype);
    wrapper.appendChild(bulletEl);

    setFirstPosition(bullet, bulletEl);
    add(bullet, bulletEl);
  }

  function listenPaused() {
    pausedRef.current = paused;
  }

  function play() {
    if (paused) return;

    invaderBulletsRef.current.forEach(({ el }) => {
      if (el.style.display === "none") return;
      moveDownForever(getTopPosition(el), el);
    });
  }

  function add(object: InvaderBulletProps, el: HTMLDivElement) {
    const InvaderBullet = spaceInvaderUtils.getEntity(object.subtype);

    invaderBulletsRef.current.push({
      object: new InvaderBullet(object.position),
      el,
    });

    setTimeout(() => moveDownForever(getTopPosition(el), el));
  }

  function setFirstPosition(
    bullet: InvaderBulletProps,
    bulletEl: HTMLDivElement
  ) {
    const { x, y } = bullet.position;

    bulletEl.style.display = "block";
    bulletEl.style.left = `${x}px`;
    bulletEl.style.top = `${y}px`;
  }

  function moveDownForever(top: number, bulletEl: HTMLDivElement) {
    if (!wrapper) return;
    if (!bulletEl) return;

    forever(
      (next: () => void) => {
        if (killForeverProcess.current) return;
        if (pausedRef.current) return;

        top += BULLET_SPEED;
        bulletEl.style.top = `${top}px`;

        if (top < wrapper.clientHeight)
          setTimeout(next, BULLET_MOVEMENT_DELAY_SPEED);
        else {
          remove(bulletEl);
          clear();
        }
      },
      (_err: unknown) => {
        remove(bulletEl);
        clear();
      }
    );
  }

  function getTopPosition(bullet: HTMLDivElement): number {
    return Number(bullet.style.top.replace("px", ""));
  }

  function remove(bullet: HTMLDivElement) {
    bullet.style.display = "none";
    bullet.remove();
  }

  function clear() {
    invaderBulletsRef.current = invaderBulletsRef.current.filter(
      ({ el }) => el.style.display !== "none"
    );
  }

  function destroyComponent() {
    killForeverProcess.current = true;
  }

  return null;
};

export default InvaderBullets;
