interface SvgProps {
  width?: string;
  height?: string;
}

const RavianUnamusedSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      height={height}
      viewBox="-24 0 511 511.99872"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <g fill="#353535">
        <path d="m333.089844 272.265625c0 12.023437-9.746094 21.769531-21.765625 21.769531-12.023438 0-21.757813-9.746094-21.757813-21.769531 0-6.027344 2.441406-11.476563 6.398438-15.421875h30.71875c3.957031 3.945312 6.40625 9.394531 6.40625 15.421875zm0 0" />
        <path d="m149.203125 272.265625c0 12.023437-9.746094 21.769531-21.765625 21.769531-12.023438 0-21.757812-9.746094-21.757812-21.769531 0-6.027344 2.441406-11.476563 6.398437-15.421875h30.71875c3.957031 3.945312 6.40625 9.394531 6.40625 15.421875zm0 0" />
        <path d="m180.933594 264.570312h-82.464844c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h82.464844c4.265625 0 7.726562 3.460937 7.726562 7.726562s-3.460937 7.726562-7.726562 7.726562zm0 0" />
      </g>
      <path
        d="m211.875 398.117188c-1.695312 0-3.402344-.550782-4.824219-1.695313-3.332031-2.667969-3.871093-7.527344-1.203125-10.859375 10.03125-12.527344 20.402344-25.480469 33.511719-35.953125 10.691406-8.542969 27.71875-18.765625 48.109375-18.957031h.511719c19.5 0 37.90625 10.453125 45.914062 26.125 1.941407 3.800781.4375 8.453125-3.363281 10.394531s-8.453125.4375-10.398438-3.363281c-5.476562-10.71875-18.574218-17.839844-32.519531-17.703125-15.984375.148437-29.820312 8.550781-38.609375 15.574219-11.769531 9.402343-21.59375 21.671874-31.09375 33.539062-1.527344 1.90625-3.769531 2.898438-6.035156 2.898438zm0 0"
        fill="#844d15"
      />
      <path
        d="m266.675781 376.183594c-2.675781 0-5.28125-1.394532-6.707031-3.886719-2.125-3.699219-.84375-8.421875 2.859375-10.546875 8.101563-4.644531 16.351563-7.039062 24.515625-7.121094 6.800781-.09375 13.519531 1.640625 19.453125 4.9375 3.730469 2.074219 5.078125 6.777344 3.003906 10.507813-2.070312 3.726562-6.777343 5.074219-10.503906 3-3.550781-1.96875-7.738281-3.023438-11.800781-2.992188-5.488282.054688-11.203125 1.761719-16.980469 5.074219-1.214844.695312-2.535156 1.027344-3.839844 1.027344zm0 0"
        fill="#844d15"
      />
      <path
        d="m364.820312 264.570312h-82.464843c-4.265625 0-7.726563-3.460937-7.726563-7.726562s3.460938-7.726562 7.726563-7.726562h82.464843c4.269532 0 7.726563 3.460937 7.726563 7.726562s-3.457031 7.726562-7.726563 7.726562zm0 0"
        fill="#353535"
      />
    </svg>
  );
};

export default RavianUnamusedSvg;
