// react
import { IonCheckbox } from "@ionic/react";

// formik
import { useField } from "formik";

// components
import { FormItem } from "components/formik/FormikInput";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  max-height: 90px;
  --color: ${color.grey.strong} !important;

  .formik_checkbox_label {
    color: ${color.grey.strong} !important;
    margin-left: 10px;
    font-size: 14px;
  }

  .formik_checkbox {
    --border-color: ${color.grey.strong};
  }
`;

const FormikCheckbox = ({ label, identifier, children, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <Wrapper>
      <FormItem removeBorder>
        <IonCheckbox
          {...props}
          onIonChange={field.onChange}
          onIonBlur={field.onBlur}
          value={field.value}
          className="formik_checkbox"
          color="tertiary"
          type="checkbox"
          mode="md"
          id={identifier}
        />

        <label className="formik_checkbox_label" htmlFor={identifier}>
          {children}
        </label>
      </FormItem>

      {meta.touched && meta.error && (
        <ErrorFeedback err={meta.error} alignLeft />
      )}
    </Wrapper>
  );
};

export default FormikCheckbox;
