// utils
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
  padding: 0 15px;
  width: 100%;
`;

interface SubHeaderWrapperProps {
  children: React.ReactNode;
}

const SubHeaderWrapper = ({ children }: SubHeaderWrapperProps) => {
  return <Wrapper>{children}</Wrapper>;
};

export default SubHeaderWrapper;
