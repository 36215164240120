// import APP_NERDFY from "constants/app";

// const english = {
//   evaluateButton: {
//     congrats: "Congratulations!",
//     howAboutEvaluate: "How about evaluating the content? ;)",
//   },
//   rate: {
//     body: "Como você avalia o <span class='important'>conteúdo</span> até agora?",
//     button: "Avaliar Conteúdo",
//   },
//   parcialRate: {
//     body: "<p>How do you rate the <span className='important'>content</span> until now?</p>",
//     button: "Rate Content",
//     evaluateLater: "Evaluate later",
//     evaluateOnEnd: "Evaluate only at the end",
//   },
// };

const portuguese = {
  evaluateButton: {
    congrats: "Parabéns!",
    howAboutEvaluate: "Que tal avaliar o conteúdo? ;)",
  },
  rate: {
    body: "Estimado(a) <span className='cmd'>primata</span>, como você <span className='imp'>avalia</span> o <span class='congrats'>conteúdo</span> até <span class='v_imp'>agora</span>?",
    button: "Salvar",
  },
};

function get() {
  // if (APP_NERDFY.languageId === 1) return english;
  // if (APP_NERDFY.languageId === 2) return portuguese;

  return portuguese;
}

const language = {
  get,
};

export default language;
