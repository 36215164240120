// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// enums
import { BubbleType } from "enums/bubbleEnum";
import { ChallengeType } from "enums/challengeEnum";
import { EventWhoType, EventChallengeFlowType } from "enums/eventEnum";

// icons
import IconWhoreGod from "components/iconWhores/IconWhoreGod";
import IconWhoreMessage from "components/iconWhores/IconWhoreMessage";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { EventProps } from "interfaces/event";
import { PlayerEventsDataProps } from "interfaces/player";
import { ChallengeCommonProps } from "interfaces/challenge";

// utils
import isEmpty from "lodash/isEmpty";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface EventsHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
  onBubble?(): void;
  onHideBubble?(): void;
}

const EventsHandler = ({
  paused,
  wrapper,
  onBubble,
  challenge,
  onHideBubble,
}: EventsHandlerProps) => {
  const dispatch = useDispatch();
  const eventRef = useRef<PlayerEventsDataProps>({});
  const player = useSelector(s.player());
  const events = useSelector(s.events());
  const { flowInit, flowFinished, flowDone } = challenge;
  const [godAdvisors, setGodAdvisors] = useState<AttentionWhoreProps[]>([]);
  const [jarvisAdvisors, setJarvisAdvisors] = useState<AttentionWhoreProps[]>(
    []
  );
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToStoriesWhenFlowInit, [events, flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToStoriesWhenFlowFinished, [events, flowFinished]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(syncEventsWhenFlowDone, [events, flowDone]);

  function listenToStoriesWhenFlowInit() {
    if (!flowInit) return;
    if (isEmpty(player)) return;
    if (isEmpty(events)) return;

    const event = get(EventChallengeFlowType.FlowStarted);
    if (!event) return;

    eventRef.current[event.id] = true;
    show(event);
  }

  function listenToStoriesWhenFlowFinished() {
    if (!flowFinished) return;
    if (isEmpty(player)) return;
    if (isEmpty(events)) return;

    const event = get(EventChallengeFlowType.FlowFinished);
    if (!event) return;

    eventRef.current[event.id] = true;
    show(event);
  }

  function syncEventsWhenFlowDone() {
    if (!flowDone) return;
    if (isEmpty(player)) return;
    if (isEmpty(events)) return;
    if (!eventRef.current) return;

    dispatch(playerActions.async.updateEvents({ events: eventRef.current }));
  }

  function get(
    challengeFlowType: EventChallengeFlowType
  ): EventProps | undefined {
    return events.find(
      (event) =>
        filterByPlayerEvents(event) &&
        filterByChallengeType(event) &&
        filterByChallengeFlowType(event, challengeFlowType)
    );
  }

  function filterByPlayerEvents(event: EventProps): boolean {
    return !player.events[event.id];
  }

  function filterByChallengeType(event: EventProps): boolean {
    if (event.challengeType === ChallengeType.None) return true;
    return event.challengeType === challenge.type;
  }

  function filterByChallengeFlowType(
    event: EventProps,
    challengeFlowType: EventChallengeFlowType
  ): boolean {
    if (event.challengeFlowType === EventChallengeFlowType.None) return true;
    return event.challengeFlowType === challengeFlowType;
  }

  function show(data: EventProps) {
    const { whoType } = data;

    if (whoType === EventWhoType.None) return;
    if (whoType === EventWhoType.God) buildWhoreGod(data);
    if (whoType === EventWhoType.Jarvis) buildWhoreJarvis(data);
  }

  function buildWhoreGod(data: EventProps) {
    if (!wrapper) return;

    const whores = data.event.reduce((acc: AttentionWhoreProps[], event) => {
      const whore = attentionWhoreUtils.get({
        payload: event,
        WhoreEl: IconWhoreGod,
        call: onAttentionWhoreCall,
        bubbleType: BubbleType.God,
        dropZoneBorderColor: "purple",
        wrapperWidth: wrapper.clientWidth,
        wrapperHeight: wrapper.clientHeight,
      });

      acc.push(whore);
      return acc;
    }, []);

    setGodAdvisors(whores);
  }

  function buildWhoreJarvis(data: EventProps) {
    if (!wrapper) return;

    const whores = data.event.reduce((acc: AttentionWhoreProps[], event) => {
      const whore = attentionWhoreUtils.get({
        payload: event,
        WhoreEl: IconWhoreMessage,
        call: onAttentionWhoreCall,
        dropZoneBorderColor: "yellow",
        bubbleType: BubbleType.Message,
        wrapperWidth: wrapper.clientWidth,
        wrapperHeight: wrapper.clientHeight,
      });

      acc.push(whore);
      return acc;
    }, []);

    setJarvisAdvisors(whores);
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    onBubble && onBubble();
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    onHideBubble && onHideBubble();
    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  return (
    <>
      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <AttentionWhores
        paused={paused}
        clear={flowDone}
        whores={jarvisAdvisors}
        disabledDrop={!!attentionWhore.show}
      />

      <AttentionWhores
        paused={paused}
        clear={flowDone}
        whores={godAdvisors}
        disabledDrop={!!attentionWhore.show}
      />
    </>
  );
};

export default EventsHandler;
