// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const CheckboxInputSvg = ({
  width = "18",
  height = "18",
  color = c.grey.strong,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4H6c-2.206 0-4-1.794-4-4V6c0-2.206 1.794-4 4-4zm0-2H6a6 6 0 0 0-6 6v12a6 6 0 0 0 6 6h12a6 6 0 0 0 6-6V6a6 6 0 0 0-6-6z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M9.957 17a1 1 0 0 1-.7-.286l-2.857-2.8a1 1 0 0 1 1.4-1.429L9.957 14.6l6.442-6.314A1 1 0 0 1 17.8 9.714l-7.143 7a1 1 0 0 1-.7.286z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default CheckboxInputSvg;
