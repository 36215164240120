// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const LocationPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0 0 100 100"
    >
      <g>
        <path
          d="M35.087 5.011H65.1v10H35.087zM35.059 75.01h10.013v10H35.059zM45.072 85.01h10.013v10H45.072zM65.072 65.01h10.013v10H65.072z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M65.059 15.01h10.013v10H65.059zM25.059 65.01h10.013v10H25.059zM25.059 15.01h10.013v10H25.059zM55.059 75.01h10.013v10H55.059zM35.072 25.01h-.013v30h30.013v-30zm19.987 20h-9.987v-10h9.987zM75.072 25.01h10v40h-10zM25.075 25.01h-10v39.981h10c-.01-13.29.01-26.691 0-39.98z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default LocationPixelIcon;
