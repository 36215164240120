// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const CodeTagPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.strong,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 12.7 12.7"
    >
      <g>
        <path
          d="M3.705 2.647v1.056h1.057V2.647zm0 1.056H2.647v1.059h1.058zM2.647 4.762H1.588V5.82h1.059zM1.588 5.82H.53v1.059h1.06zm0 1.059v1.058h1.059V6.88zm1.059 1.058v1.059h1.058V7.937zm1.058 1.059v1.058h1.057V8.996zm4.233-6.35v1.057h1.059V2.647zm1.059 1.057v1.059h1.058v-1.06zm1.058 1.059V5.82h1.059V4.762zm1.059 1.058v1.059h1.056V5.82zm0 1.059h-1.06v1.058h1.06zm-1.06 1.058H8.998v1.059h1.058zM8.998 8.996h-1.06v1.058h1.06z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default CodeTagPixelIcon;
