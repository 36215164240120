// widgets
// import SpaceshipResourcesInfoWidget from "components/widgets/SpaceshipResourcesInfoWidget";

const ShopSubHeader = () => {
  return (
    <div className="subheader pixelify_font_family">
      {/* <SpaceshipResourcesInfoWidget /> */}

      {/* <ul>
        <li className="value">({money})</li>
        <li className="coin_label">({money > 1 ? "estelins" : "estelin"})</li>
      </ul> */}
    </div>
  );
};

export default ShopSubHeader;
