// react
import { useState, useEffect } from "react";

// enums
import { BubbleType } from "enums/bubbleEnum";

// components
import {
  BubbleFeedbackFooter,
  BubbleQuestionSubHeader,
} from "handlers/bubble/Bubble";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

interface JarvisAnswerProps {
  hide(): void;
  answer: string;
  question: string;
  read?: boolean;
  show?: boolean;
  thumbsUp?: boolean;
  thumbsDown?: boolean;
  finishedAnswer?(): void;
  onCloseAnimationEnd?(): void;
  syncThumbs(thumbsUp: boolean, thumbsDown: boolean): void;
}

const JarvisAnswerBubble = ({
  show,
  hide,
  read,
  answer,
  thumbsUp,
  question,
  thumbsDown,
  syncThumbs,
  finishedAnswer,
  onCloseAnimationEnd,
}: JarvisAnswerProps) => {
  const [isThumbsUp, setIsThumbUp] = useState(false);
  const [isThumbsDown, setIsThumbsDown] = useState(false);

  useEffect(handleThumbsUp, [thumbsUp]);
  useEffect(handleThumbsDown, [thumbsDown]);

  function handleThumbsUp() {
    if (!thumbsUp) return;
    setIsThumbUp(true);
  }

  function handleThumbsDown() {
    if (!thumbsDown) return;
    setIsThumbsDown(true);
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    if (e.animationName !== "bounceOutLeft") return;
    if (!onCloseAnimationEnd) return;

    setTimeout(onCloseAnimationEnd);
    clear();
  }

  function onThumbsDown() {
    if (isThumbsDown) return;

    setIsThumbsDown(true);
    setIsThumbUp(false);
    syncThumbs(false, true);
  }

  function onThumbsUp() {
    if (isThumbsUp) return;

    setIsThumbUp(true);
    setIsThumbsDown(false);
    syncThumbs(true, false);
  }

  function clear() {
    setIsThumbUp(false);
    setIsThumbsDown(false);
  }

  return (
    <>
      {show && (
        <BubbleHandler
          show={show}
          hide={hide}
          content={answer}
          type={BubbleType.Message}
          finishedTyping={finishedAnswer}
          onAnimationEnd={onAnimationEnd}
          subHeader={
            <BubbleQuestionSubHeader question={question} read={!!read} />
          }
          footer={
            <BubbleFeedbackFooter
              isThumbsUp={isThumbsUp}
              onThumbsUp={onThumbsUp}
              isThumbsDown={isThumbsDown}
              onThumbsDown={onThumbsDown}
            />
          }
        />
      )}
    </>
  );
};

export default JarvisAnswerBubble;
