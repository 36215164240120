// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { jarvisChatsActions } from "redux/slices/jarvisChatsSlice";

// interfaces
import { JarvisChatFirebaseProps } from "interfaces/jarvisChat";

// services
import PlayerJarvisChatsFirebaseService from "services/firebase/player/PlayerJarvisChatsFirebaseService";
import GlobalClassRoomJarvisFirebaseService from "services/firebase/global/GlobalClassRoomJarvisFirebaseService";

// utils
import stateUtils from "redux/utils/stateUtils";

const jarvisChatsMiddleware = createListenerMiddleware();

// jarvis/answer/thumbs
jarvisChatsMiddleware.startListening({
  actionCreator: jarvisChatsActions.async.setAnswerThumbs,
  effect: async ({ payload }, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, down: thumbsDown, up: thumbsUp } = payload;
    const data: JarvisChatFirebaseProps = {
      id,
      up: thumbsUp,
      down: thumbsDown,
    };
    const userId = auth.user.id;
    const classRoomId = challenge.classRoomId;
    const challengeId = challenge.id;

    syncJarvisChats(userId, classRoomId, challengeId, id, data);
    syncGlobalClassRoom(
      classRoomId,
      challengeId,
      id,
      mapThumbsUpDownGlobal(data)
    );
  },
});

// jarvis/answer/read
jarvisChatsMiddleware.startListening({
  actionCreator: jarvisChatsActions.async.setAnswerAsRead,
  effect: async ({ payload }, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, read } = payload;
    const data: JarvisChatFirebaseProps = { id, read };
    const userId = auth.user.id;
    const classRoomId = challenge.classRoomId;
    const challengeId = challenge.id;

    syncJarvisChats(userId, classRoomId, challengeId, id, data);
  },
});

// jarvis/question
jarvisChatsMiddleware.startListening({
  actionCreator: jarvisChatsActions.async.setQuestionAsClicked,
  effect: async ({ payload }, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, questionClicked } = payload;
    const data: JarvisChatFirebaseProps = { id };
    const userId = auth.user.id;
    const classRoomId = challenge.classRoomId;
    const challengeId = challenge.id;

    if (questionClicked) data.questionClicked = questionClicked;

    syncJarvisChats(userId, classRoomId, challengeId, id, data);
  },
});

// private

function mapThumbsUpDownGlobal(data: JarvisChatFirebaseProps) {
  const { id, up, down } = data;
  const result: JarvisChatFirebaseProps = { id };

  if (up) result.up = up;
  if (down) result.down = down;

  return result;
}

function syncJarvisChats(
  userId: number | string,
  classRoomId: number,
  challengeId: number,
  jarvisChatId: number,
  data: JarvisChatFirebaseProps
) {
  if (!userId) return;
  if (!classRoomId) return;
  if (!challengeId) return;

  const resource = new PlayerJarvisChatsFirebaseService();
  resource.set(userId, classRoomId, jarvisChatId, data);
}

function syncGlobalClassRoom(
  classRoomId: number,
  challengeId: number,
  id: number,
  data: JarvisChatFirebaseProps
) {
  if (!id) return;
  if (!challengeId) return;

  const resource = new GlobalClassRoomJarvisFirebaseService();
  resource.update(classRoomId, challengeId, id, data);
}

export default jarvisChatsMiddleware;
