interface SvgProps {
  width?: string;
  height?: string;
}

const RavianTemplateSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      viewBox="-24 0 511 511.99872"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m367.242188 245.425781c-1.890626 0-3.789063-.691406-5.277344-2.085937-10.375-9.710938-23.890625-15.722656-38.050782-16.921875-4.253906-.363281-7.40625-4.101563-7.046874-8.351563.359374-4.253906 4.097656-7.414062 8.351562-7.046875 17.609375 1.492188 34.40625 8.964844 47.304688 21.039063 3.117187 2.917968 3.277343 7.804687.363281 10.921875-1.523438 1.625-3.582031 2.445312-5.644531 2.445312zm0 0"
        fill="#353535"
      />
      <path
        d="m96.046875 245.425781c-2.0625 0-4.121094-.820312-5.644531-2.445312-2.914063-3.113281-2.753906-8.003907.359375-10.921875 12.902343-12.074219 29.699219-19.542969 47.308593-21.039063 4.238282-.363281 7.988282 2.792969 8.351563 7.046875.359375 4.25-2.796875 7.992188-7.046875 8.351563-14.160156 1.199219-27.675781 7.210937-38.050781 16.921875-1.488281 1.394531-3.386719 2.085937-5.277344 2.085937zm0 0"
        fill="#353535"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <path
        d="m346.34375 280.570312c-2.589844 0-5.121094-1.304687-6.582031-3.671874-4.855469-7.875-10.921875-12.210938-17.078125-12.210938-6.152344 0-12.21875 4.339844-17.074219 12.210938-2.242187 3.632812-7.003906 4.757812-10.632813 2.519531-3.632812-2.242188-4.761718-7-2.519531-10.632813 7.777344-12.605468 18.511719-19.546875 30.226563-19.546875s22.453125 6.941407 30.230468 19.546875c2.238282 3.632813 1.113282 8.390625-2.519531 10.632813-1.265625.78125-2.664062 1.152343-4.050781 1.152343zm0 0"
        fill="#353535"
      />
      <path
        d="m164.265625 280.570312c-2.59375 0-5.125-1.304687-6.585937-3.671874-4.855469-7.875-10.921876-12.210938-17.074219-12.210938-6.15625 0-12.21875 4.339844-17.078125 12.210938-2.242188 3.632812-7.003906 4.757812-10.632813 2.519531-3.632812-2.242188-4.757812-7-2.519531-10.632813 7.78125-12.605468 18.515625-19.546875 30.230469-19.546875 11.714843 0 22.449219 6.941407 30.226562 19.546875 2.242188 3.632813 1.113281 8.390625-2.519531 10.632813-1.261719.78125-2.664062 1.152343-4.046875 1.152343zm0 0"
        fill="#353535"
      />
      <path
        d="m346.714844 368.972656c0-31.097656-51.519532-56.308594-115.070313-56.308594s-115.070312 25.210938-115.070312 56.308594 51.519531 56.304688 115.070312 56.304688 115.070313-25.207032 115.070313-56.304688zm0 0"
        fill="#8c2f3f"
      />
      <path
        d="m155.722656 326.664062c-1.605468 4.601563-2.457031 9.421876-2.457031 14.394532 0 10.125 3.550781 19.640625 9.769531 27.914062h137.21875c6.21875-8.273437 9.769532-17.789062 9.769532-27.914062 0-4.972656-.851563-9.792969-2.457032-14.394532-20.261718-8.714843-46.828125-14-75.921875-14s-55.660156 5.285157-75.921875 14zm0 0"
        fill="#e8eaea"
      />
      <path
        d="m163.035156 368.972656c-6.21875 8.273438-9.769531 17.785156-9.769531 27.914063 0 4.96875.851563 9.792969 2.457031 14.394531 20.261719 8.710938 46.828125 13.996094 75.921875 13.996094s55.660157-5.285156 75.921875-13.996094c1.605469-4.601562 2.457032-9.425781 2.457032-14.394531 0-10.128907-3.550782-19.640625-9.769532-27.914063zm0 0"
        fill="#e8eaea"
      />
      <g fill="#c0c4c4">
        <path d="m279.851562 405.6875c0-12.730469-1.660156-25.359375-4.769531-36.714844h-15.730469c0 .722656.097657 1.453125.3125 2.179688 3.097657 10.507812 4.734376 22.449218 4.734376 34.535156 0 5.09375-.292969 10.238281-.871094 15.292969-.082032.714843-.054688 1.417969.050781 2.09375 5.355469-.753907 10.542969-1.703125 15.546875-2.8125.472656-4.835938.726562-9.726563.726562-14.574219zm0 0" />
        <path d="m203.9375 368.972656h-15.730469c-3.109375 11.355469-4.769531 23.988282-4.769531 36.714844 0 4.847656.253906 9.734375.726562 14.574219 5.003907 1.109375 10.1875 2.058593 15.546876 2.8125.105468-.675781.132812-1.378907.050781-2.09375-.578125-5.058594-.871094-10.203125-.871094-15.292969 0-12.085938 1.636719-24.027344 4.734375-34.535156.214844-.726563.3125-1.457032.3125-2.179688zm0 0" />
        <path d="m223.917969 425.136719c2.554687.085937 5.128906.140625 7.726562.140625 2.597657 0 5.167969-.054688 7.726563-.140625v-56.164063h-15.453125zm0 0" />
        <path d="m203.625 366.785156c-3.097656-10.507812-4.734375-22.445312-4.734375-34.527344 0-5.089843.292969-10.234374.871094-15.292968.082031-.714844.054687-1.417969-.054688-2.097656-5.355469.757812-10.539062 1.703124-15.542969 2.816406-.472656 4.835937-.726562 9.726562-.726562 14.574218 0 12.726563 1.660156 25.351563 4.769531 36.714844h15.730469c0-.726562-.097656-1.460937-.3125-2.1875zm0 0" />
        <path d="m263.527344 316.964844c.578125 5.058594.871094 10.203125.871094 15.292968 0 12.082032-1.636719 24.019532-4.734376 34.527344-.214843.726563-.3125 1.464844-.3125 2.1875h15.730469c3.109375-11.359375 4.769531-23.988281 4.769531-36.714844 0-4.847656-.253906-9.738281-.726562-14.574218-5.003906-1.113282-10.1875-2.058594-15.542969-2.816406-.109375.679687-.136719 1.382812-.054687 2.097656zm0 0" />
        <path d="m223.917969 312.804688v56.167968h15.453125v-56.167968c-2.554688-.082032-5.125-.140626-7.726563-.140626-2.597656 0-5.171875.058594-7.726562.140626zm0 0" />
        <path d="m158.480469 361.765625c1.300781 2.488281 2.820312 4.894531 4.554687 7.207031h137.21875c1.734375-2.3125 3.253906-4.71875 4.554688-7.207031zm0 0" />
        <path d="m304.808594 376.179688c-1.300782-2.488282-2.820313-4.898438-4.554688-7.207032h-137.21875c-1.734375 2.308594-3.253906 4.71875-4.554687 7.207032zm0 0" />
      </g>
    </svg>
  );
};

export default RavianTemplateSvg;
