// react
import { useState, useEffect } from "react";

// icons
import IconWhoreAddPerson from "components/iconWhores/IconWhoreAddPerson";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import AstrogildoPresentation from "components/presentations/AstrogildoPresentation";

// interfaces
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface AstrogildoPresentationHandlerProps {
  start: boolean;
  finishPresentation: () => void;
  wrapper: HTMLDivElement | undefined;
}

const AstrogildoPresentationHandler = ({
  start,
  wrapper,
  finishPresentation,
}: AstrogildoPresentationHandlerProps) => {
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [showPresentation, setShowPresentation] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePresentation, [start]);

  function handlePresentation() {
    if (!start) return;
    setTimeout(displayWhores, 1000);
  }

  // ATTENTION WHORE

  function displayWhores() {
    if (!wrapper) return;

    const whore = attentionWhoreUtils.get({
      payload: "",
      WhoreEl: IconWhoreAddPerson,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "blue",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setWhores([whore]);
  }

  function onAttentionWhoreCall() {
    setTimeout(() => setShowPresentation(true));
  }

  // ATTENTION WHORE END

  return (
    <>
      <AttentionWhores whores={whores} />

      <AstrogildoPresentation
        wrapper={wrapper}
        show={showPresentation}
        finishPresentation={finishPresentation}
      />
    </>
  );
};

export default AstrogildoPresentationHandler;
