// services
import RequestCoreService from "services/core/RequestCoreService";

// interfaces
import { EventResourceProps } from "interfaces/event";

export interface GetResponseProps {
  data: EventResourceProps[];
}

class EventService {
  private _resource = "event";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  get(languageId: number): Promise<GetResponseProps> {
    if (!languageId) return Promise.reject();

    const resource = `${this._resource}/language/${languageId}`;
    return this._request.get(resource);
  }
}

export default EventService;
