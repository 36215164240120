import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

export enum SpaceshipColorType {
  None = 0,
  Red,
  Green,
  Blue,
  Purple,
  Orange,
  Yellow,
}

export enum SpaceshipPartType {
  Engine = 1,
  Wing,
  External,
}

export enum SpaceshipResourceType {
  Gold = FlyingObjectSubType.AsteroidMineralGold,
  Silver = FlyingObjectSubType.AsteroidMineralSilver,
  Sand = FlyingObjectSubType.AsteroidMineralSand,
  Bauxite = FlyingObjectSubType.AsteroidMineralBauxite,
  Copper = FlyingObjectSubType.AsteroidMineralCopper,
  Water = FlyingObjectSubType.AsteroidWater,
  StrongForce = FlyingObjectSubType.AsteroidStrongForce,
  Estelin = FlyingObjectSubType.AsteroidMineralEstelin,
}
