// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { spaceshipResourcesActions } from "redux/slices/spaceInvaders/spaceshipResourcesSlice";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesSourceDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";
import {
  AlertContentProps,
  AlertContentDataProps,
  SpaceshipResourceReducedProps,
  SpaceshipResourcesLoopOutputProps,
} from "handlers/spaceshipResources/SpaceshipResourcesHandler";
import { ChallengeCommonProps } from "interfaces/challenge";
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// entities
import SpaceshipResourcesToShiftEntity from "entities/spaceshipResources/SpaceshipResourcesToShiftEntity";

// factories
import { AlertFactoryType } from "factories/AlertFactory";

// utils
import isEmpty from "lodash/isEmpty";

const ALERTS_INIT_SHOW_DELAY = 3500;

type SpaceshipResourcesHashSourceDataProps = {
  [key: string]: SpaceshipResourcesSourceDataProps;
};

interface SpaceShipResourcesHandlerProps {
  challenge: ChallengeCommonProps;
  loop(
    resources: SpaceshipResourcesProps,
    reduce: (
      data: SpaceshipResourcesHashSourceDataProps
    ) => SpaceshipResourceReducedProps
  ): SpaceshipResourcesLoopOutputProps;
  recursiveAlerts(
    data: SpaceshipResourcesLoopOutputProps,
    getContent: (alert: AlertContentDataProps) => AlertContentProps,
    mapAlert?: (
      resource: keyof SpaceshipResourcesProps,
      data: SpaceshipResourceReducedProps
    ) => AlertContentDataProps[]
  ): void;
  hasExpired(d: SpaceshipResourcesSourceDataProps): boolean;
  getResourceAlias(resource: keyof SpaceshipResourcesProps): string;
  getResourceTurnsToTransform(resource: keyof SpaceshipResourcesProps): number;
}

const SpaceShipResourcesTransformHandler = ({
  loop,
  challenge,
  hasExpired,
  recursiveAlerts,
  getResourceAlias,
  getResourceTurnsToTransform,
}: SpaceShipResourcesHandlerProps) => {
  const dispatch = useDispatch();
  const alreadyStartedRef = useRef(false);
  const refineMachineCapacityRef = useRef(0);
  const resources = useSelector(s.spaceshipResources());
  const spaceshipCounter = useSelector(s.spaceshipItemsCounter());
  const { flowDone, flowFinished } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleShift, [flowDone, flowFinished, resources]);
  useEffect(listenToRefineMachineCapacity, [spaceshipCounter]);

  function listenToRefineMachineCapacity() {
    refineMachineCapacityRef.current =
      spaceshipCounter[SpaceshipItemEnum.RefineMachine]?.capacity || 0;
  }

  function handleShift() {
    if (!flowDone) return;
    if (!flowFinished) return;
    if (alreadyStartedRef.current) return;

    alreadyStartedRef.current = true;

    const resources = loopResources();
    const entity = new SpaceshipResourcesToShiftEntity(
      refineMachineCapacityRef.current,
      resources
    );
    const { resourcesToShift } = entity.map;

    if (!isEmpty(resourcesToShift)) {
      dispatch(spaceshipResourcesActions.async.shift(resourcesToShift));

      setTimeout(
        () =>
          recursiveAlerts(resourcesToShift, getAlertContent, mapAlertByTurns),
        ALERTS_INIT_SHOW_DELAY
      );
    }
  }

  function loopResources() {
    return loop(
      resources,
      reduceResourcesToShift
    ) as SpaceshipResourcesUpdateFirebaseProps;
  }

  function getAlertContent(alert: AlertContentDataProps): AlertContentProps {
    const { resource, quantity, turns, turnsToTransform } = alert;

    return {
      type: AlertFactoryType.Info,
      content: `${resource} (${quantity}) (${turns}/${turnsToTransform} turnos) (refinando...)`,
    };
  }

  function mapAlertByTurns(
    resource: keyof SpaceshipResourcesProps,
    data: SpaceshipResourceReducedProps
  ): AlertContentDataProps[] {
    const keys = Object.keys(data) as Array<string>;

    const reduced = keys.reduce((acc, key) => {
      const d = data[key] as SpaceshipResourcesSourceDataProps;

      if (!acc[d.turns])
        acc[d.turns] = {
          quantity: 1,
          turns: d.turns + 1,
          resource: getResourceAlias(resource),
          turnsToTransform: getResourceTurnsToTransform(resource),
        };
      else acc[d.turns].quantity += 1;

      return acc;
    }, {} as { [key: string]: AlertContentDataProps });

    return Object.values(reduced)
      .map((v) => v)
      .sort((a, b) => b.turns - a.turns);
  }

  function reduceResourcesToShift(
    data: SpaceshipResourcesHashSourceDataProps
  ): SpaceshipResourceReducedProps {
    return Object.keys(data).reduce((acc, key: string) => {
      const d = data[key];

      if (d.transformed) return acc;
      if (hasExpired(d)) return acc;

      acc[key] = d;

      return acc;
    }, {} as SpaceshipResourceReducedProps);
  }

  return null;
};

export default SpaceShipResourcesTransformHandler;
