// utils
import c from "styles/color";

interface IconProps {
  width?: string;
  color?: string;
  height?: string;
}

const ThunderIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M166.957 333.913v-33.391H77.913V200.348H44.522v133.565h89.043V512h66.783v-33.391h-33.391zM200.348 445.217h33.391v33.391h-33.391zM233.739 411.826h33.391v33.391h-33.391zM267.13 378.435h33.391v33.391H267.13zM300.522 345.043h33.391v33.391h-33.391zM333.913 311.652h33.391v33.391h-33.391zM300.522 166.957h33.391v33.391h-33.391zM367.304 233.739h-66.782v-33.391H267.13v33.391h-33.391v33.391h133.565v44.522h33.392v-77.913zM333.913 133.565h33.391v33.391h-33.391zM77.913 100.174h33.391v100.174H77.913zM367.304 100.174h33.391v33.391h-33.391zM400.696 66.783h33.391v33.391h-33.391z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M434.087 0H111.304v100.174h33.392V33.391h289.391v33.392h33.391V0z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ThunderIcon;
