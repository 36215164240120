// react
import { useEffect, useState } from "react";

// interfaces
import { ChallengeCodeStepProps } from "interfaces/challengeCode";
import { ChallengeProps } from "interfaces/challenge";

// components
import ProgressBar from "components/ProgressBar";

interface ChallengeProgressHandlerProps {
  challenge: ChallengeProps;
}

const ChallengeBattleProgressHandler = ({
  challenge,
}: ChallengeProgressHandlerProps) => {
  const { frame, code, flowDone } = challenge;
  const [challengeProgress, setChallengeProgress] = useState(flowDone ? 1 : 0);

  useEffect(hideBoss, [challenge.flowFinished]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleProgressByFrame, [frame]);

  function hideBoss() {
    if (!challenge.flowFinished) return;
  }

  function handleProgressByFrame() {
    if (flowDone) return;
    if (!frame) return;

    const len = getBiggestStepLength();
    if (!len) return;

    setChallengeProgress(frame / len);
  }

  function getBiggestStepLength(): number {
    if (!code) return 0;
    if (!code.steps) return 0;

    return code.steps.reduce((acc: number, list: ChallengeCodeStepProps) => {
      const { length } = list.data;

      if (acc < length) acc = length;
      return acc;
    }, 0);
  }

  return <ProgressBar value={challengeProgress} color="success" />;
};

export default ChallengeBattleProgressHandler;
