// redux
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import { ChallengeSectionProps } from "interfaces/challengeSection";

const initialState: ChallengeSectionProps[] = [];

export const challengeSectionsSlice = createSlice({
  name: "challengeSections",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ChallengeSectionProps[]>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const challengeSectionsActions = challengeSectionsSlice.actions;
export default challengeSectionsSlice.reducer;
