// react
import { useState, useRef, useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeQuizActions } from "redux/slices/challengeQuizSlice";

// components
import Board from "components/Board";
import Confetti from "components/Celebration";
import ProgressBar from "components/ProgressBar";
import LevelUpHandler from "handlers/levelUp/LevelUpHandler";
import SlideDotsProgress from "components/SlideDotsProgress";
import { DropZoneAttentionWhore } from "components/DropZone";
import ChallengeOptionsFooter from "components/ChallengeOptionsFooter";
import ChallengeGameFlowHandler from "handlers/ChallengeGameFlowHandler";
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import SpaceshipResourcesStatusBar from "components/SpaceshipResourcesStatusBar";
import ButtonDuolingoQuizAnswer from "components/buttons/ButtonDuolingoQuizAnswer";
import SpaceshipStatusBar from "components/challenge/battle/statusbar/SpaceshipStatusBar";
import ZarvoxPresentationHandler from "handlers/presentation/ZarvoxPresentationHandler";
import ChallengeQuizStatusBar from "components/challengeQuiz/statusbar/ChallengeQuizStatusBar";
import ChallengeQuizSubStatusBar from "components/challengeQuiz/statusbar/ChallengeQuizSubStatusBar";
import AvatarIconErgoPlaceholderIcon from "components/avatarIcons/placeholders/AvatarIconErgoPlaceholder";

// factories
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// icons
import IconWhoreQuiz from "components/iconWhores/IconWhoreQuiz";

// handlers
import AvatarButtonsHandler, {
  AvatarButtonType,
} from "handlers/AvatarButtonsHandler";
import BubbleHandler from "handlers/bubble/BubbleHandler";
import SpaceInvadersHandler from "handlers/SpaceInvadersHandler";
import VictoryNewsHandler from "handlers/victoryNews/VictoryNewsHandler";
import ChallengeCommonHandlersHandler from "handlers/ChallengeCommonHandlersHandler";
import SpaceshipCustomizationRestartHandler from "handlers/SpaceshipCustomizationRestartHandler";
import ChallengeDoneButtonHandler from "handlers/challengeDoneButton/ChallengeDoneButtonHandler";

// enums
import { BubbleType } from "enums/bubbleEnum";
import { VictoryNewsPointType } from "enums/victoryNewsEnum";

// interfaces
import {
  ChallengeQuizProps,
  ChallengeQuizAnswersHashProps,
} from "interfaces/challengeQuiz";
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { DotProgressRefProps } from "components/SlideDotsProgress";

// Swiper
import { IonicSlides } from "@ionic/react";
import { Swiper as SwiperProps } from "swiper";
import { ChallengeCommonProps } from "interfaces/challenge";
import { Swiper, SwiperSlide } from "swiper/react";

// utils
import color from "styles/color";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import blinkUtils from "utils/blinkUtils";
import numberUtils from "utils/numberUtils";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .slides {
    flex: 1;
    display: flex;
    flex-direction: column;

    .slide {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
    }
  }

  .progressbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    text-align: center;
  }

  > .buttons_wrapper {
    height: 75px;
    padding-bottom: 10px;

    ul {
      all: unset;
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 100%;

      li {
        display: flex;
        align-items: center;
        padding: 0 8px;
        position: relative;

        .correct_answer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          background: ${color.green};
        }

        .btn_wrapper {
          display: flex;
          align-items: center;

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }

  > .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100px;

    > ul {
      all: unset;
      display: flex;
      height: 100%;
      background: ${color.dark.bg};
      color: ${color.grey.strong};
    }

    ul.info_wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 15px;

      li {
        unset: all;

        p {
          unset: all;
          display: flex;
          align-items: center;
          font-size: 18px;
          margin: 5px 0;

          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

const SVG_GRADIENT_FLAG = "__flag_is_gradient__";

interface CofCofProps {
  content: string;
  type: AlertFactoryType;
}

interface CorrectHashProps {
  [key: number]: boolean;
}

interface ChallengeQuizBattleProps {
  paused: boolean;
  quiz: ChallengeQuizProps;
  handlerWrapperRef: (node: HTMLDivElement) => void;
  handlerNotifiersRef: (node: HTMLDivElement) => void;
  reOpenChallenge: (data: ChallengeCommonProps) => void;
  blinkElRef: React.MutableRefObject<HTMLDivElement | null>;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  wrapperNotifiersRef: React.MutableRefObject<HTMLDivElement | null>;
}

const ChallengeQuizBattle = ({
  quiz,
  paused,
  wrapperRef,
  blinkElRef,
  reOpenChallenge,
  handlerWrapperRef,
  handlerNotifiersRef,
  wrapperNotifiersRef,
}: ChallengeQuizBattleProps) => {
  const dispatch = useDispatch();
  const {
    correct,
    flowInit,
    flowDone,
    scoreGained,
    flowFinished,
    levelPointGained,
  } = quiz;
  const cofCofCounterRef = useRef(0);
  const isLastAttentionWhoreRef = useRef(false);
  const swiperRef = useRef<SwiperProps | undefined>();
  const speechProgressRef = useRef<DotProgressRefProps>({ 0: true });
  const quizAnswersRef = useRef<ChallengeQuizAnswersHashProps>(
    getQuizAnswers()
  );
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const [correctHash, setCorrectHash] = useState<CorrectHashProps>({});
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);
  const [whores, setWhores] = useState<AttentionWhoreProps[][]>([]);
  const [areCorrectAnswers, setAreCorrectAnswers] = useState(false);
  const [customizeSpaceShip, setCustomizeSpaceShip] = useState(false);
  const [showZarvoxPresentation, setShowZarvoxPresentation] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleCorrectHash, [correct]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleStartFlow, [paused, flowInit]);

  function bootstrap() {
    if (!flowInit) return;
    displayWhores();
  }

  function handleStartFlow() {
    if (paused) return;
    if (flowInit) return;

    setTimeout(() => dispatch(challengeQuizActions.async.start()));
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;

    listenToSlideChange(swiperRef.current);
  }

  function handleCorrectHash() {
    if (!isEmpty(correctHash)) return;

    const hash = correct.reduce((acc, value: number) => {
      acc[value] = true;
      return acc;
    }, {} as CorrectHashProps);

    setCorrectHash(hash);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex, realIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
      updateRealSpeechPositionRef(realIndex);
    });
  }

  function go(index: number) {
    handleAnswerSelection(index);
    swiperRef.current?.slideTo(index);
  }

  function handleAnswerSelection(index: number) {
    if (index !== slideActiveIndex) return;

    quizAnswersRef.current = mapAnswer(index);

    if (hasActiveAnswer(quizAnswersRef.current))
      dispatch(challengeQuizActions.async.finish());
    else dispatch(challengeQuizActions.async.unFinish());
  }

  function mapAnswer(index: number): ChallengeQuizAnswersHashProps {
    const quizAnswers = quizAnswersRef.current;
    const toggle = !quizAnswers[index];

    return quiz.multi
      ? { ...quizAnswers, [index]: toggle }
      : { [index]: toggle };
  }

  function hasActiveAnswer(data: ChallengeQuizAnswersHashProps): boolean {
    return Object.values(data).some((answer) => answer);
  }

  function getQuizAnswers(): ChallengeQuizAnswersHashProps {
    const { answer } = quiz;
    return flowDone && answer ? answer : ({} as ChallengeQuizAnswersHashProps);
  }

  function callAttentionWhores() {
    if (!isLastAttentionWhoreRef.current) {
      cofCof();
      return;
    }

    isLastAttentionWhoreRef.current = false;
    cofCofCounterRef.current = 0;
    displayWhores();
  }

  function displayWhores() {
    setWhores([...whores, getWhores()]);
  }

  function getWhores(): AttentionWhoreProps[] {
    const len = quiz.question.length;
    return quiz.question.map((q, i) => message(q, i === len - 1));
  }

  function message(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapperRef.current) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreQuiz,
      call: onAttentionWhoreCall,
      bubbleType: BubbleType.Quiz,
      dropZoneBorderColor: "green",
      wrapperWidth: wrapperRef.current.clientWidth,
      wrapperHeight: wrapperRef.current.clientHeight,
    });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    if (attentionWhore.last) isLastAttentionWhoreRef.current = true;
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function showZarvox(): boolean {
    return !flowDone && !flowFinished && !paused && !showZarvoxPresentation;
  }

  function blink() {
    if (!blinkElRef.current) return;
    blinkUtils.blinkRed(blinkElRef.current);
  }

  function callZarvoxPresentation() {
    setShowZarvoxPresentation(true);
  }

  function finishZarvoxPresentation() {
    setShowZarvoxPresentation(false);
  }

  function getIconColor(index: number): string | undefined {
    if (flowDone) return getCorrectIconColorWhenDone(index);
    return getInitialIconColor(index);
  }

  function getCorrectIconColorWhenDone(index: number): string | undefined {
    return correctHash[index]
      ? SVG_GRADIENT_FLAG
      : quizAnswersRef.current[index]
      ? color.red
      : undefined;
  }

  function getInitialIconColor(index: number): string | undefined {
    return quizAnswersRef.current[index] ? color.white : undefined;
  }

  function updateRealSpeechPositionRef(index: number) {
    if (speechProgressRef.current[index]) return;
    speechProgressRef.current = { ...speechProgressRef.current, [index]: true };
  }

  function cofCof() {
    if (!wrapperRef.current) return;

    const { clientWidth, clientHeight } = wrapperRef.current;
    cofCofCounterRef.current += 1;

    alertAnimate({
      factories: [AlertFactory(getCofCof())],
      wrapper: wrapperRef.current as HTMLDivElement,
      bounds: {
        top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
      },
    });
  }

  function getCofCof(): CofCofProps {
    if (cofCofCounterRef.current <= 5) return getGoodCofCof();
    return geBadCofCof();
  }

  function getGoodCofCof(): CofCofProps {
    const random = [
      "???",
      "Sim?",
      "O pingente lhe espera...",
      "Eu sou apenas o apresentador...",
      "Milhões de ergonianos estão assistindo...",
      "Nosso programa de perguntas e respostas é famoso em todo o universo...",
    ];

    return {
      content: random[numberUtils.randomInterval(0, random.length - 1)],
      type: AlertFactoryType.Success,
    };
  }

  function geBadCofCof(): CofCofProps {
    const random = [
      "𐌄𐌍𐌃𐌇c𐌇𐌄!",
      "𐌃𐌋𐌔𐌅𐌃𐌇!!",
      "𐌇𐌁𐌋𐌆𐌃𐌇𐌄𐌋𐌆𐌄 𐌋𐌔𐌅 𐌃𐌇𐌁!!",
      "Você nunca será capaz de ganhar o pingente...",
      "Isso é o melhor que você pode fazer?",
      "tsc tsc tsc...",
    ];

    return {
      content: random[numberUtils.randomInterval(0, random.length - 1)],
      type: AlertFactoryType.Danger,
    };
  }

  function onSpaceshipDrop() {
    isCorrectAnswer() ? success() : failed();
  }

  function success() {
    setAreCorrectAnswers(true);

    setTimeout(() =>
      dispatch(
        challengeQuizActions.async.success({ answer: quizAnswersRef.current })
      )
    );
  }

  function failed() {
    blink();
    setAreCorrectAnswers(false);

    setTimeout(() =>
      dispatch(
        challengeQuizActions.async.failed({ answer: quizAnswersRef.current })
      )
    );
  }

  function isCorrectAnswer(): boolean {
    const quizAnswers = quizAnswersRef.current;
    let answers: ChallengeQuizAnswersHashProps = {};

    for (const key in quizAnswers) {
      if (quizAnswers[key]) answers[key] = true;
    }

    const c = correct.every((c) => {
      if (!answers[c]) return false;

      delete answers[c];
      return true;
    });

    return c && isEmpty(answers);
  }

  function restart() {
    setCustomizeSpaceShip(true);
  }

  function callSameChallenge() {
    reOpenChallenge(quiz);
  }

  return (
    <>
      <ProgressBar value={flowDone ? 1 : 0} color="success" />
      <Wrapper
        ref={handlerWrapperRef}
        className={`challenge_battle_wrapper ${
          !paused && !flowDone && "no-swipe"
        }`}
      >
        <div className="nerdfy_notifiers" ref={handlerNotifiersRef}></div>

        <DropZoneAttentionWhore />

        <ChallengeQuizStatusBar />
        <SpaceshipStatusBar challenge={quiz} />
        <ChallengeQuizSubStatusBar quiz={quiz} />

        {whores.map((whores, i: number) => (
          <AttentionWhores
            key={i}
            whores={whores}
            paused={paused}
            clear={flowDone}
            disabledDrop={!!attentionWhore.show}
          />
        ))}

        <div className="slides no-swipe">
          <div className="progressbar">
            <SlideDotsProgress
              len={quiz.answers.length}
              position={slideActiveIndex}
              dotsProgress={speechProgressRef.current}
            />
          </div>

          <Swiper
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            slidesPerView={1}
            initialSlide={0}
          >
            <>
              {quiz.answers.map((answer, index) => (
                <SwiperSlide key={index}>
                  <div className="slide">
                    <Board>
                      <p>{answer}</p>
                    </Board>
                  </div>
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        </div>

        <div className="buttons_wrapper">
          <ul>
            {quiz.answers.map((_, index) => {
              const iconColor = getIconColor(index);

              return (
                <li key={index}>
                  <div />

                  <ButtonDuolingoQuizAnswer
                    disabled={quiz.flowDone}
                    onClick={() => go(index)}
                    color={slideActiveIndex === index ? color.blue : undefined}
                  >
                    <div className="btn_wrapper">
                      <AvatarIconErgoPlaceholderIcon
                        width="30"
                        height="30"
                        gradient={iconColor === SVG_GRADIENT_FLAG}
                        color={
                          iconColor !== SVG_GRADIENT_FLAG
                            ? iconColor
                            : undefined
                        }
                      />
                      <span>{index + 1}</span>
                    </div>
                  </ButtonDuolingoQuizAnswer>
                </li>
              );
            })}
          </ul>
        </div>

        {/* **** GAME FLOW **** */}

        <ChallengeGameFlowHandler
          paused={paused}
          challenge={quiz}
          restart={restart}
        />

        {/* GAME FLOW END */}

        {/* **** HANDLERS **** */}

        {flowDone && (
          <ChallengeDoneButtonHandler callNextChallenge={reOpenChallenge} />
        )}

        {flowDone && flowFinished && (
          <>
            {areCorrectAnswers && <Confetti />}

            <VictoryNewsHandler
              challengeType={quiz.type}
              victoryPoints={[
                { points: 1, type: VictoryNewsPointType.Px },
                { points: scoreGained || 0, type: VictoryNewsPointType.Score },
              ]}
              victoryLevelPoint={{
                level: quiz.level,
                points: levelPointGained,
              }}
            />
          </>
        )}

        <BubbleHandler
          hide={hideJarvisBubble}
          show={!!attentionWhore.show}
          content={attentionWhore.payload}
          type={attentionWhore.bubbleType}
        />

        <AvatarButtonsHandler
          show={showZarvox()}
          onClick={callAttentionWhores}
          type={AvatarButtonType.Zarvox}
        />

        {flowInit && wrapperRef.current && (
          <ZarvoxPresentationHandler
            challengePaused={paused}
            wrapper={wrapperRef.current}
            challengeDone={flowDone && flowFinished}
            callPresentation={callZarvoxPresentation}
            finishPresentation={finishZarvoxPresentation}
          />
        )}

        <SpaceInvadersHandler
          challenge={quiz}
          wrapperRef={wrapperRef}
          onSpaceshipDrop={onSpaceshipDrop}
        />

        <ChallengeCommonHandlersHandler
          paused={paused}
          challenge={quiz}
          wrapper={wrapperRef.current}
          wrapperNotifiers={wrapperNotifiersRef.current}
        />

        <LevelUpHandler
          paused={paused}
          challenge={quiz}
          wrapper={wrapperRef.current}
        />

        {/* HANDLERS END */}

        <ChallengeOptionsFooter
          disabled={quiz.flowDone}
          wrapper={wrapperRef.current}
        />

        <SpaceshipResourcesStatusBar />
      </Wrapper>

      {customizeSpaceShip && (
        <SpaceshipCustomizationRestartHandler finish={callSameChallenge} />
      )}
    </>
  );
};

export default ChallengeQuizBattle;
