// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import styled from "styled-components";
import c from "styles/color";

const DuolingoBtn = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

interface ButtonDuolingoIconProps {
  children: React.ReactNode;
  onClick(): void;
  color?: string;
  disabled?: boolean;
  customClassName?: string;
  boxShadowColor?: string;
}

const ButtonDuolingoQuizAnswer = ({
  onClick,
  children,
  disabled,
  customClassName,
  color = c.grey.strong,
  boxShadowColor = c.grey.strong,
}: ButtonDuolingoIconProps) => {
  return (
    <DuolingoBtn
      onClick={onClick}
      className={customClassName}
      disabled={disabled}
      color={color}
      boxShadowColor={boxShadowColor}
    >
      {children}
    </DuolingoBtn>
  );
};

export default ButtonDuolingoQuizAnswer;
