// react
import { useState, useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// components
import Shop from "handlers/shop/Shop";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// icons
import IconWhoreMarket from "components/iconWhores/IconWhoreMarket";

// enums
import { BubbleType } from "enums/bubbleEnum";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface MarketHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

const ShopHandler = ({ paused, wrapper, challenge }: MarketHandlerProps) => {
  const dispatch = useDispatch();
  const { flowFinished, flowDone } = challenge;
  const [showMarket, setShowMarket] = useState(false);
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [flowFinished]);

  function bootstrap() {
    if (!flowFinished) return;
    displayWhore();
  }

  // ATTENTION WHORE

  function displayWhore() {
    if (!wrapper) return;

    const whore = attentionWhoreUtils.get({
      payload: "",
      WhoreEl: IconWhoreMarket,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      bubbleType: BubbleType.Market,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setWhores([whore]);
  }

  function onAttentionWhoreCall() {
    freeze();
    setShowMarket(true);
  }

  // ATTENTION WHORE END

  function unfreeze() {
    setTimeout(() => dispatch(challengeFlowActions.unfreeze()));
  }

  function freeze() {
    setTimeout(() => dispatch(challengeFlowActions.freeze()), 800);
  }

  return (
    <>
      <Shop wrapper={wrapper} show={showMarket} onClose={unfreeze} />
      <AttentionWhores paused={paused} clear={flowDone} whores={whores} />
    </>
  );
};

export default ShopHandler;
