// redux
import { useEffect } from "react";
import { collection } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

// redux
import { useDispatch } from "react-redux";
import { shopActions } from "redux/slices/shopSlice";

// interfaces
import { ShopQuestionProps } from "interfaces/shop";
import { ShopQuestionFirebaseProps } from "interfaces/shopFirebase";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// parsers
import shopItemQuestionParser from "parsers/shopQuestionParser";

// utils
import isEmpty from "lodash/isEmpty";

interface PlayerShopItemsFirebaseSyncProps {
  userId: number | string;
}

const PlayerShopQuestionsFirebaseSync = ({
  userId,
}: PlayerShopItemsFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useCollection(
    collection(
      FirebaseCoreService.getDatabase(),
      `players/${userId}/shopQuestions`
    )
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    const docs = snapshot?.docs;

    if (!docs) return;
    if (isEmpty(docs)) return;

    const bubbles = docs.reduce((acc, doc) => {
      const value = doc.data() as ShopQuestionFirebaseProps;

      acc.push(shopItemQuestionParser.mapAsRead(value));
      return acc;
    }, [] as ShopQuestionProps[]);

    dispatch(shopActions.updateShopQuestionsAsRead(bubbles));
  }

  return null;
};

export default PlayerShopQuestionsFirebaseSync;
