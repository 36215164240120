interface SvgProps {
  width?: string;
  height?: string;
}

const RavianTongueSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-24 0 511 511.99872"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m231.644531 332.964844c-26.074219 0-50.414062-3.273438-71.019531-8.933594-6.707031-1.84375-12.875 4.152344-11.335938 10.929688 8.996094 39.59375 42.445313 69.003906 82.355469 69.003906 39.910157 0 73.359375-29.410156 82.355469-69.003906 1.542969-6.777344-4.628906-12.773438-11.332031-10.929688-20.613281 5.660156-44.949219 8.933594-71.023438 8.933594zm0 0"
        fill="#8c2f3f"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <path
        d="m180.070312 375.183594 2.605469 49.519531c1.371094 26.046875 22.886719 46.460937 48.96875 46.460937s47.597657-20.414062 48.96875-46.460937l2.601563-49.519531c.71875-13.609375-12.152344-23.902344-25.265625-20.199219-10.121094 2.859375-20.574219 5.34375-26.304688 5.34375s-16.183593-2.484375-26.304687-5.34375c-13.117188-3.703125-25.984375 6.589844-25.269532 20.199219zm0 0"
        fill="#fd4755"
      />
      <path
        d="m239.371094 394.621094v-34.292969c0-.289063-.019532-.570313-.050782-.851563-3 .535157-5.640624.851563-7.675781.851563-2.035156 0-4.675781-.316406-7.675781-.851563-.03125.28125-.050781.5625-.050781.851563v34.292969c0 4.265625 3.460937 7.726562 7.726562 7.726562s7.726563-3.457031 7.726563-7.726562zm0 0"
        fill="#c41926"
      />
      <g fill="#353535">
        <path d="m336.472656 297.976562c-1.597656 0-3.210937-.492187-4.59375-1.519531l-33.398437-24.765625c-3.554688-2.632812-5.59375-6.683594-5.59375-11.105468 0-4.425782 2.039062-8.472657 5.59375-11.105469l33.398437-24.765625c3.425782-2.542969 8.265625-1.824219 10.804688 1.601562 2.542968 3.429688 1.824218 8.269532-1.601563 10.808594l-31.636719 23.460938 31.636719 23.457031c3.425781 2.542969 4.144531 7.382812 1.601563 10.808593-1.515625 2.046876-3.847656 3.125-6.210938 3.125zm0 0" />
        <path d="m126.816406 297.976562c-2.363281 0-4.695312-1.078124-6.214844-3.125-2.539062-3.425781-1.820312-8.265624 1.605469-10.808593l31.636719-23.457031-31.636719-23.460938c-3.425781-2.539062-4.144531-7.378906-1.605469-10.804688 2.542969-3.429687 7.382813-4.144531 10.808594-1.605468l33.398438 24.765625c3.554687 2.632812 5.59375 6.683593 5.59375 11.105469 0 4.425781-2.039063 8.472656-5.59375 11.109374l-33.398438 24.761719c-1.382812 1.027344-2.996094 1.519531-4.59375 1.519531zm28.789063-36.082031h.011719zm0 0" />
      </g>
    </svg>
  );
};

export default RavianTongueSvg;
