// redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import {
  ChallengeCodeProps,
  ChallengeCodeUpdateProps,
} from "interfaces/challengeCode";

const initialState: ChallengeCodeProps = {
  steps: [],
  verified: [],
  finished: [],
  additional: [],
};

export const challengeCodeSlice = createSlice({
  name: "challengeCode",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ChallengeCodeProps>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<ChallengeCodeUpdateProps>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
    clear() {
      return initialState;
    },
    clearVerified(state) {
      state.verified = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const challengeCodeActions = challengeCodeSlice.actions;
export default challengeCodeSlice.reducer;
