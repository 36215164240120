// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";

class PlayerFirebaseService {
  private _resource = "players";
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async get(userId: number | string): Promise<PlayerFirebaseProps> {
    const path = `${this._resource}/${userId}`;
    const response = await this._firebase.get(path);

    return response.data() as PlayerFirebaseProps;
  }

  async update(
    userId: number | string,
    values: PlayerFirebaseUpdateProps
  ): Promise<void> {
    if (!userId) return Promise.reject();
    return this._firebase.set(`${this._resource}/${userId}`, values);
  }
}

// private updateXp(xp: xpType) {
//   if (isEmpty(xp)) return;

//   const keys = Object.keys(xp);
//   if (isEmpty(keys)) return;

//   const currentGunId = keys[0];
//   if (!currentGunId) return;

//   const value = xp[currentGunId];
//   if (!value) return;

//   return { [currentGunId]: value };
// }

// let data: any = {
//   id: userId,
// };

// if (gunId) data.gunId = gunId;
// if (shopItems) data.shopItems = shopItems;
// if (storyCheckpoint) data.storyCheckpoint = storyCheckpoint;

// if (xp && !isEmpty(xp)) data.xp = this.updateXp(xp);

// if (px) data.px = increment(px);
// if (score) data.score = increment(score);
// if (hasMissed) data.missed = increment(1);
// if (hasBeenHelped) data.help = increment(1);
// if (hasBeenKilled) data.killed = increment(1);

export default PlayerFirebaseService;
