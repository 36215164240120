// icons
import StarIcon from "svg/StarSvg";

// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreStar = (_data: AttentionWhoreElProps) => {
  return <StarIcon width="35" height="35" />;
};

export default IconWhoreStar;
