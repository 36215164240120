// redux
import {
  AnyAction,
  ThunkDispatch,
  ListenerEffectAPI,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { invadersFriendlyActions } from "redux/slices/spaceInvaders/invadersFriendlySlice";

// utils
import { forever } from "async";
import { v4 as uuidv4 } from "uuid";
import numberUtils from "utils/numberUtils";
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

const INTERVAL_RARE = 10000;
const INTERVAL_OFTEN = 4000;

const invadersFriendlyMiddleware = createListenerMiddleware();
const paused: { [key: string]: boolean } = {};

invadersFriendlyMiddleware.startListening({
  actionCreator: invadersFriendlyActions.async.add,
  effect: async ({ payload }, listenerApi) => {
    const { isMad } = payload;

    const length = numberUtils.randomInterval(1, 6);
    const subtype = spaceInvaderUtils.getRandomInvaderSubtype();
    const Invader = spaceInvaderUtils.getEntity(subtype);
    const invader = new Invader({ x: 0, y: 0 }).toJson();

    listenerApi.dispatch(
      invadersFriendlyActions.add({ ...invader, isMad, length })
    );
  },
});

invadersFriendlyMiddleware.startListening({
  actionCreator: invadersFriendlyActions.async.random,
  effect: async (_, listenerApi) => {
    const processId = uuidv4();
    paused[processId] = false;

    start(listenerApi, processId);
  },
});

invadersFriendlyMiddleware.startListening({
  actionCreator: invadersFriendlyActions.async.stopForever,
  effect: async () => {
    // kill all previous processes
    Object.keys(paused).forEach((key) => (paused[key] = true));
  },
});

// private

function start(
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >,
  processId: string
) {
  forever(
    (next: () => void) => {
      if (paused[processId]) return;
      if (numberUtils.randomInterval(1, 10) > 5)
        return setTimeout(next, INTERVAL_OFTEN);

      listenerApi.dispatch(invadersFriendlyActions.async.add());
      setTimeout(next, INTERVAL_RARE);
    },
    (_err: unknown) => {}
  );
}

export default invadersFriendlyMiddleware;
