// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// icons
import ThunderIcon from "icons/ThunderIcon";

// utils
import color from "styles/color";

const IconWhoreGod = ({ active }: AttentionWhoreElProps) => {
  return (
    <ThunderIcon
      width="35"
      height="35"
      color={active ? color.purple : undefined}
    />
  );
};

export default IconWhoreGod;
