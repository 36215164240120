// react
import React, { useState } from "react";

// components
import Loading from "components/Loading";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.5em;
    margin: 20px 10px;
    text-align: center;
    color: ${color.grey.middle};
  }

  .try_again_button {
    padding: 15px;
    font-size: 1.5em;
  }
`;

interface ExceptionErrorProps {
  message?: string;
  tryAgain?: boolean;
}

const ExceptionError = ({
  tryAgain = true,
  message = "Ops, ocorreu um erro... Um nerd já foi avisado!",
}: ExceptionErrorProps) => {
  const [loading, setLoading] = useState(false);

  function reload() {
    setLoading(true);
    setTimeout(() => window.location.reload(), 300);
  }

  return (
    <Wrapper className="pixelify_font_family">
      <Loading loading={loading} absolute />

      {message && <p>{message}</p>}

      {tryAgain && (
        <ButtonRippleEffect
          onClick={reload}
          color={color.blue}
          className="try_again_button"
        >
          Tentar novamente
        </ButtonRippleEffect>
      )}
    </Wrapper>
  );
};

export default ExceptionError;
