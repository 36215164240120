// components
import AvatarIconErgoPlaceholder from "components/avatarIcons/placeholders/AvatarIconErgoPlaceholder";

// utils
import styled from "styled-components";

const StatusBarWrapper = styled.div`
  height: 45px;

  > ul {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: center;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
    }
  }
`;

const ChallengeQuizStatusBar = () => {
  return (
    <StatusBarWrapper>
      <ul>
        <li>
          <AvatarIconErgoPlaceholder />
        </li>
      </ul>
    </StatusBarWrapper>
  );
};

export default ChallengeQuizStatusBar;
