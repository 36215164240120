interface SvgProps {
  width?: string;
  height?: string;
}

const SunPixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M451.76 210.82h-30.11v-60.23h-30.12v-30.12h-30.12V90.35h-60.23V60.24h-30.12L256 53.591l-15.06 6.649h-30.12v30.11h-60.23v30.12h-30.12v30.12H90.35v60.23H60.24v90.36h30.11v60.23h30.12v30.12h30.12v30.12h60.23v30.11h30.12l15.06 8.403 15.06-8.403h30.12v-30.11h60.23v-30.12h30.12v-30.12h30.12v-60.23h30.11v-30.12l8.076-15.06-8.076-15.06zM60.24 240.94 51.653 256l8.587 15.06"
          fill="#000000"
          opacity="1"
          data-original="#000000"
        ></path>
        <g fill="#ff9e00">
          <path
            d="M451.76 331.29h30.12v30.12h-30.12zM451.76 150.59h30.12v30.12h-30.12zM331.29 451.76h30.12v30.12h-30.12zM331.29 30.12h30.12v30.12h-30.12z"
            fill="#ff9e00"
            opacity="1"
            data-original="#ff9e00"
          ></path>
        </g>
        <path
          fill="#fadf00"
          d="M391.53 210.82v-60.23h-30.12v-30.12h-60.23V90.35h-90.36v30.12h-60.23v30.12h-30.12v60.23H90.35v90.36h30.12v60.23h30.12v30.12h60.23v30.12h90.36v-30.12h60.23v-30.12h30.12v-60.23h30.12v-90.36zm-60.24 90.36v30.11h-30.11v30.12h-90.36v-30.12h-30.11v-30.11h-30.12v-90.36h30.12v-30.11h30.11v-30.12h90.36v30.12h30.11v30.11h30.12v90.36z"
          opacity="1"
          data-original="#fadf00"
        ></path>
        <path
          fill="#ff9e00"
          d="M150.59 451.76h30.12v30.12h-30.12zM150.59 30.12h30.12v30.12h-30.12zM30.12 331.29h30.12v30.12H30.12zM30.12 150.59h30.12v30.12H30.12z"
          opacity="1"
          data-original="#ff9e00"
        ></path>
        <path
          fill="#e46705"
          d="M451.76 240.94H512v30.12h-60.24zM421.65 421.65h30.11v30.11h-30.11zM421.65 60.24h30.11v30.11h-30.11zM391.53 391.53h30.12v30.12h-30.12zM391.53 90.35h30.12v30.12h-30.12z"
          opacity="1"
          data-original="#e46705"
        ></path>
        <path
          fill="#ff9e00"
          d="M301.18 210.82v-30.11h-90.36v30.11h-30.11v90.36h30.11v30.11h90.36v-30.11h30.11v-90.36z"
          opacity="1"
          data-original="#ff9e00"
        ></path>
        <g fill="#e46705">
          <path
            d="M240.94 451.76h30.12V512h-30.12zM240.94 0h30.12v60.24h-30.12zM90.35 391.53h30.12v30.12H90.35zM90.35 90.35h30.12v30.12H90.35zM60.24 421.65h30.11v30.11H60.24zM60.24 60.24h30.11v30.11H60.24zM0 240.94h60.24v30.12H0z"
            fill="#e46705"
            opacity="1"
            data-original="#e46705"
          ></path>
        </g>
        <g fill="#ff9e00">
          <path
            d="M391.53 210.82h30.12v90.36h-30.12zM361.41 301.18h30.12v60.23h-30.12zM361.41 150.59h30.12v60.23h-30.12zM301.18 120.47h60.23v30.12h-60.23zM301.18 361.41h60.23v30.12h-60.23zM271.06 391.53h30.12v30.12h-30.12zM271.06 90.35h30.12v30.12h-30.12z"
            fill="#ff9e00"
            opacity="1"
            data-original="#ff9e00"
          ></path>
        </g>
        <g fill="#e46705">
          <path
            d="M301.18 210.82h30.11v90.36h-30.11zM271.06 301.18h30.12v30.11h-30.12zM271.06 180.71h30.12v30.11h-30.12z"
            fill="#e46705"
            opacity="1"
            data-original="#e46705"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default SunPixelSvg;
