// interfaces
import { ItemProps } from "interfaces/item";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

interface DiscoveryValueProps {
  id: string;
}

interface InputProps extends ItemProps {
  labelShadow: JSX.Element;
}

export default abstract class AbstractItemEntity implements ItemProps {
  readonly index: number;
  readonly label: JSX.Element;
  readonly labelShadow: JSX.Element;
  readonly checkpointIndex: PlayerCheckpointEnum;

  constructor({ index, checkpointIndex, label, labelShadow }: InputProps) {
    this.index = index;
    this.label = label;
    this.labelShadow = labelShadow;
    this.checkpointIndex = checkpointIndex;
  }

  get item(): ItemProps {
    return {
      index: this.index,
      label: this.label,
      checkpointIndex: this.checkpointIndex,
    };
  }

  get shadow(): ItemProps {
    return {
      index: this.index,
      label: this.labelShadow,
      checkpointIndex: this.checkpointIndex,
    };
  }

  get discoveryValue(): DiscoveryValueProps {
    return {
      id: String(this.checkpointIndex),
    };
  }
}
