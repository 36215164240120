// components
import BrandLogo from "components/BrandLogo";
import CloseButton from "components/CloseButton";
import ButtonDuolingoSave from "components/buttons/ButtonDuolingoSave";
import ButtonDuolingoSend from "components/buttons/ButtonDuolingoSend";
import ButtonDuolingoGeneric from "components/buttons/ButtonDuolingoGeneric";
import ButtonDuolingoContinue from "components/buttons/ButtonDuolingoContinue";
import ButtonDuolingoArrowIcon from "components/buttons/ButtonDuolingoArrowIcon";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 0 20px;
  height: 80px;
  background: ${color.dark.bg};
  border-bottom: 5px solid ${color.grey.dark};

  .button_z {
    z-index: 101;
  }
`;

interface FormHeaderProps {
  save?(): void;
  back?(): void;
  send?(): void;
  close?(): void;
  generic?(): void;
  forward?(): void;
  proceed?(): void;
  brand?: boolean;
  disabled?: boolean;
  hideBrand?: boolean;
  genericLabel?: string;
  genericColor?: string;
  disabledBack?: boolean;
  disabledClose?: boolean;
  customClasseName?: string;
}

const FormHeader = ({
  brand,
  close,
  save,
  back,
  send,
  forward,
  proceed,
  generic,
  disabled,
  hideBrand,
  disabledBack,
  genericColor,
  genericLabel,
  disabledClose,
  customClasseName,
}: FormHeaderProps) => {
  return (
    <Wrapper className={`safe--area ${customClasseName}`}>
      {brand && <BrandLogo />}
      {hideBrand && <div />}

      {back && (
        <ButtonDuolingoArrowIcon
          backward
          onClick={back}
          disabled={disabledBack}
          customClassName="button_z"
        />
      )}

      {forward && (
        <ButtonDuolingoArrowIcon onClick={forward} customClassName="button_z" />
      )}

      {close && (
        <CloseButton
          onClick={close}
          disabled={disabledClose}
          className="button button_z"
        />
      )}

      {save && (
        <ButtonDuolingoSave
          onClick={save}
          disabled={disabled}
          customClassName="button_z"
        />
      )}

      {send && (
        <ButtonDuolingoSend
          onClick={send}
          disabled={disabled}
          customClassName="button_z"
        />
      )}

      {generic && genericLabel && (
        <ButtonDuolingoGeneric
          onClick={generic}
          disabled={disabled}
          label={genericLabel}
          color={genericColor}
          customClassName="button_z"
        />
      )}

      {proceed && (
        <ButtonDuolingoContinue
          onClick={proceed}
          disabled={disabled}
          customClassName="button_z"
        />
      )}
    </Wrapper>
  );
};

interface FormHeaderWrapperProps {
  children: React.ReactNode;
}

export const FormHeaderWrapper = ({ children }: FormHeaderWrapperProps) => {
  <Wrapper className="safe--area">{children}</Wrapper>;
};

export default FormHeader;
