// enums
import { UserGenreType } from "enums/userEnum";

export interface PlayerDegreeValueProps {
  name: string;
  checkpoint: number;
  lightSide: boolean;
}

export interface PlayerDegreesProps {
  [key: number]: {
    [key: string]: PlayerDegreeValueProps;
  };
}

export enum PlayerDegreesLightSideCheckpoint {
  Zero = 0,
  One = 5,
  Two = 15,
  Three = 20,
  Four = 35,
  Five = 55,
  Six = 90,
  Seven = 105,
  Eight = 195,
  Nine = 300,
  Ten = 495,
}

export enum PlayerDegreesDarkSideCheckpoint {
  Zero = -1,
  One = -5,
  Two = -15,
  Three = -20,
  Four = -35,
  Five = -55,
  Six = -90,
  Seven = -105,
  Eight = -195,
  Nine = -300,
  Ten = -495,
}

const LS = PlayerDegreesLightSideCheckpoint;
const DS = PlayerDegreesDarkSideCheckpoint;
let lightSide = true;

export const PLAYER_DEGREES_LIGHT_SIDE: PlayerDegreesProps = {
  [UserGenreType.Male]: {
    zero: { checkpoint: LS.Zero, name: "aventureiro", lightSide },
    one: { checkpoint: LS.One, name: "ás em formação", lightSide },
    two: { checkpoint: LS.Two, name: "lobo solitário", lightSide },
    three: { checkpoint: LS.Three, name: "caçador de recompensas", lightSide },
    four: { checkpoint: LS.Four, name: "lobo de aço", lightSide },
    five: { checkpoint: LS.Five, name: "ás intergalático", lightSide },
    six: { checkpoint: LS.Six, name: "olhos de águia", lightSide },
    seven: { checkpoint: LS.Seven, name: "relâmpago de fogo", lightSide },
    eight: { checkpoint: LS.Eight, name: "vingador da luz", lightSide },
    nine: { checkpoint: LS.Nine, name: "destruidor da escuridão", lightSide },
    ten: { checkpoint: LS.Ten, name: "mestre", lightSide },
  },
  [UserGenreType.Female]: {
    zero: { checkpoint: LS.Zero, name: "aventureira", lightSide },
    one: { checkpoint: LS.One, name: "ás em formação", lightSide },
    two: { checkpoint: LS.Two, name: "loba solitária", lightSide },
    three: { checkpoint: LS.Three, name: "caçadora de recompensas", lightSide },
    four: { checkpoint: LS.Four, name: "loba de aço", lightSide },
    five: { checkpoint: LS.Five, name: "ás intergalático", lightSide },
    six: { checkpoint: LS.Six, name: "olhos de águia", lightSide },
    seven: { checkpoint: LS.Seven, name: "relâmpago de fogo", lightSide },
    eight: { checkpoint: LS.Eight, name: "vingadora da luz", lightSide },
    nine: { checkpoint: LS.Nine, name: "destruidora da escuridão", lightSide },
    ten: { checkpoint: LS.Ten, name: "mestra", lightSide },
  },
};

lightSide = false;
export const PLAYER_DEGREES_DARK_SIDE: PlayerDegreesProps = {
  [UserGenreType.Male]: {
    zero: { checkpoint: DS.Zero, name: "mercenário", lightSide },
    one: { checkpoint: DS.One, name: "mal amado", lightSide },
    two: { checkpoint: DS.Two, name: "degenerado", lightSide },
    three: { checkpoint: DS.Three, name: "carrasco", lightSide },
    four: { checkpoint: DS.Four, name: "mente corrompida", lightSide },
    five: { checkpoint: DS.Five, name: "fera sanguinária", lightSide },
    six: { checkpoint: DS.Six, name: "sombra do caos", lightSide },
    seven: { checkpoint: DS.Seven, name: "devorador de sonhos", lightSide },
    eight: { checkpoint: DS.Eight, name: "senhor das trevas", lightSide },
    nine: { checkpoint: DS.Nine, name: "ceifador obscuro", lightSide },
    ten: { checkpoint: DS.Ten, name: "infernalista", lightSide },
  },
  [UserGenreType.Female]: {
    zero: { checkpoint: DS.Zero, name: "mercenária", lightSide },
    one: { checkpoint: DS.One, name: "mal amada", lightSide },
    two: { checkpoint: DS.Two, name: "degenerada", lightSide },
    three: { checkpoint: DS.Three, name: "serpente ardilosa", lightSide },
    four: { checkpoint: DS.Four, name: "bruxa maligna", lightSide },
    five: { checkpoint: DS.Five, name: "sombra do caos", lightSide },
    six: { checkpoint: DS.Six, name: "devoradora de sonhos", lightSide },
    seven: { checkpoint: DS.Seven, name: "senhora do abismo", lightSide },
    eight: { checkpoint: DS.Eight, name: "madastra dos infernos", lightSide },
    nine: { checkpoint: DS.Nine, name: "rainha das trevas", lightSide },
    ten: { checkpoint: DS.Ten, name: "infernalista", lightSide },
  },
};
