// react
import React from "react";

// components
import AvatarInvaderOne from "components/avatars/invaders/AvatarInvaderOne";
import AvatarInvaderTwo from "components/avatars/invaders/AvatarInvaderTwo";
import AvatarInvaderThree from "components/avatars/invaders/AvatarInvaderThree";
import AvatarInvaderFour from "components/avatars/invaders/AvatarInvaderFour";
import AvatarInvaderFive from "components/avatars/invaders/AvatarInvaderFive";
import AvatarInvaderSix from "components/avatars/invaders/AvatarInvaderSix";
import AvatarInvaderSeven from "components/avatars/invaders/AvatarInvaderSeven";

// enums
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

// utils
import color from "styles/color";

export const defaultColor = color.grey.strong;
export const defaultWidth = "28";
export const defaultHeight = "28";

export interface AvatarInvadersProps {
  width?: string;
  height?: string;
  gradient?: boolean;
}

interface AvatarInvaderHandlerProps {
  subtype: FlyingObjectSubType;
  gradient?: boolean;
}

const AvatarInvaderHandler = React.memo(
  ({ subtype, gradient }: AvatarInvaderHandlerProps) => {
    return (
      <>
        {subtype === FlyingObjectSubType.InvaderOne && (
          <AvatarInvaderOne gradient={gradient} />
        )}

        {subtype === FlyingObjectSubType.InvaderTwo && (
          <AvatarInvaderTwo gradient={gradient} />
        )}

        {subtype === FlyingObjectSubType.InvaderThree && (
          <AvatarInvaderThree gradient={gradient} />
        )}

        {subtype === FlyingObjectSubType.InvaderFour && (
          <AvatarInvaderFour gradient={gradient} />
        )}

        {subtype === FlyingObjectSubType.InvaderFive && (
          <AvatarInvaderFive gradient={gradient} />
        )}

        {subtype === FlyingObjectSubType.InvaderSix && (
          <AvatarInvaderSix gradient={gradient} />
        )}

        {subtype === FlyingObjectSubType.InvaderSeven && (
          <AvatarInvaderSeven gradient={gradient} />
        )}
      </>
    );
  }
);

export default AvatarInvaderHandler;
