// components
import PressButton from "components/pressButtons/PressButton";

// utils
import color from "styles/color";

interface PressButtonProps {
  onClick: () => void;
  description: JSX.Element;
  goTo?: JSX.Element;
}

const PressButtonStart = ({ onClick, description, goTo }: PressButtonProps) => {
  return (
    <PressButton
      goTo={goTo}
      onClick={onClick}
      color={color.blue}
      description={description}
      title="Aperte para Iniciar"
    />
  );
};

export default PressButtonStart;
