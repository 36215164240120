// interfaces
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";

// entities
import LaseItemEntity from "entities/shopItems/LaseItemEntity";
import EncyclopediaItemEntity from "entities/shopItems/EncyclopediaItemEntity";
import RefineMachineItemEntity from "entities/shopItems/RefineMachineItemEntity";
import MineralStorageItemEntity from "entities/shopItems/MineralStorageItemEntity";

interface InitialItemsProps {
  gunId: string;
  items: PlayerShopItemsFirebaseProps;
}

class SpaceshipItemsEntity {
  get initialItems(): InitialItemsProps {
    const laserItem = new LaseItemEntity().values;
    const encyclopediaItem = new EncyclopediaItemEntity().values;
    const refineMachineItem = new RefineMachineItemEntity().values;
    const mineralStorageItem = new MineralStorageItemEntity().values;

    return {
      items: {
        ...laserItem,
        ...encyclopediaItem,
        ...refineMachineItem,
        ...mineralStorageItem,
      },
      gunId: this.getGunId(laserItem),
    };
  }

  private getGunId(gunItem: PlayerShopItemsFirebaseProps): string {
    return Object.keys(gunItem)[0];
  }
}

export default SpaceshipItemsEntity;
