import { Device, DeviceInfo } from "@capacitor/device";

let d: DeviceInfo;

async function isApp(): Promise<boolean> {
  let device: DeviceInfo;

  if (d) device = d;
  else device = await getInfo();

  const { platform } = device;
  return platform === "ios" || platform === "android";
}

async function getInfo(): Promise<DeviceInfo> {
  d = await Device.getInfo();
  return d;
}

const deviceUtils = {
  isApp,
};

export default deviceUtils;
