// components
import WizardHatPixelIcon from "icons/WizardHatPixelIcon";

// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// utils
import color from "styles/color";

const IconWhoreWizardHat = ({ active }: AttentionWhoreElProps) => {
  return (
    <WizardHatPixelIcon
      width="35"
      height="35"
      color={active ? color.blue : undefined}
    />
  );
};

export default IconWhoreWizardHat;
