// enums
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

export const SpaceshipFactory = (type: SpaceshipColorType) => {
  const spaceShipElRef = document.createElement("div");

  spaceShipElRef.className = `${getColorClassName(
    type
  )} animate__animated animate__bounceInUp space_invaders_spaceship spaceship_drop spaceship_draggable display_none bg_size_42`;

  spaceShipElRef.addEventListener("animationend", onAnimationEnd, false);

  function onAnimationEnd() {
    if (!spaceShipElRef) return;

    spaceShipElRef.classList.remove("animate__animated");
    spaceShipElRef.classList.remove("animate__bounceInUp");
    spaceShipElRef.classList.add("animate_y_infinite");

    spaceShipElRef.removeEventListener("animationend", onAnimationEnd, false);
  }

  return spaceShipElRef;
};

// private

function getColorClassName(type: SpaceshipColorType): string {
  if (type === SpaceshipColorType.Red) return "bg_spaceship_red";
  if (type === SpaceshipColorType.Blue) return "bg_spaceship_blue";
  if (type === SpaceshipColorType.Green) return "bg_spaceship_green";
  if (type === SpaceshipColorType.Yellow) return "bg_spaceship_yellow";
  if (type === SpaceshipColorType.Orange) return "bg_spaceship_orange";
  if (type === SpaceshipColorType.Purple) return "bg_spaceship_purple";

  return "bg_spaceship_purple";
}
