// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";
import Icon, { faMinus } from "components/Icon";

// utils
import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${color.red};
`;

interface ButtonDuolingoIconProps {
  onClick(): void;
  customClassName?: string;
  disabled?: boolean;
}

const ButtonDuolingoMinusIcon = ({
  onClick,
  disabled,
  customClassName,
}: ButtonDuolingoIconProps) => {
  return (
    <Wrapper onClick={onClick} className={customClassName} disabled={disabled}>
      <Icon icon={faMinus} />
    </Wrapper>
  );
};

export default ButtonDuolingoMinusIcon;
