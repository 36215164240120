// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

interface ButtonDuolingoIconProps {
  onClick(): void;
  customClassName?: string;
  disabled?: boolean;
}

const ButtonDuolingoSave = ({
  onClick,
  disabled,
  customClassName,
}: ButtonDuolingoIconProps) => {
  return (
    <Wrapper
      bg={color.blue}
      onClick={onClick}
      disabled={disabled}
      color={color.white}
      boxShadowColor="#256bb2"
      className={customClassName}
    >
      Salvar
    </Wrapper>
  );
};

export default ButtonDuolingoSave;
