// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import {
  ShopItemProps,
  ShopStateProps,
  ShopQuestionProps,
  ShopItemBubbleProps,
} from "interfaces/shop";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// entities
import RepairItemEntity from "entities/shopItems/RepairItemEntity";
import Printer2dItemEntity from "entities/shopItems/Printer2dItemEntity";
import AmateurRadioItemEntity from "entities/shopItems/AmateurRadioItemEntity";
// import RadioStationItemEntity from "entities/shopItems/RadioStationItemEntity";
import RefineMachineItemEntity from "entities/shopItems/RefineMachineItemEntity";
import MineralStorageItemEntity from "entities/shopItems/MineralStorageItemEntity";

const addShopItemAsRead = createAction(
  "@async/shop/shop/item/read",
  (id: number) => ({
    payload: {
      id,
    },
  })
);

const addShopQuestionAsRead = createAction(
  "@async/shop/shop/question/read",
  (id: number, itemId: number) => ({
    payload: {
      id,
      itemId,
    },
  })
);

const buy = createAction(
  "@async/shop/items/buy",
  (type: SpaceshipItemEnum) => ({
    payload: {
      type,
    },
  })
);

export const initialState: ShopStateProps = {
  items: getItems(),
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<ShopItemProps[]>) => {
      state.items = action.payload;
    },
    updateShopItemsAsRead: (
      state,
      action: PayloadAction<{ id: number; read: boolean }[]>
    ) => {
      state.items = updateShopItemsAsRead(state.items, action.payload);
    },
    updateShopQuestionsAsRead: (
      state,
      action: PayloadAction<ShopQuestionProps[]>
    ) => {
      state.items = updateShopQuestionsAsRead(state.items, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

function getItems(): ShopItemProps[] {
  const repair = new RepairItemEntity();
  // const radio = new RadioStationItemEntity();
  const printer2d = new Printer2dItemEntity();
  const amateurRadio = new AmateurRadioItemEntity();
  const refineMachine = new RefineMachineItemEntity();
  const mineralStorage = new MineralStorageItemEntity();

  return [
    repair.item,
    printer2d.item,
    amateurRadio.item,
    refineMachine.item,
    mineralStorage.item,
  ];
}

function updateShopItemsAsRead(
  stateItems: ShopItemProps[],
  itemsUpdated: { id: number; read: boolean }[]
): ShopItemProps[] {
  return stateItems.map((item: ShopItemProps) => {
    const itemUpdated = itemsUpdated.find((i) => i.id === item.id);

    if (!itemUpdated) return item;
    return { ...item, read: !!itemUpdated?.read };
  });
}

function updateShopQuestionsAsRead(
  stateItems: ShopItemProps[],
  questions: ShopQuestionProps[]
): ShopItemProps[] {
  return stateItems.map((item) => {
    const bubbles: ShopItemBubbleProps[] = item.bubbles.map(
      (bubble: ShopItemBubbleProps) => {
        const bubbleUpdated = questions.find(
          (b) => b.id === bubble.id && b.itemId === item.id
        );

        if (!bubbleUpdated) return bubble;
        return { ...bubble, read: !!bubbleUpdated?.read };
      }
    );

    return {
      ...item,
      bubbles,
    };
  });
}

export const shopActions = {
  async: {
    buy,
    addShopItemAsRead,
    addShopQuestionAsRead,
  },
  ...shopSlice.actions,
};

export default shopSlice.reducer;
