// react
import { useState } from "react";

// ionic-react
import { IonItem, IonLabel, IonTextarea } from "@ionic/react";

// entities
import WalkieTalkieBroadCastEntity from "entities/WalkieTalkieBroadCastEntity";

// components
import Board from "components/Board";
import Footer from "components/Footer";
import Loading from "components/Loading";
import Confetti from "components/Celebration";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import SuccessFeedback from "components/feedbacks/SuccessFeedback";
import { RatingWrapper } from "handlers/feedback/FeedbackClassRoomRatingPresentation";

interface ChallengeRatingProps {
  onClose(): void;
  submitMessage(feedback: string): void;
}

const AmateurRadioMessageHandler = ({
  onClose,
  submitMessage,
}: ChallengeRatingProps) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [wordsCounter, setWordsCounter] = useState(0);
  const [animation, setAnimation] = useState("animate__bounceInUp");

  function save() {
    if (!message) return setError("O que você achou do desafio?");

    setLoading(true);

    try {
      submitMessage(message);

      setError("");
      setSuccess("Sua mensagem foi enviada com sucesso!");

      setTimeout(close, 1000);
    } catch (_err) {
      setError("Ops, houve algum erro ao tentar salvar seu comentário.");
      setLoading(false);
    }
  }

  function onMessageChange(e: any) {
    const { value } = e.target;
    if (value.length > WalkieTalkieBroadCastEntity.maxWords) return;

    setMessage(value);
    setWordsCounter(value.length);
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLElement>) {
    e.stopPropagation();
    if (e.animationName === "bounceOutLeft") onClose();
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
  }

  return (
    <RatingWrapper
      className={`animate__animated no-swipe ${animation}`}
      onAnimationEnd={onAnimationEnd}
    >
      <Loading loading={loading} />
      <FormHeader send={save} close={close} disabled={!message || loading} />

      <div className="body">
        <Board>
          <p>
            <span className="orange">Encoraje</span> outros{" "}
            <span className="brown">primatas</span> enviando uma{" "}
            <span className="white">mensagem</span> ao{" "}
            <span className="blue">vazio</span> do{" "}
            <span className="purple">espaço</span>... Quem sabe{" "}
            <span className="yellow">alguém</span> possa{" "}
            <span className="red">ler</span> e{" "}
            <span className="green">responder</span>...
          </p>
        </Board>

        <div className="feedback_wrapper">
          <IonItem className="feedback_item">
            <IonLabel position="floating" className="feedback_label">
              Mensagem...
            </IonLabel>

            <IonTextarea
              rows={1}
              autoGrow
              mode="ios"
              value={message}
              inputMode="text"
              onIonChange={onMessageChange}
              className="feedback_textarea"
              maxlength={WalkieTalkieBroadCastEntity.maxWords}
            ></IonTextarea>
          </IonItem>

          <div className="feedback_counter">
            <p>
              {wordsCounter} / {WalkieTalkieBroadCastEntity.maxWords}
            </p>
          </div>
        </div>

        {!!error && <ErrorFeedback err={error} />}
        {!!success && <SuccessFeedback err={success} />}
      </div>

      {!!success && <Confetti />}

      <Footer />
    </RatingWrapper>
  );
};

export default AmateurRadioMessageHandler;
