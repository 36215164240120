// components
import {
  defaultColor,
  defaultWidth,
  defaultHeight,
  AvatarInvadersProps,
} from "handlers/AvatarInvaderHandler";

// components
import LinearGradientEffectSvg from "svg/LinearGradientEffectSvg";

// utils
import { v4 as uuidv4 } from "uuid";

const AvatarInvaderFour = ({
  gradient = false,
  width = defaultWidth,
  height = defaultHeight,
}: AvatarInvadersProps) => {
  const id = uuidv4();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 575.279 575.279"
    >
      {gradient && (
        <defs>
          <LinearGradientEffectSvg id={id} />
        </defs>
      )}

      <g>
        <path
          d="M568.743 189.72h-35.318c-3.378 0-7.105-3.244-7.105-6.628v-38.378c0-3.372-1.756-3.954-5.134-3.954h-130.87c-3.385 0-4.756-4.902-4.756-8.28V97.626c0-3.384-4.101-5.826-7.484-5.826H196.465c-3.378 0-6.744 2.43-6.744 5.814v35.166c0 3.378-2.111 7.98-5.49 7.98H53.116c-3.384 0-4.155.869-4.155 4.253v38.501c0 3.378-4.712 6.206-8.091 6.206H6.537c-3.378 0-6.536 2.65-6.536 6.028v136.207c0 3.384 3.151 4.645 6.53 4.645h82.051c3.378 0 3.219 4.211 3.219 7.589v35.545c0 3.385.159 5.826-3.219 5.826H54.01c-3.378 0-5.049 3.042-5.049 6.427v38.507c0 3.378 1.67 4.026 5.055 4.026h35.123c3.384 0 8.782 4.841 8.782 8.22v34.921c0 3.378.085 5.819 3.464 5.819h82.442c3.378 0 5.894-2.436 5.894-5.813v-35.606c0-3.384-2.503-7.54-5.887-7.54h-34.358c-3.378 0-8.715-1.328-8.715-4.706v-37.699c0-3.378 4.033-6.113 7.417-6.113 28.978 0 58.158-.007 86.696.042l2.191-.049c1.028-.03 1.708-.648 1.744-1.389.037-.741-.128-1.341-.128-1.341v-38.85c0-3.385 3.446-7.815 6.83-7.815h84.615c3.378 0 6.475 4.438 6.475 7.815v35.331s-.845 5.813 6.273 5.813h82.43c3.379 0 3.097 3.666 3.097 7.051v37.785c0 3.378.288 4.124-3.09 4.124h-34.518c-3.384 0-5.232 4.731-5.232 8.115v34.859c0 3.385 1.849 5.985 5.227 5.985h83.281c3.384 0 3.292-2.601 3.292-5.979v-35.202c0-3.379 5.569-7.779 8.948-7.779h34.663c3.379 0 5.349-1.083 5.349-4.461v-38.14c0-3.385-1.964-6.359-5.349-6.359h-34.608c-3.378 0-9.003-2.509-9.003-5.887v-35.436c0-3.384 5.618-7.638 9.003-7.638h82.381c3.379 0 6.536-1.229 6.536-4.608V195.331c-.002-3.377-3.159-5.611-6.538-5.611zM238.68 281.085c0 3.378-3.586 6.554-6.971 6.554h-82.148c-3.378 0-8.801-3.176-8.801-6.561V246.28c0-3.378 5.422-7.601 8.801-7.601h82.148c3.384 0 6.971 4.217 6.971 7.595v34.811zm195.84.098c0 3.378-5.74 6.457-9.118 6.457h-81.966c-3.378 0-6.836-3.078-6.836-6.462v-34.847c0-3.378 3.464-7.65 6.849-7.65h81.953c3.378 0 9.118 4.278 9.118 7.656v34.846z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M332.353 385.866c-1.31 0-58.152-.031-85.337.043l-3.88.018c-4.07 0-3.886 2.754-3.88 6.133l.019 37.074c0 3.379 2.742 5.386 6.12 5.386h85.239c3.385 0 5.967-1.278 5.967-4.657v-37.252c-.001-7.687-4.248-6.745-4.248-6.745z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default AvatarInvaderFour;
