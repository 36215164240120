// react
import React from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import SubHeaderWrapper from "components/header/SubHeaderWrapper";

// icons
import LaserPixelIcon from "icons/LaserPixelIcon";

// entities
import JarvisLevelEntity from "entities/JarvisLevelEntity";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    all: unset;
    font-size: 20px;
    color: ${color.grey.light};

    &.level_wrapper {
      margin-left: 10px;

      .level {
        display: inline-block;
        color: ${color.yellow};
        font-weight: bold;
      }
    }
  }
`;

function areEqual(prev: JarvisStatusProps, next: JarvisStatusProps): boolean {
  return prev.xp === next.xp;
}

interface JarvisStatusProps {
  xp: number;
}

const JarvisStatus = React.memo(({ xp }: JarvisStatusProps) => {
  function getXp(): number {
    return new JarvisLevelEntity({ xp }).next().checkpoint;
  }

  function getLabel(): string {
    return new JarvisLevelEntity({ xp }).getLabel();
  }

  return (
    <Wrapper className="pixelify_font_family">
      <p>{xp}</p>
      <div className="separator">/</div>
      <p>{getXp()}</p>

      <p className="level_wrapper">
        <span className="separator">[</span>
        {getLabel()}
        <span className="separator">]</span>{" "}
        <span className="level animate__animated animate__heartBeat">lv</span>
      </p>
    </Wrapper>
  );
}, areEqual);

const LevelStatusWidget = () => {
  const xp = useSelector(s.currentGunXp());

  return (
    <SubHeaderWrapper>
      <LaserPixelIcon width="18" height="18" />
      <JarvisStatus xp={xp} />
    </SubHeaderWrapper>
  );
};

export default LevelStatusWidget;
