// react
import { useEffect } from "react";

// formik
import { FormikProps } from "formik";

interface FormikAutoFillProps {
  props: FormikProps<any>;
  identifier: string;
  field: string;
}

const FormikAutoFill = ({ props, field, identifier }: FormikAutoFillProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handle, []);

  function handle() {
    setTimeout(() => {
      const el = document.getElementById(identifier);
      if (!el) return;

      const children = el.children[0];
      if (!children) return;

      children.addEventListener("change", (e: any) => {
        props.setFieldValue(field, e.target.value);
      });
    }, 1000);
  }

  return null;
};

export default FormikAutoFill;
