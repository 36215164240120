// redux
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";

const initialState: PlayerCheckpointsStateProps = {};

export const playerCheckpointsSlice = createSlice({
  name: "playerCheckpoints",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<PlayerCheckpointsStateProps>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const playerCheckpointsActions = playerCheckpointsSlice.actions;
export default playerCheckpointsSlice.reducer;
