// utils
import c from "styles/color";

interface AvatarJarvisProps {
  width?: string;
  height?: string;
  color?: string;
}

const SendMessageIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: AvatarJarvisProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M0 72.348v66.782h33.391v-33.391h445.218v33.391H512V72.348zM445.217 139.13h33.391v33.391h-33.391zM411.826 172.522h33.391v33.391h-33.391zM378.435 205.913h33.391v33.391h-33.391zM345.043 239.304h33.391v33.391h-33.391zM311.652 272.696h33.391v33.391h-33.391z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M278.261 306.087h33.391v33.391h-33.391zM244.87 339.478h33.391v33.391H244.87zM211.478 372.87h33.391v33.391h-33.391zM33.391 139.13h33.391v33.391H33.391zM66.783 172.522h33.391v33.391H66.783zM100.174 205.913h33.391v33.391h-33.391zM211.478 272.696v-33.392h-77.913v200.348h77.913v-33.391h-44.521V272.696zM211.478 205.913h66.783v33.391h-66.783zM278.261 172.522h66.783v33.391h-66.783z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default SendMessageIcon;
