// redux
import { initialState as spaceshipFeaturesInitialState } from "redux/slices/spaceInvaders/spaceshipFeaturesSlice";

// enums
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// interfaces
import {
  SpaceshipFeaturesProps,
  SpaceshipFeaturesLevelUpProps,
} from "interfaces/spaceInvaders/spaceshipFeatures";
import { DataCacheProps } from "components/presentations/Customization";
import { PlayerSpaceshipDataProps } from "interfaces/player";

// constants
import {
  JARVIS_BULLETS_INITIAL_VALUE,
  JARVIS_RADAR_INITIAL_VALUE,
  JARVIS_RELOAD_INITIAL_VALUE,
} from "constants/jarvis";

// utils
import color from "styles/color";

// reload
const RELOAD_INITIAL_VALUE = 2000;
const RELOAD_DEC_VALUE = 200;
const RELOAD_MIN_VALUE = 100;

// radar
const RADAR_INITIAL_VALUE = 10;
const RADAR_MAX_VALUE = 300;
const RADAR_INC_VALUE = 20;

function map(spaceship: PlayerSpaceshipDataProps): SpaceshipFeaturesProps {
  if (!spaceship) return fake();

  return {
    bullets: spaceship.bullets,
    engraving: spaceship.engraving,
    radar: getRadarDistance(spaceship.radar),
    reload: getReloadDelay(spaceship.reload),
    color: getColor(spaceship.color),

    // raw values
    bulletsRaw: spaceship.bullets,
    engravingRaw: spaceship.engraving,
    colorRaw: spaceship.color,
    radarRaw: spaceship.radar,
    reloadRaw: spaceship.reload,
  };
}

function mapCached(spaceship: DataCacheProps): SpaceshipFeaturesProps {
  if (!spaceship) return fake();

  return {
    bullets: spaceship.bullets,
    radar: getRadarDistance(spaceship.radar),
    reload: getReloadDelay(spaceship.reload),
    color: getColor(spaceship.spaceshipColor),
    engraving: spaceship.spaceshipEngraving,

    // raw values
    bulletsRaw: spaceship.bullets,
    engravingRaw: spaceship.spaceshipEngraving,
    colorRaw: spaceship.spaceshipColor,
    radarRaw: spaceship.radar,
    reloadRaw: spaceship.reload,
  };
}

function mapLevelUp(spaceship: DataCacheProps): SpaceshipFeaturesLevelUpProps {
  return {
    bullets: spaceship.bullets,
    radar: getRadarDistance(spaceship.radar),
    reload: getReloadDelay(spaceship.reload),

    // raw values
    bulletsRaw: spaceship.bullets,
    radarRaw: spaceship.radar,
    reloadRaw: spaceship.reload,
  };
}

// private

function fake(): SpaceshipFeaturesProps {
  return {
    bullets: JARVIS_BULLETS_INITIAL_VALUE,
    radar: getRadarDistance(JARVIS_RADAR_INITIAL_VALUE),
    reload: getReloadDelay(JARVIS_RELOAD_INITIAL_VALUE),
    engraving: spaceshipFeaturesInitialState.engraving,
    color: spaceshipFeaturesInitialState.color,

    // raw values
    bulletsRaw: JARVIS_BULLETS_INITIAL_VALUE,
    radarRaw: JARVIS_RADAR_INITIAL_VALUE,
    reloadRaw: JARVIS_RELOAD_INITIAL_VALUE,
    engravingRaw: spaceshipFeaturesInitialState.engravingRaw,
    colorRaw: spaceshipFeaturesInitialState.colorRaw,
  };
}

// O radar tem no máximo 15 pontos
function getRadarDistance(radar: number): number {
  const value = RADAR_INITIAL_VALUE + radar * RADAR_INC_VALUE;
  if (value >= RADAR_MAX_VALUE) return RADAR_MAX_VALUE;

  return value;
}

// O reload tem no máximo 10 pontos
function getReloadDelay(reload: number): number {
  const value = RELOAD_INITIAL_VALUE - reload * RELOAD_DEC_VALUE;
  if (value <= RELOAD_MIN_VALUE) return RELOAD_MIN_VALUE;

  return value;
}

function getColor(type: SpaceshipColorType): string {
  if (type === SpaceshipColorType.Blue) return color.blue;
  if (type === SpaceshipColorType.Green) return color.green;
  if (type === SpaceshipColorType.Red) return color.red;
  if (type === SpaceshipColorType.Yellow) return color.yellow;
  if (type === SpaceshipColorType.Orange) return color.orange;
  if (type === SpaceshipColorType.Purple) return color.purple;

  return color.purple;
}

const spaceshipFeaturesParser = {
  map,
  mapCached,
  mapLevelUp,
};

export default spaceshipFeaturesParser;
