// react
import { useState, useRef } from "react";

// components
import Board from "components/Board";
import SlideDotsProgress from "components/SlideDotsProgress";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// svgs
import BookPixelSvg from "svg/BookPixelSvg";
import LaserPixelSvg from "svg/LaserPixelSvg";
import RefineMachinePixelSvg from "svg/RefineMachinePixelSvg";
import MineralStoragePixelSvg from "svg/MineralStoragePixelSvg";

// interfaces
import { DotProgressRefProps } from "components/SlideDotsProgress";

// utils
import color from "styles/color";
import styled from "styled-components";

// Swiper
import { IonicSlides } from "@ionic/react";
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .body_header {
    display: flex;
    align-items: center;
    min-height: 60px;
    text-align: center;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
    }

    .buttons_wrapper {
      display: flex;
      justify-content: space-around;
      padding: 10px;

      .item {
        opacity: 0.3;
        padding: 20px;

        &.active {
          opacity: 1;
        }
      }
    }

    .slide {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }
`;

const LASER_PIXEL_SLIDE = 0;
const REFINE_MACHINE_SLIDE = 1;
const MINERAL_STORAGE_SLIDE = 2;
const ENCYCLOPEDIA_STORAGE_SLIDE = 3;

interface SpaceshipEngravingProps {
  getSpaceshipClassNameColor(): string;
}

const SpaceshipItems = ({
  getSpaceshipClassNameColor,
}: SpaceshipEngravingProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const speechProgressRef = useRef<DotProgressRefProps>({ 0: true });
  const [slideActiveIndex, setSlideActiveIndex] = useState(LASER_PIXEL_SLIDE);

  function goToLaserPixelItem() {
    slideTo(LASER_PIXEL_SLIDE);
  }

  function goToRefineMachineItem() {
    slideTo(REFINE_MACHINE_SLIDE);
  }

  function goToMineralStorageItem() {
    slideTo(MINERAL_STORAGE_SLIDE);
  }

  function goToEncyclopediaItem() {
    slideTo(ENCYCLOPEDIA_STORAGE_SLIDE);
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index);
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;

    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex, realIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
      updateRealSpeechPositionRef(realIndex);
    });
  }

  function updateRealSpeechPositionRef(index: number) {
    if (speechProgressRef.current[index]) return;
    speechProgressRef.current = { ...speechProgressRef.current, [index]: true };
  }

  return (
    <Wrapper>
      <div className="body_header">
        <Board>
          <p>
            Por <span className="white">padrão</span> a{" "}
            <span className={getSpaceshipClassNameColor()}>espaçonave</span> vem
            com <span className="gold">quatro itens</span>.
          </p>
        </Board>
      </div>

      <div className="content">
        <div className="buttons_wrapper">
          <ButtonRippleEffect
            color={color.white}
            onClick={goToLaserPixelItem}
            className={`item ${
              slideActiveIndex === LASER_PIXEL_SLIDE && "active"
            }`}
          >
            <LaserPixelSvg width="40" height="40" />
          </ButtonRippleEffect>

          <ButtonRippleEffect
            color={color.blue}
            onClick={goToRefineMachineItem}
            className={`item ${
              slideActiveIndex === REFINE_MACHINE_SLIDE && "active"
            }`}
          >
            <RefineMachinePixelSvg width="40" height="40" />
          </ButtonRippleEffect>

          <ButtonRippleEffect
            color={color.yellow}
            onClick={goToMineralStorageItem}
            className={`item ${
              slideActiveIndex === MINERAL_STORAGE_SLIDE && "active"
            }`}
          >
            <MineralStoragePixelSvg width="40" height="40" />
          </ButtonRippleEffect>

          <ButtonRippleEffect
            color={color.purple}
            onClick={goToEncyclopediaItem}
            className={`item ${
              slideActiveIndex === ENCYCLOPEDIA_STORAGE_SLIDE && "active"
            }`}
          >
            <BookPixelSvg width="40" height="40" />
          </ButtonRippleEffect>
        </div>

        <Swiper
          onSwiper={handleSwiper}
          modules={[IonicSlides]}
          slidesPerView={1}
          initialSlide={0}
        >
          <SwiperSlide>
            <div className="slide">
              <Board>
                <p>
                  O <span className="white">canhão de laser simples</span> é
                  ideal para <span className="red">aniquilar</span>{" "}
                  <span className="orange">criaturas espaciais</span> de{" "}
                  <span className="pink">pequeno porte</span>...
                </p>
              </Board>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide">
              <Board>
                <p>
                  O{" "}
                  <span className="blue">
                    refinador a vácuo de minérios brutos
                  </span>{" "}
                  transforma <span className="brown">minérios brutos</span> em{" "}
                  <span className="purple">minérios refinados</span>...
                </p>
              </Board>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide">
              <Board>
                <p>
                  O <span className="green">armazém de recursos</span> pode
                  armazenar <span className="pink">três tipos</span> de{" "}
                  <span className="yellow">recursos essenciais</span>...{" "}
                  <span className="orange">minérios</span>,{" "}
                  <span className="blue">água</span> e{" "}
                  <span className="purple">força forte</span>.
                </p>
              </Board>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide">
              <Board>
                <p>
                  A <span className="nerdfy_purple">enciclopédia</span> é um
                  item <span className="nerdfy_red">essencial</span> para todo{" "}
                  <span className="nerdfy_white">
                    explorador intergaláctico
                  </span>
                  . Nela, você vai encontrar{" "}
                  <span className="nerdfy_blue">informações</span> sobre{" "}
                  <span className="orange">criaturas</span>,{" "}
                  <span className="nerdfy_pink">civilizações</span>,{" "}
                  <span className="nerdfy_green">recursos</span> e{" "}
                  <span className="nerdfy_yellow">muito mais</span>.
                </p>
              </Board>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="progressbar">
        <SlideDotsProgress
          len={4}
          position={slideActiveIndex}
          dotsProgress={speechProgressRef.current}
        />
      </div>
    </Wrapper>
  );
};

export default SpaceshipItems;
