// components
import Board from "components/Board";

// svgs
import SpaceshipSvg from "svg/SpaceshipSvg";
import BookPixelSvg from "svg/BookPixelSvg";
import LaserPixelSvg from "svg/LaserPixelSvg";
import RefineMachinePixelSvg from "svg/RefineMachinePixelSvg";
import MineralStoragePixelSvg from "svg/MineralStoragePixelSvg";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  color: ${color.grey.strong};

  .body_header {
    display: flex;
    align-items: center;
    min-height: 60px;
    text-align: center;
  }

  .order {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .resume {
      display: flex;

      ul {
        all: unset;
        border: 1px solid ${color.grey.dark};
        padding: 10px 20px;
        margin: 22px 10px;
        border-radius: 10px;

        li {
          all: unset;
        }
      }

      ul.features {
        li {
          display: flex;

          p {
            all: unset;
            margin: 5px;
            font-size: 20px;

            &.engraving {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          p:first-child {
            flex: 1;
            text-align: left;
          }

          p:last-child {
            .value {
              color: ${color.grey.light};
            }
          }
        }
      }

      ul.items {
        display: flex;
        flex-direction: column;
        height: auto;

        li {
          p {
            all: unset;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${color.gold};
            margin: 5px;
            font-size: 20px;
          }
        }

        ul {
          all: unset;
          display: flex;
          margin: 10px 0;
          opacity: 0.7;

          li {
            margin: 0 5px;
          }
        }
      }
    }
  }
`;

const Spaceship = ({ color }: { color: string }) => {
  return <SpaceshipSvg color={color} width="50" height="50" />;
};

export interface OrderProps {
  radar: number;
  reload: number;
  bullets: number;
  engraving: string;
}

interface SpaceshipOrderProps {
  order: OrderProps;
  description: JSX.Element;
  getSpaceshipColor(): string;
  getSpaceshipClassNameColor(): string;
}

const SpaceshipOrder = ({
  order,
  description,
  getSpaceshipColor,
  getSpaceshipClassNameColor,
}: SpaceshipOrderProps) => {
  return (
    <Wrapper>
      <div className="body_header">
        <Board>{description}</Board>
      </div>

      <div className="order">
        <Spaceship color={getSpaceshipColor()} />

        <div className="resume">
          <ul className="features">
            <li>
              <p className="engraving">
                <span className={getSpaceshipClassNameColor()}>"</span>
                <span className="value">{order.engraving}</span>
                <span className={getSpaceshipClassNameColor()}>"</span>
              </p>
            </li>

            <li>
              <p>Laser:</p>
              <p>
                <span className="value">{order.bullets}</span>
              </p>
            </li>

            <li>
              <p>Radar:</p>
              <p>
                <span className="value">{order.radar}</span>
              </p>
            </li>

            <li>
              <p>Recarga:</p>
              <p>
                <span className="value">{order.reload}</span>
              </p>
            </li>
          </ul>

          <ul className="items">
            <li>
              <p>Itens</p>
            </li>

            <ul>
              <li>
                <LaserPixelSvg width="20" height="20" />
              </li>

              <li>
                <RefineMachinePixelSvg width="20" height="20" />
              </li>

              <li>
                <MineralStoragePixelSvg width="20" height="20" />
              </li>

              <li>
                <BookPixelSvg width="20" height="20" />
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

export default SpaceshipOrder;
