// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { strongForceBarrierActions } from "redux/slices/spaceInvaders/strongForceBarrierSlice";
import { spaceshipPosition } from "redux/middlewares/spaceInvaders/spaceshipMachineGunMiddleware";

// factories
import { SpaceshipFactory } from "factories/spaceInvaders/SpaceshipFactory";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";
import SpaceshipEntity from "entities/spaceInvaders/SpaceshipEntity";

// enums
import { FlyingObjectHpType } from "enums/spaceInvaders/flyingObjectEnum";

// interfaces
import { FlyingObjectRefProps } from "interfaces/spaceInvaders/flyingObject";

// drag
// TODO: Import only draggable
import interact from "interactjs";

const SPACESHIP_HALF_WIDTH = SPACE_INVADERS.spaceship.width / 2;

interface SpaceshipProps {
  paused: boolean;
  hasStrongBarrier: boolean;
  wrapperEl: HTMLDivElement | null;
  spaceshipRef: { current: FlyingObjectRefProps | null };
  blink?(): void;
}

const Spaceship = ({
  blink,
  paused,
  wrapperEl,
  spaceshipRef,
  hasStrongBarrier,
}: SpaceshipProps) => {
  const dispatch = useDispatch();
  const player = useSelector(s.player());
  const spaceshipFeatures = useSelector(s.spaceshipFeatures());
  const spaceshipHpRef = useRef<number[]>([]);
  const interactDragRef = useRef<any>(null);
  const interactDropRef = useRef<any>(null);
  const hasStrongBarrierRef = useRef(hasStrongBarrier);

  useEffect(listenHitStrongBarrier, [hasStrongBarrier]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(start, [player]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenPaused, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reload, [player]);
  useEffect(listenHp, [player]);

  function listenHp() {
    spaceshipHpRef.current.push(player.hp);
  }

  function start() {
    if (!wrapperEl) return;
    if (player.hp === PlayerHpEntity.hpNotSetValue) return;
    if (player.life === PlayerHpEntity.lifeNotSetValue) return;
    if (player.hp === PlayerHpEntity.hpKilledValue) return;
    if (player.life === PlayerHpEntity.lifeKilledValue) return;
    if (spaceshipRef.current?.el) return;
    if (spaceshipRef.current?.object) return;

    setTimeout(() => bootstrap(wrapperEl));
  }

  function reload() {
    if (!isLastHpKilled()) return;

    if (!wrapperEl) return;
    if (!spaceshipRef.current?.el) return;
    if (!spaceshipRef.current?.object) return;
    if (player.hp !== PlayerHpEntity.hpTotal) return;

    setTimeout(() => bootstrap(wrapperEl));
  }

  function isLastHpKilled() {
    return (
      spaceshipHpRef.current[spaceshipHpRef.current.length - 1] ===
      PlayerHpEntity.hpKilledValue
    );
  }

  function bootstrap(wrapperEl: HTMLDivElement) {
    const object = new SpaceshipEntity({ x: 0, y: 0 });

    object.setHp(player.hp);
    object.setLife(player.life);

    spaceshipRef.current = {
      object,
      el: SpaceshipFactory(spaceshipFeatures.colorRaw),
    };

    wrapperEl.appendChild(spaceshipRef.current.el);
    setFirstPosition(spaceshipRef.current.el);
  }

  function listenPaused() {
    const spaceshipEl = spaceshipRef.current?.el;
    if (!spaceshipEl) return;

    if (paused) spaceshipEl.classList.remove("animate_y_infinite");
    else spaceshipEl.classList.add("animate_y_infinite");
  }

  function listenHitStrongBarrier() {
    hasStrongBarrierRef.current = hasStrongBarrier;
  }

  function hitStrongForceBarrier(
    spaceshipEl: HTMLDivElement,
    spaceshipPosition: { x: number; y: number }
  ) {
    const object = spaceshipRef.current?.object;
    spaceshipRef.current = null;

    if (!object) return;

    blink && blink();
    object.hit(FlyingObjectHpType.high);

    const spaceship = object.toJson() as SpaceshipEntity;
    const { top, left, right, bottom } = spaceshipEl.getBoundingClientRect();

    dispatch(
      strongForceBarrierActions.async.spaceshipHit(
        spaceship,
        spaceshipPosition,
        {
          top,
          left,
          right,
          bottom,
        }
      )
    );

    setTimeout(() => remove(spaceshipEl));
  }

  function getBorderTop(): number {
    return hasStrongBarrierRef.current
      ? SPACE_INVADERS.strongForceBarrier.top
      : 0;
  }

  function setFirstPosition(spaceshipEl: HTMLDivElement) {
    spaceshipEl.style.display = "block";
    spaceshipEl.style.bottom = "70px";
    spaceshipEl.style.left = `calc(50% - ${SPACESHIP_HALF_WIDTH}px)`;

    setTimeout(() => startDrag(spaceshipEl), 1000);
  }

  function startDrag(spaceshipEl: HTMLElement) {
    const c = window.getComputedStyle(spaceshipEl);

    spaceshipPosition.x = Number(c.left.replace("px", ""));
    spaceshipPosition.y = Number(c.top.replace("px", ""));

    interactDragRef.current = interact(".spaceship_draggable").draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
        }),
      ],
      listeners: {
        move(event) {
          const spaceshipEl = event?.target;
          if (!spaceshipEl) return;

          spaceshipPosition.x += event.dx;
          spaceshipPosition.y += event.dy;

          // top border
          if (spaceshipPosition.y <= getBorderTop()) {
            if (
              hasStrongBarrierRef.current &&
              spaceshipEl.style.display !== "none"
            )
              return hitStrongForceBarrier(spaceshipEl, spaceshipPosition);

            spaceshipPosition.y = getBorderTop();
          }

          spaceshipEl.style.top = `${spaceshipPosition.y}px`;
          spaceshipEl.style.left = `${spaceshipPosition.x}px`;
        },
      },
    });
  }

  function remove(spaceship: HTMLDivElement) {
    spaceship.style.display = "none";
    spaceship.remove();
  }

  function destroyComponent() {
    hasStrongBarrierRef.current = false;
    spaceshipHpRef.current = [];

    interactDragRef.current?.unset();
    interactDropRef.current?.unset();
  }

  return null;
};

export default Spaceship;
