interface SvgProps {
  width?: string;
  height?: string;
}

const RavianScepticSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-24 0 511 511.99872"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <path
        d="m384.878906 274.203125c0 35.128906-28.476562 63.609375-63.609375 63.609375-35.128906 0-63.609375-28.480469-63.609375-63.609375 0-35.132813 28.480469-63.609375 63.609375-63.609375 35.132813 0 63.609375 28.476562 63.609375 63.609375zm0 0"
        fill="#e8eaea"
      />
      <path
        d="m109.804688 194.742188c-2.976563 0-5.8125-1.730469-7.082032-4.632813-1.710937-3.910156.070313-8.464844 3.980469-10.175781 16.1875-7.082032 34.515625-8.527344 51.613281-4.0625 4.128906 1.078125 6.601563 5.300781 5.523438 9.429687-1.078125 4.125-5.292969 6.605469-9.425782 5.523438-13.75-3.589844-28.496093-2.429688-41.515624 3.265625-1.011719.441406-2.0625.652344-3.09375.652344zm0 0"
        fill="#353535"
      />
      <path
        d="m353.484375 194.742188c-1.03125 0-2.085937-.210938-3.09375-.652344-13.019531-5.695313-27.765625-6.855469-41.515625-3.265625-4.125 1.078125-8.347656-1.398438-9.425781-5.527344s1.394531-8.347656 5.523437-9.425781c17.097656-4.464844 35.425782-3.019532 51.613282 4.0625 3.910156 1.710937 5.691406 6.265625 3.980468 10.175781-1.269531 2.902344-4.105468 4.632813-7.082031 4.632813zm0 0"
        fill="#353535"
      />
      <path
        d="m205.628906 274.203125c0 35.128906-28.480468 63.609375-63.609375 63.609375-35.132812 0-63.609375-28.480469-63.609375-63.609375 0-35.132813 28.476563-63.609375 63.609375-63.609375 35.128907 0 63.609375 28.476562 63.609375 63.609375zm0 0"
        fill="#e8eaea"
      />
      <path
        d="m337.855469 238.484375c0 9.160156-7.425781 16.585937-16.585938 16.585937-9.160156 0-16.582031-7.425781-16.582031-16.585937 0-9.15625 7.421875-16.582031 16.582031-16.582031 9.160157 0 16.585938 7.425781 16.585938 16.582031zm0 0"
        fill="#353535"
      />
      <path
        d="m158.601562 238.484375c0 9.160156-7.425781 16.585937-16.582031 16.585937-9.160156 0-16.585937-7.425781-16.585937-16.585937 0-9.15625 7.425781-16.582031 16.585937-16.582031 9.15625 0 16.582031 7.425781 16.582031 16.582031zm0 0"
        fill="#353535"
      />
      <path
        d="m283.246094 384.257812h-103.203125c-4.269531 0-7.726563-3.460937-7.726563-7.726562s3.457032-7.726562 7.726563-7.726562h103.203125c4.265625 0 7.726562 3.460937 7.726562 7.726562s-3.460937 7.726562-7.726562 7.726562zm0 0"
        fill="#844d15"
      />
    </svg>
  );
};

export default RavianScepticSvg;
