// widgets
import ShopItemsWidget from "components/widgets/SpaceshipItemsWidget";

const PlayerProfileItemsSlide = () => {
  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_gold">Itens</h1>
      </div>

      <div className="body">
        <ShopItemsWidget />
      </div>
    </div>
  );
};

export default PlayerProfileItemsSlide;
