import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";
import { getFirestore, Firestore } from "firebase/firestore";
class FirebaseCoreService {
  static app: FirebaseApp;
  static db: Firestore;
  static auth: Auth;
  static initialized = false;

  static bootstrap() {
    if (FirebaseCoreService.initialized) return;

    FirebaseCoreService.initialized = true;
    FirebaseCoreService.init();
  }

  static init() {
    const config = {
      apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
      authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
      projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    };

    FirebaseCoreService.app = initializeApp(config);
    FirebaseCoreService.setDatabase(FirebaseCoreService.app);
    FirebaseCoreService.setAuth(FirebaseCoreService.app);
  }

  static setDatabase(app: FirebaseApp) {
    FirebaseCoreService.db = getFirestore(app);
  }

  static setAuth(app: FirebaseApp) {
    FirebaseCoreService.auth = getAuth(app);
  }

  static getDatabase(): Firestore {
    return FirebaseCoreService.db;
  }

  static getAuth(): Auth {
    return FirebaseCoreService.auth;
  }
}

export default FirebaseCoreService;
