// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { bulletsActions } from "redux/slices/spaceInvaders/bulletsSlice";

// entities
import BulletEntity from "entities/spaceInvaders/BulletEntity";

// utils
import stateUtils from "redux/utils/stateUtils";

// enums
import { SpaceshipMachineGunEnum } from "enums/spaceInvaders/spaceshipMachineGunEnum";

const bulletMiddleware = createListenerMiddleware();

const INVALID_FIRE_POSITION = -111111111;
let fireFromLeft = false;

// bullet/fire
bulletMiddleware.startListening({
  actionCreator: bulletsActions.async.fire,
  effect: async ({ payload }, listenerApi) => {
    const { spaceshipMachineGun } = stateUtils.get(listenerApi);

    const y = payload.y + 10;
    const x = getFireXPosition(payload.x, spaceshipMachineGun.machineGun);

    if (x === INVALID_FIRE_POSITION) return;

    const bullet = new BulletEntity({ x, y });
    listenerApi.dispatch(bulletsActions.add(bullet.toJson()));
  },
});

// private

function getFireXPosition(x: number, source: SpaceshipMachineGunEnum): number {
  if (source === SpaceshipMachineGunEnum.None) return INVALID_FIRE_POSITION;
  if (source === SpaceshipMachineGunEnum.Left) return getLeftFire(x);
  if (source === SpaceshipMachineGunEnum.Right) return getRightFire(x);

  let position;

  if (source === SpaceshipMachineGunEnum.Middle)
    position = fireFromLeft ? getFromMiddleLeft(x) : getFromMiddleRight(x);
  else position = fireFromLeft ? getLeftFire(x) : getRightFire(x);

  fireFromLeft = !fireFromLeft;
  return position;
}

function getFromMiddleLeft(x: number): number {
  return x + 15;
}

function getFromMiddleRight(x: number): number {
  return x + 25;
}

function getLeftFire(x: number): number {
  return x;
}

function getRightFire(x: number): number {
  return x + 40;
}

export default bulletMiddleware;
