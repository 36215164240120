// react
import { useRef, useState } from "react";
import { IonicSlides } from "@ionic/react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// entities
import {
  ItemCharacterOneEntity,
  ItemCharacterTwoEntity,
  ItemCharacterFourEntity,
  ItemCThreeEntity,
} from "entities/items/ItemEntity";
import EnonEntity from "entities/characters/EnonEntity";
import ZarvoxEntity from "entities/characters/ZarvoxEntity";
import AstrogildoEntity from "entities/characters/AstrogildoEntity";

// components
import SlideDotsProgress from "components/SlideDotsProgress";

// interfaces
import {
  ListItems,
  ListItemProps,
  NoneDescription,
} from "components/playerProfile/PlayerProfile";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface PlayerProfileCharactersSliceProps {
  checkpoints: PlayerCheckpointsStateProps;
}

const PlayerProfileCharactersSlide = ({
  checkpoints,
}: PlayerProfileCharactersSliceProps) => {
  const npc = useSelector(s.npc());
  const swiperRef = useRef<SwiperProps | undefined>();
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  function getCharacters(): ListItemProps[] {
    const two = new ItemCharacterTwoEntity();
    const one = new ItemCharacterOneEntity();
    const three = new ItemCThreeEntity();
    const four = new ItemCharacterFourEntity();

    return [one, two, three, four];
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;

    swiperRef.current = swiper;
    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
    });
  }

  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_blue">Personagens</h1>
      </div>

      <div className="body">
        <ListItems
          checkpoints={checkpoints}
          items={getCharacters()}
          activeIndex={slideActiveIndex}
        />

        <SlideDotsProgress len={4} position={slideActiveIndex} />

        <div className="slides_body no-swipe">
          <Swiper
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            slidesPerView={1}
            initialSlide={0}
          >
            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.Astrogildo] ? (
                  <>
                    <p>
                      <span className="nerdfy_yellow">
                        {AstrogildoEntity.defaultName}, o Explorador
                      </span>
                      , <span className="nerdfy_pink">famoso</span> em{" "}
                      <span className="nerdfy_white">toda</span> Nerdfy,
                      infelizmente, ele{" "}
                      <span className="nerdfy_red">sumiu</span> durante a sua{" "}
                      <span className="nerdfy_green">heroica</span> viagem de
                      exploração, cuja{" "}
                      <span className="nerdfy_blue">missão</span> seria{" "}
                      <span className="nerdfy_white">encontrar</span> e{" "}
                      <span className="nerdfy_white">neutralizar</span> o pixel{" "}
                      <span className="nerdfy_orange">hospedeiro zero</span> do{" "}
                      <span className="nerdfy_red">bit sombrio</span>...
                    </p>

                    <p>
                      A sua última{" "}
                      <span className="nerdfy_white">mensagem</span> "
                      <span className="nerdfy_brown">
                        {AstrogildoEntity.lastMessage}
                      </span>
                      " e <span className="nerdfy_blue">localização</span> foi
                      tudo o que restou...
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.Jarvis] ? (
                  <>
                    <p>
                      <span className="nerdfy_yellow">{npc.jarvis.name}</span>,
                      criado à <span className="nerdfy_green">imagem</span> e{" "}
                      <span className="nerdfy_blue">semelhança</span> dos{" "}
                      <span className="nerdfy_yellow">nerds</span> pela{" "}
                      <span className="nerdfy_white">Nerdsoft</span>, é um IA
                      generalista ainda em fase de testes.
                    </p>

                    <p>
                      Sua <span className="nerdfy_red">missão inicial</span> é
                      auxiliar os <span className="nerdfy_brown">primatas</span>{" "}
                      a se <span className="nerdfy_pink">integrarem</span> e{" "}
                      <span className="nerdfy_orange">sobreviverem</span> no
                      mundo <span className="nerdfy_green">bidimensional</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.Zarvox] ? (
                  <>
                    <p>
                      <span className="nerdfy_green">
                        {ZarvoxEntity.defaultName}
                      </span>
                      , famoso <span className="nerdfy_pink">apresentador</span>{" "}
                      do show [bastante popular entre os ergonianos] de{" "}
                      <span className="nerdfy_white">perguntas</span> e{" "}
                      <span className="nerdfy_white">respostas</span> "
                      <span className="nerdfy_orange">
                        Quem Quer Ser O Primata
                      </span>
                      ".
                    </p>

                    <p>
                      Sua <span className="nerdfy_blue">missão</span> é garantir
                      que apenas o primata{" "}
                      <span className="nerdfy_yellow">mais capacitado</span>{" "}
                      possa receber o{" "}
                      <span className="nerdfy_red">ITEM MISTERIOSO</span>...
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.Enon] ? (
                  <>
                    <p>
                      <span className="nerdfy_yellow">
                        {EnonEntity.defaultName}
                      </span>
                      , o <span className="nerdfy_green">carismático</span> e{" "}
                      <span className="nerdfy_red">polêmico</span>{" "}
                      <span className="nerdfy_blue">CEO</span> da{" "}
                      <span className="nerdX_color">nerdX</span>, é um dos
                      maiores <span className="nerdfy_pink">entusiastas</span> e{" "}
                      <span className="nerdfy_orange">investidores</span> das{" "}
                      <span className="nerdfy_white">
                        viagens de exploração espacial
                      </span>
                      .
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfileCharactersSlide;
