// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// firestore
import { serverTimestamp } from "firebase/firestore";

// svgs
import RepairPixelSvg from "svg/RepairPixelSvg";

interface IconProps {
  width?: string;
  height?: string;
}

const Icon = ({ width = "35", height = "35" }: IconProps) => {
  return <RepairPixelSvg width={width} height={height} />;
};

export default class RepairItemEntity extends AbstractShopItemEntity {
  readonly id = 7;
  readonly price = 2;
  readonly subTitle = "";
  readonly maxQuantity = 6;
  readonly lifeCycles = 200;
  readonly available = false;
  readonly icon = (<Icon />);
  readonly bubbles = this.shopBubbles;
  readonly type = SpaceshipItemEnum.Repair;
  readonly title = "Circuito de Reparação Estelar";
  readonly image = (<Icon width="45" height="45" />);
  readonly capacity = AbstractShopItemEntity.capacityNone;

  get item(): ShopItemProps {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      price: this.price,
      bubbles: this.bubbles,
      subTitle: this.subTitle,
      capacity: this.capacity,
      available: this.available,
      lifeCycles: this.lifeCycles,
    };
  }

  get values(): PlayerShopItemsFirebaseProps {
    return {
      [this.getItemId(this.type)]: {
        t: this.type,
        c: this.capacity,
        l: this.lifeCycles,
        s: serverTimestamp(),
      },
    };
  }

  get resourcesPrice(): SpaceshipResourcesQtyProps {
    return {
      sand: 0,
      gold: 2,
      water: 1,
      silver: 1,
      copper: 0,
      estelin: 1,
      bauxite: 1,
      strongForce: 3,
    };
  }

  getIcon({ width, height }: IconProps): JSX.Element {
    return <Icon width={width} height={height} />;
  }

  private get shopBubbles(): ShopItemBubbleProps[] {
    return [
      {
        id: 1,
        itemId: this.id,
        question: `Quantos ciclos? (${this.lifeCycles})`,
        answer: `São INCRÍVEIS ${this.lifeCycles} ciclos de vida.`,
      },
      {
        id: 2,
        itemId: this.id,
        question: "Para que serve?",
        answer: `Ele deve ser utilizado para reparar a nave espacial quando danificada.`,
      },
    ];
  }
}
