// redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import { EventProps } from "interfaces/event";

const initialState: EventProps[] = [];

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<EventProps[]>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const eventsActions = eventsSlice.actions;
export default eventsSlice.reducer;
