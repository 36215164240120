// redux
import { createAction } from "@reduxjs/toolkit";

// interfaces
import { TagProps, SpecialTagProps } from "interfaces/tag";

interface AddPayloadProps {
  tag: TagProps;
  specialTags: SpecialTagProps[];
}

const missed = createAction("@async/tag/missed");
const help = createAction("@async/tag/help");
const add = createAction("@async/tag/add", (payload: AddPayloadProps) => ({
  payload,
}));

export const tagActions = {
  async: {
    add,
    help,
    missed,
  },
};
