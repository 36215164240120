interface SvgProps {
  width?: string;
  height?: string;
}

const RavianFoolSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-24 0 511 511.99872"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <path
        d="m190.21875 255.785156c0 24.566406-19.914062 44.476563-44.476562 44.476563-24.566407 0-44.476563-19.910157-44.476563-44.476563 0-24.5625 19.910156-44.476562 44.476563-44.476562 24.5625 0 44.476562 19.914062 44.476562 44.476562zm0 0"
        fill="#e8eaea"
      />
      <path
        d="m373.25 241.921875c0 35.082031-28.441406 63.523437-63.527344 63.523437-35.082031 0-63.523437-28.441406-63.523437-63.523437 0-35.085937 28.441406-63.527344 63.523437-63.527344 35.085938 0 63.527344 28.441407 63.527344 63.527344zm0 0"
        fill="#e8eaea"
      />
      <path
        d="m316.039062 219.191406c0 9.394532-7.617187 17.011719-17.011718 17.011719-9.394532 0-17.011719-7.617187-17.011719-17.011719 0-9.394531 7.617187-17.011718 17.011719-17.011718 9.394531 0 17.011718 7.617187 17.011718 17.011718zm0 0"
        fill="#353535"
      />
      <path
        d="m169.746094 261.457031c0 9.398438-7.617188 17.015625-17.011719 17.015625s-17.011719-7.617187-17.011719-17.015625c0-9.394531 7.617188-17.011719 17.011719-17.011719s17.011719 7.617188 17.011719 17.011719zm0 0"
        fill="#353535"
      />
      <path
        d="m231.644531 352.355469c-40.820312 0-77.019531-7.609375-99.546875-21.304688-6.859375-4.171875-15.453125 1.878907-13.941406 9.761719 10.742188 55.988281 57.46875 98.128906 113.488281 98.128906s102.746094-42.140625 113.488281-98.128906c1.511719-7.882812-7.082031-13.933594-13.941406-9.761719-22.527344 13.695313-58.726562 21.304688-99.546875 21.304688zm0 0"
        fill="#8c2f3f"
      />
      <path
        d="m231.644531 352.355469c-27.167969 0-52.28125-3.375-72.6875-9.667969.335938 8.011719 1.761719 15.710938 4.121094 22.929688 2.339844 7.15625 8.984375 12.023437 16.515625 12.023437h104.101562c7.53125 0 14.175782-4.867187 16.515626-12.023437 2.359374-7.21875 3.785156-14.917969 4.121093-22.929688-20.40625 6.292969-45.519531 9.667969-72.6875 9.667969zm0 0"
        fill="#e8eaea"
      />
      <path
        d="m241.078125 429.382812 14.175781 33.375c7.453125 17.554688 27.347656 26.203126 45.265625 19.679688 17.921875-6.523438 27.601563-25.933594 22.03125-44.171875l-10.597656-34.679687c-2.910156-9.53125-14.328125-13.386719-22.414063-7.558594-6.238281 4.496094-12.800781 8.816406-16.738281 10.25s-11.742187 2.339844-19.414062 2.90625c-9.9375.734375-16.203125 11.027344-12.308594 20.199218zm0 0"
        fill="#fd4755"
      />
      <path
        d="m288.636719 427.199219-8.574219-23.5625c-.117188-.324219-.261719-.632813-.417969-.929688-2.644531 1.628907-5.023437 2.910157-6.84375 3.570313-1.816406.664062-4.464843 1.210937-7.539062 1.664062.074219.328125.160156.65625.277343.976563l8.578126 23.566406c1.140624 3.136719 4.105468 5.085937 7.261718 5.085937.875 0 1.769532-.152343 2.640625-.46875 4.011719-1.457031 6.078125-5.894531 4.617188-9.902343zm0 0"
        fill="#c41926"
      />
    </svg>
  );
};

export default RavianFoolSvg;
