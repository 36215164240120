// ionic-react
import { IonRippleEffect } from "@ionic/react";

// components
import AvatarIconHandler from "handlers/AvatarIconHandler";

// interfaces
import { NextChallengeInfoProps } from "handlers/challengeDoneButton/ChallengeDoneButtonHandler";

// utils
import color from "styles/color";
import styled from "styled-components";

export const Next = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 150px;
  background: ${color.dark.bg};
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 20px;
  margin: 0;
  cursor: pointer;
  z-index: 888;

  > .btn_content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .next_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 30px;
        margin: 0;
      }
    }
  }

  > .done_footer {
    .next_challenge_info {
      text-align: right;

      p {
        color: ${color.grey.stronger};
        margin: 10px 0;
        width: 100%;
        font-size: 17px;
      }
    }
  }
`;

export interface LabelProps {
  done: boolean;
  label: string;
}
interface ChallengeDoneButtonProps {
  go(): void;
  Label: React.FC<LabelProps>;
  info: NextChallengeInfoProps;
}

const ChallengeDoneButton = ({ go, info, Label }: ChallengeDoneButtonProps) => {
  return (
    <Next
      onClick={go}
      className={`ion-activatable ripple-parent rectangle animate__animated no-swipe animate__bounceInUp pixelify_font_family`}
    >
      <IonRippleEffect style={{ color: color.blue }} />

      <div className="btn_content">
        <AvatarIconHandler
          type={info.nextChallengeType}
          avatarGradient={info.isNextChallengeSuccess}
          avatarInvader={info.nextChallengeAvatarInvader}
          avatarColor={
            info.isNextChallengeDone && !info.isNextChallengeSuccess
              ? color.danger
              : undefined
          }
        />

        <div className="next_wrapper">
          <Label done={info.isNextChallengeDone} label={"Próximo Desafio"} />
        </div>
      </div>

      <div className="done_footer">
        <div className="next_challenge_info">
          <p>{info.challengeName || ""}</p>
          <p>{info.sectionName ? `${info.sectionName}` : ""}</p>
        </div>
      </div>
    </Next>
  );
};

export default ChallengeDoneButton;
