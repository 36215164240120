// react
import { useEffect, useRef } from "react";

// components
import DragWhore, {
  DragWhoreTemplateElProps,
} from "components/dragWhores/DragWhore";

// interfaces
import { DragWhoreProps } from "interfaces/dragWhore";

// interact
import interact from "interactjs";
import type { Interactable } from "@interactjs/core/Interactable";

// utils
import dropUtils, { borderColorType } from "utils/dropUtils";

interface DragWhoresProps {
  whores: DragWhoreProps[];
  TemplateEl: React.FC<DragWhoreTemplateElProps>;
  clear?: boolean;
  paused?: boolean;
  disabledDrop?: boolean;

  /*
    Só vai precisar dos inputs abaixo caso 
    haja MAIS DE UM componente DragWhores
    na view...
  */
  draggableClassName?: string;
  interactDropZone?: Interactable;
  interactDraggable?: Interactable;
  droppableAcceptClassName?: string;
  dropZoneBorderColor?: borderColorType;
}

/*
  1) O Attention Whore é linear/sequencial, isso é, se você 
  tem 5 itens na tela, apenas será possível fazer o drop do primeiro, depois o segundo...

  2) O Drag whore, tem as mesmas funcionalidades que o AT, embora ele não seja linear/sequencial,
  isso é, é possível fazer o drop de qualquer elemento, a qualquer momento.
*/

const DragWhores = ({
  clear,
  paused,
  whores,
  TemplateEl,
  disabledDrop,
  draggableClassName,
  dropZoneBorderColor = "yellow",
  droppableAcceptClassName = "drag_whore_droppable",
  interactDropZone = interact(".drag_whore_dropzone"),
  interactDraggable = interact(".drag_whore_draggable"),
}: DragWhoresProps) => {
  const dropZoneBorderClassNameRef = useRef(
    dropUtils.getBorderColorClassName(dropZoneBorderColor)
  );
  const whoresRef = useRef<DragWhoreProps[]>([]);
  const interactDragRef = useRef<any>(null);
  const interactDropRef = useRef<any>(null);
  const disableDropRef = useRef<any>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startDrag, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startDrop, []);
  useEffect(listenToWhores, [whores]);
  useEffect(listenToDisabledDrop, [disabledDrop]);

  function listenToDisabledDrop() {
    disableDropRef.current = disabledDrop;
  }

  function listenToWhores() {
    whoresRef.current = whores;
  }

  function startDrag() {
    interactDragRef.current = interactDraggable.draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
        }),
      ],
      listeners: {
        move(event: any) {
          const { target } = event;
          const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

          target.style.top = `${y}px`;
          target.style.left = `${x}px`;

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
      },
    });
  }

  function startDrop() {
    interactDropRef.current = interactDropZone.dropzone({
      accept: `.${droppableAcceptClassName}`,
      overlap: 0.5,
      ondropactivate: function (event: any) {
        const dropElClassList = event.target.classList;
        dropElClassList.add("border_dashed_grey");
      },
      ondropdeactivate: function (event: any) {
        event.target.classList.remove("border_dashed_grey");
      },
      ondragenter: function (event: any) {
        event.target.classList.add(dropZoneBorderClassNameRef.current);
      },
      ondragleave: function (event: any) {
        event.target.classList.remove(dropZoneBorderClassNameRef.current);
      },
      ondrop: function (event: any) {
        if (disableDropRef.current) return;

        const dragEl = event.relatedTarget;
        const point = whoresRef.current.find((point) => point.id === dragEl.id);

        event.target.classList.remove(dropZoneBorderClassNameRef.current);
        if (!point) return;

        setTimeout(() => {
          if (!dragEl) return;
          if (!point) return;

          dragEl.classList.add("animate__animated");
          dragEl.classList.add("animate__zoomOut");
          dragEl.addEventListener("animationend", onAnimationEnd);

          point.call({ ...point });

          function onAnimationEnd() {
            if (!dragEl) return;
            dragEl.removeEventListener("animationend", onAnimationEnd);
          }
        });
      },
    });
  }

  function destroyComponent() {
    interactDragRef.current?.unset();
    interactDropRef.current?.unset();
  }

  return (
    <>
      {whores.map((whore: DragWhoreProps, i: number) => (
        <DragWhore
          key={i}
          clear={clear}
          whore={whore}
          paused={paused}
          TemplateEl={TemplateEl}
          draggableClassName={draggableClassName}
          droppableAcceptClassName={droppableAcceptClassName}
        />
      ))}
    </>
  );
};

export default DragWhores;
