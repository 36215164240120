// interfaces
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// utils
import isEmpty from "lodash/isEmpty";
import { AllToOptional } from "utils/transformTypeUtils";

export default class SpaceshipResourcesToDeleteEntity {
  map(
    resources: AllToOptional<SpaceshipResourcesUpdateFirebaseProps>
  ): string[] {
    if (isEmpty(resources)) return [];

    const resourceNames = Object.keys(resources) as Array<
      keyof SpaceshipResourcesUpdateFirebaseProps
    >;

    if (isEmpty(resourceNames)) return [];

    return resourceNames.reduce((acc, resourceName) => {
      const d = resources[resourceName] as { [key: string]: boolean };
      if (isEmpty(d)) return acc;

      const ids = Object.keys(d) as Array<string>;
      const list = ids.reduce((acc2, id) => {
        if (!d[id]) return acc2;

        acc2.push(`resources.${String(resourceName)}.${id}`);
        return acc2;
      }, [] as string[]);

      acc.push(...list);

      return acc;
    }, [] as string[]);
  }
}
