// react
import { IonList } from "@ionic/react";

// formik
import { Formik, Form, FormikProps } from "formik";

// components
import FormikInput from "components/formik/FormikInput";
import FormikCheckbox from "components/formik/FormikCheckbox";
import FormikAutoFill from "components/formik/FormikAutoFill";
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// interfaces
import { UserSignProps } from "interfaces/user";

// utils
import color from "styles/color";
import isEmpty from "lodash/isEmpty";
import * as yup from "yup";

interface UserFormProps extends UserSignProps {
  termsAndConditions: boolean;
  passwordConfirmation: string;
}

interface SignUpProps {
  onSignedUp: (user: UserSignProps) => void;
  disabledInputs?: boolean;
}

const SignUp = ({ onSignedUp, disabledInputs }: SignUpProps) => {
  function getSchemaValidation() {
    const email = yup
      .string()
      .email("Por favor, insira um email válido")
      .required("Qual o seu email?");

    const password = yup
      .string()
      .required("Qual a senha?")
      .min(8, "A senha deve ter no mínimo 8 caracteres");

    const passwordConfirmation = yup
      .string()
      .oneOf([yup.ref("password"), ""], "As senhas devem ser iguais");

    const termsAndConditions = yup
      .bool()
      .oneOf([true], "Você precisa aceitar os termos e condições");

    return yup.object({
      email,
      password,
      passwordConfirmation,
      termsAndConditions,
    });
  }

  function getInitialValues(): UserFormProps {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      termsAndConditions: false,
    };
  }

  function submit(values: UserFormProps) {
    if (values.password !== values.passwordConfirmation) return;

    const user: UserSignProps = {
      email: values.email,
      password: values.password,
    };

    onSignedUp(user);
  }

  function hasEmptyValue({
    email,
    password,
    termsAndConditions,
    passwordConfirmation,
  }: UserFormProps): boolean {
    return (
      isEmpty(email) ||
      isEmpty(password) ||
      isEmpty(passwordConfirmation) ||
      !termsAndConditions
    );
  }

  return (
    <Formik
      validateOnChange
      validationSchema={getSchemaValidation()}
      initialValues={getInitialValues()}
      onSubmit={submit}
    >
      {(props: FormikProps<UserFormProps>) => {
        return (
          <Form>
            <FormikAutoFill
              props={props}
              identifier="userSignUpEmail"
              field="email"
            />
            <FormikAutoFill
              props={props}
              identifier="userSignUpPassword"
              field="password"
            />

            <IonList>
              <FormikInput
                id="userSignUpEmail"
                label="Seu Email Preferido"
                name="email"
                type="email"
                inputmode="email"
                autocomplete="email"
                autocapitalize="off"
                autocorrect="off"
                placeholder="seuemailpreferido@exemplo.com"
                disabled={disabledInputs}
              />

              <FormikInput
                id="userSignUpPassword"
                label="Sua Senha Super Secreta"
                name="password"
                type="password"
                placeholder="A senha deve ter no mínimo 8 caracteres"
                disabled={disabledInputs}
              />

              <FormikInput
                id="userSignUpPasswordConfirmation"
                label="Confirme Sua Senha"
                name="passwordConfirmation"
                type="password"
                placeholder="A senha deve ser igual a anterior"
                disabled={disabledInputs}
              />

              <FormikCheckbox
                name="termsAndConditions"
                identifier="termsAndConditions"
              >
                Aceito e compreendo os{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/doit/terms-conditions"
                >
                  termos e condições
                </a>
              </FormikCheckbox>
            </IonList>

            <ButtonDuolingo
              type="submit"
              color={color.green}
              disabled={!isEmpty(props.errors) || hasEmptyValue(props.values)}
              className="submit_button"
            >
              Criar Conta
            </ButtonDuolingo>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUp;
