// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { spaceshipFeaturesActions } from "redux/slices/spaceInvaders/spaceshipFeaturesSlice";

// entities
import JarvisLevelEntity from "entities/JarvisLevelEntity";

// icons
import IconWhoreLevelUp from "components/iconWhores/IconWhoreLevelUp";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// handlers
import LevelUp from "handlers/levelUp/LevelUp";

// interfaces
import { DataCacheProps } from "components/presentations/Customization";

// parsers
import spaceshipFeaturesParser from "parsers/spaceInvaders/spaceshipFeaturesParser";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface LevelUpHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

interface CurrentAttentionWhoreProps extends AttentionWhoreProps {
  show?: boolean;
}

const LevelUpHandler = ({
  paused,
  wrapper,
  challenge,
}: LevelUpHandlerProps) => {
  const dispatch = useDispatch();
  const xp = useSelector(s.currentGunXp());
  const currentLevelsRef = useRef<{ [key: string]: number }>({});
  const spaceshipFeatures = useSelector(s.spaceshipFeatures());
  const [showSpaceshipCustomization, setShowSpaceshipCustomization] =
    useState(false);
  const [attentionWhores, setAttentionWhores] = useState<
    CurrentAttentionWhoreProps[]
  >([]);
  const { flowInit } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAttentionWhore, [xp, flowInit]);

  function handleAttentionWhore() {
    if (!xp) return;
    if (!flowInit) return;

    const levelEntity = new JarvisLevelEntity({ xp });
    const tp = levelEntity.get().totalPoints;
    const label = levelEntity.getLabel();

    if (!Number(label)) return;
    if (!hasLevelUp(tp)) return;

    addAttentionWhore(label);
  }

  function hasLevelUp(totalPoints: number): boolean {
    const { bulletsRaw, reloadRaw, radarRaw } = spaceshipFeatures;
    return bulletsRaw + reloadRaw + radarRaw !== totalPoints;
  }

  // ATTENTION WHORE

  function addAttentionWhore(label: string) {
    if (!wrapper) return;

    const whore = attentionWhoreUtils.get({
      label,
      payload: "",
      WhoreEl: IconWhoreLevelUp,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setAttentionWhores([...getHideAttentionWhores(), whore]);
  }

  function getHideAttentionWhores() {
    return attentionWhores.map((w) => ({ ...w, show: false }));
  }

  function onAttentionWhoreCall() {
    setTimeout(() => setShowSpaceshipCustomization(true));
  }

  // ATTENTION WHORE END

  function finishPresentation(data: DataCacheProps) {
    const spaceshipFeatures = spaceshipFeaturesParser.mapLevelUp(data);
    dispatch(spaceshipFeaturesActions.update(spaceshipFeatures));
  }

  function getTotalPoints(xp: number): number {
    return new JarvisLevelEntity({ xp }).get().totalPoints;
  }

  return (
    <>
      {attentionWhores.map((w) => (
        <AttentionWhores
          key={w.id}
          whores={[w]}
          paused={paused}
          clear={w.show === false || challenge.flowDone}
        />
      ))}

      {showSpaceshipCustomization && !!xp && (
        <LevelUp
          finished={finishPresentation}
          jarvisTotalPoints={getTotalPoints(xp)}
        />
      )}
    </>
  );
};

export default LevelUpHandler;
