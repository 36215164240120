// services
import RequestCoreService from "services/core/RequestCoreService";

// interfaces
import { StoryResourceProps } from "interfaces/story";

export interface GetStoryResponseProps {
  data: StoryResourceProps[];
}

class StoryService {
  private _resource = "story";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  get(languageId: number): Promise<GetStoryResponseProps> {
    if (!languageId) return Promise.reject();

    const resource = `${this._resource}/language/${languageId}`;
    return this._request.get(resource);
  }
}

export default StoryService;
