// react
import { useEffect } from "react";

// components
import { NotifyFactoryType } from "factories/NotifyFactory";

// interfaces
import { PlayerDegreeValueProps } from "constants/player";
import { PlayerProps } from "interfaces/player";

interface PlayerDegreeHandlerProps {
  player: PlayerProps;
  challengeDone: boolean;
  notify(type: NotifyFactoryType, content: string): void;
  lastPlayerDegreeRef: React.MutableRefObject<
    PlayerDegreeValueProps | undefined
  >;
}

const PlayerNotifierDarkSideDegreeHandler = ({
  player,
  notify,
  challengeDone,
  lastPlayerDegreeRef,
}: PlayerDegreeHandlerProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [challengeDone, player.degree, player.score]);

  function listen() {
    const current = player.degree;
    const last = lastPlayerDegreeRef.current;

    if (!last) return;
    if (!current) return;
    if (current.lightSide) return;

    if (last.checkpoint === current.checkpoint) return;

    if (current.checkpoint > last.checkpoint) regain(current.name);
    else lost(last.name);

    updateLastPlayerRefValues();
  }

  function regain(degree: string) {
    const content = `Você RECONQUISTOU a fama "${degree}"`;
    notify(NotifyFactoryType.Warning, content);
  }

  function lost(degree: string) {
    const content = `Você PERDEU a fama "${degree}"`;
    notify(NotifyFactoryType.Danger, content);
  }

  function updateLastPlayerRefValues() {
    lastPlayerDegreeRef.current = player.degree;
  }

  return null;
};

export default PlayerNotifierDarkSideDegreeHandler;
