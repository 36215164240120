interface SvgProps {
  width?: string;
  height?: string;
}

const RavianNinjaSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      height={width}
      width={height}
      viewBox="-13 0 511 511.99872"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m486.273438 223.507812c.679687-3.511718-2.335938-6.589843-5.875-6.058593-18.019532 2.699219-37.246094.078125-54.179688-7.507813-19.25-8.628906-35.375-23.605468-44.640625-41.464844l-11.027344 57.046876c9.261719 17.855468 25.390625 32.835937 44.640625 41.464843 18.152344 8.132813 38.945313 10.554688 58.054688 6.839844 2.066406-.402344 3.6875-2.019531 4.089844-4.089844zm0 0"
        fill="#fd4755"
      />
      <path
        d="m470.515625 274.300781c-1.128906-1.992187-2.292969-3.964843-3.527344-5.890625-10.847656-16.925781-20.125-34.804687-27.171875-53.574218-4.644531-1.296876-9.199218-2.921876-13.597656-4.894532-10.074219-4.515625-19.277344-10.78125-27.105469-18.277344-7.507812 2.988282-15.320312 5.035157-23.179687 6.027344l-5.382813 27.832032c9.261719 17.859374 25.390625 32.839843 44.640625 41.464843 17.292969 7.75 36.976563 10.300781 55.324219 7.3125zm0 0"
        fill="#c41926"
      />
      <path
        d="m416.863281 92.941406c-2.949219-2.121094-7.113281-.71875-8.175781 2.707032-5.410156 17.433593-16.265625 33.675781-30.699219 45.714843-16.410156 13.6875-37.320312 21.828125-57.878906 22.535157l47.847656 34.4375c20.558594-.707032 41.46875-8.847657 57.878907-22.535157 15.476562-12.90625 26.835937-30.648437 31.785156-49.503906.535156-2.042969-.246094-4.199219-1.984375-5.449219zm0 0"
        fill="#fd4755"
      />
      <path
        d="m399.113281 191.664062c9.605469-3.820312 18.703125-9.175781 26.722657-15.863281.042968-.035156.085937-.074219.128906-.109375-8.1875-18.535156-19.304688-35.480468-32.765625-50.257812-4.449219 5.832031-9.550781 11.207031-15.210938 15.929687-16.414062 13.6875-37.320312 21.828125-57.878906 22.535157l47.847656 34.4375c2.65625-.09375 5.316407-.3125 7.972657-.648438l5.648437-29.210938c4.417969 8.511719 10.40625 16.359376 17.535156 23.1875zm0 0"
        fill="#c41926"
      />
      <path
        d="m443.40625 275.621094c-14.011719-21.859375-25.417969-45.3125-32.757812-70.222656-15.617188-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.773437-30.511719 23.089844-40.523438-6.699219-11.800781-32.753907-9.660156-58.1875 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.621093-45.363281-2.933594-50.851562-12.261719-5.824219-31.671875 9.402343-43.351563 34.003906-5.800781 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.066406 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.382812-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m443.40625 275.621094c-14.011719-21.859375-25.417969-45.3125-32.757812-70.222656-15.617188-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.773437-30.511719 23.089844-40.523438-4.605469-8.113281-18.359375-9.636718-34.773438-5.105468 2.417969 1.238281 4.3125 2.933594 5.542969 5.105468 5.6875 10.011719-4.382812 26.832032-23.089844 40.523438-3.226562 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.757812 70.222656 12.304688 19.191406 19.394531 42.023437 19.382813 66.519531-.050782 108.460937-106.054688 164.972656-216.53125 169.546875 120.207031 4.980469 245.707031-51.53125 245.761718-169.546875.011719-24.496094-7.078124-47.328125-19.382812-66.519531zm0 0"
        fill="#fac600"
      />
      <g fill="#353535">
        <path d="m338.628906 275.382812c0 10.230469-8.292968 18.519532-18.519531 18.519532s-18.519531-8.289063-18.519531-18.519532c0-10.226562 8.292968-18.519531 18.519531-18.519531s18.519531 8.292969 18.519531 18.519531zm0 0" />
        <path d="m161.699219 275.382812c0 10.230469-8.292969 18.519532-18.519531 18.519532-10.226563 0-18.519532-8.289063-18.519532-18.519532 0-10.226562 8.292969-18.519531 18.519532-18.519531 10.226562 0 18.519531 8.292969 18.519531 18.519531zm0 0" />
        <path d="m284.855469 253.128906c-2.640625 0-5.210938-1.351562-6.65625-3.789062-2.175781-3.671875-.960938-8.410156 2.710937-10.585938 17.304688-10.253906 39.046875-16.746094 61.222656-18.277344 4.261719-.28125 7.949219 2.914063 8.242188 7.171876.296875 4.257812-2.917969 7.949218-7.175781 8.242187-19.816407 1.371094-39.140625 7.109375-54.414063 16.15625-1.234375.734375-2.589844 1.082031-3.929687 1.082031zm0 0" />
        <path d="m178.433594 253.128906c-1.339844 0-2.695313-.347656-3.929688-1.082031-15.273437-9.046875-34.597656-14.785156-54.414062-16.152344-4.257813-.296875-7.46875-3.984375-7.175782-8.242187.292969-4.257813 3.980469-7.464844 8.242188-7.175782 22.175781 1.535157 43.917969 8.023438 61.222656 18.277344 3.671875 2.175782 4.882813 6.914063 2.710938 10.585938-1.445313 2.4375-4.015625 3.789062-6.65625 3.789062zm0 0" />
      </g>
      <path
        d="m288.996094 357.472656c-2.597656 0-5.132813-1.308594-6.589844-3.683594-10.046875-16.355468-29.496094-26.515624-50.761719-26.515624-21.269531 0-40.71875 10.160156-50.761719 26.515624-2.234374 3.636719-6.988281 4.773438-10.628906 2.539063-3.632812-2.230469-4.773437-6.992187-2.539062-10.625 12.832031-20.902344 37.328125-33.882813 63.929687-33.882813 26.597657 0 51.09375 12.980469 63.929688 33.882813 2.230469 3.632813 1.09375 8.394531-2.542969 10.625-1.261719.777344-2.65625 1.144531-4.035156 1.144531zm0 0"
        fill="#844d15"
      />
      <path
        d="m404.179688 187.097656c-9.753907-23.628906-24.214844-44.8125-42.222657-62.386718-86.59375 9.867187-174.03125 9.867187-260.625 0-18.007812 17.574218-32.472656 38.757812-42.226562 62.386718 114.375 17.339844 230.703125 17.339844 345.074219 0zm0 0"
        fill="#fd4755"
      />
      <path
        d="m404.179688 187.097656c-2.0625-3.84375-12.40625-33.285156-42.222657-62.386718-8.832031 1.003906-17.671875 1.882812-26.519531 2.683593 17.707031 18 31.785156 39.574219 41.058594 63.546875 9.242187-1.175781 18.46875-2.445312 27.683594-3.84375zm0 0"
        fill="#c41926"
      />
      <path
        d="m42.101562 226.921875c-6.078124 16.25-14.167968 24.34375-30.421874 30.417969-6.558594 2.453125-6.558594 11.636718 0 14.089844 16.253906 6.074218 24.34375 14.167968 30.421874 30.421874 2.453126 6.554688 11.636719 6.554688 14.085938 0 6.078125-16.253906 14.167969-24.347656 30.421875-30.421874 6.558594-2.453126 6.558594-11.636719 0-14.089844-16.253906-6.074219-24.34375-14.167969-30.421875-30.417969-2.449219-6.558594-11.632812-6.558594-14.085938 0zm0 0"
        fill="#e8eaea"
      />
    </svg>
  );
};

export default RavianNinjaSvg;
