const ERROR_TYPES = {
  UNIQUE_EMAIL: "UNIQUE_EMAIL",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  INVALID_TOKEN: "INVALID_TOKEN",
  TOKEN_NOT_FOUND: "TOKEN_NOT_FOUND",
  TIMEOUT: "TIMEOUT",
  LOGIN_ERROR: "LOGIN_ERROR",
  USER_ALREADY_REGISTERED: "USER_ALREADY_REGISTERED",
  EMAIL_ALREADY_REGISTERED: "EMAIL_ALREADY_REGISTERED",
  USERNAME_ALREADY_REGISTERED: "USERNAME_ALREADY_REGISTERED",
  CODENAME_ALREADY_REGISTERED: "CODENAME_ALREADY_REGISTERED",
};

export { ERROR_TYPES };
