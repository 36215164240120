// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesQtyProps,
} from "interfaces/spaceInvaders/spaceshipResources";
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// parsers
import spaceshipResourcesParser from "parsers/spaceInvaders/spaceshipResourcesParser";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

const add = createAction(
  "@async/spaceshipResources/add",
  (payload: AllToOptional<SpaceshipResourcesQtyProps>) => ({ payload })
);

const remove = createAction(
  "@async/spaceshipResources/remove",
  (deleteFields: string[]) => ({
    payload: { deleteFields },
  })
);

const transform = createAction(
  "@async/spaceshipResources/transform",
  (payload: AllToOptional<SpaceshipResourcesUpdateFirebaseProps>) => ({
    payload,
  })
);

const shift = createAction(
  "@async/spaceshipResources/shift",
  (payload: AllToOptional<SpaceshipResourcesUpdateFirebaseProps>) => ({
    payload,
  })
);

const fake = spaceshipResourcesParser.fakeData();

export const initialState: SpaceshipResourcesProps = {
  gold: fake,
  sand: fake,
  water: fake,
  copper: fake,
  silver: fake,
  estelin: fake,
  bauxite: fake,
  strongForce: fake,
};

export const spaceshipResourcesSlice = createSlice({
  name: "spaceshipResources",
  initialState,
  reducers: {
    update: (
      state,
      action: PayloadAction<AllToOptional<SpaceshipResourcesProps>>
    ) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const spaceshipResourcesActions = {
  async: {
    add,
    shift,
    remove,
    transform,
  },
  ...spaceshipResourcesSlice.actions,
};

export default spaceshipResourcesSlice.reducer;
