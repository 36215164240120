// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector } from "react-redux";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { WalkieTalkieBroadcastProps } from "interfaces/walkieTalkie";

// components
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// utils
import isEmpty from "lodash/isEmpty";
import numberUtils from "utils/numberUtils";
import timeAgoUtils from "utils/timeAgoUtils";

const MESSAGE_DELAY = 2500;

interface WalkieTalkieHandlerProps {
  paused: boolean;
  hasAmateurRadioItem: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

const alreadyBroadcasted = {} as { [key: string]: boolean };

const AmateurRadioBroadcastMessagesHandler = ({
  paused,
  wrapper,
  challenge,
  hasAmateurRadioItem,
}: WalkieTalkieHandlerProps) => {
  const hasAlreadyStarted = useRef(false);
  const broadcast = useSelector(s.walkieTalkieBroadcast());
  const { flowInit, flowDone } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleBroadcast, [
    paused,
    flowDone,
    flowInit,
    broadcast,
    hasAmateurRadioItem,
  ]);

  function handleBroadcast() {
    if (paused) return;
    if (flowDone) return;
    if (!flowInit) return;
    if (isEmpty(broadcast)) return;
    if (!hasAmateurRadioItem) return;
    if (hasAlreadyStarted.current) return;

    hasAlreadyStarted.current = true;
    const messages = filterBroadcastMessages(broadcast);
    if (isEmpty(messages)) return;

    displayMessages(messages);
    fillAlreadyBroadcasted(broadcast);
  }

  function fillAlreadyBroadcasted(messages: WalkieTalkieBroadcastProps[]) {
    messages.forEach((m) => (alreadyBroadcasted[m.id] = true));
  }

  function filterBroadcastMessages(
    messages: WalkieTalkieBroadcastProps[]
  ): WalkieTalkieBroadcastProps[] {
    return messages.filter((m) => !alreadyBroadcasted[m.id]);
  }

  function displayMessages(list: WalkieTalkieBroadcastProps[], first = true) {
    if (isEmpty(list)) return;

    setTimeout(
      () => {
        const data = list.shift();
        if (!data) return;

        displayMessage(data);
        displayMessages(list, false);
      },
      first ? 0 : MESSAGE_DELAY
    );
  }

  function displayMessage(data: WalkieTalkieBroadcastProps) {
    if (!wrapper) return;

    alertAnimate({
      wrapper,
      factories: [
        AlertFactory({
          slower: true,
          type: AlertFactoryType.Light,
          content: `${data.message} (${data.author}) (${timeAgoUtils.format(
            new Date(data.timestamp * 1000)
          )})`,
        }),
      ],
      bounds: {
        top: `${numberUtils.randomInterval(100, wrapper.clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, wrapper.clientWidth - 200)}px`,
      },
    });
  }

  return null;
};

export default AmateurRadioBroadcastMessagesHandler;
