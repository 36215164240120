// redux
import { ApplicationState } from "redux/store";

// interfaces
import {
  ChallengeProps,
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import {
  SpaceshipItemProps,
  SpaceshipItemsProps,
  SpaceshipItemsCounterProps,
} from "interfaces/spaceInvaders/spaceshipItem";
import { AppProps } from "interfaces/app";
import { UserProps } from "interfaces/user";
import { AuthProps } from "interfaces/auth";
import { StoryProps } from "interfaces/story";
import { EventProps } from "interfaces/event";
import { ShopItemProps } from "interfaces/shop";
import { PlayerProps } from "interfaces/player";
import { xpType } from "interfaces/playerFirebase";
import { ClassRoomProps } from "interfaces/classRoom";
import { NpcHashProps, NpcProps } from "interfaces/npc";
import { JarvisChatProps } from "interfaces/jarvisChat";
import { NextChallengeProps } from "interfaces/nextChallenge";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { BulletProps } from "interfaces/spaceInvaders/bullet";
import { ChallengeFlowProps } from "interfaces/challengeFlow";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { ExplosionProps } from "interfaces/spaceInvaders/explosion";
import { ChallengesMergedProps } from "interfaces/challengesMerged";
import { InvadersInfoProps } from "interfaces/spaceInvaders/invader";
import { WalkieTalkieBroadcastProps } from "interfaces/walkieTalkie";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";
import { ClassRoomRatingSummaryProps } from "interfaces/classRoomRating";
import { FlyingObjectProps } from "interfaces/spaceInvaders/flyingObject";
import { ProgrammingLanguageProps } from "interfaces/programmingLanguage";
import { InvaderBulletProps } from "interfaces/spaceInvaders/invaderBullet";
import { SpaceshipFeaturesProps } from "interfaces/spaceInvaders/spaceshipFeatures";
import { SpaceshipResourcesProps } from "interfaces/spaceInvaders/spaceshipResources";
import { SpaceshipMachineGunProps } from "interfaces/spaceInvaders/spaceshipMachineGun";
import { StrongForceBarrierStateProps } from "interfaces/spaceInvaders/strongForceBarrier";

export const selector = {
  app() {
    return (state: ApplicationState): AppProps => state.app;
  },
  jarvisChats() {
    return (state: ApplicationState): JarvisChatProps[] => state.jarvisChats;
  },
  classRoom() {
    return (state: ApplicationState): ClassRoomProps => state.classRoom;
  },
  auth() {
    return (state: ApplicationState): AuthProps => state.auth;
  },
  user() {
    return (state: ApplicationState): UserProps =>
      state.auth && state.auth.user;
  },
  player() {
    return (state: ApplicationState): PlayerProps => state.player;
  },
  playerCheckpoints() {
    return (state: ApplicationState): PlayerCheckpointsStateProps =>
      state.playerCheckpoints;
  },
  xp() {
    return (state: ApplicationState): xpType => state.player.xp;
  },
  currentGunXp() {
    return (state: ApplicationState): number => state.player.currentGunXp;
  },
  codename() {
    return (state: ApplicationState): string => state.auth.user.codename;
  },
  nextChallenge() {
    return (state: ApplicationState): NextChallengeProps => state.nextChallenge;
  },
  challenge() {
    return (state: ApplicationState): ChallengeProps => state.challenge;
  },
  challengesMerged() {
    return (state: ApplicationState): ChallengesMergedProps => state.challenges;
  },
  challenges() {
    return (state: ApplicationState): ChallengeCommonProps[] =>
      state.challenges.all;
  },
  challengesHash() {
    return (state: ApplicationState): ChallengesCommonHashProps =>
      state.challenges.hash;
  },
  challengeQuiz() {
    return (state: ApplicationState): ChallengeQuizProps => state.challengeQuiz;
  },
  challengeSection() {
    return (state: ApplicationState): ChallengeSectionProps =>
      state.challengeSection;
  },
  challengeSections() {
    return (state: ApplicationState): ChallengeSectionProps[] =>
      state.challengeSections;
  },
  challengeCode() {
    return (state: ApplicationState): ChallengeCodeProps => state.challengeCode;
  },
  programmingLanguages() {
    return (state: ApplicationState): ProgrammingLanguageProps[] =>
      state.programmingLanguages;
  },
  stories() {
    return (state: ApplicationState): StoryProps[] => state.stories;
  },
  events() {
    return (state: ApplicationState): EventProps[] => state.events;
  },
  classRoomRatingSummary() {
    return (state: ApplicationState): ClassRoomRatingSummaryProps =>
      state.classRoomRatingSummary;
  },
  challengeFlow() {
    return (state: ApplicationState): ChallengeFlowProps => state.challengeFlow;
  },
  walkieTalkieBroadcast() {
    return (state: ApplicationState): WalkieTalkieBroadcastProps[] =>
      state.walkieTalkie.broadcast;
  },

  // npcs
  npc() {
    return (state: ApplicationState): NpcHashProps => state.npc;
  },
  jarvis() {
    return (state: ApplicationState): NpcProps => state.npc.jarvis;
  },
  zarvox() {
    return (state: ApplicationState): NpcProps => state.npc.zarvox;
  },

  // shop
  shopItems() {
    return (state: ApplicationState): ShopItemProps[] => state.shop.items;
  },

  // space invaders

  // spaceship
  spaceshipItems() {
    return (state: ApplicationState): SpaceshipItemProps[] =>
      state.spaceshipItems.all;
  },
  spaceshipItemsHash() {
    return (state: ApplicationState): SpaceshipItemsProps =>
      state.spaceshipItems.hash;
  },
  spaceshipItemsCounter() {
    return (state: ApplicationState): SpaceshipItemsCounterProps =>
      state.spaceshipItems.counter;
  },
  spaceshipFeatures() {
    return (state: ApplicationState): SpaceshipFeaturesProps =>
      state.spaceshipFeatures;
  },
  spaceshipResources() {
    return (state: ApplicationState): SpaceshipResourcesProps =>
      state.spaceshipResources;
  },
  spaceshipMachineGun() {
    return (state: ApplicationState): SpaceshipMachineGunProps =>
      state.spaceshipMachineGun;
  },

  bullets() {
    return (state: ApplicationState): BulletProps[] => state.bullets;
  },
  explosions() {
    return (state: ApplicationState): ExplosionProps[] => state.explosions;
  },
  invaders() {
    return (state: ApplicationState): InvadersInfoProps[] => state.invaders;
  },
  invadersFriendly() {
    return (state: ApplicationState): InvadersInfoProps[] =>
      state.invadersFriendly;
  },
  flyingObjects() {
    return (state: ApplicationState): FlyingObjectProps[] =>
      state.flyingObjects;
  },
  strongForceBarrier() {
    return (state: ApplicationState): StrongForceBarrierStateProps =>
      state.strongForceBarrier;
  },
  invaderBullets() {
    return (state: ApplicationState): InvaderBulletProps[] =>
      state.invaderBullets;
  },
};
