// react
import { useState } from "react";

// ionic-react
import { IonInput, IonItem, IonLabel } from "@ionic/react";

// entities
import PlayerCodeNameEntity from "entities/PlayerCodeNameEntity";

// components
import Board from "components/Board";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;

  .body_header {
    display: flex;
    align-items: center;
    min-height: 60px;
    text-align: center;
  }

  .body_input_wrapper {
    width: 100%;
    padding: 0 20px;
    border: none;

    .codename_input {
      --color: ${color.grey.stronger};
    }
  }

  .ps {
    margin-top: 10px;
    margin-right: auto;

    p.codename_ps {
      text-align: left;
      font-size: 0.7em;
      color: ${color.grey.strong};
      margin: 8px 0;
      opacity: 0.6;
    }
  }
`;

const Item = styled(IonItem)`
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --border-color: ${color.grey.stronger};
`;

const Label = styled(IonLabel)`
  --color: ${color.grey.middle} !important;
`;

interface PlayerNamingProps {
  demo?: boolean;
  codename?: string;
  onChange?(codename: string): void;
}

const PlayerNaming = ({ demo, codename = "", onChange }: PlayerNamingProps) => {
  const [_codename, setCodename] = useState(getDefaultCodename());

  function getDefaultCodename(): string {
    return demo ? PlayerCodeNameEntity.defaultName : codename;
  }

  function onNameChange(e: any) {
    if (demo) return;

    const codename = e.target.value || "";

    setCodename(codename);
    onChange && onChange(codename);
  }

  function isValidName(name: string): boolean {
    if (!name) return false;
    if (hasNerdfyPrefix(name)) return false;

    return !!name.match(/^[a-z][a-zA-Z0-9_]{2,16}$/);
  }

  function hasNerdfyPrefix(name: string): boolean {
    return !!name.match(/^(nerdfy)/);
  }

  return (
    <Wrapper>
      <div className="body_header">
        <Board>
          <p>
            Sua <span className="bg_nerdfy_gradient">Jornada</span> começa em um{" "}
            <span className="nerdfy_pink">mundo bidimensional</span>, e{" "}
            <span className="nerdfy_white">não</span> muito{" "}
            <span className="nerdfy_orange">distante</span>...
          </p>

          <p>
            Seu <span className="cmd">cérebro primata</span>,{" "}
            <span className="nerdfy_yellow">confuso</span>, começa a ecoar
            alguns <span className="nerdfy_white">pensamentos</span>... "
            <span className="nerdfy_blue">Onde estou?</span>
            ", "<span className="nerdfy_blue">Quem eu sou?</span>" e "
            <span className="nerdfy_blue">Para onde estou indo?</span>"
          </p>
        </Board>
      </div>

      {!demo && (
        <div className="body_input_wrapper">
          <Item>
            <Label position="floating">Digite seu codinome</Label>

            <IonInput
              type="text"
              disabled={demo}
              className="codename_input"
              onIonChange={onNameChange}
              value={demo ? PlayerCodeNameEntity.defaultName : _codename}
            ></IonInput>
          </Item>
        </div>
      )}

      {!demo && !isValidName(_codename) && (
        <div className="ps">
          <p className="codename_ps">
            * O codinome deve ser em camelCase ou snake_case
          </p>
          <p className="codename_ps">
            * O primeiro caractere deve ser uma letra minúscula
          </p>
          <p className="codename_ps">
            * Insira entre 3-15 caracteres (letras | números | underline)
          </p>
          <p className="codename_ps">
            * O codinome não pode conter o prefixo "nerdfy"
          </p>
        </div>
      )}
    </Wrapper>
  );
};

export default PlayerNaming;
