// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";

interface ButtonDuolingoIconProps {
  onClick(): void;
  customClassName?: string;
  disabled?: boolean;
}

const ButtonDuolingoContinue = ({
  onClick,
  disabled,
  customClassName,
}: ButtonDuolingoIconProps) => {
  return (
    <ButtonDuolingo
      bg={color.green}
      onClick={onClick}
      disabled={disabled}
      color={color.white}
      boxShadowColor="#209340"
      className={customClassName}
    >
      Continuar
    </ButtonDuolingo>
  );
};

export default ButtonDuolingoContinue;
