// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// enums
import { UserGenreType } from "enums/userEnum";
import { SpaceshipPartType } from "enums/spaceInvaders/spaceshipEnum";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";
import PlayerDegreeEntity from "entities/PlayerDegreeEntity";

// interfaces
import { PlayerProps, PlayerEventsProps } from "interfaces/player";
import { PlayerCheckpointsProps } from "interfaces/playerCheckpoint";
import { FlyingObjectProps } from "interfaces/spaceInvaders/flyingObject";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

const addInitialDefaultItems = createAction("@async/player/addDefaultItems");

const recoveryHp = createAction(
  "@async/player/recoveryHp",
  (payload: { type: SpaceshipPartType; hpToRecover: number }) => ({ payload })
);

const updateStoryCheckpoint = createAction(
  "@async/player/updateStoryCheckpoint",
  (storyCheckpoint: number) => ({ payload: { storyCheckpoint } })
);

const updateEvents = createAction(
  "@async/player/updateEvents",
  (payload: PlayerEventsProps) => ({ payload })
);

const hit = createAction(
  "@async/player/hit",
  (spaceship: FlyingObjectProps) => ({
    payload: spaceship,
  })
);

const updateCheckpoint = createAction(
  "@async/player/updateCheckpoint",
  (checkpoint: PlayerCheckpointsProps) => ({ payload: { checkpoint } })
);

const winXp = createAction("@async/player/winXp");

const incScore = createAction("@async/player/incScore", (score: number) => ({
  payload: { score },
}));

const degree = new PlayerDegreeEntity({
  genre: UserGenreType.Unknown,
  score: 0,
}).get();

export const initialState: PlayerProps = {
  px: 0,
  xp: {},
  degree,
  help: 0,
  score: 0,
  missed: 0,
  killed: 0,
  gameOver: false,
  currentGunXp: 0,
  currentGunId: "",
  storyCheckpoint: 0,
  hp: PlayerHpEntity.hpTotal,
  life: PlayerHpEntity.lifeTotal,

  events: {},

  quiz: {
    easy: 0,
    hard: 0,
    medium: 0,
  },

  rating: {
    value: 0,
    message: "",
  },
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<PlayerProps>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<AllToOptional<PlayerProps>>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const playerActions = {
  async: {
    hit,
    winXp,
    incScore,
    recoveryHp,
    updateEvents,
    updateCheckpoint,
    updateStoryCheckpoint,
    addInitialDefaultItems,
  },
  ...playerSlice.actions,
};

export default playerSlice.reducer;
