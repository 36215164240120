// interfaces
import { ShopQuestionProps } from "interfaces/shop";
import { ShopQuestionFirebaseProps } from "interfaces/shopFirebase";

function mapAsRead(data: ShopQuestionFirebaseProps): ShopQuestionProps {
  return {
    id: data.id,
    read: data.read,
    itemId: data.itemId,
  };
}

const shopItemQuestionParser = {
  mapAsRead,
};

export default shopItemQuestionParser;
