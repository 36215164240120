interface SvgProps {
  width?: string;
  height?: string;
}

const StarSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M512 166.959h-.003v-.001H378.432v-33.391h-33.391V89.045H311.65V33.393h-33.391V.002h-44.521v33.391h-33.391v55.652h-33.392v44.522h-33.391v33.392H33.391v-.001H0v89.043h33.391v33.391h33.391v222.607h89.043v-33.391.001h55.652V445.217h89.043V478.609h55.652v-.001 33.391h89.043V289.392h33.391v-33.39H512z"
          fill="#ffde46"
          data-original="#ffde46"
          opacity="1"
        ></path>
        <path
          d="M378.432 166.958v-33.391h-33.391V89.045H311.65V33.393h-33.391V.002h-44.521v33.391h-33.391v55.652h-33.392v44.522h-33.391v33.391H0v66.782h133.564v-33.391h33.391v-33.391h33.392v-44.522h33.391V66.784h44.521v55.652h33.391v44.522h33.391v33.391h33.391v33.391h133.565v-66.782z"
          fill="#ffffff"
          data-original="#ffffff"
        ></path>
        <path
          d="M133.564 166.958H0v89.043h33.391v-55.652h100.173v-33.391h33.391v-33.391h-33.391z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M33.391 255.999h33.391v33.391H33.391zM200.347 33.392h33.391v55.652h-33.391zM166.955 89.044h33.391v44.521h-33.391zM278.259 33.392h33.391v55.652h-33.391z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M311.65 89.044h33.391v44.521H311.65zM155.825 445.216h55.652v33.391h-55.652zM66.782 489.738v22.261h89.043v-33.391h-55.652V289.392H66.782v189.216zM445.215 255.999h33.391v33.391h-33.391zM411.823 478.608h-55.651v33.391h89.043V289.392h-33.392z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M378.432 166.958v-33.391h-33.391v33.391h33.391v33.391h100.174v55.652h33.391v-89.043zM300.52 445.216h55.652v33.391H300.52zM233.738.001h44.521v33.391h-44.521zM211.477 411.824h89.043v33.391h-89.043z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default StarSvg;
