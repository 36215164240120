// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ChallengeQuizAvailabilityEntityProps {
  quiz: ChallengeCommonProps;
  challenges: ChallengeCommonProps[];
}

class ChallengeQuizAvailabilityEntity {
  quiz: ChallengeCommonProps;
  challenges: ChallengeCommonProps[];

  constructor({ quiz, challenges }: ChallengeQuizAvailabilityEntityProps) {
    this.quiz = quiz;
    this.challenges = challenges;
  }

  is(): boolean {
    const { quiz, challenges } = this;
    if (quiz.flowDone) return true;

    const c = challenges.find((c) => {
      if (c.index >= quiz.index) return true;
      return !c.flowDone;
    });

    if (!c) return false;
    return c.index >= quiz.index ? true : false;
  }
}

export default ChallengeQuizAvailabilityEntity;
