// react
import { useRef, useState } from "react";
import { IonicSlides } from "@ionic/react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// icons
import DonePixelIcon from "icons/DonePixelIcon";

// utils
import c from "styles/color";

// components
import SlideDotsProgress from "components/SlideDotsProgress";

// entities
import JarvisEntity from "entities/characters/JarvisEntity";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const DoneIcon = (color = c.green) => (
  <div className="done_icon_wrapper">
    <DonePixelIcon width="15" height="15" color={color} />
  </div>
);

const PlayerProfileSummarySlide = () => {
  const npc = useSelector(s.npc());
  const swiperRef = useRef<SwiperProps | undefined>();
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;

    swiperRef.current = swiper;
    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
    });
  }

  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_green">Eventos</h1>
      </div>

      <SlideDotsProgress len={2} position={slideActiveIndex} />

      <div className="body">
        <div className="slides_body no-swipe">
          <Swiper
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            slidesPerView={1}
            initialSlide={0}
          >
            <SwiperSlide>
              <div className="slide_body summary_context">
                <ol>
                  <li>
                    <span className="nerdfy_white">5.</span> Na sua{" "}
                    <span className="nerdfy_blue">primeira missão</span>, você
                    foi <span className="nerdfy_red">designado</span> para{" "}
                    <span className="nerdfy_green">descobrir</span> o{" "}
                    <span className="nerdfy_orange">paradeiro</span> de{" "}
                    <span className="nerdfy_yellow">Astrogildo</span>.{" "}
                  </li>
                  <li className="opacity">
                    <span className="nerdfy_white">4.</span> Um{" "}
                    <span className="nerdfy_purple">Ser</span> divino e{" "}
                    {npc.jarvis.name === JarvisEntity.unknownName && "uma"}{" "}
                    <span className="nerdfy_yellow">{npc.jarvis.name}</span>{" "}
                    <span className="nerdfy_green">recrutaram</span> você para
                    esse{" "}
                    <span className="nerdfy_pink">mundo bidimensional</span>...{" "}
                    {DoneIcon()}
                  </li>
                </ol>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body summary_context">
                <ol>
                  <li className="opacity">
                    <span className="nerdfy_white">3.</span> Astrogildo{" "}
                    <span className="nerdfy_red">sumiu</span> e tudo o que
                    restou foi uma <span className="nerdfy_blue">mensagem</span>{" "}
                    e <span className="nerdfy_orange">localização</span>...{" "}
                    {DoneIcon()}
                  </li>
                  <li className="opacity">
                    <span className="nerdfy_white">2.</span> Em um ato{" "}
                    <span className="nerdfy_green">heroico</span>,{" "}
                    <span className="nerdfy_yellow">Astrogildo</span> saiu numa
                    missão para <span className="nerdfy_blue">encontrar</span> e{" "}
                    <span className="nerdfy_white">neutralizar</span> o pixel{" "}
                    <span className="nerdfy_orange">hospedeiro zero</span>...{" "}
                    {DoneIcon()}
                  </li>
                  <li className="opacity">
                    <span className="nerdfy_white">1.</span> Um{" "}
                    <span className="nerdfy_red">vírus misterioso</span>,
                    apelidado pelos <span className="nerdfy_yellow">nerds</span>{" "}
                    de "<span className="nerdfy_red">bit sombrio</span>",
                    infectou um{" "}
                    <span className="nerdfy_white">único pixel</span>... que por
                    sua vez, <span className="nerdfy_orange">infectou</span>{" "}
                    outros pixels... {DoneIcon()}
                  </li>
                </ol>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfileSummarySlide;
