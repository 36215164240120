// react
import { IonProgressBar } from "@ionic/react";

// utils
import color from "styles/color";
import styled from "styled-components";

interface ProgressBarStatusProps {
  bgColor?: string;
  invincible?: boolean;
}

const ProgressBar = styled(IonProgressBar)<ProgressBarStatusProps>`
  width: 50px;
  height: 8px;
  --background: ${color.grey.middle};
  --progress-background ${color.red};

  ${({ invincible }: ProgressBarStatusProps) =>
    invincible &&
    `
    --progress-background ${color.purple};
    `};

${({ invincible, bgColor }: ProgressBarStatusProps) =>
  !invincible &&
  bgColor &&
  `
    --progress-background ${bgColor} !important;
    `};
`;

interface ProgressStatusProps {
  progress: number;
  color?: string;
  invincible?: boolean;
}

const ProgressStatus = ({
  progress,
  color,
  invincible,
}: ProgressStatusProps) => {
  return <ProgressBar value={progress} {...{ invincible, bgColor: color }} />;
};

export default ProgressStatus;
