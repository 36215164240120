// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// firestore
import { serverTimestamp } from "firebase/firestore";

// svgs
import RadioStationPixelSvg from "svg/RadioStationPixelSvg";

interface IconProps {
  width?: string;
  height?: string;
}

const Icon = ({ width = "35", height = "35" }: IconProps) => {
  return <RadioStationPixelSvg width={width} height={height} />;
};

export default class RadioStationItemEntity extends AbstractShopItemEntity {
  readonly id = 5;
  readonly price = 2;
  readonly maxQuantity = 1;
  readonly available = false;
  readonly icon = (<Icon />);
  readonly bubbles = this.shopBubbles;
  readonly type = SpaceshipItemEnum.RadioStation;
  readonly image = (<Icon width="45" height="45" />);
  readonly title = "Estação de Perguntas e Respostas";
  readonly capacity = AbstractShopItemEntity.capacityNone;
  readonly subTitle = "Emissor Broadcast e Receptor Anycast";
  readonly lifeCycles = AbstractShopItemEntity.lifeCyclesNone;

  get item(): ShopItemProps {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      price: this.price,
      bubbles: this.bubbles,
      subTitle: this.subTitle,
      capacity: this.capacity,
      available: this.available,
      lifeCycles: this.lifeCycles,
    };
  }

  get values(): PlayerShopItemsFirebaseProps {
    return {
      [this.getItemId(this.type)]: {
        t: this.type,
        c: this.capacity,
        l: this.lifeCycles,
        s: serverTimestamp(),
      },
    };
  }

  get resourcesPrice(): SpaceshipResourcesQtyProps {
    return {
      sand: 0,
      gold: 1,
      water: 1,
      silver: 1,
      copper: 2,
      estelin: 1,
      bauxite: 1,
      strongForce: 1,
    };
  }

  getIcon({ width, height }: IconProps): JSX.Element {
    return <Icon width={width} height={height} />;
  }

  private get shopBubbles(): ShopItemBubbleProps[] {
    return [
      {
        id: 1,
        itemId: this.id,
        question: `Quantos ciclos? (${this.lifeCycles})`,
        answer: `Assim como o rádio amador, a quantidade de ciclos é "infinita", já que corresponde a um número muito grande de ciclos... ou seja, você NUNCA vai precisar se preocupar em comprar outra estação de perguntas e respostas.`,
      },
      {
        id: 2,
        itemId: this.id,
        question: "Como funciona?",
        answer: `A estação de perguntas e respostas é um dispositivo que permite tirar dúvidas... Digamos que você tenha uma dúvida sobre programação... com esse item será possível enviar uma pergunta para outras naves espaciais (não todas, pois elas também precisam ter esse item) e esperar que alguma responda... Por outro lado, será possível responder as perguntas que foram enviadas... (LEMBRE-SE: Ensinar é uma forma de aprender)`,
      },
      {
        id: 3,
        itemId: this.id,
        question: "O que são mensagens do tipo broadcast?",
        answer: `Uma mensagem do tipo broadcast é uma mensagem enviada para múltiplos destinatários simultaneamente, sem a necessidade de identificar ou especificar cada destinatário individualmente.`,
      },
      {
        id: 4,
        itemId: this.id,
        question: "O que seria um receptor anycast?",
        answer: `Um receptor anycast é um dispositivo que recebe dados enviados de múltiplos emissores, todos direcionando suas mensagens para um único ponto (receptor anycast). Em suma, as respostas (emissores) serão enviadas para quem fez a pergunta (receptor)...`,
      },
    ];
  }
}
