// react
import { IonicSlides } from "@ionic/react";
import { useState, useRef, useCallback } from "react";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import Intro from "components/Intro";
import ProgressBar from "components/ProgressBar";
import PlayerGenreWrapper from "components/presentations/player/PlayerGenreWrapper";
import PlayerNamingWrapper from "components/presentations/player/PlayerNamingWrapper";
import SpaceshipEngraving from "components/presentations/spaceship/SpaceshipEngraving";
import SpaceshipOrderWrapper from "components/presentations/spaceship/SpaceshipOrderWrapper";
import SelectSpaceshipWrapper from "components/presentations/spaceship/SelectSpaceshipWrapper";

// handlers
import SpaceshipItemsHandler from "handlers/SpaceshipItemsHandler";
import SpaceshipFeaturesHandler from "handlers/SpaceshipFeaturesHandler";

// interfaces
import { UserProps } from "interfaces/user";

// constants
import {
  JARVIS_RADAR_INITIAL_VALUE,
  JARVIS_RELOAD_INITIAL_VALUE,
  JARVIS_BULLETS_INITIAL_VALUE,
} from "constants/jarvis";

// enums
import { UserGenreType } from "enums/userEnum";
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// utils
import color from "styles/color";
import styled from "styled-components";
import spaceshipUtils from "utils/spaceInvaders/spaceshipUtils";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 5px 10px;

    .body_header {
      display: flex;
      align-items: center;
      min-height: 60px;
    }

    .slides {
      flex: 1;
    }
  }
`;

const PLAYER_NAMING_SLIDE = 0;
const PLAYER_GENRE_SLIDE = 1;
const SPACESHIP_SELECT_SLIDE = 2;
const SPACESHIP_DEFAULT_ITEMS = 3;
const SPACESHIP_CUSTOMIZE_SLIDE = 4;
const SPACESHIP_ENGRAVING_SLIDE = 5;
const SPACESHIP_RESUME_SLIDE = 6;

export interface DataCacheProps {
  genre: number;
  radar: number;
  reload: number;
  bullets: number;
  codename: string;
  spaceshipEngraving: string;
  spaceshipColor: SpaceshipColorType;
}

interface PlayerPresentationProps {
  user: UserProps;
  demo?: boolean;
  close?(): void;
  finished?(data: DataCacheProps): void;
  closeWithAnimation?: boolean;
}

const Customization = ({
  user,
  demo,
  close,
  finished,
  closeWithAnimation,
}: PlayerPresentationProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const cacheRef = useRef<DataCacheProps>({
    codename: "",
    spaceshipEngraving: "",
    radar: JARVIS_RELOAD_INITIAL_VALUE,
    reload: JARVIS_RADAR_INITIAL_VALUE,
    bullets: JARVIS_BULLETS_INITIAL_VALUE,
    genre: UserGenreType.Unknown,
    spaceshipColor: SpaceshipColorType.None,
  });
  const [showIntro, setShowIntro] = useState(false);
  const [animation, setAnimation] = useState("animate__bounceInUp");
  const [progress, setProgress] = useState(0);
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    wrapperRef.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  function goToPlayerNaming() {
    setProgress(0);
    slideTo(PLAYER_NAMING_SLIDE);
  }

  function goToPlayerGenre() {
    setProgress(0.1);
    slideTo(PLAYER_GENRE_SLIDE);
  }

  function goToSpaceshipSelection() {
    setProgress(0.3);
    slideTo(SPACESHIP_SELECT_SLIDE);
  }

  function goToShowDefaultItems() {
    setProgress(0.5);
    slideTo(SPACESHIP_DEFAULT_ITEMS);
  }

  function goToSpaceshipCustomization() {
    setProgress(0.7);
    slideTo(SPACESHIP_CUSTOMIZE_SLIDE);
  }

  function goToSpaceshipEngraving() {
    setProgress(0.9);
    slideTo(SPACESHIP_ENGRAVING_SLIDE);
  }

  function goToSpaceshipResume() {
    setProgress(1);
    slideTo(SPACESHIP_RESUME_SLIDE);
  }

  function onClose() {
    if (closeWithAnimation) setAnimation("animate__bounceOutLeft");
    else close && close();
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (e.animationName === "bounceOutLeft") close && close();
  }

  function onFinishedSpaceshipCustomization(data: DataCacheProps) {
    finished && finished(data);
  }

  function handleShowIntro() {
    alertEncyclopediaNerdflix();
    setTimeout(() => setShowIntro(true));
  }

  function getSpaceshipClassNameColor(): string {
    return spaceshipUtils.getClassNameColor(cacheRef.current.spaceshipColor);
  }

  function getSpaceshipColor(): string {
    return spaceshipUtils.getColor(cacheRef.current.spaceshipColor);
  }

  function alertEncyclopediaNerdflix() {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `A empresa "nerdflix" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  function onSelectSpaceship(color: SpaceshipColorType) {
    cacheRef.current.spaceshipColor = color;
  }

  return (
    <ContentStyled
      ref={handle}
      className={`animate__animated ${animation}`}
      onAnimationEnd={onAnimationEnd}
    >
      <ProgressBar value={progress} color="success" />

      <Swiper
        slidesPerView={1}
        onSwiper={handleSwiper}
        modules={[IonicSlides]}
        noSwipingClass="no_swipe"
        initialSlide={PLAYER_NAMING_SLIDE}
      >
        <SwiperSlide>
          <div className="slide no_swipe">
            <PlayerNamingWrapper
              demo={demo}
              codename={user.codename}
              cache={cacheRef.current}
              goForward={goToPlayerGenre}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <PlayerGenreWrapper
              cache={cacheRef.current}
              goBack={goToPlayerNaming}
              goForward={goToSpaceshipSelection}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <SelectSpaceshipWrapper
              goBack={goToPlayerGenre}
              onSelect={onSelectSpaceship}
              goForward={goToShowDefaultItems}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <SpaceshipItemsHandler
              wrapper={wrapperRef.current}
              goBack={goToSpaceshipSelection}
              goForward={goToSpaceshipCustomization}
              getSpaceshipClassNameColor={getSpaceshipClassNameColor}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <SpaceshipFeaturesHandler
              cache={cacheRef.current}
              goBack={goToShowDefaultItems}
              goForward={goToSpaceshipEngraving}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <SpaceshipEngraving
              demo={demo}
              cache={cacheRef.current}
              goForward={goToSpaceshipResume}
              goBack={goToSpaceshipCustomization}
              getSpaceshipClassNameColor={getSpaceshipClassNameColor}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <SpaceshipOrderWrapper
              user={user}
              demo={demo}
              close={handleShowIntro}
              cache={cacheRef.current}
              goBack={goToSpaceshipEngraving}
              getSpaceshipColor={getSpaceshipColor}
              finished={onFinishedSpaceshipCustomization}
              getSpaceshipClassNameColor={getSpaceshipClassNameColor}
            />
          </div>
        </SwiperSlide>
      </Swiper>

      {showIntro && <Intro close={onClose} demo />}
    </ContentStyled>
  );
};

export default Customization;
