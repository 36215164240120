// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import {
  SpaceshipItemProps,
  SpaceshipItemsProps,
  SpaceshipItemsStateProps,
  SpaceshipItemsCounterProps,
} from "interfaces/spaceInvaders/spaceshipItem";
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// utils
import shopItemUtils from "utils/shopItemUtils";
import { AllToOptional } from "utils/transformTypeUtils";

const remove = createAction(
  "@async/spaceshipItems/remove",
  (items: SpaceshipItemProps[]) => ({
    payload: { items },
  })
);

const decreaseLifeCycles = createAction(
  "@async/spaceshipItems/decreaseLifeCycles",
  (payload: AllToOptional<SpaceshipResourcesUpdateFirebaseProps>) => ({
    payload,
  })
);

const initialState: SpaceshipItemsStateProps = {
  all: [],
  hash: {},
  counter: {},
};

export const spaceshipItemsSlice = createSlice({
  name: "spaceshipItems",
  initialState,
  reducers: {
    setAll: (state, action: PayloadAction<SpaceshipItemProps[]>) => {
      state.all = action.payload;
    },
    setHash: (state, action: PayloadAction<SpaceshipItemsProps>) => {
      state.hash = action.payload;
    },
    setCounter: (state, action: PayloadAction<SpaceshipItemsProps>) => {
      state.counter = mapCounter(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

function mapCounter(
  spaceshipItems: SpaceshipItemsProps
): SpaceshipItemsCounterProps {
  return Object.keys(spaceshipItems).reduce((acc, key) => {
    const item = spaceshipItems[key];
    const type = Number(key[0]) as unknown as SpaceshipItemEnum;

    if (!item) return acc;

    const quantity = countItems(spaceshipItems, type);

    acc[type] = {
      type,
      quantity,
      capacity: getCapacity(type, quantity),
    };

    return acc;
  }, {} as SpaceshipItemsCounterProps);
}

function countItems(
  spaceshipItems: SpaceshipItemsProps,
  type: SpaceshipItemEnum
): number {
  return Object.keys(spaceshipItems).reduce((acc, key) => {
    const item = spaceshipItems[key];

    if (!item) return acc;
    if (Number(key[0]) !== type) return acc;

    acc += 1;
    return acc;
  }, 0);
}

function getCapacity(type: SpaceshipItemEnum, quantity: number): number {
  const Entity = shopItemUtils.getEntity(type);
  const entity = new Entity();

  if (entity.capacity === AbstractShopItemEntity.capacityNone)
    return AbstractShopItemEntity.capacityNone;
  return entity.capacity * quantity;
}

export const spaceshipItemsActions = {
  async: {
    remove,
    decreaseLifeCycles,
  },
  ...spaceshipItemsSlice.actions,
};
export default spaceshipItemsSlice.reducer;
