// entities
import SpaceshipResourcesStatusEntity, {
  ResourcesProps,
  ResourcesCommonProps,
} from "entities/spaceshipResources/SpaceshipResourcesStatusEntity";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.ul`
  all: unset;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  li {
    display: flex;
    margin: 0 5px;
    font-size: 20px;
    color: ${color.grey.middle};

    .brief {
      margin-right: 8px;
    }

    .water {
      color: #4a8dc6;
    }

    .strong_force {
      color: #7d6599;
    }

    .gold {
      color: #ffd475;
    }

    .bauxite {
      color: #d19a6e;
    }

    .copper {
      color: #c56d4a;
    }

    .sand {
      color: #c7b19a;
    }

    .silver {
      color: #585966;
    }

    .estelin {
      color: #656674;
    }
  }
`;

interface SpaceshipNeededResourcesInfoWidgetProps {
  resources: ResourcesCommonProps[];
}

export function SpaceshipNeededResourcesInfoWidget({
  resources,
}: SpaceshipNeededResourcesInfoWidgetProps) {
  return (
    <Wrapper>
      {resources.map((r, i) => (
        <li key={`${r.alias}_${i}`}>
          `+${r.total}` (
          <span
            className={SpaceshipResourcesStatusEntity.getClassNameColor(r.key)}
          >
            {r.alias}
          </span>
          )
        </li>
      ))}
    </Wrapper>
  );
}

interface SpaceshipResourcesInfoWidgetProps {
  resources: ResourcesProps[];
}

export default function SpaceshipResourcesInfoWidget({
  resources,
}: SpaceshipResourcesInfoWidgetProps) {
  return (
    <Wrapper>
      {resources.map((r, i) => (
        <li key={`${r.alias}_${i}`}>
          <span className="brief">
            {`${r.raw} + ${r.shifting} + ${r.transformed}`} = {r.total}
          </span>
          <span className="nerdfy_grey_stronger">[</span>
          <span
            className={SpaceshipResourcesStatusEntity.getClassNameColor(r.key)}
          >
            {r.alias}
          </span>
          <span className="nerdfy_grey_stronger">]</span>
        </li>
      ))}
    </Wrapper>
  );
}
