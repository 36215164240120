// react
import { useState, useEffect } from "react";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { ChallengeCommonProps } from "interfaces/challenge";

// enums
import { BubbleType } from "enums/bubbleEnum";

// components
// import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// icons
import IconWhoreAlert from "components/iconWhores/IconWhoreAlert";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface DemoVictoryAlertHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

const DemoVictoryAlertHandler = ({
  paused,
  wrapper,
  challenge,
}: DemoVictoryAlertHandlerProps) => {
  const { flowFinished } = challenge;
  const [whoresMessage, setWhoresMessage] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [flowFinished]);

  function bootstrap() {
    if (!flowFinished) return;
    setWhoresMessage([first()]);
  }

  function first() {
    return getMessage(`Uhuuull!! Veja! A barreira sumiu!`, true);
  }

  function getMessage(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreAlert,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "red",
      wrapperWidth: wrapper.clientWidth,
      bubbleType: BubbleType.JarvisAlert,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  return (
    <>
      <AttentionWhores
        paused={paused}
        whores={whoresMessage}
        clear={challenge.flowDone}
        disabledDrop={!!attentionWhore.show}
      />

      <BubbleHandler
        hide={hideBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      {/* <DropZoneAttentionWhore /> */}
    </>
  );
};

export default DemoVictoryAlertHandler;
