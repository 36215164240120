// react
import { useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector } from "react-redux";

// interfaces
import { ExplosionProps } from "interfaces/spaceInvaders/explosion";

// components
import {
  ExplosionFactory,
  ExplosionParticleFactory,
} from "factories/spaceInvaders/ExplosionFactory";

// utils
import globalColor from "styles/color";

const PARTICLE_SIZE = 20;
const AXIS = 15;

interface ExplosionsProps {
  wrapperEl: HTMLDivElement | null;
}

const Explosions = ({ wrapperEl }: ExplosionsProps) => {
  const explosions = useSelector(s.explosions());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [explosions]);

  function listen() {
    if (!wrapperEl) return;

    const explosion = explosions[explosions.length - 1];
    if (!explosion) return;

    const explosionEl = ExplosionFactory();
    wrapperEl.appendChild(explosionEl);

    setFirstPosition(explosion, explosionEl);
    add(explosion, explosionEl);
  }

  function setFirstPosition(
    explosion: ExplosionProps,
    explosionEl: HTMLDivElement
  ) {
    explosionEl.style.left = `${explosion.position.x}px`;
    explosionEl.style.top = `${explosion.position.y}px`;
  }

  function add(explosion: ExplosionProps, explosionEl: HTMLDivElement) {
    const { x, y } = explosion.position;

    if (!x) return;
    if (!y) return;

    for (let i = 0; i < PARTICLE_SIZE; i++) {
      let explodeX = getRandomPosition(AXIS, i);
      let explodeY = getRandomPosition(AXIS, i);
      const particle = ExplosionParticleFactory();

      particle.style.left = `${explodeX}px`;
      particle.style.top = `${explodeY}px`;
      particle.style.backgroundColor = `${
        explosion.color || globalColor.grey.strong
      }`;

      if (i === 0) particle.onanimationend = () => explosionEl.remove();
      explosionEl.appendChild(particle);
    }
  }

  return null;
};

function getRandomPosition(axis: number, i: number) {
  return (
    axis / 2 +
    rand(10, 30) *
      Math.cos((2 * Math.PI * i) / rand(PARTICLE_SIZE - 10, PARTICLE_SIZE + 10))
  );
}

function rand(min: number, max: number) {
  return Math.floor(Math.random() * (max + 1)) + min;
}

export default Explosions;
