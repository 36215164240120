// interfaces
import { UserProps } from "interfaces/user";
import { ChallengeCommonProps } from "interfaces/challenge";
import { WalkieTalkieBroadcastFirebaseProps } from "interfaces/walkieTalkie";

// utils
import { serverTimestamp } from "firebase/firestore";

interface ConstructorProps {
  message: string;
  user: UserProps;
  challenge: ChallengeCommonProps;
}

class WalkieTalkieBroadcastDataFirebaseEntity {
  private _user: UserProps;
  private _message: string;
  private _challenge: ChallengeCommonProps;

  constructor({ message, user, challenge }: ConstructorProps) {
    this._user = user;
    this._message = message;
    this._challenge = challenge;
  }

  get data(): WalkieTalkieBroadcastFirebaseProps {
    return {
      userId: this._user.id,
      message: this._message,
      author: this._user.codename,
      createdAt: serverTimestamp(),
      challengeId: this._challenge.id,
      languageId: this._challenge.languageId,
      classRoomId: this._challenge.classRoomId,
      challengeSectionId: this._challenge.challengeSectionId,
    };
  }
}

export default WalkieTalkieBroadcastDataFirebaseEntity;
