// react
import { useState, useEffect } from "react";

// components
import Thumbs from "components/Thumbs";
import AvatarIconHandler from "handlers/AvatarIconHandler";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  z-index: 100;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;

  .feedback_wrapper {
    display: flex;

    .feedback_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0 10px;

      &:last-child {
        margin: 0 0 0 10px;
      }
    }

    .f_button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      font-size: 16px;
      padding: 18px;

      &.f_button_star {
        svg {
          color: ${color.yellow};
        }
      }

      &.f_button_comment {
        svg {
          color: ${color.purple};
        }
      }

      &.f_button_down {
        svg {
          color: ${color.red};
        }
      }

      &.f_button_up {
        svg {
          color: ${color.green};
        }
      }

      &:last-child {
        margin: 0 0 0 10px;
      }
    }
  }
`;

interface ChallengeFeedbackProps {
  show: boolean;
  animate: boolean;
  challenge: ChallengeCommonProps;
  submitThumbs(up: boolean, down: boolean): void;
}

const FeedbackBubble = ({
  show,
  animate,
  challenge,
  submitThumbs,
}: ChallengeFeedbackProps) => {
  const [showWithAnimation, setShowWithAnimation] = useState(false);
  const [thumbs, setThumbs] = useState({ up: false, down: false });
  const { up, down } = challenge;

  useEffect(handleAnimation, [animate]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleThumbs, [up, down]);

  function handleAnimation() {
    if (!animate) return;
    setTimeout(() => setShowWithAnimation(true), 1500);
  }

  function handleThumbs() {
    if (thumbs.up || thumbs.down) return;
    setThumbs({ up: !!up, down: !!down });
  }

  function addThumbsUp() {
    if (thumbs.up) return;

    submitThumbs(true, false);
    setThumbs({ up: true, down: false });
  }

  function addThumbsDown() {
    if (thumbs.down) return;

    submitThumbs(false, true);
    setThumbs({ up: false, down: true });
  }

  return (
    <>
      {(showWithAnimation || show) && (
        <Wrapper
          className={`${animate && "animate__animated animate__bounceInDown"}`}
        >
          <AvatarIconHandler
            type={challenge.type}
            avatarGradient={challenge.flowSuccess}
            avatarInvader={challenge.avatarInvader}
            avatarColor={
              challenge.flowDone && !challenge.flowSuccess
                ? color.danger
                : undefined
            }
          />

          <div className="feedback_wrapper no-swipe">
            <Thumbs
              is={thumbs.down}
              onClick={addThumbsDown}
              buttonClassName="f_button f_button_down"
            />

            <Thumbs
              up
              is={thumbs.up}
              onClick={addThumbsUp}
              buttonClassName="f_button f_button_up"
            />
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default FeedbackBubble;
