// components
import PlayerMaleIcon from "icons/PlayerMaleIcon";
import PlayerFemaleIcon from "icons/PlayerFemaleIcon";
import PlayerUnknownGenreIcon from "icons/PlayerUnknownGenreIcon";

// enums
import { UserGenreType } from "enums/userEnum";

interface PlayerIconProps {
  genre: UserGenreType;
}

const PlayerIconHandler = ({ genre = UserGenreType.Male }: PlayerIconProps) => {
  return genre === UserGenreType.Male ? (
    <PlayerMaleIcon />
  ) : genre === UserGenreType.Female ? (
    <PlayerFemaleIcon />
  ) : (
    <PlayerUnknownGenreIcon />
  );
};

export default PlayerIconHandler;
