interface SvgProps {
  width?: string;
  height?: string;
}

const StrongForceSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      id="Layer_1"
      height={height}
      viewBox="0 0 512 512"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="453.426"
        cy="173.125"
        fill="#57c6fd"
        r="47.562"
        transform="matrix(.787 -.617 .617 .787 -10.177 316.79)"
      />
      <path
        d="m487.833 205.968c11.199-11.755 16.091-28.944 11.381-45.708-7.105-25.288-33.365-40.029-58.654-32.923-.295.083-.588.171-.88.259-11.199 11.755-16.092 28.944-11.382 45.708 7.105 25.288 33.365 40.029 58.654 32.923.296-.083.588-.171.881-.26z"
        fill="#38b4f2"
        fillRule="evenodd"
      />
      <circle
        cx="58.574"
        cy="218.468"
        fill="#e33657"
        r="47.562"
        transform="matrix(.656 -.755 .755 .656 -144.751 119.382)"
      />
      <path
        d="m12.786 231.333c4.655 16.569 17.534 28.609 32.975 32.944l.078-.021c25.288-7.105 40.029-33.365 32.924-58.654-4.655-16.569-17.534-28.609-32.975-32.944l-.078.021c-25.288 7.105-40.029 33.365-32.923 58.654z"
        fill="#fd5959"
        fillRule="evenodd"
      />
      <path
        d="m308.732 244.155 125.982-75.696c6.429-3.862 14.848-1.763 18.711 4.666 3.862 6.429 1.763 14.849-4.665 18.711l-125.982 75.696c-6.429 3.863-14.848 1.763-18.711-4.665-3.863-6.429-1.763-14.849 4.666-18.712z"
        fill="#cadbfd"
        fillRule="evenodd"
      />
      <path
        d="m453.426 173.126c3.862 6.429 1.763 14.848-4.665 18.711l-125.982 75.696c-6.428 3.862-14.846 1.764-18.709-4.662l-.002-.004 149.359-89.741z"
        fill="#abc7f0"
        fillRule="evenodd"
      />
      <path
        d="m75.416 209.074 141.386 40.143c7.215 2.048 11.441 9.627 9.393 16.842-2.048 7.215-9.627 11.442-16.842 9.393l-141.386-40.143c-7.215-2.048-11.441-9.627-9.393-16.841 2.049-7.215 9.627-11.442 16.842-9.394z"
        fill="#abc7f0"
        fillRule="evenodd"
      />
      <path
        d="m226.195 266.059c-2.048 7.215-9.627 11.441-16.842 9.393l-141.386-40.143c-7.215-2.048-11.441-9.627-9.393-16.841l167.62 47.591z"
        fill="#cadbfd"
        fillRule="evenodd"
      />
      <circle
        cx="257.521"
        cy="283.643"
        fill="#9eefff"
        r="79.57"
        transform="matrix(.707 -.707 .707 .707 -125.139 265.172)"
      />
      <g fillRule="evenodd">
        <path
          d="m271.223 362.028c2.608-.459 5.218-1.05 7.821-1.782 42.307-11.887 66.967-55.82 55.08-98.126-8.546-30.418-33.659-51.714-62.823-56.862-2.608.459-5.218 1.05-7.821 1.782-42.307 11.887-66.967 55.82-55.08 98.127 8.547 30.418 33.66 51.714 62.823 56.862z"
          fill="#65e4fd"
        />
        <path
          d="m235.759 238.103-34.712-142.816c-1.771-7.288 2.742-14.699 10.029-16.47 7.288-1.772 14.699 2.742 16.47 10.029l34.712 142.816c1.771 7.288-2.742 14.699-10.029 16.47-7.288 1.772-14.699-2.741-16.471-10.029z"
          fill="#abc7f0"
        />
        <path
          d="m235.759 238.103-34.712-142.816c-1.771-7.288 2.742-14.699 10.029-16.47s41.153 169.316 41.153 169.316l-.012.003c-7.284 1.764-14.689-2.748-16.46-10.032z"
          fill="#cadbfd"
        />
        <path
          d="m315.899 303.513 105.177 102.66c5.367 5.238 5.472 13.915.234 19.282-5.239 5.368-13.916 5.472-19.283.234l-105.177-102.66c-5.367-5.239-5.472-13.915-.233-19.282 5.239-5.368 13.916-5.473 19.283-.234z"
          fill="#cadbfd"
        />
        <path
          d="m421.309 425.456c-5.239 5.367-13.916 5.472-19.283.233l-105.177-102.66c-5.367-5.239-5.472-13.915-.233-19.282z"
          fill="#abc7f0"
        />
        <path
          d="m374.254 438.677c7.302 25.988 34.289 41.136 60.277 33.834s41.136-34.289 33.834-60.277-34.289-41.136-60.277-33.834-41.136 34.289-33.834 60.277z"
          fill="#eb6c28"
        />
        <path
          d="m397.38 468.075c10.909 6.125 24.155 8.087 37.15 4.436 25.988-7.302 41.136-34.289 33.834-60.277-1.191-4.237-2.907-8.184-5.055-11.794-10.908-6.125-24.154-8.087-37.15-4.436-25.988 7.302-41.136 34.289-33.834 60.277 1.19 4.237 2.907 8.184 5.054 11.794z"
          fill="#fd8140"
        />
        <path
          d="m226.93 321.639-98.554 109.034c-5.029 5.564-13.696 6.001-19.259.972-5.564-5.029-6.001-13.696-.972-19.26l98.554-109.034c5.029-5.564 13.696-6.001 19.259-.972 5.564 5.03 6.001 13.696.972 19.26z"
          fill="#cadbfd"
        />
        <path
          d="m226.93 321.639-98.554 109.034c-5.029 5.564-13.696 6.001-19.259.972s116.842-129.265 116.842-129.265c5.564 5.029 6.001 13.696.972 19.259z"
          fill="#abc7f0"
        />
      </g>
      <circle
        cx="104.248"
        cy="437.03"
        fill="#20a48d"
        r="61.987"
        transform="matrix(.951 -.31 .31 .951 -130.47 53.924)"
      />
      <path
        d="m44.572 453.797c9.26 32.958 43.485 52.169 76.443 42.909 5.667-1.592 10.928-3.923 15.707-6.858 8.531-14.142 11.428-31.639 6.608-48.794-9.26-32.958-43.485-52.169-76.444-42.909-5.667 1.592-10.927 3.923-15.706 6.858-8.531 14.143-11.429 31.639-6.609 48.795z"
        fill="#28d481"
        fillRule="evenodd"
      />
      <circle
        cx="211.076"
        cy="78.816"
        fill="#fcbc40"
        r="65.832"
        transform="matrix(.05 -.999 .999 .05 121.858 285.711)"
      />
      <path
        d="m264.522 40.349c-15.697-21.805-43.886-32.6-71.253-24.911-32.424 9.11-52.316 40.972-47.254 73.44 15.697 21.805 43.886 32.6 71.253 24.911 32.424-9.11 52.316-40.972 47.254-73.44z"
        fill="#fddd41"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default StrongForceSvg;
