// interfaces
import {
  WalkieTalkieBroadcastProps,
  WalkieTalkieBroadcastFirebaseProps,
} from "interfaces/walkieTalkie";

function map(
  id: string,
  data: WalkieTalkieBroadcastFirebaseProps
): WalkieTalkieBroadcastProps {
  const timestamp = data.createdAt as { seconds: number; nanoseconds: number };

  return {
    id,
    author: data.author,
    userId: data.userId,
    message: data.message,
    languageId: data.languageId,
    timestamp: timestamp.seconds,
    classRoomId: data.classRoomId,
    challengeId: data.challengeId,
    challengeSectionId: data.challengeSectionId,
  };
}

const walkieTalkieParser = {
  map,
};

export default walkieTalkieParser;
