interface SvgProps {
  width?: string;
  height?: string;
}

const JarvisPointingFingersSvg = ({
  width = "18",
  height = "18",
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          fill="#ffd764"
          d="M462.452 90.842H333.97c-18.322 0-36.417-2.945-54.283-7.006a50.674 50.674 0 0 0-10.43-1.252c-16.741-.266-31.729 7.156-41.737 18.947-4.026 4.743-9.645 7.836-15.861 8.107-5.726.249-11.18-1.948-15.511-5.703-9.411-8.158-21.682-13.1-35.115-13.1-14.552 0-27.734 5.806-37.395 15.215-4.858 4.732-11.021 7.894-17.796 8.189l-3.041.133c-6.734.294-13.178-2.026-18.73-5.848a53.39 53.39 0 0 0-29.002-9.412c-15.05-.383-28.639 5.769-38.552 15.763l-9.032 40.152v133.187c0 22.804 18.486 41.232 41.29 41.232h17.291l33.041-7.383a41.11 41.11 0 0 0 8.25-24.767l8.291 26.046c0 22.804 18.486 41.29 41.29 41.29h16.483l33.035-9.536a41.1 41.1 0 0 0 8.255-24.768l21.484 36.019-.688 1.612a49.37 49.37 0 0 0-4.28 20.176c0 22.804 18.487 41.29 41.29 41.29h107.355c27.365 0 49.548-22.184 49.548-49.548V214.713l-6.094-8.526 6.094-7.99h20.667c26.083 0 50.463-15.683 58.931-40.354 11.917-34.717-13.555-67.001-46.566-67.001z"
          opacity="1"
          data-original="#d48c64"
        ></path>
        <g fill="#c6805d">
          <path
            d="M41.29 338.584h24.774c13.468 0 25.504-6.545 33.041-16.523H41.29c-13.682 0-24.774-11.092-24.774-24.774V114.875C6.321 125.163 0 139.535 0 155.026v142.326c0 22.804 18.486 41.232 41.29 41.232zM148.645 355.094c-13.682 0-24.774-11.092-24.774-24.774V173.416a8.258 8.258 0 0 0-8.258-8.258 8.258 8.258 0 0 0-8.258 8.258v156.91c0 22.804 18.486 41.29 41.29 41.29h24.774c13.468 0 25.498-6.545 33.035-16.523h-57.809zM256 371.61h24.774c13.465 0 25.308-6.542 32.845-16.516H256c-13.682 0-24.774-11.092-24.774-24.774V173.416a8.258 8.258 0 0 0-8.258-8.258 8.258 8.258 0 0 0-8.258 8.258V330.32c0 22.804 18.486 41.29 41.29 41.29zM355.097 330.32c-9.122 0-16.516-7.395-16.516-16.516V173.416a8.258 8.258 0 0 0-8.258-8.258 8.258 8.258 0 0 0-8.258 8.258v173.419h33.032a8.258 8.258 0 0 0 0-16.515zM429.419 198.197c-26.998 0-49.615-18.527-55.963-43.55-.907-3.576-4.228-6.005-7.917-6.005-5.345 0-9.418 5.037-8.089 10.215 8.23 32.074 37.364 55.857 71.969 55.857z"
            fill="#ffaa4d"
            opacity="1"
            data-original="#c6805d"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default JarvisPointingFingersSvg;
