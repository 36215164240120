// components
import { NavSkeleton } from "components/classRoom/ClassRoom";
import ButtonsAccordionHandler from "handlers/buttonsAccordion/ButtonsAccordionHandler";

// interfaces
import {
  ChallengeProps,
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  .section {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 20px 0;
    border-radius: 10px;

    .header {
      display: flex;
      align-items: center;
      height: 50px;
      margin-bottom: 20px;

      h2 {
        font-size: 25px;
        color: ${color.gold};
        padding: 0;
        margin: 0;
      }
    }

    ul {
      all: unset;
      width: auto;
      padding: 10px 20px;

      li {
        all: unset;
        float: left;
      }
    }
  }
`;

interface ClassRoomAccordionProps {
  openChallengeModal: (
    challenge: ChallengeProps,
    section: ChallengeSectionProps
  ) => void;
  openChallengeQuizModal(
    challenge: ChallengeQuizProps,
    section: ChallengeSectionProps
  ): void;
  sections: ChallengeSectionProps[];
  challenges: ChallengesCommonHashProps;
}

const ClassRoomAccordion = ({
  sections,
  challenges,
  openChallengeModal,
  openChallengeQuizModal,
}: ClassRoomAccordionProps) => {
  return (
    <Wrapper>
      <>
        {challenges && sections.length > 0 ? (
          sections.map((section: ChallengeSectionProps) => {
            const challengesFromSection = challenges[section.id] || [];

            return (
              <div className="section" key={section.id}>
                <div className="header pixelify_font_family">
                  <h2>{section.name}</h2>
                </div>

                <ul>
                  {challengesFromSection.map(
                    (challenge: ChallengeCommonProps) => {
                      return (
                        <li key={`${challenge.type}_${challenge.id}`}>
                          <ButtonsAccordionHandler
                            section={section}
                            challenge={challenge}
                            challenges={challenges[section.id]}
                            openChallengeModal={openChallengeModal}
                            openChallengeQuizModal={openChallengeQuizModal}
                          />
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            );
          })
        ) : (
          <NavSkeleton />
        )}
      </>
    </Wrapper>
  );
};

export default ClassRoomAccordion;
