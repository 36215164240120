// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// entities
import LaseItemEntity from "entities/shopItems/LaseItemEntity";
import RepairItemEntity from "entities/shopItems/RepairItemEntity";
import Printer2dItemEntity from "entities/shopItems/Printer2dItemEntity";
import EncyclopediaItemEntity from "entities/shopItems/EncyclopediaItemEntity";
import RadioStationItemEntity from "entities/shopItems/RadioStationItemEntity";
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";
import AmateurRadioItemEntity from "entities/shopItems/AmateurRadioItemEntity";
import RefineMachineItemEntity from "entities/shopItems/RefineMachineItemEntity";
import MineralStorageItemEntity from "entities/shopItems/MineralStorageItemEntity";

function getEntity(type: SpaceshipItemEnum): new () => AbstractShopItemEntity {
  if (type === SpaceshipItemEnum.LaserGun) return LaseItemEntity;
  if (type === SpaceshipItemEnum.Repair) return RepairItemEntity;
  if (type === SpaceshipItemEnum.Printer2d) return Printer2dItemEntity;
  if (type === SpaceshipItemEnum.AmateurRadio) return AmateurRadioItemEntity;
  if (type === SpaceshipItemEnum.RadioStation) return RadioStationItemEntity;
  if (type === SpaceshipItemEnum.RefineMachine) return RefineMachineItemEntity;
  if (type === SpaceshipItemEnum.MineralStorage)
    return MineralStorageItemEntity;
  if (type === SpaceshipItemEnum.Encyclopedia) return EncyclopediaItemEntity;

  return AmateurRadioItemEntity;
}

const shopItemUtils = {
  getEntity,
};

export default shopItemUtils;
