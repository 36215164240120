import {
  ChallengeSectionProps,
  ChallengeSectionResourceProps,
} from "interfaces/challengeSection";

function list(
  sections: ChallengeSectionResourceProps[]
): ChallengeSectionProps[] {
  return sections.map(map).sort((a, b) => {
    if (a.index > b.index) return 1;
    if (a.index < b.index) return -1;

    return 0;
  });
}

function map(section: ChallengeSectionResourceProps): ChallengeSectionProps {
  return {
    id: section.id,
    classRoomId: section.classroomId,
    languageId: section.languageId,
    name: section.name,
    index: section.index,
    done: false,
    progress: 0,
  };
}

const challengeSectionParser = {
  list,
  map,
};

export default challengeSectionParser;
