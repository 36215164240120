// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";
import PlayerMaleSvg from "icons/PlayerMaleIcon";

// utils
import styled from "styled-components";
import c from "styles/color";

const Wrapper = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ButtonDuolingoIconProps {
  onClick(): void;
  color?: string;
  customClassName?: string;
  disabled?: boolean;
}

const ButtonDuolingoPlayerMale = ({
  onClick,
  disabled,
  customClassName,
  color = c.grey.strong,
}: ButtonDuolingoIconProps) => {
  return (
    <Wrapper onClick={onClick} className={customClassName} disabled={disabled}>
      <PlayerMaleSvg width="55" height="55" color={color} />
    </Wrapper>
  );
};

export default ButtonDuolingoPlayerMale;
