// interfaces
import { ChallengesUpdateProps } from "interfaces/challenge";
import { ChallengeFirebaseProps } from "interfaces/challengeFirebase";

function map(challenge: ChallengeFirebaseProps): ChallengesUpdateProps {
  const { id, type } = challenge;

  return {
    id,
    uuid: `${type}_${id}`,
    frame: challenge.frame || 0,
    currentCode: challenge.currentCode || "",
    speechProgress: challenge.speechProgress || {},
    challengeSectionId: challenge.challengeSectionId,

    // flow
    flowDone: !!challenge.flowDone,
    flowSuccess: !!challenge.flowDone,

    // gained
    pxGained: challenge.pxGained || 0,
    scoreGained: challenge.scoreGained || 0,

    // feedback
    up: !!challenge.up,
    down: !!challenge.down,
    feedback: challenge.feedback || "",
  };
}

const challengeFirebaseParser = {
  map,
};

export default challengeFirebaseParser;
