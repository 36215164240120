// redux
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction } from "@reduxjs/toolkit";

// enums
import { ChallengeType } from "enums/challengeEnum";
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

// interfaces
import {
  ChallengeQuizProps,
  ChallengeQuizUpdateProps,
  ChallengeQuizAnswersHashProps,
} from "interfaces/challengeQuiz";

const init = createAction("@async/challengeQuiz/init");
const start = createAction("@async/challengeQuiz/start");
const finish = createAction("@async/challengeQuiz/finish");
const unFinish = createAction("@async/challengeQuiz/un_finish");
const failed = createAction(
  "@async/challengeQuiz/failed",
  ({ answer }: { answer: ChallengeQuizAnswersHashProps }) => {
    return {
      payload: {
        answer,
      },
    };
  }
);
const success = createAction(
  "@async/challengeQuiz/success",
  ({ answer }: { answer: ChallengeQuizAnswersHashProps }) => {
    return {
      payload: {
        answer,
      },
    };
  }
);

const initialState: ChallengeQuizProps = {
  id: 0,
  uuid: "",
  name: "",
  index: 0,
  answers: [],
  correct: [],
  question: [],
  multi: false,
  languageId: 0,
  generic: false,
  classRoomId: 0,
  gameOver: false,
  challengeSectionId: 0,
  type: ChallengeType.None,
  level: ChallengeQuizLevelType.None,

  // flow
  flowDone: false,
  flowInit: false,
  flowFailed: false,
  flowStarted: false,
  flowSuccess: false,
  flowFinished: false,

  // feedback
  up: false,
  down: false,
  feedback: "",

  // gained
  scoreGained: 0,
  levelPointGained: 0,
};

export const challengeQuizSlice = createSlice({
  name: "challengeQuiz",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ChallengeQuizProps>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<ChallengeQuizUpdateProps>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const challengeQuizActions = {
  async: {
    init,
    start,
    finish,
    failed,
    success,
    unFinish,
  },
  ...challengeQuizSlice.actions,
};

export default challengeQuizSlice.reducer;
