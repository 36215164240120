// react
import {
  IonMenu,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonContent,
} from "@ionic/react";

// redux
import { useDispatch } from "react-redux";
import { appActions } from "redux/slices/appSlice";

// components
import Footer from "components/Footer";
import CodeName from "components/CodeName";
import ButtonDuolingoRed from "components/buttons/templates/ButtonDuolingoRed";

// utils
import color from "styles/color";
import styled from "styled-components";

const Menu = styled(IonMenu)`
  .header {
    display: flex;
    height: 100%;

    h2 {
      font-size: 20px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .body {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: ${color.dark.bg};
      padding: 20px;

      .logout_button {
        padding: 10px 30px;
      }
    }
  }
`;

interface HeaderMenuProps {
  username: string;
  signed: boolean;
}

const HeaderMenu = ({ username, signed }: HeaderMenuProps) => {
  const dispatch = useDispatch();

  function signOut() {
    if (!signed) return;
    setTimeout(() => dispatch(appActions.logout()));
  }

  return (
    <Menu contentId="mainContent" type="reveal" className="safe--area">
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonTitle slot="start">
            <div className="header">
              <CodeName />
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="content">
          <div className="body">
            <ButtonDuolingoRed
              onClick={signOut}
              disabled={!signed}
              customClassName="logout_button"
            >
              Sair
            </ButtonDuolingoRed>
          </div>

          <Footer />
        </div>
      </IonContent>
    </Menu>
  );
};

export default HeaderMenu;
