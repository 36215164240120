interface SvgProps {
  width?: string;
  height?: string;
}

const AmateurRadioPixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M481.9 60.2V30.1H30.1v30.1H0v391.6h30.1v30.1h451.8v-30.1H512V60.2z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <g fill="#6e67d5">
          <path
            d="M30.1 60.2h451.8v30.1H30.1zM30.1 421.6h451.8v30.1H30.1zM30.1 120.5v271.1h451.8V120.5zm421.7 210.8h-30.1v30.1h-30.1v-30.1h-30.1v-30.1h30.1v-30.1h30.1v30.1h30.1zm0-120.5h-30.1v30.1h-30.1v-30.1h-30.1v-30.1h30.1v-30.1h30.1v30.1h30.1z"
            fill="#6e67d5"
            opacity="1"
            data-original="#6e67d5"
          ></path>
        </g>
        <g fill="#00a8e1">
          <path
            d="M391.5 301.2h30.1v30.1h-30.1zM391.5 180.7h30.1v30.1h-30.1zM301.2 331.3h30.1v30.1h-30.1zM301.2 210.8h30.1v90.4h-30.1zM240.9 271.1H271v90.4h-30.1zM240.9 210.8H271v30.1h-30.1zM180.7 331.3h30.1v30.1h-30.1zM180.7 210.8h30.1v90.4h-30.1zM120.5 271.1h30.1v90.4h-30.1zM120.5 210.8h30.1v30.1h-30.1zM60.2 331.3h30.1v30.1H60.2zM60.2 210.8h30.1v90.4H60.2z"
            fill="#00a8e1"
            opacity="1"
            data-original="#00a8e1"
          ></path>
        </g>
        <g fill="#ffc906">
          <path
            d="M301.2 301.2h30.1v30.1h-30.1zM301.2 150.6h30.1v30.1h-30.1zM240.9 240.9H271V271h-30.1zM240.9 150.6H271v30.1h-30.1zM180.7 301.2h30.1v30.1h-30.1zM180.7 150.6h30.1v30.1h-30.1zM120.5 240.9h30.1V271h-30.1zM120.5 150.6h30.1v30.1h-30.1zM60.2 301.2h30.1v30.1H60.2zM60.2 150.6h30.1v30.1H60.2z"
            fill="#ffc906"
            opacity="1"
            data-original="#ffc906"
          ></path>
        </g>
        <g fill="#54469c">
          <path
            d="M451.8 331.3h-30.2v60.2h60.3v-271h-30.1zM421.6 60.2h60.2v30.1h-60.2zM391.5 421.6h90.4v30.1h-90.4z"
            fill="#54469c"
            opacity="1"
            data-original="#54469c"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default AmateurRadioPixelSvg;
