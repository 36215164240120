// utils
import numberUtils from "utils/numberUtils";

const LIMIT_TOP = 100;
const LIMIT_BOTTOM = 200;
const LIMIT_LEFT = 100;
const LIMIT_RIGHT = 100;

const LIMIT_DEFAULT = {
  top: LIMIT_TOP,
  bottom: LIMIT_BOTTOM,
  left: LIMIT_LEFT,
  right: LIMIT_RIGHT,
};

interface GetRandomPositionProps {
  wrapper: { width: number; height: number };
  limit?: { top: number; bottom: number; left: number; right: number };
}

function getRandomPosition({
  wrapper,
  limit = LIMIT_DEFAULT,
}: GetRandomPositionProps): {
  x: number;
  y: number;
} {
  return {
    x: numberUtils.randomInterval(limit.left, wrapper.width - limit.right),
    y: numberUtils.randomInterval(limit.top, wrapper.height - limit.bottom),
  };
}

const dragUtils = {
  getRandomPosition,
};

export default dragUtils;
