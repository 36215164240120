// react
import { IonicSlides } from "@ionic/react";
import { useState, useRef, useCallback, useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { authActions } from "redux/slices/authSlice";

// enums
import { BubbleType } from "enums/bubbleEnum";
import { UserGenreType } from "enums/userEnum";
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// parsers
import authParser from "parsers/authParser";

// icons
import IconWhoreMessage from "components/iconWhores/IconWhoreMessage";

// services
import FirebaseAuthService from "services/auth/FirebaseAuthService";
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// firebase
import { UserCredential } from "firebase/auth";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import Modal from "components/Modal";
import Footer from "components/Footer";
import Loading from "components/Loading";
import ProgressBar from "components/ProgressBar";
import FormHeader from "components/form/FormHeader";
import { DropZoneAttentionWhore } from "components/DropZone";
import { PlayerSpaceshipDataProps } from "interfaces/player";
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import NerdXFooter from "components/presentations/spaceship/NerdXFooter";
import SpaceshipOrder, {
  OrderProps,
} from "components/presentations/spaceship/SpaceshipOrder";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";
import PlayerGenre from "components/presentations/player/PlayerGenre";
import PlayerNaming from "components/presentations/player/PlayerNaming";
import SpaceshipItemsWrapper from "components/presentations/spaceship/SpaceshipItemsWrapper";
import SelectSpaceshipWrapper from "components/presentations/spaceship/SelectSpaceshipWrapper";

// entities
import GodNameEntity from "entities/GodNameEntity";
import JarvisEntity from "entities/characters/JarvisEntity";
import SpaceshipItemsEntity from "entities/SpaceshipItemsEntity";
import SpaceshipEngravingEntity from "entities/SpaceshipEngravingEntity";
import { PlayerCheckpointDemoStartedDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";
import { PlayerCheckpointCompanyNerdXDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";

// utils
import color from "styles/color";
import styled from "styled-components";
import authUtils from "utils/authUtils";
import attentionWhoreUtils from "utils/attentionWhoreUtils";
import spaceshipUtils from "utils/spaceInvaders/spaceshipUtils";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${color.dark.bg};

  .slide {
    flex: 1;
    display: flex;
    flex-direction: column;

    .player_genre {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .nerdX_order {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .player_genre {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;

interface PlayerNamingSlideProps {
  goForward: () => void;
}

const PlayerNamingSlide = ({ goForward }: PlayerNamingSlideProps) => {
  return (
    <div className="d_flex_column_1 relative">
      <FormHeader brand proceed={goForward} />
      <PlayerNaming demo />
      <Footer />
    </div>
  );
};

interface CacheProps {
  genre: UserGenreType | null;
  spaceshipColor: SpaceshipColorType | null;
}

interface OrderSlideProps {
  cache: CacheProps;
  goBack: () => void;
  finished: () => void;
  getSpaceshipColor(): string;
  wrapper: HTMLDivElement | null;
  getSpaceshipClassNameColor(): string;
}

const OrderSlide = ({
  cache,
  goBack,
  wrapper,
  finished,
  getSpaceshipColor,
  getSpaceshipClassNameColor,
}: OrderSlideProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);

  function getOrder(): OrderProps {
    return {
      radar: 1,
      reload: 1,
      bullets: 3,
      engraving: SpaceshipEngravingEntity.defaultName,
    };
  }

  function save() {
    signInAnonymously();
  }

  async function signInAnonymously() {
    try {
      setError("");
      setIsSaved(true);
      setLoading(true);

      const resource = new FirebaseAuthService();
      const { user }: UserCredential = await resource.signInAnonymously();
      const accessToken = await user.getIdToken();
      authUtils.setAccessToken(accessToken);

      completeSignIn(user.uid);
    } catch (_err: unknown) {
      setLoading(false);

      setError(
        "Ops, ocorreu um erro ao fazer o login, por favor, tente novamente."
      );
    }
  }

  async function completeSignIn(uid: string) {
    if (isSaved) return;

    const { genre, spaceshipColor } = cache;
    if (!genre) return;
    if (!spaceshipColor) return;

    try {
      setError("");
      setLoading(true);

      const { items, gunId } = new SpaceshipItemsEntity().initialItems;
      const player: PlayerFirebaseUpdateProps = {
        genre,
        gunId,
        shopItems: items,
        checkpoint: getCheckpoints(),
        spaceship: getSpaceshipFeatures(spaceshipColor),
      };

      await new PlayerFirebaseService().update(uid, player);
      dispatch(authActions.set(authParser.fake(uid, genre)));

      notify();
      setTimeout(() => {
        setWhores([one(), two(), three(), four(), five()]);
      }, 250);
    } catch (_err: unknown) {
      setError(
        "Ops, ocorreu um erro ao salvar os itens, por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }

  function getSpaceshipFeatures(
    spaceshipColor: SpaceshipColorType
  ): PlayerSpaceshipDataProps {
    return {
      ...getOrder(),
      color: spaceshipColor,
    };
  }

  function getCheckpoints() {
    const entityNerdX = new PlayerCheckpointCompanyNerdXDataFirebaseEntity();
    const entityDemoStarted =
      new PlayerCheckpointDemoStartedDataFirebaseEntity();

    return {
      ...entityNerdX.values,
      ...entityDemoStarted.values,
    };
  }

  function one() {
    const payload = "A partir de agora somos uma dupla...";
    return message(payload, wrapper);
  }

  function two() {
    const payload =
      "Para eu atirar, preciso que você mire no alvo de acordo com a distância de detecção do canhão a laser...";
    return message(payload, wrapper);
  }

  function three() {
    const payload =
      "Antes que você pergunte, a distância de detecção é simplesmente a distância máxima à qual o canhão a laser pode detectar um alvo.";
    return message(payload, wrapper);
  }

  function four() {
    const payload = "De resto... deixa com o papai aqui.";
    return message(payload, wrapper);
  }

  function five() {
    const payload =
      "E não se assuste com as criaturas espaciais; elas só representam uma ameaça quando ocorre um erro... Se isso acontecer, é de EXTREMA importância DESTRUÍ-LAS imediatamente.";
    return message(payload, wrapper, true);
  }

  function message(
    payload: string,
    wrapper: HTMLDivElement | null,
    last?: boolean
  ): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreMessage,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      bubbleType: BubbleType.Message,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });

    setTimeout(() => {
      attentionWhore.last && finished();
    });
  }

  function notify() {
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `A empresa "NerdX" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  return (
    <div className="nerdX_order">
      <Loading loading={loading} />
      <FormHeader
        save={save}
        back={goBack}
        disabled={isSaved}
        disabledBack={isSaved}
      />

      <DropZoneAttentionWhore />
      <AttentionWhores whores={whores} disabledDrop={!!attentionWhore.show} />

      <BubbleHandler
        hide={hideBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <SpaceshipOrder
        order={getOrder()}
        getSpaceshipColor={getSpaceshipColor}
        getSpaceshipClassNameColor={getSpaceshipClassNameColor}
        description={
          <p>
            <span className="nerdfy_pink">Confira</span> o{" "}
            <span className="nerdfy_gold">resumo</span> do seu{" "}
            <span className={getSpaceshipClassNameColor()}>pedido</span>.
          </p>
        }
      />

      {error && <ErrorFeedback err={error} />}
      <NerdXFooter />
    </div>
  );
};

interface PlayerGenreSlideProps {
  goBack: () => void;
  goForward: () => void;
  onSelect: (genre: UserGenreType) => void;
}

const PlayerGenreSlide = ({
  goBack,
  onSelect,
  goForward,
}: PlayerGenreSlideProps) => {
  return (
    <div className="player_genre">
      <FormHeader back={goBack} proceed={goForward} />

      <PlayerGenre
        onSelect={onSelect}
        description={
          <p>
            <span className="nerdfy_orange">Antes</span> de{" "}
            <span className="nerdfy_white">começar</span>, preciso que{" "}
            <span className="nerdfy_white">você</span> me faça um{" "}
            <span className="cmd">juramento</span>.
          </p>
        }
        maleDescription={
          <>
            <p>
              <span className="nerdfy_white">Juro</span> que serei{" "}
              <span className="blue">um</span> humilde{" "}
              <span className="blue">discípulo</span> da gloriosa{" "}
              <span className="warning">{JarvisEntity.unknownName}</span>.
            </p>

            <p>
              Que <span className="purple">{GodNameEntity.defaultName}</span>{" "}
              nos <span className="nerdfy_white">ilumine</span> nessa{" "}
              <span className="bg_nerdfy_gradient">Jornada</span>.{" "}
              <span className="nerdfy_green">Assim Seja</span>.
            </p>
          </>
        }
        femaleDescription={
          <>
            <p>
              <span className="nerdfy_white">Juro</span> que serei{" "}
              <span className="nerdfy_pink">uma</span> humilde{" "}
              <span className="nerdfy_pink">discípula</span>, da gloriosa{" "}
              <span className="warning">{JarvisEntity.unknownName}</span>.
            </p>

            <p>
              Que <span className="purple">{GodNameEntity.defaultName}</span>{" "}
              nos <span className="nerdfy_white">ilumine</span> nessa{" "}
              <span className="bg_nerdfy_gradient">Jornada</span>.{" "}
              <span className="nerdfy_green">Assim Seja</span>.
            </p>
          </>
        }
      />

      {/* <NerdXFooter /> */}
    </div>
  );
};

const PLAYER_NAMING_SLIDE = 0;
const PLAYER_GENRE_SLIDE = 1;
const SPACESHIP_SELECT_SLIDE = 2;
const SPACESHIP_DEFAULT_ITEMS = 3;
const SPACESHIP_RESUME_SLIDE = 4;

interface DemoSignInModalProps {
  show: boolean;
  finished: () => void;
  dismiss?: () => void;
}

const DemoSignInModal = ({ show, dismiss, finished }: DemoSignInModalProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [progress, setProgress] = useState(0);
  const cache = useRef<CacheProps>({
    spaceshipColor: null,
    genre: UserGenreType.Male,
  });
  const handler = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setTimeout(() => {
      wrapperRef.current = node;
    });

    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(handleProgress, [show]);

  function handleProgress() {
    if (!show) return;
    setProgress(0);
  }

  function getSpaceshipClassNameColor(): string {
    if (!cache.current.spaceshipColor) return "";
    return spaceshipUtils.getClassNameColor(cache.current.spaceshipColor);
  }

  function getSpaceshipColor(): string {
    if (!cache.current.spaceshipColor) return "";
    return spaceshipUtils.getColor(cache.current.spaceshipColor);
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  function goToPlayerNaming() {
    setProgress(0);
    slideTo(PLAYER_NAMING_SLIDE);
  }

  function goToPlayerGenre() {
    setProgress(0.25);
    slideTo(PLAYER_GENRE_SLIDE);
  }

  function goToSpaceshipSelection() {
    setProgress(0.5);
    slideTo(SPACESHIP_SELECT_SLIDE);
  }

  function goToShowDefaultItems() {
    setProgress(0.75);
    slideTo(SPACESHIP_DEFAULT_ITEMS);
  }

  function goToSpaceshipResume() {
    setProgress(1);
    slideTo(SPACESHIP_RESUME_SLIDE);
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  function onPlayerGenreSelect(genre: UserGenreType) {
    cache.current.genre = genre;
  }

  function onSpaceshipColorSelect(color: SpaceshipColorType) {
    cache.current.spaceshipColor = color;
  }

  return (
    <Modal isOpen={show} onDidDismiss={dismiss}>
      <Wrapper ref={handler}>
        <ProgressBar value={progress} color="success" />

        <Swiper
          initialSlide={0}
          slidesPerView={1}
          modules={[IonicSlides]}
          onSwiper={handleSwiper}
          noSwipingClass="no_swipe"
        >
          <SwiperSlide>
            <div className="slide no_swipe">
              <PlayerNamingSlide goForward={goToPlayerGenre} />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide no_swipe">
              <PlayerGenreSlide
                goBack={goToPlayerNaming}
                onSelect={onPlayerGenreSelect}
                goForward={goToSpaceshipSelection}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide no_swipe">
              <SelectSpaceshipWrapper
                goBack={goToPlayerGenre}
                goForward={goToShowDefaultItems}
                onSelect={onSpaceshipColorSelect}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide no_swipe">
              <SpaceshipItemsWrapper
                back={goToSpaceshipSelection}
                proceed={goToSpaceshipResume}
                getSpaceshipClassNameColor={getSpaceshipClassNameColor}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide no_swipe">
              <OrderSlide
                finished={finished}
                cache={cache.current}
                wrapper={wrapperRef.current}
                goBack={goToShowDefaultItems}
                getSpaceshipColor={getSpaceshipColor}
                getSpaceshipClassNameColor={getSpaceshipClassNameColor}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </Wrapper>
    </Modal>
  );
};

export default DemoSignInModal;
