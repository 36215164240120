// components
import NerdPlaceholderIcon from "icons/placeholders/NerdPlaceholderIcon";

interface AvatarIconProps {
  width?: string;
  height?: string;
}

const AvatarIconNerdPlaceholder = ({
  width = "28",
  height = "28",
}: AvatarIconProps) => {
  return <NerdPlaceholderIcon width={width} height={height} />;
};

export default AvatarIconNerdPlaceholder;
