// react
import { useState, useEffect } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// widgets
import SpaceshipResourcesInfoWidget from "components/widgets/SpaceshipResourcesInfoWidget";

// entities
import SpaceshipResourcesStatusEntity, {
  ResourcesProps,
} from "entities/spaceshipResources/SpaceshipResourcesStatusEntity";

// utils
import isEmpty from "lodash/isEmpty";

const PlayerProfileResourcesSlide = () => {
  const spaceshipResources = useSelector(s.spaceshipResources());
  const [resourcesBrief, setResourcesBrief] = useState<ResourcesProps[]>([]);

  useEffect(handleResourcesStatus, [spaceshipResources]);

  function handleResourcesStatus() {
    if (isEmpty(spaceshipResources)) return;

    const entity = new SpaceshipResourcesStatusEntity({
      resources: spaceshipResources,
    });

    setResourcesBrief(entity.brief);
  }

  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_brown">Recursos</h1>
      </div>

      <div className="body">
        <SpaceshipResourcesInfoWidget resources={resourcesBrief} />
      </div>
    </div>
  );
};

export default PlayerProfileResourcesSlide;
