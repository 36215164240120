// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesDataProps,
  SpaceshipResourcesSourceDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { SpaceshipResourcesFirebaseDataProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// entities
import {
  AsteroidWaterEntity,
  AsteroidMineralGoldEntity,
  AsteroidMineralSandEntity,
  AsteroidStrongForceEntity,
  AsteroidMineralCopperEntity,
  AsteroidMineralSilverEntity,
  AsteroidMineralEstelinEntity,
  AsteroidMineralBauxiteEntity,
} from "entities/spaceInvaders/AsteroidEntity";

type SpaceshipResourcesHashFirebaseDataProps = {
  [key: string]: SpaceshipResourcesFirebaseDataProps;
};
type SpaceshipResourcesHashOriginalDataProps = {
  [key: string]: SpaceshipResourcesSourceDataProps;
};

function map({ resources = {} }: PlayerFirebaseProps): SpaceshipResourcesProps {
  return {
    // minerals
    water: mapData(AsteroidWaterEntity.alias, resources.water),
    gold: mapData(AsteroidMineralGoldEntity.alias, resources.gold),
    sand: mapData(AsteroidMineralSandEntity.alias, resources.sand),
    copper: mapData(AsteroidMineralCopperEntity.alias, resources.copper),
    silver: mapData(AsteroidMineralSilverEntity.alias, resources.silver),
    bauxite: mapData(AsteroidMineralBauxiteEntity.alias, resources.bauxite),
    estelin: mapData(AsteroidMineralEstelinEntity.alias, resources.estelin),
    strongForce: mapData(
      AsteroidStrongForceEntity.alias,
      resources.strongForce
    ),
  };
}

function mapData(
  alias: string,
  data?: SpaceshipResourcesHashFirebaseDataProps
): SpaceshipResourcesDataProps {
  if (!data) return fakeData(alias);

  const reduced = Object.values(data).reduce((acc, { t, f }) => {
    acc.total += 1;

    if (f) acc.transformed += 1;
    else {
      acc.parcial += 1;

      if (t) acc.shifting += 1;
      else acc.raw += 1;
    }

    return acc;
  }, fakeData() as SpaceshipResourcesDataProps);

  reduced.alias = alias;
  reduced.source = mapOriginalData(data);

  return reduced;
}

function mapOriginalData(
  data: SpaceshipResourcesHashFirebaseDataProps
): SpaceshipResourcesHashOriginalDataProps {
  return Object.keys(data).reduce((acc, key: string) => {
    const d = data[key];
    const turns = (d.t as number) || 0;
    const c = (d.c as { seconds: number; nanoseconds: number }) || {
      seconds: 0,
      nanoseconds: 0,
    };

    acc[key] = { turns, timestamp: c.seconds, transformed: d.f || false };
    return acc;
  }, {} as SpaceshipResourcesHashOriginalDataProps);
}

function fakeData(alias = ""): SpaceshipResourcesDataProps {
  return {
    alias,
    raw: 0,
    total: 0,
    source: {},
    parcial: 0,
    shifting: 0,
    transformed: 0,
  };
}

const spaceshipResourcesParser = {
  map,
  fakeData,
};

export default spaceshipResourcesParser;
