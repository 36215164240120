// utils
import CryptoJS from "crypto-js";

const s = "WfrP8bTLkhXl";

const encode = async (email: string): Promise<string> => {
  const fullHash = CryptoJS.HmacSHA256(email, s).toString(CryptoJS.enc.Hex);
  return fullHash.substring(0, 12);
};

const match = async (email: string, hash: string): Promise<boolean> => {
  return hash === (await encode(email));
};

const hashUtils = {
  match,
  encode,
};

export default hashUtils;

// e=ro.fonseca.melo@gmail.com&h=8f890f50c711
// ZT1yby5mb25zZWNhLm1lbG9AZ21haWwuY29tJmg9OGY4OTBmNTBjNzEx
