import React from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import ProgressStatus from "components/ProgressStatus";
import SpaceshipMachineGunTargetIcon from "icons/SpaceshipMachineGunTargetIcon";

// styles
import { AvatarProgressStyle } from "components/styles/AvatarStatusStyle";

// utils
import color from "styles/color";

interface SpaceshipMachineGunBulletsProps {
  bulletsTotal: number;
}

const SpaceshipMachineGunBullets = ({
  bulletsTotal,
}: SpaceshipMachineGunBulletsProps) => {
  const { bullets, targetVisible } = useSelector(s.spaceshipMachineGun());

  return (
    <AvatarProgressStyle>
      <li>
        <SpaceshipMachineGunTargetIcon
          width="25"
          height="25"
          color={`${targetVisible ? color.yellow : color.grey.strong}`}
        />
      </li>

      <li>
        <div className="separator"></div>
      </li>

      <li>
        <ProgressStatus progress={bullets / bulletsTotal} color={color.white} />
      </li>
    </AvatarProgressStyle>
  );
};

export default React.memo(SpaceshipMachineGunBullets);
