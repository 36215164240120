// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const CivilizationPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M481.89 210.82H512v90.35h-30.11zM481.89 120.47H512v30.11h-30.11zM451.77 301.17h30.12v90.36h-30.12zM451.77 150.58h30.12v60.24h-30.12zM451.77 90.35h30.12v30.12h-30.12zM60.24 451.76v-30.12H30.12v30.12H0v30.12h30.12V512h451.77v-30.12H512v-30.12h-30.11v-30.12h-30.12v30.12zM421.65 391.53h30.12v30.11h-30.12zM421.65 210.82h30.12v30.12h-30.12zM421.65 120.47h30.12v30.11h-30.12zM391.53 331.29h30.12v30.12h-30.12zM391.53 240.94h30.12v30.12h-30.12zM391.53 180.7h30.12v30.12h-30.12zM361.42 271.06h30.11v30.11h-30.11z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M331.3 150.58h60.23v30.12H331.3zM331.3 331.29h30.12v30.12H331.3zM331.3 240.94h30.12v30.12H331.3zM301.18 180.7h30.12v60.24h-30.12zM271.06 301.17h30.12v60.24h-30.12zM271.06 30.11h30.12v30.12h-30.12zM240.95 361.41h30.11v30.12h-30.11zM240.95 271.06h30.11v30.11h-30.11zM240.95 0h30.11v30.11h-30.11zM210.83 301.17h30.12v60.24h-30.12z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M240.95 150.58h30.11v30.12h30.12v-60.23h-30.12V60.23h-30.11v60.24h-30.12v60.23h30.12z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M210.83 30.11h30.12v30.12h-30.12zM180.71 180.7h30.12v60.24h-30.12zM150.59 331.29h30.12v30.12h-30.12zM150.59 240.94h30.12v30.12h-30.12zM120.48 150.58h60.23v30.12h-60.23zM120.48 271.06h30.11v30.11h-30.11zM90.36 331.29h30.12v30.12H90.36zM90.36 240.94h30.12v30.12H90.36zM90.36 180.7h30.12v30.12H90.36zM60.24 391.53h30.12v30.11H60.24zM60.24 210.82h30.12v30.12H60.24zM60.24 120.47h30.12v30.11H60.24zM30.12 301.17h30.12v90.36H30.12zM30.12 150.58h30.12v60.24H30.12zM30.12 90.35h30.12v30.12H30.12zM0 210.82h30.12v90.35H0zM0 120.47h30.12v30.11H0z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default CivilizationPixelIcon;
