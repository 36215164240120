// redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import { StoryProps } from "interfaces/story";

const initialState: StoryProps[] = [];

export const storiesSlice = createSlice({
  name: "stories",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<StoryProps[]>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const storiesActions = storiesSlice.actions;
export default storiesSlice.reducer;
