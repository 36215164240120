// utils
import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled.div`
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin: 6px 0;
  color: ${color.green} !important;

  ${(props: any) => `
    ${props && props.alignLeft && `text-align: left;`}
  `}
`;

interface PropsI {
  err: string;
  alignLeft?: boolean;
}

const SuccessFeedback = (props: PropsI) => {
  return <Wrapper {...props}>{props.err}</Wrapper>;
};

export default SuccessFeedback;
