export const SPECIAL_REGEX = /\\\\.+?\\\\/;
export const SPECIAL_REGEX_G = /\\\\.+?\\\\/g;
export const SPECIAL_IDENTIFIER_REGEX = /\\\\/;
export const SPECIAL_IDENTIFIER_REGEX_G = /\\\\/g;
export const BACK_REF_REGEX = /\\\\\(\$\)\\\\/;
export const BACK_REF_REGEX_G = /\\\\\(\$\)\\\\/g;

const additional = {
  removeLiteral(str: string, g = true): string {
    const regex = g ? SPECIAL_IDENTIFIER_REGEX_G : SPECIAL_IDENTIFIER_REGEX;
    return str.replace(regex, "").replace(/\\\*/, "").replace(/\*\\/, "");
  },
};

const regexUtils = {
  additional,
};

export default regexUtils;
