// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// utils
import { v4 as uuidv4 } from "uuid";

export default abstract class AbstractShopItemEntity implements ShopItemProps {
  abstract readonly id: number;
  abstract readonly title: string;
  abstract readonly price: number;
  abstract readonly capacity: number;
  abstract readonly icon: JSX.Element;
  abstract readonly lifeCycles: number;
  abstract readonly available: boolean;
  abstract readonly image: JSX.Element;
  abstract readonly maxQuantity: number;
  abstract readonly type: SpaceshipItemEnum;
  abstract readonly bubbles: ShopItemBubbleProps[];
  abstract readonly subTitle?: string;

  static readonly capacityNone = -9999;
  static readonly lifeCyclesNone = -9999;

  abstract get item(): ShopItemProps;
  abstract get values(): PlayerShopItemsFirebaseProps;
  abstract get resourcesPrice(): SpaceshipResourcesQtyProps;

  abstract getIcon({
    width,
    height,
  }: {
    width: string;
    height: string;
  }): JSX.Element;

  protected get estelinWord(): string {
    return this.price > 1 ? "estelins" : "estelin";
  }

  protected getItemId(type: SpaceshipItemEnum): string {
    return `${type}_${uuidv4().split("-")[0]}`;
  }
}
