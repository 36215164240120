interface SvgProps {
  width?: string;
  height?: string;
}

const UncleSamBrSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 128 128"
    >
      <g>
        <path
          fill="#2dd25c"
          d="M64 121.5c-35.333 0-57.5-17.692-57.5-27.167 0-3.966 5.192-11.817 21.667-5.659S64 93.214 64 93.214s19.359 1.618 35.833-4.54 21.667 1.693 21.667 5.659c0 9.475-22.167 27.167-57.5 27.167z"
          opacity="1"
          data-original="#fb503c"
        ></path>
        <path
          fill="#2dd25c"
          d="M103.808 10.75S94.25 79.25 90.5 96.059c-1.091 4.891-11.864 9.074-26.5 9.074s-25.409-4.183-26.5-9.074C33.75 79.25 24.192 10.75 24.192 10.75z"
          opacity="1"
          data-original="#fb503c"
        ></path>
        <path
          fill="#f8cb21"
          d="M93.19 81.54c-1.04 6.18-1.97 11.3-2.69 14.52-1.09 4.89-11.86 9.07-26.5 9.07s-25.41-4.18-26.5-9.07c-.72-3.22-1.65-8.34-2.69-14.52 7.27 2.29 17.66 3.72 29.19 3.72s21.92-1.43 29.19-3.72z"
          opacity="1"
          data-original="#3979b6"
        ></path>
        <ellipse
          cx="64"
          cy="10.75"
          fill="#f8cb21"
          rx="39.808"
          ry="5.759"
          opacity="1"
          data-original="#3979b6"
        ></ellipse>
        <g fill="#ecf0f1">
          <path
            d="M103.78 10.99c-.5 3.55-6.4 45.62-10.59 70.55a78.759 78.759 0 0 1-14.33 2.88c1.69-14.55 5.51-47.47 7.69-68.93 10-.99 16.7-2.63 17.23-4.5zM64 16.51v68.75a127.9 127.9 0 0 1-14.85-.84c-1.7-14.55-5.52-47.47-7.7-68.93 6.41.64 14.17 1.02 22.55 1.02z"
            fill="#ecf0f1"
            opacity="1"
            data-original="#ecf0f1"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default UncleSamBrSvg;
