// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
}

const HeartPixelSvg = ({
  width = "18",
  height = "18",
  color = c.red,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 60 60"
    >
      <g>
        <g fill={color}>
          <path
            d="M26 51h8v8h-8zM18 43h8v8h-8zM10 35h8v8h-8zM10 1h16v8H10zM26 9h8v8h-8z"
            fill={color}
            opacity="1"
            data-original="#e5283f"
          ></path>
        </g>
        <path
          fill="#ff7680"
          d="M14 13h8v16h-8z"
          opacity="0"
          data-original="#ff7680"
        ></path>
        <path
          fill={color}
          d="M2 9h8v26H2zM42 51h-8v-8h8zM50 43h-8v-8h8zM34 1h16v8H34zM50 9h8v26h-8z"
          opacity="1"
          data-original="#e5283f"
        ></path>
      </g>
    </svg>
  );
};

export default HeartPixelSvg;
