// redux
import { playerActions } from "redux/slices/playerSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";

// interfaces
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";
import { SpaceshipEntityProps } from "interfaces/spaceInvaders/spaceship";
import { ChallengeFirebaseUpdateProps } from "interfaces/challengeFirebase";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";
import PlayerFirebaseEntity from "entities/PlayerFirebaseEntity";
import SpaceshipItemsEntity from "entities/SpaceshipItemsEntity";
import PlayerEventsDataEntity from "entities/PlayerEventsDataEntity";
import ChallengeFirebaseEntity from "entities/ChallengeFirebaseEntity";

// services
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";
import PlayerCheckpointFirebaseService from "services/firebase/player/PlayerCheckpointFirebaseService";
import PlayerEventsDataFirebaseService from "services/firebase/player/PlayerEventsDataFirebaseService";

// utils
import isEmpty from "lodash/isEmpty";
import stateUtils from "redux/utils/stateUtils";

// firestore
import { increment } from "firebase/firestore";

const playerMiddleware = createListenerMiddleware();

// player/hit
playerMiddleware.startListening({
  actionCreator: playerActions.async.hit,
  effect: async ({ payload }, listenerApi) => {
    const { hp, life } = payload as SpaceshipEntityProps;
    const { challenge, auth } = stateUtils.get(listenerApi);
    const userId = auth.user.id;
    const { classRoomId } = challenge;
    const playerFirebaseEntity = new PlayerFirebaseEntity();
    const playerValues = playerFirebaseEntity.getHitValues({ hp, life });

    // firebase
    updatePlayerFirebase(userId, playerValues);

    if (playerValues.killed) {
      updateChallengeFirebase(
        userId,
        challenge.id,
        classRoomId,
        new ChallengeFirebaseEntity().getKilledValues()
      );
    }
  },
});

// player/async/update
playerMiddleware.startListening({
  actionCreator: playerActions.async.updateStoryCheckpoint,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    // firebase
    updatePlayerFirebase(auth.user.id, payload);
  },
});

playerMiddleware.startListening({
  actionCreator: playerActions.async.updateEvents,
  effect: async ({ payload }, listenerApi) => {
    const { events } = payload;
    if (isEmpty(events)) return;

    const { auth } = stateUtils.get(listenerApi);
    const entityData = new PlayerEventsDataEntity();
    const resource = new PlayerEventsDataFirebaseService();
    const userId = auth.user.id;

    // firebase
    Object.keys(events).forEach((eventId) => {
      entityData.set(Number(eventId));
    });

    resource.update(userId, entityData.get());
  },
});

playerMiddleware.startListening({
  actionCreator: playerActions.async.recoveryHp,
  effect: async ({ payload }, listenerApi) => {
    const { auth, player } = stateUtils.get(listenerApi);
    const entity = new PlayerHpEntity({ hp: player.hp, life: player.life });
    entity.spaceshipPart = payload.type;
    entity.hpToRecover = payload.hpToRecover;

    const data = {
      hp: entity.recoveredHp,
      life: entity.recoveredLife,
    };

    // firebase
    updatePlayerFirebase(auth.user.id, data);
  },
});

playerMiddleware.startListening({
  actionCreator: playerActions.async.updateCheckpoint,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);
    const service = new PlayerCheckpointFirebaseService();

    if (!auth.user.id) return;
    if (isEmpty(payload.checkpoint)) return;

    // firebase
    service.set(auth.user.id, payload.checkpoint);
  },
});

playerMiddleware.startListening({
  actionCreator: playerActions.async.winXp,
  effect: async (_, listenerApi) => {
    const { auth, player } = stateUtils.get(listenerApi);
    const { currentGunId } = player;

    if (!auth.user.id) return;
    if (!currentGunId) return;

    const entity = new PlayerFirebaseEntity();
    updatePlayerFirebase(auth.user.id, entity.getXpValue(currentGunId));
  },
});

playerMiddleware.startListening({
  actionCreator: playerActions.async.addInitialDefaultItems,
  effect: async (_, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    const spaceshipItemsEntity = new SpaceshipItemsEntity();
    const { items, gunId } = spaceshipItemsEntity.initialItems;

    const data: PlayerFirebaseUpdateProps = {
      gunId,
      shopItems: items,
    };

    updatePlayerFirebase(auth.user.id, data);
  },
});

playerMiddleware.startListening({
  actionCreator: playerActions.async.incScore,
  effect: async ({ payload }, listenerApi) => {
    const { score } = payload;
    const { auth } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    updatePlayerFirebase(auth.user.id, { score: increment(score) });
  },
});

// private

function updatePlayerFirebase(
  userId: number | string,
  player: PlayerFirebaseUpdateProps
) {
  if (!userId) return;

  const playerFirebaseService = new PlayerFirebaseService();
  playerFirebaseService.update(userId, player);
}

function updateChallengeFirebase(
  userId: number | string,
  challengeId: number,
  classRoomId: number,
  data: ChallengeFirebaseUpdateProps
) {
  if (!userId) return;
  if (!challengeId) return;
  if (!classRoomId) return;

  const challengeFirebaseService = new PlayerChallengeFirebaseService();
  challengeFirebaseService.update(challengeId, userId, classRoomId, data);
}

export default playerMiddleware;
