// interfaces
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";

// utils
import isEmpty from "lodash/isEmpty";

export interface PlayerParserCheckpointsProps {
  [key: string]: boolean;
}

function map({ checkpoint }: PlayerFirebaseProps): PlayerCheckpointsStateProps {
  if (!checkpoint) return {};
  if (isEmpty(checkpoint)) return {};

  return Object.keys(checkpoint).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as PlayerParserCheckpointsProps);
}

const playerCheckpointsParser = {
  map,
};

export default playerCheckpointsParser;
