// components
import {
  defaultColor,
  defaultWidth,
  defaultHeight,
  AvatarInvadersProps,
} from "handlers/AvatarInvaderHandler";

// components
import LinearGradientEffectSvg from "svg/LinearGradientEffectSvg";

// utils
import { v4 as uuidv4 } from "uuid";

const AvatarInvaderThree = ({
  gradient = false,
  width = defaultWidth,
  height = defaultHeight,
}: AvatarInvadersProps) => {
  const id = uuidv4();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 550.801 550.8"
    >
      {gradient && (
        <defs>
          <LinearGradientEffectSvg id={id} />
        </defs>
      )}

      <g>
        <path
          d="M541.951 272.337h-37.217c-3.379 0-9.014-.202-9.014-3.58v-37.565c0-3.378.158-7.815-3.221-7.815h-37.387c-3.385 0-8.354-1.047-8.354-4.425v-37.363c0-3.384-.502-7.172-3.881-7.172h-37.281c-3.379 0-7.797-1.702-7.797-5.08v-37.546c0-3.378-1.898-6.089-5.275-6.059-12.211.098-24.719.11-37.395.11-3.377 0-6.285 2.742-6.285 6.12v37.216c-.006 3.39-1.561 5.239-4.945 5.239H206.598c-3.378 0-4.639-1.849-4.639-5.227v-37.252c0-3.378-3.476-6.12-6.854-6.12-12.552 0-24.333-.012-36.42-.11-3.391-.024-5.686 2.687-5.686 6.065v37.895c0 3.385-4.352 4.75-7.736 4.75h-37.589c-3.378 0-3.635 4.106-3.635 7.484v37.412c0 3.384-5.227 4.064-8.605 4.064h-37.46c-3.378 0-9.015 4.792-9.015 8.176v37.46c0 3.378.153 3.323-3.225 3.323H8.849c-3.378 0-8.85 5.532-8.85 8.91v135.729c0 3.385 5.471 8.36 8.85 8.36h36.867c3.378 0 3.244-4.988 3.244-8.366v-86.384c0-3.385 5.618-3.17 8.997-3.17H95.79c3.378 0 8.25-.208 8.25 3.17v86.935c0 3.378 1.67 6.114 5.055 6.114 12.558 0 25.239.018 37.332.109 3.378.031 6.573-2.68 6.573-6.059V380.36c0-3.379.759-3.984 4.143-3.984h236.544c3.379 0 6.119 1.677 6.119 5.055 0 12.559-.018 25.227-.115 37.455-.025 3.384 2.68 6.45 6.059 6.45h37.271c3.371 0 3.738-4.468 3.738-7.846v-86.818c0-3.379 5.117-3.256 8.5-3.256h38.037c3.377 0 8.543-.123 8.543 3.256v86.298c0 3.378.324 8.366 3.703 8.366h36.4c3.379 0 8.857-4.981 8.857-8.36v-135.98c.002-3.383-5.471-8.659-8.848-8.659zm-339.991-3.862c0 3.379-3.831 3.862-7.209 3.862h-37.387c-3.384 0-4.363-.478-4.363-3.855v-37.326c0-3.378.985-7.778 4.37-7.778h37.375c3.378 0 7.215 4.4 7.215 7.785l-.001 37.312zm195.841.099c0 3.378-1.023 3.764-4.4 3.764h-37.32c-3.385 0-7.24-.386-7.24-3.771v-37.344c0-3.372 3.861-7.846 7.24-7.846h37.32c3.377 0 4.4 4.467 4.4 7.846v37.351z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M244.341 424.071c-9.21.006-18.5.018-27.558-.007-19.805-.061-39.725-.146-59.529-.227-3.378-.012-6.151 2.718-6.12 6.103.098 12.239.08 25.275.085 37.521 0 3.378 2.711 6.842 6.09 6.842h86.567c3.378-.006 7.044-4.204 7.044-7.582v-36.524c0-3.385-3.201-6.133-6.579-6.126zM395.836 423.936c-.949.073-1.463.753-2.43.753-29.139.018-57.816.648-86.953.648h-2.773c-1.529 0-3.799 1.469-3.799 4.847V466.8c0 3.378 3.77 7.497 7.154 7.497h86.486c3.379 0 4.279-4.125 4.279-7.503v-37.063c.001-3.378-.366-5.918-1.964-5.795zM108.214 125.458h37.057c3.378 0 6.083-2.644 6.059-6.022-.098-12.24-.11-24.56-.116-36.806a6.129 6.129 0 0 0-6.126-6.126h-36.873c-3.378-.006-4.174 2.846-4.174 6.23v36.806c-.001 3.377.794 5.918 4.173 5.918zM404.844 125.788c12.24-.098 24.053-.11 36.299-.116 3.383 0 5.617-2.748 5.617-6.126V82.661c0-3.378-.82-6.163-4.197-6.163h-36.795c-3.377 0-7.967 2.796-7.967 6.175v37.062c0 3.378 3.664 6.083 7.043 6.053z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default AvatarInvaderThree;
