import { TagProps, SpecialTagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";

function is(
  tag: TagProps,
  steps: TagProps[],
  specialTags: SpecialTagProps[]
): boolean {
  return steps.some((step: TagProps) => {
    if (!step) return false;
    if (!step.special) return simple.is(tag, step);
    if (step.type?.regexp)
      return special.regex.validate(tag, step, specialTags);

    return false;
  });
}

function finished({ steps, verified }: ChallengeCodeProps): boolean {
  return steps.some(({ data }) => verified.length >= data.length);
}

const simple = {
  is(tag: TagProps, step: TagProps): boolean {
    return tag.value === step.value;
  },
};

const special = {
  regex: {
    is(tag: TagProps, step: TagProps): boolean {
      return !!tag.value.match(new RegExp(step.value));
    },
    validate(
      tag: TagProps,
      step: TagProps,
      specialTagsI: SpecialTagProps[]
    ): boolean {
      if (special.backReference.is(step)) {
        const lastSpecial = specialTagsI[specialTagsI.length - 1];

        /*
          Verifica se a última tag é exclusiva e se os valores são iguais
        */
        if (
          lastSpecial.step.flag?.exclusive &&
          lastSpecial.tag.value === tag.value
        )
          return false;
      }

      return this.is(tag, step);
    },
  },
  backReference: {
    is(tag: TagProps): boolean {
      return !!tag.type?.backReference;
    },
  },
};

const validatorUtils = {
  is,
  simple,
  special,
  finished,
};

export default validatorUtils;
