class PlayerEventsDataEntity {
  private events: { [key: number]: boolean };

  constructor() {
    this.events = {};
  }

  set(id: number) {
    this.events[id] = true;
  }

  get(): { events: { [key: number]: boolean } } {
    return {
      events: {
        ...this.events,
      },
    };
  }
}

export default PlayerEventsDataEntity;
