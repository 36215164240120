// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { spaceshipItemsActions } from "redux/slices/spaceInvaders/spaceshipItemsSlice";

// services
import PlayerShopItemsFirebaseService from "services/firebase/player/PlayerShopItemsDataFirebaseService";
import PlayerShopItemsDataFirebaseService from "services/firebase/player/PlayerShopItemsDataFirebaseService";

// entities
import SpaceshipItemEntity from "entities/spaceshipItem/SpaceshipItemEntity";
import PlayerSpaceshipItemToDeleteDataFirebaseEntity from "entities/data/PlayerSpaceshipItemToDeleteDataFirebaseEntity";

// utils
import isEmpty from "lodash/isEmpty";
import stateUtils from "redux/utils/stateUtils";

const spaceshipItemsMiddleware = createListenerMiddleware();

spaceshipItemsMiddleware.startListening({
  actionCreator: spaceshipItemsActions.async.remove,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);
    const { items } = payload;

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;
    if (isEmpty(items)) return;

    const service = new PlayerShopItemsDataFirebaseService();
    const entity = new PlayerSpaceshipItemToDeleteDataFirebaseEntity(items);

    service.remove(auth.user.id, entity.map);
  },
});

spaceshipItemsMiddleware.startListening({
  actionCreator: spaceshipItemsActions.async.decreaseLifeCycles,
  effect: async ({ payload }, listenerApi) => {
    const { auth, spaceshipItems } = stateUtils.get(listenerApi);

    if (isEmpty(payload)) return;

    const entity = new SpaceshipItemEntity();
    const quantity = entity.getTotalQuantity(payload);

    if (quantity <= 0) return;

    const items = SpaceshipItemEntity.filterActiveLifeCycles(
      spaceshipItems.all
    ).sort((a, b) => a.timestamp - b.timestamp);

    if (isEmpty(items)) return;

    const service = new PlayerShopItemsFirebaseService();
    const data = entity.decreaseLifeCycles(quantity, items);

    if (isEmpty(data)) return;

    service.update(auth.user.id, data);
  },
});

export default spaceshipItemsMiddleware;
