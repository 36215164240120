interface SvgProps {
  width?: string;
  height?: string;
}

const RepairPixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M512 60.24v421.64h-30.12V512H30.12v-30.12H0V30.12h30.12V0h301.17v30.12h150.59v30.12z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <g fill="#a1aab7">
          <path
            d="M150.59 90.35v30.12l-15.06 15.06h-30.12l-15.06-15.06V90.35zM301.18 331.29v30.12l-15.06 15.06h-60.24l-15.06-15.06v-30.12l15.06-15.05h60.24z"
            fill="#a1aab7"
            opacity="1"
            data-original="#a1aab7"
          ></path>
        </g>
        <path
          fill="#58b354"
          d="m451.76 120.47-15.06 15.06-15.05-15.06h-30.12l-15.06 15.06-15.06-15.06V90.35h-30.12V60.24l-15.05-15.06-15.06 15.06v60.23h-30.12v30.12h-60.24V60.24l-15.055-15.06-15.055 15.06v90.35h-30.12v30.12H90.35v-30.12H60.24V60.24L45.18 45.18 30.12 60.24v391.52l15.06 15.06 421.64 1.57 15.06-16.63V120.47zM346.35 376.46l-15.06 15.07h-30.11v30.12l-15.06 15.06-15.06-15.06h-30.12l-15.06 15.06-15.06-15.06v-30.12h-30.11l-15.06-15.06 15.06-15.06v-30.12l-15.06-15.05 15.06-15.06h30.11v-30.12L225.88 256l15.06 15.06h30.12L286.12 256l15.06 15.06v30.12h30.11l15.06 15.05-15.06 15.06v30.12z"
          opacity="1"
          data-original="#58b354"
        ></path>
        <path
          fill="#dbe1e8"
          d="M361.41 60.24h120.47v30.11H361.41z"
          opacity="1"
          data-original="#dbe1e8"
        ></path>
        <path
          fill="#ffcc00"
          d="M240.94 90.35h30.12v30.12h-30.12z"
          opacity="1"
          data-original="#ffcc00"
        ></path>
        <path
          fill="#007745"
          d="M481.88 451.76v30.12H30.12v-30.12h60.23l15.06-15.06 15.06 15.06h30.12l15.06-15.06 15.06 15.06h30.11l15.06-15.06 15.06 15.06h30.12l15.06-15.06 15.06 15.06h30.11l15.06-15.06 15.06 15.06h60.24l15.05-15.06 15.06 15.06z"
          opacity="1"
          data-original="#007745"
        ></path>
        <path
          fill="#b3d141"
          d="M30.12 30.12h301.17v30.12H30.12z"
          opacity="1"
          data-original="#b3d141"
        ></path>
        <g fill="#07485e">
          <path
            d="M421.65 301.18h30.11v60.23h-30.11zM421.65 120.47h30.11v60.24h-30.11zM421.65 391.53h30.11v60.23h-30.11zM391.53 271.06h30.12v30.12h-30.12zM271.06 421.65h30.12v30.11h-30.12zM331.29 301.18h30.12v30.11h-30.12zM331.29 361.41h30.12v30.12h-30.12zM391.53 180.71h30.12v30.11h-30.12v30.12h-30.12v-30.12h-60.23v-30.11h60.23v-60.24h30.12zM271.06 240.94h30.12v30.12h-30.12zM180.71 240.94v30.12h-60.24v30.12H90.35v-30.12H60.24v-30.12h30.11v-30.12h30.12v30.12zM90.35 331.29h30.12v30.12H90.35zM150.59 301.18h30.12v30.11h-30.12zM150.59 361.41h30.12v30.12h-30.12zM150.59 421.65h30.12v30.11h-30.12zM210.82 240.94h30.12v30.12h-30.12zM210.82 180.71h30.12v30.11h-30.12zM210.82 421.65h30.12v30.11h-30.12z"
            fill="#07485e"
            opacity="1"
            data-original="#07485e"
          ></path>
        </g>
        <g fill="#fc0">
          <path
            d="M361.41 180.71h30.12v30.11h-30.12zM331.29 421.65h30.12v30.11h-30.12zM90.35 240.94h30.12v30.12H90.35z"
            fill="#ffcc00"
            opacity="1"
            data-original="#ffcc00"
          ></path>
        </g>
        <g fill="#5f6c75">
          <path
            d="M210.82 361.41h90.36v30.12h-90.36zM90.35 120.47h60.24v30.12H90.35z"
            fill="#5f6c75"
            opacity="1"
            data-original="#5f6c75"
          ></path>
        </g>
        <path
          fill="#dbe1e8"
          d="M210.82 301.18h90.36v30.11h-90.36z"
          opacity="1"
          data-original="#dbe1e8"
        ></path>
        <g fill="#efefa6">
          <path
            d="M90.35 421.65h30.12v30.11H90.35zM60.24 391.53h30.11v30.12H60.24z"
            fill="#efefa6"
            opacity="1"
            data-original="#efefa6"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default RepairPixelSvg;
