import { ApplicationErrorProps } from "interfaces/error";

export default class ApplicationError extends Error {
  static errorName = "ApplicationError";

  constructor(message: string = "Application error") {
    super(message);

    this.name = "ApplicationError";
    this.message = message;
  }

  toJSON(): ApplicationErrorProps {
    return {
      data: {
        name: this.name,
        message: this.message,
        type: ApplicationError.errorName,
        status: 0,
        stacktrace: this.stack,
      },
    };
  }
}
