// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// interfaces
import { SpaceshipItemProps } from "interfaces/spaceInvaders/spaceshipItem";
import { PlayerShopItemsOptionalFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

export default class SpaceshipItemEntity {
  getTotalQuantity(
    resources: AllToOptional<SpaceshipResourcesUpdateFirebaseProps>
  ): number {
    return Object.keys(resources).reduce((acc, r) => {
      const d = resources[r as keyof SpaceshipResourcesUpdateFirebaseProps];

      if (!d) return acc;
      acc += Object.keys(d).length;

      return acc;
    }, 0);
  }

  decreaseLifeCycles(
    allResourcesQuantity: number,
    machines: SpaceshipItemProps[],
    acc: PlayerShopItemsOptionalFirebaseProps = {}
  ): PlayerShopItemsOptionalFirebaseProps {
    if (!allResourcesQuantity) return acc;
    if (!machines.length) return acc;

    const machine = machines.shift();

    if (!machine) return acc;
    if (!machine.id) return acc;
    if (!machine.lifeCycles)
      return this.decreaseLifeCycles(allResourcesQuantity, machines, acc);

    const { lifeCycles: l, quantity: qty } = this.decreaseLifeCycle(
      machine.lifeCycles,
      allResourcesQuantity
    );

    acc[machine.id] = {
      l,
    };

    return this.decreaseLifeCycles(qty, machines, acc);
  }

  private decreaseLifeCycle(
    lifeCycles: number,
    allResourcesQuantity: number
  ): { lifeCycles: number; quantity: number } {
    if (!allResourcesQuantity)
      return { lifeCycles, quantity: allResourcesQuantity };
    if (!lifeCycles) return { lifeCycles, quantity: allResourcesQuantity };

    return this.decreaseLifeCycle(--lifeCycles, --allResourcesQuantity);
  }

  static filterActiveLifeCycles(
    spaceshipItems: SpaceshipItemProps[]
  ): SpaceshipItemProps[] {
    return spaceshipItems.filter(
      ({ lifeCycles }) =>
        lifeCycles !== AbstractShopItemEntity.lifeCyclesNone && lifeCycles > 0
    );
  }

  static filterExpiredLifeCycles(
    spaceshipItems: SpaceshipItemProps[]
  ): SpaceshipItemProps[] {
    return spaceshipItems.filter(
      ({ lifeCycles }) =>
        lifeCycles !== AbstractShopItemEntity.lifeCyclesNone && lifeCycles === 0
    );
  }
}
