// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesQtyProps,
} from "interfaces/spaceInvaders/spaceshipResources";

interface InputProps {
  resources: SpaceshipResourcesProps;
  resourcesPrice?: SpaceshipResourcesQtyProps;
}

export interface ResourcesCommonProps {
  key: string;
  alias: string;
  total: number;
}

export interface ResourcesProps extends ResourcesCommonProps {
  raw: number;
  shifting: number;
  transformed: number;
}

type K = keyof SpaceshipResourcesProps;

export default class SpaceshipResourcesStatusEntity {
  private readonly resources: SpaceshipResourcesProps;
  private readonly resourcesPrice: SpaceshipResourcesQtyProps;

  constructor({ resources, resourcesPrice }: InputProps) {
    this.resources = resources;
    if (resourcesPrice) this.resourcesPrice = resourcesPrice;
  }

  get brief(): ResourcesProps[] {
    return this.sortResources(this.map());
  }

  get needed(): ResourcesCommonProps[] {
    if (!this.resourcesPrice) return [];

    return this.sortResources(
      this.map().reduce((acc, data) => {
        const quantity = this.resourcesPrice[data.key as K];
        if (quantity === undefined) return acc;

        const total = data.transformed - quantity;

        if (total >= 0) return acc;
        if (quantity) {
          acc.push({
            ...data,
            total: Math.abs(total),
          });
        }

        return acc;
      }, [] as ResourcesProps[])
    );
  }

  private map(): ResourcesProps[] {
    return Object.keys(this.resources).map((resource) => {
      const r = this.resources[resource as K];
      if (!r) return {} as ResourcesProps;

      const raw = r.raw || 0;
      const total = r.total || 0;
      const shifting = r.shifting || 0;
      const transformed = r.transformed || 0;

      return {
        raw,
        total,
        shifting,
        transformed,
        key: resource,
        alias: r.alias,
      } as ResourcesProps;
    });
  }

  private sortResources(resources: ResourcesProps[]): ResourcesProps[] {
    return resources.sort((a, b) => a.alias.localeCompare(b.alias));
  }

  static getClassNameColor(resource: string) {
    switch (resource) {
      case "strongForce":
        return "strong_force";
      case "water":
        return "water";
      case "gold":
        return "gold";
      case "bauxite":
        return "bauxite";
      case "copper":
        return "copper";
      case "sand":
        return "sand";
      case "silver":
        return "silver";
      case "estelin":
        return "estelin";

      default:
        return "";
    }
  }
}
