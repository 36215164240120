// react
import { useState, useRef } from "react";

// components
import Board from "components/Board";
import SlideDotsProgress from "components/SlideDotsProgress";
import { DataCacheProps } from "components/presentations/Customization";
import NerdXFooter from "components/presentations/spaceship/NerdXFooter";
import ButtonDuolingoPlusIcon from "components/buttons/ButtonDuolingoPlusIcon";
import ButtonDuolingoMinusIcon from "components/buttons/ButtonDuolingoMinusIcon";

// constants
import {
  JARVIS_RADAR_INITIAL_VALUE,
  JARVIS_RELOAD_INITIAL_VALUE,
  JARVIS_BULLETS_INITIAL_VALUE,
  JARVIS_SPACESHIP_FEATURE_MAX_RADAR_VALUE,
  JARVIS_SPACESHIP_FEATURE_MAX_RELOAD_VALUE,
} from "constants/jarvis";

// icons
import LaserPixelSvg from "icons/LaserPixelIcon";
import ReloadPixelSvg from "icons/ReloadPixelSvg";
import SpaceshipMachineGunTargetIcon from "icons/SpaceshipMachineGunTargetIcon";

// interfaces
import { DotProgressRefProps } from "components/SlideDotsProgress";

// utils
import color from "styles/color";
import styled from "styled-components";

// Swiper
import { IonicSlides } from "@ionic/react";
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .slide {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .feature_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      .feature {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .values {
        all: unset;
        margin-top: 25px;
        font-size: 22px;
        color: ${color.grey.light};
      }

      .range_wrapper {
        all: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;

        button {
          margin: 0 10px;
          width: 35px;
          height: 35px;
        }
      }
    }

    .board {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px !important;
  }
`;

interface FeatureSlideProps {
  min: number;
  add(): void;
  value: number;
  remove(): void;
  icon: React.ReactNode;
  board: React.ReactNode;
  disablePlusButton?: boolean;
  disableMinusButton?: boolean;
}

const FeatureSlide = ({
  min,
  add,
  icon,
  board,
  value,
  remove,
  disablePlusButton,
  disableMinusButton,
}: FeatureSlideProps) => {
  return (
    <div className="slide">
      <div className="feature_wrapper">
        {icon}

        <p className="values">{value}</p>

        <div className="range_wrapper">
          <ButtonDuolingoMinusIcon
            onClick={remove}
            customClassName="no_swipe_customize"
            disabled={value === min || disableMinusButton}
          />
          <ButtonDuolingoPlusIcon
            onClick={add}
            customClassName="no_swipe_customize"
            disabled={disablePlusButton}
          />
        </div>
      </div>

      <div className="board">
        <Board>{board}</Board>
      </div>
    </div>
  );
};

interface CustomizationProps {
  bullets: number;
  reload: number;
  radar: number;
}

interface SpaceshipFeaturesFormProps {
  jarvisPoints: number;
  cache: DataCacheProps;
  jarvisTotalPoints: number;
  setJarvisPoints(points: number): void;
  isSaved?: boolean;
}

const SpaceshipFeatures = ({
  cache,
  isSaved,
  jarvisPoints,
  setJarvisPoints,
  jarvisTotalPoints,
}: SpaceshipFeaturesFormProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const speechProgressRef = useRef<DotProgressRefProps>({ 0: true });
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);
  const [customization, setCustomization] = useState<CustomizationProps>({
    bullets: cache.bullets,
    reload: cache.reload,
    radar: cache.radar,
  });

  function plus(src: "bullets" | "reload" | "radar") {
    if (hasReachMaxRadarValue(src)) return;
    if (hasReachMaxReloadValue(src)) return;
    if (isPlusButtonDisabled()) return;

    const currentPoints = jarvisPoints - 1;
    const data = { ...customization, [src]: customization[src] + 1 };

    updateCache(data);
    setCustomization(data);
    setJarvisPoints(currentPoints);
  }

  function minus(src: "bullets" | "reload" | "radar") {
    if (isMinusButtonDisabled()) return;
    if (customization[src] === 1) return;

    const currentPoints = jarvisPoints + 1;
    const data = { ...customization, [src]: customization[src] - 1 };

    updateCache(data);
    setCustomization(data);
    setJarvisPoints(currentPoints);
  }

  function hasReachMaxRadarValue(src: "bullets" | "reload" | "radar"): boolean {
    if (src !== "radar") return false;

    return customization.radar >= JARVIS_SPACESHIP_FEATURE_MAX_RADAR_VALUE;
  }

  function hasReachMaxReloadValue(
    src: "bullets" | "reload" | "radar"
  ): boolean {
    if (src !== "reload") return false;

    return customization.reload >= JARVIS_SPACESHIP_FEATURE_MAX_RELOAD_VALUE;
  }

  function isBigger(points: number): boolean {
    return (
      points >
      jarvisTotalPoints -
        (customization.bullets + customization.reload + customization.radar)
    );
  }

  function updateCache(customization: CustomizationProps) {
    cache.bullets = customization.bullets;
    cache.reload = customization.reload;
    cache.radar = customization.radar;
  }

  function isMinusButtonDisabled(): boolean {
    return isSaved || isBigger(jarvisPoints);
  }

  function isPlusButtonDisabled(): boolean {
    return isSaved || jarvisPoints === 0;
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;

    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex, realIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
      updateRealSpeechPositionRef(realIndex);
    });
  }

  function updateRealSpeechPositionRef(index: number) {
    if (speechProgressRef.current[index]) return;
    speechProgressRef.current = { ...speechProgressRef.current, [index]: true };
  }

  return (
    <Wrapper>
      <div className="body">
        <div className="body_header">
          <Board>
            <p>
              Distribua{" "}
              <span className="bg_nerdfy_gradient">
                {jarvisPoints} {`${jarvisPoints === 1 ? "ponto" : "pontos"}`}
              </span>{" "}
              entre as <span className="purple">habilidades</span> abaixo.
            </p>
          </Board>
        </div>

        <div className="slides">
          <Swiper
            initialSlide={0}
            slidesPerView={1}
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            noSwipingClass="no_swipe_customize"
          >
            <SwiperSlide>
              <FeatureSlide
                value={customization.bullets}
                add={plus.bind(null, "bullets")}
                min={JARVIS_BULLETS_INITIAL_VALUE}
                remove={minus.bind(null, "bullets")}
                disablePlusButton={isPlusButtonDisabled()}
                disableMinusButton={isMinusButtonDisabled()}
                board={
                  <p>
                    <span className="blue">Incrementa</span> a{" "}
                    <span className="green">quantidade de "balas"</span> por{" "}
                    <span className="white">rajada</span>.
                  </p>
                }
                icon={
                  <LaserPixelSvg width="40" height="40" color={color.purple} />
                }
              />
            </SwiperSlide>

            <SwiperSlide>
              <FeatureSlide
                value={customization.radar}
                add={plus.bind(null, "radar")}
                min={JARVIS_RADAR_INITIAL_VALUE}
                remove={minus.bind(null, "radar")}
                disablePlusButton={isPlusButtonDisabled()}
                disableMinusButton={isMinusButtonDisabled()}
                icon={
                  <SpaceshipMachineGunTargetIcon
                    width="40"
                    height="40"
                    color={color.yellow}
                  />
                }
                board={
                  <p>
                    <span className="blue">Aumenta</span> a{" "}
                    <span className="green">distância de detecção</span> de{" "}
                    <span className="orange">inimigos</span>.
                  </p>
                }
              />
            </SwiperSlide>

            <SwiperSlide>
              <FeatureSlide
                value={customization.reload}
                add={plus.bind(null, "reload")}
                min={JARVIS_RELOAD_INITIAL_VALUE}
                remove={minus.bind(null, "reload")}
                disablePlusButton={isPlusButtonDisabled()}
                disableMinusButton={isMinusButtonDisabled()}
                board={
                  <p>
                    <span className="blue">Diminui</span> o{" "}
                    <span className="green">tempo de recarga</span>.
                  </p>
                }
                icon={
                  <ReloadPixelSvg width="40" height="40" color={color.blue} />
                }
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <SlideDotsProgress
          len={3}
          position={slideActiveIndex}
          dotsProgress={speechProgressRef.current}
        />
      </div>

      <NerdXFooter />
    </Wrapper>
  );
};

export default SpaceshipFeatures;
