// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { ShopItemFirebaseProps } from "interfaces/shopFirebase";

// utils
import isEmpty from "lodash/isEmpty";

class ShopItemsFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  set(userId: number | string, data: ShopItemFirebaseProps): Promise<void> {
    if (isEmpty(data)) return Promise.reject();

    const path = `players/${userId}/shopItems/${data.id}`;
    return this._firebase.set(path, data);
  }
}

export default ShopItemsFirebaseService;
