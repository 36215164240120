// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";

// utils
import cloneDeep from "lodash/cloneDeep";

function merge(
  challenge: ChallengeCommonProps,
  hash: ChallengesCommonHashProps
): ChallengesCommonHashProps {
  const clone = cloneDeep(hash);
  const section = clone[challenge.challengeSectionId];
  if (!section) return clone;

  const index = section.findIndex((c) => c.uuid === challenge.uuid);
  if (index === -1) return clone;

  clone[challenge.challengeSectionId][index] = challenge;
  return clone;
}

const challengeCommonUtils = {
  merge,
};

export default challengeCommonUtils;
