// react
import { useRef, useState } from "react";
import { IonicSlides } from "@ionic/react";

// entities
import {
  ItemCompanyOneEntity,
  ItemCompanyTwoEntity,
  ItemCompanyThreeEntity,
  ItemCompanyFourEntity,
  ItemCompanyFiveEntity,
} from "entities/items/ItemEntity";

// components
import SlideDotsProgress from "components/SlideDotsProgress";

// interfaces
import {
  ListItems,
  ListItemProps,
  NoneDescription,
} from "components/playerProfile/PlayerProfile";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface PlayerProfileCivilizationsSliceProps {
  checkpoints: PlayerCheckpointsStateProps;
}

const PlayerProfileCompaniesSlide = ({
  checkpoints,
}: PlayerProfileCivilizationsSliceProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  function getCompanies(): ListItemProps[] {
    const one = new ItemCompanyOneEntity();
    const two = new ItemCompanyTwoEntity();
    const three = new ItemCompanyThreeEntity();
    const four = new ItemCompanyFourEntity();
    const five = new ItemCompanyFiveEntity();

    return [one, two, three, four, five];
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;

    swiperRef.current = swiper;
    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
    });
  }

  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_yellow">Empresas</h1>
      </div>

      <div className="body">
        <ListItems
          checkpoints={checkpoints}
          items={getCompanies()}
          activeIndex={slideActiveIndex}
        />

        <SlideDotsProgress len={5} position={slideActiveIndex} />

        <div className="slides_body no-swipe">
          <Swiper
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            slidesPerView={1}
            initialSlide={0}
          >
            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CompanyNerdflix] ? (
                  <>
                    <p>
                      <span className="nerdflix_color">Nerdflix</span> é a
                      plataforma de{" "}
                      <span className="nerdfy_white">streaming</span> mais
                      popular dos <span className="nerdfy_yellow">nerds</span>
                      ...
                    </p>

                    <p>
                      Responsável por{" "}
                      <span className="nerdfy_pink">grandes sucessos</span> como
                      "
                      <span className="nerdfy_orange">
                        Primata: O Extraterrestre
                      </span>
                      ", "<span className="nerdfy_blue">O poderoso Nerd</span>
                      ", "
                      <span className="nerdfy_green">Planeta dos Primatas</span>
                      " e tantos outros!
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CompanyNerdBook] ? (
                  <>
                    <p>
                      <span className="nerdbook_color">NerdBook</span> é um
                      conglomerado de tecnologia e mídia que [supostamente]{" "}
                      <span className="nerdfy_blue">conecta</span> os{" "}
                      <span className="nerdfy_yellow">nerds</span>.
                    </p>

                    <p>
                      <span className="nerdfy_pink">Responsável</span> por criar
                      essa{" "}
                      <span className="nerdfy_orange">
                        incrível enciclopédia
                      </span>
                      , item <span className="nerdfy_red">essencial</span> para{" "}
                      <span className="nerdfy_green">qualquer</span> explorador
                      intergaláctico .
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CompanyNerdX] ? (
                  <>
                    <p>
                      <span className="nerdX_color">nerdX</span> é uma{" "}
                      <span className="nerdfy_orange">fabricante</span> de{" "}
                      <span className="nerdfy_blue">espaçonaves</span>,{" "}
                      <span className="nerdfy_blue">
                        sistemas aeroespaciais
                      </span>{" "}
                      e <span className="nerdfy_blue">transporte espacial</span>{" "}
                      como um todo.
                    </p>

                    <p>
                      Tem como <span className="nerdfy_purple">base</span> uma{" "}
                      <span className="nerdfy_yellow">legião</span> de{" "}
                      <span className="nerdfy_red">fãs fanáticos</span> e um
                      líder <span className="nerdfy_green">polêmico</span> e{" "}
                      <span className="nerdfy_pink">carismático</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CompanyNerdtel] ? (
                  <>
                    <p>
                      <span className="nerdtel_color">Nerdtel</span> é FAMOSA
                      por sua linha de processadores, semicondutores e sistemas
                      integrados.
                    </p>

                    <p>
                      <span className="nerdfy_green">Boa parte</span> das
                      tecnologias de{" "}
                      <span className="nerdfy_blue">comunicação</span> em Nerdfy
                      tem como base algum{" "}
                      <span className="nerdfy_white">produto</span> da Nerdtel.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CompanyNerdsoft] ? (
                  <>
                    <p>
                      <span className="nerdsoft_color">Nerdsoft</span> é a maior
                      desenvolvedora de{" "}
                      <span className="nerdfy_orange">software</span> e{" "}
                      <span className="nerdfy_purple">
                        inteligência artificial
                      </span>{" "}
                      de Nerdfy.
                    </p>

                    <p>
                      Responsável pela{" "}
                      <span className="nerdfy_green">criação</span> da primeira
                      I.A generalista,{" "}
                      <span className="nerdfy_blue">imagem</span> e{" "}
                      <span className="nerdfy_pink">semelhança</span> dos{" "}
                      <span className="nerdfy_yellow">nerds</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfileCompaniesSlide;
