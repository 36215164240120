// components
import Bubble, { BubbleProps } from "handlers/bubble/Bubble";

// icons
import ThunderIcon from "icons/ThunderIcon";

const BubbleGod = ({
  hide,
  name,
  footer,
  content,
  subHeader,
  onAnimationEnd,
  finishedTyping,
}: BubbleProps) => {
  return (
    <Bubble
      show
      hide={hide}
      name={name}
      footer={footer}
      content={content}
      subHeader={subHeader}
      labelClassName="nerdfy_purple"
      onAnimationEnd={onAnimationEnd}
      finishedTyping={finishedTyping}
      footerIcon={<ThunderIcon width="35" height="35" />}
    />
  );
};

export default BubbleGod;
