// react
import { useRef } from "react";

// svgs
import RavianFoolSvg from "svg/ravians/RavianFoolSvg";
import RavianNerdSvg from "svg/ravians/RavianNerdSvg";
import RavianWinkSvg from "svg/ravians/RavianWinkSvg";
import RavianNinjaSvg from "svg/ravians/RavianNinjaSvg";
import RavianTongueSvg from "svg/ravians/RavianTongueSvg";
import RavianScepticSvg from "svg/ravians/RavianScepticSvg";
import RavianSmirkingSvg from "svg/ravians/RavianSmirkingSvg";
import RavianGrinningSvg from "svg/ravians/RavianGrinningSvg";
import RavianUnamusedSvg from "svg/ravians/RavianUnamusedSvg";

interface AvatarProps {
  customClassName?: string;
  onAnimationEnd?: () => void;
}

const AvatarRavians = ({
  onAnimationEnd,
  customClassName = "",
}: AvatarProps) => {
  const randomAvatar = useRef(getRandomAvatar());

  function getRandomAvatar(): JSX.Element {
    const avatars = [
      <RavianFoolSvg width="35" height="35" />,
      <RavianNerdSvg width="35" height="35" />,
      <RavianWinkSvg width="35" height="35" />,
      <RavianNinjaSvg width="35" height="35" />,
      <RavianTongueSvg width="35" height="35" />,
      <RavianScepticSvg width="35" height="35" />,
      <RavianUnamusedSvg width="35" height="35" />,
      <RavianGrinningSvg width="35" height="35" />,
      <RavianSmirkingSvg width="35" height="35" />,
    ];

    return avatars[Math.floor(Math.random() * avatars.length)];
  }

  return (
    <div className={customClassName} onAnimationEnd={onAnimationEnd}>
      {randomAvatar.current}
    </div>
  );
};

export default AvatarRavians;
