export enum SpaceshipItemEnum {
  None = 0,
  RefineMachine,
  AmateurRadio,
  RadioStation,
  MineralStorage,
  Printer2d,
  LaserGun,
  Repair,
  Encyclopedia,
}
