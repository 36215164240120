// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const ResourcesPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M481.89 180.71v60.23h-60.24v30.12h60.24v60.23H512V180.71zM451.77 331.29h30.12v60.24h-30.12zM451.77 120.47h30.12v60.24h-30.12zM421.65 391.53h30.12v30.12h-30.12zM421.65 90.35h30.12v30.12h-30.12zM391.53 421.65h30.12v30.12h-30.12zM391.53 361.41h30.12v30.12h-30.12zM391.53 271.06h30.12v30.12h-30.12zM391.53 60.24h30.12v30.11h-30.12zM361.42 301.18h30.11v60.23h-30.11zM331.3 451.77h60.23v30.11H331.3zM361.42 180.71h30.11v-30.12h-30.11v-30.12H331.3v30.12h-30.12v30.12h30.12v30.11h30.12zM331.3 30.12h60.23v30.12H331.3zM180.71 481.88H331.3V512H180.71zM271.06 240.94h30.12v30.12h-30.12zM240.95 271.06h30.11v30.12h-30.11zM240.95 210.82h30.11v30.12h-30.11zM210.83 391.53h30.12v30.12h-30.12zM210.83 240.94h30.12v30.12h-30.12zM210.83 90.35h30.12v60.24h-30.12zM180.71 0H331.3v30.12H180.71zM180.71 421.65h30.12v30.12h-30.12zM180.71 361.41h30.12v30.12h-30.12zM180.71 150.59h30.12v30.12h-30.12zM180.71 60.24h30.12v30.11h-30.12zM120.48 451.77h60.23v30.11h-60.23zM120.48 331.29h60.23v30.12h-60.23zM120.48 30.12h60.23v30.12h-60.23zM60.24 180.71h120.47v30.11H60.24zM90.36 421.65h30.12v30.12H90.36zM90.36 361.41h30.12v30.12H90.36zM90.36 60.24h30.12v30.11H90.36zM60.24 391.53h30.12v30.12H60.24zM60.24 90.35h30.12v30.12H60.24zM30.12 331.29h30.12v60.24H30.12zM30.12 120.47h30.12v60.24H30.12zM0 180.71h30.12v150.58H0z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ResourcesPixelIcon;
