// react
import { useState, useRef, useCallback } from "react";
import { IonicSlides } from "@ionic/react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// interfaces
import { PlayerSpaceshipDataProps } from "interfaces/player";

// entities
import SpaceshipEngravingEntity from "entities/SpaceshipEngravingEntity";

// components
import Loading from "components/Loading";
import ProgressBar from "components/ProgressBar";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import { ContentStyled } from "components/presentations/Customization";
import NerdXFooter from "components/presentations/spaceship/NerdXFooter";
import SpaceshipItemsWrapper from "components/presentations/spaceship/SpaceshipItemsWrapper";
import SelectSpaceshipWrapper from "components/presentations/spaceship/SelectSpaceshipWrapper";
import SpaceshipOrder, {
  OrderProps,
} from "components/presentations/spaceship/SpaceshipOrder";

// services
import PlayerSpaceshipDataFirebaseService from "services/firebase/player/PlayerSpaceshipDataFirebaseService";

// constants
import {
  JARVIS_RADAR_INITIAL_VALUE,
  JARVIS_RELOAD_INITIAL_VALUE,
  JARVIS_BULLETS_INITIAL_VALUE,
} from "constants/jarvis";

// enums
import { UserGenreType } from "enums/userEnum";
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// utils
import spaceshipUtils from "utils/spaceInvaders/spaceshipUtils";

const SPACESHIP_SELECT_SLIDE = 0;
const SPACESHIP_DEFAULT_ITEMS_SLIDE = 1;
const SPACESHIP_ORDER_SLIDE = 2;

export interface DataCacheProps {
  genre: number;
  codename: string;
  bullets: number;
  reload: number;
  radar: number;
  spaceshipEngraving: string;
  spaceshipColor: SpaceshipColorType;
}

interface GameOverHandlerProps {
  finish(): void;
}

const SpaceshipCustomizationRestartHandler = ({
  finish,
}: GameOverHandlerProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const player = useSelector(s.player());
  const [error, setError] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const swiperRef = useRef<SwiperProps | undefined>();
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const cacheRef = useRef<DataCacheProps>({
    codename: "",
    spaceshipEngraving: "",
    genre: UserGenreType.Unknown,
    radar: JARVIS_RELOAD_INITIAL_VALUE,
    reload: JARVIS_RADAR_INITIAL_VALUE,
    bullets: JARVIS_BULLETS_INITIAL_VALUE,
    spaceshipColor: SpaceshipColorType.None,
  });
  const [progress, setProgress] = useState(0);

  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    wrapperRef.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  function goToSpaceshipSelection() {
    setProgress(0);
    slideTo(SPACESHIP_SELECT_SLIDE);
  }

  function goToSpaceshipDefaultItems() {
    setProgress(0.5);
    slideTo(SPACESHIP_DEFAULT_ITEMS_SLIDE);
  }

  function goToSpaceshipOrder() {
    setProgress(1);
    slideTo(SPACESHIP_ORDER_SLIDE);
  }

  async function save() {
    if (isSaved) return;
    if (!auth.user.id) return;
    if (!cacheRef.current) return;

    const data = map(cacheRef.current);
    if (!isDataValid(data)) return;

    try {
      setLoading(true);
      setError("");

      const resource = new PlayerSpaceshipDataFirebaseService();
      await resource.update(auth.user.id, data);

      restart();
      setIsSaved(true);

      setTimeout(finish);
    } catch (_err: unknown) {
      setError("Ops, ocorreu um erro...");
    } finally {
      setLoading(false);
    }
  }

  function restart() {
    setTimeout(() => dispatch(challengeActions.async.restart()));
  }

  function isDataValid(data: PlayerSpaceshipDataProps): boolean {
    if (!data.bullets) return false;
    if (!data.reload) return false;
    if (!data.radar) return false;
    if (!data.color) return false;
    if (!data.engraving) return false;

    return true;
  }

  function map(cache: DataCacheProps): PlayerSpaceshipDataProps {
    return {
      ...getOrder(),
      color: cache.spaceshipColor,
    };
  }

  function getSpaceshipColor(): string {
    return spaceshipUtils.getColor(cacheRef.current.spaceshipColor);
  }

  function getSpaceshipClassNameColor(): string {
    return spaceshipUtils.getClassNameColor(cacheRef.current.spaceshipColor);
  }

  function onSelectSpaceship(color: SpaceshipColorType) {
    cacheRef.current.spaceshipColor = color;
  }

  function getOrder(): OrderProps {
    return {
      radar: 1,
      reload: 1,
      bullets: 3,
      engraving: SpaceshipEngravingEntity.defaultName,
    };
  }

  if (!player.gameOver) return null;

  return (
    <ContentStyled
      ref={handle}
      className="animate__animated animate__bounceInUp no-swipe"
    >
      <ProgressBar value={progress} color="success" />
      <Loading loading={loading} />

      <Swiper
        slidesPerView={1}
        onSwiper={handleSwiper}
        modules={[IonicSlides]}
        noSwipingClass="no_swipe"
        initialSlide={SPACESHIP_SELECT_SLIDE}
      >
        <SwiperSlide>
          <div className="slide no_swipe">
            <SelectSpaceshipWrapper
              onSelect={onSelectSpaceship}
              goForward={goToSpaceshipDefaultItems}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <SpaceshipItemsWrapper
              proceed={goToSpaceshipOrder}
              back={goToSpaceshipSelection}
              getSpaceshipClassNameColor={getSpaceshipClassNameColor}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide no_swipe">
            <Loading loading={loading} />
            <FormHeader
              save={save}
              disabled={isSaved}
              disabledBack={isSaved}
              back={goToSpaceshipDefaultItems}
            />

            <SpaceshipOrder
              order={getOrder()}
              getSpaceshipColor={getSpaceshipColor}
              getSpaceshipClassNameColor={getSpaceshipClassNameColor}
              description={
                <p>
                  <span className="nerdfy_pink">Confira</span> o{" "}
                  <span className="nerdfy_gold">resumo</span> do seu{" "}
                  <span className={getSpaceshipClassNameColor()}>pedido</span>.
                </p>
              }
            />

            {error && <ErrorFeedback err={error} />}
            <NerdXFooter />
          </div>
        </SwiperSlide>
      </Swiper>
    </ContentStyled>
  );
};

export default SpaceshipCustomizationRestartHandler;
