interface SvgProps {
  width?: string;
  height?: string;
}

const ZarvoxSintarSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m46.31 11.23c1.17-2.56 3.58-4.25 4.97-5.24l2.52 3.1c-1.12.79-3.41 2.6-4.19 4.48z"
        fill="#5fc77d"
      />
      <path
        d="m13.69 11.23c-1.17-2.56-3.58-4.25-4.97-5.24l-2.52 3.1c1.12.79 3.41 2.6 4.19 4.48z"
        fill="#5fc77d"
      />
      <path
        d="m57 29c0 12.979-12.088 29-27 29s-27-16.021-27-29 12.088-22 27-22 27 9.021 27 22z"
        fill="#6de08e"
      />
      <path
        d="m7.244 8.244c-1.825 1.835-4.315 2.3-5.55 1.067s-.768-3.725 1.057-5.56 4.315-2.3 5.56-1.057.768 3.725-1.067 5.55z"
        fill="#6de08e"
      />
      <path
        d="m52.756 8.244c1.825 1.835 4.315 2.3 5.55 1.067s.768-3.725-1.057-5.56-4.315-2.3-5.56-1.057-.768 3.725 1.067 5.55z"
        fill="#6de08e"
      />
      <path
        d="m25.36 22.09a6.738 6.738 0 0 1 .64 2.91 7 7 0 1 1 -8.01-6.92z"
        fill="#fff"
      />
      <path
        d="m42.01 18.08a7 7 0 1 1 -8.01 6.92 6.738 6.738 0 0 1 .64-2.91z"
        fill="#fff"
      />
      <path
        d="m31 37a1 1 0 0 1 1 1v4a0 0 0 0 1 0 0h-4a0 0 0 0 1 0 0v-2a3 3 0 0 1 3-3z"
        fill="#fff"
        transform="matrix(-1 0 0 -1 60 79)"
      />
      <path
        d="m21 29a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z"
        fill="#36513c"
      />
      <path
        d="m26.709 23.8a2.514 2.514 0 0 1 -1.772-.737c-4.221-4.232-11.959-5.063-12.037-5.063a1 1 0 0 1 .2-1.99c.345.035 8.521.9 13.254 5.644a.489.489 0 0 0 .426.144.427.427 0 0 0 .325-.24 1 1 0 1 1 1.79.894 2.417 2.417 0 0 1 -1.795 1.316 2.453 2.453 0 0 1 -.391.032z"
        fill="#48935a"
      />
      <path
        d="m43 29a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z"
        fill="#36513c"
      />
      <path
        d="m33.291 23.8a2.453 2.453 0 0 1 -.391-.031 2.417 2.417 0 0 1 -1.795-1.321 1 1 0 1 1 1.79-.894.427.427 0 0 0 .325.24.494.494 0 0 0 .426-.144c4.733-4.743 12.909-5.609 13.254-5.644a1 1 0 0 1 .2 1.994c-.079.008-7.817.836-12.039 5.067a2.51 2.51 0 0 1 -1.77.733z"
        fill="#48935a"
      />
      <path
        d="m30 20a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z"
        fill="#48935a"
      />
      <path d="m36 38h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" fill="#48935a" />
      <g fill="#86fdaa">
        <path d="m15 38h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" />
        <path d="m14 44h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" />
        <path d="m17 41h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" />
        <path d="m12 41h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" />
        <path d="m47 38h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" />
      </g>
      <path d="m31 47h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2z" fill="#48935a" />
      <path d="m46 44h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" fill="#86fdaa" />
      <path d="m49 41h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" fill="#86fdaa" />
      <path d="m44 41h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z" fill="#86fdaa" />
      <path
        d="m13.149 15.366a1 1 0 0 1 -.587-1.81 27.637 27.637 0 0 1 11.261-4.732 1 1 0 1 1 .353 1.969 25.621 25.621 0 0 0 -10.442 4.383.989.989 0 0 1 -.585.19z"
        fill="#86fdaa"
      />
      <path
        d="m9.761 18.333a1 1 0 0 1 -.728-1.686l.251-.262a1 1 0 0 1 1.431 1.4l-.226.236a1 1 0 0 1 -.728.312z"
        fill="#86fdaa"
      />
      <path
        d="m57 29c0 12.98-12.09 29-27 29-9.51 0-17.88-6.52-22.68-14.62 4.94 6.65 12.37 11.62 20.68 11.62 14.91 0 27-16.02 27-29a19.321 19.321 0 0 0 -2.36-9.36 19.428 19.428 0 0 1 4.36 12.36z"
        fill="#5fc77d"
      />
      <path
        d="m7.24 8.24c-1.82 1.84-4.31 2.31-5.55 1.07a2.513 2.513 0 0 1 -.26-.31 5.422 5.422 0 0 0 3.81-1.76c1.7-1.68 2.23-3.93 1.33-5.24a2.5 2.5 0 0 1 1.74.69c1.24 1.24.77 3.73-1.07 5.55z"
        fill="#5fc77d"
      />
      <path
        d="m58.31 9.31c-1.08 1.08-3.12.86-4.83-.44a2.889 2.889 0 0 0 2.83-.56c1.24-1.24.76-3.72-1.06-5.56a5.065 5.065 0 0 0 -.72-.61 6.064 6.064 0 0 1 2.72 1.61c1.82 1.84 2.3 4.32 1.06 5.56z"
        fill="#5fc77d"
      />
    </svg>
  );
};

export default ZarvoxSintarSvg;
