// enums
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

interface ConstructorInputProps {
  level: ChallengeQuizLevelType;
}

class ChallengeQuizScoreEntity {
  level: ChallengeQuizLevelType;

  constructor({ level }: ConstructorInputProps) {
    this.level = level;
  }

  gained(): number {
    if (this.level === ChallengeQuizLevelType.Easy) return 1;
    if (this.level === ChallengeQuizLevelType.Medium) return 2;
    if (this.level === ChallengeQuizLevelType.Hard) return 2;

    return 0;
  }

  lost(): number {
    if (this.level === ChallengeQuizLevelType.Easy) return -2;
    if (this.level === ChallengeQuizLevelType.Medium) return -1;
    if (this.level === ChallengeQuizLevelType.Hard) return -1;

    return 0;
  }
}

export default ChallengeQuizScoreEntity;
