// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { spaceshipItemsActions } from "redux/slices/spaceInvaders/spaceshipItemsSlice";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesSourceDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";
import {
  AlertContentProps,
  AlertContentDataProps,
  SpaceshipResourceReducedProps,
  SpaceshipResourcesLoopOutputProps,
} from "handlers/spaceshipResources/SpaceshipResourcesHandler";
import { ChallengeCommonProps } from "interfaces/challenge";
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// entities
import SpaceshipResourcesToShiftEntity from "entities/spaceshipResources/SpaceshipResourcesToShiftEntity";

// factories
import { AlertFactoryType } from "factories/AlertFactory";

// utils
import isEmpty from "lodash/isEmpty";

type SpaceshipResourcesHashSourceDataProps = {
  [key: string]: SpaceshipResourcesSourceDataProps;
};

interface RefineMachineLifeCyclesHandlerProps {
  challenge: ChallengeCommonProps;
  loop(
    resources: SpaceshipResourcesProps,
    reduce: (
      data: SpaceshipResourcesHashSourceDataProps
    ) => SpaceshipResourceReducedProps
  ): SpaceshipResourcesLoopOutputProps;
  recursiveAlerts(
    data: SpaceshipResourcesLoopOutputProps,
    getContent: (alert: AlertContentDataProps) => AlertContentProps,
    mapAlert?: (
      resource: keyof SpaceshipResourcesProps,
      data: SpaceshipResourceReducedProps
    ) => AlertContentDataProps[]
  ): void;
  hasExpired(d: SpaceshipResourcesSourceDataProps): boolean;
}

const ALERTS_INIT_SHOW_DELAY = 3500;

const RefineMachineLifeCyclesHandler = ({
  challenge,
  loop,
  recursiveAlerts,
  hasExpired,
}: RefineMachineLifeCyclesHandlerProps) => {
  const dispatch = useDispatch();
  const alreadyStartedRef = useRef(false);
  const refineMachineCapacityRef = useRef(0);
  const resources = useSelector(s.spaceshipResources());
  const spaceshipCounter = useSelector(s.spaceshipItemsCounter());
  const { flowDone, flowFinished } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleShift, [flowDone, flowFinished, resources]);
  useEffect(listenToRefineMachineCapacity, [spaceshipCounter]);

  function listenToRefineMachineCapacity() {
    refineMachineCapacityRef.current =
      spaceshipCounter[SpaceshipItemEnum.RefineMachine]?.capacity || 0;
  }

  function handleShift() {
    if (!flowDone) return;
    if (!flowFinished) return;
    if (alreadyStartedRef.current) return;

    alreadyStartedRef.current = true;

    const resources = loopResources();
    const entity = new SpaceshipResourcesToShiftEntity(
      refineMachineCapacityRef.current,
      resources
    );
    const { resourcesToShift, resourcesNotAbleToShift } = entity.map;

    if (!isEmpty(resourcesToShift))
      dispatch(
        spaceshipItemsActions.async.decreaseLifeCycles(resourcesToShift)
      );

    if (!isEmpty(resourcesNotAbleToShift))
      setTimeout(
        () =>
          recursiveAlerts(
            resourcesNotAbleToShift,
            getAlertNotAbleToShiftContent
          ),
        ALERTS_INIT_SHOW_DELAY
      );
  }

  function loopResources() {
    return loop(
      resources,
      reduceResourcesToShift
    ) as SpaceshipResourcesUpdateFirebaseProps;
  }

  function reduceResourcesToShift(
    data: SpaceshipResourcesHashSourceDataProps
  ): SpaceshipResourceReducedProps {
    return Object.keys(data).reduce((acc, key: string) => {
      const d = data[key];

      if (d.transformed) return acc;
      if (hasExpired(d)) return acc;

      acc[key] = d;

      return acc;
    }, {} as SpaceshipResourceReducedProps);
  }

  function getAlertNotAbleToShiftContent(
    alert: AlertContentDataProps
  ): AlertContentProps {
    const { resource, quantity } = alert;

    return {
      type: AlertFactoryType.Warning,
      content: `${resource} (${quantity}) (NÃO é possível refinar...)`,
    };
  }

  return null;
};

export default RefineMachineLifeCyclesHandler;
