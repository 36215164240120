// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// icons
import CodeTagPixelIcon from "icons/CodeTagPixelIcon";

// utils
import color from "styles/color";

const IconWhoreLabs = ({ active }: AttentionWhoreElProps) => {
  return (
    <CodeTagPixelIcon
      width="35"
      height="35"
      color={active ? color.pink : undefined}
    />
  );
};

export default IconWhoreLabs;
