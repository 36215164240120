interface SvgProps {
  width?: string;
  height?: string;
}

const RavianWinkSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="-24 0 511 511.99872"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m297.21875 288.726562c-4 0-7.71875-2.265624-9.519531-5.996093-2.128907-4.410157-.96875-9.65625 2.820312-12.753907 12.320313-10.082031 27.269531-18.226562 43.230469-23.554687 4.046875-1.351563 8.421875.832031 9.773438 4.878906 1.351562 4.050781-.835938 8.425781-4.882813 9.777344-6.96875 2.328125-13.710937 5.25-20.058594 8.675781 10.238281-1.101562 20.589844-1.460937 30.804688-1.0625 4.367187.167969 8.738281.464844 12.976562.886719 4.246094.425781 7.34375 4.210937 6.921875 8.457031-.425781 4.246094-4.203125 7.351563-8.457031 6.921875-3.925781-.390625-7.976563-.667969-12.035156-.824219-10.203125-.394531-20.554688.019532-30.777344 1.238282-6.25.742187-12.476563 1.785156-18.515625 3.109375-.761719.164062-1.527344.246093-2.28125.246093zm0 0"
        fill="#353535"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <path
        d="m231.644531 343.269531c-26.074219 0-50.414062-3.273437-71.019531-8.9375-6.707031-1.84375-12.875 4.152344-11.335938 10.933594 8.996094 39.59375 42.445313 69 82.355469 69 39.910157 0 73.359375-29.40625 82.355469-69 1.542969-6.78125-4.628906-12.777344-11.332031-10.933594-20.613281 5.664063-44.949219 8.9375-71.023438 8.9375zm0 0"
        fill="#8c2f3f"
      />
      <path
        d="m283.714844 395.183594c-13.167969-12.972656-31.621094-21.054688-52.070313-21.054688s-38.902343 8.082032-52.070312 21.054688c14.367187 11.949218 32.433593 19.082031 52.070312 19.082031s37.703125-7.132813 52.070313-19.082031zm0 0"
        fill="#fd4755"
      />
      <g fill="#353535">
        <path d="m156.546875 272.269531c0 10.226563-8.289063 18.519531-18.519531 18.519531-10.226563 0-18.519532-8.292968-18.519532-18.519531 0-10.230469 8.292969-18.519531 18.519532-18.519531 10.230468 0 18.519531 8.289062 18.519531 18.519531zm0 0" />
        <path d="m291.757812 243.253906c-2.402343 0-4.769531-1.117187-6.277343-3.214844-2.492188-3.464843-1.703125-8.292968 1.761719-10.785156 19.734374-14.1875 53.453124-19.921875 76.765624-13.054687 4.09375 1.203125 6.4375 5.5 5.230469 9.59375s-5.503906 6.429687-9.597656 5.230469c-18.609375-5.484376-47.628906-.546876-63.375 10.777343-1.367187.980469-2.945313 1.453125-4.507813 1.453125zm0 0" />
        <path d="m103.691406 224.199219c-2.339844 0-4.648437-1.054688-6.167968-3.058594-2.574219-3.402344-1.90625-8.246094 1.496093-10.824219 18.503907-14.019531 50.265625-19.273437 72.296875-11.960937 4.050782 1.339843 6.246094 5.714843 4.902344 9.765625-1.34375 4.046875-5.71875 6.246094-9.765625 4.898437-17.121094-5.679687-43.726563-1.277343-58.105469 9.613281-1.394531 1.058594-3.03125 1.566407-4.65625 1.566407zm0 0" />
      </g>
    </svg>
  );
};

export default RavianWinkSvg;
