// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const BarricadeSvg = ({
  width = "18",
  height = "18",
  color = c.yellow,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M319.492 414.732H129.015v31.747H382.984v-31.747H351.24zM382.984 224.254v190.478h31.746V224.254zM192.507 224.254h190.477v-31.746H351.24v-63.493h-31.748v63.493H192.507v-63.493h-31.745v63.493h-31.747v31.746h31.747zM287.746 97.268h31.746v31.747h-31.746zM287.746 97.268V65.521h-63.491v31.747H256zM192.507 97.268h31.748v31.747h-31.748zM129.015 351.238V224.254H97.269v190.478h31.746v-31.746zM224.255 287.746h-31.748v63.492h31.748v-31.746zM224.255 351.238v31.748h63.491v-31.748H256zM287.746 287.746v-31.745h-63.491v31.745H256zM287.746 319.492v31.746h31.746v-63.492h-31.746z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default BarricadeSvg;
