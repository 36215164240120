// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import {
  ExplosionProps,
  ExplosionAddProps,
} from "interfaces/spaceInvaders/explosion";

const add = createAction(
  "@async/explosion/add",
  (payload: ExplosionAddProps) => ({
    payload,
  })
);

const initialState: ExplosionProps[] = [];

export const explosionsSlice = createSlice({
  name: "explosions",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<ExplosionProps>) => {
      return [...state, action.payload];
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const explosionsActions = {
  async: {
    add,
  },
  ...explosionsSlice.actions,
};

export default explosionsSlice.reducer;
