import c from "styles/color";

interface IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const WizardHatPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M157.02 453.96h28.28v28.28h-28.28zM213.58 453.96h28.28v28.28h-28.28zM185.3 453.96h28.28v28.28H185.3zM468.1 369.12h28.28v28.28H468.1zM354.98 369.12h28.28v28.28h-28.28zM439.82 397.4h28.28v28.28h-28.28zM326.7 397.4h28.28v28.28H326.7zM411.54 425.68h28.28v28.28h-28.28zM72.18 425.676h28.28v28.28H72.18zM72.18 284.317h28.28v28.28H72.18zM383.26 425.68h28.28v28.28h-28.28zM326.7 453.96h28.28v28.28H326.7zM354.98 453.96h28.28v28.28h-28.28zM270.14 453.96h28.28v28.28h-28.28z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M241.86 453.96h28.28v28.28h-28.28zM298.42 453.96h28.28v28.28h-28.28zM213.58 58.044h28.28v28.28h-28.28zM213.58 142.887h28.28v28.28h-28.28zM185.3 86.328h28.28v28.28H185.3zM185.3 114.612h28.28v28.28H185.3zM157.02 142.896h28.28v28.28h-28.28zM157.02 171.18h28.28v28.28h-28.28zM128.74 199.464h28.28v28.28h-28.28zM128.74 227.749h28.28v28.28h-28.28zM157.02 227.749h28.28v28.28h-28.28zM185.3 256.033h28.28v28.28H185.3zM213.58 256.033h28.28v28.28h-28.28zM128.74 256.033h28.28v28.28h-28.28zM100.46 284.317h28.28v28.28h-28.28zM383.26 284.317h28.28v28.28h-28.28zM354.98 284.317h28.28v28.28h-28.28zM411.54 284.317h28.28v28.28h-28.28zM241.86 29.76h28.28v28.28h-28.28zM270.14 29.76h28.28v28.28h-28.28zM298.42 58.044h28.28v28.28h-28.28zM354.98 58.044h28.28v28.28h-28.28zM270.14 86.328h28.28v28.28h-28.28zM270.14 114.612h28.28v28.28h-28.28zM270.14 142.896h28.28v28.28h-28.28zM298.42 171.18h28.28v28.28h-28.28zM270.14 199.464h28.28v28.28h-28.28zM241.86 199.436h28.28v28.28h-28.28zM298.42 199.464h28.28v28.28h-28.28zM298.42 227.749h28.28v28.28h-28.28zM326.7 256.033h28.28v28.28H326.7zM326.7 284.317h28.28v28.28H326.7zM383.26 369.116h28.28v28.28h-28.28zM298.42 29.76h28.28v28.28h-28.28zM326.742 29.76h28.28v28.28h-28.28zM15.62 369.116H43.9v28.28H15.62zM100.46 340.881h28.28v28.28h-28.28zM468.1 340.84h28.28v28.28H468.1zM411.54 340.84h28.28v28.28h-28.28z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M439.82 312.56h28.28v28.28h-28.28zM43.9 312.556h28.28v28.28H43.9zM15.62 340.836H43.9v28.28H15.62zM72.18 340.836h28.28v28.28H72.18zM43.9 397.396h28.28v28.28H43.9zM100.46 425.68h28.28v28.28h-28.28zM128.74 453.96h28.28v28.28h-28.28zM157.02 312.619h28.28v28.28h-28.28zM213.58 340.881h28.28v28.28h-28.28zM213.58 397.462h28.28v28.28h-28.28zM185.3 312.619h28.28v28.28H185.3zM185.3 397.462h28.28v28.28H185.3zM326.7 312.583h28.28v28.28H326.7zM270.14 340.881h28.28v28.28h-28.28z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M241.86 340.881h28.28v28.28h-28.28zM298.42 312.663h28.28v28.28h-28.28zM128.74 284.321h28.28v28.28h-28.28zM128.74 369.14h28.28v28.28h-28.28z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default WizardHatPixelIcon;
