// constants
import { JARVIS_LEVELS } from "constants/jarvis";

// interfaces
import { JarvisLevelValueProps } from "constants/jarvis";

class JarvisLevelEntity {
  xp = 0;

  constructor({ xp }: { xp: number }) {
    this.xp = xp;
  }

  get(): JarvisLevelValueProps {
    const { xp } = this;
    const { zero, one, two, three, four, five, six, seven, eight, nine, ten } =
      JARVIS_LEVELS;

    if (xp < one.checkpoint) return zero;
    if (xp < two.checkpoint) return one;
    if (xp < three.checkpoint) return two;
    if (xp < four.checkpoint) return three;
    if (xp < five.checkpoint) return four;
    if (xp < six.checkpoint) return five;
    if (xp < seven.checkpoint) return six;
    if (xp < eight.checkpoint) return seven;
    if (xp < nine.checkpoint) return eight;
    if (xp < ten.checkpoint) return nine;

    return ten;
  }

  next(): JarvisLevelValueProps {
    const { xp } = this;
    const { zero, one, two, three, four, five, six, seven, eight, nine, ten } =
      JARVIS_LEVELS;

    if (xp < zero.checkpoint) return zero;
    if (xp < one.checkpoint) return one;
    if (xp < two.checkpoint) return two;
    if (xp < three.checkpoint) return three;
    if (xp < four.checkpoint) return four;
    if (xp < five.checkpoint) return five;
    if (xp < six.checkpoint) return six;
    if (xp < seven.checkpoint) return seven;
    if (xp < eight.checkpoint) return eight;
    if (xp < nine.checkpoint) return nine;
    if (xp < ten.checkpoint) return ten;

    return ten;
  }

  getLabel(): string {
    const {
      one,
      two,
      three,
      four,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      eleven,
    } = JARVIS_LEVELS;
    const { xp } = this;

    if (xp < one.checkpoint) return "0";
    if (xp < two.checkpoint) return "1";
    if (xp < three.checkpoint) return "2";
    if (xp < four.checkpoint) return "3";
    if (xp < five.checkpoint) return "4";
    if (xp < six.checkpoint) return "5";
    if (xp < seven.checkpoint) return "6";
    if (xp < eight.checkpoint) return "7";
    if (xp < nine.checkpoint) return "8";
    if (xp < ten.checkpoint) return "9";
    if (xp < eleven.checkpoint) return "10";

    return "0";
  }
}

export default JarvisLevelEntity;
