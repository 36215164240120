// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";
import { explosionsActions } from "redux/slices/spaceInvaders/explosionsSlice";
import { spaceshipResourcesActions } from "redux/slices/spaceInvaders/spaceshipResourcesSlice";
import { spaceshipMachineGunActions } from "redux/slices/spaceInvaders/spaceshipMachineGunSlice";

// interfaces
import {
  FlyingObjectProps,
  FlyingObjectRefProps,
  FlyingObjectBoundingProps,
} from "interfaces/spaceInvaders/flyingObject";
import { AsteroidProps } from "interfaces/spaceInvaders/asteroid";
import { ExplosionAddProps } from "interfaces/spaceInvaders/explosion";
import { SpaceshipResourcesProps } from "interfaces/spaceInvaders/spaceshipResources";

// components
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// entities
import SpaceshipMineralResourcesEntity, {
  SpaceshipMineralResourcesCounterProps,
} from "entities/spaceInvaders/spaceshipMineralResources/SpaceshipMineralResourcesEntity";
import PlayerSpaceshipResourcesDataFirebaseEntity from "entities/data/PlayerSpaceshipResourcesDataFirebaseEntity";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
} from "enums/spaceInvaders/flyingObjectEnum";
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";
import { SpaceshipResourceType } from "enums/spaceInvaders/spaceshipEnum";

// utils
import { forever } from "async";
import domUtils from "utils/domUtils";
import numberUtils from "utils/numberUtils";

const NEXT_EXPLOSION_DELAY = 50;
const NEX_EMPTY_EXPLOSION_DELAY = 500;
const SPACESHIP_HALF_SIZE = SPACE_INVADERS.spaceship.width / 2;

const MACHINE_GUN_FIRE_DELAY = 100;
const MACHINE_GUN_INIT_MIN_RELOAD_DELAY = 250;

interface FlyingObjectOpacityRefProps {
  [key: string]: number;
}

interface SpaceInvadersProps {
  paused: boolean;
  wrapperEl: HTMLDivElement | null;
  bulletsRef: { current: FlyingObjectRefProps[] };
  invadersRef: { current: FlyingObjectRefProps[] };
  flyingObjectsRef: { current: FlyingObjectRefProps[] };
  invaderBulletsRef: { current: FlyingObjectRefProps[] };
  spaceshipRef: { current: FlyingObjectRefProps | null };
  blink?(): void;
}

const SpaceInvadersBehaviors = ({
  blink,
  paused,
  wrapperEl,
  bulletsRef,
  invadersRef,
  spaceshipRef,
  flyingObjectsRef,
  invaderBulletsRef,
}: SpaceInvadersProps) => {
  const dispatch = useDispatch();
  const spaceshipFeatures = useSelector(s.spaceshipFeatures());
  const spaceshipResources = useSelector(s.spaceshipResources());
  const spaceshipCounter = useSelector(s.spaceshipItemsCounter());
  const pausedRef = useRef(paused);
  const machineGunIdRef = useRef(1);
  const mineralStorageCapacityRef = useRef(0);
  const killForeverProcessRef = useRef(false);
  const isMachineGunFiringRef = useRef(false);
  const stopMachineGunFireRef = useRef(false);
  const machineGunTargetVisible = useRef(false);
  const spaceshipResourcesRef = useRef(spaceshipResources);
  const machineGunLockedEnemy = useRef<FlyingObjectBoundingProps>();
  const machineGunsBulletsRef = useRef(spaceshipFeatures.bulletsRaw);
  const flyingObjectOpacityRef = useRef<FlyingObjectOpacityRefProps>({});

  // spaceship features
  const bulletsTotalRef = useRef(0);
  const bulletsReloadDelayRef = useRef(0);
  const radarDistanceRef = useRef(0);

  useEffect(() => destroyComponent, []);
  useEffect(listenPaused, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenExplosions, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleReloadMachineGun, [paused]);

  // SPACESHIP FEATURES

  useEffect(listenToRadar, [spaceshipFeatures.radar]);
  useEffect(listenToBullets, [spaceshipFeatures.bullets]);
  useEffect(listenToSpaceshipResources, [spaceshipResources]);
  useEffect(handleMineralStorageCapacity, [spaceshipCounter]);
  useEffect(listenToBulletsReloadDelay, [spaceshipFeatures.reload]);

  function listenToBullets() {
    bulletsTotalRef.current = spaceshipFeatures.bullets;
  }

  function listenToRadar() {
    radarDistanceRef.current = spaceshipFeatures.radar;
  }

  function listenToBulletsReloadDelay() {
    bulletsReloadDelayRef.current = spaceshipFeatures.reload;
  }

  function listenToSpaceshipResources() {
    spaceshipResourcesRef.current = spaceshipResources;
  }

  // SPACESHIP FEATURES END

  function listenPaused() {
    pausedRef.current = paused;
  }

  function handleReloadMachineGun() {
    if (paused) return;
    reloadMachineGunWithTimeout();
  }

  function handleMineralStorageCapacity() {
    if (!spaceshipCounter) return;
    if (!spaceshipCounter[SpaceshipItemEnum.MineralStorage]) return;
    if (!spaceshipCounter[SpaceshipItemEnum.MineralStorage].capacity) return;

    mineralStorageCapacityRef.current =
      spaceshipCounter[SpaceshipItemEnum.MineralStorage].capacity;
  }

  function listenExplosions() {
    if (paused) return;
    if (!wrapperEl) return;

    forever(
      (next: () => void) => {
        if (killForeverProcessRef.current) return;
        if (pausedRef.current) return;

        const flyingObjectsElRef: FlyingObjectRefProps[] = [
          ...bulletsRef.current,
          ...flyingObjectsRef.current,
          ...invadersRef.current,
          ...invaderBulletsRef.current,
        ];

        if (spaceshipRef.current) flyingObjectsElRef.push(spaceshipRef.current);

        flyingObjectsElRef.forEach(
          (flyingObjectOne: FlyingObjectRefProps, i) => {
            flyingObjectsElRef.forEach(
              (flyingObjectTwo: FlyingObjectRefProps, j) => {
                if (!spaceshipRef.current) return;

                const { el: elOne, object: objectOne } = flyingObjectOne;
                const { el: elTwo, object: objectTwo } = flyingObjectTwo;

                if (j === i) return;
                if (elOne.style.display === "none") return;
                if (elTwo.style.display === "none") return;
                if (!allowExplosion(objectOne, objectTwo)) return;

                const flyingObjectBoundingOne = getBounding(elOne);
                const flyingObjectBoundingTwo = getBounding(elTwo);

                // spaceship vision
                const nonSpaceshipFlyingObject = getNonSpaceshipFlyingObject(
                  objectOne,
                  objectTwo
                );

                if (!isBulletFlyingObject(nonSpaceshipFlyingObject)) {
                  const spaceshipBounding = getSpaceshipFlyingObjectBounding(
                    flyingObjectOne,
                    flyingObjectTwo,
                    flyingObjectBoundingOne,
                    flyingObjectBoundingTwo
                  );
                  const nonSpaceshipFlyingObjectBounding =
                    getNonSpaceshipFlyingObjectBounding(
                      flyingObjectOne,
                      flyingObjectBoundingOne,
                      flyingObjectBoundingTwo
                    );

                  if (
                    hasHitSpaceshipVision(
                      spaceshipBounding,
                      nonSpaceshipFlyingObjectBounding
                    )
                  ) {
                    updateSpaceshipTargetVisible(
                      true,
                      nonSpaceshipFlyingObject
                    );
                    machineGunLockedEnemy.current =
                      nonSpaceshipFlyingObjectBounding;

                    fire();
                  } else if (
                    !hasHitSpaceshipVision(
                      spaceshipBounding,
                      machineGunLockedEnemy.current
                    )
                  ) {
                    updateSpaceshipTargetVisible(
                      false,
                      nonSpaceshipFlyingObject
                    );
                    stopFire();
                  }
                }
                // spaceship vision END

                if (isAsteroidHit(objectOne, objectTwo)) return;
                if (hasHit(flyingObjectBoundingOne, flyingObjectBoundingTwo)) {
                  objectOne.hit(objectTwo.damage);
                  objectTwo.hit(objectOne.damage);

                  hitFlyingObject(flyingObjectOne);
                  hitFlyingObject(flyingObjectTwo);

                  if (objectOne.type === FlyingObjectType.SpaceShip) {
                    blink && blink();
                    dispatch(playerActions.async.hit(objectOne.toJson()));
                  } else if (objectTwo.type === FlyingObjectType.SpaceShip) {
                    blink && blink();
                    dispatch(playerActions.async.hit(objectTwo.toJson()));
                  }

                  if (objectOne.isDestroyed()) elOne.style.display = "none";
                  if (objectTwo.isDestroyed()) elTwo.style.display = "none";

                  // the monster position is the same than the bullet position...
                  const item = getPreferredExplosionItem(
                    { el: elOne, object: objectOne },
                    { el: elTwo, object: objectTwo }
                  );

                  explode({
                    x:
                      domUtils.getLeftPosition(item.el) -
                      getAverageElHalfSize(item.object),
                    y:
                      domUtils.getTopPosition(item.el) -
                      getAverageElHalfSize(item.object),
                    types: [objectOne.type, objectTwo.type],
                    subtypes: [objectOne.subtype, objectTwo.subtype],
                  });

                  setTimeout(() => {
                    if (objectOne.isDestroyed() || objectTwo.isDestroyed()) {
                      handleScore(flyingObjectOne, flyingObjectTwo);
                      handleResources(flyingObjectOne, flyingObjectTwo);
                    }

                    if (objectOne.isDestroyed())
                      destroyObject(elOne, flyingObjectsElRef, i);

                    if (objectTwo.isDestroyed())
                      destroyObject(elTwo, flyingObjectsElRef, j);
                  });
                }
              }
            );

            if (flyingObjectsElRef.length - 1 === i) {
              setTimeout(next, NEXT_EXPLOSION_DELAY);
            }
          }
        );

        if (flyingObjectsElRef.length === 0)
          setTimeout(next, NEX_EMPTY_EXPLOSION_DELAY);
      },
      (_err: unknown) => {}
    );
  }

  function handleScore(
    flyingObjectOne: FlyingObjectRefProps,
    flyingObjectTwo: FlyingObjectRefProps
  ) {
    if (!wrapperEl) return;

    const invaderFlyingObject = getInvaderFlyingObject(
      flyingObjectOne,
      flyingObjectTwo
    );

    if (!invaderFlyingObject) return;
    if (!invaderFlyingObject.object.isDestroyed()) return;

    const preferredFlyingObject = getPreferredFlyingObject(
      flyingObjectOne,
      flyingObjectTwo
    );

    if (!preferredFlyingObject) return;

    dispatch(playerActions.async.incScore(1));
    animateAlert(
      wrapperEl,
      preferredFlyingObject.el,
      AlertFactory({
        content: "+1 s",
        type: AlertFactoryType.Congrats,
        animation: "animate__wobble",
      })
    );
  }

  function handleResources(
    flyingObjectOne: FlyingObjectRefProps,
    flyingObjectTwo: FlyingObjectRefProps
  ) {
    if (!wrapperEl) return;

    const flyingObject = getAsteroidFlyingObject(
      flyingObjectOne,
      flyingObjectTwo
    );

    if (!flyingObject) return;
    if (!flyingObject.object.isDestroyed()) return;

    const preferredFlyingObject = getPreferredFlyingObject(
      flyingObjectOne,
      flyingObjectTwo
    );

    if (!preferredFlyingObject) return;

    const isResourceAvailable = getResourceAvailability(flyingObject.object);
    const asteroid = flyingObject.object as unknown as AsteroidProps;
    const asteroidSubtype =
      asteroid.subtype as unknown as SpaceshipResourceType;
    const asteroidPropsName = asteroid.key as keyof SpaceshipResourcesProps;
    const asteroidName = getAsteroidName(asteroid);
    const asteroidMax = SPACE_INVADERS.spaceshipResources[asteroidSubtype].max;

    if (!isResourceAvailable) {
      const content = `0 ${
        asteroid.isMineral ? `mineral (${asteroidName}) (bruto)` : asteroidName
      }`;

      animateAlert(
        wrapperEl,
        preferredFlyingObject.el,
        AlertFactory({ content, type: AlertFactoryType.Light })
      );

      return;
    }

    if (asteroid.isMineral && !hasMineralStorageCapacity()) {
      animateAlert(
        wrapperEl,
        preferredFlyingObject.el,
        AlertFactory({
          content: "O armazenamento de minerais atingiu o limite máximo...",
          type: AlertFactoryType.Warning,
        })
      );

      return;
    }

    if (
      !PlayerSpaceshipResourcesDataFirebaseEntity.hasAvailableResource(
        spaceshipResourcesRef.current[asteroidPropsName],
        asteroidMax,
        1
      )
    ) {
      const content = `Limite máximo (${asteroidMax}) atingido: ${
        asteroid.isMineral ? `mineral (${asteroidName}) (bruto)` : asteroidName
      }`;

      animateAlert(
        wrapperEl,
        preferredFlyingObject.el,
        AlertFactory({ content, type: AlertFactoryType.Warning })
      );

      return;
    }

    winXp(preferredFlyingObject.el);
    winResource(preferredFlyingObject.el, asteroid);
  }

  function winResource(el: HTMLDivElement, asteroid: AsteroidProps) {
    if (!wrapperEl) return;

    const content = `+1 ${
      asteroid.isMineral
        ? `mineral (${getAsteroidName(asteroid)}) (bruto)`
        : getAsteroidName(asteroid)
    }`;

    animateAlert(
      wrapperEl,
      el,
      AlertFactory({
        content,
        type: AlertFactoryType.Success,
        animation: "animate__wobble",
      })
    );

    dispatch(spaceshipResourcesActions.async.add({ [asteroid.key]: 1 }));
  }

  function winXp(el: HTMLDivElement) {
    if (!wrapperEl) return;
    if (numberUtils.randomInterval(0, 9) < 2) return;

    animateAlert(
      wrapperEl,
      el,
      AlertFactory({
        content: "+1 xp (canhão a laser)",
        type: AlertFactoryType.Warning,
        animation: "animate__wobble",
      }),
      { top: -30, left: 0 }
    );

    dispatch(playerActions.async.winXp());
  }

  function getAsteroidName(asteroid: AsteroidProps) {
    return asteroid.name ? asteroid.name.toLowerCase() : "";
  }

  function hasMineralStorageCapacity() {
    return (
      mineralStorageCapacityRef.current >
      countTotalResources(spaceshipResourcesRef.current).total
    );
  }

  function countTotalResources(
    resource: SpaceshipResourcesProps
  ): SpaceshipMineralResourcesCounterProps {
    const entity = new SpaceshipMineralResourcesEntity(resource);
    return entity.count;
  }

  function animateAlert(
    wrapper: HTMLDivElement,
    target: HTMLDivElement,
    factory: () => HTMLDivElement,
    config: { top: number; left: number } = {
      top: 0,
      left: 0,
    }
  ) {
    setTimeout(() => {
      const left = `${domUtils.getLeftPosition(target) + config.left}px`;
      const top = `${
        domUtils.getTopPosition(target) + config.top - target.clientHeight / 2
      }px`;

      alertAnimate({ wrapper, bounds: { top, left }, factories: [factory] });
    });
  }

  function getResourceAvailability({ subtype }: FlyingObjectProps): number {
    if (subtype === FlyingObjectSubType.AsteroidMineralGold)
      return numberUtils.randomInterval(0, 2);
    if (subtype === FlyingObjectSubType.AsteroidMineralBauxite)
      return numberUtils.randomInterval(0, 4);
    if (subtype === FlyingObjectSubType.AsteroidMineralSilver)
      return numberUtils.randomInterval(0, 2);
    if (subtype === FlyingObjectSubType.AsteroidMineralSand)
      return numberUtils.randomInterval(0, 3);
    if (subtype === FlyingObjectSubType.AsteroidMineralCopper)
      return numberUtils.randomInterval(0, 3);
    if (subtype === FlyingObjectSubType.AsteroidWater)
      return numberUtils.randomInterval(0, 3);
    if (subtype === FlyingObjectSubType.AsteroidStrongForce)
      return numberUtils.randomInterval(0, 2);

    return numberUtils.randomInterval(0, 1);
  }

  function hitFlyingObject(flyingObject: FlyingObjectRefProps) {
    const { el, object } = flyingObject;
    if (!isInvaderFlyingObject(object) && !isAsteroideFlyingObject(object))
      return;

    const { id } = el;

    if (
      !flyingObjectOpacityRef.current[id] &&
      flyingObjectOpacityRef.current[id] !== 0
    )
      flyingObjectOpacityRef.current[id] = 1;

    flyingObject.el.style.opacity = `${(flyingObjectOpacityRef.current[
      id
    ] -= 0.2)}`;
  }

  function getPreferredFlyingObject(
    flyingObjectOne: FlyingObjectRefProps,
    flyingObjectTwo: FlyingObjectRefProps
  ): FlyingObjectRefProps | undefined {
    return isBulletFlyingObject(flyingObjectOne.object) ||
      isSpaceshipFlyingObject(flyingObjectOne.object)
      ? flyingObjectOne
      : isBulletFlyingObject(flyingObjectTwo.object) ||
        isSpaceshipFlyingObject(flyingObjectTwo.object)
      ? flyingObjectTwo
      : undefined;
  }

  function getInvaderFlyingObject(
    flyingObjectOne: FlyingObjectRefProps,
    flyingObjectTwo: FlyingObjectRefProps
  ): FlyingObjectRefProps | undefined {
    return isInvaderFlyingObject(flyingObjectOne.object)
      ? flyingObjectOne
      : isInvaderFlyingObject(flyingObjectTwo.object)
      ? flyingObjectTwo
      : undefined;
  }

  function getAsteroidFlyingObject(
    flyingObjectOne: FlyingObjectRefProps,
    flyingObjectTwo: FlyingObjectRefProps
  ): FlyingObjectRefProps | undefined {
    return isAsteroideFlyingObject(flyingObjectOne.object)
      ? flyingObjectOne
      : isAsteroideFlyingObject(flyingObjectTwo.object)
      ? flyingObjectTwo
      : undefined;
  }

  function updateSpaceshipTargetVisible(
    targetVisible: boolean,
    flyingObject: FlyingObjectProps
  ) {
    if (machineGunTargetVisible.current === targetVisible) return;
    if (isInvaderBulletFlyingObject(flyingObject)) return;

    dispatch(
      spaceshipMachineGunActions.async.target(
        targetVisible,
        machineGunTargetVisible
      )
    );
  }

  function hasHitSpaceshipVision(
    spaceshipBounding: FlyingObjectBoundingProps,
    flyingObjectBounding?: FlyingObjectBoundingProps
  ): boolean {
    if (!flyingObjectBounding) return false;

    const spaceshipTop = spaceshipBounding.top;
    const flyingObjectTop = flyingObjectBounding.bottom;

    if (spaceshipTop <= flyingObjectTop) return false;
    if (spaceshipTop - flyingObjectTop > radarDistanceRef.current) return false;

    return !(
      spaceshipBounding.right < flyingObjectBounding.left ||
      spaceshipBounding.left > flyingObjectBounding.right
    );
  }

  function destroyObject(
    el: HTMLDivElement,
    list: FlyingObjectRefProps[],
    index: number
  ) {
    el.remove();
    list.splice(index, 1);
  }

  function fire() {
    if (isMachineGunFiringRef.current) return;
    if (machineGunsBulletsRef.current < 1) return;
    if (killForeverProcessRef.current) return;
    if (pausedRef.current) return;

    stopMachineGunFireRef.current = false;
    isMachineGunFiringRef.current = true;
    machineGunIdRef.current += 1;

    const f = fireMachineGun(machineGunIdRef.current);

    for (let i = 0; i < machineGunsBulletsRef.current; i++)
      f(i + 1 === machineGunsBulletsRef.current);
  }

  function fireMachineGun(id: number) {
    let delay = 0;

    return (last: boolean) =>
      setTimeout(() => {
        if (id !== machineGunIdRef.current) return;
        if (stopMachineGunFireRef.current) return;
        if (killForeverProcessRef.current) return;
        if (pausedRef.current) return;

        dispatch(spaceshipMachineGunActions.async.fire());
        dispatch(
          spaceshipMachineGunActions.async.removeBullets(machineGunsBulletsRef)
        );

        if (last) stopFire();
      }, (delay += MACHINE_GUN_FIRE_DELAY));
  }

  function stopFire() {
    if (stopMachineGunFireRef.current) return;

    stopMachineGunFireRef.current = true;
    isMachineGunFiringRef.current = false;
    machineGunLockedEnemy.current = undefined;

    reloadMachineGunWithTimeout();
  }

  function reloadMachineGunWithTimeout() {
    setTimeout(
      reloadMachineGun.bind(null, machineGunIdRef.current),
      MACHINE_GUN_INIT_MIN_RELOAD_DELAY > bulletsReloadDelayRef.current
        ? MACHINE_GUN_INIT_MIN_RELOAD_DELAY
        : bulletsReloadDelayRef.current
    );
  }

  function reloadMachineGun(id: number) {
    forever(
      (next: () => void) => {
        if (id !== machineGunIdRef.current) return;
        if (machineGunsBulletsRef.current >= bulletsTotalRef.current) return;
        if (killForeverProcessRef.current) return;
        if (pausedRef.current) return;

        dispatch(
          spaceshipMachineGunActions.async.reload(machineGunsBulletsRef)
        );

        if (machineGunsBulletsRef.current >= bulletsTotalRef.current) return;
        setTimeout(next, bulletsReloadDelayRef.current);
      },
      (_err: unknown) => {}
    );
  }

  function getSpaceshipFlyingObjectBounding(
    objectOne: FlyingObjectRefProps,
    objectTwo: FlyingObjectRefProps,
    boundOne: FlyingObjectBoundingProps,
    boundTwo: FlyingObjectBoundingProps
  ): FlyingObjectBoundingProps {
    return isSpaceshipFlyingObject(objectOne.object)
      ? boundOne
      : isSpaceshipFlyingObject(objectTwo.object)
      ? boundTwo
      : getBounding(spaceshipRef.current?.el as HTMLDivElement);
  }

  function getNonSpaceshipFlyingObjectBounding(
    objectOne: FlyingObjectRefProps,
    boundOne: FlyingObjectBoundingProps,
    boundTwo: FlyingObjectBoundingProps
  ): FlyingObjectBoundingProps {
    return isSpaceshipFlyingObject(objectOne.object) ? boundTwo : boundOne;
  }

  function getNonSpaceshipFlyingObject(
    objectOne: FlyingObjectProps,
    objectTwo: FlyingObjectProps
  ): FlyingObjectProps {
    return isSpaceshipFlyingObject(objectOne) ? objectTwo : objectOne;
  }

  function hasHit(
    boundOne: FlyingObjectBoundingProps,
    boundTwo: FlyingObjectBoundingProps
  ): boolean {
    return !(
      boundOne.top > boundTwo.bottom ||
      boundOne.right < boundTwo.left ||
      boundOne.bottom < boundTwo.top ||
      boundOne.left > boundTwo.right
    );
  }

  function getPreferredExplosionItem(
    itemOne: FlyingObjectRefProps,
    itemTwo: FlyingObjectRefProps
  ): FlyingObjectRefProps {
    return isSpaceshipFlyingObject(itemOne.object) ||
      isBulletFlyingObject(itemOne.object)
      ? itemOne
      : itemTwo;
  }

  function getAverageElHalfSize(object: FlyingObjectProps): number {
    return isSpaceshipFlyingObject(object)
      ? SPACESHIP_HALF_SIZE
      : SPACE_INVADERS.bullet.averageSize;
  }

  function getBounding(el: HTMLDivElement): FlyingObjectBoundingProps {
    return el.getBoundingClientRect();
  }

  function explode({ x, y, types, subtypes }: ExplosionAddProps) {
    dispatch(explosionsActions.async.add({ x, y, types, subtypes }));
  }

  function allowExplosion(
    objectOne: FlyingObjectProps,
    objectTwo: FlyingObjectProps
  ): boolean {
    if (
      (isSpaceshipFlyingObject(objectOne) && isBulletFlyingObject(objectTwo)) ||
      (isBulletFlyingObject(objectOne) && isSpaceshipFlyingObject(objectTwo))
    )
      return false;
    if (isBulletFlyingObject(objectOne) && isBulletFlyingObject(objectTwo))
      return false;

    return (
      isSpaceshipFlyingObject(objectOne) ||
      isSpaceshipFlyingObject(objectTwo) ||
      isBulletFlyingObject(objectOne) ||
      isBulletFlyingObject(objectTwo)
    );
  }

  function isAsteroidHit(
    objectOne: FlyingObjectProps,
    objectTwo: FlyingObjectProps
  ): boolean {
    return (
      (isAsteroideFlyingObject(objectOne) &&
        isSpaceshipFlyingObject(objectTwo)) ||
      (isSpaceshipFlyingObject(objectOne) && isAsteroideFlyingObject(objectTwo))
    );
  }

  function isSpaceshipFlyingObject(item: FlyingObjectProps): boolean {
    return item.type === FlyingObjectType.SpaceShip;
  }

  function isBulletFlyingObject(item: FlyingObjectProps): boolean {
    return item.type === FlyingObjectType.Bullet;
  }

  function isInvaderBulletFlyingObject(item: FlyingObjectProps): boolean {
    return item.type === FlyingObjectType.InvaderBullet;
  }

  function isAsteroideFlyingObject(item: FlyingObjectProps): boolean {
    return item.type === FlyingObjectType.Asteroid;
  }

  function isInvaderFlyingObject(item: FlyingObjectProps): boolean {
    return item.type === FlyingObjectType.Invader;
  }

  function destroyComponent() {
    killForeverProcessRef.current = true;
  }

  return null;
};

export default SpaceInvadersBehaviors;
