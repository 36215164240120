// react
import { useState, useEffect, memo } from "react";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";

// components
import HeartPixelSvg from "svg/HeartPixelSvg";

// enums
import { SpaceshipPartType } from "enums/spaceInvaders/spaceshipEnum";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  .icon_svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 20px;
  }

  > li {
    :not(:first-child) {
      margin-left: 5px;
    }
  }
`;

interface HeartProps {
  loseLife: boolean;
  lost: boolean;
  type: SpaceshipPartType;
}

interface IconSvgProps {
  heart: HeartProps;
  children: React.ReactNode;
  heartBeatAnimation?: boolean;
  onHeartBeatAnimationEnd?: () => void;
}

const IconSvg = ({
  heart,
  children,
  heartBeatAnimation,
  onHeartBeatAnimationEnd,
}: IconSvgProps) => {
  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
    onHeartBeatAnimationEnd && onHeartBeatAnimationEnd();
  }

  return (
    <div
      className={`icon_svg life ${heart.lost ? "lost " : ""} ${
        heart.loseLife && heart.lost ? "animate__animated animate__jello " : ""
      } ${
        heartBeatAnimation && !heart.lost
          ? " animate__animated animate__heartBeat "
          : ""
      }`}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  );
};

interface PlayerLifeProps {
  len?: number;
  life?: number;
  invincible?: boolean;
  loseLifeAnimation?: boolean;
  heartBeatAnimation?: boolean;
  onHeartBeatAnimationEnd?: () => void;
}

const PlayerLife = ({
  life = 0,
  invincible,
  onHeartBeatAnimationEnd,
  loseLifeAnimation = false,
  heartBeatAnimation = false,
  len = PlayerHpEntity.lifeTotal,
}: PlayerLifeProps) => {
  const { External, Wing, Engine } = SpaceshipPartType;
  const [hearts, setHearts] = useState<HeartProps[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleLife, [life]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  function handleLife() {
    setHearts(map(Array(len).fill(0)));
  }

  function map(list: number[]) {
    const lost = len - life;

    return list.map((_, i: number) => {
      const data: HeartProps = {
        lost: false,
        loseLife: false,
        type: i === 0 ? Engine : i === 1 ? Wing : External,
      };

      if (lost && i + lost >= len) {
        if (life === i && loseLifeAnimation) data.loseLife = true;
        data.lost = true;
      }

      return data;
    });
  }

  return (
    <Wrapper>
      {hearts.map((heart, i: number) => (
        <li key={i}>
          {heart.type === Engine ? (
            <IconSvg
              heart={heart}
              heartBeatAnimation={heartBeatAnimation}
              onHeartBeatAnimationEnd={onHeartBeatAnimationEnd}
            >
              <HeartPixelSvg
                width="25"
                height="18"
                color={`${
                  life >= i + 1
                    ? invincible
                      ? color.primary
                      : color.red
                    : color.grey.stronger
                }`}
              />
            </IconSvg>
          ) : heart.type === Wing ? (
            <IconSvg
              heart={heart}
              heartBeatAnimation={heartBeatAnimation}
              onHeartBeatAnimationEnd={onHeartBeatAnimationEnd}
            >
              <HeartPixelSvg
                width="25"
                height="18"
                color={`${
                  life >= i + 1
                    ? invincible
                      ? color.primary
                      : color.red
                    : color.grey.stronger
                }`}
              />
            </IconSvg>
          ) : (
            <IconSvg
              heart={heart}
              heartBeatAnimation={heartBeatAnimation}
              onHeartBeatAnimationEnd={onHeartBeatAnimationEnd}
            >
              <HeartPixelSvg
                width="25"
                height="18"
                color={`${
                  life >= i + 1
                    ? invincible
                      ? color.primary
                      : color.red
                    : color.grey.stronger
                }`}
              />
            </IconSvg>
          )}
        </li>
      ))}
    </Wrapper>
  );
};

export default memo(PlayerLife);
