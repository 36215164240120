// interfaces
import { ChallengeProps } from "interfaces/challenge";

// enums
import { ChallengeType } from "enums/challengeEnum";

// handlers
import AvatarIconHandler from "handlers/AvatarIconHandler";

// utils
import styled from "styled-components";

const StatusBarWrapper = styled.div`
  height: 45px;

  > ul {
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: center;
    position: relative;
    margin: 0;
    padding: 0 5px;
    height: 100%;

    li {
      margin: 0;
      padding: 0;

      .avatar_icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

interface ChallengeStatusBarProps {
  challenge: ChallengeProps;
}

const ChallengeStatusBar = ({ challenge }: ChallengeStatusBarProps) => {
  return (
    <StatusBarWrapper>
      <ul>
        <li>
          <div className="avatar_icon">
            <AvatarIconHandler
              type={challenge.type}
              customClassName={`${
                challenge.type !== ChallengeType.Introduction &&
                challenge.flowFinished &&
                challenge.flowDone &&
                "animate__animated animate__hinge"
              }`}
              avatarInvader={challenge.avatarInvader}
            />
          </div>
        </li>
      </ul>
    </StatusBarWrapper>
  );
};

export default ChallengeStatusBar;
