interface SvgProps {
  width?: string;
  height?: string;
}

const RavianSmirkingSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-24 0 511 511.99872"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m362.300781 265.117188c0 10.226562-8.292969 18.523437-18.519531 18.523437-10.230469 0-18.523438-8.292969-18.523438-18.523437 0-8.398438 5.601563-15.496094 13.265626-17.75 1.425781.183593 2.84375.398437 4.257812.65625 4.644531.847656 9.238281 2.074218 13.742188 3.667968 3.5625 3.371094 5.777343 8.140625 5.777343 13.425782zm0 0"
        fill="#353535"
      />
      <path
        d="m175.066406 265.117188c0 10.226562-8.292968 18.523437-18.523437 18.523437-10.21875 0-18.511719-8.292969-18.511719-18.523437 0-8.386719 5.585938-15.472657 13.238281-17.742188 1.402344.175781 2.804688.390625 4.195313.648438 4.695312.855468 9.332031 2.09375 13.875 3.710937 3.53125 3.378906 5.726562 8.117187 5.726562 13.382813zm0 0"
        fill="#353535"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <g fill="#353535">
        <path d="m285.789062 264.570312c-2.773437 0-5.453124-1.492187-6.835937-4.113281-1.996094-3.769531-.558594-8.449219 3.210937-10.441406 8.699219-4.605469 18.019532-7.832031 27.699219-9.59375 9.824219-1.789063 19.8125-2.027344 29.679688-.714844 1.625.210938 3.140625.445313 4.625.714844 5.039062.917969 10.066406 2.261719 14.9375 3.988281 4.433593 1.574219 8.722656 3.460938 12.765625 5.609375 3.769531 2.003907 5.199218 6.679688 3.199218 10.449219-2.003906 3.769531-6.679687 5.199219-10.449218 3.195312-3.375-1.792968-6.972656-3.371093-10.6875-4.691406-4.085938-1.453125-8.304688-2.578125-12.535156-3.347656-1.226563-.222656-2.488282-.417969-3.871094-.597656-8.289063-1.105469-16.660156-.902344-24.898438.597656-8.121094 1.476562-15.9375 4.183594-23.234375 8.046875-1.152343.609375-2.390625.898437-3.605469.898437zm0 0" />
        <path d="m98.46875 264.570312c-2.769531 0-5.449219-1.492187-6.835938-4.113281-1.996093-3.769531-.554687-8.449219 3.214844-10.441406 8.699219-4.605469 18.019532-7.832031 27.699219-9.59375 9.871094-1.789063 19.878906-2.023437 29.746094-.707031 1.492187.1875 3.050781.429687 4.574219.710937 5.085937.925781 10.160156 2.28125 15.0625 4.027344 4.332031 1.546875 8.582031 3.417969 12.625 5.5625 3.773437 2 5.207031 6.675781 3.207031 10.445313-1.996094 3.769531-6.671875 5.207031-10.441407 3.207031-3.390624-1.796875-6.949218-3.363281-10.578124-4.660157-4.117188-1.464843-8.375-2.601562-12.664063-3.382812-1.25-.230469-2.511719-.425781-3.769531-.582031-8.332032-1.117188-16.722656-.917969-25.003906.582031-8.117188 1.476562-15.933594 4.183594-23.230469 8.046875-1.148438.609375-2.386719.898437-3.605469.898437zm0 0" />
        <path d="m371.851562 225.761719c-1.636718 0-3.285156-.515625-4.6875-1.589844-16.453124-12.578125-38.511718-18.3125-59.011718-15.339844-4.214844.617188-8.144532-2.316406-8.753906-6.539062-.613282-4.222657 2.3125-8.140625 6.535156-8.753907 24.890625-3.613281 50.632812 3.078126 70.617187 18.359376 3.390625 2.589843 4.035157 7.441406 1.445313 10.832031-1.519532 1.988281-3.820313 3.03125-6.144532 3.03125zm0 0" />
        <path d="m91.4375 225.761719c-2.324219.003906-4.625-1.042969-6.144531-3.03125-2.59375-3.390625-1.945313-8.238281 1.445312-10.832031 19.984375-15.277344 45.726563-21.972657 70.617188-18.359376 4.222656.613282 7.148437 4.53125 6.535156 8.753907-.609375 4.222656-4.542969 7.15625-8.753906 6.539062-20.496094-2.96875-42.558594 2.761719-59.011719 15.34375-1.402344 1.066407-3.050781 1.585938-4.6875 1.585938zm0 0" />
      </g>
      <path
        d="m284.074219 385.027344h-122.84375c-4.269531 0-7.726563-3.457032-7.726563-7.726563 0-4.265625 3.460938-7.722656 7.726563-7.722656h122.84375c17.480469 0 33.707031-10.160156 41.34375-25.882813l7.082031-14.578124c1.867188-3.839844 6.488281-5.441407 10.328125-3.574219 3.835937 1.863281 5.4375 6.484375 3.570313 10.324219l-7.078126 14.582031c-10.207031 21.007812-31.890624 34.578125-55.246093 34.578125zm0 0"
        fill="#844d15"
      />
    </svg>
  );
};

export default RavianSmirkingSvg;
