// components
import {
  defaultColor,
  defaultWidth,
  defaultHeight,
  AvatarInvadersProps,
} from "handlers/AvatarInvaderHandler";

// components
import LinearGradientEffectSvg from "svg/LinearGradientEffectSvg";

// utils
import { v4 as uuidv4 } from "uuid";

const AvatarInvaderTwo = ({
  gradient = false,
  width = defaultWidth,
  height = defaultHeight,
}: AvatarInvadersProps) => {
  const id = uuidv4();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 550.801 550.801"
    >
      {gradient && (
        <defs>
          <LinearGradientEffectSvg id={id} />
        </defs>
      )}

      <g>
        <path
          d="M205.546 380.505v.153h.159v-.153h-.159zM136.341 434.948h.153v-.109h-.153v.109zM0 489.601h67.32v-55.08H0v55.08z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M483.48 165.24h-73.441v-48.96h-67.318V61.2H208.08v55.08h-67.32v48.96H67.32v55.08H0v104.04h140.76v55.08H67.32v55.08h73.44v-54.027c18.018.006 42.84.006 67.32.012v53.728c-12.24 0-55.08.478-67.32.716V489.6h67.32v-55.079h134.641V489.6h67.318v-54.651c-12.238-.238-55.08-.716-67.318-.716v-53.728c24.479-.006 49.303-.012 67.318-.012v54.027h73.441v-55.08h-73.441v-55.08h140.762V220.32h-67.32l-.001-55.08zm-342.72 55.08h67.32v55.08h-67.32v-55.08zM275.4 379.44h-67.32v-55.08h134.641v55.08H275.4zM410.039 275.4h-67.318v-55.08h67.318v55.08z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M345.254 380.658v-.153h-.16v.153h.16zM414.305 434.948h.154v-.109h-.154v.109zM550.801 489.601v-55.08h-67.32v55.08h67.32z"
          fill={`url(#${id}) ${defaultColor}`}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default AvatarInvaderTwo;
