// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ClassRoomInputProps {
  challenges: ChallengeCommonProps[];
}

class ClassRoomEntity {
  private challenges: ChallengeCommonProps[];

  constructor({ challenges }: ClassRoomInputProps) {
    this.challenges = challenges;
  }

  isFlowParcialDone(): boolean {
    const len = this.challenges.length;
    const finished = this.challenges.filter((c) => c.flowDone).length;

    return finished >= Math.ceil(len * 0.4);
  }
}

export default ClassRoomEntity;
