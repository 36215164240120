// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// interfaces
import { DragWhoreProps } from "interfaces/dragWhore";
import { ChallengeProps } from "interfaces/challenge";

// components
import { DropZoneDragWhore } from "components/DropZone";

// handlers
import SudoInit from "handlers/challengeButtons/sudo/SudoInit";
import SudoStarted from "handlers/challengeButtons/sudo/SudoStarted";

// enums
import { BubbleType } from "enums/bubbleEnum";

// utils
import dragWhoreUtils from "utils/dragWhoreUtils";

type DragWhoreCbType = (whore: DragWhoreProps) => void;

export interface SudoFlowProps {
  paused: boolean;
  challenge: ChallengeProps;
  getContent(whore: DragWhoreProps): string;
  getWhores(cmd: string, cb: DragWhoreCbType): DragWhoreProps[];
  wrapper?: HTMLElement | null;
}

export interface CurrentDragWhoreProps extends DragWhoreProps {
  show: boolean;
  speech?: string;
  content?: string;
}

interface SudoHandlerProps {
  paused: boolean;
  challenge: ChallengeProps;
  wrapper: HTMLDivElement | null;
}

const SudoHandler = ({ paused, wrapper, challenge }: SudoHandlerProps) => {
  const section = useSelector(s.challengeSection());

  function getWhores(cmd: string, cb: DragWhoreCbType): DragWhoreProps[] {
    return [message(cmd, challenge.speeches[0], cb, true)];
  }

  function message(
    cmd: string,
    speech: string,
    onDragWhoreCall: DragWhoreCbType,
    last: boolean
  ): DragWhoreProps {
    if (!wrapper) return dragWhoreUtils.fake();

    return dragWhoreUtils.get({
      last,
      payload: {
        speech,
        content: `${cmd} “${challenge.name} (${section.name})"`,
      },
      call: onDragWhoreCall,
      bubbleType: BubbleType.Speech,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function getContent(whore: DragWhoreProps): string {
    const payload = whore.payload as CurrentDragWhoreProps;
    return payload.speech || "";
  }

  if (challenge.flowDone) return null;

  return (
    <>
      <SudoInit
        paused={paused}
        challenge={challenge}
        getWhores={getWhores}
        getContent={getContent}
      />

      <SudoStarted
        paused={paused}
        challenge={challenge}
        getWhores={getWhores}
        getContent={getContent}
      />

      <DropZoneDragWhore dropZoneClassName="drag_whore_dropzone_sudo" />
    </>
  );
};

export default SudoHandler;
