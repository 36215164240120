export interface JarvisLevelValueProps {
  checkpoint: number;
  totalPoints: number;
}

interface JarvisLevelProps {
  [key: string]: JarvisLevelValueProps;
}

enum JarvisCheckpoint {
  Zero = 0,
  One = 5,
  Two = 15,
  Three = 20,
  Four = 35,
  Five = 55,
  Six = 90,
  Seven = 105,
  Eight = 195,
  Nine = 300,
  Ten = 495,
}

enum JarvisTotalPoints {
  Zero = 5,
  One = 7,
  Two = 9,
  Three = 11,
  Four = 13,
  Five = 15,
  Six = 17,
  Seven = 19,
  Eight = 21,
  Nine = 23,
  Ten = 25,
}

const CP = JarvisCheckpoint;
const TP = JarvisTotalPoints;

export const JARVIS_LEVELS: JarvisLevelProps = {
  zero: { checkpoint: CP.Zero, totalPoints: TP.Zero },
  one: { checkpoint: CP.One, totalPoints: TP.One }, // 5
  two: { checkpoint: CP.Two, totalPoints: TP.Two },
  three: { checkpoint: CP.Three, totalPoints: TP.Three },
  four: { checkpoint: CP.Four, totalPoints: TP.Four },
  five: { checkpoint: CP.Five, totalPoints: TP.Five },
  six: { checkpoint: CP.Six, totalPoints: TP.Six },
  seven: { checkpoint: CP.Seven, totalPoints: TP.Seven },
  eight: { checkpoint: CP.Eight, totalPoints: TP.Eight },
  nine: { checkpoint: CP.Nine, totalPoints: TP.Nine },
  ten: { checkpoint: CP.Ten, totalPoints: TP.Ten },
};

export const JARVIS_BULLETS_INITIAL_VALUE = 1;
export const JARVIS_RELOAD_INITIAL_VALUE = 1;
export const JARVIS_RADAR_INITIAL_VALUE = 1;
export const JARVIS_SPACESHIP_FEATURE_MAX_RADAR_VALUE = 15;
export const JARVIS_SPACESHIP_FEATURE_MAX_RELOAD_VALUE = 10;
