// react
import { renderToString } from "react-dom/server";

// components
import IconCode from "components/IconCode";
import Icon, { faHandPointRight } from "components/Icon";

// utils
import color from "styles/color";
import styled from "styled-components";

const encodedURITip = encodeURIComponent(
  renderToString(<Icon icon={faHandPointRight} color={color.yellow} />)
);

const encodedURIChallenge = encodeURIComponent(renderToString(<IconCode />));

const Wrapper = styled.div`
  width: 100%;
  color: ${color.grey.middle};
  line-height: 25px;

  &.black_board {
    .important,
    .imp {
      color: ${color.grey.light} !important;
    }
  }

  &.white_board {
    .important,
    .imp {
      color: ${color.white} !important;
    }
  }

  &.white_board,
  &.black_board {
    p {
      font-size: 1.5em;
      margin: 20px 0;

      &.tip {
        color: ${color.grey.middle};

        &:before {
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          padding-right: 8px;
          content: url("data:image/svg+xml, ${encodedURITip}");
        }
      }
    }

    .challenge {
      &:after {
        display: inline-block;
        position: absolute;
        top: 0px;
        right: 15px;
        width: 18px;
        line-height: 1;
        content: url("data:image/svg+xml, ${encodedURIChallenge}");
      }
    }

    .white {
      color: ${color.white};
      margin: 0 2px;
      padding: 0;
    }

    .red {
      color: ${color.red};
      margin: 0 2px;
      padding: 0;
    }

    .blue {
      color: ${color.blue};
      margin: 0 2px;
      padding: 0;
    }

    .green {
      color: ${color.green};
      margin: 0 2px;
      padding: 0;
    }

    .purple {
      color: ${color.purple};
      margin: 0 2px;
      padding: 0;
    }

    .yellow {
      color: ${color.yellow};
      margin: 0 2px;
      padding: 0;
    }

    .orange {
      color: ${color.orange};
      margin: 0 2px;
      padding: 0;
    }

    .brown {
      color: ${color.brown};
      margin: 0 2px;
      padding: 0;
    }

    .pink {
      color: ${color.pink};
      margin: 0 2px;
      padding: 0;
    }

    .navy {
      color: ${color.navy};
      margin: 0 2px;
      padding: 0;
    }

    .gold {
      color: ${color.gold};
      margin: 0 2px;
      padding: 0;
    }

    .warning {
      color: ${color.yellow};
      margin: 0 2px;
      padding: 0;
    }

    .command,
    .cmd {
      color: ${color.green};
      margin: 0 2px;
      padding: 0;
    }

    .congrats {
      color: ${color.purple} !important;
      margin: 0 2px;
      padding: 0;
    }

    .v_imp {
      color: ${color.red} !important;
      margin: 0 2px;
      padding: 0;
    }
  }
`;

interface BoardProps {
  children: React.ReactNode;
  white?: boolean;
}

const Board = ({ children, white }: BoardProps) => {
  return (
    <Wrapper className={`${white ? "white_board" : "black_board"}`}>
      {children}
    </Wrapper>
  );
};

export default Board;
