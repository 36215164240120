// react
import { useRef, useState } from "react";
import { IonicSlides } from "@ionic/react";

// entities
import {
  ItemCivilizationOneEntity,
  ItemCivilizationTwoEntity,
  ItemCivilizationThreeEntity,
} from "entities/items/ItemEntity";

// components
import SlideDotsProgress from "components/SlideDotsProgress";

// interfaces
import {
  ListItems,
  ListItemProps,
  NoneDescription,
} from "components/playerProfile/PlayerProfile";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface PlayerProfileCivilizationsSliceProps {
  checkpoints: PlayerCheckpointsStateProps;
}

const PlayerProfileCivilizationsSlide = ({
  checkpoints,
}: PlayerProfileCivilizationsSliceProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  function getCivilizations(): ListItemProps[] {
    const one = new ItemCivilizationOneEntity();
    const two = new ItemCivilizationTwoEntity();
    const three = new ItemCivilizationThreeEntity();

    return [one, two, three];
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;

    swiperRef.current = swiper;
    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
    });
  }

  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_pink">Civilizações</h1>
      </div>

      <div className="body">
        <ListItems
          checkpoints={checkpoints}
          items={getCivilizations()}
          activeIndex={slideActiveIndex}
        />

        <SlideDotsProgress len={3} position={slideActiveIndex} />

        <div className="slides_body no-swipe">
          <Swiper
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            slidesPerView={1}
            initialSlide={0}
          >
            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CivilizationNerd] ? (
                  <>
                    <p>
                      <span className="nerdfy_yellow">Nerdfy</span> é uma reino{" "}
                      <span className="nerdfy_green">próspero</span> e{" "}
                      <span className="nerdfy_pink">avançado</span>, onde o{" "}
                      <span className="nerdfy_red">amor</span> pela{" "}
                      <span className="nerdfy_white">ciência</span>,{" "}
                      <span className="nerdfy_white">tecnologia</span> e{" "}
                      <span className="nerdfy_white">inovação</span> é a{" "}
                      <span className="nerdfy_purple">força motriz</span> da
                      sociedade.
                    </p>

                    <p>
                      Os <span className="nerdfy_yellow">nerds</span> são seres
                      bastante <span className="nerdfy_blue">racionais</span> e{" "}
                      <span className="nerdfy_blue">lógicos</span>,{" "}
                      <span className="nerdfy_white">engenheiros natos</span>,
                      muito bons em atividades como{" "}
                      <span className="nerdfy_green">programação</span>,{" "}
                      <span className="nerdfy_orange">matemática</span> e{" "}
                      <span className="nerdfy_blue">física</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CivilizationRavians] ? (
                  <>
                    <p>
                      <span className="nerdfy_orange">Raviania</span> é uma
                      civilização <span className="nerdfy_red">vibrante</span> e
                      cheia de vida, conhecida por sua{" "}
                      <span className="nerdfy_green">alegria contagiante</span>{" "}
                      e excentricidade{" "}
                      <span className="nerdfy_blue">colorida</span>.
                    </p>

                    <p>
                      Os <span className="nerdfy_orange">ravianos</span>{" "}
                      celebram a vida com festivais diários que honram a{" "}
                      <span className="nerdfy_white">música</span>, a{" "}
                      <span className="nerdfy_yellow">dança</span> e a{" "}
                      <span className="nerdfy_pink">arte</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.CivilizationErgonians] ? (
                  <>
                    <p>
                      <span className="nerdfy_green">Ergonia</span> é uma
                      civilização <span className="nerdfy_brown">peculiar</span>
                      , habitada por um povo conhecido por sua natureza{" "}
                      <span className="nerdfy_blue">ranzinza</span> e atitude{" "}
                      <span className="nerdfy_orange">negativa</span>...
                    </p>

                    <p>
                      Os <span className="nerdfy_green">ergonianos</span> são
                      conhecidos pelo seu{" "}
                      <span className="nerdfy_yellow">
                        descontentamento constante
                      </span>{" "}
                      e uma visão <span className="nerdfy_red">pessimista</span>{" "}
                      da vida...
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfileCivilizationsSlide;
