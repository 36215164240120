interface SvgProps {
  width?: string;
  height?: string;
}

const JarvisSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 504.5 504.5"
      width={width}
      height={height}
      viewBox="0 0 504.5 504.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m500.75 248.5c0 81.617-39.347 154.044-100.107 199.347-41.41 30.876-92.767 33.653-148.393 33.653-137.243 0-238.745-95.757-238.745-233 0-83.632 31.559-157.615 94.891-202.653 40.598-28.871 90.244-45.847 143.854-45.847 137.243 0 248.5 111.257 248.5 248.5z"
          fill="#ffd764"
        />
        <path
          d="m190.981 301.512s-31.009-17.28-46.838-47.714c-12.092-23.249-11.828-57.248-2.894-78.818 3.698-8.928 5.281-16.502 11.081-23.65 26.558-32.724 72.487-45.678 113.364-28.746 49.806 20.63 73.542 77.934 52.912 127.74-6.008 14.505-15.127 26.8-26.266 36.431-27.105 23.438-101.359 14.757-101.359 14.757z"
          fill="#b36d3c"
        />
        <path
          d="m303.26 275.66c-26.69 31.86-72.01 44.31-112.39 27.58-49.81-20.63-73.54-77.94-52.91-127.74 6.2-14.97 15.72-27.59 27.35-37.34-6.23 7.43-11.45 15.92-15.35 25.34-20.63 49.8 3.1 107.11 52.91 127.74 34.83 14.43 73.34 7.15 100.39-15.58z"
          fill="#995e34"
        />
        <path
          d="m294.99 212.91c0 24.327-12.816 45.658-32.064 57.613-10.368 6.439-34.14 5.829-34.14 5.829s-64.851-26.011-64.851-63.441c0-3.96-4.145-7.84-3.485-11.62l66.773-12.003 66.767 12.003c.66 3.779 1 7.659 1 11.619z"
          fill="#fcf7ed"
        />
        <path
          d="m260.983 228.346c-6.931 14.297-15.578 18.029-30.457 18.182-1.104.011-2.064-.75-2.37-1.81-1.496-5.198-6.569-9.654-11.813-12.197-5.356-2.593-11.26-1.212-16.331.963-1.052.451-2.26.12-2.972-.776-8.732-10.985-6.897-22.111-1.572-35.832l14.689-12.03 54.798 12.03c3.147 8.125 3.681 17.252 1.117 25.985-.623 2.123-4.093 3.428-5.089 5.485z"
          fill="#8c818f"
        />
        <path
          d="m266.791 220.02c-.667 3.082-1.698 6.151-3.145 9.119-6.931 14.302-21.17 22.679-36.037 22.83-1.107.013-2.075-.742-2.377-1.811-1.497-5.195-5.082-9.761-10.327-12.302-5.358-2.591-11.308-2.541-16.377-.365-1.044.453-2.252.126-2.969-.767-8.729-10.994-11.396-26.126-6.075-39.849l7.181-4.179 9.083 4.179c4.629 17.144 20.302 29.761 38.918 29.761 8.175.001 15.773-2.427 22.125-6.616z"
          fill="#766d78"
        />
        <path
          d="m293.99 201.29h-133.54c1.659-9.618 10.736-13.931 15.995-21.705 6.73-9.947 10.643-22.621 21.565-27.845 8.844-4.231 18.75-6.6 29.21-6.6 33.47 0 61.27 24.26 66.77 56.15z"
          fill="#ffaa4d"
        />
        <path
          d="m267.86 267.15c-11.32 8.5-25.4 13.53-40.64 13.53-37.43 0-67.77-30.34-67.77-67.77 0-3.96.34-7.84 1-11.62l9.102-3.322 8.918 3.322c-.01.54-.02 1.08-.02 1.62 0 37.43 30.34 67.77 67.77 67.77 7.57 0 14.84-1.24 21.64-3.53z"
          fill="#f7e5c1"
        />
        <path
          d="m205.59 148.67c-13.54 10.15-23.14 25.25-26.14 42.62-.57 3.26-.9 6.6-.98 10h-18.02c4.26-24.68 21.87-44.79 45.14-52.62z"
          fill="#e69642"
        />
        <path
          d="m497.22 212.91c0 24.327-12.816 45.658-32.064 57.613-10.368 6.439-34.14 5.829-34.14 5.829s-64.851-26.011-64.851-63.441c0-3.96-4.145-7.84-3.485-11.62l66.773-12.003 66.767 12.003c.66 3.779 1 7.659 1 11.619z"
          fill="#fcf7ed"
        />
        <path
          d="m384.863 81.904c27.435-15.323 58.511-25.199 90.851-27.789l.314-.025c12.413-.994 23.281 8.262 24.275 20.675s-22.148 18.571-34.56 19.565c-.84.067-1.803.081-2.626.057-25.699-.942-32.045 3.073-56.682 13.314-8.937 3.715-25.493 5.167-29.208-3.77-3.454-8.306-.038-17.752 7.636-22.027z"
          fill="#b36d3c"
        />
        <path
          d="m400.138 113.383.14-.058c24.634-10.239 51.028-15.172 76.725-14.226.821.019 1.789.011 2.627-.06 12.408-.989 21.667-11.855 20.673-24.272-.278-3.518-1.356-6.779-3.025-9.625-1.433 10.336-10.432 18.689-21.982 19.561-.896.071-1.922.078-2.802.058-27.476-.94-55.684 3.997-82.025 14.246l-.148.063c-4.784 1.86-9.919 1.726-14.344.01.176 1.633.589 3.256 1.251 4.85 3.711 8.935 13.965 13.169 22.91 9.453z"
          fill="#995e34"
        />
        <path
          d="m420.5 431.38c-44.27 40.74-103.35 65.62-168.25 65.62-137.24 0-248.5-111.26-248.5-248.5 0-90.32 48.18-169.38 120.25-212.88-49.34 45.42-80.25 110.54-80.25 182.88 0 137.24 111.26 248.5 248.5 248.5 46.92 0 90.81-13.01 128.25-35.62z"
          fill="#ffaa4d"
        />
        <path
          d="m456.293 226.309c-5.51 11.366-12.384 14.333-24.214 14.455-.878.009-1.641-.596-1.884-1.439-1.189-4.132-5.223-7.675-9.392-9.696-4.258-2.062-8.952-.963-12.983.766-.836.359-1.797.095-2.363-.617-6.942-8.733-5.483-17.579-1.25-28.487l11.678-9.564 43.565 9.564c2.502 6.46 2.926 13.715.888 20.658-.495 1.687-3.253 2.725-4.045 4.36z"
          fill="#8c818f"
        />
        <path
          d="m460.91 219.69c-.53 2.45-1.35 4.89-2.5 7.25-5.51 11.37-16.83 18.03-28.65 18.15-.88.01-1.65-.59-1.89-1.44-1.19-4.13-4.04-7.76-8.21-9.78-4.26-2.06-8.99-2.02-13.02-.29-.83.36-1.79.1-2.36-.61-6.94-8.74-9.06-20.77-4.83-31.68l5.709-3.322 7.221 3.322c3.68 13.63 16.14 23.66 30.94 23.66 6.5 0 12.54-1.93 17.59-5.26z"
          fill="#766d78"
        />
        <path
          d="m257.908 29.331c-31.419.566-63.243 7.672-92.492 21.709l-.284.136c-11.226 5.388-15.959 18.856-10.572 30.083 5.388 11.226 28.485 4.902 39.711-.486.76-.365 1.599-.837 2.298-1.272 21.733-13.748 29.238-13.472 55.681-17.021 9.593-1.288 24.63-8.365 23.342-17.958-1.197-8.916-8.902-15.359-17.684-15.191z"
          fill="#b36d3c"
        />
        <path
          d="m260.55 64.22-.15.02c-26.44 3.55-51.73 12.57-73.46 26.32-.7.43-1.54.91-2.3 1.27-11.22 5.39-24.69.66-30.08-10.57-1.53-3.18-2.24-6.54-2.23-9.84 6.44 8.21 18.42 10.9 28.84 5.84.81-.39 1.7-.9 2.45-1.36 23.27-14.64 50.13-24.57 78.05-28.97l.16-.02c5.07-.8 9.44-3.5 12.4-7.21.67 1.5 1.13 3.11 1.36 4.82 1.29 9.59-5.44 18.41-15.04 19.7z"
          fill="#995e34"
        />
        <g>
          <g>
            <path
              d="m125.75 349.5c-12.682 0-23-10.317-23-23s10.318-23 23-23 23 10.317 23 23-10.318 23-23 23zm0-31c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"
              fill="#b36d3c"
            />
          </g>
          <g>
            <path
              d="m125.75 380.5c-12.682 0-23-10.317-23-23s10.318-23 23-23 23 10.317 23 23-10.318 23-23 23zm0-31c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"
              fill="#b36d3c"
            />
          </g>
          <g>
            <path
              d="m125.75 411.5c-12.682 0-23-10.317-23-23s10.318-23 23-23 23 10.317 23 23-10.318 23-23 23zm0-31c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"
              fill="#b36d3c"
            />
          </g>
          <g>
            <path
              d="m125.75 442.5c-12.682 0-23-10.317-23-23s10.318-23 23-23 23 10.317 23 23-10.318 23-23 23zm0-31c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"
              fill="#b36d3c"
            />
          </g>
          <g>
            <path
              d="m125.75 473.5c-12.682 0-23-10.317-23-23s10.318-23 23-23 23 10.317 23 23-10.318 23-23 23zm0-31c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"
              fill="#b36d3c"
            />
          </g>
          <g>
            <path
              d="m125.75 504.5c-12.682 0-23-10.317-23-23s10.318-23 23-23 23 10.317 23 23-10.318 23-23 23zm0-31c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"
              fill="#b36d3c"
            />
          </g>
        </g>
        <g>
          <path
            d="m307.254 405.952c-2.159 0-4.301-.927-5.784-2.721-2.64-3.192-2.192-7.92 1-10.56 19.515-16.136 46.402-21.218 71.927-13.588 3.968 1.186 6.224 5.364 5.038 9.333-1.187 3.968-5.364 6.228-9.334 5.038-20.713-6.188-42.424-2.161-58.073 10.777-1.397 1.158-3.09 1.721-4.774 1.721z"
            fill="#ffaa4d"
          />
        </g>
        <path
          d="m432.66 351.96c-.76-8.25-8.05-14.32-16.3-13.57-6.64 1.21-9.75-1.14-11.72-4.17-9.89-15.22-21.89-27.72-39.89-27.72-7.47 0-16.901 6.734-20.668 10.137-.758.685-1.905.685-2.663 0-3.768-3.402-13.199-10.137-20.668-10.137-18 0-30 12.5-39.89 27.72-1.97 3.03-5.08 5.38-11.72 4.17-8.25-.75-15.54 5.32-16.3 13.57-.64 6.97 5.079 9.47 11.389 11.71 7.3 2.74 16.768 1.278 26.298-1.532 6.55-1.79 13.033-2.536 19.713-7.447 5.18-3.31 8.33-4.17 18.33-4.17 4.637 0 9.349-2.369 13.338-4.213.543-.251 1.158-.251 1.701 0 3.988 1.844 7.152 2.128 11.789 2.128 10 0 13.15.86 18.33 4.17 6.68 4.91 13.654 9.529 20.204 11.319 9.53 2.81 17.466 4.527 24.766 1.787 6.308-2.239 14.601-6.782 13.961-13.752z"
          fill="#b36d3c"
        />
        <path
          d="m422.74 367.46c-7.3 2.74-17.36 3.98-26.89 1.17-6.55-1.79-14.09-6.55-20.77-11.46-5.18-3.31-8.33-4.17-18.33-4.17-4.64 0-9.16-.98-13.15-2.82-.54-.25-1.16-.25-1.7 0-3.99 1.84-8.51 2.82-13.15 2.82-10 0-13.15.86-18.33 4.17-6.68 4.91-14.22 9.67-20.77 11.46-9.53 2.81-19.59 1.57-26.89-1.17-6.31-2.24-10.56-8.53-9.92-15.5.04-.41.09-.81.16-1.2 1.11.7 2.32 1.27 3.62 1.7 7.86 2.74 18.69 3.98 28.95 1.17 7.06-1.79 15.17-6.55 22.37-11.46 5.58-3.31 8.97-4.17 19.73-4.17 5 0 9.87-.98 14.16-2.82.59-.25 1.25-.25 1.84 0 4.29 1.84 9.16 2.82 14.16 2.82 10.76 0 14.15.86 19.73 4.17 7.2 4.91 15.31 9.67 22.37 11.46 10.26 2.81 21.09 1.57 28.95-1.17 1.3-.43 2.51-1 3.62-1.7.07.39.12.79.16 1.2.64 6.97-3.61 13.26-9.92 15.5z"
          fill="#995e34"
        />
        <path
          d="m496.22 201.29h-133.54c1.659-9.618 10.736-13.931 15.995-21.705 6.73-9.947 10.643-22.621 21.565-27.845 8.844-4.231 18.75-6.6 29.21-6.6 33.47 0 61.27 24.26 66.77 56.15z"
          fill="#ffaa4d"
        />
        <path
          d="m470.09 267.15c-11.32 8.5-25.4 13.53-40.64 13.53-37.43 0-67.77-30.34-67.77-67.77 0-3.96.34-7.84 1-11.62l9.102-3.322 8.918 3.322c-.01.54-.02 1.08-.02 1.62 0 37.43 30.34 67.77 67.77 67.77 7.57 0 14.84-1.24 21.64-3.53z"
          fill="#f7e5c1"
        />
        <path
          d="m407.82 148.67c-13.54 10.15-23.14 25.25-26.14 42.62-.57 3.26-.9 6.6-.98 10h-18.02c4.26-24.68 21.87-44.79 45.14-52.62z"
          fill="#e69642"
        />
        <path
          d="m103.463 284.7c0 14.912 7.376 22.715 22.287 22.715 11.905 0 22.01-4.119 25.601-14.814.907-2.701 1.399-5.594 1.399-8.601 0-14.912-12.088-27-27-27-3.007 0-5.899.492-8.6 1.399-10.695 3.591-13.687 14.396-13.687 26.301z"
          fill="#b36d3c"
        />
        <path
          d="m151.35 292.6c-3.59 10.7-13.7 18.4-25.6 18.4-14.91 0-27-12.09-27-27 0-11.9 7.7-22.01 18.4-25.6-.91 2.7-1.4 5.59-1.4 8.6 0 14.91 12.09 27 27 27 3.01 0 5.9-.49 8.6-1.4z"
          fill="#995e34"
        />
      </g>
    </svg>
  );
};

export default JarvisSvg;
