// redux
import { createAction } from "@reduxjs/toolkit";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

const submitChallengeThumbs = createAction(
  "@async/feedback/thumbs",
  (payload: {
    up: boolean;
    down: boolean;
    challenge: ChallengeCommonProps;
  }) => ({
    payload,
  })
);

const submitClassRoomRating = createAction(
  "@async/feedback/classRoom/rating",
  (payload: {
    rating: number;
    message: string;
    challenge: ChallengeCommonProps;
  }) => ({
    payload,
  })
);

export const feedbackActions = {
  async: {
    submitChallengeThumbs,
    submitClassRoomRating,
  },
};
