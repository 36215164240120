// interfaces
import { TagProps, SpecialTagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";

// utils
import tagStepUtils from "utils/code/tagStepUtils";
import regexUtils, { SPECIAL_REGEX_G } from "utils/regexUtils";
import isEmpty from "lodash/isEmpty";

let lastCodeIndex = 0;

const simple = {
  get(value: string, finished: string, specialTags: SpecialTagProps[]): string {
    lastCodeIndex = getLastIndex(value, finished);

    return replaceSpecialTags(
      finished.substring(0, lastCodeIndex),
      specialTags
    );
  },
  getFinished(currentCode: string, code: ChallengeCodeProps): string {
    return formatFinished(currentCode, code);
  },
};

const special = {
  regex: {
    get(
      value: string,
      finished: string,
      specialTags: SpecialTagProps[]
    ): string {
      const sub = finished.substring(lastCodeIndex);
      const before = finished.substring(0, lastCodeIndex);
      const after = sub.substring(0, sub.indexOf(value) + value.length);
      const code = before + after;
      lastCodeIndex = getLastIndex(value, code);

      return replaceSpecialTags(code, specialTags);
    },
    getFinished(currentCode: string, code: ChallengeCodeProps): string {
      return formatFinished(currentCode, code);
    },
  },
};

function clear() {
  lastCodeIndex = 0;
}

function get(
  tag: TagProps,
  specialTags: SpecialTagProps[],
  { verified, finished, steps }: ChallengeCodeProps
): string {
  const finishedCode = finished[0].data;
  const step = steps[0].data[verified.length - 1];

  if (!step.special) return simple.get(tag.value, finishedCode, specialTags);
  const value = step.specialRawValue || "";

  if (step.type?.regexp)
    return special.regex.get(value, finishedCode, specialTags);

  return "";
}

function replaceSpecialTags(
  code: string,
  specialTags: SpecialTagProps[]
): string {
  if (isEmpty(specialTags)) return code;

  let counter = -1;

  return code.replace(SPECIAL_REGEX_G, () => {
    return specialTags[++counter].tag.value;
  });
}

function getLastIndex(value: string, code: string): number {
  return code.indexOf(value, lastCodeIndex) + value.length;
}

function formatFinished(currentCode: string, code: ChallengeCodeProps) {
  const { steps } = code;
  const additional = regexUtils.additional.removeLiteral(
    tagStepUtils.getAdditional(steps)
  );

  return currentCode + breakLine() + additional;
}

function breakLine(): string {
  return "\n";
}

const codeUtils = {
  simple,
  clear,
  get,
};

export default codeUtils;
