// components
import CodeName from "components/CodeName";

// widgets
import PixelWidget from "components/widgets/PixelWidget";
import LevelStatusWidget from "components/widgets/LevelStatusWidget";
import PlayerScoreWidget from "components/widgets/PlayerScoreWidget";
import SpaceshipStatusWidget from "components/widgets/SpaceshipStatusWidget";

// components
import CloseButton from "components/CloseButton";

interface PlayerProfileStatusSlideProps {
  closeModal: () => void;
}

const PlayerProfileStatusSlide = ({
  closeModal,
}: PlayerProfileStatusSlideProps) => {
  return (
    <div className="slide">
      <div className="header_nerdbook">
        <div className="bg_nerdbook bg_brands" />
        <CloseButton onClick={closeModal} />
      </div>

      <div className="body player_profile">
        <div className="body_header">
          <CodeName />

          <h2 className="nerdfy_grey_stronger margin_top">Capítulo 1</h2>
          <h2 className="nerdfy_grey_stronger margin_top">
            A ajuda vem de dimensões superiores
          </h2>
        </div>

        <ul>
          <li>
            <PlayerScoreWidget />
          </li>

          <li>
            <PixelWidget />
          </li>

          <li>
            <LevelStatusWidget />
          </li>

          <li>
            <SpaceshipStatusWidget />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PlayerProfileStatusSlide;
