import React from "react";
import styled from "styled-components";
// import Rocket from 'components/rocket/Rocket'
// import { useSelector } from 'react-redux'
// import { selector as s } from 'redux/selectors'

const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 50px; */
`;

interface PropsI {
  classRoomProgress: number;
  loading: boolean;
}

const Footer = ({ classRoomProgress, loading }: PropsI) => {
  // const player = useSelector(s.player())

  return (
    <FooterStyled>
      {/* {!loading && player && (
        <Rocket
          classRoomProgress={classRoomProgress}
        />
      )} */}
    </FooterStyled>
  );
};

export default React.memo(Footer);
