import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { appActions } from "redux/slices/appSlice";

export const initialState: ChallengeSectionProps = {
  id: 0,
  classRoomId: 0,
  languageId: 0,
  name: "",
  index: 0,
  progress: 0,
  done: false,
};

export const challengeSection = createSlice({
  name: "challengeSection",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ChallengeSectionProps>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const challengeSectionActions = challengeSection.actions;
export default challengeSection.reducer;
