// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import {
  SpaceshipResourcesFirebaseProps,
  SpaceshipResourcesShiftFirebaseProps,
  SpaceshipResourcesTransformFirebaseProps,
} from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// utils
import isEmpty from "lodash/isEmpty";
import { AllToOptional } from "utils/transformTypeUtils";

class PlayerSpaceshipResourcesDataFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async set(
    userId: number | string,
    values:
      | AllToOptional<SpaceshipResourcesFirebaseProps>
      | AllToOptional<SpaceshipResourcesShiftFirebaseProps>
      | AllToOptional<SpaceshipResourcesTransformFirebaseProps>
  ): Promise<void> {
    if (!userId) return Promise.reject();

    const path = `players/${userId}`;

    if (isEmpty(values)) return Promise.reject();
    return this._firebase.set(path, { resources: { ...values } });
  }

  async remove(userId: number | string, deleteFields: string[]): Promise<void> {
    if (!userId) return Promise.reject();

    const path = `players/${userId}`;
    return this._firebase.deleteField(path, deleteFields);
  }
}

export default PlayerSpaceshipResourcesDataFirebaseService;
