// interfaces
import { ChallengeQuizzesUpdateProps } from "interfaces/challengeQuiz";
import { ChallengeQuizFirebaseProps } from "interfaces/challengeQuizFirebase";

function map(quiz: ChallengeQuizFirebaseProps): ChallengeQuizzesUpdateProps {
  const { id, type } = quiz;

  return {
    id,
    uuid: `${type}_${id}`,
    answer: quiz.answer || {},
    challengeSectionId: quiz.challengeSectionId,

    // flow
    flowDone: !!quiz.flowDone,
    flowFailed: !!quiz.flowFailed,
    flowSuccess: !!quiz.flowSuccess,

    // gained
    scoreGained: quiz.scoreGained || 0,
    levelPointGained: quiz.levelPointGained || 0,

    // feedback
    up: !!quiz.up,
    down: !!quiz.down,
    feedback: quiz.feedback || "",
  };
}

const challengeQuizFirebaseParser = {
  map,
};

export default challengeQuizFirebaseParser;
