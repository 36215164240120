// icons
import StarIcon from "icons/StarIcon";

// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreStarFit = (_data: AttentionWhoreElProps) => {
  return <StarIcon width="35" height="35" />;
};

export default IconWhoreStarFit;
