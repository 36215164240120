import { createSlice, createAction } from "@reduxjs/toolkit";
import FirebaseAuthService from "services/auth/FirebaseAuthService";
import authUtils from "utils/authUtils";
import { AppProps } from "interfaces/app";
import APP_NERDFY from "constants/app";

const initialState: AppProps = {
  version: APP_NERDFY.version,
};

const logout = createAction("auth/logout");

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => {
      const firebaseAuthService = new FirebaseAuthService();

      firebaseAuthService.logout();
      authUtils.clear();
    });
  },
});

export const appActions = {
  ...appSlice.actions,
  logout,
};

export default appSlice.reducer;
