// redux
import { appActions } from "redux/slices/appSlice";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { InvaderBulletProps } from "interfaces/spaceInvaders/invaderBullet";

const fire = createAction(
  "@async/invaderBullet/fire",
  (payload: InvaderBulletProps) => ({
    payload,
  })
);

const initialState: InvaderBulletProps[] = [];

export const invaderBulletsSlice = createSlice({
  name: "invaderBullets",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<InvaderBulletProps>) => {
      return [...state, action.payload];
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const invaderBulletsActions = {
  async: {
    fire,
  },
  ...invaderBulletsSlice.actions,
};

export default invaderBulletsSlice.reducer;
