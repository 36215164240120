// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const RadioInputInputSvg = ({
  width = "18",
  height = "18",
  color = c.grey.strong,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 32 32"
    >
      <g>
        <path
          fill={color}
          d="M16 3a13 13 0 1 0 13 13A13.025 13.025 0 0 0 16 3zm0 24a11 11 0 1 1 11-11 11.012 11.012 0 0 1-11 11zm8-11a8 8 0 1 1-8-8 8.013 8.013 0 0 1 8 8z"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default RadioInputInputSvg;
