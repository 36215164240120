interface SvgProps {
  width?: string;
  height?: string;
}

const AstrogildoSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      enableBackground="new 0 0 497 497"
      viewBox="0 0 497 497"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m449.755 286.991-34.775-41.049s13.742-44.008 38.009-44.008 44.01 19.743 44.01 44.01-47.244 41.047-47.244 41.047zm-4.766-65.702c-9.927 0-10.004 14.727-10.004 24.655s3.141 22.664 13.068 22.664c1.749 0 8.376-4.911 9.976-5.378 7.482-2.185 12.964-9.108 12.964-17.286 0-8.309-5.658-15.321-13.323-17.387-1.492-.403-11.062-7.268-12.681-7.268z"
            fill="#ffaa4d"
          />
        </g>
        <path
          d="m497 245.942c0-2.03-.14-4.04-.41-6-2.52 18.437-16.502 33.265-34.512 37.059-2.67.562-5.123-1.605-4.84-4.319.328-3.143.595-6.294.792-9.45-2.017.592-4.176.835-6.419.669-8.434-.627-15.43-7.259-16.477-15.652-1.367-10.956 7.167-20.307 17.856-20.307 1.62 0 3.19.21 4.68.62-.75-8.97-2-17.85-3.76-26.62 0 0 0 0-.001-.001-22.655-.464-41.552 16.283-44.514 38.034-.001-.011-.004-.022-.005-.033-.239 1.992-.412 3.984-.41 6 0 24.27 19.74 44.01 44.01 44.01.62 0 1.23-.01 1.84-.04 0-.002.001-.004.001-.007 23.419-.969 42.169-20.311 42.169-43.963z"
          fill="#e69642"
        />
        <path
          d="m443.487 248.502c0 73.192-35.461 138.102-90.14 178.486-36.81 27.187-82.33 32.429-131.603 32.429-122.466 0-210.967-88.451-210.967-210.916 0-75.305 26.762-141.841 84.147-181.92 35.938-25.1 79.66-39.822 126.82-39.822 45.757 0 88.276 13.859 123.59 37.608 1.737 1.168-2.302 10.516-.6 11.731 2.42 1.729 10.562-4.65 12.908-2.828 20.733 16.102 38.532 35.803 52.459 58.165 2.289 3.675-5.145 18.552-3.07 22.366 3.98 7.315 17.179 3.748 20.33 11.531 10.4 25.682 16.126 53.757 16.126 83.17z"
          fill="#ffd764"
        />
        <circle cx="414.979" cy="290.956" fill="#b36d3c" r="7.5" />
        <path
          d="m373.85 409.852c-39.7 37.44-93.22 60.39-152.11 60.39-122.46 0-221.74-99.27-221.74-221.74 0-5.075.17-10.11.506-15.1.192-2.859 9.077-5.412 9.377-8.24.241-2.273-8.123-4.828-7.814-7.08 9.42-68.659 50.27-127.292 107.571-160.93-42.88 40.43-69.64 97.77-69.64 161.35 0 122.47 99.28 221.74 221.74 221.74 40.91 0 79.22-11.07 112.11-30.39z"
          fill="#ffaa4d"
        />
        <g>
          <path
            d="m227.03 202.882c-1.17 1.16-2.7 1.9-4.33 2.11l-222.19 28.41c.223-3.388 9.888-4.721 10.267-8.063.198-1.748-8.944-5.523-8.707-7.257l216.2-27.64z"
            fill="#8c818f"
          />
        </g>
        <g>
          <path
            d="m357.64 73.272-130.61 129.61c-1.17 1.16-2.7 1.9-4.33 2.11l-4.43-14.55 127.06-126.07c4.21 2.82 8.31 5.79 12.31 8.9z"
            fill="#766d78"
          />
        </g>
        <path
          d="m40 218.502c0-1.765.028-3.523.069-5.278l-37.999 4.858c-.033.239-.06.48-.092.719-.34 2.789-1.083 9.93-1.377 13.398-.029.401-.065.801-.091 1.203l39.718-5.078c-.142-3.258-.228-6.53-.228-9.822z"
          fill="#766d78"
        />
        <path
          d="m431.26 190.032c-.952 20.426-12.048 38.297-28.281 48.741-9.174 5.902-19.263 6.893-30.813 7.171-33.042.799-56.749-19.307-59.15-51.548-.112-1.505.609-2.954 1.895-3.745l9.5-11.168 80.181-49.353c1.881-1.158 4.337-.567 5.506 1.307 6.685 10.722 12.469 22.055 17.263 33.895v.01c2.889 7.62 4.299 15.97 3.899 24.69z"
          fill="#fcf7ed"
        />
        <path
          d="m405.12 237.332c-9.61 6.77-21.21 10.85-33.68 11.15-33.04.8-60.33-24.51-62.73-56.75-.12-1.51.6-2.96 1.89-3.75l13.81-8.5c6.4 31.34 35.61 60.22 67.96 59.44 4.36-.11 8.63-.65 12.75-1.59z"
          fill="#f7e5c1"
        />
        <path
          d="m372.479 205.547c0 15.924 8.98 24.252 24.904 24.252 12.829 0 23.7-4.776 27.442-16.359.903-2.795 1.39-5.776 1.39-8.871 0-15.924-12.909-28.833-28.833-28.833-5.168 0-10.018 1.36-14.213 3.741-8.73 4.956-10.69 15.314-10.69 26.07z"
          fill="#8c818f"
        />
        <path
          d="m424.815 213.437c-3.73 11.59-14.6 19.96-27.43 19.96-15.93 0-28.84-12.9-28.84-28.83 0-12.24 7.63-22.7 18.4-26.87-.91 2.79-1.4 5.78-1.4 8.87 0 15.93 12.91 28.83 28.84 28.83 3.68 0 7.2-.69 10.43-1.96z"
          fill="#766d78"
        />
        <path
          d="m205.931 248.502c-32.144-.1-55.367-27.29-56.772-60.191-.727-17.032 4.97-37.068 22.546-39.849 24.345-2.876 48.689-2.876 73.034 0 17.576 2.782 33.824 22.202 33.097 39.485-.893 21.23-16.243 43.017-35.657 55.825-10.666 7.037-24.851 4.697-36.248 4.73z"
          fill="#8c818f"
        />
        <path
          d="m242.18 243.772c-10.67 7.04-22.56 11.36-33.96 11.4-32.14-.1-68.21-34.33-69.61-67.23-.73-17.03 15.52-36.7 33.1-39.48 2.88-.34 5.75-.64 8.63-.89-8.45 7.91-14.17 19.12-13.73 29.37 1.4 32.9 37.47 67.13 69.61 67.23 1.98-.01 3.97-.14 5.96-.4z"
          fill="#766d78"
        />
        <path
          d="m366.585 289.736c0 16.386-6.659 31.373-17.68 42.9-13.458 14.075-33.668 14.695-55.953 14.695h-9.167c-40.525 0-68.242-19.983-68.242-56.377 0-6.795 1.394-7.924 8.17-4.899 1.73.767 5.175-2.95 6.998-2.244 1.343.52 2.711 1.022 4.102 1.503 15.509 5.406 33.966 8.544 53.803 8.544 18.779 0 36.329-2.807 51.308-7.693 4.187-1.361 8.175-2.892 11.927-4.565 6.785-3.025 14.734 1.341 14.734 8.136z"
          fill="#b36d3c"
        />
        <path
          d="m298.85 355.432c-1.87.13-4.748-.249-6.648-.249l-12.83-1.468c-12.96 0-20.304-8.875-30.872-14.17-6.949-3.481-13.376-.181-18.7-5.422-11.895-11.71-19.15-27.287-19.15-44.39 0-6.79 7.95-11.16 14.73-8.13 1.73.77 3.51 1.5 5.33 2.21 1.47 38.179 31 68.989 68.14 71.619z"
          fill="#995e34"
        />
        <path
          d="m348.9 332.632c-13.46 14.08-33.42 22.99-55.7 22.99l-38.45-6.654s-11.53-1.636-24.95-14.846l14.16-13.33c1.77-1.88 4.37-2.72 6.91-2.24 7.55 1.45 24.27 4.33 37.75 4.33 13.74 0 32.8-3.33 41.03-4.9 2.54-.48 5.14.37 6.92 2.25z"
          fill="#e27c48"
        />
        <path
          d="m298.81 355.432c-24.652 2.02-51.14-3.084-69.01-21.31l12.93-12.17c12.22 18.9 32.63 31.8 56.08 33.48z"
          fill="#d1653f"
        />
        <g fill="#b36d3c">
          <circle cx="394.979" cy="305.96" r="7.5" />
          <circle cx="402.979" cy="327.962" r="7.5" />
          <circle cx="380.97" cy="336.966" r="7.5" />
          <circle cx="379.97" cy="361.972" r="7.5" />
          <circle cx="348.9" cy="366.972" r="7.5" />
          <circle cx="360.97" cy="385.982" r="7.5" />
          <circle cx="339.96" cy="399.98" r="7.5" />
          <circle cx="320.96" cy="382.975" r="7.5" />
          <circle cx="313.96" cy="411.986" r="7.5" />
          <circle cx="292.95" cy="389.98" r="7.5" />
          <circle cx="257.939" cy="381.98" r="7.5" />
          <circle cx="272.95" cy="404.982" r="7.5" />
          <circle cx="285.95" cy="428.986" r="7.5" />
          <circle cx="259.945" cy="432.99" r="7.5" />
          <circle cx="235.939" cy="418.982" r="7.5" />
          <path d="m227.132 439.055c0 4.142-3.06 6.128-7.202 6.128s-6.813-2.05-6.813-6.192c0-1.471-.264-2.844.468-4.002 1.329-2.102 3.674-3.498 6.344-3.498 3.624 0 6.648 2.571 7.348 5.988.1.488-.145 1.058-.145 1.576z" />
          <circle cx="205.93" cy="415.982" r="7.5" />
          <circle cx="230.939" cy="392.981" r="7.5" />
          <circle cx="211.93" cy="372.976" r="7.5" />
          <circle cx="188.93" cy="390.98" r="7.5" />
          <circle cx="179.93" cy="413.986" r="7.5" />
        </g>
        <circle cx="185.93" cy="441.992" fill="#995e34" r="7.5" />
        <circle cx="157.92" cy="426.986" fill="#995e34" r="7.5" />
        <circle cx="130.91" cy="413.982" fill="#995e34" r="7.5" />
        <circle cx="155.92" cy="396.98" fill="#b36d3c" r="7.5" />
        <circle cx="97.905" cy="392.98" fill="#995e34" r="7.5" />
        <path
          d="m132.41 387.98c0 3.227-2.039 5.979-4.898 7.036-.811.3-1.65-.387-2.565-.387-4.142 0-6.553-1.858-6.553-6 0-.363-.958-1.369-.908-1.718.519-3.636 3.645-6.431 7.424-6.431 4.142 0 7.5 3.358 7.5 7.5z"
          fill="#b36d3c"
        />
        <circle cx="143.91" cy="372.975" fill="#b36d3c" r="7.5" />
        <circle cx="170.92" cy="369.973" fill="#b36d3c" r="7.5" />
        <circle cx="77.899" cy="372.972" fill="#995e34" r="7.5" />
        <circle cx="103.91" cy="363.973" fill="#b36d3c" r="7.5" />
        <circle cx="128.91" cy="352.97" fill="#b36d3c" r="7.5" />
        <circle cx="154.92" cy="348.97" fill="#b36d3c" r="7.5" />
        <circle cx="191.93" cy="352.97" fill="#b36d3c" r="7.5" />
        <circle cx="170.92" cy="330.962" fill="#b36d3c" r="7.5" />
        <circle cx="161.92" cy="309.96" fill="#b36d3c" r="7.5" />
        <circle cx="134.915" cy="325.962" fill="#b36d3c" r="7.5" />
        <circle cx="104.91" cy="338.971" fill="#b36d3c" r="7.5" />
        <circle cx="66.899" cy="346.97" fill="#995e34" r="7.5" />
        <circle cx="74.899" cy="321.966" fill="#b36d3c" r="7.5" />
        <circle cx="104.91" cy="312.961" fill="#b36d3c" r="7.5" />
        <circle cx="128.91" cy="298.955" fill="#b36d3c" r="7.5" />
        <circle cx="46.89" cy="325.962" fill="#995e34" r="7.5" />
        <path
          d="m65.399 301.96c0 3.624-2.571 6.648-5.988 7.348-.488.1-1.648-.849-2.166-.849-4.142 0-5.617-6.571-5.617-6.571s.127-4.874 2.178-6.213c1.177-.768 2.583-1.215 4.094-1.215 4.142 0 7.499 3.358 7.499 7.5z"
          fill="#b36d3c"
        />
        <path
          d="m227.43 438.992c0 4.14-3.36 7.5-7.5 7.5s-7.5-3.36-7.5-7.5c0-1.47.42-2.84 1.16-4 4.52 1.01 9.08 1.87 13.7 2.59h.01c.08.46.13.93.13 1.41z"
          fill="#995e34"
        />
        <path
          d="m127.51 395.012c-.81.31-1.69.47-2.6.47-4.14 0-7.5-3.36-7.5-7.5 0-.36.03-.72.08-1.07 3.26 2.8 6.6 5.5 10.02 8.1z"
          fill="#995e34"
        />
        <path
          d="m59.39 309.312c-.48.1-.98.15-1.49.15-4.14 0-7.5-3.36-7.5-7.5 0-2.63 1.36-4.94 3.41-6.28 1.7 4.62 3.57 9.16 5.58 13.63z"
          fill="#995e34"
        />
        <circle cx="33.89" cy="289.956" fill="#995e34" r="7.5" />
        <circle cx="81.899" cy="292.955" fill="#b36d3c" r="7.5" />
      </g>
    </svg>
  );
};

export default AstrogildoSvg;
