interface SvgProps {
  width?: string;
  height?: string;
}

const EnonMurfSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 497 497"
      viewBox="0 0 497 497"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m484.918 248.5c0 80.499-39.23 151.836-99.615 195.934-40.063 29.257-89.437 34.981-142.844 34.981-133.906-.001-224.363-230.915-224.363-230.915s19.502-148.833 77.739-193.117c40.718-30.963 91.524-49.343 146.624-49.343 133.907 0 242.459 108.553 242.459 242.46z"
          fill="#ffd764"
        />
        <path
          d="m467.734 81.834c-6.806 4.747-15.515-1.859-20.256-8.654-8.451-12.115-21.567-17.788-36.02-14.739l-41.631 8.782c-.704.143-1.532.274-2.264.34-8.014.728-17.725 1.226-21.334-5.424-1.229-2.266-2.028-4.813-2.276-7.543-.976-10.744 6.942-20.245 17.685-21.222 0 0 36.671-3.558 61.942-5.888 14.39-1.327 28.375 5.216 36.584 17.108l11.289 16.354c4.741 6.796 3.076 16.146-3.719 20.886z"
          fill="#b36d3c"
        />
        <path
          d="m467.61 81.91c-6.79 4.65-16.05 2.96-20.76-3.8-6.97-9.99-18.31-15.74-30.18-15.74-2.52 0-5.07.26-7.6.79l-41.63 8.78c-.7.14-1.53.28-2.26.34-8.02.73-15.34-3.49-18.95-10.14l59.95-12.68c3.45-.73 6.98-1.09 10.49-1.09 16.59 0 32.17 8.12 41.66 21.73.25.36 7.99 10.13 9.28 11.81z"
          fill="#995e34"
        />
        <path
          d="m301.646 64.178c-6.473-5.192-15.902-4.148-21.087 2.313-9.244 11.521-24.48 16.399-38.696 12.389l-40.949-11.551c-.692-.19-1.51-.376-2.236-.491-10.654-1.694-20.665 5.57-22.359 16.224-.311 1.953-.32 3.885-.063 5.747 1.146 8.298 15.543 13.137 24.244 14.521 0 0 30.346 1.089 55.404 5.106 14.268 2.288 20.27 5.686 29.258-5.63l12.359-15.561c4.875-6.077 10.687-16.768 5.221-22.097-.346-.338-.712-.662-1.096-.97z"
          fill="#b36d3c"
        />
        <path
          d="m303.96 85.26-12.36 15.57c-8.99 11.31-23.38 16.9-37.65 14.62-25.06-4.02-61.41-10.03-61.41-10.03-8.7-1.38-15.14-8.31-16.29-16.61 22.5 3.6 62.57 10.03 79.92 12.81 1.36.22 2.74.33 4.12.33 7.97 0 15.38-3.58 20.35-9.83l22.1-26.97c5.47 5.32 6.09 14.03 1.22 20.11z"
          fill="#995e34"
        />
        <path
          d="m407.06 426.54c-43.23 39.98-101.06 64.42-164.6 64.42-133.91 0-242.46-108.55-242.46-242.46 0-88.35 47.25-165.66 117.86-208.04-47.88 44.3-77.86 107.67-77.86 178.04 0 133.91 108.55 242.46 242.46 242.46 45.56 0 88.19-12.57 124.6-34.42z"
          fill="#ffaa4d"
        />
        <path
          d="m324.791 198.653 11.177 32.373c4.207 19.103 17.716 30.856 37.277 30.856l51.83 1.787c14.075 0 28.231-2.793 35.81-13.764 3.402-4.924 5.782-10.642 6.812-16.875l7.44-40.999c1.427-7.865 8.277-13.584 16.27-13.584 3.089 0 5.593-2.504 5.593-5.593v-23.328c0-3.089-2.504-5.593-5.593-5.593h-331.987l-9.349 3.079c-3.089 0-5.593 2.504-5.593 5.593l.766 18.217c0 3.089 2.504 5.593 5.593 5.593 7.994 0 13.916 4.325 15.343 12.19l9.893 42.42c3.325 20.119 17.971 31.112 38.363 31.112l43.915.511c14.027 0 33.198-1.738 40.797-12.74 3.005-4.351 5.217-9.328 6.405-14.746l8.006-36.505c1.322-6.02 9.906-6.024 11.232-.004z"
          fill="#8c818f"
        />
        <path
          d="m299.15 249.91c-7.6 11-20.28 18-34.3 18h-53.02c-20.39 0-37.79-14.76-41.11-34.88l-7.44-41c-1.371-7.841-8.427-13.636-16.27-13.58-3.09 0-5.59-2.51-5.59-5.59v-23.33c-.043-3.031 2.569-5.645 5.6-5.6h12.4v16.83c0 1.17.68 2.24 1.75 2.72 10.11 4.54 17.73 13.83 19.82 25.34l7.49 41.28c1.9 11.48 11.72 19.81 23.35 19.81z"
          fill="#766d78"
        />
        <path
          d="m400.15 346.08c-9.956 26.126-36.17 44.665-67.097 47.106-5.106.403-10.071-1.088-15.383-1.601l-10.978-2.043c-37.47-3.62-64.558-28.844-70.128-60.934 3.21 1.68 5.176 4.831 8.646 6.351 20.11 8.93 45.88 7.417 72.46 9.987 25.16 2.43 47.61 9.023 68.28 4.713 4.92-1.019 9.67-2.219 14.2-3.579z"
          fill="#b36d3c"
        />
        <path
          d="m338.05 392.65c-6.67.91-13.59 1.07-20.65.39l-9.97-.96c-37.47-3.62-66.67-29.85-72.24-61.94 3.21 1.68 7.776-2.727 11.246-1.207 6.04 2.68 11.234 11.167 17.994 13.357 11.13 26.47 36.29 46.4 67 49.66z"
          fill="#995e34"
        />
        <g>
          <g>
            <path
              d="m366.395 235.584c-1.726 0-3.46-.592-4.874-1.803-3.146-2.694-3.513-7.429-.819-10.575l37.815-44.168c2.694-3.146 7.429-3.513 10.575-.819s3.513 7.429.819 10.575l-37.815 44.168c-1.484 1.732-3.586 2.622-5.701 2.622z"
              fill="#fcf7ed"
            />
          </g>
          <g>
            <path
              d="m403.782 235.584c-1.726 0-3.46-.592-4.874-1.803-3.146-2.694-3.513-7.429-.819-10.575l37.815-44.168c2.694-3.146 7.429-3.513 10.575-.819s3.513 7.429.819 10.575l-37.815 44.168c-1.483 1.732-3.586 2.622-5.701 2.622z"
              fill="#fcf7ed"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m204.395 235.584c-1.726 0-3.46-.592-4.875-1.803-3.146-2.694-3.513-7.429-.819-10.575l37.815-44.168c2.694-3.146 7.428-3.513 10.575-.819 3.146 2.694 3.513 7.429.819 10.575l-37.815 44.168c-1.483 1.732-3.585 2.622-5.7 2.622z"
              fill="#fcf7ed"
            />
          </g>
          <g>
            <path
              d="m241.783 235.584c-1.726 0-3.46-.592-4.875-1.803-3.146-2.694-3.513-7.429-.819-10.575l37.815-44.168c2.694-3.146 7.428-3.513 10.575-.819 3.146 2.694 3.513 7.429.819 10.575l-37.815 44.168c-1.483 1.732-3.585 2.622-5.7 2.622z"
              fill="#fcf7ed"
            />
          </g>
        </g>
        <path
          d="m404.14 329.08c-.57 5.94-1.93 11.63-3.99 17-4.53 1.36-9.28 2.56-14.2 3.58-20.67 4.31-44.54 5.63-69.7 3.2-26.58-2.57-50.93-8.97-71.04-17.9-3.47-1.52-6.81-3.14-10.02-4.82-.99-5.66 1.314-11.263 1.885-17.193.72-7.39 7.248-8.435 14.298-4.426 2.209 1.242 4.535-.672 6.904.486.987.483 1.989.957 3.004 1.422 16.3 7.51 36.05 12.86 57.63 14.94 20.42 1.98 39.8.77 56.61-2.97 4.7-1.04 9.2-2.29 13.45-3.71 7.699-2.579 15.889 3.001 15.169 10.391z"
          fill="#fcf7ed"
        />
        <path
          d="m264.43 342.29c-6.76-2.19-13.18-4.65-19.22-7.33-3.47-1.52-6.81-3.14-10.02-4.82-.99-5.66-1.23-11.51-.66-17.44.72-7.39 9.83-11.31 16.88-7.3 2.21 1.24 4.5 2.45 6.87 3.6-.18 5.09.13 10.1.91 14.98 1.01 6.35 2.78 12.49 5.24 18.31z"
          fill="#f7e5c1"
        />
        <path
          d="m373.54 249.91c-1.977 0-4.075-.112-6.253-.333-7.988-.808-14.755-6.527-16.447-14.377l-5.789-26.165c-1.925-8.701-9.639-14.895-18.551-14.895h-7.29c2.47.01 4.92 1.51 5.58 4.51l4.76 21.63.001-.005c.616 2.589 3.406 16.05 4.183 18.298-.002-.001-.003-.002-.005-.003 5.071 17.114 21.961 29.566 39.81 29.34h53.04c14.07 0 26.72-7.03 34.31-18z"
          fill="#766d78"
        />
      </g>
    </svg>
  );
};

export default EnonMurfSvg;
