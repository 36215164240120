// react
import React from "react";

// ionic react
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";

// components
import BrandLogo from "components/BrandLogo";
import SettingsIcon from "icons/SettingsIcon";
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled(IonHeader)`
  --border-width: 0 0 0 !important;
  --min-height: 80px;
  --background: ${color.dark.bg};
  --padding-end: 6px;

  .menu_button {
    margin: 0 10px 10px 0;
  }
`;

const Header = () => {
  return (
    <Wrapper mode="md" className="safe--area">
      <IonToolbar>
        <IonTitle>
          <BrandLogo />
        </IonTitle>

        <IonButtons slot="end">
          <IonMenuButton color="secondary" className="menu_button">
            <ButtonDuolingo>
              <SettingsIcon color={color.blue} />
            </ButtonDuolingo>
          </IonMenuButton>
        </IonButtons>
      </IonToolbar>
    </Wrapper>
  );
};

export default React.memo(Header);
