// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { PlayerCheckpointsProps } from "interfaces/playerCheckpoint";

class PlayerCheckpointFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async set(
    userId: number | string,
    checkpoint: PlayerCheckpointsProps
  ): Promise<void> {
    if (!userId) return Promise.reject();

    const path = `players/${userId}`;
    return this._firebase.set(path, { checkpoint });
  }
}

export default PlayerCheckpointFirebaseService;
