// utils
import c from "styles/color";
import styled from "styled-components";

interface ButtonStyledProps {
  bg: string;
  color: string;
  boxShadowColor: string;
}

const ButtonStyled = styled.button<ButtonStyledProps>`
  all: unset;
  display: inline-block;
  padding: 8px 12px;
  letter-spacing: 1.5px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 20px !important;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bg};
  box-shadow: 0 4px 0 ${(props) => props.boxShadowColor};

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:disabled {
    opacity: 0.3;
  }
`;

interface ButtonDuolingoProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  bg?: string;
  color?: string;
  boxShadowColor?: string;
}

const ButtonDuolingo = ({
  children,
  disabled,
  className,
  onClick,
  color = c.blue,
  type = "button",
  bg = c.grey.dark,
  boxShadowColor = c.grey.stronger,
}: ButtonDuolingoProps) => {
  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setTimeout(() => onClick && onClick(e), 50);
  }

  return (
    <ButtonStyled
      bg={bg}
      type={type}
      color={color}
      disabled={disabled}
      onClick={handleClick}
      boxShadowColor={boxShadowColor}
      className={`${className} pixelify_font_family`}
    >
      {children}
    </ButtonStyled>
  );
};

export default ButtonDuolingo;
