// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";
import { spaceshipMachineGunActions } from "redux/slices/spaceInvaders/spaceshipMachineGunSlice";

// components
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";
import ButtonPlayPauseToggle from "components/buttons/ButtonPlayPauseToggle";
import ButtonSelectMachineGunSide from "components/buttons/ButtonSelectMachineGunSide";

// enums
import { SpaceshipMachineGunEnum } from "enums/spaceInvaders/spaceshipMachineGunEnum";

// utils
import color from "styles/color";
import styled from "styled-components";
import numberUtils from "utils/numberUtils";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100px;

  > ul {
    all: unset;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: ${color.dark.bg};
    color: ${color.grey.strong};
  }
`;

interface ChallengeOptionsFooterProps {
  wrapper: HTMLDivElement | null;
  disabled?: boolean;
}

const ChallengeOptionsFooter = ({
  wrapper,
  disabled,
}: ChallengeOptionsFooterProps) => {
  const dispatch = useDispatch();
  const { paused } = useSelector(s.challengeFlow());

  function play() {
    dispatch(challengeFlowActions.unpause());
  }

  function pause() {
    dispatch(challengeFlowActions.pause());
  }

  function setBulletSource(source: SpaceshipMachineGunEnum) {
    warn(source);
    dispatch(spaceshipMachineGunActions.async.updateMachineGun(source));
  }

  function warn(source: SpaceshipMachineGunEnum) {
    if (!wrapper) return;

    const { clientWidth, clientHeight } = wrapper;

    alertAnimate({
      wrapper: wrapper as HTMLDivElement,
      factories: [AlertFactory(getWarnAlertFactory(source))],
      bounds: {
        top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
      },
    });
  }

  function getWarnAlertFactory(source: SpaceshipMachineGunEnum) {
    if (source === SpaceshipMachineGunEnum.Left)
      return {
        content: "Armamento ESQUERDO ativado",
        type: AlertFactoryType.Info,
      };
    if (source === SpaceshipMachineGunEnum.Right)
      return {
        content: "Armamento DIREITO ativado",
        type: AlertFactoryType.Info,
      };
    if (source === SpaceshipMachineGunEnum.Both)
      return {
        content: "Armamentos ESQUERDO e DIREITO ativados",
        type: AlertFactoryType.Info,
      };
    if (source === SpaceshipMachineGunEnum.Middle)
      return {
        content: "Armamento CENTRAL ativado",
        type: AlertFactoryType.Info,
      };

    return { content: "Armamentos desativados", type: AlertFactoryType.Danger };
  }

  return (
    <Wrapper>
      <ul>
        <li>
          <ButtonSelectMachineGunSide
            disabled={disabled}
            onClick={setBulletSource}
          />
        </li>

        <li>
          <ButtonPlayPauseToggle
            play={play}
            pause={pause}
            paused={paused}
            disabled={disabled}
          />
        </li>
      </ul>
    </Wrapper>
  );
};

export default ChallengeOptionsFooter;
