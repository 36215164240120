// react
import { useState, useCallback, useRef } from "react";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import Board from "components/Board";
import FormHeader from "components/form/FormHeader";
import { CivilizationNerdFooter } from "components/presentations/CivilizationFooter";

// entities
import AstrogildoEntity from "entities/characters/AstrogildoEntity";

// svg
import AstrogildoSvg from "svg/characters/AstrogildoSvg";

// utils
import color from "styles/color";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  > .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 50px;
    padding-bottom: 50px;

    .body_content {
      display: flex;
      align-items: center;
    }
  }
`;

interface AstrogildoPresentationProps {
  show: boolean;
  finishPresentation: () => void;
  wrapper: HTMLDivElement | undefined;
}

const AstrogildoPresentation = ({
  show,
  wrapper,
  finishPresentation,
}: AstrogildoPresentationProps) => {
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const [disabled, setDisabled] = useState(false);
  const [animation, setAnimation] = useState("animate__bounceInUp");
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    wrapperRef.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function proceed() {
    setDisabled(true);
    setTimeout(alert);
    setTimeout(close, 2000);
  }

  function alert() {
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `O personagem "${AstrogildoEntity.defaultName}" foi adicionado na sua enciclopédia`
      ),
      AlertEncyclopediaFactory(
        `A civilização "Nerdfy" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
    setTimeout(finishPresentation);
  }

  return (
    <>
      {show && (
        <Content
          ref={handle}
          onAnimationEnd={onAnimationEnd}
          className={`animate__animated no-swipe ${animation}`}
        >
          <FormHeader hideBrand proceed={proceed} disabled={disabled} />

          <div className="body">
            <AstrogildoSvg width="30" height="30" />

            <div className="body_content">
              <Board>
                <p>
                  <span className="nerdfy_yellow">
                    {AstrogildoEntity.defaultName}, o Explorador
                  </span>
                  , seu <span className="nerdfy_white">nome</span> e{" "}
                  <span className="nerdfy_white">imagem</span> está estampado
                  por toda <span className="nerdfy_purple">Nerdfy</span>.
                </p>

                <p>
                  <span className="nerdfy_orange">Infelizmente</span>, ele sumiu
                  durante a sua <span className="nerdfy_green">heroica</span>{" "}
                  viagem de <span className="nerdfy_brown">exploração</span>...
                  tudo o que restou foi uma{" "}
                  <span className="nerdfy_blue">mensagem</span> e{" "}
                  <span className="nerdfy_blue">localização</span>...
                </p>

                <p>
                  Sua <span className="nerdfy_red">missão</span>, até então, era{" "}
                  <span className="nerdfy_white">explorar</span> e{" "}
                  <span className="nerdfy_white">encontrar</span> um{" "}
                  <span className="nerdfy_pink">novo lar</span>...
                </p>
              </Board>
            </div>
          </div>

          <CivilizationNerdFooter />
        </Content>
      )}
    </>
  );
};

export default AstrogildoPresentation;
