// react
import { useState } from "react";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import SpaceshipItemsWrapper from "components/presentations/spaceship/SpaceshipItemsWrapper";

// enums
import { BubbleType } from "enums/bubbleEnum";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";
import AstrogildoPresentationHandler from "handlers/presentation/AstrogildoPresentationHandler";

// icons
import IconWhoreMessage from "components/iconWhores/IconWhoreMessage";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface SpaceshipItemsHandlerProps {
  goBack(): void;
  goForward(): void;
  getSpaceshipClassNameColor(): string;
  wrapper: HTMLDivElement | undefined;
}

const SpaceshipItemsHandler = ({
  goBack,
  wrapper,
  goForward,
  getSpaceshipClassNameColor,
}: SpaceshipItemsHandlerProps) => {
  const [disabled, setDisabled] = useState<boolean | null>(null);

  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const [attentionWhores, setAttentionWhores] = useState<AttentionWhoreProps[]>(
    []
  );

  // ATTENTION WHORES

  // function first(): AttentionWhoreProps {
  //   const payload =
  //     "O armazém de recursos contém 5 espaços para CADA RECURSO essencial, isso é, 5 para MINERAIS, 5 para ÁGUA e 5 para FORÇA FORTE...";
  //   return message(payload, wrapper);
  // }

  // function second(): AttentionWhoreProps {
  //   const payload =
  //     "Embora o limite da espaçonave para recursos como água e força forte seja 5 (para cada), o limite para MINERAIS pode ser MAIOR que 5...";
  //   return message(payload, wrapper);
  // }

  // function third(): AttentionWhoreProps {
  //   const payload =
  //     "Existem vários tipos de minerais, como bauxita, cobre, prata, ouro, etc. Para eles serem úteis, precisam ser refinados...";
  //   return message(payload, wrapper);
  // }

  // function four(): AttentionWhoreProps {
  //   const payload =
  //     "Cada refinador tem capacidade de 5, isso é, 5 minérios BRUTOS podem ser refinados ao mesmo tempo...";
  //   return message(payload, wrapper);
  // }

  // function five(): AttentionWhoreProps {
  //   const payload =
  //     "Assim como no caso de ARMAZENAMENTO de minérios, é possível, também, AUMENTAR a capacidade do REFINADOR...";
  //   return message(payload, wrapper);
  // }

  // function six(): AttentionWhoreProps {
  //   const payload =
  //     "Além da capacidade, o refinador tem um número LIMITADO de CICLOS DE VIDA, ou seja, um número MÁXIMO de vezes que pode ser utilizado...";
  //   return message(payload, wrapper);
  // }

  function first(): AttentionWhoreProps {
    const payload =
      "Dica 1: Fique atento com a quantidade de refinadores X armazéns de minérios... exemplo... não adianta ter mais refinadores se não tiver onde armazenar TODOS os minérios...";
    return message(payload, wrapper);
  }

  function second(): AttentionWhoreProps {
    const payload =
      "Dica 2: Os minérios BRUTOS se extinguem com cerca de 48 horas, portanto, é interessante que você tenha refinadores suficientes para transformar os minérios brutos em minérios REFINADOS...";
    return message(payload, wrapper);
  }

  function third(): AttentionWhoreProps {
    const payload =
      "Dica 3 (mais importante): Por fim, fique atento com o famoso LIMBO DA MORTE... O limbo da morte ocorre quando você perde seus refinadores e não tem recursos para produzir ou comprar outro...";
    return message(payload, wrapper);
  }

  function four(): AttentionWhoreProps {
    const payload =
      "Provavelmente, o famoso explorador Astrogildo, caiu nesse limbo e infelizmente, estava muito distante... nunca saberemos... (será?)... Que O Todo-Poderoso o guarde...";
    return message(payload, wrapper, true);
  }

  function message(
    payload: string,
    wrapper: HTMLDivElement | undefined,
    last?: boolean
  ): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreMessage,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      bubbleType: BubbleType.Message,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  // ATTENTION WHORES END

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  function proceed() {
    if (disabled) return;
    if (disabled === false) {
      goForward();
      return;
    }

    alert();
    setDisabled(true);
    setAttentionWhores([first(), second(), third(), four()]);
  }

  function finishPresentation() {
    setDisabled(false);
  }

  function alert() {
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `A empresa "nerdX" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  return (
    <>
      <AttentionWhores
        whores={attentionWhores}
        disabledDrop={!!attentionWhore.show}
      />

      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <AstrogildoPresentationHandler
        wrapper={wrapper}
        start={!!attentionWhore.last}
        finishPresentation={finishPresentation}
      />

      <SpaceshipItemsWrapper
        back={goBack}
        proceed={proceed}
        disabled={!!disabled}
        getSpaceshipClassNameColor={getSpaceshipClassNameColor}
      />

      <DropZoneAttentionWhore />
    </>
  );
};

export default SpaceshipItemsHandler;
