interface SvgProps {
  width?: string;
  height?: string;
}

const RefineMachinePixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M481.88 0v90.35h-30.12v60.24h30.12v210.82h-30.12v60.24h30.12V512H30.12v-90.35h30.12v-60.24H30.12V150.59h30.12V90.35H30.12V0z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          fill="#3d99e1"
          d="M451.76 150.59v210.82h-30.11v60.24L256 466.82 90.35 421.65v-60.24H60.24V150.59h30.11V90.35L256 45.18l165.65 45.17v60.24z"
          opacity="1"
          data-original="#3d99e1"
        ></path>
        <path
          fill="#145382"
          d="M391.53 240.94v30.12h-30.12v60.23h-30.12v30.12h-60.23v30.12h-30.12v-30.12h-60.23v-30.12h-30.12v-60.23h-30.12v-30.12h30.12v-60.23h30.12v-30.12h60.23v-30.12h30.12v30.12h60.23v30.12h30.12v60.23z"
          opacity="1"
          data-original="#145382"
        ></path>
        <path
          fill="#a1aab7"
          d="M451.76 481.88v-30.12L256 436.7 60.24 451.76v30.12z"
          opacity="1"
          data-original="#a1aab7"
        ></path>
        <path
          fill="#f7f9fa"
          d="M451.76 30.12v30.12L256 75.3 60.24 60.24V30.12z"
          opacity="1"
          data-original="#f7f9fa"
        ></path>
        <g fill="#d1d9e1">
          <path
            d="m105.11 436.7-14.76 15.06H60.24v-30.11h30.11zM451.76 451.76h-30.11l-18.969-12.915 18.969-17.195h30.11M421.65 60.24l-15.06 15.055 15.06 15.055h30.11V60.24zM105.41 75.295 90.35 60.24H60.24v30.11h30.11z"
            fill="#d1d9e1"
            opacity="1"
            data-original="#d1d9e1"
          ></path>
        </g>
        <g fill="#145382">
          <path
            d="M90.35 60.24h331.3v30.11H90.35zM90.35 421.65h331.3v30.11H90.35z"
            fill="#145382"
            opacity="1"
            data-original="#145382"
          ></path>
        </g>
        <path
          fill="#b2d7f3"
          d="M361.41 240.94v30.12h-30.12l-15.06 15.06 15.06 15.06v30.11h-30.11l-15.06-15.06-15.06 15.06v30.12h-30.12v-30.12l-15.06-15.06-15.06 15.06h-30.11v-30.11l15.06-15.06-15.06-15.06h-30.12v-30.12h30.12l15.06-15.06-15.06-15.06v-30.11h30.11l15.06 15.05 15.06-15.05v-30.12h30.12v30.12l15.06 15.05 15.06-15.05h30.11v30.11l-15.06 15.06 15.06 15.06z"
          opacity="1"
          data-original="#b2d7f3"
        ></path>
        <g fill="#3d99e1">
          <path
            d="M301.18 271.06h30.11v30.12h-30.11zM271.06 301.18h30.12v30.11h-30.12zM210.82 301.18h30.12v30.11h-30.12zM180.71 271.06h30.11v30.12h-30.11zM301.18 210.82h30.11v30.12h-30.11zM271.06 180.71h30.12v30.11h-30.12zM240.94 240.94h30.12v30.12h-30.12zM210.82 180.71h30.12v30.11h-30.12zM180.71 210.82h30.11v30.12h-30.11z"
            fill="#3d99e1"
            opacity="1"
            data-original="#3d99e1"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default RefineMachinePixelSvg;
