// react
import React, { useState, useEffect } from "react";

// components
import Icon, { faStar, faStarHalfAlt } from "components/Icon";

// utils
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import color from "styles/color";

const Wrapper = styled.div`
  display: inline-block;

  .checked {
    color: ${color.yellow};
  }

  ${({ color }) =>
    color &&
    `
    &.rating-disabled, &.rating {
      direction: rtl;
      unicode-bidi: bidi-override;
      color: ${color};
    }
  `};
`;

interface Props {
  stars: number;
  id?: string;
  onRating?(stars: number): any;
  disabled?: boolean;
  size?: any;
  color?: string;
}

const StarRating = ({
  stars,
  disabled = false,
  size = "3x",
  color = "#ddd",
  onRating,
}: Props) => {
  const [ratings, setRatings] = useState<any>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init.bind(null, { stars, disabled }), [stars]);

  function init(options: any) {
    const ratings = getRatings(options.stars, options.disabled);

    setRatings(ratings);
  }

  function rate(stars: number) {
    const ratings = getRatings(stars, disabled);

    setRatings(ratings);
    if (onRating) onRating(stars);
  }

  function getRatings(stars: number, disabled: boolean) {
    let cb: any = () => {};
    const ceil = Math.ceil(stars);
    const rounded = -Math.round(-stars);
    const ratings: any = [];
    const half = true;
    const full = true;
    const checked = true;

    if (!disabled) cb = rate;

    for (let i = 5; i > 0; i--) {
      const rate = i;

      if (ceil >= i) {
        if (ceil === i && i > rounded && !isInt(stars))
          ratings.push({ rate, half, checked, cb });
        else ratings.push({ rate, full, checked, cb });

        continue;
      }

      ratings.push({ rate, cb });
    }

    return ratings;
  }

  function isInt(n: number) {
    return n % 1 === 0;
  }

  const className = disabled ? "rating-disabled" : "rating";
  return (
    <Wrapper color={color} className={className}>
      {ratings.map((rating: any) => {
        const cb = rating.cb.bind(null, rating.rate);

        return (
          <label key={rating.rate}>
            {rating.full && rating.checked && (
              <Icon
                icon={faStar}
                size={size}
                className="checked"
                onClick={cb}
              />
            )}

            {rating.half && rating.checked && (
              <Icon
                icon={faStarHalfAlt}
                size={size}
                className="checked"
                onClick={cb}
              />
            )}

            {!rating.checked && <Icon icon={faStar} size={size} onClick={cb} />}
          </label>
        );
      })}
    </Wrapper>
  );
};

function areEqual(prev: any, next: any) {
  if (isEmpty(prev) || isEmpty(next)) return true;
  return prev.stars === next.stars;
}

export default React.memo(StarRating, areEqual);
