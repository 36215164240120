// utils
import color from "styles/color";

const BLINK_DELAY = 150;

function blinkOrange(blinkEl: HTMLElement, delay = BLINK_DELAY) {
  if (!blinkEl) return;

  blinkEl.style.background = color.orange;
  _blink(blinkEl, delay);
}

function blinkRed(blinkEl: HTMLElement, delay = BLINK_DELAY) {
  if (!blinkEl) return;

  blinkEl.style.background = color.red;
  _blink(blinkEl, delay);
}

function blinkPurple(blinkEl: HTMLElement, delay = BLINK_DELAY) {
  if (!blinkEl) return;

  blinkEl.style.background = color.purple;
  _blink(blinkEl, delay);
}

// private

function _blink(blinkEl: HTMLElement, delay: number) {
  blinkEl.style.display = "block";

  setTimeout(() => {
    if (!blinkEl) return;
    blinkEl.style.display = "none";
  }, delay);
}

const blinkUtils = {
  blinkOrange,
  blinkPurple,
  blinkRed,
};

export default blinkUtils;
