// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";
import Icon, { faPlus } from "components/Icon";

// utils
import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${color.green};
`;

interface ButtonDuolingoIconProps {
  onClick(): void;
  customClassName?: string;
  disabled?: boolean;
}

const ButtonDuolingoPlusIcon = ({
  onClick,
  disabled,
  customClassName,
}: ButtonDuolingoIconProps) => {
  return (
    <Wrapper onClick={onClick} className={customClassName} disabled={disabled}>
      <Icon icon={faPlus} />
    </Wrapper>
  );
};

export default ButtonDuolingoPlusIcon;
