// interfaces
import { FlyingObjectPositionProps } from "interfaces/spaceInvaders/flyingObject";
import { InvaderProps } from "interfaces/spaceInvaders/invader";

// entities
import AbstractFlyingObjectEntity from "entities/spaceInvaders/AbstractFlyingObjectEntity";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
  FlyingObjectHpType,
  FlyingObjectDamageType,
} from "enums/spaceInvaders/flyingObjectEnum";
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

export class InvaderOneEntity
  extends AbstractFlyingObjectEntity
  implements InvaderProps
{
  static index = 1;
  static color = "#009a84";
  static defaultName = "Cosmopode";
  static checkpointIndex = PlayerCheckpointEnum.InvaderOne;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.explosionColor = "#69b2a5";
    this.hp = FlyingObjectHpType.middle;
    this.type = FlyingObjectType.Invader;
    this.damage = FlyingObjectDamageType.low;
    this.subtype = FlyingObjectSubType.InvaderOne;
  }
}

export class InvaderTwoEntity
  extends AbstractFlyingObjectEntity
  implements InvaderProps
{
  static index = 2;
  static color = "#295ed8";
  static defaultName = "Nebuloth";
  static checkpointIndex = PlayerCheckpointEnum.InvaderTwo;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.explosionColor = "#637FDA";
    this.hp = FlyingObjectHpType.middle;
    this.type = FlyingObjectType.Invader;
    this.damage = FlyingObjectDamageType.low;
    this.subtype = FlyingObjectSubType.InvaderTwo;
  }
}

export class InvaderThreeEntity
  extends AbstractFlyingObjectEntity
  implements InvaderProps
{
  static index = 3;
  static color = "#8c5abd";
  static defaultName = "Crustaceon";
  static checkpointIndex = PlayerCheckpointEnum.InvaderThree;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.explosionColor = "#8c5abd";
    this.hp = FlyingObjectHpType.low;
    this.type = FlyingObjectType.Invader;
    this.damage = FlyingObjectDamageType.low;
    this.subtype = FlyingObjectSubType.InvaderThree;
  }
}

export class InvaderFourEntity
  extends AbstractFlyingObjectEntity
  implements InvaderProps
{
  static index = 4;
  static color = "#c4a000";
  static defaultName = "Megacephalus";
  static checkpointIndex = PlayerCheckpointEnum.InvaderFour;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.explosionColor = "#d2cc69";
    this.hp = FlyingObjectHpType.low;
    this.type = FlyingObjectType.Invader;
    this.damage = FlyingObjectDamageType.low;
    this.subtype = FlyingObjectSubType.InvaderFour;
  }
}

export class InvaderFiveEntity
  extends AbstractFlyingObjectEntity
  implements InvaderProps
{
  static index = 5;
  static color = "#F13303";
  static defaultName = "Draconova";
  static checkpointIndex = PlayerCheckpointEnum.InvaderFive;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.explosionColor = "#f14c5d";
    this.hp = FlyingObjectHpType.low;
    this.type = FlyingObjectType.Invader;
    this.damage = FlyingObjectDamageType.low;
    this.subtype = FlyingObjectSubType.InvaderFive;
  }
}

export class InvaderSixEntity
  extends AbstractFlyingObjectEntity
  implements InvaderProps
{
  static index = 6;
  static color = "#ab6e2a";
  static defaultName = "Gosmafang";
  static checkpointIndex = PlayerCheckpointEnum.InvaderSix;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.explosionColor = "#956840";
    this.hp = FlyingObjectHpType.high;
    this.type = FlyingObjectType.Invader;
    this.damage = FlyingObjectDamageType.middle;
    this.subtype = FlyingObjectSubType.InvaderSix;
  }
}

export class InvaderSevenEntity
  extends AbstractFlyingObjectEntity
  implements InvaderProps
{
  static index = 7;
  static color = "#00ab00";
  static defaultName = "Galáctigryllus";
  static checkpointIndex = PlayerCheckpointEnum.InvaderSeven;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.explosionColor = "#6dc75b";
    this.hp = FlyingObjectHpType.middle;
    this.type = FlyingObjectType.Invader;
    this.damage = FlyingObjectDamageType.low;
    this.subtype = FlyingObjectSubType.InvaderSeven;
  }
}
