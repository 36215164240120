interface SvgProps {
  width?: string;
  height?: string;
}

const BookPixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M512 30.12v451.76h-30.12V512H60.24v-30.12H30.12v-30.12H0V60.24h30.12V30.12h30.12V0h421.64v30.12z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          fill="#dbe1e8"
          d="m481.88 421.65-15.06 15.06 15.06 15.05v30.12H60.24v-30.12H30.12v-30.11h30.12v-30.12h421.64z"
          opacity="1"
          data-original="#dbe1e8"
        ></path>
        <path
          fill="#c68ef4"
          d="M481.88 60.24v271.05l-15.06 15.06H75.29l-15.05 15.06-15.06 15.06-15.06-15.06V90.35l30.12-30.11 15.05-15.06h391.53z"
          opacity="1"
          data-original="#c68ef4"
        ></path>
        <g fill="#f9c1dc">
          <path
            d="M481.88 30.12v30.12h-90.35L376.47 75.3H135.53l-15.06-15.06H60.24V30.12zM30.12 60.24h30.12v30.11H30.12z"
            fill="#f9c1dc"
            opacity="1"
            data-original="#f9c1dc"
          ></path>
        </g>
        <g fill="#00347b">
          <path
            d="M90.35 240.94h30.12v30.12H90.35zM391.53 240.94h30.12v30.12h-30.12zM150.59 240.94h210.82v30.12H150.59zM421.65 90.35v90.36h-30.12v30.11H120.47v-30.11H90.35V90.35h30.12V60.24h271.06v30.11z"
            fill="#00347b"
            opacity="1"
            data-original="#00347b"
          ></path>
        </g>
        <path
          fill="#dbe1e8"
          d="M120.47 90.35h271.06v90.36H120.47z"
          opacity="1"
          data-original="#dbe1e8"
        ></path>
        <g fill="#5f6c75">
          <path
            d="M210.82 120.47h150.59v30.12H210.82zM60.24 421.65h421.64v30.11H60.24zM150.59 120.47h30.12v30.12h-30.12z"
            fill="#5f6c75"
            opacity="1"
            data-original="#5f6c75"
          ></path>
        </g>
        <g fill="#5f5ace">
          <path
            d="M60.24 331.29h421.64v30.12H60.24zM30.12 361.41h30.12v30.12H30.12z"
            fill="#5f5ace"
            opacity="1"
            data-original="#5f5ace"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default BookPixelSvg;
