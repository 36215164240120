// react
import { useEffect, useRef } from "react";

// types
import { dropZoneBorderColorType } from "components/DropZone";

// components
import { DropZoneSpaceship } from "components/DropZone";

// drag
import interact from "interactjs";

interface DropZoneSpaceshipProps {
  ondrop(): void;
  isActive: boolean;
  dropZoneBorderColor: dropZoneBorderColorType;
}

const DropZoneSpaceshipHandler = ({
  ondrop,
  isActive,
  dropZoneBorderColor,
}: DropZoneSpaceshipProps) => {
  const interactDropRef = useRef<any>(null);
  const isActiveRef = useRef(isActive);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startDrop, []);
  useEffect(listenIsActive, [isActive]);

  function listenIsActive() {
    isActiveRef.current = isActive;
  }

  function startDrop() {
    if (interactDropRef.current) return;

    interactDropRef.current = interact(".spaceship_dropzone").dropzone({
      accept: ".spaceship_drop",
      overlap: 0.5,

      ondropactivate: function (event) {
        if (!isActiveRef.current) return;
        event.target.classList.add("border_dashed_grey");
      },
      ondropdeactivate: function (event) {
        if (!isActiveRef.current) return;
        event.target.classList.remove("border_dashed_grey");
      },
      ondragenter: function (event) {
        if (!isActiveRef.current) return;
        event.target.classList.add(getDropZoneActiveBorderColor());
      },
      ondragleave: function (event) {
        if (!isActiveRef.current) return;
        event.target.classList.remove(getDropZoneActiveBorderColor());
      },
      ondrop: function (event) {
        if (!isActiveRef.current) return;
        event.target.classList.remove(getDropZoneActiveBorderColor());

        ondrop();
      },
    });
  }

  function getDropZoneActiveBorderColor(): string {
    return `border_dashed_${dropZoneBorderColor}`;
  }

  function destroyComponent() {
    interactDropRef.current?.unset();
  }

  return <DropZoneSpaceship />;
};

export default DropZoneSpaceshipHandler;
