// redux
import { combineReducers } from "redux";

// reducers
import appReducer from "redux/slices/appSlice";
import npcReducer from "redux/slices/npcSlice";
import authReducer from "redux/slices/authSlice";
import shopReducer from "redux/slices/shopSlice";
import playerReducer from "redux/slices/playerSlice";
import eventsReducer from "redux/slices/eventsSlice";
import storiesReducer from "redux/slices/storiesSlice";
import classRoomReducer from "redux/slices/classRoomSlice";
import challengeReducer from "redux/slices/challengeSlice";
import challengesReducer from "redux/slices/challengesSlice";
import jarvisChatsReducer from "redux/slices/jarvisChatsSlice";
import walkieTalkieReducer from "redux/slices/walkieTalkieSlice";
import nextChallengeReducer from "redux/slices/nextChallengeSlice";
import challengeCodeReducer from "redux/slices/challengeCodeSlice";
import challengeQuizReducer from "redux/slices/challengeQuizSlice";
import challengeFlowReducer from "redux/slices/challengeFlowSlice";
import bulletsReducer from "redux/slices/spaceInvaders/bulletsSlice";
import invadersReducer from "redux/slices/spaceInvaders/invadersSlice";
import challengeSectionReducer from "redux/slices/challengeSectionSlice";
import challengeSectionsReducer from "redux/slices/challengeSectionsSlice";
import explosionsReducer from "redux/slices/spaceInvaders/explosionsSlice";
import playerCheckpointsReducer from "redux/slices/playerCheckpointsSlice";
import programmingLanguagesReducer from "redux/slices/programmingLanguagesSlice";
import flyingObjectsReducer from "redux/slices/spaceInvaders/flyingObjectsSlice";
import invaderBulletsReducer from "redux/slices/spaceInvaders/invaderBulletsSlice";
import spaceshipItemsReducer from "redux/slices/spaceInvaders/spaceshipItemsSlice";
import classRoomRatingSummaryReducer from "redux/slices/classRoomRatingSummarySlice";
import invadersFriendlyReducer from "redux/slices/spaceInvaders/invadersFriendlySlice";
import spaceshipFeaturesReducer from "redux/slices/spaceInvaders/spaceshipFeaturesSlice";
import strongForceBarrierReducer from "redux/slices/spaceInvaders/strongForceBarrierSlice";
import spaceshipResourcesReducer from "redux/slices/spaceInvaders/spaceshipResourcesSlice";
import spaceshipMachineGunReducer from "redux/slices/spaceInvaders/spaceshipMachineGunSlice";

const appReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  npc: npcReducer,
  player: playerReducer,
  playerCheckpoints: playerCheckpointsReducer,
  classRoom: classRoomReducer,
  challenge: challengeReducer,
  nextChallenge: nextChallengeReducer,
  challengeCode: challengeCodeReducer,
  challengeSection: challengeSectionReducer,
  challengeSections: challengeSectionsReducer,
  programmingLanguages: programmingLanguagesReducer,
  jarvisChats: jarvisChatsReducer,
  bullets: bulletsReducer,
  flyingObjects: flyingObjectsReducer,
  strongForceBarrier: strongForceBarrierReducer,
  stories: storiesReducer,
  events: eventsReducer,
  challengeFlow: challengeFlowReducer,
  explosions: explosionsReducer,
  invaders: invadersReducer,
  invadersFriendly: invadersFriendlyReducer,
  invaderBullets: invaderBulletsReducer,
  classRoomRatingSummary: classRoomRatingSummaryReducer,
  spaceshipMachineGun: spaceshipMachineGunReducer,
  spaceshipFeatures: spaceshipFeaturesReducer,
  spaceshipResources: spaceshipResourcesReducer,
  challengeQuiz: challengeQuizReducer,
  challenges: challengesReducer,
  walkieTalkie: walkieTalkieReducer,
  shop: shopReducer,
  spaceshipItems: spaceshipItemsReducer,
});

export default appReducers;
