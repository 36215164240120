// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";
import React, { useEffect, useState, useRef, useCallback, memo } from "react";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// interfaces
import { BoundClientDirectionsProps } from "interfaces/boundClient";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 2px;
  width: 100%;
  position: absolute;

  top: ${SPACE_INVADERS.strongForceBarrier.top}px;
  \ > .strong_force_barrier_item {
    height: 5px;
    width: 10%;
    opacity: 0.2;
    background: ${color.pink};

    &.destroy {
      opacity: 0.1 !important;
    }
  }
`;

interface StrongForceBarrierItemProps {
  destroy: boolean;
  hitDirections: BoundClientDirectionsProps | null;
}

const StrongForceBarrierItem = ({
  destroy,
  hitDirections,
}: StrongForceBarrierItemProps) => {
  const refBarrier = useRef<HTMLDivElement | null>(null);
  const refDirections = useRef<BoundClientDirectionsProps | null>(null);
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    refBarrier.current = node;
    setTimeout(() => {
      const { top, right, bottom, left } = node.getBoundingClientRect();
      refDirections.current = { top, right, bottom, left };
    }, 1000);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleHit, [hitDirections]);

  function handleHit() {
    if (destroy) return;
    if (!refBarrier.current) return;
    if (!refDirections.current) return;
    if (!hitDirections) return;
    if (!hasOverlap(hitDirections, refDirections.current)) return;

    hit(refBarrier.current);
  }

  function hasOverlap(
    to: BoundClientDirectionsProps,
    from: BoundClientDirectionsProps
  ) {
    return !(from.right < to.left || from.left > to.right);
  }

  function hit(barrier: HTMLDivElement) {
    barrier.style.opacity = "1";
    setTimeout(() => (barrier.style.opacity = "0.2"), 150);
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();

    if (e.animationName !== "zoomOut") return;
    e.currentTarget.remove();
  }

  return (
    <div
      ref={handle}
      className={`animate__animated strong_force_barrier_item ${
        destroy ? "animate__zoomOut destroy" : "animate__tada"
      }`}
      onAnimationEnd={onAnimationEnd}
    ></div>
  );
};

interface StrongForceBarrierProps {
  destroy: boolean;
  show: boolean;
}

const StrongForceBarrier = ({ destroy, show }: StrongForceBarrierProps) => {
  const strongForceBarrier = useSelector(s.strongForceBarrier());
  const [hitDirections, setHitDirections] =
    useState<BoundClientDirectionsProps | null>(null);

  useEffect(handleHit, [strongForceBarrier]);

  function handleHit() {
    setHitDirections(strongForceBarrier.hitDirections);
  }

  return (
    <>
      {(show || destroy) && (
        <Wrapper>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
          <StrongForceBarrierItem
            hitDirections={hitDirections}
            destroy={destroy}
          ></StrongForceBarrierItem>
        </Wrapper>
      )}
    </>
  );
};

export default memo(StrongForceBarrier);
