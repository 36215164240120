// interfaces
import {
  JarvisChatProps,
  JarvisChatResourceProps,
} from "interfaces/jarvisChat";

// utils
import isEmpty from "lodash/isEmpty";

function map(data: JarvisChatResourceProps): JarvisChatProps {
  return {
    id: data.id,
    challengeId: data.challengeId,
    answer: data.answer,
    question: data.question,
    // answer
    answerRead: false,
    answerUp: false,
    answerDown: false,
    // question
    questionClicked: false,
  };
}

function list(data: JarvisChatResourceProps[]): JarvisChatProps[] {
  if (isEmpty(data)) return [];
  return data.map(map);
}

const jarvisChatParser = {
  map,
  list,
};

export default jarvisChatParser;
