// interfaces
import { ShopItemFirebaseProps } from "interfaces/shopFirebase";

function mapAsRead(data: ShopItemFirebaseProps): { id: number; read: boolean } {
  return {
    id: data.id,
    read: !!data.read,
  };
}

const shopQuestionParser = {
  mapAsRead,
};

export default shopQuestionParser;
