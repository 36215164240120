export enum EventWhoType {
  None = 0,
  Jarvis,
  God,
}

export enum EventChallengeFlowType {
  None = 0,
  FlowStarted,
  FlowFinished,
}
