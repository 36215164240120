interface SvgProps {
  width?: string;
  height?: string;
}

const RadioStationPixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M512 30.12v210.82h-30.12v30.12H512v210.82h-30.12V512H30.12v-30.12H0V271.06h30.12v-30.12H0V30.12h30.12V0h451.76v30.12z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          fill="#58b354"
          d="M240.94 120.47v60.24H90.35v-60.24l15.06-15.06h120.47z"
          opacity="1"
          data-original="#58b354"
        ></path>
        <g fill="#dbe1e8">
          <path
            d="M90.35 180.71v30.11L75.3 225.88H45.18l-15.06-15.06V60.24l15.06-15.06 15.06 15.06v120.47zM481.88 60.24v150.58l-15.06 15.06H256l-15.06-15.06v-30.11h30.12V60.24l15.06-15.06h180.7zM421.65 421.65v30.11l-15.06 15.06h-60.24l-15.06-15.06v-30.11zM301.18 391.53v60.23l-15.06 15.06H45.18l-15.06-15.06v-60.23h30.12v30.12h210.82v-30.12z"
            fill="#dbe1e8"
            opacity="1"
            data-original="#dbe1e8"
          ></path>
        </g>
        <path
          fill="#c68ef4"
          d="M481.88 301.18v120.47l-15.06 15.05-15.06-15.05-30.11-30.12H301.18v-30.12h-30.12v-30.12H60.24v30.12H30.12v-60.23l15.06-15.06h421.64z"
          opacity="1"
          data-original="#c68ef4"
        ></path>
        <path
          fill="#b3d141"
          d="M271.06 361.41v30.12h-30.12L225.88 384l-15.06 7.53h-30.11L165.65 384l-15.06 7.53h-30.12L105.41 384l-15.06 7.53H60.24v-30.12h30.11l15.06 7.53 15.06-7.53h30.12l15.06 7.53 15.06-7.53h30.11l15.06 7.53 15.06-7.53z"
          opacity="1"
          data-original="#b3d141"
        ></path>
        <g fill="#5f5ace">
          <path
            d="M421.65 391.53h30.11v30.12h-30.11zM451.76 421.65h30.12v60.23h-30.12zM481.88 271.06v30.12h-30.12l-15.06 15.06-15.05-15.06h-30.12l-15.06 15.06-15.06-15.06H30.12v-30.12z"
            fill="#5f5ace"
            opacity="1"
            data-original="#5f5ace"
          ></path>
        </g>
        <g fill="#a1aab7">
          <path
            d="M30.12 451.76h271.06v30.12H30.12zM481.88 210.82v30.12H30.12v-30.12h331.29l15.06-15.06 15.06 15.06h30.12l15.05-15.06 15.06 15.06zM331.29 451.76h90.36v30.12h-90.36z"
            fill="#a1aab7"
            opacity="1"
            data-original="#a1aab7"
          ></path>
        </g>
        <path
          fill="#f7f9fa"
          d="M481.88 30.12v30.12h-30.12L436.7 75.3H316.24l-15.06-15.06H30.12V30.12z"
          opacity="1"
          data-original="#f7f9fa"
        ></path>
        <path
          fill="#5f6c75"
          d="M451.76 60.24v30.11h-30.11v30.12h-90.36V90.35h-30.11V60.24z"
          opacity="1"
          data-original="#5f6c75"
        ></path>
        <g fill="#b3d141">
          <path
            d="M90.35 90.35h150.59v30.12H90.35zM421.65 301.18h30.11v30.11h-30.11zM361.41 301.18h30.12v30.11h-30.12z"
            fill="#b3d141"
            opacity="1"
            data-original="#b3d141"
          ></path>
        </g>
        <g fill="#007745">
          <path
            d="M210.82 361.41h30.12v30.12h-30.12zM150.59 361.41h30.12v30.12h-30.12zM90.35 361.41h30.12v30.12H90.35z"
            fill="#007745"
            opacity="1"
            data-original="#007745"
          ></path>
        </g>
        <g fill="#ff3939">
          <path
            d="M421.65 180.71h30.11v30.11h-30.11zM361.41 180.71h30.12v30.11h-30.12z"
            fill="#ff3939"
            opacity="1"
            data-original="#ff3939"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default RadioStationPixelSvg;
