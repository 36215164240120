// react
import { useState, useEffect } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// entities
import ZarvoxNameEntity from "entities/characters/ZarvoxEntity";

// icons
import IconWhoreAddPerson from "components/iconWhores/IconWhoreAddPerson";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import ZarvoxPresentation from "components/presentations/ZarvoxPresentation";

// interfaces
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// utils
import isEmpty from "lodash/isEmpty";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface JarvisPresentationHandlerProps {
  wrapper: HTMLDivElement;
  challengeDone: boolean;
  challengePaused: boolean;
  callPresentation: () => void;
  finishPresentation: () => void;
}

const ZarvoxPresentationHandler = ({
  wrapper,
  challengeDone,
  challengePaused,
  callPresentation,
  finishPresentation,
}: JarvisPresentationHandlerProps) => {
  const zarvox = useSelector(s.zarvox());
  const codename = useSelector(s.codename());
  const [showPresentation, setShowPresentation] = useState(false);
  const [attentionWhores, setAttentionWhores] = useState<AttentionWhoreProps[]>(
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePresentation, [zarvox.name]);

  function handlePresentation() {
    if (hasZarvoxName()) return;
    setTimeout(addAttentionWhore, 1000);
  }

  function hasZarvoxName() {
    return new ZarvoxNameEntity({ name: zarvox.name }).has();
  }

  // ATTENTION WHORE

  function addAttentionWhore() {
    if (!wrapper) return;

    const whore = attentionWhoreUtils.get({
      payload: "",
      WhoreEl: IconWhoreAddPerson,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "blue",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setAttentionWhores([whore]);
  }

  function onAttentionWhoreCall() {
    callPresentation();
    setTimeout(() => setShowPresentation(true));
  }

  // ATTENTION WHORE END

  return (
    <>
      {!isEmpty(attentionWhores) && (
        <AttentionWhores
          clear={challengeDone}
          whores={attentionWhores}
          paused={challengePaused}
        />
      )}

      {showPresentation && (
        <ZarvoxPresentation
          wrapper={wrapper}
          codename={codename}
          show={showPresentation}
          finishPresentation={finishPresentation}
        />
      )}
    </>
  );
};

export default ZarvoxPresentationHandler;
