// react
import { useRef } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import {
  notifyAnimate,
  NotifyFactory,
  NotifyFactoryType,
} from "factories/NotifyFactory";

import { ChallengeCommonProps } from "interfaces/challenge";

// handlers
import PlayerAlertDarkSideDegreeHandler from "handlers/playerNotifierDegree/PlayerNotifierDarkSideDegreeHandler";
import PlayerNotifierLightSideDegreeHandler from "handlers/playerNotifierDegree/PlayerNotifierLightSideDegreeHandler";

interface PlayerDegreeHandlerProps {
  challenge: ChallengeCommonProps;
  wrapper: HTMLDivElement | null;
}

const PlayerNotifierDegreeHandler = ({
  wrapper,
  challenge,
}: PlayerDegreeHandlerProps) => {
  const player = useSelector(s.player());
  const lastPlayerDegreeRef = useRef(player.degree);
  const maxCheckpointRef = useRef(player.degree.checkpoint);

  function notify(type: NotifyFactoryType, content: string) {
    if (!wrapper) return;

    notifyAnimate({
      wrapper: wrapper,
      factoriesData: [{ factories: [NotifyFactory({ type, content })] }],
    });
  }

  return (
    <>
      <PlayerNotifierLightSideDegreeHandler
        player={player}
        notify={notify}
        challengeDone={challenge.flowDone}
        maxCheckpointRef={maxCheckpointRef}
        lastPlayerDegreeRef={lastPlayerDegreeRef}
      />

      <PlayerAlertDarkSideDegreeHandler
        player={player}
        notify={notify}
        challengeDone={challenge.flowDone}
        lastPlayerDegreeRef={lastPlayerDegreeRef}
      />
    </>
  );
};

export default PlayerNotifierDegreeHandler;
