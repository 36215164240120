// redux
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// enums
import { UserGenreType } from "enums/userEnum";

// interfaces
import { AuthProps } from "interfaces/auth";
import { UserUpdateProps } from "interfaces/user";

const initialState: AuthProps = {
  user: {
    id: 0,
    name: "",
    email: "",
    image: "",
    codename: "",
    givenName: "",
    languageId: 0,
    familyName: "",
    emailActivated: false,
    genre: UserGenreType.Unknown,
  },
  signed: false,
  anonymous: false,
  firebaseSigned: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<AuthProps>) => {
      return action.payload;
    },
    updateUser(state, action: PayloadAction<UserUpdateProps>) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
