// react
import { IonModal, IonContent } from "@ionic/react";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled(IonModal)`
  --border-radius: 0;
  --background: ${color.dark.bg};
  --height: 100%;

  &::part(handle) {
    background: ${color.grey.strong};
    height: 6px;
    width: 40px;
    margin-top: 4px;
  }

  @media only screen and (min-width: 768px) and (min-height: 768px) {
    --width: 600px;
    --height: 600px;

    &::part(content) {
      bottom: auto;
    }
  }

  @media only screen and (min-width: 768px) and (min-height: 600px) {
    --width: 600px;
    --height: 600px;
    --ion-safe-area-top: 0px;
    --ion-safe-area-bottom: 0px;
    --ion-safe-area-right: 0px;
    --ion-safe-area-left: 0px;

    &::part(content) {
      bottom: auto;
    }
  }

  .vertical_separator {
    height: 22px;
    background: ${color.grey.dark};
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  onDidDismiss?(event: CustomEvent<any>): void;
}

const Modal = ({ children, isOpen, onDidDismiss }: ModalProps) => {
  return (
    <Wrapper
      mode="ios"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      backdropDismiss={false}
      showBackdrop
      initialBreakpoint={1}
      breakpoints={[0, 1]}
    >
      <div className="vertical_separator"></div>

      <IonContent>
        <div className="wrapper pixelify_font_family">{children}</div>
      </IonContent>
    </Wrapper>
  );
};

export default Modal;
