// interfaces
import { DragWhoreProps, CurrentDragWhoreProps } from "interfaces/dragWhore";

// enums
import { BubbleType } from "enums/bubbleEnum";

// utils
import { v4 as uuidv4 } from "uuid";
import dragUtils from "utils/dragUtils";

interface GetProps {
  payload: unknown;
  wrapperWidth: number;
  wrapperHeight: number;
  call(whore: DragWhoreProps): void;
  last?: boolean;
  bubbleType?: BubbleType;
}

function get({
  call,
  payload,
  last = false,
  wrapperWidth,
  wrapperHeight,
  bubbleType = BubbleType.None,
}: GetProps): DragWhoreProps {
  const wrapper = { width: wrapperWidth, height: wrapperHeight };
  const { x, y } = dragUtils.getRandomPosition({ wrapper });

  return {
    call,
    last,
    payload,
    bubbleType,
    x: `${x}px`,
    y: `${y}px`,
    id: uuidv4(),
  };
}

function fake(): CurrentDragWhoreProps {
  return {
    id: "",
    x: "0",
    y: "0",
    show: false,
    last: false,
    payload: {},
    call: () => {},
    bubbleType: BubbleType.None,
  };
}

const dragWhoreUtils = {
  get,
  fake,
};

export default dragWhoreUtils;
