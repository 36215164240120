// react
import React, { useState, useEffect, useRef } from "react";

// entities
import ChallengeQuizLevelPointsEntity from "entities/ChallengeQuizLevelPointsEntity";

// components
import VictoryNews, {
  PxPlus,
  ScorePlus,
  ScoreMinus,
  LevelPointPlus,
  LevelPointMinus,
  VictoryNewsType,
  VictoryPointsProps,
} from "handlers/victoryNews/VictoryNews";
import VictoryNewsErgoAvatar from "handlers/victoryNews/VictoryNewsErgoAvatar";
import VictoryNewsNerdAvatar from "handlers/victoryNews/VictoryNewsNerdAvatar";

// enums
import { ChallengeType } from "enums/challengeEnum";
import { VictoryNewsPointType } from "enums/victoryNewsEnum";
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

const SHOW_DELAY = 800;
const HIDE_DELAY = 3000;

export interface VictoryPointMetaDataProps {
  points: number;
  type: VictoryNewsPointType;
}

export interface VictoryLevelPointMetaDataProps {
  points: number;
  level: ChallengeQuizLevelType;
}

interface VictoryPointsNewsProps {
  challengeType: ChallengeType;
  victoryPoints: VictoryPointMetaDataProps[];
  victoryLevelPoint?: VictoryLevelPointMetaDataProps;
}

const VictoryNewsHandler = ({
  victoryPoints,
  challengeType,
  victoryLevelPoint,
}: VictoryPointsNewsProps) => {
  const showTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const hideTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const victoryNewsTypeRef = useRef<VictoryNewsType>(getType());
  const [animation, setAnimation] = useState("");
  const [points, setPoints] = useState<VictoryPointsProps[] | undefined>();

  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleShow, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePoints, []);

  function handleShow() {
    showTimeoutRef.current = setTimeout(
      () => setAnimation("animate__swing"),
      SHOW_DELAY
    );
    hideTimeoutRef.current = setTimeout(
      () => setAnimation(getBackOutClassName()),
      HIDE_DELAY
    );
  }

  function getType(): VictoryNewsType {
    const hasNegative = victoryPoints.some((p) => p.points < 0);

    if (hasNegative) return VictoryNewsType.Danger;
    return VictoryNewsType.Success;
  }

  function getBackOutClassName(): string {
    return victoryNewsTypeRef.current === VictoryNewsType.Danger
      ? "animate__backOutDown"
      : "animate__backOutUp";
  }

  function handlePoints() {
    setPoints(sort(victoryPoints.map(mapPoint)));

    if (victoryLevelPoint)
      setPoints((p) => [...(p || []), mapLevelPoint(victoryLevelPoint)]);
  }

  function sort(victoryPoints: VictoryPointsProps[]): VictoryPointsProps[] {
    return victoryPoints.sort((a, b) => b.points - a.points);
  }

  function mapPoint({
    points,
    type,
  }: VictoryPointMetaDataProps): VictoryPointsProps {
    return {
      type,
      points,
      el: factoryPoint(points, type),
    };
  }

  function mapLevelPoint({
    points,
    level,
  }: VictoryLevelPointMetaDataProps): VictoryPointsProps {
    return {
      points,
      type: VictoryNewsPointType.Level,
      el: factoryLevelPoints(points, level),
    };
  }

  function factoryPoint(
    points: number,
    type: VictoryNewsPointType
  ): React.ReactNode {
    if (!points) return null;

    if (type === VictoryNewsPointType.Px) return factoryPxPoints(points);
    if (type === VictoryNewsPointType.Score) return factoryScorePoints(points);

    return null;
  }

  function factoryPxPoints(points = 1): React.ReactNode {
    return <PxPlus points={points} />;
  }

  function factoryScorePoints(points: number): React.ReactNode {
    if (points > 0) return <ScorePlus points={points} />;
    if (points < 0) return <ScoreMinus points={points} />;

    return null;
  }

  function factoryLevelPoints(
    points: number,
    level: ChallengeQuizLevelType
  ): React.ReactNode {
    if (points > 0)
      return (
        <LevelPointPlus
          points={points}
          level={level}
          levelLabel={getLevelLabel(level)}
        />
      );
    if (points < 0)
      return (
        <LevelPointMinus
          points={points}
          level={level}
          levelLabel={getLevelLabel(level)}
        />
      );

    return null;
  }

  function getLevelLabel(level: ChallengeQuizLevelType): string {
    return new ChallengeQuizLevelPointsEntity({ level }).getLabel();
  }

  function destroyComponent() {
    if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
    if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);
  }

  return (
    <>
      {!!animation &&
        points &&
        challengeType === ChallengeType.Introduction && (
          <VictoryNews
            points={points}
            type={victoryNewsTypeRef.current}
            avatar={<VictoryNewsNerdAvatar />}
            customClassName={`animate__animated ${animation}`}
          />
        )}

      {!!animation && points && challengeType === ChallengeType.Exercise && (
        <VictoryNews
          points={points}
          type={victoryNewsTypeRef.current}
          avatar={<VictoryNewsNerdAvatar />}
          customClassName={`animate__animated ${animation}`}
        />
      )}

      {!!animation && points && challengeType === ChallengeType.Quiz && (
        <VictoryNews
          points={points}
          type={victoryNewsTypeRef.current}
          avatar={<VictoryNewsErgoAvatar />}
          customClassName={`animate__animated ${animation}`}
        />
      )}
    </>
  );
};

export default VictoryNewsHandler;
