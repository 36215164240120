export enum FlyingObjectType {
  NonExistent = 0,
  SpaceShip,
  Bullet,
  Asteroid,
  Invader,
  InvaderBullet,
}

export enum FlyingObjectFrequencyType {
  Rare = 1,
  Common,
  Often,
}

export enum FlyingObjectSubType {
  NonExistent = 0,
  SpaceShip,
  Bullet,
  AsteroidMineralGold,
  AsteroidMineralSilver,
  AsteroidMineralSand,
  AsteroidMineralBauxite,
  AsteroidMineralCopper,
  AsteroidMineralEstelin,
  AsteroidWater,
  AsteroidStrongForce,
  InvaderOneBullet,
  InvaderTwoBullet,
  InvaderThreeBullet,
  InvaderFourBullet,
  InvaderFiveBullet,
  InvaderSixBullet,
  InvaderSevenBullet,
  InvaderOne,
  InvaderTwo,
  InvaderThree,
  InvaderFour,
  InvaderFive,
  InvaderSix,
  InvaderSeven,
}

export enum FlyingObjectDamageType {
  low = 1,
  middle,
  high,
}

export enum FlyingObjectHpType {
  low = 1,
  middle,
  high,
}
