import { ApplicationErrorProps } from "interfaces/error";
import { ERROR_TYPES } from "constants/error";
import AuthError from "errors/AuthError";
import UnknownError from "errors/UnknownError";
import ApplicationError from "errors/ApplicationError";

const handle = {
  auth(e: unknown): Error {
    const error = e as ApplicationErrorProps;
    let customError = "Algum erro aconteceu...";

    if (!error) return new UnknownError();

    const { data } = error;
    if (!data || !data.type) return new UnknownError();

    const { type } = data;

    if (data && data.type === ERROR_TYPES.USER_NOT_FOUND)
      customError = "Email ou senha incorreto(s)";
    else if (type === ERROR_TYPES.USER_ALREADY_REGISTERED) {
      customError = "Email já cadastrado";
    } else if (type === ERROR_TYPES.LOGIN_ERROR) {
      customError =
        "Sua conta foi criada com sucesso, mas por algum motivo não foi possível realizar o login automático, por favor, faça o login com o seu email e senha na tela de login";
    } else if (type === ERROR_TYPES.USER_NOT_FOUND) {
      customError = "Usuário não encontrado";
    }

    return new AuthError(customError);
  },
  general(e: unknown): Error {
    const error = e as ApplicationErrorProps;
    let customError = "Algum erro aconteceu...";

    if (!error) return new UnknownError();
    const { data } = error;
    if (!data || !data.type) return new UnknownError();

    const { type } = data;

    if (type === ERROR_TYPES.USER_NOT_FOUND) {
      customError = "Usuário não encontrado";
    } else if (type === ERROR_TYPES.EMAIL_ALREADY_REGISTERED) {
      customError = "Email já cadastrado";
    } else if (type === ERROR_TYPES.CODENAME_ALREADY_REGISTERED) {
      customError = "Codinome já cadastrado";
    }

    return new ApplicationError(data.message || customError);
  },
};

const errorUtils = {
  handle,
};

export default errorUtils;
