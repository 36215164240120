// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";
import { spaceshipResourcesActions } from "redux/slices/spaceInvaders/spaceshipResourcesSlice";

// components
import {
  notifyAnimate,
  NotifyFactory,
  NotifyFactoryType,
} from "factories/NotifyFactory";
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { SpaceshipResourcesProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import {
  AsteroidWaterEntity,
  AsteroidMineralGoldEntity,
  AsteroidMineralSandEntity,
  AsteroidStrongForceEntity,
  AsteroidMineralSilverEntity,
  AsteroidMineralCopperEntity,
  AsteroidMineralEstelinEntity,
  AsteroidMineralBauxiteEntity,
} from "entities/spaceInvaders/AsteroidEntity";
import PlayerHpEntity from "entities/PlayerHpEntity";
import SpaceshipResourcesEntity from "entities/spaceshipResources/SpaceshipResourcesEntity";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// enums
import {
  SpaceshipPartType,
  SpaceshipResourceType,
} from "enums/spaceInvaders/spaceshipEnum";
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// utils
import numberUtils from "utils/numberUtils";

interface SpaceshipResourcesNeededProps {
  cost: number;
  name: string;
  typeName: keyof SpaceshipResourcesProps;
}

interface RecoverHpProps {
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
  wrapperNotifiers: HTMLDivElement | null;
}

const PlayerHpRecoverHandler = ({
  wrapper,
  challenge,
  wrapperNotifiers,
}: RecoverHpProps) => {
  const dispatch = useDispatch();
  const player = useSelector(s.player());
  const hasRepairItemRef = useRef(false);
  const flagAlreadyStarted = useRef(false);
  const spaceshipFeatures = useSelector(s.spaceshipFeatures());
  const spaceshipResources = useSelector(s.spaceshipResources());
  const spaceshipItemsCounter = useSelector(s.spaceshipItemsCounter());
  const spaceshipFeaturesRef = useRef(spaceshipFeatures);
  const { flowFinished } = challenge;

  useEffect(handleHasRepairItem, [spaceshipItemsCounter]);
  useEffect(handleSpaceshipFeatures, [spaceshipFeatures]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [player, flowFinished]);

  function handleSpaceshipFeatures() {
    spaceshipFeaturesRef.current = spaceshipFeatures;
  }

  function handleHasRepairItem() {
    const repairItem = spaceshipItemsCounter[SpaceshipItemEnum.Repair];
    hasRepairItemRef.current = repairItem && repairItem.quantity > 0;
  }

  function bootstrap() {
    if (!flowFinished) return;
    if (flagAlreadyStarted.current) return;

    const entity = new PlayerHpEntity({ hp: player.hp, life: player.life });

    if (!hasRepairItemRef.current) {
      flagAlreadyStarted.current = true;
      entity.needRecover && alertHasNotRepairItem();

      return;
    }

    start(entity.hp, entity.hpToRecover, entity.spaceshipPart);
  }

  function start(
    hp: number,
    hpToRecover: number,
    type: SpaceshipPartType
  ): void {
    if (flagAlreadyStarted.current) return;
    if (hpToRecover <= 0) {
      const entity = new PlayerHpEntity({ hp, life: player.life });
      const nextType = entity.nextSpaceshipPart;

      if (!nextType) return;
      return start(0, PlayerHpEntity.hpTotal, nextType);
    }

    flagAlreadyStarted.current = true;
    const resourcesNeeded = getSpaceshipResourcesNeeded(type);

    if (hasNotAvailableResource(resourcesNeeded, spaceshipResources)) {
      alertNoResourcesAvailable(resourcesNeeded, spaceshipResources);
      return;
    }

    recover(hp, hpToRecover, type, resourcesNeeded);
  }

  function recover(
    hp: number,
    hpToRecover: number,
    type: SpaceshipPartType,
    resourcesNeeded: SpaceshipResourcesNeededProps[]
  ): void {
    if (hpToRecover <= 0) return;

    const resources = calculateCost(hpToRecover, resourcesNeeded);
    if (hasNotAvailableResource(resources, spaceshipResources))
      return recover(hp, hpToRecover - 1, type, resourcesNeeded);

    const entity = new SpaceshipResourcesEntity({
      resources: spaceshipResources,
    });
    const mapped = entity.mapByQuantity(entity.transformedValues, {
      gold: resources[0].cost,
      silver: resources[1].cost,
      bauxite: resources[2].cost,
      sand: resources[3].cost,
      copper: resources[4].cost,
      water: resources[5].cost,
      strongForce: resources[6].cost,
    });
    const deleteFields = entity.stringifyDeleteFields(mapped);

    dispatch(playerActions.async.recoveryHp({ type, hpToRecover }));
    dispatch(spaceshipResourcesActions.async.remove(deleteFields));

    alertRecoveringHp(hpToRecover);
    alertLostResources(resources);
  }

  function calculateCost(
    hp: number,
    resources: SpaceshipResourcesNeededProps[]
  ): SpaceshipResourcesNeededProps[] {
    return resources.map((resource) => ({
      ...resource,
      cost: Math.ceil(resource.cost * hp),
    }));
  }

  function hasNotAvailableResource(
    resourcesNeeded: SpaceshipResourcesNeededProps[],
    resourcesAvailable: SpaceshipResourcesProps
  ): boolean {
    return resourcesNeeded.some((resource) => {
      const { cost, typeName } = resource;
      return resourcesAvailable[typeName].transformed < cost;
    });
  }

  function alertNoResourcesAvailable(
    resourcesNeeded: SpaceshipResourcesNeededProps[],
    resourcesAvailable: SpaceshipResourcesProps
  ) {
    if (!wrapperNotifiers) return;

    const messages = resourcesNeeded.reduce((acc: string[], resource) => {
      const { cost, name, typeName } = resource;
      const available = resourcesAvailable[typeName].transformed;

      if (available < cost) acc.push(`+${cost - available} ${name}`);
      return acc;
    }, []);

    const content = `Para recuperar o hp são necessários: ${messages.join(
      "; "
    )}`;
    const factoriesData = [
      {
        slow: true,
        factories: [
          NotifyFactory({
            content,
            type: NotifyFactoryType.Danger,
          }),
        ],
      },
    ];

    notifyAnimate({ wrapper: wrapperNotifiers, factoriesData });
  }

  function alertRecoveringHp(hp: number) {
    if (!wrapperNotifiers) return;

    const content = `+${hp} (${spaceshipFeaturesRef.current.engraving})...`;
    const type = NotifyFactoryType.Success;
    const factoriesData = [
      {
        factories: [NotifyFactory({ content, type })],
        slow: true,
      },
    ];

    notifyAnimate({ wrapper: wrapperNotifiers, factoriesData });
  }

  function alertHasNotRepairItem() {
    if (!wrapperNotifiers) return;

    const content = `Não foi possível reparar a espaçonave [${spaceshipFeaturesRef.current.engraving}]. O item "Circuito de Reparação Estelar" NÃO foi encontrado.`;
    const type = NotifyFactoryType.Warning;
    const factoriesData = [
      {
        factories: [NotifyFactory({ content, type })],
        slower: true,
      },
    ];

    notifyAnimate({ wrapper: wrapperNotifiers, factoriesData });
  }

  function getSpaceshipResourcesNeeded(
    type: SpaceshipPartType
  ): SpaceshipResourcesNeededProps[] {
    const part = SPACE_INVADERS.spaceshipRecoverHp[type];
    const gold = {
      name: AsteroidMineralGoldEntity.alias,
      cost: part[SpaceshipResourceType.Gold],
      typeName: "gold" as keyof SpaceshipResourcesProps,
    };
    const silver = {
      name: AsteroidMineralSilverEntity.alias,
      cost: part[SpaceshipResourceType.Silver],
      typeName: "silver" as keyof SpaceshipResourcesProps,
    };
    const bauxite = {
      name: AsteroidMineralBauxiteEntity.alias,
      cost: part[SpaceshipResourceType.Bauxite],
      typeName: "bauxite" as keyof SpaceshipResourcesProps,
    };
    const sand = {
      name: AsteroidMineralSandEntity.alias,
      cost: part[SpaceshipResourceType.Sand],
      typeName: "sand" as keyof SpaceshipResourcesProps,
    };
    const copper = {
      name: AsteroidMineralCopperEntity.alias,
      cost: part[SpaceshipResourceType.Copper],
      typeName: "copper" as keyof SpaceshipResourcesProps,
    };
    const estelin = {
      name: AsteroidMineralEstelinEntity.alias,
      cost: part[SpaceshipResourceType.Estelin],
      typeName: "estelin" as keyof SpaceshipResourcesProps,
    };
    const water = {
      name: AsteroidWaterEntity.alias,
      cost: part[SpaceshipResourceType.Water],
      typeName: "water" as keyof SpaceshipResourcesProps,
    };
    const strongForce = {
      name: AsteroidStrongForceEntity.alias,
      cost: part[SpaceshipResourceType.StrongForce],
      typeName: "strongForce" as keyof SpaceshipResourcesProps,
    };

    return [gold, silver, bauxite, sand, copper, estelin, water, strongForce];
  }

  // ATTENTION WHORE

  function alertLostResources(
    resourcesNeeded: SpaceshipResourcesNeededProps[]
  ) {
    if (!wrapper) return;

    const { clientWidth, clientHeight } = wrapper;

    alertAnimate({
      wrapper: wrapper as HTMLDivElement,
      factories: mapFactoryAlerts(resourcesNeeded),
      bounds: {
        top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
      },
    });
  }

  function mapFactoryAlerts(
    resourcesNeeded: SpaceshipResourcesNeededProps[]
  ): (() => HTMLDivElement)[] {
    return resourcesNeeded
      .filter((resource) => resource.cost > 0)
      .map((resource) =>
        AlertFactory({
          type: AlertFactoryType.Danger,
          content: `-${resource.cost} ${resource.name} (refinado)`,
        })
      );
  }

  return null;
};

export default PlayerHpRecoverHandler;
