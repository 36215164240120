// components
import FormHeader from "components/form/FormHeader";
import NerdXFooter from "components/presentations/spaceship/NerdXFooter";
import SpaceshipItems from "components/presentations/spaceship/SpaceshipItems";

interface SpaceshipItemsWrapperProps {
  back(): void;
  proceed(): void;
  getSpaceshipClassNameColor(): string;
  disabled?: boolean;
}

const SpaceshipItemsWrapper = ({
  back,
  proceed,
  disabled,
  getSpaceshipClassNameColor,
}: SpaceshipItemsWrapperProps) => {
  return (
    <div className="d_flex_column_1 relative">
      <FormHeader back={back} proceed={proceed} disabled={!!disabled} />
      <SpaceshipItems getSpaceshipClassNameColor={getSpaceshipClassNameColor} />
      <NerdXFooter />
    </div>
  );
};

export default SpaceshipItemsWrapper;
