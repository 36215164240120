import React from "react";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// enums
import { BubbleType } from "enums/bubbleEnum";

// types
import { dropZoneBorderColorType } from "components/DropZone";

// utils
import { v4 as uuidv4 } from "uuid";
import dragUtils from "utils/dragUtils";

interface GetProps {
  payload: string;
  wrapperWidth: number;
  wrapperHeight: number;
  WhoreEl: React.FC<AttentionWhoreElProps>;
  dropZoneBorderColor: dropZoneBorderColorType;
  call(attentionWhore: AttentionWhoreProps): void;
  last?: boolean;
  label?: string;
  bubbleType?: BubbleType;
  unknownPayload?: unknown;
}

function get({
  call,
  WhoreEl,
  payload,
  label = "",
  last = false,
  wrapperWidth,
  wrapperHeight,
  unknownPayload,
  dropZoneBorderColor,
  bubbleType = BubbleType.None,
}: GetProps): AttentionWhoreProps {
  const wrapper = { width: wrapperWidth, height: wrapperHeight };
  const { x, y } = dragUtils.getRandomPosition({ wrapper });

  return {
    call,
    last,
    label,
    payload,
    WhoreEl,
    bubbleType,
    x: `${x}px`,
    y: `${y}px`,
    id: uuidv4(),
    unknownPayload,
    dropZoneBorderColor,
  };
}

function fake(): CurrentAttentionWhoreProps {
  return {
    x: "0",
    y: "0",
    label: "",
    show: null,
    payload: "",
    id: uuidv4(),
    call: () => {},
    WhoreEl: _WhoreEl,
    bubbleType: BubbleType.None,
    dropZoneBorderColor: "yellow",
  };
}

// private
const _WhoreEl = () => <div />;

const attentionWhoreUtils = {
  get,
  fake,
};

export default attentionWhoreUtils;
