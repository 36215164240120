// components
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// icons
import DonePixelIcon from "icons/DonePixelIcon";
import LaserPixelIcon from "icons/LaserPixelIcon";
import HeartPixelIcon from "icons/HeartPixelIcon";
import MonsterPixelIcon from "icons/MonsterPixelIcon";
import LocationPixelIcon from "icons/LocationPixelIcon";
import PersonsPixelIcon from "icons/PersonsPixelIcon";
import HouseFlagPixelIcon from "icons/HouseFlagPixelIcon";
import ResourcesPixelIcon from "icons/ResourcesPixelIcon";
import CivilizationPixelIcon from "icons/CivilizationPixelIcon";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 65px;
  position: relative;

  .button_ripple_effect {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  ul {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background: ${color.grey.dark};

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
    }
  }
`;

interface PlayerProfileSideMenuProps {
  slideActiveIndex: number;
  goTo(index: number): void;
}

const PlayerProfileSideMenu = ({
  goTo,
  slideActiveIndex,
}: PlayerProfileSideMenuProps) => {
  return (
    <Wrapper>
      <ul>
        <li>
          <ButtonRippleEffect
            color={color.purple}
            onClick={goTo.bind(null, 0)}
            className="button_ripple_effect"
          >
            <HeartPixelIcon
              width="25"
              height="25"
              primaryColor={
                slideActiveIndex === 0 ? color.purple : color.grey.stronger
              }
              secondaryColor={
                slideActiveIndex === 0 ? color.red : color.grey.stronger
              }
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 1)}
            className="button_ripple_effect"
            color={color.green}
          >
            <DonePixelIcon
              width="25"
              height="25"
              color={slideActiveIndex === 1 ? color.green : color.grey.stronger}
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 2)}
            className="button_ripple_effect"
            color={color.gold}
          >
            <LaserPixelIcon
              width="25"
              height="25"
              color={slideActiveIndex === 2 ? color.gold : color.grey.stronger}
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 3)}
            className="button_ripple_effect"
            color={color.brown}
          >
            <ResourcesPixelIcon
              width="25"
              height="25"
              color={slideActiveIndex === 3 ? color.brown : color.grey.stronger}
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 4)}
            className="button_ripple_effect"
            color={color.purple}
          >
            <LocationPixelIcon
              width="25"
              height="25"
              color={
                slideActiveIndex === 4 ? color.purple : color.grey.stronger
              }
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 5)}
            className="button_ripple_effect"
            color={color.yellow}
          >
            <MonsterPixelIcon
              width="25"
              height="25"
              color={
                slideActiveIndex === 5 ? color.orange : color.grey.stronger
              }
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 6)}
            className="button_ripple_effect"
            color={color.pink}
          >
            <CivilizationPixelIcon
              width="25"
              height="25"
              color={slideActiveIndex === 6 ? color.pink : color.grey.stronger}
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 7)}
            className="button_ripple_effect"
            color={color.yellow}
          >
            <HouseFlagPixelIcon
              width="30"
              height="30"
              color={
                slideActiveIndex === 7 ? color.yellow : color.grey.stronger
              }
              flagColor={
                slideActiveIndex === 7 ? color.red : color.grey.stronger
              }
            />
          </ButtonRippleEffect>
        </li>

        <li>
          <ButtonRippleEffect
            onClick={goTo.bind(null, 8)}
            className="button_ripple_effect"
            color={color.blue}
          >
            <PersonsPixelIcon
              width="25"
              height="25"
              color={slideActiveIndex === 8 ? color.blue : color.grey.stronger}
            />
          </ButtonRippleEffect>
        </li>
      </ul>
    </Wrapper>
  );
};

export default PlayerProfileSideMenu;
