// react
import { useState, useEffect } from "react";

// components
import PressButtonTryAgain from "components/pressButtons/PressButtonTryAgain";

// utils
import styled from "styled-components";
import color from "styles/color";

const TryAgain = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  z-index: 9001;
  opacity: 0.7;
`;

interface ChallengeTryAgainProps {
  show: boolean;
  onTryAgain(): void;
}

const ChallengeTryAgain = ({ show, onTryAgain }: ChallengeTryAgainProps) => {
  const [config, setConfig] = useState({
    animation: "",
    show: false,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToShow, [show]);

  function listenToShow() {
    if (!show) return;
    setConfig({ animation: "animate__bounceInUp", show: true });
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLElement>) {
    e.stopPropagation();

    if (e.animationName === "bounceOutLeft")
      setConfig({ ...config, show: false });
  }

  function tryAgain() {
    setConfig({ ...config, animation: "animate__bounceOutLeft" });
    onTryAgain();
  }

  return (
    <>
      {config.show && (
        <TryAgain
          className={`${config.animation} animate__animated`}
          onAnimationEnd={onAnimationEnd}
        >
          <PressButtonTryAgain onClick={tryAgain} />
        </TryAgain>
      )}
    </>
  );
};

export default ChallengeTryAgain;
