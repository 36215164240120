// react
import { useState, useRef, useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { feedbackActions } from "redux/slices/feedbackSlice";

// handlers
import FeedbackBubble from "handlers/feedback/FeedbackBubble";

// componentes
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import FeedbackClassRoomRatingPresentation from "handlers/feedback/FeedbackClassRoomRatingPresentation";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { AttentionWhoreProps } from "interfaces/attentionWhore";
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// icons
import IconWhoreStar from "components/iconWhores/IconWhoreStar";
import IconWhoreStarFit from "components/iconWhores/IconWhoreStarFit";

// enums
import { BubbleType } from "enums/bubbleEnum";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface ChallengeFeedbackHandlerProps {
  wrapper: HTMLElement | null;
  challenge: ChallengeCommonProps;
}

const FeedbackHandler = ({
  wrapper,
  challenge,
}: ChallengeFeedbackHandlerProps) => {
  const timeoutRef = useRef<any>();
  const dispatch = useDispatch();
  const player = useSelector(s.player());
  const { flowParcialDone } = useSelector(s.classRoom());
  const { flowDone, flowFinished } = challenge;
  const [isAvailable, setIsAvailable] = useState(false);
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [showClassroomRating, setShowClassroomRating] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(displayWhore, [flowParcialDone, flowFinished]);
  useEffect(handleAvailability, [challenge.flowDone, challenge.flowFinished]);

  function displayWhore() {
    if (!flowFinished) return;
    if (!flowParcialDone) return;

    setWhores([getWhore()]);
  }

  function handleAvailability() {
    if (!challenge.flowDone) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(
      () => setIsAvailable(true),
      challenge.flowFinished ? 2000 : 0
    );
  }

  function onCloseRatingClassRoom() {
    setShowClassroomRating(false);
  }

  function submitClassRoomRating(rating: number, message: string) {
    dispatch(
      feedbackActions.async.submitClassRoomRating({
        rating,
        message,
        challenge,
      })
    );
  }

  function submitThumbs(up: boolean, down: boolean) {
    dispatch(
      feedbackActions.async.submitChallengeThumbs({ up, down, challenge })
    );
  }

  function destroyComponent() {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }

  function getWhore(): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      payload: "",
      WhoreEl: getIconStar(),
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function getIconStar(): React.FC<AttentionWhoreElProps> {
    return player.rating.value ? IconWhoreStar : IconWhoreStarFit;
  }

  function onAttentionWhoreCall(_attentionWhore: AttentionWhoreProps) {
    setShowClassroomRating(true);
  }

  return (
    <>
      <AttentionWhores whores={whores} clear={flowDone} />

      <FeedbackBubble
        challenge={challenge}
        submitThumbs={submitThumbs}
        show={isAvailable && flowDone && !flowFinished}
        animate={isAvailable && flowDone && flowFinished}
      />

      {showClassroomRating && (
        <FeedbackClassRoomRatingPresentation
          player={player}
          onClose={onCloseRatingClassRoom}
          submitClassRoomRating={submitClassRoomRating}
        />
      )}
    </>
  );
};

export default FeedbackHandler;
