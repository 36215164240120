// react
import { match } from "react-router-dom";
import { useState, useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import Loading from "components/Loading";
import Header from "components/header/Header";
import FirebaseSync from "firebaseSync/FirebaseSync";
import HeaderMenu from "components/header/HeaderMenu";
import ClassRoom from "components/classRoom/ClassRoom";
import ExceptionError from "components/ExceptionError";

// widgets
import PixelWidget from "components/widgets/PixelWidget";
import PlayerScoreWidget from "components/widgets/PlayerScoreWidget";
import LevelStatusWidget from "components/widgets/LevelStatusWidget";
import SpaceshipStatusWidget from "components/widgets/SpaceshipStatusWidget";

// handlers
import FetchClassRoomDataHandler from "handlers/FetchClassRoomDataHandler";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  --ion-background-color: ${color.dark.bg};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 0;
`;

interface ClassRoomPageProps {
  match: match<{ id: string; lang: string }>;
}

const ClassRoomPage = ({ match }: ClassRoomPageProps) => {
  const auth = useSelector(s.auth());
  const classRoom = useSelector(s.classRoom());
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(bootstrap, [auth]);

  function bootstrap() {
    if (!auth.signed) return;
    setLoading(true);
  }

  function errorCallback(_error: unknown) {
    setLoading(false);
    setNotFound(true);
  }

  function successCallback() {
    setLoading(false);
  }

  return (
    <Wrapper>
      <HeaderMenu
        username={auth.user && auth.user.codename}
        signed={auth.signed}
      />

      <IonPage id="mainContent">
        <Header />
        <Loading loading={loading} />

        <IonContent>
          <Content>
            <PlayerScoreWidget />
            <PixelWidget />
            <LevelStatusWidget />
            <SpaceshipStatusWidget />

            {notFound ? (
              <ExceptionError />
            ) : (
              <ClassRoom
                auth={auth}
                loading={loading}
                classRoom={classRoom}
                classRoomProgress={0}
              />
            )}

            {auth.signed && (
              <FetchClassRoomDataHandler
                auth={auth}
                errorCallback={errorCallback}
                successCallback={successCallback}
                classRoomId={Number(match.params.id)}
                languageId={Number(match.params.lang)}
              />
            )}

            {!!(auth.firebaseSigned && auth.user && classRoom.id) && (
              <FirebaseSync
                user={auth.user}
                classRoomId={classRoom.id}
                languageId={classRoom.languageId}
              />
            )}
          </Content>
        </IonContent>
      </IonPage>
    </Wrapper>
  );
};

export default ClassRoomPage;
