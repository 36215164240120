import {
  ProgrammingLanguageProps,
  ProgrammingLanguageResourceProps,
} from "interfaces/programmingLanguage";

function list(
  programmingLanguages: ProgrammingLanguageResourceProps[]
): ProgrammingLanguageProps[] {
  return programmingLanguages.map(map);
}

function map(
  programmingLanguage: ProgrammingLanguageResourceProps
): ProgrammingLanguageProps {
  return {
    id: programmingLanguage.id,
    name: programmingLanguage.name,
    alias: programmingLanguage.alias,
    type: programmingLanguage.type,
  };
}

const programmingLanguageParser = {
  list,
  map,
};

export default programmingLanguageParser;
