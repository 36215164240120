// react
import { useRef, useEffect } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { FlyingObjectRefProps } from "interfaces/spaceInvaders/flyingObject";

// components
import Bullets from "components/spaceInvaders/Bullets";
import Spaceship from "components/spaceInvaders/Spaceship";
import Explosions from "components/spaceInvaders/Explosions";
import FlyingObjects from "components/spaceInvaders/FlyingObjects";
import StrongForceBarrier from "components/spaceInvaders/StrongForceBarrier";
import SpaceInvadersBehaviors from "components/spaceInvaders/SpaceInvadersBehaviors";

// handlers
import InvadersHandler from "handlers/invaders/InvadersHandler";

// types
import { dropZoneBorderColorType } from "components/DropZone";
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// enums
import { FlyingObjectFrequencyType } from "enums/spaceInvaders/flyingObjectEnum";

// handlers
import DropZoneSpaceshipHandler from "handlers/dropzone/DropZoneSpaceshipHandler";

interface SpaceInvadersHandlerProps {
  challenge: ChallengeCommonProps;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  blink?(): void;
  onSpaceshipDrop?(): void;
}

const SpaceInvadersHandler = ({
  blink,
  challenge,
  wrapperRef,
  onSpaceshipDrop,
}: SpaceInvadersHandlerProps) => {
  const { paused, freezed } = useSelector(s.challengeFlow());
  const spaceshipFeatures = useSelector(s.spaceshipFeatures());
  const freezedRef = useRef(false);
  const freezedInternalRef = useRef(false);
  const bulletsRef = useRef<FlyingObjectRefProps[]>([]);
  const invadersRef = useRef<FlyingObjectRefProps[]>([]);
  const flyingObjectsRef = useRef<FlyingObjectRefProps[]>([]);
  const invaderBulletsRef = useRef<FlyingObjectRefProps[]>([]);
  const spaceshipRef = useRef<FlyingObjectRefProps | null>(null);

  useEffect(listenToFreezed, [freezed]);
  useEffect(handleInternalFreezed, [freezed]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePageVisibility, []);

  function listenToFreezed() {
    freezedRef.current = freezed;
  }

  function handleInternalFreezed() {
    freezedInternalRef.current = freezed;
  }

  function handlePageVisibility() {
    document.addEventListener("visibilitychange", syncFreezed, false);
    // return document.removeEventListener("visibilitychange", syncFreezed);
  }

  function syncFreezed() {
    const freezed = freezedInternalRef.current;
    freezedRef.current = freezed ? freezed : document.hidden;
  }

  function onSpaceshipDropEl() {
    goUpAnimation(spaceshipRef.current?.el as HTMLElement);
    onSpaceshipDrop && setTimeout(onSpaceshipDrop);
  }

  function getDropZoneActiveBorderColor(
    colorType: SpaceshipColorType
  ): dropZoneBorderColorType {
    if (colorType === SpaceshipColorType.Red) return "red";
    if (colorType === SpaceshipColorType.Blue) return "blue";
    if (colorType === SpaceshipColorType.Green) return "green";
    if (colorType === SpaceshipColorType.Yellow) return "yellow";
    if (colorType === SpaceshipColorType.Purple) return "purple";
    if (colorType === SpaceshipColorType.Orange) return "orange";

    return "yellow";
  }

  function goUpAnimation(spaceshipEl: HTMLElement) {
    setTimeout(() => {
      spaceshipEl.classList.remove("animate_y_infinite");
      spaceshipEl.classList.add("animate__animated");
      spaceshipEl.classList.add("animate__wobble");

      spaceshipEl.addEventListener("animationend", onAnimationEnd);

      function onAnimationEnd() {
        if (!spaceshipEl) return;

        spaceshipEl.classList.remove("animate__wobble");
        spaceshipEl.classList.add("animate__backOutUp");
        spaceshipEl.removeEventListener("animationend", onAnimationEnd);
      }
    });
  }

  return (
    <>
      <DropZoneSpaceshipHandler
        ondrop={onSpaceshipDropEl}
        isActive={challenge.flowFinished}
        dropZoneBorderColor={getDropZoneActiveBorderColor(
          spaceshipFeatures.colorRaw
        )}
      />

      <StrongForceBarrier
        destroy={challenge.flowFinished}
        show={!challenge.flowDone && challenge.flowInit}
      />

      <Explosions wrapperEl={wrapperRef.current} />

      <SpaceInvadersBehaviors
        blink={blink}
        paused={paused}
        bulletsRef={bulletsRef}
        invadersRef={invadersRef}
        spaceshipRef={spaceshipRef}
        wrapperEl={wrapperRef.current}
        flyingObjectsRef={flyingObjectsRef}
        invaderBulletsRef={invaderBulletsRef}
      />

      <Bullets
        paused={paused}
        freezedRef={freezedRef}
        bulletsRef={bulletsRef}
        wrapperEl={wrapperRef.current}
        hasStrongBarrier={!challenge.flowDone && !challenge.flowFinished}
      />

      <InvadersHandler
        paused={paused}
        freezedRef={freezedRef}
        wrapperRef={wrapperRef}
        invadersRef={invadersRef}
        invaderBulletsRef={invaderBulletsRef}
      />

      <FlyingObjects
        paused={paused}
        wrapperRef={wrapperRef}
        freezedRef={freezedRef}
        flyingObjectsRef={flyingObjectsRef}
        frequency={FlyingObjectFrequencyType.Common}
      />

      {challenge.flowInit && !!spaceshipRef && (
        <Spaceship
          blink={blink}
          paused={paused}
          spaceshipRef={spaceshipRef}
          wrapperEl={wrapperRef.current}
          hasStrongBarrier={!challenge.flowDone && !challenge.flowFinished}
        />
      )}
    </>
  );
};

export default SpaceInvadersHandler;
