// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// icons
import LevelUpBadgeSvg from "icons/LevelUpBadgeIcon";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;

  p {
    all: unset;

    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    left: 10px;
    width: 20px;
    height: 18px;
    position: absolute;
    background: ${color.yellow};
    font-size: 18px;
    color: ${color.grey.dark};
  }
`;

const IconWhoreLevelUp = ({ whore }: AttentionWhoreElProps) => {
  return (
    <Wrapper>
      <p className="pixelify_font_family">{whore.label}</p>
      <LevelUpBadgeSvg width="40" height="40" />
    </Wrapper>
  );
};

export default IconWhoreLevelUp;
