// react
import { match } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { IonContent, IonPage } from "@ionic/react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// components
import Loading from "components/Loading";
import Header from "components/header/Header";
import FirebaseSync from "firebaseSync/FirebaseSync";
import HeaderMenu from "components/header/HeaderMenu";
import ExceptionError from "components/ExceptionError";
import DemoSignInModal from "pages/demo/DemoSignInModal";
import DemoStartButton, {
  DemoContinueButton,
} from "pages/demo/DemoStartButton";

// interfaces
import { ChallengeProps } from "interfaces/challenge";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// widgets
import PixelWidget from "components/widgets/PixelWidget";
import PlayerScoreWidget from "components/widgets/PlayerScoreWidget";
import LevelStatusWidget from "components/widgets/LevelStatusWidget";
import SpaceshipStatusWidget from "components/widgets/SpaceshipStatusWidget";

// handlers
import ChallengeHandler from "handlers/ChallengeHandler";
import FetchClassRoomDataHandler from "handlers/FetchClassRoomDataHandler";

// utils
import color from "styles/color";
import styled from "styled-components";
import hashUtils from "utils/hashUtils";
import base64Utils from "utils/base64Utils";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  --ion-background-color: ${color.dark.bg};
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 0;
`;

interface DemoPageProps {
  match: match<{ hash: string }>;
}

const DemoPage = ({ match }: DemoPageProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const classRoom = useSelector(s.classRoom());
  const sections = useSelector(s.challengeSections());
  const checkpoints = useSelector(s.playerCheckpoints());
  const challengesHash = useSelector(s.challengesHash());
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isChallengeModalOpen, setIsChallengeModalOpen] = useState(false);
  const sectionsRef = useRef<ChallengeSectionProps[]>([]);
  const challengesHashRef = useRef<any>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSections, [sections]);
  useEffect(handleChallengesHash, [challengesHash]);

  useEffect(() => {
    async function bootstrap() {
      try {
        const decoded = base64Utils.decode(match.params.hash);
        const [email, hash] = decoded.split(",");

        if (!auth.signed) setLoading(false);
        if (!(await hashUtils.match(email, hash)))
          throw new Error("Invalid hash");
      } catch (error) {
        setLoading(false);
        setHasError(true);
      }
    }

    bootstrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  function handleSections() {
    sectionsRef.current = sections;
  }

  function handleChallengesHash() {
    challengesHashRef.current = challengesHash;
  }

  function openModal() {
    if (!checkpoints[PlayerCheckpointEnum.DemoStarted]) {
      openOrderModal();
      return;
    }

    openChallengeModal();
  }

  function openOrderModal() {
    if (isOrderModalOpen) return;
    setIsOrderModalOpen(true);
  }

  function openChallengeModal() {
    if (!auth.signed) return;
    if (isChallengeModalOpen) return;

    const section = getSection();
    const challenge = getChallenge(section);

    if (!section) return;
    if (!challenge) return;

    setTimeout(() => {
      dispatch(challengeActions.async.openModal(challenge, section));
      setTimeout(() => setIsChallengeModalOpen(true));
    });
  }

  function getSection(): ChallengeSectionProps {
    return sectionsRef.current[0];
  }

  function getChallenge(section: ChallengeSectionProps): ChallengeProps {
    const challengesFromSection = (challengesHashRef.current[section.id] ||
      []) as ChallengeProps[];
    return challengesFromSection[0] || [];
  }

  function onOrderModalDismissed() {
    setIsOrderModalOpen(false);
  }

  function onOrderModalFinished() {
    setIsOrderModalOpen(false);
    openChallengeModalAutomatically();
  }

  function onStart() {
    setLoading(true);
  }

  function errorCallback(_error: unknown) {
    setLoading(false);
    setHasError(true);
  }

  function successCallback() {
    setLoading(false);
  }

  function openChallengeModalAutomatically() {
    if (loading) return;
    setTimeout(openChallengeModal, 250);
  }

  return (
    <Wrapper>
      <HeaderMenu signed={auth.signed} username={auth.user.codename} />

      <IonPage id="mainContent">
        <Header />
        <Loading loading={loading} />

        <IonContent>
          <Content>
            <PlayerScoreWidget />
            <PixelWidget />
            <LevelStatusWidget />
            <SpaceshipStatusWidget />

            {hasError ? (
              <ExceptionError />
            ) : auth.signed ? (
              <DemoContinueButton loading={loading} onClick={openModal} />
            ) : (
              <DemoStartButton loading={loading} onClick={openModal} />
            )}

            {auth.signed && (
              <FetchClassRoomDataHandler
                auth={auth}
                languageId={1}
                classRoomId={1}
                onStart={onStart}
                errorCallback={errorCallback}
                successCallback={successCallback}
              />
            )}

            {!!(auth.firebaseSigned && auth.user && classRoom.id) && (
              <FirebaseSync
                user={auth.user}
                classRoomId={classRoom.id}
                languageId={classRoom.languageId}
              />
            )}

            <DemoSignInModal
              show={isOrderModalOpen}
              dismiss={onOrderModalDismissed}
              finished={onOrderModalFinished}
            />

            <ChallengeHandler
              sections={sections}
              isChallengeQuizModalOpen={false}
              openChallengeQuizModal={() => {}}
              challengesMerged={challengesHash}
              toggleChallengeQuizModal={() => {}}
              openChallengeModal={openChallengeModal}
              isChallengeModalOpen={isChallengeModalOpen}
              toggleChallengeModal={setIsChallengeModalOpen}
            />
          </Content>
        </IonContent>
      </IonPage>
    </Wrapper>
  );
};

export default DemoPage;
