// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import PlayerIconHandler from "handlers/PlayerIconHandler";
import SubHeaderWrapper from "components/header/SubHeaderWrapper";

// utils
import styled from "styled-components";
import color from "styles/color";

const PlayerStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .player_score_wrapper {
    display: flex;
    align-items: center;
    font-size: 21px;

    .player_score {
      color: ${color.grey.light};
      font-size: 20px;
      margin-left: 10px;

      .player_score_name {
        display: inline-block;
        font-weight: bold;
      }
    }
  }
`;

interface PlayerScoreStatusProps {
  score: number;
  isLightSide: boolean;
}

const PlayerScoreStatus = ({ isLightSide, score }: PlayerScoreStatusProps) => {
  return (
    <div className="player_score">
      {score}{" "}
      <span
        className={`player_score_name animate__animated animate__heartBeat pixelify_font_family ${
          isLightSide ? "nerdfy_green" : "nerdfy_red"
        }`}
      >
        p
      </span>
    </div>
  );
};

const PlayerScoreWidget = () => {
  const user = useSelector(s.user());
  const player = useSelector(s.player());

  return (
    <SubHeaderWrapper>
      <PlayerStatusWrapper className="pixelify_font_family">
        <PlayerIconHandler genre={user.genre} />

        <div className="player_score_wrapper">
          <span className="separator">[</span>
          <span
            className={`${
              player.degree.lightSide ? "nerdfy_green" : "nerdfy_red"
            }`}
          >
            {player.degree && player.degree.name ? `${player.degree.name}` : ""}
          </span>

          <span className="separator">]</span>

          <PlayerScoreStatus
            score={player.score}
            isLightSide={player.degree.lightSide}
          />
        </div>
      </PlayerStatusWrapper>
    </SubHeaderWrapper>
  );
};

export default PlayerScoreWidget;
