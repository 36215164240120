import React, { useState } from "react";

// components
import FormHeader from "components/form/FormHeader";
import NerdXFooter from "components/presentations/spaceship/NerdXFooter";
import SelectSpaceship from "components/presentations/spaceship/SelectSpaceship";

// enums
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

interface SelectSpaceshipWrapperProps {
  goForward(): void;
  onSelect(color: SpaceshipColorType): void;
  goBack?(): void;
  text?: React.ReactNode;
}

const SelectSpaceshipWrapper = ({
  text,
  goBack,
  onSelect,
  goForward,
}: SelectSpaceshipWrapperProps) => {
  const [isDisabled, setDisabled] = useState(true);

  function select(color: SpaceshipColorType) {
    setDisabled(false);
    setTimeout(() => onSelect(color));
  }

  return (
    <div className="d_flex_column_1">
      <FormHeader
        proceed={goForward}
        disabled={isDisabled}
        brand={!!goBack ? false : true}
        back={!!goBack ? goBack : undefined}
      />

      <SelectSpaceship text={text} onSelect={select} />
      <NerdXFooter />
    </div>
  );
};

export default SelectSpaceshipWrapper;
