// interfaces
import { ShopQuestionFirebaseProps } from "interfaces/shopFirebase";

class ShopQuestionDataFirebaseEntity {
  private readonly _id: number;
  private readonly _itemId: number;

  constructor({ id, itemId }: { id: number; itemId: number }) {
    this._id = id;
    this._itemId = itemId;
  }

  get readValues(): ShopQuestionFirebaseProps {
    return {
      read: true,
      id: this._id,
      itemId: this._itemId,
    };
  }
}

export default ShopQuestionDataFirebaseEntity;
