// components
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import c from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div<{ width: string; height: string; color: string }>`
  font-family: "Pixelify Sans", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  opacity: 0.7;
  background: ${c.dark.bg};

  > .go_to {
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 12px;
    margin: 0;
    text-align: right;
    opacity: 0.6;
    font-size: 1.5em;
    color: ${c.pink};

    .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .encyclopedia {
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        text-align: center;

        p {
          all: unset;
        }
      }
    }
  }

  > .description {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: 1.5em;
    margin: 0;
    text-align: right;
    opacity: 0.6;
  }

  > .content {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    z-index: 9999;

    > .button_ripple_effect {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-radius: 0;
      font-size: 2em;
      border: 2px dashed ${(props) => props.color};

      span {
        text-align: center;
      }
    }
  }
`;

interface PressButtonProps {
  title: string;
  onClick: () => void;
  width?: string;
  height?: string;
  color?: string;
  goTo?: JSX.Element;
  description?: JSX.Element;
}

const PressButton = ({
  goTo,
  title,
  onClick,
  description,
  width = "90%",
  height = "250px",
  color = c.yellow,
}: PressButtonProps) => {
  function handleOnClick() {
    setTimeout(onClick, 250);
  }

  return (
    <Wrapper {...{ width, height, color }}>
      {goTo}
      {description}

      <div className="content blink no-swipe">
        <ButtonRippleEffect
          color={color}
          onClick={handleOnClick}
          className="button_ripple_effect"
        >
          <span>{title}</span>
        </ButtonRippleEffect>
      </div>
    </Wrapper>
  );
};

export default PressButton;
