// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { invadersActions } from "redux/slices/spaceInvaders/invadersSlice";

// utils
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

const invadersMiddleware = createListenerMiddleware();

invadersMiddleware.startListening({
  actionCreator: invadersActions.async.add,
  effect: async ({ payload }, listenerApi) => {
    const { length, subtype, isMad } = payload;
    const Invader = spaceInvaderUtils.getEntity(subtype);
    const invader = new Invader({ x: 0, y: 0 }).toJson();

    listenerApi.dispatch(invadersActions.add({ ...invader, isMad, length }));
  },
});

export default invadersMiddleware;
