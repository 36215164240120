// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";
import { invadersFriendlyActions } from "redux/slices/spaceInvaders/invadersFriendlySlice";

// entities
import {
  PlayerCheckpointDataFirebaseEntity,
  PlayerCheckpointInvaderOneDataFirebaseEntity,
  PlayerCheckpointInvaderTwoDataFirebaseEntity,
  PlayerCheckpointInvaderSixDataFirebaseEntity,
  PlayerCheckpointInvaderFourDataFirebaseEntity,
  PlayerCheckpointInvaderFiveDataFirebaseEntity,
  PlayerCheckpointInvaderThreeDataFirebaseEntity,
  PlayerCheckpointInvaderSevenDataFirebaseEntity,
} from "entities/data/PlayerCheckpointDataFirebaseEntity";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";

// interfaces
import { InvaderProps } from "interfaces/spaceInvaders/invader";
import { InvadersCommonProps } from "handlers/invaders/InvadersHandler";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";
// import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";
import { FlyingObjectRefProps } from "interfaces/spaceInvaders/flyingObject";

// constants
import {
  INVADER_WIDTH,
  INVADER_MARGIN_X,
  INVADER_HIDE_TOP,
  INVADER_MOVEMENT_DELAY_SPEED,
  INVADER_REMAINING_HIDE_BOTTOM,
} from "handlers/invaders/InvadersHandler";

// factories
import { InvaderContainerFactory } from "factories/spaceInvaders/InvaderFactory";

// utils
import { forever } from "async";
import isEmpty from "lodash/isEmpty";
import numberUtils from "utils/numberUtils";
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

const INVADER_SPEED = 1.6;

interface ContainerRefProps {
  list: HTMLDivElement[];
  hash: { [key: string]: boolean };
}

const InvadersFriendly = ({
  paused,
  freezedRef,
  createEls,
  wrapperRef,
  clearInvaders,
  getTopPosition,
  removeInvaders,
  removeContainer,
  setFirstPosition,
}: InvadersCommonProps) => {
  const dispatch = useDispatch();
  const checkpoints = useSelector(s.playerCheckpoints());
  const invaders = useSelector(s.invadersFriendly());
  const alreadyBootstrapped = useRef(false);
  const killForeverProcess = useRef(false);
  const pausedRef = useRef<boolean | null>(null);
  const invadersRef = useRef([] as FlyingObjectRefProps[]);
  const containerRef = useRef<ContainerRefProps>({ hash: {}, list: [] });
  const checkpointsRef = useRef<PlayerCheckpointsStateProps | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenPaused, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenInvaders, [invaders]);
  useEffect(listenCheckpoints, [checkpoints]);

  function bootstrap() {
    if (alreadyBootstrapped.current) return;

    const wrapper = wrapperRef.current;

    if (!wrapper || !wrapper.clientWidth || !wrapper.clientHeight) {
      setTimeout(bootstrap, 250);
      return;
    }

    alreadyBootstrapped.current = true;
    setTimeout(start);
  }

  function listenCheckpoints() {
    if (isEmpty(checkpoints)) return;
    checkpointsRef.current = checkpoints;
  }

  function listenPaused() {
    if (!alreadyBootstrapped.current) return;
    pausedRef.current = paused;

    if (pausedRef.current !== null) play();
  }

  function start() {
    dispatch(invadersFriendlyActions.async.random());
  }

  function play() {
    containerRef.current.list.forEach((container) =>
      moveForever(getTopPosition(container), container)
    );
  }

  function listenInvaders() {
    if (!wrapperRef.current) return;
    if (freezedRef.current) return;
    if (pausedRef.current) return;
    if (isEmpty(invaders)) return;

    const invadersInfo = invaders[invaders.length - 1];
    if (!invadersInfo) return;

    const container = InvaderContainerFactory();

    containerRef.current.hash[container.id] = true;
    containerRef.current.list.push(container);

    syncInvaderCheckpoint(invadersInfo.subtype);
    setFirstPosition(container);

    const top = INVADER_HIDE_TOP;
    const left = numberUtils.randomInterval(
      10,
      wrapperRef.current.clientWidth -
        invadersInfo.length * (INVADER_WIDTH + INVADER_MARGIN_X)
    );

    createEls(container, invadersInfo, top, left, add, pausedRef);
    wrapperRef.current.appendChild(container);

    setTimeout(() => moveForever(getTopPosition(container), container));
  }

  function add(object: InvaderProps, el: HTMLDivElement) {
    const Invader = spaceInvaderUtils.getEntity(object.subtype);
    invadersRef.current.push({ object: new Invader(object.position), el });
  }

  function moveForever(y: number, container: HTMLDivElement) {
    const containerId = container.id;
    const wrapper = wrapperRef.current;

    forever(
      (next: () => void) => {
        if (!wrapper) return;
        if (killForeverProcess.current) return;
        if (pausedRef.current) return;
        if (freezedRef.current) return setTimeout(next);

        y += INVADER_SPEED;
        container.style.top = `${y}px`;

        if (y >= wrapper.clientHeight + INVADER_REMAINING_HIDE_BOTTOM) {
          removeInvaders(containerId, invadersRef);
          clearInvaders(containerId, invadersRef);
          removeContainer(container, containerRef);

          return;
        }

        setTimeout(next, INVADER_MOVEMENT_DELAY_SPEED);
      },
      (_err: unknown) => {
        removeInvaders(containerId, invadersRef);
        clearInvaders(containerId, invadersRef);
        removeContainer(container, containerRef);
      }
    );
  }

  function syncInvaderCheckpoint(subtype: number) {
    if (paused) return;

    const c = checkpointsRef.current;
    const { defaultName, checkpointIndex } =
      spaceInvaderUtils.getEntity(subtype);

    if (!defaultName) return;
    if (c && c[checkpointIndex]) return;

    const EntityFirebaseData = getEntity(checkpointIndex);
    const entityFirebaseData = new EntityFirebaseData();

    alert(defaultName);
    dispatch(playerActions.async.updateCheckpoint(entityFirebaseData.values));
  }

  function getEntity(
    checkpointIndex: number
  ): new () => PlayerCheckpointDataFirebaseEntity {
    switch (checkpointIndex) {
      case PlayerCheckpointInvaderOneDataFirebaseEntity.checkpointIndex:
        return PlayerCheckpointInvaderOneDataFirebaseEntity;
      case PlayerCheckpointInvaderTwoDataFirebaseEntity.checkpointIndex:
        return PlayerCheckpointInvaderTwoDataFirebaseEntity;
      case PlayerCheckpointInvaderThreeDataFirebaseEntity.checkpointIndex:
        return PlayerCheckpointInvaderThreeDataFirebaseEntity;
      case PlayerCheckpointInvaderFourDataFirebaseEntity.checkpointIndex:
        return PlayerCheckpointInvaderFourDataFirebaseEntity;
      case PlayerCheckpointInvaderFiveDataFirebaseEntity.checkpointIndex:
        return PlayerCheckpointInvaderFiveDataFirebaseEntity;
      case PlayerCheckpointInvaderSixDataFirebaseEntity.checkpointIndex:
        return PlayerCheckpointInvaderSixDataFirebaseEntity;
      case PlayerCheckpointInvaderSevenDataFirebaseEntity.checkpointIndex:
        return PlayerCheckpointInvaderSevenDataFirebaseEntity;
      default:
        return PlayerCheckpointInvaderOneDataFirebaseEntity;
    }
  }

  function alert(name: string) {
    if (!wrapperRef.current) return;

    alertLeft(wrapperRef.current, [
      AlertEncyclopediaFactory(
        `A criatura "${name}" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  function destroyComponent() {
    killForeverProcess.current = true;

    dispatch(invadersFriendlyActions.clear());
    dispatch(invadersFriendlyActions.async.stopForever());
  }

  return null;
};

export default InvadersFriendly;
