// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
  flagColor?: string;
}

const HouseFlagPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
  flagColor = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 96 96"
    >
      <g>
        <path
          fill={color}
          d="M33 48h6v24h-6zM57 48h6v24h-6z"
          opacity="1"
          data-original="#001084"
        ></path>
        <path
          fill={color}
          d="M75 48v-6h-6v-6h-6v-6h-6v-6h-6v-6h-6v6h-6v6h-6v6h-6v6h-6v6h6v24h-6v6h54v-6h-6V48zM33 72V48h12v24zm30 0H51V48h12z"
          opacity="1"
          data-original="#8087aa"
        ></path>
        <g>
          <path
            d="M39 6h12v6H39zM33 12h6v6h-6z"
            fill={flagColor}
            opacity="1"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default HouseFlagPixelIcon;
