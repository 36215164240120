// enums
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

// utils
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

export const InvaderBulletFactory = (subtype: FlyingObjectSubType) => {
  const entity = spaceInvaderUtils.getEntity(subtype);
  const el = document.createElement("div");

  el.className = `space_invaders_invader_bullet space_invaders_invader_bullet_${entity.index} display_none`;

  return el;
};
