// icons
import CoffeeIcon from "icons/SendMessageIcon";

// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// utils
import color from "styles/color";

const IconWhoreSendMessage = ({ active }: AttentionWhoreElProps) => {
  return (
    <CoffeeIcon
      width="35"
      height="35"
      color={active ? color.white : undefined}
    />
  );
};

export default IconWhoreSendMessage;
