// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import {
  ChallengeQuizFirebaseProps,
  ChallengeQuizFirebaseUpdateProps,
} from "interfaces/challengeQuizFirebase";

class PlayerChallengeQuizFirebaseService {
  private _resource = "challengeQuizzes";
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async getCollection(
    userId: number | string,
    classRoomId: number
  ): Promise<ChallengeQuizFirebaseProps[]> {
    const path = `players/${userId}/classRooms/${classRoomId}/${this._resource}`;
    const response = await this._firebase.getByCollection(path);

    return response.docs.map((doc) => doc.data() as ChallengeQuizFirebaseProps);
  }

  update(
    id: number,
    userId: number | string,
    classRoomId: number,
    data: ChallengeQuizFirebaseUpdateProps
  ): Promise<void> {
    if (!id) return Promise.reject();
    if (!userId) return Promise.reject();
    if (!classRoomId) return Promise.reject();

    const path = `players/${userId}/classRooms/${classRoomId}/${this._resource}/${id}`;
    return this._firebase.set(path, data);
  }
}

export default PlayerChallengeQuizFirebaseService;
