// redux
import { appActions } from "redux/slices/appSlice";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { BoundClientDirectionsProps } from "interfaces/boundClient";
import { SpaceshipEntityProps } from "interfaces/spaceInvaders/spaceship";
import { StrongForceBarrierStateProps } from "interfaces/spaceInvaders/strongForceBarrier";

const spaceshipHit = createAction(
  "@async/strongForce/spaceship/hit",
  (
    spaceship: SpaceshipEntityProps,
    spaceshipPosition: { x: number; y: number },
    directions: BoundClientDirectionsProps
  ) => ({ payload: { spaceship, spaceshipPosition, directions } })
);

const initialState: StrongForceBarrierStateProps = {
  hitDirections: null,
};

export const strongForceBarrierSlice = createSlice({
  name: "strongForceBarrier",
  initialState,
  reducers: {
    hit: (state, action: PayloadAction<BoundClientDirectionsProps>) => {
      return {
        ...state,
        hitDirections: action.payload,
      };
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const strongForceBarrierActions = {
  async: {
    spaceshipHit,
  },
  ...strongForceBarrierSlice.actions,
};

export default strongForceBarrierSlice.reducer;
