// interfaces
import { StoryProps, StoryResourceProps } from "interfaces/story";

function list(list: StoryResourceProps[]): StoryProps[] {
  if (!Array.isArray(list)) return [];
  return sortByCheckpoint(list.map(map));
}

// private

function map(data: StoryResourceProps): StoryProps {
  return {
    id: data.id,
    story: data.story,
    whoType: data.whoType,
    checkpoint: data.checkpoint,
    challengeType: data.challengeType,
    challengeFlowType: data.challengeFlowType,
  };
}

function sortByCheckpoint(data: StoryResourceProps[]): StoryProps[] {
  return data.sort((a, b) => {
    if (a.checkpoint > b.checkpoint) return 1;
    if (a.checkpoint < b.checkpoint) return -1;

    return 0;
  });
}

const storyParser = {
  list,
};

export default storyParser;
