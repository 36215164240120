// interfaces
import { SpaceshipItemProps } from "interfaces/spaceInvaders/spaceshipItem";

// utils
import isEmpty from "lodash/isEmpty";

export default class PlayerSpaceshipItemToDeleteDataFirebaseEntity {
  private readonly _items: SpaceshipItemProps[];

  constructor(items: SpaceshipItemProps[]) {
    this._items = items;
  }

  get map(): string[] {
    if (isEmpty(this._items)) return [];
    return this._items.map((resource) => `shopItems.${resource.id}`);
  }
}
