// react
import { useEffect, useState } from "react";

// interfaces
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// types
import { dropZoneBorderColorType } from "components/DropZone";

// utils
import color from "styles/color";
import styled from "styled-components";
import animationUtils from "utils/animationUtils";

const Wrapper = styled.div<{ x: string; y: string }>`
  --box-shadow: none;
  --background: none;
  --background-activated: ${color.yellow};
  --background-hover: ${color.yellow};

  position: absolute;
  top: ${(props) => props.y};
  left: ${(props) => props.x};
  z-index: 1000;
  touch-action: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 60px;
  color: ${color.grey.strong};

  span {
    margin-right: 8px;
  }

  p {
    font-size: 22px;
  }
`;

export interface AttentionWhoreElProps {
  active: boolean;
  whore: AttentionWhoreProps;
}

interface AttentionWhorePropsExtended {
  whore: AttentionWhoreProps;
  active: boolean;
  clear?: boolean;
  paused?: boolean;
  draggableClassName?: string;
  droppableAcceptClassName?: string;
}

const AttentionWhore = ({
  whore,
  clear,
  active,
  paused,
  draggableClassName = "attention_whore_draggable",
  droppableAcceptClassName = "attention_whore_droppable",
}: AttentionWhorePropsExtended) => {
  const { x, y, id, WhoreEl, dropZoneBorderColor } = whore;
  const [animation, setAnimation] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAnimation, []);
  useEffect(clearAttentionWhore, [clear]);

  function clearAttentionWhore() {
    if (!clear) return;
    setAnimation("animate__zoomOut");
  }

  function handleAnimation() {
    if (!x) return;
    if (!y) return;

    setAnimation(animationUtils.getRandom());
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (e.animationName === "zoomOut") setAnimation("d_none");
  }

  function getDropZoneClassName(
    dropZoneBorderColor: dropZoneBorderColorType
  ): string {
    switch (dropZoneBorderColor) {
      case "blue":
        return "attention_whore_drop_zone_border_blue";
      case "yellow":
        return "attention_whore_drop_zone_border_yellow";
      case "red":
        return "attention_whore_drop_zone_border_red";
      case "purple":
        return "attention_whore_drop_zone_border_purple";
      case "brown":
        return "attention_whore_drop_zone_border_brown";
      case "green":
        return "attention_whore_drop_zone_border_green";
      case "orange":
        return "attention_whore_drop_zone_border_orange";
      case "grey":
        return "attention_whore_drop_zone_border_grey";
      case "white":
        return "attention_whore_drop_zone_border_white";
      case "acai":
        return "attention_whore_drop_zone_border_acai";
      case "pink":
        return "attention_whore_drop_zone_border_pink";
      case "navy":
        return "attention_whore_drop_zone_border_navy";
      case "wine":
        return "attention_whore_drop_zone_border_wine";
      default:
        return "attention_whore_drop_zone_border_yellow";
    }
  }

  return (
    <>
      {!!animation && (
        <Wrapper
          x={x}
          y={y}
          id={id}
          data-x={x}
          data-y={y}
          onAnimationEnd={onAnimationEnd}
          className={`${draggableClassName} ${droppableAcceptClassName} ion-activatable no-swipe ${
            animation && ` animate__animated ${animation} ${active && "active"}`
          } ${getDropZoneClassName(dropZoneBorderColor)}`}
        >
          <div className={`${!paused ? "animate_y_infinite" : ""}`}>
            <WhoreEl whore={whore} active={active} />
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default AttentionWhore;
