// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// enums
import { ChallengeType } from "enums/challengeEnum";

// svgs
import ArrowRightPixelSvg from "svg/ArrowRightPixelSvg";

// components
import ChallengeTryAgain from "components/challenge/ChallengeTryAgain";
import ChallengeGameOver from "components/challenge/ChallengeGameOver";
import PressButtonStart from "components/pressButtons/PressButtonStart";
import PressButtonContinue from "components/pressButtons/PressButtonContinue";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// entities
import PlayerEntity from "entities/PlayerEntity";

// utils
import color from "styles/color";

const { isTryAgain, isGameOver } = PlayerEntity;

interface ChallengeGameFlowHandlerProps {
  restart: () => void;
  paused: boolean;
  challenge: ChallengeCommonProps;
}

const goTo = (
  <div className="go_to animate__animated animate__bounceInRight">
    <div className="wrapper blink">
      <div className="encyclopedia">
        <p>Enciclopédia</p>
        <p>[deslize à esquerda]</p>
      </div>

      <ArrowRightPixelSvg color={color.yellow} width="70" height="70" />
    </div>
  </div>
);

const getDescription = (type: ChallengeType, description: string) => {
  switch (type) {
    case ChallengeType.Introduction:
      return <IntroDescription description={description} />;
    case ChallengeType.Exercise:
      return <IntroExercise description={description} />;
    case ChallengeType.Quiz:
      return <IntroQuiz description={description} />;
    default:
      return <IntroDescription description={description} />;
  }
};

const IntroDescription = ({ description }: { description: string }) => (
  <h2 className="description nerdfy_purple animate__animated animate__bounceInRight">
    {description}
  </h2>
);

const IntroExercise = ({ description }: { description: string }) => (
  <h2 className="description nerdfy_orange animate__animated animate__bounceInRight">
    {description}
  </h2>
);

const IntroQuiz = ({ description }: { description: string }) => (
  <h2 className="description nerdfy_green animate__animated animate__bounceInRight">
    {description}
  </h2>
);

const ChallengeGameFlowHandler = ({
  paused,
  restart,
  challenge,
}: ChallengeGameFlowHandlerProps) => {
  const dispatch = useDispatch();
  const player = useSelector(s.player());
  const section = useSelector(s.challengeSection());
  const { flowDone, flowInit } = challenge;

  function tryAgain() {
    unpause();
    setTimeout(() => dispatch(challengeActions.async.tryAgain()));
  }

  function unpause() {
    if (!paused) return;
    dispatch(challengeFlowActions.unpause());
  }

  return (
    <>
      {!isTryAgain(player) &&
        !isGameOver(player) &&
        paused &&
        !flowDone &&
        (flowInit ? (
          <PressButtonContinue
            goTo={goTo}
            onClick={unpause}
            description={getDescription(
              challenge.type,
              `${challenge.name} (${section.name})`
            )}
          />
        ) : (
          <PressButtonStart
            goTo={goTo}
            onClick={unpause}
            description={getDescription(
              challenge.type,
              `${challenge.name} (${section.name})`
            )}
          />
        ))}

      <ChallengeTryAgain show={isTryAgain(player)} onTryAgain={tryAgain} />
      {isGameOver(player) && <ChallengeGameOver restart={restart} />}
    </>
  );
};

export default ChallengeGameFlowHandler;
