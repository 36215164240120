// react
import { useRef, useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { flyingObjectsActions } from "redux/slices/spaceInvaders/flyingObjectsSlice";

// enums
import { FlyingObjectFrequencyType } from "enums/spaceInvaders/flyingObjectEnum";

// interfaces
import {
  FlyingObjectProps,
  FlyingObjectRefProps,
} from "interfaces/spaceInvaders/flyingObject";

// components
import { AsteroidFactory } from "factories/spaceInvaders/FlyingObjectFactory";

// utils
import { forever } from "async";
import { v4 as uuidv4 } from "uuid";
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

const FLYING_OBJECT_SPEED = 2;
const FLYING_OBJECT_MOVEMENT_DELAY_SPEED = 15;

interface FlyingObjectsProps {
  paused: boolean;
  freezedRef: { current: boolean };
  frequency: FlyingObjectFrequencyType;
  flyingObjectsRef: { current: FlyingObjectRefProps[] };
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
}

const FlyingObjects = ({
  paused,
  freezedRef,
  frequency,
  wrapperRef,
  flyingObjectsRef,
}: FlyingObjectsProps) => {
  const dispatch = useDispatch();
  const flyingObjects = useSelector(s.flyingObjects());
  const pausedRef = useRef<boolean | null>(null);
  const killForeverProcess = useRef(false);
  const alreadyBootstrapped = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenPaused, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [flyingObjects]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(play, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAsteroidRotation, [paused]);

  function listenPaused() {
    if (!alreadyBootstrapped.current) return;

    if (paused) stop();
    else if (pausedRef.current !== null) start();

    pausedRef.current = paused;
  }

  function handleAsteroidRotation() {
    flyingObjectsRef.current.forEach(({ el }) => {
      if (pausedRef.current === null) return;

      if (pausedRef.current) el.classList.remove("rotate");
      else el.classList.add("rotate");
    });
  }

  function bootstrap() {
    if (alreadyBootstrapped.current) return;

    const wrapper = wrapperRef.current;
    if (!wrapper || !wrapper.clientWidth || !wrapper.clientHeight) {
      setTimeout(bootstrap, 250);
      return;
    }

    alreadyBootstrapped.current = true;
    setTimeout(start);
  }

  function start() {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;

    const border = { height: wrapper.clientHeight, width: wrapper.clientWidth };
    dispatch(flyingObjectsActions.async.fireForever({ frequency, border }));
  }

  function stop() {
    dispatch(flyingObjectsActions.async.stopFireForever());
  }

  function listen() {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;
    if (freezedRef.current) return;
    if (pausedRef.current) return;

    const flyingObject = flyingObjects[flyingObjects.length - 1];
    if (!flyingObject) return;

    const flyingObjectEl = AsteroidFactory(uuidv4(), flyingObject.subtype);
    wrapper.appendChild(flyingObjectEl);

    setFirstPosition(flyingObject, flyingObjectEl);
    add(flyingObject, flyingObjectEl);
  }

  function play() {
    if (pausedRef.current) return;

    flyingObjectsRef.current.forEach(({ object, el }) => {
      if (el.style.display === "none") return;
      moveForever(getPosition(el), object, el);
    });
  }

  function add(object: FlyingObjectProps, el: HTMLDivElement) {
    const Entity = spaceInvaderUtils.getEntity(object.subtype);

    flyingObjectsRef.current.push({
      object: new Entity(object.position),
      el,
    });

    setTimeout(() => moveForever(getPosition(el), object, el));
  }

  function setFirstPosition(
    flyingObject: FlyingObjectProps,
    flyingObjectEl: HTMLDivElement
  ) {
    const { x, y } = flyingObject.position;

    flyingObjectEl.style.display = "block";
    flyingObjectEl.style.top = `${y}px`;
    flyingObjectEl.style.left = `${x}px`;
  }

  function moveForever(
    { left, top }: { top: number; left: number },
    flyingObject: FlyingObjectProps,
    flyingObjectEl: HTMLDivElement
  ) {
    forever(
      (next: () => void) => {
        if (!wrapperRef.current) return;
        if (killForeverProcess.current) return;
        if (pausedRef.current) return;
        if (freezedRef.current) return setTimeout(next);

        top += FLYING_OBJECT_SPEED;
        left = getLeftPosition(left, flyingObject.position.x < 0);

        flyingObjectEl.style.top = `${top}px`;
        flyingObjectEl.style.left = `${left}px`;

        if (top < wrapperRef.current.clientHeight)
          setTimeout(next, FLYING_OBJECT_MOVEMENT_DELAY_SPEED);
        else {
          remove(flyingObjectEl);
          clear();
        }
      },
      (_err: unknown) => {
        remove(flyingObjectEl);
        clear();
      }
    );
  }

  function getLeftPosition(left: number, fromLeft: boolean): number {
    return fromLeft ? left + FLYING_OBJECT_SPEED : left - FLYING_OBJECT_SPEED;
  }

  function getPosition(flyingObject: HTMLDivElement): {
    top: number;
    left: number;
  } {
    const top = Number(flyingObject.style.top.replace("px", ""));
    const left = Number(flyingObject.style.left.replace("px", ""));

    return { top, left };
  }

  function remove(flyingObjectEl: HTMLDivElement) {
    flyingObjectEl.style.display = "none";
    flyingObjectEl.remove();
  }

  function clear() {
    flyingObjectsRef.current = flyingObjectsRef.current.filter(
      ({ el }) => el.style.display !== "none"
    );
  }

  function destroyComponent() {
    killForeverProcess.current = true;
    stop();
  }

  return null;
};

export default FlyingObjects;
