// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { invadersActions } from "redux/slices/spaceInvaders/invadersSlice";
import { invaderBulletsActions } from "redux/slices/spaceInvaders/invaderBulletsSlice";

// interfaces
import { InvaderProps } from "interfaces/spaceInvaders/invader";
import { FlyingObjectRefProps } from "interfaces/spaceInvaders/flyingObject";
import {
  ContainerRefProps,
  InvadersCommonProps,
} from "handlers/invaders/InvadersHandler";

// constants
import {
  INVADER_SPEED,
  INVADER_WIDTH,
  INVADER_HEIGHT,
  INVADER_MARGIN_X,
  INVADER_HIDE_TOP,
  INVADER_HALF_WIDTH,
  INVADER_HALF_HEIGHT,
  INVADER_MOVEMENT_DELAY_SPEED,
  INVADER_REMAINING_HIDE_BOTTOM,
  RANDOM_NUMBER_TO_INVADER_APPEAR,
} from "handlers/invaders/InvadersHandler";

// components
import InvaderBullets from "components/spaceInvaders/InvaderBullets";

// enums
import { InvaderContainerFactory } from "factories/spaceInvaders/InvaderFactory";

// utils
import { forever } from "async";
import isEmpty from "lodash/isEmpty";
import numberUtils from "utils/numberUtils";
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

interface InvadersProps extends InvadersCommonProps {
  getLeftPosition(el: HTMLDivElement): number;
  hasId(el: HTMLDivElement, id: string): boolean;
  invadersRef: { current: FlyingObjectRefProps[] };
  invaderBulletsRef: { current: FlyingObjectRefProps[] };
}

const Invaders = ({
  hasId,
  paused,
  freezedRef,
  createEls,
  wrapperRef,
  invadersRef,
  clearInvaders,
  getTopPosition,
  removeInvaders,
  getLeftPosition,
  removeContainer,
  setFirstPosition,
  invaderBulletsRef,
}: InvadersProps) => {
  const dispatch = useDispatch();
  const invaders = useSelector(s.invaders());
  const pausedRef = useRef(paused);
  const fromLeftDirectionRef = useRef<{ [key: string]: boolean }>({});
  const containerRef = useRef<ContainerRefProps>({ hash: {}, list: [] });
  const killForeverProcess = useRef(false);

  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenPaused, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenInvaders, [invaders]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(play, [paused]);

  function listenPaused() {
    pausedRef.current = paused;
  }

  function play() {
    if (pausedRef.current) return;
    if (!containerRef.current) return;

    containerRef.current.list.forEach((container) => {
      moveForever(container);
      fireForever(container);
    });
  }

  function listenInvaders() {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;
    if (isEmpty(invaders)) return;
    if (freezedRef.current) return;
    if (pausedRef.current) return;

    const invadersInfo = invaders[invaders.length - 1];
    if (!invadersInfo) return;

    const container = InvaderContainerFactory();

    containerRef.current.hash[container.id] = true;
    containerRef.current.list.push(container);

    setFirstPosition(container);

    const top = INVADER_HIDE_TOP;
    const left = numberUtils.randomInterval(
      10,
      wrapper.clientWidth -
        invadersInfo.length * (INVADER_WIDTH + INVADER_MARGIN_X)
    );

    createEls(container, invadersInfo, top, left, add, pausedRef);
    wrapper.appendChild(container);

    setTimeout(() => moveForever(container));
    setTimeout(() => fireForever(container));
  }

  function moveForever(container: HTMLDivElement) {
    const containerId = container.id;
    const wrapper = wrapperRef.current;

    forever(
      (next: () => void) => {
        if (!wrapper) return;
        if (killForeverProcess.current) return;
        if (pausedRef.current) return;
        if (freezedRef.current) return setTimeout(next);

        const firstElement = container.firstElementChild as HTMLDivElement;
        const lastElement = container.lastElementChild as HTMLDivElement;

        if (!firstElement) return;
        if (!lastElement) return;

        let x = getLeftPosition(container);

        if (fromLeftDirectionRef.current[containerId]) x += INVADER_SPEED;
        else x -= INVADER_SPEED;

        container.style.left = `${x}px`;

        if (
          hasHitInitBorder(x + getLeftPosition(firstElement)) ||
          hasHitEndBorder(
            x,
            wrapper.clientWidth - getContainerWidth(lastElement)
          )
        ) {
          fromLeftDirectionRef.current[containerId] =
            !fromLeftDirectionRef.current[containerId];

          const y = getTopPosition(container) + INVADER_HEIGHT;
          container.style.top = `${y}px`;

          if (y >= wrapper.clientHeight + INVADER_REMAINING_HIDE_BOTTOM) {
            removeInvaders(containerId, invadersRef);
            clearInvaders(containerId, invadersRef);
            removeContainer(container, containerRef);

            return;
          }
        }

        setTimeout(next, INVADER_MOVEMENT_DELAY_SPEED);
      },
      (_err: unknown) => {
        removeInvaders(containerId, invadersRef);
        clearInvaders(containerId, invadersRef);
        removeContainer(container, containerRef);
      }
    );
  }

  function getContainerWidth(lastElement: HTMLDivElement): number {
    return getLeftPosition(lastElement) + INVADER_WIDTH;
  }

  function fireForever(container: HTMLDivElement) {
    const containerId = container.id;

    forever(
      (next: () => void) => {
        if (killForeverProcess.current) return;
        if (pausedRef.current) return;
        if (freezedRef.current) return setTimeout(next);
        if (!containerRef.current.hash[containerId]) return;

        const randomFire = numberUtils.randomInterval(1, 10);

        if (randomFire > RANDOM_NUMBER_TO_INVADER_APPEAR) {
          const invaders = getAvailableInvaders(containerId);
          const randomIndex = Math.floor(Math.random() * invaders.length);

          const invader = invaders[randomIndex];

          if (!invader) return;
          if (!invader.el) return;

          const { el } = invader;
          const x =
            getLeftPosition(container) +
            getLeftPosition(el) +
            INVADER_HALF_WIDTH;
          const y =
            getTopPosition(container) +
            getTopPosition(el) +
            INVADER_HALF_HEIGHT;
          const InvaderBullet = spaceInvaderUtils.getInvaderBulletByInvader(
            invader.object.subtype
          );

          dispatch(
            invaderBulletsActions.async.fire(
              new InvaderBullet({ x, y }).toJson()
            )
          );
        }

        setTimeout(next, 1000);
      },
      (_err: unknown) => {}
    );
  }

  function add(object: InvaderProps, el: HTMLDivElement) {
    const Invader = spaceInvaderUtils.getEntity(object.subtype);
    invadersRef.current.push({ object: new Invader(object.position), el });
  }

  function getAvailableInvaders(containerId: string): FlyingObjectRefProps[] {
    return invadersRef.current.filter(({ el }) => {
      if (el.style.display === "none") return false;
      if (!hasId(el, containerId)) return false;

      return true;
    });
  }

  function hasHitInitBorder(x: number): boolean {
    return x <= 0;
  }

  function hasHitEndBorder(x: number, border: number): boolean {
    return x >= border;
  }

  function destroyComponent() {
    killForeverProcess.current = true;
    dispatch(invadersActions.clear());
  }

  return (
    <InvaderBullets
      paused={paused}
      wrapper={wrapperRef.current}
      invaderBulletsRef={invaderBulletsRef}
    />
  );
};

export default Invaders;
