// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { explosionsActions } from "redux/slices/spaceInvaders/explosionsSlice";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
} from "enums/spaceInvaders/flyingObjectEnum";

// utils
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

const explosionMiddleware = createListenerMiddleware();

// explosion/add
explosionMiddleware.startListening({
  actionCreator: explosionsActions.async.add,
  effect: async ({ payload }, listenerApi) => {
    const subtype = getSubType(payload.types, payload.subtypes);
    const Entity = spaceInvaderUtils.getEntity(subtype);
    const position = { x: payload.x, y: payload.y };

    listenerApi.dispatch(
      explosionsActions.add({
        position,
        color: new Entity(position).explosionColor,
      })
    );
  },
});

// private

function getSubType(types: number[], subtypes: number[]): FlyingObjectSubType {
  if (has(types, FlyingObjectType.SpaceShip))
    return FlyingObjectSubType.SpaceShip;

  return getDifferentThan(types, subtypes, FlyingObjectType.Bullet);
}

function has(types: number[], type: FlyingObjectType): boolean {
  return types.some((item) => item === type);
}

function getDifferentThan(
  types: number[],
  subtypes: number[],
  type: FlyingObjectType
): FlyingObjectSubType {
  const index = types.findIndex((item) => item !== type);

  if (!index) return FlyingObjectSubType.NonExistent;
  return subtypes[index];
}

export default explosionMiddleware;
