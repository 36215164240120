// react
import { useRef, useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { strongForceBarrierActions } from "redux/slices/spaceInvaders/strongForceBarrierSlice";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// components
import { BulletFactory } from "factories/spaceInvaders/BulletFactory";

// entities
import BulletEntity from "entities/spaceInvaders/BulletEntity";

// interfaces
import { BulletProps } from "interfaces/spaceInvaders/bullet";
import { FlyingObjectRefProps } from "interfaces/spaceInvaders/flyingObject";

// utils
import { forever } from "async";

const BULLET_SPEED = 10;
const BULLET_MOVEMENT_DELAY_SPEED = 20;

interface BulletsProps {
  hasStrongBarrier: boolean;
  freezedRef: { current: boolean };
  wrapperEl: HTMLDivElement | null;
  bulletsRef: { current: FlyingObjectRefProps[] };
  paused?: boolean;
}

const Bullets = ({
  paused,
  freezedRef,
  wrapperEl,
  bulletsRef,
  hasStrongBarrier,
}: BulletsProps) => {
  const dispatch = useDispatch();
  const bullets = useSelector(s.bullets());
  const pausedRef = useRef(paused);
  const killForeverProcess = useRef(false);

  useEffect(() => destroyComponent, []);
  useEffect(listenPaused, [paused]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [bullets]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(play, [paused]);

  function listen() {
    if (!wrapperEl) return;
    if (freezedRef.current) return;
    if (pausedRef.current) return;

    const bullet = bullets[bullets.length - 1];
    if (!bullet) return;

    const bulletEl = BulletFactory();
    wrapperEl.appendChild(bulletEl);

    setFirstPosition(bullet, bulletEl);
    add(bullet, bulletEl);
  }

  function listenPaused() {
    pausedRef.current = paused;
  }

  function play() {
    if (paused) return;

    bulletsRef.current.forEach(({ el }) => {
      if (el.style.display === "none") return;
      moveUpForever(getTopPosition(el), el);
    });
  }

  function add(object: BulletProps, el: HTMLDivElement) {
    bulletsRef.current.push({ object: new BulletEntity(object.position), el });
    setTimeout(() => moveUpForever(getTopPosition(el), el));
  }

  function setFirstPosition(bullet: BulletProps, bulletEl: HTMLDivElement) {
    const { x, y } = bullet.position;

    bulletEl.style.display = "block";
    bulletEl.style.left = `${x}px`;
    bulletEl.style.top = `${y}px`;
  }

  function moveUpForever(top: number, bulletEl: HTMLDivElement) {
    if (!bulletEl) return;

    forever(
      (next: () => void) => {
        if (killForeverProcess.current) return;
        if (pausedRef.current) return;
        if (freezedRef.current) return setTimeout(next);

        top -= BULLET_SPEED;
        bulletEl.style.top = `${top}px`;

        if (top > getBorderTop()) setTimeout(next, BULLET_MOVEMENT_DELAY_SPEED);
        else {
          hitStrongForceBarrier(bulletEl);
          remove(bulletEl);
          clear();
        }
      },
      (_err: unknown) => {
        remove(bulletEl);
        clear();
      }
    );
  }

  function hitStrongForceBarrier(el: HTMLDivElement) {
    if (!hasStrongBarrier) return;

    const { top, left, bottom, right } = el.getBoundingClientRect();
    dispatch(strongForceBarrierActions.hit({ top, left, bottom, right }));
  }

  function getBorderTop(): number {
    return hasStrongBarrier ? SPACE_INVADERS.strongForceBarrier.top : 0;
  }

  function remove(bullet: HTMLDivElement) {
    bullet.style.display = "none";
    bullet.remove();
  }

  function clear() {
    bulletsRef.current = bulletsRef.current.filter(
      ({ el }) => el.style.display !== "none"
    );
  }

  function getTopPosition(bullet: HTMLDivElement): number {
    return Number(bullet.style.top.replace("px", ""));
  }

  function destroyComponent() {
    killForeverProcess.current = true;
  }

  return null;
};

export default Bullets;
