// redux
import { appActions } from "redux/slices/appSlice";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { InvadersInfoProps } from "interfaces/spaceInvaders/invader";

const add = createAction(
  "@async/invadersFriendly/add",
  ({
    isMad,
  }: {
    isMad?: boolean;
  } = {}) => ({
    payload: { isMad },
  })
);
const random = createAction("@async/invadersFriendly/random");
const stopForever = createAction("@async/invadersFriendly/stopForever");

const initialState: InvadersInfoProps[] = [];

export const invadersFriendlySlice = createSlice({
  name: "invadersFriendly",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<InvadersInfoProps>) => {
      return [...state, action.payload];
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const invadersFriendlyActions = {
  async: {
    add,
    random,
    stopForever,
  },
  ...invadersFriendlySlice.actions,
};

export default invadersFriendlySlice.reducer;
