// services
import RequestCoreService from "services/core/RequestCoreService";

// errors
import BadRequestError from "errors/BadRequestError";

// enums
import { UserGenreType } from "enums/userEnum";

// interfaces
import {
  UserSignProps,
  UserUpdateProps,
  UserResourceProps,
} from "interfaces/user";
import { AuthResourceProps } from "interfaces/auth";

class UserService {
  private _resource = "user";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  signIn(user: UserSignProps): Promise<{ data: AuthResourceProps }> {
    const resource = `${this._resource}/login`;
    return this._request.postWithoutAuthorization(resource, user);
  }

  signInFromDemo(
    id: string
  ): Promise<{ data: { accessToken: string; firebaseToken: string } }> {
    if (!id) return Promise.reject(new BadRequestError());
    return this._request.post(`${this._resource}/demo/login`, { id });
  }

  signUp(data: UserSignProps): Promise<{ data: AuthResourceProps }> {
    const resource = `${this._resource}`;
    return this._request.postWithoutAuthorization(resource, data);
  }

  get(): Promise<{ data: UserResourceProps }> {
    const resource = `${this._resource}`;
    return this._request.get(resource);
  }

  update(data: UserUpdateProps): Promise<unknown> {
    const resource = `${this._resource}`;
    return this._request.put(resource, data);
  }

  updateCodename({ codename }: UserUpdateProps): Promise<unknown> {
    if (!codename) return Promise.reject(new BadRequestError());

    const resource = `${this._resource}/codename`;
    return this._request.put(resource, { codename });
  }

  updateJarvisName({ jarvisName }: UserUpdateProps): Promise<unknown> {
    if (!jarvisName) return Promise.reject(new BadRequestError());

    const resource = `${this._resource}/jarvis`;
    return this._request.put(resource, { jarvisName });
  }

  updateGenre({ genre }: UserUpdateProps): Promise<unknown> {
    if (genre !== UserGenreType.Male && genre !== UserGenreType.Female)
      return Promise.reject(new BadRequestError());

    const resource = `${this._resource}/genre`;
    return this._request.put(resource, { genre });
  }

  sendEmailActivation(): Promise<unknown> {
    const resource = `${this._resource}/email-activation/send`;
    return this._request.post(resource);
  }

  activateEmail({
    signUpValidationCode,
  }: {
    signUpValidationCode: string;
  }): Promise<unknown> {
    const resource = `${this._resource}/email-activation/confirm`;
    return this._request.postWithoutAuthorization(resource, {
      signUpValidationCode,
    });
  }
}

export default UserService;
