import { useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import color from "styles/color";
import styled from "styled-components";
import ButtonDuolingo from "components/buttons/ButtonDuolingo";
import UserService from "services/UserService";
import Loading from "components/Loading";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  --ion-background-color: ${color.dark.bg};
  background: ${color.dark.bg};
  padding: 25px;

  p {
    text-align: center;
    color: ${color.grey.middle};
  }

  .purple {
    font-weight: bold;
    color: ${color.purple};
  }

  .red {
    font-weight: bold;
    color: ${color.red};
  }

  .congrats {
    color: ${color.green};
  }

  .strong {
    font-weight: bold;
    color: ${color.grey.light};
  }
`;

interface EmailActivationPageProps {
  match: {
    params: { hash: string };
  };
}

const EmailActivationPage = ({ match }: EmailActivationPageProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEmailAlreadyConfirmed, setIsEmailAlreadyConfirmed] = useState(false);

  async function confirm() {
    const signUpValidationCode = match.params.hash;

    try {
      setLoading(true);
      setError("");

      const resource = new UserService();
      await resource.activateEmail({ signUpValidationCode });

      setIsEmailAlreadyConfirmed(true);
    } catch (e) {
      setError("Ops, não foi possível confirmar o seu email...");
    } finally {
      setLoading(false);
    }
  }

  return (
    <IonPage>
      <IonContent>
        <Loading loading={loading} />

        <Content>
          <p>
            Seja bem vindo à página de{" "}
            <span className="strong">confirmação de email</span> da{" "}
            <span className="red">Nerdfy</span>, para{" "}
            <span className="strong">confirmar</span> seu email, clique no botão
            abaixo.
          </p>

          <ButtonDuolingo onClick={confirm} disabled={isEmailAlreadyConfirmed}>
            Confirmar meu email
          </ButtonDuolingo>

          {isEmailAlreadyConfirmed && (
            <p className="congrats">Seu email foi confirmado com sucesso ;)</p>
          )}

          {error && <ErrorFeedback err={error} />}
        </Content>
      </IonContent>
    </IonPage>
  );
};

export default EmailActivationPage;
