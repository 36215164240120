// react
import { useState, useRef, useLayoutEffect } from "react";

// redux
import { useSelector } from "react-redux";

// redux
import { selector as s } from "redux/selectors";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// components
import SpaceshipHealthStatus from "components/SpaceshipHealthStatus";
import SpaceshipMachineGunBullets from "components/spaceInvaders/SpaceshipMachineGun";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  height: 45px;
  width: 100%;
  margin: 0;
  padding: 0 15px;

  ul {
    all: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
`;

interface ChallengePlayerStatusBarProps {
  challenge: ChallengeCommonProps;
}

const SpaceshipStatusBar = ({ challenge }: ChallengePlayerStatusBarProps) => {
  const player = useSelector(s.player());
  const spaceshipFeatures = useSelector(s.spaceshipFeatures());
  const gameOverFlagRef = useRef(false);
  const [lifeAnimation, setLifeAnimation] = useState(false);
  const { id, flowDone, flowInit, gameOver } = challenge;

  useLayoutEffect(handleGameOverFlag, [gameOver]);
  useLayoutEffect(handleLifeAnimationAfterGameOver, [gameOver]);
  useLayoutEffect(handleLifeAnimation, [id, flowDone, flowInit]);

  function handleLifeAnimation() {
    if (!id) return;
    if (flowDone) return;
    if (!flowInit) return;

    setTimeout(() => setLifeAnimation(true), 300);
  }

  function onLifeAnimationEnd() {
    setLifeAnimation(false);
  }

  function handleLifeAnimationAfterGameOver() {
    if (gameOver) return;
    if (!gameOverFlagRef.current) return;

    setTimeout(() => {
      setLifeAnimation(true);
      gameOverFlagRef.current = false;
    }, 500);
  }

  function handleGameOverFlag() {
    if (!gameOver) return;
    gameOverFlagRef.current = true;
  }

  return (
    <Wrapper>
      <ul>
        <li>
          <SpaceshipHealthStatus
            icon
            hp={player.hp}
            loseLifeAnimation
            life={player.life}
            heartBeatAnimation={lifeAnimation}
            onHeartBeatAnimationEnd={onLifeAnimationEnd}
          />
        </li>

        <li>
          <SpaceshipMachineGunBullets
            bulletsTotal={spaceshipFeatures.bullets}
          />
        </li>
      </ul>
    </Wrapper>
  );
};

export default SpaceshipStatusBar;
