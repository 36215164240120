// react
import { useRef, useState } from "react";

// redux
import { useDispatch } from "react-redux";
import { authActions } from "redux/slices/authSlice";
import { playerActions } from "redux/slices/playerSlice";
// import { spaceshipFeaturesActions } from "redux/slices/spaceInvaders/spaceshipFeaturesSlice";

// ionic-react
import { IonRippleEffect } from "@ionic/react";

// components
import Modal from "components/Modal";
import Footer from "components/Footer";
import Loading from "components/Loading";
import SignIn from "components/sign/SignIn";
import SignUp from "components/sign/SignUp";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import ButtonDuolingo from "components/buttons/ButtonDuolingo";
import Customization from "components/presentations/Customization";

// interfaces
import { AuthProps } from "interfaces/auth";
import { UserSignProps } from "interfaces/user";
import { AuthResourceProps } from "interfaces/auth";
import { DataCacheProps } from "components/presentations/Customization";

// services
import UserService from "services/UserService";
import FirebaseAuthService from "services/auth/FirebaseAuthService";

// entities
import { PlayerCheckpointAstrogildoDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";
import { PlayerCheckpointCompanyNerdXDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";
import { PlayerCheckpointCompanyNerdflixDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";
import { PlayerCheckpointCivilizationNerdDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";

// Swiper
import { IonicSlides } from "@ionic/react";
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// firebase
import { UserCredential } from "firebase/auth";

// parsers
import authParser from "parsers/authParser";
// import spaceshipFeaturesParser from "parsers/spaceInvaders/spaceshipFeaturesParser";

// utils
import color from "styles/color";
import styled from "styled-components";
import authUtils from "utils/authUtils";
import errorUtils from "utils/errorUtils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};

  .game_mode_button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    p {
      font-size: 22px;
      padding: 0 20px;
      color: ${color.grey.strong};

      span.strong {
        color: ${color.grey.middle};
      }
    }

    &.campaign_mode_button {
      display: flex;
      flex-direction: column;
    }

    &.fast_game_mode_button {
      display: flex;
      flex-direction: column;

      .mode_title {
        color: ${color.red};
      }
    }
  }

  .sign {
    .submit_button {
      margin-top: 15px;
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
    }

    .body {
      flex: 1;
      padding: 0 30px;

      .feedback {
        margin-top: 20px;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0;
      height: 100px;

      p {
        all: unset;
        color: ${color.grey.strong};
      }
    }
  }
`;

const SIGN_GAME_MODES = 0;
const SIGN_IN_SLIDE = 1;
const SIGN_UP_SLIDE = 2;

interface SignModesProps {
  close(): void;
  isOpen: boolean;
}

const Sign = ({ isOpen, close }: SignModesProps) => {
  const dispatch = useDispatch();
  const signInBindedRef = useRef<(data: DataCacheProps) => void | undefined>();
  const swiperRef = useRef<SwiperProps | undefined>();
  const [danger, setDanger] = useState("");
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState<AuthProps | undefined>();
  const [disableAllSignInInputs, setDisableAllSignInInputs] = useState(true);
  const [disableAllSignUpInputs, setDisableAllSignUpInputs] = useState(true);
  const [isDemoPresentation, setIsDemoPresentation] = useState(false);

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  function goToGameModes() {
    setDanger("");
    setDisableAllSignInInputs(true);
    setDisableAllSignUpInputs(true);

    slideTo(SIGN_GAME_MODES);
  }

  function goToSignIn() {
    setDanger("");
    setDisableAllSignInInputs(false);
    setDisableAllSignUpInputs(true);

    slideTo(SIGN_IN_SLIDE);
  }

  function goToSignUp() {
    setDanger("");
    setDisableAllSignInInputs(true);
    setDisableAllSignUpInputs(false);

    slideTo(SIGN_UP_SLIDE);
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  async function signInAnonymously() {
    try {
      setLoading(true);
      setDanger("");

      const resource = new FirebaseAuthService();
      const { user }: UserCredential = await resource.signInAnonymously();
      const accessToken = await user.getIdToken();
      authUtils.setAccessToken(accessToken);

      setIsDemoPresentation(true);
      completeRegistration(authParser.fake(user.uid));
    } finally {
      setLoading(false);
    }
  }

  async function signIn({ email, password }: UserSignProps) {
    try {
      setLoading(true);
      setDanger("");

      const resource = new UserService();
      const firebaseAuth = new FirebaseAuthService();
      const response = await resource.signIn({ email, password });
      const auth: AuthResourceProps = response.data;

      const authMapped = authParser.map(auth);
      authUtils.setAccessToken(auth.accessToken);

      await firebaseAuth.signIn(auth.firebaseToken);

      if (auth.user.codename && auth.user.genre) {
        dispatch(authActions.set(authMapped));
        setTimeout(close);

        return;
      }

      completeRegistration(authMapped);
    } catch (error: unknown) {
      const { message } = errorUtils.handle.auth(error);
      setDanger(message);
    } finally {
      setLoading(false);
    }
  }

  async function signUp({ email, password }: UserSignProps) {
    try {
      setLoading(true);
      setDanger("");

      const resource = new UserService();
      const firebaseAuth = new FirebaseAuthService();
      const response = await resource.signUp({ email, password });
      const auth: AuthResourceProps = response.data;
      const authMapped = authParser.map(auth);

      authUtils.setAccessToken(auth.accessToken);
      await firebaseAuth.signIn(auth.firebaseToken);

      completeRegistration(authMapped);
    } catch (error: unknown) {
      const { message } = errorUtils.handle.auth(error);
      setDanger(message);
    } finally {
      setLoading(false);
    }
  }

  function completeRegistration(auth: AuthProps) {
    signInBindedRef.current = (data: DataCacheProps) => {
      const { codename, genre } = data;
      // const spaceshipFeatures = spaceshipFeaturesParser.mapCached(data);
      const authMapped = { ...auth, user: { ...auth.user, codename, genre } };

      dispatch(authActions.set(authMapped));
      dispatch(playerActions.async.addInitialDefaultItems());
      // dispatch(spaceshipFeaturesActions.update(spaceshipFeatures));

      syncCheckpoints();
    };

    setTimeout(() => setAuth(auth), 300);
  }

  function syncCheckpoints() {
    const entity = new PlayerCheckpointAstrogildoDataFirebaseEntity();
    const entityNerdX = new PlayerCheckpointCompanyNerdXDataFirebaseEntity();
    const entityNerdflix =
      new PlayerCheckpointCompanyNerdflixDataFirebaseEntity();
    const entityNerds =
      new PlayerCheckpointCivilizationNerdDataFirebaseEntity();

    dispatch(
      playerActions.async.updateCheckpoint({
        ...entity.values,
        ...entityNerds.values,
        ...entityNerdX.values,
        ...entityNerdflix.values,
      })
    );
  }

  function dismissModal() {
    setDanger("");
    setAuth(undefined);
    setIsDemoPresentation(false);
    setDisableAllSignInInputs(true);
    setDisableAllSignUpInputs(true);

    close();
  }

  return (
    <Modal isOpen={isOpen} onDidDismiss={dismissModal}>
      <Content>
        <Loading loading={loading} />

        <Swiper
          slidesPerView={1}
          initialSlide={0}
          onSwiper={handleSwiper}
          modules={[IonicSlides]}
          noSwipingClass="no-swipe"
        >
          <SwiperSlide>
            <div className="slide no-swipe">
              <FormHeader brand close={dismissModal} />

              <div
                className="game_mode_button campaign_mode_button ion-activatable"
                onClick={goToSignIn}
              >
                <IonRippleEffect style={{ color: color.yellow }} />
                <h2 className="bg_nerdfy_gradient blink">Campanha</h2>

                <p>
                  Comece ou continue com a{" "}
                  <span className="nerdfy_purple">Jornada do Mestre</span>
                  <span className="bg_nerdfy_gradient">...</span> Tenha uma{" "}
                  <span className="nerdfy_green">experiência completa</span>
                  ... Tudo <span className="strong">salvo</span> no nome do seu{" "}
                  <span className="strong">jogador</span>...
                </p>
              </div>

              <div
                className="game_mode_button fast_game_mode_button ion-activatable"
                onClick={signInAnonymously}
              >
                <IonRippleEffect style={{ color: color.red }} />
                <h2 className="mode_title blink">Demo</h2>

                <p>
                  Login <span className="strong">anônimo</span>, rápido, ideal
                  para quem quer apenas conhecer... Os dados são{" "}
                  <span className="strong">apagados</span> automaticamente...
                </p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide sign no-swipe">
              <FormHeader close={dismissModal} back={goToGameModes} />

              <div className="header">
                <h2 className="bg_nerdfy_gradient">Continuar Jornada</h2>
              </div>

              <div className="body">
                <SignIn
                  onSignedIn={signIn}
                  disabledInputs={disableAllSignInInputs}
                />

                <div className="feedback">
                  {danger && <ErrorFeedback err={danger} />}
                </div>
              </div>

              <div className="footer">
                <p>Não tem conta?</p>

                <ButtonDuolingo color={color.green} onClick={goToSignUp}>
                  Criar Conta
                </ButtonDuolingo>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide sign no-swipe">
              <FormHeader close={dismissModal} back={goToSignIn} />

              <div className="header">
                <h2 className="bg_nerdfy_gradient pixelify_font_family">
                  Criar Conta
                </h2>
              </div>

              <div className="body">
                <SignUp
                  onSignedUp={signUp}
                  disabledInputs={disableAllSignUpInputs}
                />

                <div className="feedback">
                  {danger && <ErrorFeedback err={danger} />}
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        {auth && auth.user && (
          <Customization
            user={auth.user}
            close={dismissModal}
            demo={isDemoPresentation}
            finished={signInBindedRef.current}
          />
        )}

        <Footer />
      </Content>
    </Modal>
  );
};

export default Sign;
