// components
import PressButtonGameOver from "components/pressButtons/PressButtonGameOver";

// utils
import styled from "styled-components";
import color from "styles/color";

const GameOverBitch = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  z-index: 9001;
  opacity: 0.7;
`;

interface ChallengeTryAgainProps {
  restart(): void;
}

const ChallengeGameOver = ({ restart }: ChallengeTryAgainProps) => {
  return (
    <GameOverBitch className="animate__animated animate__bounceInUp no-swipe">
      <PressButtonGameOver onClick={restart} />
    </GameOverBitch>
  );
};

export default ChallengeGameOver;
