import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

export {
  faTimes,
  faStar,
  faPlus,
  faStarHalf,
  faHandPointRight,
  faBugs,
  faDragon,
  faCheck,
  faGlasses,
  faHourglassHalf,
  faClock,
  faChevronDown,
  faChevronRight,
  faArrowLeft,
  faInfo,
  faMinus,
  faThumbsUp,
  faThumbsDown,
  faExclamation,
  faAward,
  faStarHalfAlt,
  faSmileBeam,
  faRedoAlt,
  faArrowRight,
  faChevronLeft,
  faHeart,
  faTerminal,
  faMicrochip,
  faFileCode,
  faSortDown,
  faMars,
  faVenus,
  faCrosshairs,
  faPause,
  faPlay,
  faComment,
  faBars,
  faBullseye,
  faLocationCrosshairs,
} from "@fortawesome/free-solid-svg-icons";

export {
  faSmileBeam as farFaceSmileBeam,
  faRegistered as farRegistered,
  faCommentDots as farCommentDots,
  faClock as farClock,
  faCopyright as farCopyright,
  faFileCode as farFileCode,
  faThumbsUp as farThumbsUp,
  faThumbsDown as farThumbsDown,
  faStar as farStar,
  faHeart as farHeart,
  faComment as farComment,
} from "@fortawesome/free-regular-svg-icons";

interface Props extends FontAwesomeIconProps {
  icon: any;
}

const Icon = (props: Props) => {
  return <FontAwesomeIcon {...props} />;
};

export default Icon;
