// react
import { useState, useEffect, useRef } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import Loading from "components/Loading";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import SpaceshipFeatures from "components/presentations/spaceship/SpaceshipFeatures";

// interfaces
import { DataCacheProps } from "components/presentations/Customization";
import { SpaceshipFeaturesProps } from "interfaces/spaceInvaders/spaceshipFeatures";

// components
import { ContentStyled } from "components/presentations/Customization";

// services
import PlayerSpaceshipDataFirebaseService from "services/firebase/player/PlayerSpaceshipDataFirebaseService";

// interfaces
import { UserProps } from "interfaces/user";

// utils
import isEmpty from "lodash/isEmpty";

interface JarvisCustomizationProps {
  jarvisTotalPoints: number;
  finished(data: DataCacheProps): void;
}

const LevelUp = ({ finished, jarvisTotalPoints }: JarvisCustomizationProps) => {
  const user = useSelector(s.user());
  const spaceshipFeatures = useSelector(s.spaceshipFeatures());
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const cacheRef = useRef<DataCacheProps | undefined>();
  const [jarvisPoints, setJarvisPoints] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [animation, setAnimation] = useState("animate__bounceInUp");

  useEffect(listenToSpaceshipFeatures, [user, spaceshipFeatures]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleJarvisPoints, [spaceshipFeatures, jarvisTotalPoints]);

  function handleJarvisPoints() {
    if (!jarvisTotalPoints) return;
    if (isEmpty(spaceshipFeatures)) return;

    const { bulletsRaw, reloadRaw, radarRaw } = spaceshipFeatures;
    setJarvisPoints(jarvisTotalPoints - (bulletsRaw + reloadRaw + radarRaw));
  }

  function listenToSpaceshipFeatures() {
    if (!user) return;
    if (!spaceshipFeatures) return;

    cacheRef.current = mapCache(user, spaceshipFeatures);
  }

  function mapCache(
    user: UserProps,
    spaceshipFeatures: SpaceshipFeaturesProps
  ): DataCacheProps {
    return {
      genre: user.genre,
      codename: user.codename,
      reload: spaceshipFeatures.reloadRaw,
      radar: spaceshipFeatures.radarRaw,
      bullets: spaceshipFeatures.bulletsRaw,
      spaceshipColor: spaceshipFeatures.colorRaw,
      spaceshipEngraving: spaceshipFeatures.engravingRaw,
    };
  }

  async function save() {
    if (isSaved) return;
    if (!user.id) return;
    if (!cacheRef.current) return;

    try {
      setLoading(true);
      setError("");

      const resource = new PlayerSpaceshipDataFirebaseService();
      await resource.update(user.id, map(cacheRef.current));

      setIsSaved(true);
      finished(cacheRef.current as DataCacheProps);
      setTimeout(() => setAnimation("animate__bounceOutLeft"));
    } catch (_err: unknown) {
      setError("Ops, ocorreu um erro...");
    } finally {
      setLoading(false);
    }
  }

  function map(data: DataCacheProps): {
    bullets: number;
    reload: number;
    radar: number;
  } {
    return {
      bullets: data.bullets,
      reload: data.reload,
      radar: data.radar,
    };
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  return (
    <ContentStyled
      className={`animate__animated no-swipe ${animation}`}
      onAnimationEnd={onAnimationEnd}
    >
      <Loading loading={loading} />

      <FormHeader
        brand
        save={save}
        disabledBack={isSaved}
        disabled={jarvisPoints !== 0 || isSaved}
      />

      {/* <div className="body">
        
      </div> */}

      {cacheRef.current && (
        <SpaceshipFeatures
          isSaved={isSaved}
          cache={cacheRef.current}
          jarvisPoints={jarvisPoints}
          setJarvisPoints={setJarvisPoints}
          jarvisTotalPoints={jarvisTotalPoints}
        />
      )}

      {error && <ErrorFeedback err={error} />}
    </ContentStyled>
  );
};

export default LevelUp;
