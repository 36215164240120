// interfaces
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";
import { SpaceshipResourcesSourceDataProps } from "interfaces/spaceInvaders/spaceshipResources";

type ResourcesToShiftProps = {
  resourcesToShift: SpaceshipResourcesUpdateFirebaseProps;
  resourcesNotAbleToShift: SpaceshipResourcesUpdateFirebaseProps;
};

export default class SpaceshipResourcesToShiftEntity {
  readonly refineMachineCapacity: number;
  readonly resources: SpaceshipResourcesUpdateFirebaseProps;

  constructor(
    refineMachineCapacity: number,
    resources: SpaceshipResourcesUpdateFirebaseProps
  ) {
    this.resources = resources;
    this.refineMachineCapacity = refineMachineCapacity;
  }

  get map(): ResourcesToShiftProps {
    let spots = this.countRemainingSpots();

    return Object.keys(this.resources).reduce(
      (acc, resource: string) => {
        const r = resource as keyof SpaceshipResourcesUpdateFirebaseProps;
        const hash = this.resources[r] as {
          [key: string]: SpaceshipResourcesSourceDataProps;
        };

        Object.keys(hash).reduce((acc, key) => {
          const data = hash[key] as SpaceshipResourcesSourceDataProps;

          if (data.transformed) return acc;

          if (data.turns > 0)
            acc.resourcesToShift[r] = {
              ...acc.resourcesToShift[r],
              [key]: data,
            };
          else if (spots > 0) {
            spots -= 1;

            acc.resourcesToShift[r] = {
              ...acc.resourcesToShift[r],
              [key]: data,
            };
          } else {
            acc.resourcesNotAbleToShift[r] = {
              ...acc.resourcesNotAbleToShift[r],
              [key]: data,
            };
          }

          return acc;
        }, acc);

        return acc;
      },
      {
        resourcesToShift: {},
        resourcesNotAbleToShift: {},
      } as ResourcesToShiftProps
    );
  }

  private countRemainingSpots(): number {
    if (this.refineMachineCapacity <= 0) return 0;

    const spots = Object.keys(this.resources).reduce(
      (acc, resource: string) => {
        const r = resource as keyof SpaceshipResourcesUpdateFirebaseProps;
        const hash = this.resources[r] as {
          [key: string]: SpaceshipResourcesSourceDataProps;
        };

        return Object.keys(hash).reduce((acc, key) => {
          const data = hash[key] as SpaceshipResourcesSourceDataProps;
          if (data.turns > 0 && !data.transformed) acc -= 1;

          return acc;
        }, acc);
      },
      this.refineMachineCapacity
    );

    return spots < 0 ? 0 : spots;
  }
}
