// react
import { useState } from "react";
import { IonTextarea, IonItem, IonLabel } from "@ionic/react";

// languages
import language from "languages/language";

// interfaces
import { PlayerProps } from "interfaces/player";

// components
import Board from "components/Board";
import Footer from "components/Footer";
import Loading from "components/Loading";
import StarRating from "components/StarRating";
import Confetti from "components/Celebration";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import SuccessFeedback from "components/feedbacks/SuccessFeedback";

// utils
import color from "styles/color";
import styled from "styled-components";
import HtmlParser from "html-react-parser";

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 6px;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  width: 100%;
  z-index: 9001;

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 20px;

    .content {
      padding: 20px;

      p {
        padding: 0;
        margin: 0;
        line-height: 1.5;
        color: ${color.grey.strong};
        font-size: 18px;
        text-align: center;
      }
    }

    .feedback_wrapper {
      width: 100%;
      margin-top: 30px;
      padding: 0 20px;
      border: none;

      .feedback_item {
        --padding-start: 0;
        --padding-top: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --border-color: ${color.grey.stronger};

        .feedback_label {
          --color: ${color.grey.middle} !important;
        }

        .feedback_textarea {
          --padding-start: 0;
          --padding-top: 20px;
          --padding-end: 0;
          --padding-bottom: 10px;
          --color: ${color.grey.middle};
        }
      }

      .feedback_counter {
        all: unset;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        color: ${color.grey.stronger};

        p {
          all: unset;
        }
      }
    }
  }
`;

interface FeedbackProps {
  message?: string;
  error?: boolean;
  success?: boolean;
}

interface ChallengeRatingProps {
  onClose(): void;
  player: PlayerProps;
  submitClassRoomRating(rating: number, message: string): void;
}

const FeedbackClassRoomRatingPresentation = ({
  player,
  onClose,
  submitClassRoomRating,
}: ChallengeRatingProps) => {
  const [rating, setRating] = useState(getRating());
  const [message, setMessage] = useState(getMessage());
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(getFeedback());
  const [animation, setAnimation] = useState("animate__bounceInUp");
  const lang = language.get();

  function getRating(): number {
    return player.rating.value || 0;
  }

  function getMessage(): string {
    return player.rating.message || "";
  }

  function onRating(rating: number) {
    setFeedback(getFeedback());
    setRating(rating);
  }

  function getFeedback({
    message = "",
    error = false,
    success = false,
  }: FeedbackProps = {}) {
    return {
      message,
      error,
      success,
    };
  }

  function onChangeMessage({ target }: any) {
    setMessage(target.value || "");
  }

  function save() {
    try {
      setLoading(true);
      submitClassRoomRating(rating, message);

      setFeedback(
        getFeedback({
          message: "Grato por sua avaliação!",
          success: true,
        })
      );

      setTimeout(close, 1000);
    } catch (e) {
      setFeedback(
        getFeedback({
          message: "Ops, ocorreu um erro ao enviar sua avaliação",
          error: true,
        })
      );

      setLoading(false);
    }
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLElement>) {
    e.stopPropagation();
    if (e.animationName === "bounceOutLeft") onClose();
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
  }

  return (
    <RatingWrapper
      onAnimationEnd={onAnimationEnd}
      className={`animate__animated no-swipe ${animation}`}
    >
      <Loading loading={loading} />
      <FormHeader save={save} close={close} disabled={!rating} />

      <div className="body">
        <div className="content">
          <Board>
            <p>{HtmlParser(lang?.rate.body || "")}</p>
          </Board>
        </div>

        <StarRating stars={rating} onRating={onRating} />

        <div className="feedback_wrapper">
          <IonItem className="feedback_item">
            <IonLabel position="floating" className="feedback_label">
              Comentário...
            </IonLabel>

            <IonTextarea
              className="feedback_textarea"
              onIonChange={onChangeMessage}
              value={message}
              inputMode="text"
              mode="ios"
              rows={1}
              autoGrow
            ></IonTextarea>
          </IonItem>

          {feedback.error && <ErrorFeedback err={feedback.message} />}
          {feedback.success && <SuccessFeedback err={feedback.message} />}
        </div>
      </div>

      {feedback.success && <Confetti />}

      <Footer />
    </RatingWrapper>
  );
};

export default FeedbackClassRoomRatingPresentation;
