import { PlayerProps } from "interfaces/player";
import { PlayerFirebaseProps, xpType } from "interfaces/playerFirebase";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";
import PlayerDegreeEntity from "entities/PlayerDegreeEntity";

// enums
import { UserGenreType } from "enums/userEnum";
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// interfaces
import { isEmpty } from "lodash";
import { PlayerSpaceshipDataProps } from "interfaces/player";

function map(player: PlayerFirebaseProps): PlayerProps {
  const score = player.score || 0;
  const degree = new PlayerDegreeEntity({
    genre: player.genre || UserGenreType.Unknown,
    score,
  }).get();

  return {
    score,
    degree,
    xp: player.xp || {},
    hp: getHp(player.hp),
    px: getPx(player.px),
    help: player.help || 0,
    life: getLife(player.life),
    missed: player.missed || 0,
    killed: player.killed || 0,
    currentGunId: player.gunId || "",
    gameOver: player.gameOver || false,
    storyCheckpoint: player.storyCheckpoint || 0,
    currentGunXp: getCurrentGunXp(player.xp, player.gunId),

    // events
    events: player.events || {},

    // quiz points
    quiz: {
      easy: player.quiz?.easy || 0,
      medium: player.quiz?.medium || 0,
      hard: player.quiz?.hard || 0,
    },

    // rating
    rating: {
      message: player.rating?.message || "",
      value: player.rating?.value || 0,
    },
  };
}

function getCurrentGunXp(xp?: xpType, currentGunId?: string): number {
  if (!xp) return 0;
  if (isEmpty(xp)) return 0;
  if (!currentGunId) return 0;

  return xp[currentGunId] || 0;
}

function mapSpaceship(player: PlayerFirebaseProps): PlayerSpaceshipDataProps {
  return {
    radar: player.spaceship?.radar || 0,
    reload: player.spaceship?.reload || 0,
    bullets: player.spaceship?.bullets || 0,
    engraving: player.spaceship?.engraving || "",
    color: player.spaceship?.color || SpaceshipColorType.None,
  };
}

function getLife(life?: number): number {
  return life === undefined ? PlayerHpEntity.lifeTotal : life;
}

function getHp(hp?: number): number {
  return hp === undefined ? PlayerHpEntity.hpTotal : hp;
}

function getPx(px?: number): number {
  return px === undefined ? 0 : px;
}

const playerParser = {
  map,
  mapSpaceship,
};

export default playerParser;
