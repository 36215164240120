// react
import React from "react";

// components
import Icon, { faPlus, faMinus } from "components/Icon";

// enums
import { VictoryNewsPointType } from "enums/victoryNewsEnum";
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

// handlers
import LevelIconHandler from "handlers/LevelIconHandler";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.white};
  z-index: 9000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 330px;

  ul {
    list-style: none;
    margin: 0;
    background: ${color.grey.dark};
    padding: 15px;
    border-radius: 10px;

    &.border_danger {
      border: 2px dashed ${color.red};
    }

    &.border_success {
      border: 2px dashed ${color.green};
    }

    li {
      margin-bottom: 10px;

      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          &.icon {
            margin-right: 8px;
            font-size: 15px;
            opacity: 0.8;
          }

          &.point {
            font-weight: bold;
            margin-left: 5px;
            font-size: 22px;
          }

          &.who {
            margin-left: 8px;
            opacity: 0.5;
            font-size: 22px;
          }
        }
      }
    }
  }
`;

export interface VictoryPointsProps {
  points: number;
  el: React.ReactNode;
  type: VictoryNewsPointType;
}

export enum VictoryNewsType {
  Danger = 1,
  Success = 2,
}

export function PxPlus({ points }: { points: number }) {
  return (
    <div className="wrapper pixelify_font_family">
      <span className="icon nerdfy_green">
        <Icon icon={faPlus} />
      </span>
      {points} <span className="point nerdfy_orange">px</span>{" "}
      <span className="who nerdfy_grey_stronger pixelify_font_family">
        (<span className="nerdfy_orange">distância</span>)
      </span>
    </div>
  );
}

export function ScorePlus({ points }: { points: number }) {
  return (
    <div className="wrapper">
      <span className="icon nerdfy_green">
        <Icon icon={faPlus} />
      </span>
      {points}{" "}
      <span className="point nerdfy_purple pixelify_font_family">s</span>{" "}
      <span className="who nerdfy_grey_stronger pixelify_font_family">
        (<span className="nerdfy_purple">você</span>)
      </span>
    </div>
  );
}

export function ScoreMinus({ points }: { points: number }) {
  return (
    <div className="wrapper">
      <span className="icon nerdfy_red">
        <Icon icon={faMinus} />
      </span>
      {Math.abs(points)}{" "}
      <span className="point nerdfy_purple pixelify_font_family">s</span>{" "}
      <span className="who nerdfy_grey_stronger pixelify_font_family">
        (<span className="nerdfy_purple">você</span>)
      </span>
    </div>
  );
}

export function LevelPointPlus({
  level,
  points,
  levelLabel,
}: {
  points: number;
  levelLabel: string;
  level: ChallengeQuizLevelType;
}) {
  return (
    <div className="wrapper">
      <span className="icon nerdfy_green">
        <Icon icon={faPlus} />
      </span>

      {points}

      <span className="point">
        <LevelIconHandler level={level} />
      </span>

      <span className="who nerdfy_grey_stronger">
        (<span className="nerdfy_green pixelify_font_family">{levelLabel}</span>
        )
      </span>
    </div>
  );
}

export function LevelPointMinus({
  level,
  points,
  levelLabel,
}: {
  points: number;
  levelLabel: string;
  level: ChallengeQuizLevelType;
}) {
  return (
    <div className="wrapper">
      <span className="icon nerdfy_red">
        <Icon icon={faMinus} />
      </span>

      {Math.abs(points)}

      <span className="point">
        <LevelIconHandler level={level} />
      </span>

      <span className="who nerdfy_grey_stronger">
        (<span className="nerdfy_green pixelify_font_family">{levelLabel}</span>
        )
      </span>
    </div>
  );
}

interface VictoryNewsProps {
  type: VictoryNewsType;
  avatar: React.ReactNode;
  points: VictoryPointsProps[];
  customClassName?: string;
}

const VictoryNews = ({
  type,
  avatar,
  points,
  customClassName,
}: VictoryNewsProps) => {
  return (
    <Wrapper className={customClassName}>
      <ul
        className={
          type === VictoryNewsType.Danger ? "border_danger" : "border_success"
        }
      >
        <li>{avatar}</li>

        {points.map((point, index) => (
          <li key={index}>{!!point.el && point.el}</li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default VictoryNews;
