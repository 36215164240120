// icons
import ChatMessageIcon from "icons/chat/ChatMessageIcon";

// components
import Bubble, { BubbleProps } from "handlers/bubble/Bubble";

// utils
import color from "styles/color";

const BubbleJarvis = ({
  hide,
  name,
  footer,
  content,
  subHeader,
  onAnimationEnd,
  finishedTyping,
}: BubbleProps) => {
  return (
    <Bubble
      show
      hide={hide}
      name={name}
      footer={footer}
      content={content}
      subHeader={subHeader}
      labelClassName="nerdfy_yellow"
      onAnimationEnd={onAnimationEnd}
      finishedTyping={finishedTyping}
      footerIcon={
        <ChatMessageIcon width="20" height="20" color={color.yellow} />
      }
    />
  );
};

export default BubbleJarvis;
