function setAccessToken(accessToken: string) {
  localStorage.setItem("AUTH_NERDFY", JSON.stringify({ accessToken }));
}

function getAccessToken(): string {
  const AUTH_NERDFY = localStorage.getItem("AUTH_NERDFY");
  if (!AUTH_NERDFY) return "";

  const auth = JSON.parse(AUTH_NERDFY);

  if (!auth) return "";
  if (!auth.accessToken) return "";

  return auth.accessToken;
}

function removeAccessToken(): void {
  localStorage.removeItem("AUTH_NERDFY");
}

function clear() {
  removeAccessToken();
}

const authUtils = {
  clear,
  setAccessToken,
  getAccessToken,
  removeAccessToken,
};

export default authUtils;
