// interfaces
import { EventProps, EventResourceProps } from "interfaces/event";

function list(list: EventResourceProps[]): EventProps[] {
  if (!Array.isArray(list)) return [];
  return sortByIndex(list.map(map));
}

// private

function map(data: EventResourceProps): EventProps {
  return {
    id: data.id,
    index: data.index,
    event: data.event,
    whoType: data.whoType,
    challengeType: data.challengeType,
    challengeFlowType: data.challengeFlowType,
  };
}

function sortByIndex(data: EventResourceProps[]): EventProps[] {
  return data.sort((a, b) => {
    if (a.index > b.index) return 1;
    if (a.index < b.index) return -1;

    return 0;
  });
}

const eventParser = {
  list,
};

export default eventParser;
