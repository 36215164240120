// redux
import {
  AnyAction,
  ThunkDispatch,
  ListenerEffectAPI,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { flyingObjectsActions } from "redux/slices/spaceInvaders/flyingObjectsSlice";

// interfaces
import { FlyingObjectProps } from "interfaces/spaceInvaders/flyingObject";

// enums
import { FlyingObjectFrequencyType } from "enums/spaceInvaders/flyingObjectEnum";

// utils
import { forever } from "async";
import { v4 as uuidv4 } from "uuid";
import numberUtils from "utils/numberUtils";
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

const flyingObjectMiddleware = createListenerMiddleware();

const EXTRA_BORDER = 50;
const TOP_MIN = 60;
const TOP_MAX = 250;
const INTERVAL_RARE = 10000;
const INTERVAL_COMMON = 5000;
const INTERVAL_OFTEN = 2500;
const paused: { [key: string]: boolean } = {};

// flyingObject/fire/forever
flyingObjectMiddleware.startListening({
  actionCreator: flyingObjectsActions.async.fireForever,
  effect: async ({ payload }, listenerApi) => {
    const { frequency, border } = payload;
    const { width, height } = border;
    const processId = uuidv4();

    paused[processId] = false;
    start(listenerApi, processId, { width, height, frequency });
  },
});

// flyingObject/stop/fire/forever
flyingObjectMiddleware.startListening({
  actionCreator: flyingObjectsActions.async.stopFireForever,
  effect: async () => {
    // kill all previous processes
    Object.keys(paused).forEach((key) => (paused[key] = true));
  },
});

// private

function start(
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >,
  processId: string,
  {
    width,
    height,
    frequency,
  }: {
    width: number;
    height: number;
    frequency: number;
  }
) {
  const interval = getFrequencyInterval(frequency);

  forever(
    (next: () => void) => {
      if (paused[processId]) return;

      listenerApi.dispatch(flyingObjectsActions.add(map({ width, height })));
      setTimeout(next, interval);
    },
    (_err: unknown) => {}
  );
}

// private

function getFrequencyInterval(frequency: number): number {
  switch (frequency) {
    case FlyingObjectFrequencyType.Rare:
      return INTERVAL_RARE;
    case FlyingObjectFrequencyType.Common:
      return INTERVAL_COMMON;
    case FlyingObjectFrequencyType.Often:
      return INTERVAL_OFTEN;
    default:
      return INTERVAL_COMMON;
  }
}

function map({
  width,
  height,
}: {
  width: number;
  height: number;
}): FlyingObjectProps {
  const position = getRandomPosition({ width, height });
  const Entity = spaceInvaderUtils.getRandomAsteroid();
  const entity = new Entity(position);

  return entity.toJson();
}

function getRandomPosition(border: { width: number; height: number }): {
  y: number;
  x: number;
} {
  return {
    y: numberUtils.randomInterval(TOP_MIN, TOP_MAX),
    x: getRandomLeftPosition(border),
  };
}

function getRandomLeftPosition(border: {
  width: number;
  height: number;
}): number {
  const random = [-EXTRA_BORDER, border.width + EXTRA_BORDER];
  return random[numberUtils.randomInterval(0, 1)];
}

export default flyingObjectMiddleware;
