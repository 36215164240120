// redux
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { ProgrammingLanguageProps } from "interfaces/programmingLanguage";

const initialState: ProgrammingLanguageProps[] = [];

export const programmingLanguagesSlice = createSlice({
  name: "programmingLanguages",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ProgrammingLanguageProps[]>) => {
      return action.payload;
    },
  },
});

export const programmingLanguagesActions = programmingLanguagesSlice.actions;
export default programmingLanguagesSlice.reducer;
