import { ApplicationErrorProps } from "interfaces/error";
export default class UnknownError extends Error {
  static errorName = "UnknownError";

  constructor(message: string = "Unknown error") {
    super(message);

    this.name = "UnknownError";
    this.message = message;
  }

  toJSON(): ApplicationErrorProps {
    return {
      data: {
        name: this.name,
        message: this.message,
        type: UnknownError.errorName,
        status: 0,
        stacktrace: this.stack,
      },
    };
  }
}
