function getTopPosition(el: HTMLDivElement): number {
  return Number(el.style.top.replace("px", ""));
}

function getLeftPosition(el: HTMLDivElement): number {
  return Number(el.style.left.replace("px", ""));
}

const domUtils = {
  getTopPosition,
  getLeftPosition,
};

export default domUtils;
