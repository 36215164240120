// time-ago
import TimeAgo from "javascript-time-ago";
import pt from "javascript-time-ago/locale/pt";

TimeAgo.addDefaultLocale(pt);

function format(date: Date) {
  const timeAgo = new TimeAgo("pt-BR");
  return timeAgo.format(date);
}

const timeAgoUtils = {
  format,
};

export default timeAgoUtils;
