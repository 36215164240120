// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
}

const MarketIcon = ({
  width = "18",
  height = "18",
  color = c.green,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M512 178.087v-77.913h-33.391V33.391h-33.392V0H66.783v33.391H33.391v66.783H0v77.913h33.391v267.13H0V512h512v-66.783h-33.391v-267.13z"
          fill="#3399ff"
          data-original="#83c9ff"
          opacity="1"
        ></path>
        <path
          d="M478.609 100.174V33.391h-33.392V0H66.783v33.391H33.391v66.783H0v77.913h33.391v33.391h55.652v-33.391h33.392v33.391h66.782v-33.391h33.392v33.391h66.782v-33.391h33.392v33.391h66.782v-33.391h33.392v33.391h55.652v-33.391H512v-77.913z"
          fill="#f14c5d"
          data-original="#fb0023"
          opacity="1"
        ></path>
        <path
          d="M122.435 0h66.783v211.478h-66.783zM322.783 0h66.783v211.478h-66.783z"
          fill="#ffffff"
          data-original="#ffffff"
        ></path>
        <path
          d="M100.174 244.87h100.174v200.348H100.174zM233.739 244.87h178.087v122.435H233.739z"
          fill="#00479b"
          data-original="#00479b"
          opacity="1"
        ></path>
        <path
          d="M478.609 445.217v-33.391H33.391v33.391H0V512h512v-66.783z"
          fill="#1b1b1b"
          data-original="#787680"
        ></path>
        <path
          d="M0 100.174h33.391v77.913H0zM89.043 133.565h33.391v44.522H89.043zM122.435 178.087h66.783v33.391h-66.783zM289.391 133.565h33.391v44.522h-33.391zM189.217 133.565h33.391v44.522h-33.391zM322.783 178.087h66.783v33.391h-66.783zM222.609 178.087h66.783v33.391h-66.783zM389.565 133.565h33.391v44.522h-33.391zM478.609 100.174H512v77.913h-33.391zM33.391 33.391h33.391v66.783H33.391zM445.217 33.391h33.391v66.783h-33.391zM66.783 0h378.435v33.391H66.783z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M478.609 178.087h-55.652v33.391h22.261v200.348H66.783V211.478h22.261v-33.391H33.391v267.13h445.217v-267.13zM33.391 478.609v-33.392H0V512h512v-66.783h-33.391v33.392z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default MarketIcon;
