interface AvatarJarvisProps {
  customClassName?: string;
  onAnimationEnd?: () => void;
}

const AvatarJarvis = ({
  onAnimationEnd,
  customClassName = "",
}: AvatarJarvisProps) => {
  return (
    <div
      className={`nerdfy_avatar nerdfy_jarvis ${customClassName}`}
      onAnimationEnd={onAnimationEnd}
    ></div>
  );
};

export default AvatarJarvis;
