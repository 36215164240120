// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import {
  SpaceshipFeaturesProps,
  SpaceshipFeaturesUpdateProps,
} from "interfaces/spaceInvaders/spaceshipFeatures";
import { PlayerSpaceshipDataProps } from "interfaces/player";

// enums
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// entities
import SpaceshipEngravingEntity from "entities/SpaceshipEngravingEntity";

const add = createAction(
  "@async/spaceshipFeatures/add",
  (features: PlayerSpaceshipDataProps) => ({
    payload: { features },
  })
);

export const initialState: SpaceshipFeaturesProps = {
  radar: 0,
  reload: 0,
  color: "",
  bullets: 0,
  engraving: SpaceshipEngravingEntity.defaultName,

  // raw values
  radarRaw: 0,
  reloadRaw: 0,
  bulletsRaw: 0,
  colorRaw: SpaceshipColorType.None,
  engravingRaw: SpaceshipEngravingEntity.defaultName,
};

export const spaceshipFeaturesSlice = createSlice({
  name: "spaceshipFeatures",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<SpaceshipFeaturesUpdateProps>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const spaceshipFeaturesActions = {
  async: {
    add,
  },
  ...spaceshipFeaturesSlice.actions,
};

export default spaceshipFeaturesSlice.reducer;
