// interfaces
import { PlayerProps } from "interfaces/player";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";

class PlayerEntity {
  static isGameOver(player: PlayerProps): boolean {
    return player.gameOver;
  }

  static isTryAgain(player: PlayerProps): boolean {
    return player.hp === PlayerHpEntity.hpKilledValue && !player.gameOver;
  }
}

export default PlayerEntity;
