// react
import { IonicSlides } from "@ionic/react";
import { useState, useCallback, useRef } from "react";

// redux
import { useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// ionic-react
import { IonInput, IonItem, IonLabel } from "@ionic/react";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import Board from "components/Board";
import Loading from "components/Loading";
import ProgressBar from "components/ProgressBar";
import FormHeader from "components/form/FormHeader";
import AvatarJarvis from "components/avatars/AvatarJarvis";
import { DropZoneAttentionWhore } from "components/DropZone";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import { CivilizationNerdFooter } from "components/presentations/CivilizationFooter";

// icons
import IconWhoreMessage from "components/iconWhores/IconWhoreMessage";

// entities
import JarvisEntity from "entities/characters/JarvisEntity";
import { PlayerCheckpointJarvisDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";
import { PlayerCheckpointCompanyNerdsoftDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";
import { PlayerCheckpointCivilizationNerdDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// enums
import { BubbleType } from "enums/bubbleEnum";

// services
import UserService from "services/UserService";

// utils
import color from "styles/color";
import interact from "interactjs";
import styled from "styled-components";
import errorUtils from "utils/errorUtils";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000;
  background: ${color.dark.bg};

  .slide {
    z-index: 9000;

    .body {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 20px;
      align-items: center;

      &.body_presentation {
        justify-content: center;
      }

      &.body_input {
        justify-content: center;
      }
    }

    .body_input_wrapper {
      padding: 0 20px;
      border: none;

      .jarvis_input {
        --color: ${color.grey.light};
      }
    }

    .ps {
      margin-top: 10px;
      margin-right: auto;

      p.jarvis_ps {
        text-align: left;
        font-size: 1em;
        color: ${color.grey.strong};
        margin: 8px 0;
        opacity: 0.6;
      }
    }
  }
`;

const Item = styled(IonItem)<{ disabled: boolean }>`
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --border-color: ${({ disabled }) => (disabled ? "#5d5325" : color.yellow)};
`;

const Label = styled(IonLabel)`
  --color: ${color.grey.light} !important;
`;

const InputName = styled(IonInput)`
  --color: ${color.grey.light};
`;

interface JarvisPresentationProps {
  codename: string;
  finishPresentation: () => void;
  wrapper: HTMLDivElement | null;
  show: boolean;
  demo?: boolean;
}

const JarvisPresentation = ({
  demo,
  show,
  wrapper,
  codename,
  finishPresentation,
}: JarvisPresentationProps) => {
  const dispatch = useDispatch();
  const swiperRef = useRef<SwiperProps | undefined>();
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const [progress, setProgress] = useState(0);
  const [isSavedName, setIsSavedName] = useState(false);
  const [animation, setAnimation] = useState("animate__bounceInUp");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [jarvisName, setJarvisName] = useState(JarvisEntity.defaultName);
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    wrapperRef.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onNameChange(e: any) {
    if (demo) return;
    setJarvisName(e.target.value);
  }

  async function save() {
    if (!jarvisName) return;

    try {
      setLoading(true);
      setError("");

      const resource = new UserService();
      await resource.updateJarvisName({ jarvisName });

      saved();
    } catch (error: unknown) {
      const { message } = errorUtils.handle.general(error);
      setError(message);
    } finally {
      setLoading(false);
    }
  }

  function saved() {
    alert();
    setError("");
    setIsSavedName(true);
    syncCheckpoints();

    setTimeout(() => {
      setWhores([first(), second(), third()]);
    }, 250);
  }

  function syncCheckpoints() {
    const entityNerdSoft =
      new PlayerCheckpointCompanyNerdsoftDataFirebaseEntity();
    const entityJarvis = new PlayerCheckpointJarvisDataFirebaseEntity(
      jarvisName
    );
    const entityNerdfy =
      new PlayerCheckpointCivilizationNerdDataFirebaseEntity();

    dispatch(
      playerActions.async.updateCheckpoint({
        ...entityJarvis.values,
        ...entityNerdfy.values,
        ...entityNerdSoft.values,
      })
    );
  }

  function isValidName(name: string): boolean {
    if (!name) return false;
    if (hasNerdfyPrefix(name)) return false;

    return !!name.match(/^[a-zA-Z][a-zA-Z0-9_]{2,16}$/);
  }

  function hasNerdfyPrefix(name: string): boolean {
    return !!name.match(/^(nerdfy)/);
  }

  // ATTENTION WHORES

  function first(): AttentionWhoreProps {
    const payload = `Olá ${codename}, seja bem-vindo! Pode me chamar pelo nome "${jarvisName}".`;
    return message(payload);
  }

  function second(): AttentionWhoreProps {
    const payload = `O espaço é grande, vazio e tedioso demais para um macaco ansioso do mundo tridimensional. Eu não apenas sei disso, como também fui especialmente projetado para ajudá-lo. Não se preocupe, você não está sozinho.`;
    return message(payload);
  }

  function third(): AttentionWhoreProps {
    const payload = `Se precisar de ajuda, basta me chamar. ["${jarvisName}" pode completar o código para você. Para isso, no momento apropriado, basta clicar nele]`;
    return message(payload, true);
  }

  function message(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapperRef.current) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreMessage,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      bubbleType: BubbleType.Message,
      wrapperWidth: wrapperRef.current.clientWidth,
      wrapperHeight: wrapperRef.current.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  // ATTENTION WHORES END

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });

    setTimeout(() => {
      attentionWhore.last && close();
    });
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
    setTimeout(() => finishPresentation());
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function isSaveDisabled(): boolean {
    if (isSavedName) return true;
    return !demo && (!isValidName(jarvisName) || loading);
  }

  function alert() {
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `O personagem "${jarvisName}" foi adicionado na sua enciclopédia`
      ),
      AlertEncyclopediaFactory(
        `A civilização "Nerdfy" foi adicionada na sua enciclopédia`
      ),
      AlertEncyclopediaFactory(
        `A empresa "Nerdsoft" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  function goToPresentation() {
    slideTo(0);
    setProgress(0);
  }

  function goToNaming() {
    slideTo(1);
    setProgress(1);
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  return (
    <>
      {show && (
        <Wrapper
          className={`animate__animated no-swipe ${animation}`}
          ref={handle}
        >
          <ProgressBar value={progress} color="success" />

          <Swiper
            initialSlide={0}
            slidesPerView={1}
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            noSwipingClass="no_swipe"
          >
            <SwiperSlide>
              <div className="slide no_swipe" onAnimationEnd={onAnimationEnd}>
                <FormHeader brand proceed={goToNaming} />

                <div className="body body_presentation">
                  <AvatarJarvis />

                  <Board>
                    <p>
                      Criado à <span className="nerdfy_orange">imagem</span> e{" "}
                      <span className="nerdfy_orange">semelhança</span> dos{" "}
                      <span className="nerdfy_yellow">nerds</span> e dono da{" "}
                      <span className="nerdfy_yellow">voz exótica</span> e{" "}
                      <span className="nerdfy_yellow">desconhecida</span> ,
                      muitos dizem que ele é apenas um{" "}
                      <span className="nerdfy_white">sistema arcaico</span> de{" "}
                      <span className="nerdfy_pink">IA</span> [não deixe ele
                      ouvir isso].
                    </p>

                    <p>
                      Sua <span className="purple">missão</span> é ajudar os{" "}
                      <span className="cmd">viajantes</span> da{" "}
                      <span className="blue">terceira dimensão</span> a{" "}
                      <span className="nerdfy_white">não</span> sofrerem com as
                      leis de <span className="red">Darwin</span> e{" "}
                      <span className="red">Murphy</span>.
                    </p>
                  </Board>
                </div>

                <CivilizationNerdFooter />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <Loading loading={loading} />

                <FormHeader
                  back={goToPresentation}
                  save={demo ? saved : save}
                  disabledBack={isSavedName}
                  disabled={isSaveDisabled()}
                />

                <div className="body body_input">
                  <AvatarJarvis />

                  <Board>
                    <p>
                      Como <span className="nerdfy_green">deseja</span> chamá-lo
                      <span className="nerdfy_yellow">?</span>
                    </p>
                  </Board>

                  <div className="body_input_wrapper">
                    <Item disabled={isSavedName || !!demo}>
                      <Label position="floating">Como deseja chamá-lo?</Label>

                      <InputName
                        type="text"
                        disabled={demo}
                        value={jarvisName}
                        className="jarvis_input"
                        onIonChange={onNameChange}
                      ></InputName>
                    </Item>
                  </div>

                  {error && <ErrorFeedback err={error} />}

                  {/* {!isValidName(jarvisName) && (
                    <div className="ps">
                      <p className="jarvis_ps">
                        * Insira entre 3-15 caracteres (letras | números |
                        underline)
                      </p>
                      <p className="jarvis_ps">
                        * O primeiro caractere deve ser uma letra
                      </p>
                      <p className="jarvis_ps">
                        * Não pode conter o prefixo "nerdfy"
                      </p>
                    </div>
                  )} */}
                </div>

                <CivilizationNerdFooter />
              </div>
            </SwiperSlide>
          </Swiper>

          <AttentionWhores
            whores={whores}
            disabledDrop={!!attentionWhore.show}
            draggableClassName="drag_whore_draggable_jarvis"
            droppableAcceptClassName="drag_whore_droppable_jarvis"
            interactDraggable={interact(".drag_whore_draggable_jarvis")}
            interactDropZone={interact(".drag_whore_dropzone_jarvis")}
          />

          <BubbleHandler
            hide={hideJarvisBubble}
            show={!!attentionWhore.show}
            content={attentionWhore.payload}
            type={attentionWhore.bubbleType}
          />

          <DropZoneAttentionWhore dropZoneClassName="drag_whore_dropzone_jarvis" />
        </Wrapper>
      )}
    </>
  );
};

export default JarvisPresentation;
