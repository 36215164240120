// enums
import { ChallengeType } from "enums/challengeEnum";

interface ConstructorInputProps {
  type: ChallengeType;
}

class ChallengeScoreEntity {
  type: ChallengeType;

  constructor({ type }: ConstructorInputProps) {
    this.type = type;
  }

  gained(): number {
    if (this.type === ChallengeType.None) return 0;
    if (this.type === ChallengeType.Introduction) return 1;
    if (this.type === ChallengeType.Exercise) return 2;

    return 0;
  }
}

export default ChallengeScoreEntity;
