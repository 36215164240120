interface SvgProps {
  width?: string;
  height?: string;
}

const WaterSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path
        fill="#84DBFF"
        d="M438.4,329.6C438.4,430.933,356.267,512,256,512S73.6,430.933,73.6,329.6S256,0,256,0
	S438.4,229.333,438.4,329.6z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M256,464c-73.6,0-134.4-59.733-134.4-134.4c0-8.533,7.467-16,16-16s16,7.467,16,16
	c0,56.533,45.867,102.4,102.4,102.4c8.533,0,16,7.467,16,16S264.533,464,256,464z"
      ></path>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default WaterSvg;
