// The max is inclusive and the min is inclusive
function randomInterval(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1) + min);
}

const numberUtils = {
  randomInterval,
};

export default numberUtils;
