// redux
import { initialState } from "redux/slices/spaceInvaders/spaceshipMachineGunSlice";

// interfaces
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { SpaceshipMachineGunProps } from "interfaces/spaceInvaders/spaceshipMachineGun";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

function map(
  player: PlayerFirebaseProps
): AllToOptional<SpaceshipMachineGunProps> {
  const { spaceship } = player;

  if (!spaceship) return fake();

  return {
    machineGun:
      spaceship.machineGun || spaceship.machineGun === 0
        ? spaceship.machineGun
        : initialState.machineGun,
  };
}

function fake(): AllToOptional<SpaceshipMachineGunProps> {
  return {
    machineGun: initialState.machineGun,
  };
}

const spaceshipMachineGunFirebaseParser = {
  map,
};

export default spaceshipMachineGunFirebaseParser;
