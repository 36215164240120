// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// enums
import { BubbleType } from "enums/bubbleEnum";

// entities
import { PlayerCheckpointEncyclopediaIntroFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";
import { PlayerCheckpointCompanyNerdBookDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// icons
import IconWhoreWizardHat from "components/iconWhores/IconWhoreWizardHat";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface PlayerProfileBubbleIntroHandlerProps {
  start: boolean;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
}

const PlayerProfileAlertsHandler = ({
  start,
  wrapperRef,
}: PlayerProfileBubbleIntroHandlerProps) => {
  const dispatch = useDispatch();
  const alreadyStarted = useRef(false);
  const alreadyNerdBookStarted = useRef(false);
  const checkpoints = useSelector(s.playerCheckpoints());
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [start, checkpoints]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAlertEncyclopedia, [start]);

  function bootstrap() {
    if (!start) return;
    if (alreadyStarted.current) return;
    if (checkpoints[PlayerCheckpointEnum.EncyclopediaBubbleIntro]) return;

    setTimeout(() => {
      setWhores([first()]);
      alreadyStarted.current = true;
    });
  }

  function handleAlertEncyclopedia() {
    if (!start) return;
    if (alreadyNerdBookStarted.current) return;
    if (checkpoints[PlayerCheckpointEnum.CompanyNerdBook]) return;

    setTimeout(() => {
      syncNerdBookCheckpoint();
      alertEncyclopediaNerdBook();

      alreadyNerdBookStarted.current = true;
    });
  }

  function first() {
    const payload = `A enciclopédia é dividida em capítulos. Conforme você avança, novos capítulos serão liberados, junto com novos personagens, novas criaturas, novos mapas e muito mais!`;
    return message(payload);
  }

  function message(payload: string, last?: boolean): AttentionWhoreProps {
    const wrapper = wrapperRef.current;
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      call: onAttentionWhoreCall,
      WhoreEl: IconWhoreWizardHat,
      dropZoneBorderColor: "blue",
      bubbleType: BubbleType.Narrator,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    syncIntroCheckpoint();
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function syncIntroCheckpoint() {
    const intro = new PlayerCheckpointEncyclopediaIntroFirebaseEntity();

    dispatch(
      playerActions.async.updateCheckpoint({
        ...intro.values,
      })
    );
  }

  function syncNerdBookCheckpoint() {
    const nerdBook = new PlayerCheckpointCompanyNerdBookDataFirebaseEntity();

    dispatch(
      playerActions.async.updateCheckpoint({
        ...nerdBook.values,
      })
    );
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  function alertEncyclopediaNerdBook() {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `A empresa "NerdBook" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  return (
    <>
      <AttentionWhores whores={whores} disabledDrop={!!attentionWhore.show} />

      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <DropZoneAttentionWhore />
    </>
  );
};

export default PlayerProfileAlertsHandler;
