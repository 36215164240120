// react
import React from "react";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  text-align: center;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    .dot {
      width: 7px;
      height: 7px;
      background: ${color.grey.light};
      border-radius: 50px;
      margin: 0 2px;
      padding: 0;
      opacity: 0.8;
      background: ${color.green};

      &.not_active {
        background: ${color.grey.middle};
      }

      &.active {
        background: ${color.blue};
      }

      &.read {
        background: ${color.blue};
        opacity: 0.3;
      }
    }
  }
`;

export interface DotProgressRefProps {
  [key: number]: boolean;
}

interface SlideDotsProgressProps {
  len: number;
  position: number;
  dotsProgress?: { [key: number]: boolean };
}

const SlideDotsProgress = ({
  len,
  position,
  dotsProgress = {},
}: SlideDotsProgressProps) => {
  return (
    <Wrapper>
      <ul>
        {Array.apply(null, Array(len)).map((_, i) => (
          <li
            className={`dot ${
              i === position
                ? "active"
                : dotsProgress[i]
                ? "read"
                : "not_active"
            }`}
            key={i}
          ></li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default React.memo(SlideDotsProgress);
