// interfaces
import { UserProps, UserResourceProps } from "interfaces/user";

// enums
import { UserGenreType } from "enums/userEnum";

// entities
import PlayerCodeNameEntity from "entities/PlayerCodeNameEntity";

function map(user: UserResourceProps): UserProps {
  return {
    id: user.id,
    email: user.email,
    name: user.name || "",
    image: user.image || "",
    languageId: user.languageId,
    givenName: user.givenName || "",
    familyName: user.familyName || "",
    genre: user.genre || UserGenreType.Unknown,
    emailActivated: !!user.emailActivated || false,
    codename: user.codename || PlayerCodeNameEntity.defaultName,
  };
}

function fake(id: string, genre?: UserGenreType): UserProps {
  return {
    id,
    name: "",
    image: "",
    email: "",
    givenName: "",
    languageId: 0,
    familyName: "",
    emailActivated: true,
    genre: genre || UserGenreType.Unknown,
    codename: PlayerCodeNameEntity.defaultName,
  };
}

const userParser = {
  map,
  fake,
};

export default userParser;
