// redux
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { ClassRoomRatingSummaryProps } from "interfaces/classRoomRating";

const initialState: ClassRoomRatingSummaryProps = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  total: 0,
  avg: 0,
};

export const classRoomRatingSummarySlice = createSlice({
  name: "classRoomRatingSummary",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ClassRoomRatingSummaryProps>) => {
      return action.payload;
    },
  },
});

export const classRoomRatingSummaryActions =
  classRoomRatingSummarySlice.actions;
export default classRoomRatingSummarySlice.reducer;
