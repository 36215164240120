// components
import PressButton from "components/pressButtons/PressButton";

// utils
import color from "styles/color";

interface PressButtonProps {
  onClick: () => void;
}

const PressButtonTryAgain = ({ onClick }: PressButtonProps) => {
  return (
    <PressButton
      onClick={onClick}
      color={color.white}
      title="Perdeu. Deseja continuar?"
    />
  );
};

export default PressButtonTryAgain;
