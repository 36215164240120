// interfaces
import { PlayerProps, PlayerQuizLevelPointsProps } from "interfaces/player";

// enums
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

interface ConstructorProps {
  level: ChallengeQuizLevelType;
  player?: PlayerProps;
}

class ChallengeQuizLevelPointsEntity {
  static minPoints = 0;
  static maxPoints = 5;
  private gainPoints = 1;
  private level: ChallengeQuizLevelType;
  private player?: PlayerProps;

  constructor({ level, player }: ConstructorProps) {
    this.level = level;
    this.player = player;
  }

  inc(): PlayerQuizLevelPointsProps {
    return this._inc(this.level);
  }

  dec(): PlayerQuizLevelPointsProps {
    return this._dec(this.level);
  }

  getLabel(): string {
    if (this.level === ChallengeQuizLevelType.Easy) return "fácil";
    if (this.level === ChallengeQuizLevelType.Medium) return "médio";
    if (this.level === ChallengeQuizLevelType.Hard) return "difícil";

    return "fácil";
  }

  gained(): number {
    const currentPoints = this.getCurrentPoints(
      this.map(this.player),
      this.level
    );

    if (currentPoints < ChallengeQuizLevelPointsEntity.maxPoints)
      return this.gainPoints;

    return 0;
  }

  lost(): number {
    const currentPoints = this.getCurrentPoints(
      this.map(this.player),
      this.level
    );

    if (
      currentPoints &&
      currentPoints < ChallengeQuizLevelPointsEntity.maxPoints
    )
      return -currentPoints;

    return 0;
  }

  private _inc(level: ChallengeQuizLevelType): PlayerQuizLevelPointsProps {
    let copy = this.map(this.player);
    const currentPoints = this.getCurrentPoints(copy, level);

    if (currentPoints < ChallengeQuizLevelPointsEntity.maxPoints)
      copy = this.updatePlayerPoints(
        copy,
        level,
        currentPoints + this.gainPoints
      );

    return copy;
  }

  private _dec(level: ChallengeQuizLevelType): PlayerQuizLevelPointsProps {
    let copy = this.map(this.player);
    const currentPoints = this.getCurrentPoints(copy, level);

    if (currentPoints < ChallengeQuizLevelPointsEntity.maxPoints)
      copy = this.updatePlayerPoints(copy, level, 0);

    return copy;
  }

  private map(player?: PlayerProps): PlayerQuizLevelPointsProps {
    if (!player) return this.mapInitialState();

    return {
      quizEasy: player.quiz.easy || 0,
      quizHard: player.quiz.hard || 0,
      quizMedium: player.quiz.medium || 0,
    };
  }

  private mapInitialState(): PlayerQuizLevelPointsProps {
    return {
      quizEasy: 0,
      quizHard: 0,
      quizMedium: 0,
    };
  }

  private getCurrentPoints(
    data: PlayerQuizLevelPointsProps,
    level: ChallengeQuizLevelType
  ): number {
    if (level === ChallengeQuizLevelType.Easy) return data.quizEasy;
    if (level === ChallengeQuizLevelType.Medium) return data.quizMedium;
    if (level === ChallengeQuizLevelType.Hard) return data.quizHard;

    return 0;
  }

  private updatePlayerPoints(
    data: PlayerQuizLevelPointsProps,
    level: ChallengeQuizLevelType,
    points: number
  ): PlayerQuizLevelPointsProps {
    const copy = { ...data };

    if (level === ChallengeQuizLevelType.Easy) copy.quizEasy = points;
    if (level === ChallengeQuizLevelType.Medium) copy.quizMedium = points;
    if (level === ChallengeQuizLevelType.Hard) copy.quizHard = points;

    return copy;
  }
}

export default ChallengeQuizLevelPointsEntity;
