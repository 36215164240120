// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { spaceshipFeaturesActions } from "redux/slices/spaceInvaders/spaceshipFeaturesSlice";

// services
import PlayerSpaceshipDataFirebaseService from "services/firebase/player/PlayerSpaceshipDataFirebaseService";

// utils
import stateUtils from "redux/utils/stateUtils";

const spaceshipFeaturesMiddleware = createListenerMiddleware();

spaceshipFeaturesMiddleware.startListening({
  actionCreator: spaceshipFeaturesActions.async.add,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);
    const { features } = payload;
    const playerSpaceshipResource = new PlayerSpaceshipDataFirebaseService();

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    playerSpaceshipResource.update(auth.user.id, features);
  },
});

// private

export default spaceshipFeaturesMiddleware;
