interface IconProps {
  width?: string;
  height?: string;
}

const StarIcon = ({ width = "18", height = "18" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M0 200.348V256h33.391v-55.652h100.174v-33.391H0zM133.565 133.565h33.391v33.391h-33.391zM33.391 256h33.391v33.391H33.391zM200.348 33.391h33.391v55.652h-33.391zM166.957 89.044h33.391v44.522h-33.391zM278.261 33.391h33.391v55.652h-33.391zM311.652 89.044h33.391v44.522h-33.391zM155.826 445.217h55.652v33.391h-55.652zM100.174 478.609V289.391H66.783V512h89.043v-33.391zM445.217 256h33.391v33.391h-33.391zM411.826 289.391v189.218h-55.652V512h89.043V289.391zM345.043 133.565h33.391v33.391h-33.391zM378.435 166.957v33.391h100.174V256H512v-89.043zM300.522 445.217h55.652v33.391h-55.652zM233.739 0h44.522v33.391h-44.522zM211.478 411.826h89.043v33.391h-89.043z"
          fill="#ffde46"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default StarIcon;
