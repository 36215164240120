// icons
import ErgoZarvoxQuizIcon from "icons/ergonians/ErgoZarvoxQuizIcon";
// components
import Bubble, { BubbleProps } from "handlers/bubble/Bubble";

const BubbleZarvoxQuiz = ({
  name,
  hide,
  footer,
  content,
  subHeader,
  finishedTyping,
  onAnimationEnd,
}: BubbleProps) => {
  return (
    <Bubble
      show
      hide={hide}
      name={name}
      footer={footer}
      content={content}
      subHeader={subHeader}
      labelClassName="nerdfy_green"
      onAnimationEnd={onAnimationEnd}
      finishedTyping={finishedTyping}
      footerIcon={<ErgoZarvoxQuizIcon width="35" height="35" />}
    />
  );
};

export default BubbleZarvoxQuiz;
