// react
import { useEffect, useRef, useState } from "react";

// interfaces
import { TagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";

// components
import ChallengeTag, {
  TagEnrichedProps,
} from "components/challenge/battle/tags/ChallengeTag";

// utils
import isEmpty from "lodash/isEmpty";
import domUtils from "utils/domUtils";
import dragUtils from "utils/dragUtils";
import animationUtils from "utils/animationUtils";

// drag
import interact from "interactjs";

interface ChallengeTagsProps {
  tags: TagProps[];
  code: ChallengeCodeProps;
  wrapper: HTMLDivElement | null;
  onSuccessTag(tag: TagProps): void;
  isTagValid(tag: TagProps): boolean;
  disabledDropRef: React.MutableRefObject<boolean>;
  onFailedTag(bounds: { left: string; top: string }): void;
  clear?: boolean;
  paused?: boolean;
}

const ChallengeTags = ({
  tags,
  code,
  clear,
  paused,
  wrapper,
  isTagValid,
  onFailedTag,
  onSuccessTag,
  disabledDropRef,
}: ChallengeTagsProps) => {
  const animationClassNameRef = useRef("");
  const interactDragRef = useRef<any>(null);
  const interactDropRef = useRef<any>(null);
  const tagsRef = useRef<TagEnrichedProps[]>([]);
  const filteredTagsRef = useRef<TagProps[]>([]);
  const tagsRemovedRef = useRef<{ [key: string]: boolean }>({});
  const [whores, setWhores] = useState<TagEnrichedProps[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  useEffect(startDrag, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startDrop, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(animateAddedTagFromAdvisor.bind(null, code.verified), [
    code.verified,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(removeAddedTagFromFilter.bind(null, code.verified), [
    code.verified,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleTags, [tags]);
  useEffect(handleFilteredTags, [tags]);

  function handleTags() {
    if (isEmpty(tags)) return;
    if (!isEmpty(tagsRef.current)) return;

    animationClassNameRef.current = animationUtils.getRandom();
    setTimeout(() => {
      tagsRef.current = tags.map((tag) => {
        const { x, y } = getRandom();
        return { ...tag, x, y };
      });

      setWhores(tagsRef.current);
    });
  }

  function handleFilteredTags() {
    filteredTagsRef.current = tags;
  }

  function removeAddedTagFromFilter(tags: TagProps[]) {
    if (isEmpty(tags)) return;

    const tag: TagProps = tags[tags.length - 1];

    if (!tag) return;
    if (!tag.value) return;

    if (tag.fromAvatar) removeTagByValue(filteredTagsRef.current, tag.value);
    else removeTagById(filteredTagsRef.current, tag.id);
  }

  function animateAddedTagFromAdvisor(tags: TagProps[]) {
    if (isEmpty(tags)) return;

    const tag: TagProps = tags[tags.length - 1];

    if (!tag) return;
    if (!tag.value) return;
    if (!tag.fromAvatar) return;

    const found = findByValue(filteredTagsRef.current, tag.value);
    if (!found) return;

    animateTag(found);
  }

  function animateTag(tag: TagProps) {
    const el = document.getElementById(String(tag.id));
    if (!el) return;

    el.classList.remove("animate_y_infinite");
    el.classList.add("animate__animated");
    el.classList.add("animate__zoomOut");
    el.addEventListener("animationend", onAnimationEnd);

    function onAnimationEnd() {
      if (!el) return;

      el.removeEventListener("animationend", onAnimationEnd);
      el.classList.remove("animate__zoomOut");
      el.classList.add("display_none");
    }
  }

  function removeTagById(tags: TagProps[], id: string) {
    filteredTagsRef.current = filterById(tags, id);
  }

  function removeTagByValue(tags: TagProps[], value: string) {
    const found = findByValue(tags, value);
    if (!found) return;

    filteredTagsRef.current = filterById(tags, found.id);
  }

  function filterById(tags: TagProps[], id: string): TagProps[] {
    return tags.filter((tag) => tag.id !== id);
  }

  function findByValue(tags: TagProps[], value: string): TagProps | undefined {
    return tags.find((tag) => tag.value === value);
  }

  function startDrag() {
    interactDragRef.current = interact(".tag_draggable").draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
        }),
      ],
      listeners: {
        move(event) {
          const { target } = event;

          const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

          target.style.top = `${y}px`;
          target.style.left = `${x}px`;

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
      },
    });
  }

  function startDrop() {
    interactDropRef.current = interact(".tag_dropzone").dropzone({
      accept: ".tag_droppable",
      overlap: 0.3,

      ondropactivate: function (event) {
        event.target.classList.add("border_dashed_grey");
      },
      ondropdeactivate: function (event) {
        event.target.classList.remove("border_dashed_grey");
      },
      ondragenter: function (event) {
        event.target.classList.add("border_dashed_green");
      },
      ondragleave: function (event) {
        event.target.classList.remove("border_dashed_green");
      },
      ondrop: function (event) {
        if (disabledDropRef.current) return;

        const { target, relatedTarget } = event;
        target.classList.remove("border_dashed_green");

        const tag = tagsRef.current.find((tag) => tag.id === relatedTarget.id);
        if (!tag) return;

        setTimeout(() => {
          if (!relatedTarget) return;

          const valid = isTagValid(tag);
          relatedTarget.classList.remove("animate_y_infinite");
          relatedTarget.addEventListener("animationend", onAnimationEnd);

          if (valid) {
            relatedTarget.classList.add("animate__animated");
            relatedTarget.classList.add("animate__zoomOut");

            onSuccessTag(tag);
            return;
          }

          relatedTarget.classList.add("animate__headShake");
          failedTag(relatedTarget);

          function onAnimationEnd() {
            if (!relatedTarget) return;
            relatedTarget.removeEventListener("animationend", onAnimationEnd);

            if (valid) tagsRemovedRef.current[relatedTarget.id] = true;
            else relatedTarget.classList.remove("animate__headShake");
          }
        });
      },
    });
  }

  function failedTag(target: HTMLDivElement) {
    setTimeout(() => {
      const left = `${domUtils.getLeftPosition(target)}px`;
      const top = `${
        domUtils.getTopPosition(target) - 5 - target.clientHeight / 2
      }px`;

      onFailedTag({ left, top });
    });
  }

  function getRandom(): { x: string; y: string } {
    if (!wrapper) return { x: "0px", y: "0px" };

    const w = { width: wrapper.clientWidth, height: wrapper.clientHeight };
    const { x, y } = dragUtils.getRandomPosition({ wrapper: w });

    return {
      x: `${x}px`,
      y: `${y}px`,
    };
  }

  function destroyComponent() {
    interactDragRef.current?.unset();
    interactDropRef.current?.unset();
  }

  if (clear) return null;

  return (
    <>
      {whores.map((tag: TagEnrichedProps) => {
        if (tagsRemovedRef.current[tag.id]) return null;

        return (
          <ChallengeTag
            tag={tag}
            key={tag.id}
            clear={clear}
            paused={paused}
            animationClassName={animationClassNameRef.current}
          />
        );
      })}
    </>
  );
};

export default ChallengeTags;
