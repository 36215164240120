// utils
import c from "styles/color";

// components
import LinearGradientEffectSvg from "svg/LinearGradientEffectSvg";

// utils
import { v4 as uuidv4 } from "uuid";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
  gradient?: boolean;
}

const ErgoPlaceholderIcon = ({
  gradient,
  width = "18",
  height = "18",
  color = c.grey.strong,
}: SvgProps) => {
  const id = uuidv4();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 60 60"
    >
      {gradient && (
        <defs>
          <LinearGradientEffectSvg id={id} />
        </defs>
      )}

      <g>
        <path
          d="M41 21a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6Zm-2 7a1 1 0 0 1-2 0v-2a1 1 0 0 1 2 0Z"
          fill={`url(#${id}) ${color}`}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M30 8C15.178 8 4 17.028 4 29c0 12.6 12 28 26 28s26-15.4 26-28C56 17.028 44.822 8 30 8Zm5 7h12a1 1 0 0 1 0 2H35a1 1 0 0 1 0-2ZM11 27a8 8 0 0 1 1.085-4H12a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2h-.085A8 8 0 1 1 11 27Zm20 25h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm2-9.142V46a2 2 0 0 1-2 2 4 4 0 0 1-4-4v-1h-2a1 1 0 0 1 0-2h7a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-3 3.858ZM41 35a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Z"
          fill={`url(#${id}) ${color}`}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M29 43v1a2 2 0 0 0 2 2v-3ZM23.466 23h-8.932a6 6 0 1 0 8.932 0ZM17 28a1 1 0 0 1-2 0v-2a1 1 0 0 1 2 0ZM12.366 10.874A12.645 12.645 0 0 0 9.09 7.5a7.588 7.588 0 0 1-1.139 1.451c-.04.041-.084.076-.125.115a14.379 14.379 0 0 1 2.858 2.994q.81-.621 1.682-1.186ZM3.521 9a4.6 4.6 0 0 0 3.01-1.462A4.6 4.6 0 0 0 8 4.525 1.576 1.576 0 0 0 7.6 3.4a1.549 1.549 0 0 0-1.127-.408A4.634 4.634 0 0 0 3.456 4.46C2.053 5.871 1.567 7.771 2.4 8.6a1.572 1.572 0 0 0 1.121.4ZM50.91 7.5a12.654 12.654 0 0 0-3.276 3.377q.872.564 1.682 1.185a14.379 14.379 0 0 1 2.858-2.994c-.041-.039-.085-.074-.125-.115A7.551 7.551 0 0 1 50.91 7.5ZM53.524 2.992A1.55 1.55 0 0 0 52.4 3.4a1.576 1.576 0 0 0-.4 1.125 4.6 4.6 0 0 0 1.462 3.01A4.6 4.6 0 0 0 56.475 9a1.572 1.572 0 0 0 1.125-.4c.833-.833.347-2.733-1.06-4.148a4.627 4.627 0 0 0-3.016-1.46Z"
          fill={`url(#${id}) ${color}`}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ErgoPlaceholderIcon;
