// react
import { useEffect } from "react";

//redux
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { npcActions } from "redux/slices/npcSlice";
import { playerActions } from "redux/slices/playerSlice";
import { playerCheckpointsActions } from "redux/slices/playerCheckpointsSlice";
import { spaceshipItemsActions } from "redux/slices/spaceInvaders/spaceshipItemsSlice";
import { spaceshipFeaturesActions } from "redux/slices/spaceInvaders/spaceshipFeaturesSlice";
import { spaceshipResourcesActions } from "redux/slices/spaceInvaders/spaceshipResourcesSlice";
import { spaceshipMachineGunActions } from "redux/slices/spaceInvaders/spaceshipMachineGunSlice";

// parsers
import playerParser from "parsers/playerParser";
import playerCheckpointsParser from "parsers/playerCheckpointsParser";
import spaceshipItemsParser from "parsers/spaceInvaders/spaceshipItemsParser";

// enums
import { UserGenreType } from "enums/userEnum";
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// firebase
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

// interfaces
import { UserProps } from "interfaces/user";
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { PlayerCheckpointsProps } from "interfaces/playerCheckpoint";

// entities
import PlayerDegreeEntity from "entities/PlayerDegreeEntity";
import spaceshipFeaturesParser from "parsers/spaceInvaders/spaceshipFeaturesParser";
import spaceshipResourcesParser from "parsers/spaceInvaders/spaceshipResourcesParser";
import spaceshipMachineGunFirebaseParser from "parsers/spaceInvaders/spaceshipMachineGunFirebaseParser";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// utils
import isEmpty from "lodash/isEmpty";

interface PlayerFirebaseSyncProps {
  user: UserProps;
}

const PlayerFirebaseSync = ({ user }: PlayerFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useDocument(
    doc(FirebaseCoreService.getDatabase(), "players", String(user.id))
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    syncPlayerFirebase(snapshot?.data() as PlayerFirebaseProps, dispatch);
  }

  return null;
};

export function syncPlayerFirebase(
  player: PlayerFirebaseProps,
  dispatch: Dispatch
) {
  if (isEmpty(player)) return;

  const p = playerParser.map(player);
  const spaceshipItems = spaceshipItemsParser.all(player);
  const spaceshipItemsHash = spaceshipItemsParser.hash(player);
  const spaceshipResources = spaceshipResourcesParser.map(player);
  const spaceshipFeatures = spaceshipFeaturesParser.map(
    playerParser.mapSpaceship(player)
  );

  updateCheckpoint(player, dispatch);

  dispatch(playerActions.update(p));
  dispatch(spaceshipItemsActions.setAll(spaceshipItems));
  dispatch(spaceshipItemsActions.setHash(spaceshipItemsHash));
  dispatch(spaceshipItemsActions.setCounter(spaceshipItemsHash));
  dispatch(spaceshipFeaturesActions.update(spaceshipFeatures));
  dispatch(spaceshipResourcesActions.update(spaceshipResources));
  dispatch(
    spaceshipMachineGunActions.update(
      spaceshipMachineGunFirebaseParser.map(player)
    )
  );
  dispatch(playerCheckpointsActions.set(playerCheckpointsParser.map(player)));
}

function updateCheckpoint(player: PlayerFirebaseProps, dispatch: Dispatch) {
  if (isEmpty(player)) return;

  const checkpoint = player.checkpoint as PlayerCheckpointsProps | undefined;
  if (!checkpoint) return;

  const jarvisName = checkpoint[PlayerCheckpointEnum.Jarvis];
  const zarvoxName = checkpoint[PlayerCheckpointEnum.Zarvox];
  const astrogildoName = checkpoint[PlayerCheckpointEnum.Astrogildo];

  if (zarvoxName) dispatch(npcActions.addZarvoxName());
  if (astrogildoName) dispatch(npcActions.addAstrogildoName());
  if (jarvisName) dispatch(npcActions.updateJarvisName(jarvisName));
}

export function syncPlayerDegreeFirebase(
  player: PlayerFirebaseProps,
  dispatch: Dispatch
) {
  if (isEmpty(player)) return;

  const score = player?.score || 0;
  const degree = new PlayerDegreeEntity({
    genre: player.genre || UserGenreType.Unknown,
    score,
  }).get();

  dispatch(playerActions.update({ degree }));
}

export default PlayerFirebaseSync;
