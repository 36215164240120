// entities
import { InvaderSixEntity } from "entities/spaceInvaders/InvaderEntity";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const SpaceInvaderSixSvg = ({
  width = "20",
  height = "20",
  color = InvaderSixEntity.color,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 550.801 550.801"
    >
      <g>
        <path
          d="M336.6 339.661H214.2a6.122 6.122 0 0 0-6.12 6.119v61.2a6.122 6.122 0 0 0 6.12 6.12h122.4a6.122 6.122 0 0 0 6.121-6.12v-61.2a6.122 6.122 0 0 0-6.121-6.119z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M544.68 143.821h-61.2a6.123 6.123 0 0 1-6.121-6.12v-55.08a6.122 6.122 0 0 0-6.119-6.12h-48.961a6.122 6.122 0 0 1-6.119-6.12v-61.2a6.123 6.123 0 0 0-6.121-6.12H348.84a6.121 6.121 0 0 0-6.119 6.12v61.2a6.123 6.123 0 0 1-6.121 6.12H214.2a6.123 6.123 0 0 1-6.12-6.12v-61.2a6.123 6.123 0 0 0-6.12-6.12h-61.2a6.123 6.123 0 0 0-6.12 6.12v61.2a6.123 6.123 0 0 1-6.12 6.12H79.56a6.122 6.122 0 0 0-6.12 6.12v55.08a6.123 6.123 0 0 1-6.12 6.12H6.12a6.123 6.123 0 0 0-6.12 6.12v391.681a6.122 6.122 0 0 0 6.12 6.119h134.64a6.122 6.122 0 0 0 6.12-6.119v-61.2a6.122 6.122 0 0 0-6.12-6.12h-61.2a6.123 6.123 0 0 1-6.12-6.12v-183.6a6.122 6.122 0 0 1 6.12-6.12h48.96a6.122 6.122 0 0 1 6.12 6.12v48.96a6.123 6.123 0 0 0 6.12 6.12h61.2a6.123 6.123 0 0 0 6.12-6.12v-48.96a6.122 6.122 0 0 1 6.12-6.12h122.4a6.122 6.122 0 0 1 6.121 6.12v48.96c0 3.378 2.74 6.12 6.119 6.12h61.199a6.123 6.123 0 0 0 6.121-6.12v-48.96a6.122 6.122 0 0 1 6.119-6.12h48.961a6.122 6.122 0 0 1 6.119 6.12v183.6a6.122 6.122 0 0 1-6.119 6.12h-61.201a6.122 6.122 0 0 0-6.119 6.12v61.2a6.121 6.121 0 0 0 6.119 6.119H544.68a6.122 6.122 0 0 0 6.121-6.119V149.94a6.122 6.122 0 0 0-6.121-6.119zm-336.6 67.32a6.123 6.123 0 0 1-6.12 6.12h-61.2a6.123 6.123 0 0 1-6.12-6.12v-61.2a6.123 6.123 0 0 1 6.12-6.12h61.2a6.123 6.123 0 0 1 6.12 6.12v61.2zm208.08 0a6.123 6.123 0 0 1-6.121 6.12H348.84a6.121 6.121 0 0 1-6.119-6.12v-61.2c0-3.378 2.74-6.12 6.119-6.12h61.199a6.123 6.123 0 0 1 6.121 6.12v61.2z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default SpaceInvaderSixSvg;
