// react
import { memo, useState } from "react";

// utils
import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 18px;
    text-align: center;
    color: ${color.grey.strong};
  }
`;

interface VictoryPointsNewsAvatarMessageProps {
  avatar: React.ReactNode;
  message: string;
}

const VictoryNewsAvatar = ({
  avatar,
  message,
}: VictoryPointsNewsAvatarMessageProps) => {
  const [classNameColor] = useState(getRandomClassNameColor());

  function getRandomClassNameColor() {
    const colors = [
      "nerdfy_purple",
      "nerdfy_blue",
      "nerdfy_orange",
      "nerdfy_green",
      "nerdfy_yellow",
      "nerdfy_red",
    ];

    return colors[Math.floor(Math.random() * colors.length)];
  }

  return (
    <Wrapper>
      {avatar}
      <p>
        <span className={`${classNameColor} pixelify_font_family`}>"</span>
        {message}
        <span className={`${classNameColor} pixelify_font_family`}>"</span>
      </p>
    </Wrapper>
  );
};

export default memo(VictoryNewsAvatar);
