// interfaces
import { TagProps, TagSpecialResourceProps } from "interfaces/tag";

// utils
import { v4 as uuidv4 } from "uuid";
import { SPECIAL_REGEX } from "utils/regexUtils";

function mix(tags: string[], whitelist: string[]): string[] {
  return mixTagsWithWhiteList(tags, whitelist);
}

function format(tags: string[]): TagProps[] {
  return tags.map((name: string) => {
    return map(name);
  });
}

function formatMultipleValues(tags: string[][]): TagProps[][] {
  return tags.map((tags: string[]) => {
    return tags.map((tag) => map(tag));
  });
}

function map(value: string, index?: number): TagProps {
  const data: TagProps = {
    id: uuidv4(),
    value,
  };

  if (index || index === 0) data.index = index;
  return data;
}

function mapSpecial(tag: TagSpecialResourceProps, index = 0): TagProps {
  const flag = {
    exclusive: !!(tag.f && tag.f.e),
  };
  const type = {
    regexp: !!tag.t.r || !!tag.t.b,
    backReference: !!tag.t.b,
    callbackFunction: !!tag.t.c,
    multiple: !!tag.t.m,
  };
  const id = uuidv4();
  const special = true;
  const specialRawValue = type.backReference ? getBackReferenceValue() : tag.v;
  const value = getRawValue(tag.v);
  const multipleValues = type.multiple ? formatMultipleValues(tag.mv) : [];
  const specialIndex = tag.i || 0;

  return {
    id,
    flag,
    type,
    special,
    value,
    index,
    specialRawValue,
    multipleValues,
    specialIndex,
  };
}

function getBackReferenceValue(): string {
  return "\\\\($)\\\\";
}

function getRawValue(str: string): string {
  const matched = str.match(SPECIAL_REGEX);
  if (!matched) return "";

  return matched[0]
    .replace(/\/[a-zA-Z]*\\\\/g, "") // remove as flags
    .replace(/\//g, "") // remove o identificador de regex "/.../"
    .replace(/\\/g, ""); // remove o identificador de special "//...//"
}

function shuffle(tags: TagProps[]) {
  let currentIndex = tags.length;
  let temporaryValue;
  let randomIndex;
  let list = [...tags];

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = list[currentIndex];
    list[currentIndex] = list[randomIndex];
    list[randomIndex] = temporaryValue;
  }

  return list;
}

function mixTagsWithWhiteList(tags: string[], whitelist: string[]): string[] {
  // const mixed = [...tags, ...whitelist]
  // const reduced = mixed.reduce((acc: { [key: string]: boolean }, tag) => {
  //   acc[tag] = true
  //   return acc
  // }, {})

  // return Object.keys(reduced)

  return [...tags, ...whitelist];
}

const tagUtils = {
  map,
  mix,
  format,
  formatMultipleValues,
  mapSpecial,
  getRawValue,
  getBackReferenceValue,
  shuffle,
  mixTagsWithWhiteList,
};

export default tagUtils;

// getValue(isSpecial: boolean, name: string, flags: TagFlagsI): any {
//   if (!isSpecial) return name
//   if (flags.multiple) return JSON.parse(regexUtils.special.getValue(name))

//   return regexUtils.special.getValue(name)
// },
// getValueSteps(
//   isSpecial: boolean,
//   values: string[],
//   flags: TagFlagsI
// ): { [key: number]: string }[] {
//   if (!isSpecial) return []
//   if (!flags.multiple) return []
//   if (!_.isArray(values)) return []
//   if (_.isEmpty(values)) return []

//   return values.reduce((acc: { [key: number]: string }[], value) => {
//     const splitted = value.split(' ')
//     const filtered = splitted.filter(v => v)
//     const data = Object.assign({}, filtered)

//     acc.push(data)
//     return acc
//   }, [])
// },
