// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import styled from "styled-components";
import c from "styles/color";

const DuolingoBtn = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

interface ButtonDuolingoIconProps {
  label: string;
  onClick(): void;
  color?: string;
  disabled?: boolean;
  customClassName?: string;
}

const ButtonDuolingoGeneric = ({
  label,
  onClick,
  disabled,
  color = c.green,
  customClassName,
}: ButtonDuolingoIconProps) => {
  return (
    <DuolingoBtn
      onClick={onClick}
      className={customClassName}
      disabled={disabled}
      color={color}
    >
      {label}
    </DuolingoBtn>
  );
};

export default ButtonDuolingoGeneric;
