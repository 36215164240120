export const ExplosionFactory = () => {
  const el = document.createElement("div");
  el.className = "space_invaders_explosion";

  return el;
};

export const ExplosionParticleFactory = () => {
  const el = document.createElement("div");
  el.className = "particle";

  return el;
};
