// interfaces
import { PlayerCheckpointsProps } from "interfaces/playerCheckpoint";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

export interface PlayerCheckpointDataFirebaseEntity {
  get values(): PlayerCheckpointsProps;
}

export class PlayerCheckpointJarvisDataFirebaseEntity {
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.Jarvis]: this.name };
  }
}

export class PlayerCheckpointEncyclopediaIntroFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.EncyclopediaBubbleIntro]: true };
  }
}

export class PlayerCheckpointEnonDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.Enon]: true };
  }
}

export class PlayerCheckpointZarvoxDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.Zarvox]: true };
  }
}

export class PlayerCheckpointAstrogildoDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.Astrogildo]: true };
  }
}

export class PlayerCheckpointCivilizationNerdDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CivilizationNerd]: true };
  }
}

export class PlayerCheckpointCivilizationErgonianDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CivilizationErgonians]: true };
  }
}

export class PlayerCheckpointCivilizationRavianDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CivilizationRavians]: true };
  }
}

export class PlayerCheckpointCompanyNerdXDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CompanyNerdX]: true };
  }
}

export class PlayerCheckpointCompanyNerdflixDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CompanyNerdflix]: true };
  }
}

export class PlayerCheckpointCompanyNerdsoftDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CompanyNerdsoft]: true };
  }
}

export class PlayerCheckpointCompanyNerdtelDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CompanyNerdtel]: true };
  }
}

export class PlayerCheckpointCompanyNerdBookDataFirebaseEntity {
  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.CompanyNerdBook]: true };
  }
}

export class PlayerCheckpointInvaderOneDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.InvaderOne;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.InvaderOne]: true };
  }
}

export class PlayerCheckpointInvaderTwoDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.InvaderTwo;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.InvaderTwo]: true };
  }
}

export class PlayerCheckpointInvaderThreeDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.InvaderThree;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.InvaderThree]: true };
  }
}

export class PlayerCheckpointInvaderFourDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.InvaderFour;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.InvaderFour]: true };
  }
}

export class PlayerCheckpointInvaderFiveDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.InvaderFive;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.InvaderFive]: true };
  }
}

export class PlayerCheckpointInvaderSixDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.InvaderSix;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.InvaderSix]: true };
  }
}

export class PlayerCheckpointInvaderSevenDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.InvaderSeven;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.InvaderSeven]: true };
  }
}

export class PlayerCheckpointDemoStartedDataFirebaseEntity {
  static checkpointIndex = PlayerCheckpointEnum.DemoStarted;

  get values(): PlayerCheckpointsProps {
    return { [PlayerCheckpointEnum.DemoStarted]: true };
  }
}
