// services/core
import RequestCoreService from "services/core/RequestCoreService";

// interfaces
import { ClassRoomDtoResourceProps } from "interfaces/classRoom";

export interface GetClassRoomResponseProps {
  data: ClassRoomDtoResourceProps;
}

class ClassRoomDtoService {
  private _resource = "classroom/dto";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  get(languageId: number, id?: number): Promise<GetClassRoomResponseProps> {
    let resource = `${this._resource}`;

    if (id) resource += `/${id}`;
    if (languageId) resource += `/language/${languageId}`;

    return this._request.get(resource);
  }
}

export default ClassRoomDtoService;
