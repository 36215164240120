// icons
import AddPersonPixelIcon from "icons/AddPersonPixelIcon";

// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreAddPerson = (_data: AttentionWhoreElProps) => {
  return <AddPersonPixelIcon width="35" height="35" />;
};

export default IconWhoreAddPerson;
