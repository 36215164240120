export enum BubbleType {
  None = 0,
  God,
  Message,
  Info,
  Quiz,
  Market,
  Speech,
  Narrator,
  JarvisAlert,
}
