// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// entities
import GodNameEntity from "entities/GodNameEntity";
import EnonEntity from "entities/characters/EnonEntity";
import ZarvoxEntity from "entities/characters/ZarvoxEntity";
import JarvisEntity from "entities/characters/JarvisEntity";
import AstrogildoEntity from "entities/characters/AstrogildoEntity";

// interfaces
import { NpcHashProps } from "interfaces/npc";

const initialState: NpcHashProps = {
  god: {
    name: GodNameEntity.defaultName,
  },
  jarvis: {
    name: JarvisEntity.unknownName,
  },
  zarvox: {
    name: ZarvoxEntity.unknownName,
  },
  astrogildo: {
    name: AstrogildoEntity.unknownName,
  },
  enon: {
    name: EnonEntity.unknownName,
  },
};

export const npcSlice = createSlice({
  name: "npc",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<NpcHashProps>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      return action.payload;
    },
    updateJarvisName: (state, action: PayloadAction<string>) => {
      state.jarvis.name = action.payload;
    },
    addZarvoxName: (state) => {
      state.zarvox.name = ZarvoxEntity.defaultName;
    },
    addAstrogildoName: (state) => {
      state.astrogildo.name = AstrogildoEntity.defaultName;
    },
    addEnonName: (state) => {
      state.astrogildo.name = EnonEntity.defaultName;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const npcActions = npcSlice.actions;
export default npcSlice.reducer;
