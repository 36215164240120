import { ChallengeCodeStepProps } from "interfaces/challengeCode";
import { TagProps } from "interfaces/tag";

function getCurrentByIndex(
  steps: ChallengeCodeStepProps[],
  index: number
): TagProps[] {
  return steps.map((step) => step.data[index]);
}

function getAdditional(steps: ChallengeCodeStepProps[]): string {
  const step = steps && steps[0];
  if (!step) return "";

  return (step.additional && step.additional.data) || "";
}

const tagStepUtils = {
  getCurrentByIndex,
  getAdditional,
};

export default tagStepUtils;
