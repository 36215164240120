// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// svgs
import AmateurRadioPixelSvg from "svg/AmateurRadioPixelSvg";

// firestore
import { serverTimestamp } from "firebase/firestore";

interface IconProps {
  width?: string;
  height?: string;
}

const Icon = ({ width = "35", height = "35" }: IconProps) => {
  return <AmateurRadioPixelSvg width={width} height={height} />;
};

export default class AmateurRadioItemEntity extends AbstractShopItemEntity {
  readonly id = 2;
  readonly price = 1;
  readonly maxQuantity = 1;
  readonly available = false;
  readonly icon = (<Icon />);
  readonly title = "Rádio Amador";
  readonly bubbles = this.shopBubbles;
  readonly type = SpaceshipItemEnum.AmateurRadio;
  readonly subTitle = "Emissor e Receptor Broadcast";
  readonly image = (<Icon width="45" height="45" />);
  readonly capacity = AbstractShopItemEntity.capacityNone;
  readonly lifeCycles = AbstractShopItemEntity.lifeCyclesNone;

  get item(): ShopItemProps {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      price: this.price,
      bubbles: this.bubbles,
      subTitle: this.subTitle,
      capacity: this.capacity,
      available: this.available,
      lifeCycles: this.lifeCycles,
    };
  }

  get values(): PlayerShopItemsFirebaseProps {
    return {
      [this.getItemId(this.type)]: {
        t: this.type,
        c: this.capacity,
        l: this.lifeCycles,
        s: serverTimestamp(),
      },
    };
  }

  get resourcesPrice(): SpaceshipResourcesQtyProps {
    return {
      sand: 0,
      gold: 2,
      water: 1,
      silver: 1,
      copper: 2,
      estelin: 0,
      bauxite: 1,
      strongForce: 1,
    };
  }

  getIcon({ width, height }: IconProps): JSX.Element {
    return <Icon width={width} height={height} />;
  }

  private get shopBubbles(): ShopItemBubbleProps[] {
    return [
      {
        id: 0,
        itemId: this.id,
        question: "Quantos ciclos?",
        answer: `A quantidade de ciclos é "infinita", já que corresponde a um número muito grande de ciclos... ou seja, você NUNCA vai precisar se preocupar em comprar outro rádio amador. [a não ser, é claro, que você destrua a espaçonave]`,
      },
      {
        id: 2,
        itemId: this.id,
        question: "Como funciona?",
        answer: `O rádio amador é capaz de ENVIAR e RECEBER mensagens do tipo broadcast para quaisquer outras naves espaciais que estejam ao alcance do sinal e que TAMBÉM possuam um rádio amador.`,
      },
      {
        id: 3,
        itemId: this.id,
        question: "O que são mensagens do tipo broadcast?",
        answer: `Uma mensagem do tipo broadcast é uma mensagem enviada para múltiplos destinatários simultaneamente, sem a necessidade de identificar ou especificar cada destinatário individualmente.`,
      },
      {
        id: 4,
        itemId: this.id,
        question: "O que seria emissor e receptor broadcast?",
        answer: `Com o rádio amador será possível enviar (emissor) e receber (receptor) mensagens do tipo broadcast...`,
      },
    ];
  }
}
