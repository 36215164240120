// firestore
import { Firestore, FieldValue } from "firebase/firestore";
import FirebaseCoreService from "services/core/FirebaseCoreService";
import {
  doc,
  setDoc,
  getDoc,
  addDoc,
  getDocs,
  updateDoc,
  collection,
  deleteField,
  DocumentData,
  QuerySnapshot,
  DocumentSnapshot,
  DocumentReference,
} from "firebase/firestore";

// utils
import isEmpty from "lodash/isEmpty";

interface FieldsToDeleteHashProps {
  [key: string]: FieldValue;
}

/*
  1) set without merge will overwrite a document or create it if it doesn't exist yet

  2*) set with merge will update fields in the document or create it if it doesn't exists

  3) update will update fields but will fail if the document doesn't exist

  4) create will create the document but fail if the document already exists

  * https://stackoverflow.com/questions/46597327/difference-between-firestore-set-with-merge-true-and-update
*/

class FirebaseCoreResourceService {
  db: Firestore;

  constructor() {
    this.db = FirebaseCoreService.getDatabase();
  }

  set(path: string, values: any): Promise<void> {
    if (!path) return Promise.reject();
    if (isEmpty(values)) return Promise.reject();

    return setDoc(doc(this.db, path), values, { merge: true });
  }

  overwrite(path: string, values: any): Promise<void> {
    if (!path) return Promise.reject();
    if (isEmpty(values)) return Promise.reject();

    return setDoc(doc(this.db, path), values);
  }

  add(path: string, values: any): Promise<DocumentReference<any> | void> {
    if (!path) return Promise.reject();
    if (isEmpty(values)) return Promise.reject();

    return addDoc(collection(this.db, path), values);
  }

  deleteField(path: string, deleteFields: string[]): Promise<void> {
    if (!path) return Promise.reject();
    if (isEmpty(deleteFields)) return Promise.reject();

    return updateDoc(doc(this.db, path), this._getFieldsToDelete(deleteFields));
  }

  get(path: string): Promise<DocumentSnapshot<DocumentData>> {
    return getDoc(doc(this.db, path));
  }

  getByCollection(path: string): Promise<QuerySnapshot<DocumentData>> {
    return getDocs(collection(this.db, path));
  }

  private _getFieldsToDelete(deleteFields: string[]): FieldsToDeleteHashProps {
    return deleteFields.reduce((acc, field) => {
      acc[field] = deleteField();
      return acc;
    }, {} as FieldsToDeleteHashProps);
  }
}

export default FirebaseCoreResourceService;
