// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { spaceshipResourcesActions } from "redux/slices/spaceInvaders/spaceshipResourcesSlice";

// entities
import SpaceshipResourcesToDeleteEntity from "entities/spaceshipResources/SpaceshipResourcesToDeleteEntity";

// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesSourceDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";
import { ChallengeCommonProps } from "interfaces/challenge";
import {
  AlertContentProps,
  AlertContentDataProps,
  SpaceshipResourceReducedProps,
  SpaceshipResourcesLoopOutputProps,
} from "handlers/spaceshipResources/SpaceshipResourcesHandler";
import { SpaceshipResourcesUpdateFirebaseProps } from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// factories
import { AlertFactoryType } from "factories/AlertFactory";

// utils
import isEmpty from "lodash/isEmpty";
import { AllToOptional } from "utils/transformTypeUtils";

interface SpaceShipResourcesHandlerProps {
  challenge: ChallengeCommonProps;
  loop(
    resources: SpaceshipResourcesProps,
    reduce: (
      data: {
        [key: string]: SpaceshipResourcesSourceDataProps;
      },
      turnsToTransform: number
    ) => SpaceshipResourceReducedProps
  ): SpaceshipResourcesLoopOutputProps;
  recursiveAlerts(
    data: SpaceshipResourcesLoopOutputProps,
    cb: (alert: AlertContentDataProps) => AlertContentProps
  ): void;
  hasExpired(d: SpaceshipResourcesSourceDataProps): boolean;
}

const SpaceshipResourcesExpirationHandler = ({
  loop,
  challenge,
  hasExpired,
  recursiveAlerts,
}: SpaceShipResourcesHandlerProps) => {
  const dispatch = useDispatch();
  const alreadyStartedRef = useRef(false);
  const resources = useSelector(s.spaceshipResources());
  const { flowInit } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleExpiration, [flowInit, resources]);

  function handleExpiration() {
    if (!flowInit) return;
    if (alreadyStartedRef.current) return;

    alreadyStartedRef.current = true;
    const expired = expire();

    syncExpiration(expired);
    recursiveAlerts(expired, getAlertContent);
  }

  function expire() {
    return loop(resources, reduce);
  }

  function getAlertContent(alert: AlertContentDataProps): AlertContentProps {
    return {
      type: AlertFactoryType.Danger,
      content: `-${alert.quantity} ${alert.resource} (bruto) (expirado)`,
    };
  }

  function reduce(data: {
    [key: string]: SpaceshipResourcesSourceDataProps;
  }): SpaceshipResourceReducedProps {
    return Object.keys(data).reduce((acc, key: string) => {
      const d = data[key];

      if (d.transformed) return acc;
      if (!hasExpired(d)) return acc;

      acc[key] = d;
      return acc;
    }, {} as SpaceshipResourceReducedProps);
  }

  function syncExpiration(
    expired: AllToOptional<SpaceshipResourcesUpdateFirebaseProps>
  ) {
    if (isEmpty(expired)) return;

    const entity = new SpaceshipResourcesToDeleteEntity();
    dispatch(spaceshipResourcesActions.async.remove(entity.map(expired)));
  }

  return null;
};

export default SpaceshipResourcesExpirationHandler;
