// react
import { useState, useCallback, useRef } from "react";

// redux
import { useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// svgs
import RavianFoolSvg from "svg/ravians/RavianFoolSvg";
import RavianNerdSvg from "svg/ravians/RavianNerdSvg";
import RavianWinkSvg from "svg/ravians/RavianWinkSvg";
import RavianNinjaSvg from "svg/ravians/RavianNinjaSvg";
import RavianTongueSvg from "svg/ravians/RavianTongueSvg";
import RavianScepticSvg from "svg/ravians/RavianScepticSvg";
import RavianSmirkingSvg from "svg/ravians/RavianSmirkingSvg";
import RavianGrinningSvg from "svg/ravians/RavianGrinningSvg";
import RavianUnamusedSvg from "svg/ravians/RavianUnamusedSvg";

// entities
import { PlayerCheckpointCivilizationRavianDataFirebaseEntity } from "entities/data/PlayerCheckpointDataFirebaseEntity";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import Board from "components/Board";
import FormHeader from "components/form/FormHeader";
import { DropZoneAttentionWhore } from "components/DropZone";
import { CivilizationRaviansFooter } from "components/presentations/CivilizationFooter";

// utils
import color from "styles/color";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  > .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 20px;

    .ravians {
      svg {
        margin: 0 5px;
      }
    }

    .body_content {
      display: flex;
      align-items: center;
    }

    .body_input_wrapper {
      width: 100%;
      padding: 0 20px;
      border: none;

      .jarvis_input {
        --color: ${color.grey.middle};
      }
    }

    .ps {
      margin-top: 10px;
      margin-right: auto;

      p.jarvis_ps {
        text-align: left;
        font-size: 12px;
        color: ${color.grey.strong};
        margin: 8px 0;
        opacity: 0.6;
      }
    }
  }
`;

interface JarvisPresentationProps {
  show: boolean;
  onFinish: () => void;
  wrapper: HTMLDivElement | null;
}

const RavianPresentation = ({
  show,
  wrapper,
  onFinish,
}: JarvisPresentationProps) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const [disabled, setDisabled] = useState(false);
  const [animation, setAnimation] = useState("animate__bounceInUp");
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    wrapperRef.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function save() {
    setDisabled(true);
    syncCheckpoints();
    setTimeout(alert);
    setTimeout(close, 2000);
  }

  function syncCheckpoints() {
    const entity = new PlayerCheckpointCivilizationRavianDataFirebaseEntity();
    dispatch(playerActions.async.updateCheckpoint(entity.values));
  }

  // ATTENTION WHORES END

  function close() {
    setAnimation("animate__bounceOutLeft");
  }

  function alert() {
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `A civilização "Raviania" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (e.animationName === "bounceOutLeft") onFinish();
  }

  return (
    <>
      {show && (
        <Content
          ref={handle}
          onAnimationEnd={onAnimationEnd}
          className={`animate__animated no-swipe ${animation}`}
        >
          <FormHeader brand save={save} disabled={disabled} />

          <div className="body">
            <div className="ravians">
              <RavianFoolSvg width="35" height="35" />
              <RavianGrinningSvg width="35" height="35" />
              <RavianNerdSvg width="35" height="35" />
              <RavianWinkSvg width="35" height="35" />
              <RavianNinjaSvg width="35" height="35" />
              <RavianTongueSvg width="35" height="35" />
              <RavianScepticSvg width="35" height="35" />
              <RavianSmirkingSvg width="35" height="35" />
              <RavianUnamusedSvg width="35" height="35" />
            </div>

            <div className="body_content">
              <Board>
                <p>
                  <span className="nerdfy_pink">Muambeiros intergaláticos</span>{" "}
                  e presente em quase{" "}
                  <span className="nerdfy_white">todos</span> os lugares, os{" "}
                  <span className="nerdfy_orange">ravianos</span> são{" "}
                  <span className="nerdfy_yellow">amigáveis</span>,{" "}
                  <span className="nerdfy_blue">comerciantes natos</span> e
                  sempre <span className="nerdfy_red">dispostos</span> a fazer
                  um <span className="nerdfy_green">bom negócio</span>.
                </p>
              </Board>
            </div>
          </div>

          <CivilizationRaviansFooter />
          <DropZoneAttentionWhore />
        </Content>
      )}
    </>
  );
};

export default RavianPresentation;
