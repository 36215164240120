import { ApplicationErrorProps } from "interfaces/error";
export default class AuthError extends Error {
  static errorName = "AuthError";

  constructor(message: string = "AuthError") {
    super(message);

    this.name = "AuthError";
    this.message = message;
  }

  toJSON(): ApplicationErrorProps {
    return {
      data: {
        name: this.name,
        message: this.message,
        type: AuthError.errorName,
        status: 0,
        stacktrace: this.stack,
      },
    };
  }
}
