// react
import { memo, useState, useEffect, useRef } from "react";

// constants
import people, { peopleRangeType } from "constants/people";

// components
import AvatarErgo from "components/avatars/AvatarErgo";
import VictoryNewsAvatar from "handlers/victoryNews/VictoryNewsAvatar";

// utils
import numberUtils from "utils/numberUtils";

const VictoryNewsErgoAvatar = () => {
  const [message, setMessage] = useState<string>("");
  const randomAvatarRef = useRef<peopleRangeType>(getPeopleRangeType());

  useEffect(handleMessage, []);

  function handleMessage() {
    const p = people.ergo[randomAvatarRef.current];
    if (!p) return;

    setMessage(p[numberUtils.randomInterval(0, p.length - 1)]);
  }

  function getPeopleRangeType() {
    return numberUtils.randomInterval(1, 9) as peopleRangeType;
  }

  return (
    <VictoryNewsAvatar
      message={message}
      avatar={<AvatarErgo avatar={randomAvatarRef.current} />}
    />
  );
};

export default memo(VictoryNewsErgoAvatar);
