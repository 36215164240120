// react
import { IonRippleEffect } from "@ionic/react";

// utils
import styled from "styled-components";

const Wrapper = styled.div<{ color: string }>`
  --box-shadow: none;
  --background: none;
  --background-activated: ${({ color }) => color};
  --background-hover: ${({ color }) => color};
  color: ${({ color }) => color};
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  &.disabled {
    opacity: 0.2 !important;
  }
`;

interface ButtonRippleEffectProps {
  color: string;
  children: React.ReactNode;
  onClick(e: React.MouseEvent<HTMLElement>): void;
  disabled?: boolean;
  className?: string;
  onAnimationEnd?(animateName: string): void;
}

const ButtonRippleEffect = ({
  color,
  onClick,
  disabled,
  children,
  className,
  onAnimationEnd,
}: ButtonRippleEffectProps) => {
  function click(e: React.MouseEvent<HTMLElement>) {
    if (disabled) return;
    onClick(e);
  }

  function animateEnd(e: React.AnimationEvent<HTMLElement>) {
    onAnimationEnd && onAnimationEnd(e.animationName);
  }

  return (
    <Wrapper
      color={color}
      onClick={click}
      onAnimationEnd={animateEnd}
      className={`ion-activatable ${className} ${disabled && "disabled"}`}
    >
      <IonRippleEffect style={{ color }} />
      {children}
    </Wrapper>
  );
};

export default ButtonRippleEffect;
