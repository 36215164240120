function decode(data: string): string {
  return atob(data);
}

function encode(data: string): string {
  return btoa(data);
}

const base64Utils = {
  decode,
  encode,
};

export default base64Utils;
