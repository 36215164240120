// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { JarvisChatFirebaseProps } from "interfaces/jarvisChat";

class PlayerJarvisChatsFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async getCollection(
    userId: number | string,
    classRoomId: number
  ): Promise<JarvisChatFirebaseProps[]> {
    const path = `players/${userId}/classRooms/${classRoomId}/jarvisChats`;
    const response = await this._firebase.getByCollection(path);

    return response.docs.map((doc) => doc.data() as JarvisChatFirebaseProps);
  }

  async set(
    userId: number | string,
    classRoomId: number,
    jarvisChatId: number,
    data: JarvisChatFirebaseProps
  ): Promise<void> {
    if (!data.id) return Promise.reject();
    if (!userId) return Promise.reject();
    if (!classRoomId) return Promise.reject();
    if (!jarvisChatId) return Promise.reject();

    const path = `players/${userId}/classRooms/${classRoomId}/jarvisChats/${jarvisChatId}`;
    return this._firebase.set(path, data);
  }
}

export default PlayerJarvisChatsFirebaseService;
