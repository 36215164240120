// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { spaceshipItemsActions } from "redux/slices/spaceInvaders/spaceshipItemsSlice";

// entities
import SpaceshipItemEntity from "entities/spaceshipItem/SpaceshipItemEntity";

// factories
import { AlertFactoryType } from "factories/AlertFactory";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { SpaceshipItemProps } from "interfaces/spaceInvaders/spaceshipItem";
import { AlertContentProps } from "handlers/spaceshipResources/SpaceshipResourcesHandler";

// utils
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

interface SpaceshipItemsRemoveHandlerProps {
  challenge: ChallengeCommonProps;
  alert(alert: AlertContentProps): void;
}

const SpaceshipItemsRemoveHandler = ({
  alert,
  challenge,
}: SpaceshipItemsRemoveHandlerProps) => {
  const dispatch = useDispatch();
  const alreadyStartedRef = useRef(false);
  const spaceshipItems = useSelector(s.spaceshipItems());
  const { flowInit } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [flowInit, spaceshipItems]);

  function listen() {
    if (!flowInit) return;
    if (alreadyStartedRef.current) return;

    alreadyStartedRef.current = true;

    setTimeout(() => {
      const items = SpaceshipItemEntity.filterExpiredLifeCycles(spaceshipItems);

      if (isEmpty(items)) return;

      recursiveAlert(cloneDeep(items));
      dispatch(spaceshipItemsActions.async.remove(items));
    }, 500);
  }

  function recursiveAlert(items: SpaceshipItemProps[]) {
    if (isEmpty(items)) return;

    const item = items.shift();
    if (!item) return;

    alert({
      type: AlertFactoryType.Danger,
      content: `O item "${item.title}" foi expirado e descartado...`,
    });

    setTimeout(() => {
      recursiveAlert(items);
    });
  }

  return null;
};

export default SpaceshipItemsRemoveHandler;
