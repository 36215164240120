// react-canvas-confetti
import FireworksCanvas from "react-canvas-confetti/dist/presets/realistic";
import ExplosionCanvas from "react-canvas-confetti/dist/presets/explosion";
import CrossFireCanvas from "react-canvas-confetti/dist/presets/crossfire";

interface CelebrationProps {
  speed?: number;
  delay?: number;
  duration?: number;
}

export default function Confetti({
  delay,
  speed = 1,
  duration = 1,
}: CelebrationProps) {
  return <FireworksCanvas autorun={{ duration, delay, speed }} />;
}

export function VictoryStars({
  delay,
  speed = 3,
  duration = 1000,
}: CelebrationProps) {
  return <ExplosionCanvas autorun={{ duration, delay, speed }} />;
}

export function Celebration({
  delay,
  speed = 3,
  duration = 2000,
}: CelebrationProps) {
  return <CrossFireCanvas autorun={{ duration, delay, speed }} />;
}
