// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { feedbackActions } from "redux/slices/feedbackSlice";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeQuizActions } from "redux/slices/challengeQuizSlice";

// enums
import { ChallengeType } from "enums/challengeEnum";

// interfaces
import { PlayerRatingProps } from "interfaces/player";
import { FeedbackClassRoomRatingsFirebaseProps } from "interfaces/feedback";

// entities
import feedbackClassRoomRatingParser from "parsers/feedbackClassRoomRatingParser";

// services
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";
import PlayerRatingDataFirebaseService from "services/firebase/player/PlayerRatingDataFirebaseService";
import PlayerChallengeQuizFirebaseService from "services/firebase/player/PlayerChallengeQuizFirebaseService";
import GlobalChallengeThumbsFirebaseService from "services/firebase/global/GlobalChallengeThumbsFirebaseService";
import GlobalClassRoomRatingFirebaseService from "services/firebase/global/GlobalClassRoomRatingFirebaseService";
import GlobalChallengeQuizThumbsFirebaseService from "services/firebase/global/GlobalChallengeQuizThumbsFirebaseService";

// utils
import stateUtils from "redux/utils/stateUtils";

const feedbackMiddleware = createListenerMiddleware();

feedbackMiddleware.startListening({
  actionCreator: feedbackActions.async.submitChallengeThumbs,
  effect: async ({ payload }, listenerApi) => {
    const { up, down, challenge } = payload;
    const { auth } = stateUtils.get(listenerApi);
    const userId = auth.user.id;
    const { id, classRoomId } = challenge;
    const data = { up: !!up, down: !!down };

    if (challenge.type === ChallengeType.Quiz) {
      listenerApi.dispatch(challengeQuizActions.update(data));
      syncChallengeQuiz(userId, classRoomId, id, data);

      // global
      syncGlobalChallengeQuizThumbs(id, classRoomId, data);

      return;
    }

    listenerApi.dispatch(challengeActions.update(data));
    syncChallenge(userId, classRoomId, id, data);

    // global
    syncGlobalChallengeThumbs(id, classRoomId, data);
  },
});

feedbackMiddleware.startListening({
  actionCreator: feedbackActions.async.submitClassRoomRating,
  effect: async ({ payload }, listenerApi) => {
    const { message, rating, challenge } = payload;
    const { auth } = stateUtils.get(listenerApi);
    const value = { rating: { value: rating, message } };
    const data = feedbackClassRoomRatingParser.map(auth, challenge, {
      rating,
      message,
    });

    updatePlayerRatingFirebase(auth.user.id, value);

    // global
    syncGlobalClassRoomRatingFeedback(challenge.classRoomId, data);
  },
});

// private

function updatePlayerRatingFirebase(
  userId: number | string,
  player: PlayerRatingProps
) {
  if (!userId) return;

  const playerFirebaseService = new PlayerRatingDataFirebaseService();
  playerFirebaseService.update(userId, player);
}

interface ChallengeCommonDataUpdateProps {
  up?: boolean;
  down?: boolean;
  feedback?: string;
}

function syncChallenge(
  userId: number | string,
  classRoomId: number,
  challengeId: number,
  data: ChallengeCommonDataUpdateProps
) {
  if (!userId) return;
  if (!classRoomId) return;
  if (!challengeId) return;

  const resource = new PlayerChallengeFirebaseService();
  resource.update(challengeId, userId, classRoomId, data);
}

function syncChallengeQuiz(
  userId: number | string,
  classRoomId: number,
  quizId: number,
  data: ChallengeCommonDataUpdateProps
) {
  if (!userId) return;
  if (!classRoomId) return;
  if (!quizId) return;

  const resource = new PlayerChallengeQuizFirebaseService();
  resource.update(quizId, userId, classRoomId, data);
}

// SYNC GLOBAL

function syncGlobalClassRoomRatingFeedback(
  classRoomId: number,
  data: FeedbackClassRoomRatingsFirebaseProps
) {
  const resource = new GlobalClassRoomRatingFirebaseService();
  resource.add(classRoomId, data);
}

function syncGlobalChallengeThumbs(
  challengeId: number,
  classRoomId: number,
  { up, down }: { up: boolean; down: boolean }
) {
  if (!challengeId) return;

  const resource = new GlobalChallengeThumbsFirebaseService();
  resource.set(challengeId, classRoomId, { up, down });
}

function syncGlobalChallengeQuizThumbs(
  quizId: number,
  classRoomId: number,
  { up, down }: { up: boolean; down: boolean }
) {
  if (!quizId) return;

  const resource = new GlobalChallengeQuizThumbsFirebaseService();
  resource.set(quizId, classRoomId, { up, down });
}

export default feedbackMiddleware;
