// redux
import { createSlice, createAction } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { SpaceshipMachineGunProps } from "interfaces/spaceInvaders/spaceshipMachineGun";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

// enums
import { SpaceshipMachineGunEnum } from "enums/spaceInvaders/spaceshipMachineGunEnum";

const fill = createAction("@async/spaceshipFeatures/fill");
const removeBullets = createAction(
  "@async/spaceshipFeatures/setBullets",
  (state: { current: number }) => ({
    payload: { state },
  })
);
const reload = createAction(
  "@async/spaceshipFeatures/reload",
  (state: { current: number }) => ({
    payload: { state },
  })
);
const fire = createAction("@async/spaceshipFeatures/fire");
const target = createAction(
  "@async/spaceshipFeatures/target",
  (visible: boolean, state: { current: boolean }) => ({
    payload: { state, visible },
  })
);
const updateMachineGun = createAction(
  "@async/spaceshipFeatures/updateMachineGun",
  (machineGun: SpaceshipMachineGunEnum) => ({
    payload: { machineGun },
  })
);

export const initialState: SpaceshipMachineGunProps = {
  bullets: 0,
  targetVisible: false,
  machineGun: SpaceshipMachineGunEnum.Middle,
};

export const spaceshipMachineGunSlice = createSlice({
  name: "spaceshipMachineGun",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<SpaceshipMachineGunProps>) => {
      return action.payload;
    },
    update: (
      state,
      action: PayloadAction<AllToOptional<SpaceshipMachineGunProps>>
    ) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
    updateMachineGun: (
      state,
      action: PayloadAction<SpaceshipMachineGunEnum>
    ) => {
      state.machineGun = action.payload;
    },
  },
});

export const spaceshipMachineGunActions = {
  async: {
    fire,
    fill,
    target,
    reload,
    removeBullets,
    updateMachineGun,
  },
  ...spaceshipMachineGunSlice.actions,
};

export default spaceshipMachineGunSlice.reducer;
