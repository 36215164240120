// react
import { useState, useRef, useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector } from "react-redux";

// components
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// enums
import { SpaceshipMachineGunEnum } from "enums/spaceInvaders/spaceshipMachineGunEnum";

// utils
import color from "styles/color";
import styled from "styled-components";

const Button = styled(ButtonRippleEffect)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 10;

  ul {
    all: unset;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      all: unset;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 10px;
      border: 1px solid ${color.grey.dark};

      &.selected {
        border: 1px solid #292829;
        background: #292829;
      }

      &.selected_none {
        border: 1px solid ${color.red};
        opacity: 0.2;
      }
    }
  }
`;

type bulletSourceStateType = {
  [key: number]: boolean;
};

type selectedClassNameStateType = {
  [key: number]: string;
};

interface ButtonSelectMachineGunSideProps {
  onClick: (source: SpaceshipMachineGunEnum) => void;
  disabled?: boolean;
}

const ButtonSelectMachineGunSide = ({
  onClick,
  disabled,
}: ButtonSelectMachineGunSideProps) => {
  const { None, Left, Right, Both, Middle } = SpaceshipMachineGunEnum;
  const { machineGun } = useSelector(s.spaceshipMachineGun());
  const counterRef = useRef<number>(machineGun);
  const [selectedClassName, setSelectedClassName] =
    useState<selectedClassNameStateType>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToMachineGun, [machineGun]);

  function listenToMachineGun() {
    counterRef.current = getStateIndex(machineGun);
    setSelectedClassName(getSelectedClassName(getCurrentState()));
  }

  function click() {
    counterRef.current += 1;
    if (counterRef.current === 5) counterRef.current = 0;

    const state = getCurrentState();

    setSelectedClassName(getSelectedClassName(state));
    setTimeout(onClick.bind(null, getBulletSource(state)));
  }

  function getCurrentState(): bulletSourceStateType {
    let state;

    if (counterRef.current === getStateIndex(Both)) state = getStateBoth();
    else if (counterRef.current === getStateIndex(Right))
      state = getStateRight();
    else if (counterRef.current === getStateIndex(Left)) state = getStateLeft();
    else if (counterRef.current === getStateIndex(Middle))
      state = getStateMiddle();
    else state = getStateNone();

    return state;
  }

  function getStateIndex(source: SpaceshipMachineGunEnum) {
    if (source === Both) return 0;
    if (source === Right) return 1;
    if (source === Middle) return 2;
    if (source === None) return 3;

    return 4;
  }

  function getStateBoth(): bulletSourceStateType {
    return { 0: true, 1: true, 2: false };
  }

  function getStateLeft(): bulletSourceStateType {
    return { 0: true, 1: false, 2: false };
  }

  function getStateRight(): bulletSourceStateType {
    return { 0: false, 1: true, 2: false };
  }

  function getStateMiddle(): bulletSourceStateType {
    return { 0: false, 1: false, 2: true };
  }

  function getStateNone(): bulletSourceStateType {
    return { 0: false, 1: false, 2: false };
  }

  function getBulletSource(
    state: bulletSourceStateType
  ): SpaceshipMachineGunEnum {
    const left = state[0];
    const right = state[1];
    const middle = state[2];

    if (left && right) return Both;
    if (left && !right) return Left;
    if (!left && right) return Right;
    if (middle) return Middle;

    return None;
  }

  function getSelectedClassName(state: bulletSourceStateType): {
    [key: number]: string;
  } {
    const left = state[0];
    const right = state[1];
    const middle = state[2];
    const none = !left && !right && !middle;
    const result: {
      [key: number]: string;
    } = {};

    if (left) result[0] = "selected";
    if (right) result[1] = "selected";
    if (middle) result[2] = "selected";
    if (none) {
      result[0] = "selected_none";
      result[1] = "selected_none";
      result[2] = "selected_none";
    }

    return result;
  }

  return (
    <Button onClick={click} disabled={disabled} color={color.grey.strong}>
      <ul>
        <li className={selectedClassName[0]}></li>
        <li className={selectedClassName[2]}></li>
        <li className={selectedClassName[1]}></li>
      </ul>
    </Button>
  );
};

export default ButtonSelectMachineGunSide;
