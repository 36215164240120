// redux
import { useEffect } from "react";
import { collection } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { jarvisChatsActions } from "redux/slices/jarvisChatsSlice";

// redux
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

// interfaces
import { JarvisChatFirebaseProps } from "interfaces/jarvisChat";

// components
import { hasPendingWritesDocType } from "firebaseSync/FirebaseSync";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// parsers
import jarvisChatsParser from "parsers/jarvisChatsParser";

interface ChallengesFirebaseSyncProps {
  classRoomId: number;
  userId: number | string;
  isCachedData: (docs: hasPendingWritesDocType) => boolean;
}

const ChallengesFirebaseSync = ({
  userId,
  classRoomId,
  isCachedData,
}: ChallengesFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useCollection(
    collection(
      FirebaseCoreService.getDatabase(),
      `players/${userId}/classRooms/${classRoomId}/jarvisChats`
    )
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    const docs = snapshot?.docs;
    if (!docs) return;

    const data = docs.reduce((acc, doc) => {
      if (!isCachedData(doc)) return acc;

      acc.push(doc.data() as JarvisChatFirebaseProps);
      return acc;
    }, [] as JarvisChatFirebaseProps[]);

    syncJarvisChatsFirebase(data, dispatch);
  }

  return null;
};

export function syncJarvisChatsFirebase(
  data: JarvisChatFirebaseProps[],
  dispatch: Dispatch
) {
  dispatch(jarvisChatsActions.update(data.map(jarvisChatsParser.map)));
}

export default ChallengesFirebaseSync;
