// firebase
import {
  UserCredential,
  signInAnonymously,
  signInWithCustomToken,
} from "firebase/auth";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

class FirebaseAuthService {
  async signIn(firebaseToken?: string): Promise<UserCredential> {
    if (!firebaseToken) return Promise.reject();
    return signInWithCustomToken(FirebaseCoreService.getAuth(), firebaseToken);
  }

  async signInAnonymously(): Promise<UserCredential> {
    return signInAnonymously(FirebaseCoreService.getAuth());
  }

  async logout() {
    return FirebaseCoreService.getAuth().signOut();
  }
}

export default FirebaseAuthService;
