// react
import { useState, useEffect, useRef } from "react";

// interfaces
import { TagProps } from "interfaces/tag";

// utils
import color from "styles/color";
import styled from "styled-components";

interface TagElProps {
  x: string;
  y: string;
}

const Tag = styled.div<TagElProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(props: TagElProps) => props.y};
  left: ${(props: TagElProps) => props.x};
  border-radius: 10px;
  background: ${color.grey.dark};
  padding: 13px 18px;
  z-index: 900;
  touch-action: none;
  user-select: none;

  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-transform: none !important;
    padding: 0;
    margin: 0;
    font-size: 30px;
    white-space: nowrap;
    color: ${color.green};
  }
`;

export interface TagEnrichedProps extends TagProps {
  x: string;
  y: string;
}

interface ChallengeTagProps {
  clear?: boolean;
  paused?: boolean;
  tag: TagEnrichedProps;
  animationClassName: string;
}

const ChallengeTag = ({
  tag,
  clear,
  paused,
  animationClassName,
}: ChallengeTagProps) => {
  const { x, y } = tag;
  const animationRef = useRef("");
  const [animation, setAnimation] = useState(animationClassName);

  useEffect(clearTag, [clear]);

  function clearTag() {
    if (!clear) return;
    setAnimation("animate__zoomOut");
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();

    if (e.animationName === "zoomOut") setAnimation("d_none");
    if (e.animationName === animationRef.current.split("__")[1])
      setAnimation("");
  }

  return (
    <Tag
      x={x}
      y={y}
      data-x={x}
      data-y={y}
      id={tag.id}
      onAnimationEnd={onAnimationEnd}
      className={`tag_draggable tag_droppable animate__animated vt323_font_family ${
        !paused && !animation && "animate_y_infinite"
      } ${animation}`}
    >
      <div className="content">{tag.value}</div>
    </Tag>
  );
};

export default ChallengeTag;
