// utils
import c from "styles/color";

interface IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const ChatMessageIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M100.173 189.217h66.783V256h-66.783zM222.609 189.217h66.783V256h-66.783zM345.043 189.217h66.783V256h-66.783zM33.391 300.522h33.39v44.522h-33.39zM66.781 345.043h33.391v33.391H66.781zM0 144.696h33.391v155.826H0zM66.781 100.174h-33.39v44.522h33.392v-44.522h33.39V66.783H66.781zM100.173 33.391h66.783v33.391h-66.783z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M411.826 66.783V33.391h-66.783V0H166.955v33.391h178.087v33.392h66.783v33.391h33.391V66.783zM445.217 100.174h33.39v44.522h-33.39zM478.608 144.696H512v189.217h-33.392zM445.217 333.913h33.39v44.522h-33.39zM100.173 445.217H66.781V512H267.13v-33.391H100.173zM267.13 445.217h77.912v33.391H267.13z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M345.042 411.826h66.783v33.391h-66.783zM100.173 378.435v66.782h33.391v-33.391h33.391v-33.391zM411.826 378.435h33.391v33.391h-33.391z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ChatMessageIcon;
