import React from "react";
import styled from "styled-components";
import color from "styles/color";

const Message = styled.p`
  color: ${color.grey.middle};
  margin: 10px;
`;

const NotFound = () => {
  return <Message>ops, página não encontrada :(</Message>;
};

export default NotFound;
