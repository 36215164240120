export enum PlayerCheckpointEnum {
  None = 0,
  Jarvis,
  Zarvox,
  Enon,
  Astrogildo,
  CivilizationNerd,
  CivilizationErgonians,
  CivilizationRavians,
  InvaderOne,
  InvaderTwo,
  InvaderThree,
  InvaderFour,
  InvaderFive,
  InvaderSix,
  InvaderSeven,
  EncyclopediaBubbleIntro,
  QuestionBubbleIntro,
  CompanyNerdflix,
  CompanyNerdBook,
  CompanyNerdX,
  CompanyNerdtel,
  CompanyNerdsoft,
  DemoStarted,
}
