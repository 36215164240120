// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// enums
import { UserGenreType } from "enums/userEnum";

interface CodeNameProps {
  className?: string;
}

const CodeName = ({ className }: CodeNameProps) => {
  const user = useSelector(s.user());

  return (
    <h2
      className={`margin_top pixelify_font_family ${className} ${
        user.genre === UserGenreType.Male ? "nerdfy_blue" : "nerdfy_pink"
      }`}
    >
      {user.codename}
    </h2>
  );
};

export default CodeName;
