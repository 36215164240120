import { memo } from "react";

interface AvatarNerdProps {
  avatar: number;
  customClassName?: string;
  onAnimationEnd?: () => void;
}

const AvatarNerd = ({
  avatar,
  onAnimationEnd,
  customClassName = "",
}: AvatarNerdProps) => {
  return (
    <div
      className={`nerdfy_avatar nerd_${avatar} ${customClassName}`}
      onAnimationEnd={onAnimationEnd}
    ></div>
  );
};

export default memo(AvatarNerd);
