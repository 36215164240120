// react
import { IonRippleEffect } from "@ionic/react";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.9;
  background: ${color.dark.bg};
  padding: 15px;

  .fake_btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      font-size: 1.9em;
      text-align: center;
      margin: 10px 20px;

      &.desc {
        color: ${color.grey.middle};
      }
    }
  }
`;

interface DemoStartButtonProps {
  loading: boolean;
  onClick: () => void;
}

const DemoStartButton = ({ loading, onClick }: DemoStartButtonProps) => {
  function click() {
    setTimeout(onClick, 50);
  }

  return (
    <Wrapper onClick={click} className="ion-activatable pixelify_font_family">
      <IonRippleEffect style={{ color: color.yellow }} />

      <div className="fake_btn blink">
        <p className="bg_nerdfy_gradient">Aperte para Começar</p>
        <p className="desc">
          {loading ? "Carregando dados..." : "Estamos quase lá..."}
        </p>
        <p className="nerdfy_yellow">Não entre em pânico!</p>
      </div>
    </Wrapper>
  );
};

export const DemoContinueButton = ({
  loading,
  onClick,
}: DemoStartButtonProps) => {
  function click() {
    setTimeout(onClick, 50);
  }

  return (
    <Wrapper onClick={click} className="ion-activatable pixelify_font_family">
      <IonRippleEffect style={{ color: color.blue }} />

      <div className="fake_btn blink">
        <p className="nerdfy_green">Aperte para Iniciar</p>
        <p className="desc">{loading ? "Carregando dados..." : "Pronto?"}</p>
        <p className="nerdfy_yellow">Não entre em pânico!</p>
      </div>
    </Wrapper>
  );
};

export default DemoStartButton;
