// react
import { useEffect, useState, useRef } from "react";

// components
import Footer from "components/Footer";
import ButtonDuolingoRed from "components/buttons/templates/ButtonDuolingoRed";

// svgs
import JarvisSvg from "svg/JarvisSvg";
import UncleSamBrSvg from "svg/UncleSamBrSvg";
import JarvisPointingFingersSvg from "svg/JarvisPointingFingersSvg";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: ${color.dark.bg};
  text-align: center;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hero_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 150px;
      height: 220px;

      .uncle_sam {
        position: absolute;
        top: 0px;
        z-index: 2;
      }

      .jarvis {
        position: absolute;
        top: 74px;
      }

      .pointing {
        position: absolute;
        top: 150px;
        left: 15px;
      }
    }

    h2 {
      all: unset;
      font-size: 2.5em;
      margin: 30px 0 40px 0;
      color: ${color.white};
    }

    p {
      all: unset;
      font-size: 1.4em;
      color: ${color.grey.strong};
    }
  }
`;

const ChallengeDemoCta = () => {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showHero, setShowHero] = useState(false);
  const refs = useRef<any>({ one: null, two: null });

  useEffect(() => destroyComponent, []);
  useEffect(handleShow, []);

  function handleShow() {
    refs.current.one = setTimeout(() => setShow(true), 500);
    refs.current.two = setTimeout(() => setShowHero(true), 1200);
  }

  function redirect() {
    if (disabled) return;

    setDisabled(true);
    window.location.href = "http://nerdfy.io";
  }

  function destroyComponent() {
    clearTimeout(refs.current.one);
    clearTimeout(refs.current.two);
  }

  return (
    <>
      {show && (
        <Wrapper className="animate__animated animate__bounceInUp no-swipe">
          <div className="content">
            <div className="hero_wrapper">
              {showHero && (
                <>
                  <div className="uncle_sam animate__animated animate__backInDown">
                    <UncleSamBrSvg width="100" height="100" />
                  </div>

                  <div className="jarvis">
                    <JarvisSvg width="100" height="100" />
                  </div>

                  <div className="pointing animate__animated animate__bounceIn">
                    <JarvisPointingFingersSvg width="75" height="75" />
                  </div>
                </>
              )}
            </div>

            <h2>Quero Você.</h2>

            <ButtonDuolingoRed onClick={redirect} disabled={disabled}>
              Quero me juntar aos nerds!
            </ButtonDuolingoRed>
          </div>

          <Footer />
        </Wrapper>
      )}
    </>
  );
};

export default ChallengeDemoCta;
