// react
import { useState } from "react";

// redux
import { useDispatch } from "react-redux";
import { tagActions } from "redux/slices/tagSlice";

// interfaces
import { TagProps } from "interfaces/tag";
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// factories
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// handlers
import AvatarButtonsHandler, {
  AvatarButtonType,
} from "handlers/AvatarButtonsHandler";
import JarvisPresentationHandler from "handlers/presentation/JarvisPresentationHandler";
import JarvisQuestionBubbleHandler from "handlers/jarvisQuestionBubble/JarvisQuestionBubbleHandler";

// components
import ShopHandler from "handlers/shop/ShopHandler";
import { DropZoneDragWhore } from "components/DropZone";
import LevelUpHandler from "handlers/levelUp/LevelUpHandler";
import SudoHandler from "handlers/challengeButtons/sudo/SudoHandler";
import ChallengeBattle from "components/challenge/battle/ChallengeBattle";
import ChallengeCommonHandlersHandler from "handlers/ChallengeCommonHandlersHandler";

// enums
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

// utils
import numberUtils from "utils/numberUtils";
import tagGuesserUtils from "utils/code/tagGuesserUtils";

interface CofCofProps {
  content: string;
  type: AlertFactoryType;
}

interface ChallengeIntroductionProps extends ChallengeTypeHandlerCommonProps {
  onFailTag(length: number, subtype: FlyingObjectSubType): void;
  alertLostScore(bounds: { left: string; top: string }, length: number): void;
}

const ChallengeIntroduction = ({
  code,
  paused,
  onFailTag,
  challenge,
  wrapperRef,
  blinkElRef,
  isTagValid,
  onSuccessTag,
  alertLostScore,
  reOpenChallenge,
  handlerWrapperRef,
  wrapperNotifiersRef,
  handlerNotifiersRef,
}: ChallengeIntroductionProps) => {
  const dispatch = useDispatch();
  const [startSudo, setStartSudo] = useState(false);
  const { tags, specialTags, flowInit, flowStarted, flowDone, flowFinished } =
    challenge;
  const [showJarvisPresentation, setShowJarvisPresentation] = useState(false);

  function failTag(bounds: { top: string; left: string }) {
    const length = numberUtils.randomInterval(2, 6);

    alertLostScore(bounds, length);
    onFailTag(length, challenge.avatarSubtype);
  }

  function showJarvis(): boolean {
    return !flowDone && !flowFinished && !paused && !showJarvisPresentation;
  }

  function callJarvisPresentation() {
    setShowJarvisPresentation(true);
  }

  function finishJarvisPresentation() {
    setShowJarvisPresentation(false);
  }

  function help() {
    if (flowDone) return;
    if (flowInit && !flowStarted) return cofCof();

    const tag = tagGuesserUtils.get(tags, specialTags, code);
    if (!tag) return;

    add({ ...tag, fromAvatar: true });
    dispatch(tagActions.async.help());
  }

  function add(tag: TagProps) {
    if (isTagValid(tag)) return onSuccessTag(tag);
    fail();
  }

  function cofCof() {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const { clientWidth, clientHeight } = wrapper;

    alertAnimate({
      wrapper: wrapper,
      factories: [AlertFactory({ ...getGoodCofCof(), slower: true })],
      bounds: {
        top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
      },
    });
  }

  function getGoodCofCof(): CofCofProps {
    const random = ["???", "?????", "????????"];

    return {
      content: random[numberUtils.randomInterval(0, random.length - 1)],
      type: AlertFactoryType.Success,
    };
  }

  function finishedQuestions() {
    setTimeout(() => setStartSudo(true));
  }

  return (
    <>
      <ChallengeBattle
        code={code}
        paused={paused}
        onFailedTag={failTag}
        challenge={challenge}
        wrapperRef={wrapperRef}
        isTagValid={isTagValid}
        blinkElRef={blinkElRef}
        onSuccessTag={onSuccessTag}
        reOpenChallenge={reOpenChallenge}
        handlerWrapperRef={handlerWrapperRef}
        handlerNotifiersRef={handlerNotifiersRef}
        wrapperNotifiersRef={wrapperNotifiersRef}
      />

      <AvatarButtonsHandler
        onClick={help}
        show={showJarvis()}
        type={AvatarButtonType.Jarvis}
      />

      <ShopHandler
        paused={paused}
        challenge={challenge}
        wrapper={wrapperRef.current}
      />

      <DropZoneDragWhore />

      <LevelUpHandler
        paused={paused}
        challenge={challenge}
        wrapper={wrapperRef.current}
      />

      {startSudo && (
        <>
          <SudoHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
          />

          <ChallengeCommonHandlersHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
            wrapperNotifiers={wrapperNotifiersRef.current}
          />

          <JarvisPresentationHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
            callPresentation={callJarvisPresentation}
            finishPresentation={finishJarvisPresentation}
          />
        </>
      )}

      {flowInit && (
        <JarvisQuestionBubbleHandler
          paused={paused}
          challenge={challenge}
          finished={finishedQuestions}
          wrapper={wrapperRef.current}
        />
      )}
    </>
  );
};

export default ChallengeIntroduction;
