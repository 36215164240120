// svgs
import SunPixelSvg from "svg/SunPixelSvg";
import SpaceInvaderOneSvg from "svg/monsters/SpaceInvaderOneSvg";
import SpaceInvaderTwoSvg from "svg/monsters/SpaceInvaderTwoSvg";
import SpaceInvaderSixSvg from "svg/monsters/SpaceInvaderSixSvg";
import SpaceInvaderFiveSvg from "svg/monsters/SpaceInvaderFiveSvg";
import SpaceInvaderFourSvg from "svg/monsters/SpaceInvaderFourSvg";
import SpaceInvaderThreeSvg from "svg/monsters/SpaceInvaderThreeSvg";
import SpaceInvaderSevenSvg from "svg/monsters/SpaceInvaderSevenSvg";
import NerdfyCivilizationPixelIcon from "icons/NerdfyCivilizationPixelIcon";

// icons
import InterrogationPixelIcon from "icons/InterrogationPixelIcon";

// svgs
import JarvisSvg from "svg/characters/JarvisSvg";
import EnonMurfSvg from "svg/characters/EnonMurfSvg";
import AstrogildoSvg from "svg/characters/AstrogildoSvg";
import ZarvoxSintarSvg from "svg/characters/ZarvoxSintarSvg";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// entities
import AbstractItemEntity from "entities/items/AbstractItemEntity";

// utils
import color from "styles/color";

const W_D = "25";
const H_D = "25";

const labelInterrogation = () => (
  <InterrogationPixelIcon width="25" height="25" color={color.yellow} />
);

export class ItemInvaderOneEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 1,
      checkpointIndex: PlayerCheckpointEnum.InvaderOne,
      label: <SpaceInvaderOneSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemInvaderTwoEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 2,
      checkpointIndex: PlayerCheckpointEnum.InvaderTwo,
      label: <SpaceInvaderTwoSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemInvaderThreeEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 3,
      checkpointIndex: PlayerCheckpointEnum.InvaderThree,
      label: <SpaceInvaderThreeSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemInvaderFourEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 4,
      checkpointIndex: PlayerCheckpointEnum.InvaderFour,
      label: <SpaceInvaderFourSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemInvaderFiveEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 5,
      checkpointIndex: PlayerCheckpointEnum.InvaderFive,
      label: <SpaceInvaderFiveSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemInvaderSixEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 6,
      checkpointIndex: PlayerCheckpointEnum.InvaderSix,
      label: <SpaceInvaderSixSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemInvaderSevenEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 7,
      checkpointIndex: PlayerCheckpointEnum.InvaderSeven,
      label: <SpaceInvaderSevenSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCivilizationOneEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 1,
      checkpointIndex: PlayerCheckpointEnum.CivilizationNerd,
      label: <NerdfyCivilizationPixelIcon width="25" height="25" />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCivilizationTwoEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 2,
      checkpointIndex: PlayerCheckpointEnum.CivilizationRavians,
      label: <SunPixelSvg width="25" height="25" />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCivilizationThreeEntity extends AbstractItemEntity {
  constructor(label = "ergonia") {
    super({
      index: 3,
      checkpointIndex: PlayerCheckpointEnum.CivilizationErgonians,
      label: (
        <p className="nerdfy_grey_stronger font_size_20">
          <span className="nerdfy_green">{label}</span>
        </p>
      ),
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCharacterOneEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 1,
      checkpointIndex: PlayerCheckpointEnum.Astrogildo,
      label: <AstrogildoSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCharacterTwoEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 2,
      checkpointIndex: PlayerCheckpointEnum.Jarvis,
      label: <JarvisSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCThreeEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 3,
      checkpointIndex: PlayerCheckpointEnum.Zarvox,
      label: <ZarvoxSintarSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCharacterFourEntity extends AbstractItemEntity {
  constructor() {
    super({
      index: 4,
      checkpointIndex: PlayerCheckpointEnum.Enon,
      label: <EnonMurfSvg width={W_D} height={H_D} />,
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCompanyOneEntity extends AbstractItemEntity {
  constructor(label = "Nerdflix") {
    super({
      index: 1,
      checkpointIndex: PlayerCheckpointEnum.CompanyNerdflix,
      label: (
        <p className="nerdfy_grey_stronger font_size_20">
          <span className="nerdflix_color">{label}</span>
        </p>
      ),
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCompanyTwoEntity extends AbstractItemEntity {
  constructor(label = "NerdBook") {
    super({
      index: 2,
      checkpointIndex: PlayerCheckpointEnum.CompanyNerdBook,
      label: (
        <p className="nerdfy_grey_stronger font_size_20">
          <span className="nerdbook_color">{label}</span>
        </p>
      ),
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCompanyThreeEntity extends AbstractItemEntity {
  constructor(label = "nerdX") {
    super({
      index: 3,
      checkpointIndex: PlayerCheckpointEnum.CompanyNerdX,
      label: (
        <p className="nerdfy_grey_stronger font_size_20">
          <span className="nerdX_color">{label}</span>
        </p>
      ),
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCompanyFourEntity extends AbstractItemEntity {
  constructor(label = "Nerdtel") {
    super({
      index: 4,
      checkpointIndex: PlayerCheckpointEnum.CompanyNerdtel,
      label: (
        <p className="nerdfy_grey_stronger font_size_20">
          <span className="nerdtel_color">{label}</span>
        </p>
      ),
      labelShadow: labelInterrogation(),
    });
  }
}

export class ItemCompanyFiveEntity extends AbstractItemEntity {
  constructor(label = "Nerdsoft") {
    super({
      index: 5,
      checkpointIndex: PlayerCheckpointEnum.CompanyNerdsoft,
      label: (
        <p className="nerdfy_grey_stronger font_size_20">
          <span className="nerdsoft_color">{label}</span>
        </p>
      ),
      labelShadow: labelInterrogation(),
    });
  }
}
