// redux
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import {
  Persistor,
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

// middlewares
import tagMiddleware from "redux/middlewares/tagMiddleware";
import shopMiddleware from "redux/middlewares/shopMiddleware";
import playerMiddleware from "redux/middlewares/playerMiddleware";
import feedbackMiddleware from "redux/middlewares/feedbackMiddleware";
import challengeMiddleware from "redux/middlewares/challengeMiddleware";
import classRoomMiddleware from "redux/middlewares/classRoomMiddleware";
import jarvisChatsMiddleware from "redux/middlewares/jarvisChatsMiddleware";
import walkieTalkieMiddleware from "redux/middlewares/walkieTalkieMiddleware";
import challengeQuizMiddleware from "redux/middlewares/challengeQuizMiddleware";
import nextChallengeMiddleware from "redux/middlewares/nextChallengeMiddleware";
import bulletMiddleware from "redux/middlewares/spaceInvaders/bulletMiddleware";
import challengesMergedMiddleware from "redux/middlewares/challengesMiddleware";
import invadersMiddleware from "redux/middlewares/spaceInvaders/invadersMiddleware";
import explosionMiddleware from "redux/middlewares/spaceInvaders/explosionMiddleware";
import strongForceMiddleware from "redux/middlewares/spaceInvaders/strongForceMiddleware";
import flyingObjectMiddleware from "redux/middlewares/spaceInvaders/flyingObjectMiddleware";
import invaderBulletMiddleware from "redux/middlewares/spaceInvaders/invaderBulletMiddleware";
import spaceshipItemsMiddleware from "redux/middlewares/spaceInvaders/spaceshipItemsMiddleware";
import invadersFriendlyMiddleware from "redux/middlewares/spaceInvaders/invadersFriendlyMiddleware";
import spaceshipFeaturesMiddleware from "redux/middlewares/spaceInvaders/spaceshipFeaturesMiddleware";
import spaceshipResourcesMiddleware from "redux/middlewares/spaceInvaders/spaceshipResourcesMiddleware";
import spaceshipMachineGunMiddleware from "redux/middlewares/spaceInvaders/spaceshipMachineGunMiddleware";

// reducers
import appReducers from "redux/reducers";

// interfaces
import { AppProps } from "interfaces/app";
import { AuthProps } from "interfaces/auth";
import { StoryProps } from "interfaces/story";
import { EventProps } from "interfaces/event";
import { NpcHashProps } from "interfaces/npc";
import { PlayerProps } from "interfaces/player";
import { ShopStateProps } from "interfaces/shop";
import { ClassRoomProps } from "interfaces/classRoom";
import { ChallengeProps } from "interfaces/challenge";
import { JarvisChatProps } from "interfaces/jarvisChat";
import { NextChallengeProps } from "interfaces/nextChallenge";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { BulletProps } from "interfaces/spaceInvaders/bullet";
import { ChallengeFlowProps } from "interfaces/challengeFlow";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { WalkieTalkieStateProps } from "interfaces/walkieTalkie";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { ExplosionProps } from "interfaces/spaceInvaders/explosion";
import { ChallengesMergedProps } from "interfaces/challengesMerged";
import { InvadersInfoProps } from "interfaces/spaceInvaders/invader";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";
import { ProgrammingLanguageProps } from "interfaces/programmingLanguage";
import { ClassRoomRatingSummaryProps } from "interfaces/classRoomRating";
import { FlyingObjectProps } from "interfaces/spaceInvaders/flyingObject";
import { InvaderBulletProps } from "interfaces/spaceInvaders/invaderBullet";
import { SpaceshipItemsStateProps } from "interfaces/spaceInvaders/spaceshipItem";
import { SpaceshipFeaturesProps } from "interfaces/spaceInvaders/spaceshipFeatures";
import { SpaceshipResourcesProps } from "interfaces/spaceInvaders/spaceshipResources";
import { SpaceshipMachineGunProps } from "interfaces/spaceInvaders/spaceshipMachineGun";
import { StrongForceBarrierStateProps } from "interfaces/spaceInvaders/strongForceBarrier";

export interface ApplicationState {
  app: AppProps;
  auth: AuthProps;
  player: PlayerProps;
  playerCheckpoints: PlayerCheckpointsStateProps;
  npc: NpcHashProps;
  classRoom: ClassRoomProps;
  programmingLanguages: ProgrammingLanguageProps[];
  challengeSections: ChallengeSectionProps[];
  challengeSection: ChallengeSectionProps;
  challenge: ChallengeProps;
  nextChallenge: NextChallengeProps;
  challengeCode: ChallengeCodeProps;
  jarvisChats: JarvisChatProps[];
  jarvisChat: JarvisChatProps[];
  bullets: BulletProps[];
  flyingObjects: FlyingObjectProps[];
  strongForceBarrier: StrongForceBarrierStateProps;
  challengeFlow: ChallengeFlowProps;
  explosions: ExplosionProps[];
  invaders: InvadersInfoProps[];
  invadersFriendly: InvadersInfoProps[];
  invaderBullets: InvaderBulletProps[];
  stories: StoryProps[];
  events: EventProps[];
  classRoomRatingSummary: ClassRoomRatingSummaryProps;
  spaceshipMachineGun: SpaceshipMachineGunProps;
  spaceshipFeatures: SpaceshipFeaturesProps;
  spaceshipResources: SpaceshipResourcesProps;
  challengeQuiz: ChallengeQuizProps;
  challenges: ChallengesMergedProps;
  walkieTalkie: WalkieTalkieStateProps;
  shop: ShopStateProps;
  spaceshipItems: SpaceshipItemsStateProps;
}

const LOCAL_STORAGE_STORE_TOPIC = "87aea99d7054b04afb77b66f971d7929";
const persistConfig = {
  key: LOCAL_STORAGE_STORE_TOPIC,
  storage,
  // do NOT persist these reducers
  blacklist: [
    "app",
    "challengeFlow",
    "jarvisAnswers",
    "classRooms",
    "player",
    "stories",
    "events",
    "shop",
    "npc",
    "playerCheckpoints",
    "walkieTalkie",
    "classRoomRatings",
    "classRoom",
    "jarvisChats",
    "jarvisChat",
    "nextChallenge",
    "challengeCode",
    "challenge",
    "challengeQuiz",
    "challengesMerged",
    "classRoomRatingSummary",
    "spaceshipItems",
    "challengeSections",
    "challengeSection",
    "bullets",
    "explosions",
    "flyingObjects",
    "strongForceBarrier",
    "challengeModal",
    "spaceInvaders",
    "invaders",
    "invadersFriendly",
    "invaderBullets",
    "spaceshipMachineGun",
    "spaceshipResources",
  ],
};

const persistedReducer = persistReducer(persistConfig, appReducers);
const middlewares = [
  tagMiddleware.middleware,
  challengeMiddleware.middleware,
  jarvisChatsMiddleware.middleware,
  feedbackMiddleware.middleware,
  bulletMiddleware.middleware,
  flyingObjectMiddleware.middleware,
  explosionMiddleware.middleware,
  invadersMiddleware.middleware,
  invaderBulletMiddleware.middleware,
  playerMiddleware.middleware,
  spaceshipResourcesMiddleware.middleware,
  spaceshipMachineGunMiddleware.middleware,
  nextChallengeMiddleware.middleware,
  challengeQuizMiddleware.middleware,
  challengesMergedMiddleware.middleware,
  strongForceMiddleware.middleware,
  classRoomMiddleware.middleware,
  walkieTalkieMiddleware.middleware,
  shopMiddleware.middleware,
  spaceshipItemsMiddleware.middleware,
  invadersFriendlyMiddleware.middleware,
  spaceshipFeaturesMiddleware.middleware,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(middlewares),
});

export const persistor: Persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
