// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { invaderBulletsActions } from "redux/slices/spaceInvaders/invaderBulletsSlice";

const invaderBulletMiddleware = createListenerMiddleware();

// bullet/fire
invaderBulletMiddleware.startListening({
  actionCreator: invaderBulletsActions.async.fire,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.dispatch(invaderBulletsActions.add(payload));
  },
});

export default invaderBulletMiddleware;
