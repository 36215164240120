// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { shopActions } from "redux/slices/shopSlice";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";
import FormHeader from "components/form/FormHeader";
import { Celebration } from "components/Celebration";
import { DropZoneDragWhore } from "components/DropZone";
import ShopSubHeader from "handlers/shop/ShopSubHeader";
import DragWhores from "components/dragWhores/DragWhores";
import { BubbleQuestionSubHeader } from "handlers/bubble/Bubble";
import { DragWhoreTemplateElProps } from "components/dragWhores/DragWhore";

// widgets
import { SpaceshipNeededResourcesInfoWidget } from "components/widgets/SpaceshipResourcesInfoWidget";

// svgs
import DonePixelSvg from "icons/DonePixelIcon";

// enums
import { BubbleType } from "enums/bubbleEnum";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import { ShopItemBubbleProps } from "interfaces/shop";
import { ShopItemEnrichedProps } from "handlers/shop/ShopItems";
import { DragWhoreProps, CurrentDragWhoreProps } from "interfaces/dragWhore";

// entities
import SpaceshipResourcesStatusEntity, {
  ResourcesCommonProps,
} from "entities/spaceshipResources/SpaceshipResourcesStatusEntity";

// utils
import color from "styles/color";
import interact from "interactjs";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import numberUtils from "utils/numberUtils";
import dragWhoreUtils from "utils/dragWhoreUtils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9001;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    .img_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 4px dashed ${color.grey.dark};
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .title_wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      h2 {
        font-size: 22px;
        color: ${color.yellow};
        margin: 0;
        padding: 0;
        text-align: center;
      }

      h4 {
        font-size: 20px;
        color: ${color.grey.strong};
        margin: 5px 0 0 0;
        padding: 0;
      }
    }

    p {
      font-size: 18px;
      text-align: left;
      color: ${color.grey.middle};
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    opacity: 0.6;
    padding: 0 10px;

    h2 {
      font-size: 22px;
      color: ${color.grey.stronger};
      margin: 0 0 10px 0;
      padding: 0;
    }
  }
`;

const DragWhoreJarvisQuestionTemplateEl = ({
  whore,
}: DragWhoreTemplateElProps) => {
  const payload = whore.payload as ShopItemBubbleProps;

  return payload.read ? (
    <p className={`nerdfy_orange pixelify_font_family opacity_text_read`}>
      {payload.question}{" "}
      <span>
        <DonePixelSvg />
      </span>
    </p>
  ) : (
    <p className={`nerdfy_orange pixelify_font_family`}>{payload.question}</p>
  );
};

const DISPLAY_QUESTION_WHORES_DELAY = 500;

interface ItemProps {
  show: boolean;
  onFinish(): void;
  onClose(): void;
  item: ShopItemEnrichedProps;
  wrapper: HTMLDivElement | null;
}

const ShopItemPresentation = ({
  show,
  item,
  onFinish,
  onClose,
  wrapper,
}: ItemProps) => {
  const dispatch = useDispatch();
  const spaceshipResources = useSelector(s.spaceshipResources());
  const bubblesAlreadySetRef = useRef(false);
  const [bought, setBought] = useState(false);
  const [animation, setAnimation] = useState("");
  const [resourcesNeeded, setResourcesNeeded] = useState<
    ResourcesCommonProps[]
  >([]);
  const [questionsWhores, setQuestionsWhores] = useState<DragWhoreProps[]>([]);
  const [currentQuestionWhore, setCurrentQuestionWhore] =
    useState<CurrentDragWhoreProps>(dragWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [item]);
  useEffect(handleAnimation, [show]);
  useEffect(handleResourcesNeeded, [spaceshipResources, item.resourcesPrice]);

  function handleResourcesNeeded() {
    if (isEmpty(spaceshipResources)) return;
    if (isEmpty(item.resourcesPrice)) return;

    const entity = new SpaceshipResourcesStatusEntity({
      resources: spaceshipResources,
      resourcesPrice: item.resourcesPrice,
    });

    setResourcesNeeded(entity.needed);
  }

  function bootstrap() {
    if (!item) return;
    if (isEmpty(item.bubbles)) return;
    if (bubblesAlreadySetRef.current) return;

    bubblesAlreadySetRef.current = true;
    setTimeout(displayQuestionWhores, DISPLAY_QUESTION_WHORES_DELAY);
  }

  function handleAnimation() {
    if (!show) return;
    setAnimation("animate__bounceInUp");
  }

  function buy() {
    if (!isEmpty(resourcesNeeded)) return;

    setBought(true);
    setTimeout(alert);
    setTimeout(close, 3500);
    dispatch(shopActions.async.buy(item.type));
  }

  // DRAG WHORE

  function displayQuestionWhores() {
    setQuestionsWhores([...questionsWhores, ...getQuestionWhores()]);
  }

  function getQuestionWhores(): DragWhoreProps[] {
    const len = item.bubbles.length;
    return item.bubbles.map((d: ShopItemBubbleProps, i) =>
      getQuestionWhore(d, i === len - 1)
    );
  }

  function getQuestionWhore(
    item: ShopItemBubbleProps,
    last = false
  ): DragWhoreProps {
    if (!wrapper) return dragWhoreUtils.fake();

    const { clientWidth, clientHeight } = wrapper;

    return dragWhoreUtils.get({
      last,
      payload: item,
      call: onDragWhoreCall,
      wrapperWidth: clientWidth,
      wrapperHeight: clientHeight,
    });
  }

  function onDragWhoreCall(whore: DragWhoreProps) {
    setCurrentQuestionWhore({ ...whore, show: true });
  }

  function getBubbleContent(whore: DragWhoreProps): ShopItemBubbleProps {
    return whore.payload as ShopItemBubbleProps;
  }

  function finishedAnswer() {
    if (!currentQuestionWhore.id) return;

    const bubble = {
      ...(currentQuestionWhore.payload as ShopItemBubbleProps),
      read: true,
    };

    updateItemBubbles(bubble);
    setCurrentQuestionWhore({ ...currentQuestionWhore, payload: bubble });
    dispatch(shopActions.async.addShopQuestionAsRead(bubble.id, bubble.itemId));
  }

  // TODO: update via redux...
  function updateItemBubbles(bubble: ShopItemBubbleProps) {
    item.bubbles = item.bubbles.map((b) => {
      if (b.id !== bubble.id) return b;
      if (b.itemId !== bubble.itemId) return b;

      b = {
        ...b,
        read: true,
      };

      return b;
    });
  }

  function hideBubble() {
    setCurrentQuestionWhore(dragWhoreUtils.fake());
  }

  function alert() {
    if (!wrapper) return;

    const { clientWidth, clientHeight } = wrapper;

    alertAnimate({
      wrapper: wrapper as HTMLDivElement,
      factories: [
        AlertFactory({
          slower: true,
          type: AlertFactoryType.Success,
          content: `Você adquiriu um item: ${item.title}`,
        }),
      ],
      bounds: {
        top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
      },
    });
  }

  function close() {
    onClose();
    setAnimation("animate__bounceOutLeft");
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();

    if (e.animationName === "bounceOutLeft") {
      reset();
      onFinish();
    }
  }

  function reset() {
    setBought(false);
    setQuestionsWhores([]);
    bubblesAlreadySetRef.current = false;
    setCurrentQuestionWhore(dragWhoreUtils.fake());
  }

  return (
    <>
      {!!animation && (
        <Content
          className={`animate__animated no-swipe ${animation}`}
          onAnimationEnd={onAnimationEnd}
        >
          {bought && <Celebration />}

          <FormHeader
            close={close}
            generic={buy}
            genericLabel="Comprar"
            disabledClose={bought}
            disabled={bought || !item.available || !isEmpty(resourcesNeeded)}
          />

          <BubbleHandler
            hide={hideBubble}
            type={BubbleType.Market}
            onAnimationEnd={onAnimationEnd}
            finishedTyping={finishedAnswer}
            show={currentQuestionWhore.show}
            content={getBubbleContent(currentQuestionWhore).answer}
            subHeader={
              <BubbleQuestionSubHeader
                questionLabelClassName="nerdfy_orange"
                read={getBubbleContent(currentQuestionWhore).read}
                question={getBubbleContent(currentQuestionWhore).question}
              />
            }
          />

          <ShopSubHeader />

          <div className="content">
            <div className="img_wrapper">{item.image}</div>

            <div className="title_wrapper pixelify_font_family">
              <h2>{item.title}</h2>
              {!!item.subTitle && <h4>{item.subTitle}</h4>}
            </div>
          </div>

          <footer>
            {!isEmpty(resourcesNeeded) && (
              <>
                <h2>Recursos necessários</h2>

                <SpaceshipNeededResourcesInfoWidget
                  resources={resourcesNeeded}
                />
              </>
            )}
          </footer>

          <DragWhores
            clear={bought}
            whores={questionsWhores}
            dropZoneBorderColor="orange"
            disabledDrop={currentQuestionWhore.show}
            TemplateEl={DragWhoreJarvisQuestionTemplateEl}
            draggableClassName="drag_whore_draggable_shop_item"
            droppableAcceptClassName="drag_whore_droppable_shop_item"
            interactDraggable={interact(".drag_whore_draggable_shop_item")}
            interactDropZone={interact(".drag_whore_dropzone_shop_item")}
          />

          <DropZoneDragWhore dropZoneClassName="drag_whore_dropzone_shop_item" />
        </Content>
      )}
    </>
  );
};

export default ShopItemPresentation;
