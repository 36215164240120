interface SvgProps {
  width?: string;
  height?: string;
}

const RavianNerdSvg = ({ width = "30", height = "30" }: SvgProps) => {
  return (
    <svg
      viewBox="-24 0 511 511.99872"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-6.703125-11.800781-32.753906-9.660156-58.191406 4.78125-5.125 2.910157-9.824219 6.097657-14.019531 9.429688 9.695312-23.136719 8.625-45.363281-2.933594-50.851562-12.257813-5.824219-31.667969 9.402343-43.351563 34.003906-5.796875 12.207031-8.597656 24.425781-8.453125 34.351562.035156 2.515625-1.714844 4.695313-4.1875 5.152344-69.0625 12.746094-124.796875 63.519531-144.675781 129.800781-7.398437 24.683594-18.015625 48.28125-32.183594 69.800781-12.8125 19.453126-20.21875 42.75-20.207031 67.789063.105469 226.476563 462.183594 226.476563 462.289062 0 .011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fee45a"
      />
      <path
        d="m443.410156 275.621094c-14.015625-21.859375-25.421875-45.3125-32.761718-70.222656-15.613282-52.988282-54.117188-96.164063-104.066407-118.09375-3.660156-1.605469-4.199219-6.539063-.972656-8.898438 18.707031-13.691406 28.777344-30.511719 23.09375-40.523438-4.609375-8.113281-18.363281-9.636718-34.777344-5.105468 2.417969 1.238281 4.3125 2.933594 5.546875 5.105468 5.683594 10.011719-4.382812 26.832032-23.09375 40.523438-3.222656 2.359375-2.6875 7.292969.972656 8.898438 49.949219 21.929687 88.449219 65.105468 104.066407 118.09375 7.339843 24.910156 18.746093 48.363281 32.761719 70.222656 12.300781 19.191406 19.390624 42.023437 19.378906 66.519531-.050782 108.460937-106.054688 164.972656-216.527344 169.546875 120.207031 4.980469 245.703125-51.53125 245.757812-169.546875.011719-24.496094-7.078124-47.328125-19.378906-66.519531zm0 0"
        fill="#fac600"
      />
      <path
        d="m231.644531 375.390625c-26.074219 0-50.414062-4.042969-71.019531-11.039063-6.707031-2.273437-12.875 5.132813-11.335938 13.503907 8.996094 48.894531 42.445313 85.210937 82.355469 85.210937 39.910157 0 73.359375-36.316406 82.355469-85.210937 1.542969-8.371094-4.628906-15.777344-11.332031-13.503907-20.613281 6.996094-44.949219 11.039063-71.023438 11.039063zm0 0"
        fill="#8c2f3f"
      />
      <path
        d="m370.90625 335.335938h-91.292969c-12.996093 0-23.535156-10.535157-23.535156-23.53125v-79.070313c0-12.996094 10.539063-23.535156 23.535156-23.535156h91.292969c12.996094 0 23.53125 10.539062 23.53125 23.535156v79.070313c0 12.996093-10.53125 23.53125-23.53125 23.53125zm0 0"
        fill="#92dbf4"
      />
      <path
        d="m183.675781 335.335938h-91.296875c-12.996094 0-23.53125-10.535157-23.53125-23.53125v-79.070313c0-12.996094 10.535156-23.535156 23.53125-23.535156h91.296875c12.996094 0 23.53125 10.539062 23.53125 23.535156v79.070313c0 12.996093-10.535156 23.53125-23.53125 23.53125zm0 0"
        fill="#92dbf4"
      />
      <g fill="#353535">
        <path d="m343.78125 272.269531c0 10.226563-8.292969 18.519531-18.519531 18.519531-10.230469 0-18.519531-8.292968-18.519531-18.519531 0-10.230469 8.289062-18.519531 18.519531-18.519531 10.226562 0 18.519531 8.289062 18.519531 18.519531zm0 0" />
        <path d="m156.546875 272.269531c0 10.226563-8.289063 18.519531-18.519531 18.519531-10.226563 0-18.519532-8.292968-18.519532-18.519531 0-10.230469 8.292969-18.519531 18.519532-18.519531 10.230468 0 18.519531 8.289062 18.519531 18.519531zm0 0" />
        <path d="m353.726562 185.234375c-2.238281 0-4.457031-.964844-5.984374-2.832031-6.105469-7.460938-14.09375-11.566406-22.480469-11.566406-8.390625 0-16.375 4.109374-22.484375 11.566406-2.703125 3.300781-7.570313 3.789062-10.871094 1.085937-3.304688-2.703125-3.789062-7.570312-1.085938-10.871093 9.097657-11.113282 21.328126-17.234376 34.441407-17.234376 13.109375 0 25.339843 6.121094 34.4375 17.234376 2.703125 3.300781 2.21875 8.167968-1.082031 10.871093-1.4375 1.175781-3.171876 1.746094-4.890626 1.746094zm0 0" />
        <path d="m166.496094 185.234375c-2.238282 0-4.457032-.964844-5.984375-2.832031-6.105469-7.460938-14.09375-11.566406-22.480469-11.566406-8.394531 0-16.375 4.109374-22.484375 11.566406-2.703125 3.300781-7.570313 3.789062-10.871094 1.085937-3.304687-2.703125-3.789062-7.570312-1.085937-10.871093 9.097656-11.113282 21.328125-17.234376 34.441406-17.234376 13.109375 0 25.339844 6.121094 34.4375 17.234376 2.703125 3.300781 2.21875 8.167968-1.085938 10.871093-1.433593 1.175781-3.167968 1.746094-4.886718 1.746094zm0 0" />
        <path d="m370.910156 201.476562h-91.296875c-17.238281 0-31.257812 14.019532-31.257812 31.257813v30.664063h-33.421875v-30.664063c0-17.238281-14.019532-31.261719-31.257813-31.261719h-91.296875c-17.234375 0-31.257812 14.023438-31.257812 31.261719v79.066406c0 17.238281 14.023437 31.261719 31.257812 31.261719h91.296875c17.238281 0 31.257813-14.023438 31.257813-31.261719v-32.949219h33.421875v32.949219c0 17.238281 14.019531 31.261719 31.257812 31.261719h91.296875c17.234375 0 31.257813-14.023438 31.257813-31.261719v-79.066406c0-17.238281-14.023438-31.257813-31.257813-31.257813zm-171.429687 110.328126c0 8.714843-7.089844 15.804687-15.804688 15.804687h-91.292969c-8.714843 0-15.804687-7.089844-15.804687-15.804687v-79.070313c0-8.714844 7.089844-15.808594 15.804687-15.808594h91.292969c8.714844 0 15.804688 7.09375 15.804688 15.808594zm187.234375 0c0 8.714843-7.089844 15.804687-15.804688 15.804687h-91.296875c-8.714843 0-15.804687-7.089844-15.804687-15.804687v-79.070313c0-8.714844 7.089844-15.808594 15.804687-15.808594h91.296875c8.714844 0 15.804688 7.09375 15.804688 15.808594zm0 0" />
      </g>
      <path
        d="m231.644531 375.390625c-9.394531 0-18.558593-.535156-27.410156-1.53125l1.519531 22.050781c.414063 6 6.007813 10.265625 11.90625 9.082032 5.183594-1.042969 10.878906-2.019532 13.984375-2.019532s8.800781.976563 13.984375 2.019532c5.898438 1.183593 11.492188-3.082032 11.90625-9.082032l1.519532-22.050781c-8.851563.996094-18.015626 1.53125-27.410157 1.53125zm0 0"
        fill="#e8eaea"
      />
      <path
        d="m272.8125 449.035156c-10.277344-11.011718-24.914062-17.90625-41.167969-17.90625-16.253906 0-30.890625 6.894532-41.167969 17.90625 12.1875 8.9375 26.222657 14.03125 41.167969 14.03125 14.941407 0 28.980469-5.09375 41.167969-14.03125zm0 0"
        fill="#fd4755"
      />
    </svg>
  );
};

export default RavianNerdSvg;
