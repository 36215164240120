// enums
import { UserGenreType } from "enums/userEnum";

// constants
import {
  PlayerDegreeValueProps,
  PLAYER_DEGREES_DARK_SIDE,
  PLAYER_DEGREES_LIGHT_SIDE,
  PlayerDegreesDarkSideCheckpoint,
  PlayerDegreesLightSideCheckpoint,
} from "constants/player";

interface DegreesProps {
  [key: string]: PlayerDegreeValueProps;
}

class PlayerDegreeEntity {
  genre: UserGenreType;
  score: number;

  constructor({ genre, score }: { genre: UserGenreType; score: number }) {
    this.genre = genre;
    this.score = score;
  }

  get(): PlayerDegreeValueProps {
    if (!this.genre)
      return {
        name: "",
        lightSide: true,
        checkpoint: PlayerDegreesLightSideCheckpoint.Zero,
      };

    const degrees = this.isLightSide(this.score)
      ? PLAYER_DEGREES_LIGHT_SIDE[this.genre]
      : PLAYER_DEGREES_DARK_SIDE[this.genre];

    return this._get(degrees, Math.abs(this.score));
  }

  private isLightSide(score: number): boolean {
    return score > PlayerDegreesDarkSideCheckpoint.Zero;
  }

  private _get(degrees: DegreesProps, score: number): PlayerDegreeValueProps {
    const { zero, one, two, three, four, five, six, seven, eight, nine, ten } =
      degrees;

    if (score < Math.abs(one.checkpoint)) return zero;
    if (score < Math.abs(two.checkpoint)) return one;
    if (score < Math.abs(three.checkpoint)) return two;
    if (score < Math.abs(four.checkpoint)) return three;
    if (score < Math.abs(five.checkpoint)) return four;
    if (score < Math.abs(six.checkpoint)) return five;
    if (score < Math.abs(seven.checkpoint)) return six;
    if (score < Math.abs(eight.checkpoint)) return seven;
    if (score < Math.abs(nine.checkpoint)) return eight;
    if (score < Math.abs(ten.checkpoint)) return nine;

    return ten;
  }
}

export default PlayerDegreeEntity;
