// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
}

const SpaceshipMachineGunTargetIcon = ({
  width = "18",
  height = "18",
  color = c.grey.strong,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M138.18 138.18h47.13v47.13h-47.13zM232.44 373.82h-47.13v47.13h141.38v-47.13h-47.13zM138.18 232.44H91.05v94.25h47.13v-47.13zM138.18 326.69h47.13v47.13h-47.13zM326.69 138.18h47.13v47.13h-47.13zM373.82 279.56v47.13h47.13v-94.25h-47.13zM91.05 185.31h47.13v47.13H91.05zM232.44 232.44h47.13v47.13h-47.13zM373.82 185.31h47.13v47.13h-47.13zM279.56 91.05h-94.25v47.13h141.38V91.05zM326.69 326.69h47.13v47.13h-47.13z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default SpaceshipMachineGunTargetIcon;
