// react
import { useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeQuizActions } from "redux/slices/challengeQuizSlice";

// components
import ChallengeQuizBattle from "components/challengeQuiz/ChallengeQuizBattle";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ChallengeQuizProps {
  handlerWrapperRef: (node: HTMLDivElement) => void;
  handlerNotifiersRef: (node: HTMLDivElement) => void;
  reOpenChallenge: (data: ChallengeCommonProps) => void;
  blinkElRef: React.MutableRefObject<HTMLDivElement | null>;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  wrapperNotifiersRef: React.MutableRefObject<HTMLDivElement | null>;
  currentChallengeRef: React.MutableRefObject<ChallengeCommonProps | null>;
}

const ChallengeQuiz = ({
  blinkElRef,
  wrapperRef,
  reOpenChallenge,
  handlerWrapperRef,
  handlerNotifiersRef,
  wrapperNotifiersRef,
  currentChallengeRef,
}: ChallengeQuizProps) => {
  const dispatch = useDispatch();
  const quiz = useSelector(s.challengeQuiz());
  const { paused } = useSelector(s.challengeFlow());
  const { flowInit } = quiz;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleInitFlow, [paused, flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToCurrentChallenge, [quiz]);

  function handleInitFlow() {
    if (paused) return;
    if (flowInit) return;

    setTimeout(() => dispatch(challengeQuizActions.async.init()));
  }

  function listenToCurrentChallenge() {
    currentChallengeRef.current = quiz;
  }

  return (
    <ChallengeQuizBattle
      quiz={quiz}
      paused={paused}
      wrapperRef={wrapperRef}
      blinkElRef={blinkElRef}
      reOpenChallenge={reOpenChallenge}
      handlerWrapperRef={handlerWrapperRef}
      wrapperNotifiersRef={wrapperNotifiersRef}
      handlerNotifiersRef={handlerNotifiersRef}
    />
  );
};

export default ChallengeQuiz;
