// react
import { useState } from "react";
import { IonGrid, IonRow, IonCol, IonSkeletonText } from "@ionic/react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";
import { challengeQuizActions } from "redux/slices/challengeQuizSlice";
import { challengeSectionActions } from "redux/slices/challengeSectionSlice";

// svgs
import DonePixelSvg from "icons/DonePixelIcon";

// components
import Image from "components/Image";
import Sign from "components/sign/Sign";
import Footer from "components/classRoom/footer/Footer";
import ClassRoomStartButton from "pages/classRoom/ClassRoomStartButton";
import ClassRoomAccordion from "components/classRoom/accordion/ClassRoomAccordion";

// handlers
import ChallengeHandler from "handlers/ChallengeHandler";

// interfaces
import { AuthProps } from "interfaces/auth";
import { ClassRoomProps } from "interfaces/classRoom";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import {
  ChallengeProps,
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";

// utils
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .content {
    flex: 1;
    color: ${color.grey.middle};

    h1,
    h2 {
      font-size: 22px;
    }

    p {
      font-size: 20px;
    }

    .grid {
      width: 100%;

      ul.nav {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 0;

          p {
            display: flex;
            align-items: center;
            margin: 7px;
            padding: 0;

            .icon_wrapper {
              display: inline-block;
              width: 20px;
              margin-right: 15px;

              svg {
                color: ${color.green};
              }
            }
          }
        }
      }

      .header_grid {
        .brand_classroom {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          .image_brand {
            width: 100%;
            height: 240px;

            @media only screen and (min-width: 1400px) {
              height: 300px;
            }

            @media only screen and (min-width: 1650px) {
              height: 300px;
            }

            @media only screen and (min-width: 1750px) {
              height: 300px;
            }

            &.hide-skeleton {
              display: none;
            }

            &.show-skeleton {
              display: block;
            }
          }
        }

        .header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 20px;

          img {
            border-radius: 10px;
          }

          h1 {
            text-align: center;
            font-size: 30px;
          }

          p {
            text-align: center;
            font-size: 25px;
          }
        }
      }
    }
  }
`;

export const NavSkeleton = () => {
  return (
    <ul className="nav">
      <li>
        <p>
          <DonePixelSvg />
          <IonSkeletonText animated style={{ width: "20%" }} />
        </p>
      </li>
      <li>
        <p>
          <DonePixelSvg />
          <IonSkeletonText animated style={{ width: "20%" }} />
        </p>
      </li>
      <li>
        <p>
          <DonePixelSvg />
          <IonSkeletonText animated style={{ width: "20%" }} />
        </p>
      </li>
      <li>
        <p>
          <DonePixelSvg />
          <IonSkeletonText animated style={{ width: "20%" }} />
        </p>
      </li>
    </ul>
  );
};

export interface ChallengesMergedProps {
  all: ChallengeCommonProps[];
  hash: ChallengesCommonHashProps;
}

interface Props {
  auth: AuthProps;
  classRoom: ClassRoomProps;
  classRoomProgress: number;
  loading: boolean;
}

const ClassRoom = ({ auth, classRoom, classRoomProgress, loading }: Props) => {
  const dispatch = useDispatch();
  const sections = useSelector(s.challengeSections());
  const challengesHash = useSelector(s.challengesHash());
  const [isOpenSignModal, setIsOpenSignModal] = useState(false);
  const [isChallengeModalOpen, setIsChallengeModalOpen] = useState(false);
  const [isChallengeQuizModalOpen, setIsChallengeQuizModalOpen] =
    useState(false);
  const { requirements, name, description, image, content } = classRoom;

  function openChallengeQuizModal(
    quiz: ChallengeQuizProps,
    section: ChallengeSectionProps
  ) {
    if (isChallengeQuizModalOpen) return;

    setTimeout(() => {
      dispatch(challengeFlowActions.pause());
      dispatch(challengeQuizActions.set(quiz));
      dispatch(challengeSectionActions.set(section));
      dispatch(nextChallengeActions.async.set(quiz, challengesHash));

      setTimeout(() => setIsChallengeQuizModalOpen(true));
    });
  }

  function openChallengeModal(
    challenge: ChallengeProps,
    section: ChallengeSectionProps
  ) {
    if (isChallengeModalOpen) return;

    setTimeout(() => {
      dispatch(challengeActions.async.openModal(challenge, section));
      setTimeout(() => setIsChallengeModalOpen(true));
    });
  }

  function openSignModal() {
    setIsOpenSignModal(true);
  }

  function closeSignModal() {
    if (!isOpenSignModal) return;
    setIsOpenSignModal(false);
  }

  return (
    <Wrapper>
      <div className="content">
        <IonGrid className="grid">
          <IonRow>
            <IonCol>
              <IonRow className="header_grid">
                <IonCol>
                  <div className="header">
                    <h1 className={`${auth.signed && "nerdfy_white"}`}>
                      {!loading && name}
                      {loading && (
                        <IonSkeletonText
                          animated
                          style={{ width: "60%", marginTop: "20px" }}
                        />
                      )}
                    </h1>

                    <p>
                      {!loading && description}
                      {loading && (
                        <IonSkeletonText
                          animated
                          style={{ width: "40%", textAlign: "center" }}
                        />
                      )}
                    </p>
                  </div>
                </IonCol>

                <IonCol size-md="4" size-sm="12" size-xs="12">
                  <div className="brand_classroom">
                    {!loading && image && <Image item src={image} />}
                    {loading && <div className="image_brand" />}
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <h2>What is nice to have</h2>

                  {!loading && (
                    <ul className="nav">
                      {requirements &&
                        requirements.map((requirement: string, i: number) => (
                          <li key={i}>
                            <p>
                              <span className="icon_wrapper">
                                <DonePixelSvg />
                              </span>

                              <span>{requirement}</span>
                            </p>
                          </li>
                        ))}
                    </ul>
                  )}

                  {loading && <NavSkeleton />}
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <h2>What you will learn</h2>

                  {!loading && (
                    <ul className="nav">
                      {content &&
                        content.map((_content: string, i: number) => (
                          <li key={i}>
                            <p>
                              <span className="icon_wrapper">
                                <DonePixelSvg />
                              </span>

                              <span>{_content}</span>
                            </p>
                          </li>
                        ))}
                    </ul>
                  )}

                  {loading && <NavSkeleton />}
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <h2>Conteúdo</h2>

                  {!loading && !isEmpty(challengesHash) && (
                    <ClassRoomAccordion
                      sections={sections}
                      challenges={challengesHash}
                      openChallengeModal={openChallengeModal}
                      openChallengeQuizModal={openChallengeQuizModal}
                    />
                  )}

                  {loading && <NavSkeleton />}
                </IonCol>
              </IonRow>

              {/* <IonRow>
                <IonCol>
                  <ClassRoomRatingStars />
                </IonCol>
              </IonRow> */}

              {/* <IonRow>
                <IonCol>
                  <ClassRoomRatingComments classRoom={classRoom} />
                </IonCol>
              </IonRow> */}
            </IonCol>
          </IonRow>
        </IonGrid>

        <Footer classRoomProgress={classRoomProgress} loading={loading} />
      </div>

      <ChallengeHandler
        sections={sections}
        challengesMerged={challengesHash}
        openChallengeModal={openChallengeModal}
        isChallengeModalOpen={isChallengeModalOpen}
        toggleChallengeModal={setIsChallengeModalOpen}
        openChallengeQuizModal={openChallengeQuizModal}
        isChallengeQuizModalOpen={isChallengeQuizModalOpen}
        toggleChallengeQuizModal={setIsChallengeQuizModalOpen}
      />

      <Sign close={closeSignModal} isOpen={isOpenSignModal} />

      {!auth.signed && (
        <ClassRoomStartButton onClick={openSignModal} loading={loading} />
      )}
    </Wrapper>
  );
};

export default ClassRoom;
