// react
import { useState, useEffect, useRef } from "react";

// components
import CloseButton from "components/CloseButton";
import ShopItems from "handlers/shop/ShopItems";
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import RaviansPresentation from "components/presentations/RaviansPresentation";

// icons
import IconWhoreAddPerson from "components/iconWhores/IconWhoreAddPerson";

// interfaces
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// handlers
import AvatarButtonsHandler, {
  AvatarButtonType,
} from "handlers/AvatarButtonsHandler";

// utils
import color from "styles/color";
import styled from "styled-components";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000;

  .shop_item {
    header {
      h2 {
        font-size: 16px;
        margin: 0;
        padding: 0;

        &.available {
          color: ${color.yellow};
        }
      }
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      > svg {
        margin-left: 5px;
      }

      > .quantity {
        font-size: 18px;
        margin-left: 5px;
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      padding: 0 20px;
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
    padding: 15px;
  }
`;

const DISPLAY_AVATAR = 500;
const DISPLAY_PRESENTATION_DELAY = 1500;

interface MarketProps {
  show: boolean;
  onClose: () => void;
  wrapper: HTMLDivElement | null;
}

const Shop = ({ show, onClose, wrapper }: MarketProps) => {
  const itemsAlreadySetRef = useRef(false);
  const [showAvatar, setShowAvatar] = useState(false);
  const [animation, setAnimation] = useState("animate__bounceInUp");
  const [showPresentation, setShowPresentation] = useState(false);
  const [showItemPresentation, setShowItemPresentation] = useState(false);
  const [presentationWhores, setPresentationWhores] = useState<
    AttentionWhoreProps[]
  >([]);

  useEffect(handleShowAvatar, [show]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePresentation, [show]);

  function handleShowAvatar() {
    if (!show) return;
    displayAvatar();
  }

  function handlePresentation() {
    if (!show) return;
    displayPresentation();
  }

  function displayAvatar() {
    setTimeout(() => {
      setShowAvatar(true);
    }, DISPLAY_AVATAR);
  }

  function displayPresentation() {
    setTimeout(() => {
      setPresentationWhores([getPresentationWhore()]);
    }, DISPLAY_PRESENTATION_DELAY);
  }

  function getPresentationWhore(): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      payload: "",
      WhoreEl: IconWhoreAddPerson,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "blue",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall() {
    setTimeout(() => setShowPresentation(true));
  }

  function showRavianAvatar(): boolean {
    return showAvatar && !showPresentation && !showItemPresentation;
  }

  function close() {
    reset();
    onClose();
    setTimeout(() => setAnimation("animate__bounceOutLeft"));
  }

  function reset() {
    itemsAlreadySetRef.current = false;
  }

  function onFinishPresentation() {
    setShowPresentation(false);
  }

  function onOpenShopItem() {
    setShowItemPresentation(true);
  }

  function onCloseShopItem() {
    setShowItemPresentation(false);
  }

  return (
    <>
      {show && (
        <Wrapper className={`animate__animated no-swipe ${animation}`}>
          <div className="content">
            <header>
              <CloseButton onClick={close} className="close_button" />
              <div></div>
            </header>

            <ShopItems
              wrapper={wrapper}
              onOpen={onOpenShopItem}
              onClose={onCloseShopItem}
            />

            <AvatarButtonsHandler
              onClick={() => {}}
              show={showRavianAvatar()}
              type={AvatarButtonType.Ravians}
            />
          </div>

          <AttentionWhores whores={presentationWhores} />

          <RaviansPresentation
            wrapper={wrapper}
            show={showPresentation}
            onFinish={onFinishPresentation}
          />

          <DropZoneAttentionWhore />
        </Wrapper>
      )}
    </>
  );
};

export default Shop;
