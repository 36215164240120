// firestore
import { FieldValue, increment } from "firebase/firestore";

// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { JarvisChatFirebaseProps } from "interfaces/jarvisChat";

export interface ValuesProps {
  id: number;
  read?: boolean;
  up?: FieldValue;
  down?: FieldValue;
  questionClicked?: boolean;
}

class GlobalClassRoomJarvisFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  update(
    classRoomId: number,
    challengeId: number,
    jarvisId: number,
    values: JarvisChatFirebaseProps
  ): Promise<void> {
    if (!jarvisId) return Promise.reject();
    if (!challengeId) return Promise.reject();

    const path = `/classRooms/${classRoomId}/challenges/${challengeId}/jarvis/${jarvisId}`;
    const data: ValuesProps = { id: values.id };

    if (values.read) data.read = values.read;
    if (values.questionClicked) data.questionClicked = values.questionClicked;

    if (values.up) data.up = increment(1);
    if (values.down) data.down = increment(1);

    return this._firebase.set(path, data);
  }
}

export default GlobalClassRoomJarvisFirebaseService;
