// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { shopActions } from "redux/slices/shopSlice";
import { useSelector, useDispatch } from "react-redux";

// components
import ShopItem from "handlers/shop/ShopItem";
import { DropZoneDragWhore } from "components/DropZone";
import DragWhores from "components/dragWhores/DragWhores";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// icons
import EyeIcon from "icons/EyeIcon";

// interfaces
import { ShopItemProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { DragWhoreTemplateElProps } from "components/dragWhores/DragWhore";
import { DragWhoreProps, CurrentDragWhoreProps } from "interfaces/dragWhore";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// utils
import color from "styles/color";
import interact from "interactjs";
import isEmpty from "lodash/isEmpty";
import shopItemUtils from "utils/shopItemUtils";
import dragWhoreUtils from "utils/dragWhoreUtils";
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

export interface ShopItemEnrichedProps extends ShopItemProps {
  icon: JSX.Element;
  image: JSX.Element;
  values: PlayerShopItemsFirebaseProps;
  resourcesPrice: SpaceshipResourcesQtyProps;
}

const DragWhoreJarvisQuestionTemplateEl = ({
  whore,
}: DragWhoreTemplateElProps) => {
  const payload = whore.payload as ShopItemEnrichedProps;
  return (
    <div className="shop_item pixelify_font_family">
      {payload.icon}

      <header>
        <h2 className={`${payload.available && "available"}`}>
          {payload.title}
        </h2>
      </header>

      {payload.available ? (
        <p>
          {payload.price} {`(estelin${payload.price > 1 ? "s" : ""})`}{" "}
          {payload.read && (
            <EyeIcon width="25" height="25" color={color.blue} />
          )}
        </p>
      ) : (
        <p>(Indisponível)</p>
      )}
    </div>
  );
};

const DISPLAY_ITEM_WHORES_DELAY = 500;

interface ShopItemsProps {
  onOpen: () => void;
  onClose: () => void;
  wrapper: HTMLDivElement | null;
}

const ShopItems = ({ wrapper, onOpen, onClose }: ShopItemsProps) => {
  const dispatch = useDispatch();
  const items = useSelector(s.shopItems());
  const spaceshipItems = useSelector(s.spaceshipItemsHash());
  const itemsAlreadySetRef = useRef(false);
  const [itemsWhores, setItemsWhores] = useState<DragWhoreProps[]>([]);
  const [currentDragWhore, setCurrentDragWhore] =
    useState<CurrentDragWhoreProps>(dragWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleItems, [items]);

  function handleItems() {
    if (isEmpty(items)) return;
    if (itemsAlreadySetRef.current) return;

    itemsAlreadySetRef.current = true;
    setTimeout(displayItemWhores, DISPLAY_ITEM_WHORES_DELAY);
  }

  function displayItemWhores() {
    setItemsWhores([...itemsWhores, ...getItemWhores()]);
  }

  function getItemWhores(): DragWhoreProps[] {
    const len = items.length;
    return items.map((d: ShopItemProps, i) => getItemWhore(d, i === len - 1));
  }

  function getItemWhore(item: ShopItemProps, last = false): DragWhoreProps {
    if (!wrapper) return dragWhoreUtils.fake();

    const { clientWidth, clientHeight } = wrapper;

    return dragWhoreUtils.get({
      last,
      call: onDragWhoreCall,
      wrapperWidth: clientWidth,
      wrapperHeight: clientHeight,
      payload: enrichShopItem(item),
    });
  }

  function enrichShopItem(item: ShopItemProps): ShopItemEnrichedProps {
    const entity = getEntity(item.type);
    const { icon, image, values, resourcesPrice } = entity;

    const enrichedItem = { ...item, icon, image, values, resourcesPrice };
    if (!spaceshipItems) return enrichedItem;

    const spaceshipItem = spaceshipItems[item.id];
    if (!spaceshipItem) return enrichedItem;

    return { ...item, icon, image, values, resourcesPrice };
  }

  function onDragWhoreCall(whore: DragWhoreProps) {
    const payload = whore.payload as ShopItemProps;

    onOpen();
    setCurrentDragWhore({
      ...whore,
      show: true,
      payload: { ...payload, read: true },
    });

    dispatch(shopActions.async.addShopItemAsRead(payload.id));
  }

  function closeShopItemPresentation() {
    reloadWhores();
    setCurrentDragWhore(dragWhoreUtils.fake());
  }

  function reloadWhores() {
    setTimeout(() => {
      setItemsWhores([
        ...itemsWhores,
        getItemWhore(currentDragWhore.payload as ShopItemProps),
      ]);
    });
  }

  function getCurrentDragWhorePayload(): ShopItemEnrichedProps {
    return currentDragWhore.payload as ShopItemEnrichedProps;
  }

  function getEntity(type: SpaceshipItemEnum): AbstractShopItemEntity {
    const Entity = shopItemUtils.getEntity(type);
    return new Entity();
  }

  return (
    <>
      <ShopItem
        onClose={onClose}
        wrapper={wrapper}
        show={currentDragWhore.show}
        item={getCurrentDragWhorePayload()}
        onFinish={closeShopItemPresentation}
      />

      <DragWhores
        whores={itemsWhores}
        dropZoneBorderColor="yellow"
        TemplateEl={DragWhoreJarvisQuestionTemplateEl}
        draggableClassName="drag_whore_draggable_shop"
        droppableAcceptClassName="drag_whore_droppable_shop"
        interactDraggable={interact(".drag_whore_draggable_shop")}
        interactDropZone={interact(".drag_whore_dropzone_shop")}
      />

      <DropZoneDragWhore dropZoneClassName="drag_whore_dropzone_shop" />
    </>
  );
};

export default ShopItems;
