// enums
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// entities
import BulletEntity from "entities/spaceInvaders/BulletEntity";
import SpaceshipEntity from "entities/spaceInvaders/SpaceshipEntity";
import NonExistentEntity from "entities/spaceInvaders/NonExistentEntity";
import {
  InvaderOneEntity,
  InvaderSixEntity,
  InvaderTwoEntity,
  InvaderFiveEntity,
  InvaderFourEntity,
  InvaderThreeEntity,
  InvaderSevenEntity,
} from "entities/spaceInvaders/InvaderEntity";
import {
  InvaderOneBulletEntity,
  InvaderSixBulletEntity,
  InvaderTwoBulletEntity,
  InvaderFiveBulletEntity,
  InvaderFourBulletEntity,
  InvaderThreeBulletEntity,
  InvaderSevenBulletEntity,
} from "entities/spaceInvaders/InvaderBulletEntity";
import {
  AsteroidWaterEntity,
  AsteroidMineralGoldEntity,
  AsteroidStrongForceEntity,
  AsteroidMineralSandEntity,
  AsteroidMineralSilverEntity,
  AsteroidMineralCopperEntity,
  AsteroidMineralEstelinEntity,
  AsteroidMineralBauxiteEntity,
} from "entities/spaceInvaders/AsteroidEntity";

// utils
import numberUtils from "utils/numberUtils";

function getRandomInvaderSubtype(): FlyingObjectSubType {
  // const n = [FlyingObjectSubType.InvaderOne, FlyingObjectSubType.InvaderFour];
  // const random = numberUtils.randomInterval(1, SPACE_INVADERS.invaders.length);

  // if (random === InvaderOneEntity.index) return FlyingObjectSubType.InvaderOne;
  // if (random === InvaderTwoEntity.index) return FlyingObjectSubType.InvaderTwo;
  // if (random === InvaderThreeEntity.index)
  //   return FlyingObjectSubType.InvaderThree;
  // if (random === InvaderFourEntity.index)
  //   return FlyingObjectSubType.InvaderFour;
  // if (random === InvaderFiveEntity.index)
  //   return FlyingObjectSubType.InvaderFive;
  // if (random === InvaderSixEntity.index) return FlyingObjectSubType.InvaderSix;
  // if (random === InvaderSevenEntity.index)
  //   return FlyingObjectSubType.InvaderSeven;

  // return FlyingObjectSubType.InvaderOne;

  return [FlyingObjectSubType.InvaderOne, FlyingObjectSubType.InvaderFour][
    numberUtils.randomInterval(0, 1)
  ];
}

function getRandomAsteroid() {
  const data = [
    1, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4,
    5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 8,
  ];

  const random = numberUtils.randomInterval(1, data.length);
  const r = data[random];

  if (r === AsteroidMineralGoldEntity.index) return AsteroidMineralGoldEntity;
  if (r === AsteroidMineralSilverEntity.index)
    return AsteroidMineralSilverEntity;
  if (r === AsteroidMineralSandEntity.index) return AsteroidMineralSandEntity;
  if (r === AsteroidMineralBauxiteEntity.index)
    return AsteroidMineralBauxiteEntity;
  if (r === AsteroidMineralCopperEntity.index)
    return AsteroidMineralCopperEntity;
  if (r === AsteroidWaterEntity.index) return AsteroidWaterEntity;
  if (r === AsteroidStrongForceEntity.index) return AsteroidStrongForceEntity;
  if (r === AsteroidMineralEstelinEntity.index)
    return AsteroidMineralEstelinEntity;

  return AsteroidMineralGoldEntity;
}

function getEntity(subtype: FlyingObjectSubType) {
  if (subtype === FlyingObjectSubType.NonExistent) return NonExistentEntity;
  if (subtype === FlyingObjectSubType.SpaceShip) return SpaceshipEntity;
  if (subtype === FlyingObjectSubType.Bullet) return BulletEntity;

  // asteroids
  if (subtype === FlyingObjectSubType.AsteroidMineralGold)
    return AsteroidMineralGoldEntity;
  if (subtype === FlyingObjectSubType.AsteroidMineralSilver)
    return AsteroidMineralSilverEntity;
  if (subtype === FlyingObjectSubType.AsteroidMineralBauxite)
    return AsteroidMineralBauxiteEntity;
  if (subtype === FlyingObjectSubType.AsteroidMineralSand)
    return AsteroidMineralSandEntity;
  if (subtype === FlyingObjectSubType.AsteroidMineralCopper)
    return AsteroidMineralCopperEntity;
  if (subtype === FlyingObjectSubType.AsteroidMineralEstelin)
    return AsteroidMineralEstelinEntity;
  if (subtype === FlyingObjectSubType.AsteroidStrongForce)
    return AsteroidStrongForceEntity;
  if (subtype === FlyingObjectSubType.AsteroidWater) return AsteroidWaterEntity;

  // invaders
  if (subtype === FlyingObjectSubType.InvaderOne) return InvaderOneEntity;
  if (subtype === FlyingObjectSubType.InvaderTwo) return InvaderTwoEntity;
  if (subtype === FlyingObjectSubType.InvaderThree) return InvaderThreeEntity;
  if (subtype === FlyingObjectSubType.InvaderFour) return InvaderFourEntity;
  if (subtype === FlyingObjectSubType.InvaderFive) return InvaderFiveEntity;
  if (subtype === FlyingObjectSubType.InvaderSix) return InvaderSixEntity;
  if (subtype === FlyingObjectSubType.InvaderSeven) return InvaderSevenEntity;

  // bullets
  if (subtype === FlyingObjectSubType.InvaderOneBullet)
    return InvaderOneBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderTwoBullet)
    return InvaderTwoBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderThreeBullet)
    return InvaderThreeBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderFourBullet)
    return InvaderFourBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderFiveBullet)
    return InvaderFiveBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderSixBullet)
    return InvaderSixBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderSevenBullet)
    return InvaderSevenBulletEntity;

  return NonExistentEntity;
}

function getInvaderBulletByInvader(subtype: FlyingObjectSubType) {
  if (subtype === FlyingObjectSubType.InvaderOne) return InvaderOneBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderTwo) return InvaderTwoBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderThree)
    return InvaderThreeBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderFour)
    return InvaderFourBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderFive)
    return InvaderFiveBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderSix) return InvaderSixBulletEntity;
  if (subtype === FlyingObjectSubType.InvaderSeven)
    return InvaderSevenBulletEntity;

  return InvaderOneBulletEntity;
}

function getInvaderSubtype(avatar: number): FlyingObjectSubType {
  if (avatar === InvaderOneEntity.index) return FlyingObjectSubType.InvaderOne;
  if (avatar === InvaderTwoEntity.index) return FlyingObjectSubType.InvaderTwo;
  if (avatar === InvaderThreeEntity.index)
    return FlyingObjectSubType.InvaderThree;
  if (avatar === InvaderFourEntity.index)
    return FlyingObjectSubType.InvaderFour;
  if (avatar === InvaderFiveEntity.index)
    return FlyingObjectSubType.InvaderFive;
  if (avatar === InvaderSixEntity.index) return FlyingObjectSubType.InvaderSix;
  if (avatar === InvaderSevenEntity.index)
    return FlyingObjectSubType.InvaderSeven;

  return FlyingObjectSubType.InvaderOne;
}

const spaceInvaderUtils = {
  getEntity,
  getInvaderSubtype,
  getRandomAsteroid,
  getRandomInvaderSubtype,
  getInvaderBulletByInvader,
};

export default spaceInvaderUtils;
