// react
import { useState } from "react";

// enums
import { UserGenreType } from "enums/userEnum";

// entities
import GodNameEntity from "entities/GodNameEntity";
import JarvisEntity from "entities/characters/JarvisEntity";

// components
import FormHeader from "components/form/FormHeader";
import PlayerGenre from "components/presentations/player/PlayerGenre";
import { DataCacheProps } from "components/presentations/Customization";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;

  > .body {
    flex: 1;
    padding: 0 20px;

    .buttons_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 120px;
    }
  }
`;

interface PlayerGenreProps {
  goBack(): void;
  goForward(): void;
  cache: DataCacheProps;
}

const PlayerGenreWrapper = ({ goBack, goForward, cache }: PlayerGenreProps) => {
  const [genre, setGenre] = useState<UserGenreType | null>(null);

  function save() {
    cache.genre = genre ? UserGenreType.Male : UserGenreType.Female;
    setTimeout(() => goForward());
  }

  function onSelect(genre: UserGenreType) {
    setGenre(genre);
  }

  return (
    <Wrapper>
      <FormHeader proceed={save} back={goBack} />

      <PlayerGenre
        onSelect={onSelect}
        description={
          <p>
            <span className="nerdfy_orange">Hummmm</span>, antes... preciso que
            você me faça um <span className="cmd">juramento</span>...
          </p>
        }
        maleDescription={
          <>
            <p>
              Eu, <span className="bg_nerdfy_gradient">{cache.codename}</span>,
              juro que serei <span className="blue">um</span> humilde{" "}
              <span className="blue">discípulo</span> da gloriosa{" "}
              <span className="warning">{JarvisEntity.unknownName}</span>,
            </p>

            <p>
              Que <span className="purple">{GodNameEntity.defaultName}</span>{" "}
              nos <span className="imp">guie</span> e nos{" "}
              <span className="imp">ilumine</span> nessa{" "}
              <span className="bg_nerdfy_gradient">Jornada</span>.{" "}
              <span className="nerdfy_green">Assim Seja</span>.
            </p>
          </>
        }
        femaleDescription={
          <>
            <p>
              Eu, <span className="bg_nerdfy_gradient">{cache.codename}</span>,
              juro que serei <span className="nerdfy_pink">uma</span> humilde{" "}
              <span className="nerdfy_pink">discípula</span>, da gloriosa{" "}
              <span className="warning">{JarvisEntity.unknownName}</span>,
            </p>

            <p>
              Que <span className="purple">{GodNameEntity.defaultName}</span>{" "}
              nos <span className="imp">guie</span> e nos{" "}
              <span className="imp">ilumine</span> nessa{" "}
              <span className="bg_nerdfy_gradient">Jornada</span>.{" "}
              <span className="nerdfy_green">Assim Seja</span>.
            </p>
          </>
        }
      />
    </Wrapper>
  );
};

export default PlayerGenreWrapper;
