export const AWS = {
  s3: {
    key: '',
    secret: '',
    region: '',
    bucket: {
      assets: process.env.REACT_APP_AWS_S3_BUCKET_ASSETS,
      url: process.env.REACT_APP_AWS_S3_BUCKET_ASSETS_URL,
      urlClassRoom: process.env.REACT_APP_AWS_S3_BUCKET_ASSETS_URL_CLASSROOM,
    },
  }
};
