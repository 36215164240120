// icons
import WizardHatPixelIcon from "icons/WizardHatPixelIcon";

// components
import Bubble, { BubbleProps } from "handlers/bubble/Bubble";

// utils
import color from "styles/color";

const BubbleNarrator = ({
  hide,
  name,
  footer,
  content,
  subHeader,
  onAnimationEnd,
  finishedTyping,
}: BubbleProps) => {
  return (
    <Bubble
      show
      hide={hide}
      name={name}
      footer={footer}
      content={content}
      subHeader={subHeader}
      labelClassName="nerdfy_blue"
      onAnimationEnd={onAnimationEnd}
      finishedTyping={finishedTyping}
      footerIcon={
        <WizardHatPixelIcon width="35" height="35" color={color.blue} />
      }
    />
  );
};

export default BubbleNarrator;
