// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// enums
import { BubbleType } from "enums/bubbleEnum";
import { ChallengeType } from "enums/challengeEnum";
import { StoryFlowType, StoryWhoType } from "enums/storyEnum";

// icons
import IconWhoreGod from "components/iconWhores/IconWhoreGod";
import IconWhoreMessage from "components/iconWhores/IconWhoreMessage";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { StoryProps } from "interfaces/story";
import { ChallengeCommonProps } from "interfaces/challenge";

// utils
import isEmpty from "lodash/isEmpty";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface StoriesHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
  onBubble?(): void;
  onHideBubble?(): void;
}

const StoriesHandler = ({
  paused,
  wrapper,
  onBubble,
  challenge,
  onHideBubble,
}: StoriesHandlerProps) => {
  const dispatch = useDispatch();
  const player = useSelector(s.player());
  const stories = useSelector(s.stories());
  const lastStoryCheckpoint = useRef(0);
  const { flowInit, flowFinished, flowDone } = challenge;
  const [godAdvisors, setGodAdvisors] = useState<AttentionWhoreProps[]>([]);
  const [jarvisAdvisors, setJarvisAdvisors] = useState<AttentionWhoreProps[]>(
    []
  );
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(getEmptyAttentionWhore());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToStoriesWhenFlowInit, [stories, flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToStoriesWhenFlowFinished, [stories, flowFinished]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(syncStoriesWhenFlowDone, [stories, flowDone]);

  function getEmptyAttentionWhore(): CurrentAttentionWhoreProps {
    return attentionWhoreUtils.fake();
  }

  function listenToStoriesWhenFlowInit() {
    if (!flowInit) return;
    if (isEmpty(player)) return;
    if (isEmpty(stories)) return;

    const story = get(StoryFlowType.FlowStarted);
    if (!story) return;

    show(story);
    updateLastStoryCheckpoint(story.checkpoint);
  }

  function listenToStoriesWhenFlowFinished() {
    if (!flowFinished) return;
    if (isEmpty(player)) return;
    if (isEmpty(stories)) return;

    const story = get(StoryFlowType.FlowFinished);
    if (!story) return;

    show(story);
    updateLastStoryCheckpoint(story.checkpoint);
  }

  function syncStoriesWhenFlowDone() {
    if (!flowDone) return;
    if (isEmpty(player)) return;
    if (isEmpty(stories)) return;
    if (!lastStoryCheckpoint.current) return;

    syncCheckpoint(lastStoryCheckpoint.current);
  }

  function updateLastStoryCheckpoint(storyCheckpoint: number) {
    const v = lastStoryCheckpoint.current;
    lastStoryCheckpoint.current = v < storyCheckpoint ? storyCheckpoint : v;
  }

  function get(challengeFlowType: StoryFlowType): StoryProps | undefined {
    return stories.find(
      (story) =>
        filterByCheckpoint(story) &&
        filterByChallengeType(story) &&
        filterByChallengeFlowType(story, challengeFlowType)
    );
  }

  function filterByCheckpoint(story: StoryProps): boolean {
    return story.checkpoint > player.storyCheckpoint;
  }

  function filterByChallengeType(story: StoryProps): boolean {
    if (story.challengeType === ChallengeType.None) return true;
    return story.challengeType === challenge.type;
  }

  function filterByChallengeFlowType(
    story: StoryProps,
    challengeFlowType: StoryFlowType
  ): boolean {
    if (story.challengeFlowType === StoryFlowType.None) return true;
    return story.challengeFlowType === challengeFlowType;
  }

  function syncCheckpoint(storyCheckpoint: number) {
    if (!storyCheckpoint) return;

    setTimeout(() =>
      dispatch(playerActions.async.updateStoryCheckpoint(storyCheckpoint))
    );
  }

  function show(data: StoryProps) {
    const { whoType } = data;

    if (whoType === StoryWhoType.None) return;
    if (whoType === StoryWhoType.God) buildWhoreGod(data);
    if (whoType === StoryWhoType.Jarvis) buildWhoreJarvis(data);
  }

  function buildWhoreGod(data: StoryProps) {
    if (!wrapper) return;

    const whores = data.story.reduce((acc: AttentionWhoreProps[], event) => {
      const whore = attentionWhoreUtils.get({
        payload: event,
        WhoreEl: IconWhoreGod,
        call: onAttentionWhoreCall,
        bubbleType: BubbleType.God,
        dropZoneBorderColor: "purple",
        wrapperWidth: wrapper.clientWidth,
        wrapperHeight: wrapper.clientHeight,
      });

      acc.push(whore);
      return acc;
    }, []);

    setGodAdvisors(whores);
  }

  function buildWhoreJarvis(data: StoryProps) {
    if (!wrapper) return;

    const whores = data.story.reduce((acc: AttentionWhoreProps[], event) => {
      const whore = attentionWhoreUtils.get({
        payload: event,
        WhoreEl: IconWhoreMessage,
        call: onAttentionWhoreCall,
        dropZoneBorderColor: "yellow",
        bubbleType: BubbleType.Message,
        wrapperWidth: wrapper.clientWidth,
        wrapperHeight: wrapper.clientHeight,
      });

      acc.push(whore);
      return acc;
    }, []);

    setJarvisAdvisors(whores);
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    onBubble && onBubble();
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    onHideBubble && onHideBubble();
    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  return (
    <>
      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <AttentionWhores
        paused={paused}
        clear={flowDone}
        whores={jarvisAdvisors}
        disabledDrop={!!attentionWhore.show}
      />

      <AttentionWhores
        paused={paused}
        clear={flowDone}
        whores={godAdvisors}
        disabledDrop={!!attentionWhore.show}
      />
    </>
  );
};

export default StoriesHandler;
