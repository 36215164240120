// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeCodeActions } from "redux/slices/challengeCodeSlice";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";
import { challengeSectionActions } from "redux/slices/challengeSectionSlice";
import { spaceshipMachineGunActions } from "redux/slices/spaceInvaders/spaceshipMachineGunSlice";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";
import PlayerFirebaseEntity from "entities/PlayerFirebaseEntity";
import ChallengeStateEntity from "entities/ChallengeStateEntity";
import ChallengeFirebaseEntity from "entities/ChallengeFirebaseEntity";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";
import { ChallengeFirebaseUpdateProps } from "interfaces/challengeFirebase";

// services
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";

// utils
import stateUtils, { listenerApiType } from "redux/utils/stateUtils";
import challengeCommonUtils from "redux/utils/challengeCommonUtils";

const challengeMiddleware = createListenerMiddleware();

// challenge/init
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.init,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const stateEntity = new ChallengeStateEntity();
    const firebaseEntity = new ChallengeFirebaseEntity();
    const values = stateEntity.getFlowInitValues();
    const valuesFirebase = firebaseEntity.getFlowInitValues(userId, challenge);

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/start
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.start,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const stateEntity = new ChallengeStateEntity();
    const firebaseEntity = new ChallengeFirebaseEntity();
    const values = stateEntity.getFlowStartedValues();
    const valuesFirebase = firebaseEntity.getFlowStartedValues();

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/finish
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.finish,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const stateEntity = new ChallengeStateEntity();
    const firebaseEntity = new ChallengeFirebaseEntity();

    const values = stateEntity.getFlowFinishedValues();
    const valuesFirebase = firebaseEntity.getFlowFinishedValues();

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/done
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.done,
  effect: async (_action, listenerApi) => {
    const { challenge, challenges, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const challengeStateEntity = new ChallengeStateEntity();
    const playerFirebaseEntity = new PlayerFirebaseEntity();
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();
    const values = challengeStateEntity.getDoneValues(challenge);
    const playerValues = playerFirebaseEntity.getDoneValues(challenge);
    const valuesFirebase = challengeFirebaseEntity.getDoneValues(challenge);

    setNextChallenge({ ...challenge, ...values }, challenges.hash, listenerApi);
    listenerApi.dispatch(challengeActions.update(values));
    listenerApi.dispatch(challengeCodeActions.clear());

    // firebase
    updatePlayerFirebase(userId, playerValues);
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/updateSpeechProgress
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.updateSpeechProgress,
  effect: async ({ payload }, listenerApi) => {
    const { speechProgress } = payload;
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const values = { speechProgress };
    const userId = auth.user.id;

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, values);
  },
});

// challenge/tryAgain
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.tryAgain,
  effect: async (_action, listenerApi) => {
    const { player, auth } = stateUtils.get(listenerApi);
    if (player.hp === PlayerHpEntity.hpTotal) return;

    const firebaseEntity = new PlayerFirebaseEntity();
    const playerValues = firebaseEntity.getTryAgainValues({
      life: player.life,
    });

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    // firebase
    updatePlayerFirebase(auth.user.id, playerValues);
  },
});

// challenge/restart
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.restart,
  effect: async (_action, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);
    const firebaseEntity = new PlayerFirebaseEntity();
    const playerValues = firebaseEntity.getRestartValues();

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    updatePlayerFirebase(auth.user.id, playerValues);
  },
});

// challenge/openModal
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.openModal,
  effect: async ({ payload }, listenerApi) => {
    const { challenge, section } = payload;
    const { challenges } = stateUtils.get(listenerApi);

    listenerApi.dispatch(challengeFlowActions.pause());
    listenerApi.dispatch(challengeActions.set(challenge));
    listenerApi.dispatch(challengeSectionActions.set(section));
    listenerApi.dispatch(spaceshipMachineGunActions.async.fill());
    listenerApi.dispatch(challengeCodeActions.set(challenge.code));
    listenerApi.dispatch(
      nextChallengeActions.async.set(challenge, challenges.hash)
    );
  },
});

// private

function setNextChallenge(
  challenge: ChallengeCommonProps,
  hash: ChallengesCommonHashProps,
  listenerApi: listenerApiType
) {
  const challenges = challengeCommonUtils.merge(challenge, hash);
  listenerApi.dispatch(nextChallengeActions.async.set(challenge, challenges));
}

function updatePlayerFirebase(
  userId: number | string,
  player: PlayerFirebaseUpdateProps
) {
  if (!userId) return;

  const playerFirebaseService = new PlayerFirebaseService();
  playerFirebaseService.update(userId, player);
}

function updateChallengeFirebase(
  userId: number | string,
  challengeId: number,
  classRoomId: number,
  data: ChallengeFirebaseUpdateProps
) {
  if (!userId) return;
  if (!challengeId) return;
  if (!classRoomId) return;

  const challengeFirebaseService = new PlayerChallengeFirebaseService();
  challengeFirebaseService.update(challengeId, userId, classRoomId, data);
}

export default challengeMiddleware;
