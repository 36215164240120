import styled from "styled-components";
import color from "styles/color";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  color: ${color.grey.middle};
  padding: 30px;
  background: ${color.dark.bg};

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  h2,
  h3 {
    color: ${color.purple};
  }

  ul {
    li {
      list-style: none;
      margin-bottom: 20px;

      .number {
        color: ${color.purple};
      }
    }
  }
`;

const TermsPage = () => {
  return (
    <Wrapper>
      <div className="header">{/* <BrandAvatar /> */}</div>

      <div className="term">
        <h2>1. Termos</h2>
        <p>
          Ao acessar ao site Nerdfy, concorda em cumprir estes termos de
          serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é
          responsável pelo cumprimento de todas as leis locais aplicáveis.
        </p>

        <p>
          Se você não concordar com algum desses termos, está proibido de usar
          ou acessar este site. Os materiais contidos neste site são protegidos
          pelas leis de direitos autorais e marcas comerciais aplicáveis.
        </p>
      </div>

      <div className="term">
        <h2>2. Uso de Licença</h2>
        <p>
          É concedida permissão para baixar temporariamente uma cópia dos
          materiais (informações ou software) no site Nerdfy , apenas para
          visualização transitória pessoal e não comercial.
        </p>

        <p>
          Esta é a concessão de uma licença, não uma transferência de título e,
          sob esta licença, você não pode:
        </p>

        <ul>
          <li>
            <span className="number">1.</span> Modificar ou copiar os materiais;
          </li>
          <li>
            <span className="number">2.</span> Usar os materiais para qualquer
            finalidade comercial ou para exibição pública (comercial ou não
            comercial);
          </li>
          <li>
            <span className="number">3.</span> Tentar descompilar ou fazer
            engenharia reversa de qualquer software contido no site Nerdfy;
          </li>
          <li>
            <span className="number">4.</span> Remover quaisquer direitos
            autorais ou outras notações de propriedade dos materiais; ou
          </li>
          <li>
            <span className="number">5.</span> Transferir os materiais para
            outra pessoa ou "espelhe" os materiais em qualquer outro servidor.
          </li>
        </ul>

        <p>
          Esta licença será automaticamente rescindida se você violar alguma
          dessas restrições e poderá ser rescindida por Nerdfy a qualquer
          momento.
        </p>

        <p>
          Ao encerrar a visualização desses materiais ou após o término desta
          licença, você deve apagar todos os materiais baixados em sua posse,
          seja em formato digital ou impresso.
        </p>
      </div>

      <div className="term">
        <h2>3. Isenção de responsabilidade</h2>

        <ul>
          <li>
            <span className="number">1.</span> Os materiais no site da Nerdfy
            são fornecidos 'como estão'. Nerdfy não oferece garantias, expressas
            ou implícitas, e, por este meio, isenta e nega todas as outras
            garantias, incluindo, sem limitação, garantias implícitas ou
            condições de comercialização, adequação a um fim específico ou não
            violação de propriedade intelectual ou outra violação de direitos.
          </li>
          <li>
            <span className="number">2.</span> Além disso, o Nerdfy não garante
            ou faz qualquer representação relativa à precisão, aos resultados
            prováveis ​​ou à confiabilidade do uso dos materiais em seu site ou
            de outra forma relacionado a esses materiais ou em sites vinculados
            a este site.
          </li>
        </ul>
      </div>

      <div className="term">
        <h2>4. Limitações</h2>
        <p>
          Em nenhum caso o Nerdfy ou seus criadores serão responsáveis ​​por
          quaisquer danos (incluindo, sem limitação, danos por perda de dados ou
          lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
          incapacidade de usar os materiais em Nerdfy, mesmo que Nerdfy ou um
          representante autorizado da Nerdfy tenha sido notificado oralmente ou
          por escrito da possibilidade de tais danos.
        </p>

        <p>
          Como algumas jurisdições não permitem limitações em garantias
          implícitas, ou limitações de responsabilidade por danos conseqüentes
          ou incidentais, essas limitações podem não se aplicar a você.
        </p>
      </div>

      <div className="term">
        <h2>5. Precisão dos materiais</h2>
        <p>
          Os materiais exibidos no site da Nerdfy podem incluir erros técnicos,
          tipográficos ou fotográficos. Nerdfy não garante que qualquer material
          em seu site seja preciso, completo ou atual.
        </p>

        <p>
          Nerdfy pode fazer alterações nos materiais contidos em seu site a
          qualquer momento, sem aviso prévio. No entanto, Nerdfy não se
          compromete a atualizar os materiais.
        </p>
      </div>

      <div className="term">
        <h2>6. Links</h2>
        <p>
          O Nerdfy não analisou todos os sites vinculados ao seu site e não é
          responsável pelo conteúdo de nenhum site vinculado. A inclusão de
          qualquer link não implica endosso por Nerdfy do site. O uso de
          qualquer site vinculado é por conta e risco do usuário.
        </p>
      </div>

      <div className="details">
        <h3>Modificações</h3>
        <p>
          O Nerdfy pode revisar estes termos de serviço do site a qualquer
          momento, sem aviso prévio. Ao usar este site, você concorda em ficar
          vinculado à versão atual desses termos de serviço.
        </p>
      </div>

      <div className="details">
        <h3>Lei aplicável</h3>
        <p>
          Estes termos e condições são regidos e interpretados de acordo com as
          leis do Nerdfy e você se submete irrevogavelmente à jurisdição
          exclusiva dos tribunais naquele estado ou localidade.
        </p>
      </div>
    </Wrapper>
  );
};

export default TermsPage;
