// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

export interface SpaceshipMineralResourcesCounterProps {
  raw: number;
  total: number;
  parcial: number;
  shifting: number;
  transformed: number;
}

export default class SpaceshipMineralResourcesEntity {
  resources: AllToOptional<SpaceshipResourcesProps>;

  constructor(resources: AllToOptional<SpaceshipResourcesProps>) {
    this.resources = resources;
  }

  get count(): SpaceshipMineralResourcesCounterProps {
    const keys = Object.keys(this.resources) as Array<
      keyof SpaceshipResourcesProps
    >;

    return keys.reduce(
      (acc, key) => {
        if (key === "water") return acc;
        if (key === "strongForce") return acc;

        const data = this.resources[key] as SpaceshipResourcesDataProps;

        acc.raw += data.raw;
        acc.total += data.total;
        acc.parcial += data.parcial;
        acc.shifting += data.shifting;
        acc.transformed += data.transformed;

        return acc;
      },
      { total: 0, parcial: 0, raw: 0, shifting: 0, transformed: 0 }
    );
  }
}
