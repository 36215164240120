// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import styled from "styled-components";

const ButtonDuolingoWrapper = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 20px 25px;
  font-size: 26px !important;

  .opacity {
    opacity: 0.3;
  }
`;

interface ButtonAccordionLabelProps {
  label: string;
  customClassName?: string;
}

export function ButtonAccordionLabel({
  label,
  customClassName,
}: ButtonAccordionLabelProps) {
  return (
    <span className={`pixelify_font_family ${customClassName}`}>{label}</span>
  );
}

interface ButtonProps {
  onClick(): void;
  children: React.ReactNode;
  disabled?: boolean;
}

const ButtonAccordion = ({ onClick, children, disabled }: ButtonProps) => {
  return (
    <ButtonDuolingoWrapper onClick={onClick} disabled={false}>
      {children}
    </ButtonDuolingoWrapper>
  );
};

export default ButtonAccordion;
