// components
import ButtonAccordion, {
  ButtonAccordionLabel,
} from "handlers/buttonsAccordion/ButtonAccordion";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ButtonProps {
  onClick(): void;
  challenge: ChallengeCommonProps;
}

const ButtonAccordionIntro = ({ onClick, challenge }: ButtonProps) => {
  return (
    <ButtonAccordion onClick={onClick}>
      <ButtonAccordionLabel
        label={challenge.name}
        customClassName={`nerdfy_purple ${
          challenge.flowDone && "bg_nerdfy_gradient"
        }`}
      />
    </ButtonAccordion>
  );
};

export default ButtonAccordionIntro;
