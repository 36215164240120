import { StatusBar, Style } from "@capacitor/status-bar";
import deviceUtils from "utils/deviceUtils";

async function dark() {
  const isApp = await deviceUtils.isApp();

  if (!isApp) return;
  await StatusBar.setStyle({ style: Style.Dark });
}

const statusBarUtils = {
  dark,
};

export default statusBarUtils;
