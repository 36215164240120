interface SvgProps {
  width?: string;
  height?: string;
}

const Printer2dPixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      x="0"
      y="0"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m436.7 75.29-15.05 15.06h-30.12v30.12h-30.12v30.12h-30.12l-15.05 15.06-15.06-15.06h-30.12v-30.12h-60.24v30.12h-30.11v30.12h-30.12v60.23L165.65 256l-15.06 15.06v60.23h30.12v30.12h30.11v30.12h30.12v30.12h60.24v30.11h30.11V512H30.12v-60.24h30.12v-30.11h60.23v-30.12H90.35v-30.12H60.24v-30.12H30.12v-30.11H0v-90.36h30.12v-30.11h30.12v-30.12h30.11v-30.12h30.12V90.35h30.12V60.24h30.12V30.12h30.11V0h180.71v30.12h30.12v30.12z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          fill="#ff9e22"
          d="M60.24 451.76h240.94v30.12H60.24z"
          opacity="1"
          data-original="#ff9e22"
        ></path>
        <g fill="#58b354">
          <path
            d="M240.94 60.24v30.11h-30.12v30.12h-30.11v30.12h-30.12l-15.06 15.06-15.06-15.06v-30.12h30.12V90.35h30.12V60.24h30.11l15.06-15.06zM180.71 361.41v30.12l-15.06 15.06-15.06-15.06h-30.12v-30.12H90.35v-30.12h60.24v30.12z"
            fill="#58b354"
            opacity="1"
            data-original="#58b354"
          ></path>
        </g>
        <g fill="#fc0">
          <path
            d="M391.53 60.24v30.11h-30.12l-15.06 15.06h-60.23l-15.06-15.06V60.24l15.06-15.06h90.35zM105.41 225.88H45.18l-15.06 15.06v30.12l15.06 15.06h60.23l15.06-15.06v-30.12zm-15.06 45.18H60.23v-30.12h30.12z"
            fill="#ffcc00"
            opacity="1"
            data-original="#ffcc00"
          ></path>
        </g>
        <g fill="#fff69e">
          <path
            d="M391.53 30.12v30.12h-60.24L316.24 75.3l-15.06-15.06h-30.12V30.12zM30.12 210.82h90.35v30.12H30.12z"
            fill="#fff69e"
            opacity="1"
            data-original="#fff69e"
          ></path>
        </g>
        <g fill="#ff9e22">
          <path
            d="M361.41 90.35v30.12h-90.35V90.35h30.12l15.06-15.05 15.05 15.05zM30.12 271.06h90.35v30.12H30.12z"
            fill="#ff9e22"
            opacity="1"
            data-original="#ff9e22"
          ></path>
        </g>
        <path
          fill="#9d5015"
          d="M301.18 60.24h30.11v30.11h-30.11z"
          opacity="1"
          data-original="#9d5015"
        ></path>
        <g fill="#007745">
          <path
            d="M150.59 391.53h60.23v30.12h-60.23zM90.35 150.59h60.24v30.12H90.35z"
            fill="#007745"
            opacity="1"
            data-original="#007745"
          ></path>
        </g>
        <path
          fill="#b3d141"
          d="M210.82 30.12h30.12v30.12h-30.12z"
          opacity="1"
          data-original="#b3d141"
        ></path>
        <g fill="#5f6c75">
          <path
            d="M421.65 60.24H512v30.11h-90.35zM150.59 240.94h120.47v30.12H150.59zM271.06 210.82h30.12v30.12h-30.12zM301.18 150.59h30.11v60.23h-30.11z"
            fill="#5f6c75"
            opacity="1"
            data-original="#5f6c75"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Printer2dPixelSvg;
