// components
import Icon, { faPause, faPlay } from "components/Icon";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import styled from "styled-components";

const Button = styled(ButtonRippleEffect)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 10;
`;

interface ButtonPlayPauseToggleProps {
  paused: boolean;
  play: () => void;
  pause: () => void;
  disabled?: boolean;
}

const ButtonPlayPauseToggle = ({
  play,
  pause,
  paused,
  disabled,
}: ButtonPlayPauseToggleProps) => {
  return (
    <>
      {!paused ? (
        <Button onClick={pause} disabled={disabled} color="#242424">
          <Icon icon={faPause} />
        </Button>
      ) : (
        <Button onClick={play} disabled={disabled} color="#242424">
          <Icon icon={faPlay} />
        </Button>
      )}
    </>
  );
};

export default ButtonPlayPauseToggle;
