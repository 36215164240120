// react
import { useState, useRef } from "react";

// entities
import { DotProgressRefProps } from "components/SlideDotsProgress";

// enums
import { UserGenreType } from "enums/userEnum";

// components
import Board from "components/Board";
import SlideDotsProgress from "components/SlideDotsProgress";
import ButtonDuolingoPlayerMale from "components/buttons/ButtonDuolingoPlayerMale";
import ButtonDuolingoPlayerFemale from "components/buttons/ButtonDuolingoPlayerFemale";

// utils
import color from "styles/color";
import styled from "styled-components";

// Swiper
import { IonicSlides } from "@ionic/react";
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .body_header {
    display: flex;
    align-items: center;
    min-height: 60px;
    text-align: center;
  }

  .slides {
    flex: 1;
    display: flex;
    flex-direction: column;

    .slide {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }

  .buttons_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0;
  }
`;

interface PlayerGenreProps {
  description: JSX.Element;
  maleDescription: JSX.Element;
  femaleDescription: JSX.Element;
  onSelect: (genre: UserGenreType) => void;
}

const MALE_SLIDE = 0;
const FEMALE_SLIDE = 1;

const PlayerGenre = ({
  onSelect,
  description,
  maleDescription,
  femaleDescription,
}: PlayerGenreProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const speechProgressRef = useRef<DotProgressRefProps>({ 0: true });
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  function goToMaleGenre() {
    onSelect(UserGenreType.Male);
    swiperRef.current?.slideTo(MALE_SLIDE);
  }

  function goToFemaleGenre() {
    onSelect(UserGenreType.Female);
    swiperRef.current?.slideTo(FEMALE_SLIDE);
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;

    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex, realIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
      updateRealSpeechPositionRef(realIndex);
    });
  }

  function updateRealSpeechPositionRef(index: number) {
    if (speechProgressRef.current[index]) return;
    speechProgressRef.current = { ...speechProgressRef.current, [index]: true };
  }

  return (
    <Wrapper>
      <div className="body_header">
        <Board>{description}</Board>
      </div>

      <SlideDotsProgress
        len={2}
        position={slideActiveIndex}
        dotsProgress={speechProgressRef.current}
      />

      <div className="slides">
        <Swiper
          onSwiper={handleSwiper}
          modules={[IonicSlides]}
          slidesPerView={1}
          initialSlide={MALE_SLIDE}
        >
          <SwiperSlide>
            <div className="slide">
              <Board>{maleDescription}</Board>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide">
              <Board>{femaleDescription}</Board>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="buttons_wrapper">
        <ButtonDuolingoPlayerMale
          onClick={goToMaleGenre}
          color={slideActiveIndex === MALE_SLIDE ? color.blue : undefined}
        />
        <ButtonDuolingoPlayerFemale
          onClick={goToFemaleGenre}
          color={slideActiveIndex === FEMALE_SLIDE ? color.pink : undefined}
        />
      </div>
    </Wrapper>
  );
};

export default PlayerGenre;
