// redux
import { appActions } from "redux/slices/appSlice";
import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

// interfaces
import {
  WalkieTalkieStateProps,
  WalkieTalkieBroadcastProps,
} from "interfaces/walkieTalkie";

const broadcast = createAction(
  "@async/walkieTalkie/broadcast",
  (payload: { message: string }) => ({
    payload,
  })
);

export const initialState: WalkieTalkieStateProps = {
  broadcast: [] as WalkieTalkieBroadcastProps[],
};

export const walkieTalkieSlice = createSlice({
  name: "walkieTalkie",
  initialState,
  reducers: {
    broadcast: (state, action: PayloadAction<WalkieTalkieBroadcastProps[]>) => {
      state.broadcast = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const walkieTalkieActions = {
  async: {
    broadcast,
  },
  ...walkieTalkieSlice.actions,
};

export default walkieTalkieSlice.reducer;
