interface SvgProps {
  width?: string;
  height?: string;
}

const MineralStoragePixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M512 421.65v-60.24h-30.12v-180.7H361.41V0H150.59v180.71H30.12v180.7H0v60.24h30.12v30.11H0V512h512v-60.24h-30.12v-30.11zm-240.94-60.24h-30.12V210.82h30.12z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <g fill="#ffd322">
          <path
            d="M451.76 240.95v90.34l-4.973 15.06-135.288-.996-10.319-14.064v-90.34l15.06-15.07 15.05 15.06v30.12h30.12v30.12h30.12v-30.12h30.12v-30.12l15.06-15.06zM331.29 150.59l-6.63 11.431-133.882.983-10.068-12.414V60.24l15.06-15.06 15.05 15.06v30.11h30.12v30.12h30.12V90.35h30.12V60.24l15.06-15.06 15.05 15.06zM210.82 240.95v90.34l-6.756 12.604-135.678.026-8.146-12.63v-90.34l15.05-15.07 15.06 15.06v30.12h30.12v30.12h30.12v-30.12h30.12v-30.12l15.06-15.06z"
            fill="#beb279"
            opacity="1"
            data-original="#ffd322"
          ></path>
        </g>
        <path
          fill="#cf7530"
          d="M481.88 391.53v30.12h-30.12v30.11l-15.05 15.06-15.06-15.06v-30.11h-90.36v30.11l-15.06 15.06-15.05-15.06v-30.11h-90.36v30.11l-15.05 15.06-15.06-15.06v-30.11H90.35v30.11l-15.06 15.06-15.05-15.06v-30.11H30.12v-30.12z"
          opacity="1"
          data-original="#ed8600"
        ></path>
        <g fill="#9d5015">
          <path
            d="m135.53 225.88 15.06 15.06v30.12h-30.12v-30.12zM256 45.18l15.06 15.06v30.11h-30.12V60.24zM391.53 240.94v30.12h-30.12v-30.12l15.06-15.06z"
            fill="#9d5015"
            opacity="1"
            data-original="#9d5015"
          ></path>
        </g>
        <path
          fill="#9d5015"
          d="M30.12 451.76h451.76v30.12H30.12z"
          opacity="1"
          data-original="#9d5015"
        ></path>
        <g fill="#ed8600">
          <path
            d="M120.47 210.82h30.12v30.12h-30.12zM361.41 210.82h30.12v30.12h-30.12zM60.24 331.29h150.58v30.12H60.24zM180.71 150.59h150.58v30.12H180.71zM240.94 30.12h30.12v30.12h-30.12zM301.18 331.29h150.58v30.12H301.18z"
            fill="#cf7530"
            opacity="1"
            data-original="#ed8600"
          ></path>
        </g>
        <g fill="#fff0b5">
          <path
            d="M421.65 210.82h30.11v30.12h-30.11zM301.18 210.82h30.11v30.12h-30.11zM301.18 30.12h30.11v30.12h-30.11zM180.71 210.82h30.11v30.12h-30.11zM180.71 30.12h30.11v30.12h-30.11zM60.24 210.82h30.11v30.12H60.24z"
            fill="#fff0b5"
            opacity="1"
            data-original="#fff0b5"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default MineralStoragePixelSvg;
