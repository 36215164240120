// redux
import { ThunkDispatch, AnyAction, ListenerEffectAPI } from "@reduxjs/toolkit";
import { ApplicationState } from "redux/store";

export type listenerApiType = ListenerEffectAPI<
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>,
  unknown
>;

function get(listenerApi: listenerApiType): ApplicationState {
  return listenerApi.getOriginalState() as ApplicationState;
}

const stateUtils = {
  get,
};

export default stateUtils;
