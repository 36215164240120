// react
import { useEffect, useRef, useState } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// components
import Confetti from "components/Celebration";
import ChallengeDemoCta from "components/ChallengeDemoCta";
import ChallengeCode from "components/challenge/battle/ChallengeCode";
import ChallengeOptionsFooter from "components/ChallengeOptionsFooter";
import { DropZoneTag, DropZoneAttentionWhore } from "components/DropZone";
import ChallengeTags from "components/challenge/battle/tags/ChallengeTags";
import SpaceshipResourcesStatusBar from "components/SpaceshipResourcesStatusBar";
import ChallengeStatusBar from "components/challenge/battle/statusbar/ChallengeStatusBar";
import SpaceshipStatusBar from "components/challenge/battle/statusbar/SpaceshipStatusBar";

// factories
import { AlertVictory, alertLeft } from "factories/AlertFactory";

// entities
import PlayerEntity from "entities/PlayerEntity";

// handlers
import TipsHandler from "handlers/TipsHandler";
import LabsHandler from "handlers/challengeButtons/labs/LabsHandler";
import SpaceInvadersHandler from "handlers/SpaceInvadersHandler";
import DemoVictoryAlertHandler from "handlers/DemoVictoryAlertHandler";
import ChallengeGameFlowHandler from "handlers/ChallengeGameFlowHandler";
import ChallengeBattleProgressHandler from "handlers/ChallengeBattleProgressHandler";
// import ChallengeDoneButtonHandler from "handlers/challengeDoneButton/ChallengeDoneButtonHandler";
import SpaceshipCustomizationRestartHandler from "handlers/SpaceshipCustomizationRestartHandler";

// interfaces
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// utils
import color from "styles/color";
import styled from "styled-components";
import blinkUtils from "utils/blinkUtils";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .challenge_battle_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .code_wrapper {
    flex: 1;
    height: 100%;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100px;

    > ul {
      all: unset;
      display: flex;
      height: 100%;
      background: ${color.dark.bg};
      color: ${color.grey.strong};
    }
  }
`;

interface TagBoundsProps {
  left: string;
  top: string;
}

interface ChallengeBattleProps extends ChallengeTypeHandlerCommonProps {
  onFailedTag(bounds: TagBoundsProps): void;
}

const { isTryAgain, isGameOver } = PlayerEntity;
const ChallengeBattle = ({
  code,
  paused,
  challenge,
  wrapperRef,
  blinkElRef,
  isTagValid,
  onFailedTag,
  onSuccessTag,
  reOpenChallenge,
  handlerWrapperRef,
  handlerNotifiersRef,
}: ChallengeBattleProps) => {
  const dispatch = useDispatch();
  const player = useSelector(s.player());
  const pxGainedRef = useRef(0);
  const scoreGainedRef = useRef(0);
  const disabledTagDropRef = useRef(false);
  // const [showVictoryNews, setShowVictoryNews] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [customizeSpaceShip, setCustomizeSpaceShip] = useState(false);

  const {
    tip,
    tags,
    flowInit,
    flowDone,
    scoreGained,
    flowStarted,
    currentCode,
    flowFinished,
    programmingLanguageType,
  } = challenge;

  useEffect(listenToPxGained, [scoreGained]);
  useEffect(listenToScoreGained, [scoreGained]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleInitFlow, [paused, flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleFinishedCelebration, [flowDone, flowFinished]);

  function listenToScoreGained() {
    scoreGainedRef.current = scoreGained;
  }

  function listenToPxGained() {
    pxGainedRef.current = scoreGained;
  }

  function handleFinishedCelebration() {
    if (!flowDone) return;
    if (!flowFinished) return;

    setTimeout(() => alert(), 2000);
    setTimeout(() => setShowCelebration(true), 150);
    // setTimeout(() => setShowVictoryNews(true), 800);
  }

  function blinkSpaceship() {
    if (!blinkElRef.current) return;
    blinkUtils.blinkRed(blinkElRef.current, 100);
  }

  function handleInitFlow() {
    if (paused) return;
    if (flowInit) return;

    setTimeout(() => dispatch(challengeActions.async.init()));
  }

  function onBubble() {
    disabledTagDropRef.current = true;
  }

  function onHideBubble() {
    disabledTagDropRef.current = false;
  }

  function onSpaceshipDrop() {
    dispatch(challengeActions.async.done());
  }

  function restart() {
    setCustomizeSpaceShip(true);
  }

  function callSameChallenge() {
    reOpenChallenge(challenge);
  }

  function alert() {
    const pixel = pxGainedRef.current;
    const score = scoreGainedRef.current;
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const alerts = [
      AlertVictory(`Você avançou ${pixel} ${pixel > 1 ? "pixels" : "pixel"}`),
    ];

    if (score > 0)
      alerts.push(
        AlertVictory(
          `Você ganhou ${score} ${
            score > 1 ? "pontos" : "ponto"
          } de popularidade`
        )
      );

    alertLeft(wrapper, alerts, 200);
  }

  return (
    <>
      {flowDone && <ChallengeDemoCta />}
      <ChallengeBattleProgressHandler challenge={challenge} />
      <Wrapper
        ref={handlerWrapperRef}
        className={`challenge_battle_wrapper ${
          !paused &&
          !flowDone &&
          !isTryAgain(player) &&
          !isGameOver(player) &&
          "no-swipe"
        }`}
      >
        <div className="nerdfy_notifiers" ref={handlerNotifiersRef}></div>

        <DropZoneTag />
        <DropZoneAttentionWhore />

        <ChallengeStatusBar challenge={challenge} />
        <SpaceshipStatusBar challenge={challenge} />

        {flowStarted && (
          <ChallengeTags
            code={code}
            tags={tags}
            paused={paused}
            clear={flowDone}
            onFailedTag={onFailedTag}
            isTagValid={isTagValid}
            onSuccessTag={onSuccessTag}
            wrapper={wrapperRef.current}
            disabledDropRef={disabledTagDropRef}
          />
        )}

        <div className="challenge_battle_content">
          <div className="code_wrapper">
            <ChallengeCode
              wrap
              code={currentCode}
              language={programmingLanguageType}
            />
          </div>

          <ChallengeOptionsFooter
            wrapper={wrapperRef.current}
            disabled={challenge.flowDone}
          />

          {!flowDone && <SpaceshipResourcesStatusBar />}

          {/* **** GAME FLOW **** */}

          <ChallengeGameFlowHandler
            paused={paused}
            restart={restart}
            challenge={challenge}
          />

          {/* GAME FLOW END */}
        </div>

        {/* **** HANDLERS **** */}

        {/* {flowDone && (
          <ChallengeDoneButtonHandler callNextChallenge={reOpenChallenge} />
        )} */}

        {showCelebration && <Confetti />}
        {/* {showVictoryNews && (
          <VictoryNewsHandler
            challengeType={type}
            victoryPoints={[
              { points: pxGained, type: VictoryNewsPointType.Px },
              { points: scoreGained, type: VictoryNewsPointType.Score },
            ]}
          />
        )} */}

        <TipsHandler
          tip={tip}
          paused={paused}
          clear={flowDone}
          onBubble={onBubble}
          onHideBubble={onHideBubble}
          wrapper={wrapperRef.current}
        />

        <SpaceInvadersHandler
          challenge={challenge}
          blink={blinkSpaceship}
          wrapperRef={wrapperRef}
          onSpaceshipDrop={onSpaceshipDrop}
        />

        <LabsHandler
          paused={paused}
          challenge={challenge}
          wrapper={wrapperRef.current}
        />

        <DemoVictoryAlertHandler
          paused={paused}
          challenge={challenge}
          wrapper={wrapperRef.current}
        />

        {/* HANDLERS END */}
      </Wrapper>

      {customizeSpaceShip && (
        <SpaceshipCustomizationRestartHandler finish={callSameChallenge} />
      )}
    </>
  );
};

export default ChallengeBattle;
