// interfaces
import {
  FlyingObjectProps,
  FlyingObjectPositionProps,
} from "interfaces/spaceInvaders/flyingObject";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
} from "enums/spaceInvaders/flyingObjectEnum";

export default abstract class AbstractFlyingObjectEntity
  implements FlyingObjectProps
{
  hp: number;
  index: number;
  damage: number;
  explosionColor: string;
  type: FlyingObjectType;
  subtype: FlyingObjectSubType;
  position: FlyingObjectPositionProps;

  static key = "";
  static index = 0;
  static alias = "";
  static defaultName = "";
  static checkpointIndex = 0;
  static turnsToTransform = 1;

  constructor(position: FlyingObjectPositionProps) {
    this.position = position;
  }

  toJson(): FlyingObjectProps {
    return {
      hp: this.hp,
      type: this.type,
      damage: this.damage,
      subtype: this.subtype,
      position: this.position,
      explosionColor: this.explosionColor,
    };
  }

  hit(damage: number): void {
    this.hp -= damage;
  }

  isDestroyed(): boolean {
    return this.hp <= 0;
  }
}
