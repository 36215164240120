// redux
import { appActions } from "redux/slices/appSlice";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { FlyingObjectProps } from "interfaces/spaceInvaders/flyingObject";

const fireForever = createAction(
  "@async/asteroid/fire/forever",
  (payload: {
    frequency: number;
    border: { width: number; height: number };
  }) => ({
    payload,
  })
);
const stopFireForever = createAction("@async/asteroid/stop/fire/forever");

const initialState: FlyingObjectProps[] = [];

export const flyingObjectsSlice = createSlice({
  name: "flyingObjects",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<FlyingObjectProps>) => {
      return [...state, action.payload];
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const flyingObjectsActions = {
  async: {
    fireForever,
    stopFireForever,
  },
  ...flyingObjectsSlice.actions,
};
export default flyingObjectsSlice.reducer;
