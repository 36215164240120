// utils
import color from "styles/color";

interface LinearGradientProps {
  id?: string;
}

const LinearGradientEffectSvg = ({ id }: LinearGradientProps) => {
  return (
    <linearGradient
      id={`${id ? id : "linearGradient"}`}
      x2="1"
      y2="1"
      gradientUnits="objectBoundingBox"
    >
      <stop offset="0%" stopColor={color.red} />
      <stop offset="50%" stopColor={color.green} />
      <stop offset="100%" stopColor={color.blue} />
    </linearGradient>
  );
};

export default LinearGradientEffectSvg;
