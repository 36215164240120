// react
import { useState, useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { ChallengeProps } from "interfaces/challenge";

// icons whores
import IconWhoreLabs from "components/iconWhores/IconWhoreLabs";

// components
import ChallengeButton from "handlers/challengeButtons/ChallengeButton";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// presentations
import LabsPresentation from "handlers/challengeButtons/labs/LabsPresentation";

// utils
import color from "styles/color";
import isEmpty from "lodash/isEmpty";
import animationUtils from "utils/animationUtils";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface LabsHandlerProps {
  paused: boolean;
  challenge: ChallengeProps;
  wrapper: HTMLDivElement | null;
}

const LabsHandler = ({ paused, wrapper, challenge }: LabsHandlerProps) => {
  const dispatch = useDispatch();
  const { labs, flowInit, flowFinished } = challenge;
  const [animation, setAnimation] = useState("");
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startWhores, [labs, flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(buttonZoomOut, [flowFinished]);

  function startWhores() {
    if (!flowInit) return;
    if (isEmpty(labs)) return;

    setTimeout(displayWhores, 800);
  }

  function buttonZoomOut() {
    if (!animation) return;
    if (!flowFinished) return;
    if (animation === "animate__zoomOut") return;

    setAnimation("animate__zoomOut");
  }

  function displayWhores() {
    setWhores([...getWhores()]);
  }

  function getWhores(): AttentionWhoreProps[] {
    const data = labs[0].data;
    if (!data) return [attentionWhoreUtils.fake()];

    return [message(data, true)];
  }

  function message(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreLabs,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "pink",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    freeze();
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function finishedPresentation() {
    displayButton();
    setAttentionWhore({ ...attentionWhore, show: false });
  }

  function displayButton() {
    setAnimation(animationUtils.getRandom());
  }

  function hideButton() {
    setTimeout(() => setAnimation("animate__zoomOut"));
  }

  function onClick() {
    if (animation === "animate__zoomOut") return;

    displayWhores();
    setTimeout(hideButton);
  }

  function unfreeze() {
    setTimeout(() => dispatch(challengeFlowActions.unfreeze()));
  }

  function freeze() {
    setTimeout(() => dispatch(challengeFlowActions.freeze()), 800);
  }

  return (
    <>
      {!!animation && (
        <ChallengeButton
          bottom={230}
          onClick={onClick}
          color={color.pink}
          label={<span>labs</span>}
          customClassName={`animate__animated ${animation} no-swipe`}
        />
      )}

      {labs.map((lab, index) => (
        <LabsPresentation
          key={index}
          code={lab.data}
          onClose={unfreeze}
          show={!!attentionWhore.show}
          language={lab.programmingLanguage}
          animationEnd={finishedPresentation}
        />
      ))}

      <AttentionWhores
        paused={paused}
        whores={whores}
        clear={flowFinished}
        disabledDrop={!!attentionWhore.show}
      />
    </>
  );
};

export default LabsHandler;
