// react
import { useState } from "react";

// ionic-react
import { IonInput, IonItem, IonLabel } from "@ionic/react";

// components
import Board from "components/Board";
import FormHeader from "components/form/FormHeader";
import { DataCacheProps } from "components/presentations/Customization";
import NerdXFooter from "components/presentations/spaceship/NerdXFooter";

// entities
import SpaceshipEngravingEntity from "entities/SpaceshipEngravingEntity";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  > .body {
    .body_input_wrapper {
      width: 100%;
      padding: 0 20px;
      border: none;

      .engraving_input {
        --color: ${color.grey.middle};
      }
    }

    .ps {
      margin-top: 10px;
      margin-right: auto;

      p.engraving_ps {
        text-align: left;
        font-size: 12px;
        color: ${color.grey.strong};
        margin: 8px 0;
        opacity: 0.6;
      }
    }
  }
`;

const Item = styled(IonItem)`
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --border-color: ${color.grey.stronger};
`;

const Label = styled(IonLabel)`
  --color: ${color.grey.middle} !important;
`;

interface SpaceshipEngravingProps {
  goBack(): void;
  goForward(): void;
  cache: DataCacheProps;
  getSpaceshipClassNameColor(): string;
  demo?: boolean;
}

const SpaceshipEngraving = ({
  demo,
  cache,
  goBack,
  goForward,
  getSpaceshipClassNameColor,
}: SpaceshipEngravingProps) => {
  const [engraving, setEngraving] = useState(
    demo ? SpaceshipEngravingEntity.defaultName : ""
  );

  function save() {
    cache.spaceshipEngraving = engraving;
    setTimeout(() => goForward());
  }

  function onNameChange(e: any) {
    if (demo) return;
    setEngraving(e.target.value);
  }

  function isValidName(name: string): boolean {
    if (!name) return false;
    if (hasNerdfyPrefix(name)) return false;
    if (isEqualToCodename(name)) return false;

    return !!name.match(/^[a-zA-Z0-9][a-zA-Z0-9_ ]{2,16}$/);
  }

  function hasNerdfyPrefix(name: string): boolean {
    return !!name.match(/^(nerdfy)/);
  }

  function isEqualToCodename(name: string): boolean {
    return name === cache.codename;
  }

  function isContinueAvailable(): boolean {
    if (demo) return true;
    return isValidName(engraving);
  }

  return (
    <Wrapper>
      <FormHeader
        back={goBack}
        proceed={save}
        disabled={!isContinueAvailable()}
      />

      <div className="body">
        <div className="body_header">
          <Board>
            <p>
              <span className="imp">Toda</span>{" "}
              <span className={getSpaceshipClassNameColor()}>espaçonave</span>{" "}
              sai de <span className="orange">fábrica</span> com{" "}
              <span className="nerdfy_pink">gravação personalizada</span>.
            </p>
          </Board>
        </div>

        <div className="body_input_wrapper">
          <Item>
            <Label position="floating">Digite sua gravação</Label>

            <IonInput
              type="text"
              value={engraving}
              placeholder={
                demo ? "" : `Ex. ${SpaceshipEngravingEntity.defaultName}`
              }
              onIonChange={onNameChange}
              disabled={demo}
              className="engraving_input"
            ></IonInput>
          </Item>
        </div>

        {!demo && !isValidName(engraving) && (
          <div className="ps">
            <p className="engraving_ps">
              * Insira entre 3-15 caracteres (letras | números | underline)
            </p>
            <p className="engraving_ps">
              * A gravação não pode ser igual ao seu codinome
            </p>
            <p className="engraving_ps">
              * A gravação não pode conter o prefixo "nerdfy"
            </p>
          </div>
        )}
      </div>

      <NerdXFooter />
    </Wrapper>
  );
};

export default SpaceshipEngraving;
