// redux
import { useEffect } from "react";
import { collection } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

// redux
import { useDispatch } from "react-redux";
import { shopActions } from "redux/slices/shopSlice";

// interfaces
import { ShopItemProps } from "interfaces/shop";
import { ShopItemFirebaseProps } from "interfaces/shopFirebase";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// parsers
import shopItemParser from "parsers/shopItemParser";

// utils
import isEmpty from "lodash/isEmpty";

interface PlayerShopItemsFirebaseSyncProps {
  userId: number | string;
}

const PlayerShopItemsFirebaseSync = ({
  userId,
}: PlayerShopItemsFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useCollection(
    collection(FirebaseCoreService.getDatabase(), `players/${userId}/shopItems`)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    const docs = snapshot?.docs;

    if (!docs) return;
    if (isEmpty(docs)) return;

    const reduced = docs.reduce((acc, doc) => {
      const value = doc.data() as ShopItemFirebaseProps;

      acc.push(shopItemParser.mapAsRead(value));
      return acc;
    }, [] as { id: number; read: boolean }[]);

    dispatch(shopActions.updateShopItemsAsRead(reduced));
  }

  return null;
};

export default PlayerShopItemsFirebaseSync;
