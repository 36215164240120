// components
import ZarvoxSintarSvg from "svg/characters/ZarvoxSintarSvg";

interface AvatarProps {
  customClassName?: string;
  onAnimationEnd?: () => void;
}

const AvatarZarvox = ({
  onAnimationEnd,
  customClassName = "",
}: AvatarProps) => {
  return (
    <div className={customClassName} onAnimationEnd={onAnimationEnd}>
      <ZarvoxSintarSvg width="33" height="33" />
    </div>
  );
};

export default AvatarZarvox;
