// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesQtyProps,
  SpaceshipResourcesSourceDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";

// utils
import isEmpty from "lodash/isEmpty";
import { AllToOptional } from "utils/transformTypeUtils";

interface SpaceshipResourcesSourceEnrichedDataProps
  extends SpaceshipResourcesSourceDataProps {
  sourceId: string;
  resourceKey: keyof SpaceshipResourcesProps;
}

interface InputProps {
  resources: AllToOptional<SpaceshipResourcesProps>;
}

export default class SpaceshipResourcesEntity {
  private readonly resources: AllToOptional<SpaceshipResourcesProps>;

  constructor({ resources }: InputProps) {
    this.resources = resources;
  }

  get transformedValues(): SpaceshipResourcesSourceEnrichedDataProps[] {
    if (isEmpty(this.resources)) return [];
    return this.reduce();
  }

  mapByQuantity(
    resources: SpaceshipResourcesSourceEnrichedDataProps[],
    data: AllToOptional<SpaceshipResourcesQtyProps>
  ): SpaceshipResourcesSourceEnrichedDataProps[] {
    return Object.keys(data).reduce((acc, r) => {
      const counter = data[r as keyof SpaceshipResourcesQtyProps];
      if (!counter) return acc;

      let i = 0;
      const filtered = resources.filter((resource) => {
        return resource.resourceKey === r && i++ < counter;
      });
      acc.push(...filtered);

      return acc;
    }, [] as SpaceshipResourcesSourceEnrichedDataProps[]);
  }

  stringifyDeleteFields(
    data: SpaceshipResourcesSourceEnrichedDataProps[]
  ): string[] {
    return data.reduce((acc, data) => {
      acc.push(`resources.${String(data.resourceKey)}.${data.sourceId}`);
      return acc;
    }, [] as string[]);
  }

  private reduce(): SpaceshipResourcesSourceEnrichedDataProps[] {
    const keys = Object.keys(this.resources) as Array<
      keyof SpaceshipResourcesProps
    >;

    return keys.reduce((acc, resourceName) => {
      const sources = this.resources[resourceName]?.source;
      if (!sources) return acc;

      return Object.keys(sources).reduce((acc, sourceKey) => {
        const resource = sources[sourceKey];

        if (!resource) return acc;
        if (!resource.transformed) return acc;

        acc.push({
          ...resource,
          resourceKey: resourceName,
          sourceId: sourceKey,
        });
        return acc;
      }, acc);
    }, [] as SpaceshipResourcesSourceEnrichedDataProps[]);
  }
}
