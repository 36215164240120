interface NotifyAnimateFactoryDataProps {
  factories: (() => HTMLDivElement)[];
  fast?: boolean;
  slow?: boolean;
}

interface NotifyAnimateProps {
  wrapper: HTMLDivElement;
  factoriesData: NotifyAnimateFactoryDataProps[];
}

export enum NotifyFactoryType {
  Danger = 1,
  Success,
  Congrats,
  Light,
  Warning,
}

export interface NotifyFactoryProps {
  type: NotifyFactoryType;
  content: string;
  animation?: string;
}

const BOUNCE_OUT_DELAY = 1500;
const BOUNCE_OUT_DELAY_FAST = 0;
const BOUNCE_OUT_DELAY_SLOW = 2000;

export const NotifyFactory = ({
  type,
  content,
  animation = "animate__bounceInDown",
}: NotifyFactoryProps): (() => HTMLDivElement) => {
  return function () {
    const el = document.createElement("div");
    const typeClassName = getType(type);

    el.className = `pixelify_font_family notify_nerdfy ${typeClassName} animate__animated ${animation}`;
    el.innerHTML = content;

    return el;
  };
};

export const notifyAnimate = ({
  wrapper,
  factoriesData,
}: NotifyAnimateProps) => {
  const factoryData = factoriesData.shift();
  if (!factoryData) return;

  setTimeout(() => {
    const { factories } = factoryData;

    factories.forEach((factory, i: number) => {
      const el = factory();
      el.addEventListener("animationend", onAnimationEnd);

      wrapper.appendChild(el);

      function onAnimationEnd(e: AnimationEvent) {
        e.preventDefault();
        if (!el) return;

        setTimeout(
          () => {
            el.classList.add("animate__bounceOutLeft");

            if (e.animationName === "bounceOutLeft") {
              el.removeEventListener("animationend", onAnimationEnd);
              el.classList.add("d_none");
              el.remove();

              // last element
              if (i === factories.length - 1)
                setTimeout(() => notifyAnimate({ wrapper, factoriesData }));
            }
          },
          factoryData?.fast
            ? BOUNCE_OUT_DELAY_FAST
            : factoryData?.slow
            ? BOUNCE_OUT_DELAY_SLOW
            : BOUNCE_OUT_DELAY
        );
      }
    });
  });
};

// private

function getType(type: NotifyFactoryType): string {
  return type === NotifyFactoryType.Danger
    ? "nerdfy_red"
    : type === NotifyFactoryType.Success
    ? "nerdfy_green"
    : type === NotifyFactoryType.Congrats
    ? "nerdfy_purple"
    : type === NotifyFactoryType.Light
    ? "nerdfy_white"
    : type === NotifyFactoryType.Warning
    ? "nerdfy_yellow"
    : "nerdfy_green";
}
