// icons
import ErgoZarvoxQuizIcon from "icons/ergonians/ErgoZarvoxQuizIcon";

// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// utils
import color from "styles/color";

const IconWhoreQuiz = ({ active }: AttentionWhoreElProps) => {
  return (
    <ErgoZarvoxQuizIcon
      width="35"
      height="35"
      color={active ? color.green : undefined}
    />
  );
};

export default IconWhoreQuiz;
