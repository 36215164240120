// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { PlayerSpaceshipFirebaseDataProps } from "interfaces/playerFirebase";

// utils
import isEmpty from "lodash/isEmpty";
import { AllToOptional } from "utils/transformTypeUtils";

type PlayerSpaceshipDataOptionalProps =
  AllToOptional<PlayerSpaceshipFirebaseDataProps>;

interface SpaceshipProps {
  spaceship: PlayerSpaceshipDataOptionalProps;
}

class PlayerResourcesDataFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async update(
    userId: number | string,
    {
      color,
      radar,
      reload,
      bullets,
      engraving,
      machineGun,
    }: PlayerSpaceshipDataOptionalProps
  ): Promise<void> {
    if (!userId) return Promise.reject();

    const path = `players/${userId}`;
    const data: SpaceshipProps = { spaceship: {} };

    if (radar) data.spaceship.radar = radar;
    if (color) data.spaceship.color = color;
    if (reload) data.spaceship.reload = reload;
    if (bullets) data.spaceship.bullets = bullets;
    if (engraving) data.spaceship.engraving = engraving;
    if (machineGun || machineGun === 0) data.spaceship.machineGun = machineGun;

    if (isEmpty(data.spaceship)) return Promise.reject();
    return this._firebase.set(path, data);
  }
}

export default PlayerResourcesDataFirebaseService;
