// react
import { useState, useEffect, useRef } from "react";

// redux
import { useDispatch } from "react-redux";
import { walkieTalkieActions } from "redux/slices/walkieTalkieSlice";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// icons
import IconWhoreSendMessage from "components/iconWhores/IconWhoreSendMessage";

// interfaces
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import AmateurRadioMessageHandler from "handlers/amateurRadio/AmateurRadioMessageHandler";

// utils
import isEmpty from "lodash/isEmpty";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface WalkieTalkieHandlerProps {
  paused: boolean;
  hasAmateurRadioItem: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

const AmateurRadioWhoreHandler = ({
  paused,
  wrapper,
  challenge,
  hasAmateurRadioItem,
}: WalkieTalkieHandlerProps) => {
  const dispatch = useDispatch();
  const hasAlreadyStarted = useRef(false);
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [showMessagePresentation, setShowMessagePresentation] = useState(false);
  const { flowFinished, flowDone } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [flowFinished]);

  function bootstrap() {
    if (!flowFinished) return;
    if (!hasAmateurRadioItem) return;
    if (hasAlreadyStarted.current) return;

    hasAlreadyStarted.current = true;
    displayWhore();
  }

  function displayWhore() {
    if (!wrapper) return;
    if (!isEmpty(whores)) return;

    const whore = attentionWhoreUtils.get({
      payload: "",
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "white",
      WhoreEl: IconWhoreSendMessage,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setWhores([whore]);
  }

  function onAttentionWhoreCall(_attentionWhore: AttentionWhoreProps) {
    setShowMessagePresentation(true);
  }

  function submitMessage(message: string) {
    dispatch(walkieTalkieActions.async.broadcast({ message }));
  }

  function onCloseMessagePresentation() {
    setShowMessagePresentation(false);
  }

  return (
    <>
      <AttentionWhores paused={paused} whores={whores} clear={flowDone} />

      {showMessagePresentation && (
        <AmateurRadioMessageHandler
          submitMessage={submitMessage}
          onClose={onCloseMessagePresentation}
        />
      )}
    </>
  );
};

export default AmateurRadioWhoreHandler;
