// react
import { useState, useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// components
import DragWhores from "components/dragWhores/DragWhores";

// interfaces
import {
  SudoFlowProps,
  CurrentDragWhoreProps,
} from "handlers/challengeButtons/sudo/SudoHandler";
import { DragWhoreProps } from "interfaces/dragWhore";
import { DragWhoreTemplateElProps } from "components/dragWhores/DragWhore";
import BubbleHandler from "handlers/bubble/BubbleHandler";

// utils
import interact from "interactjs";
import dragWhoreUtils from "utils/dragWhoreUtils";

export const DragWhoreSudoTemplateEl = ({
  whore,
}: DragWhoreTemplateElProps) => {
  const payload = whore.payload as CurrentDragWhoreProps;

  return (
    <p className={`nerdfy_white pixelify_font_family`}>{payload.content}</p>
  );
};

const SudoInit = ({
  paused,
  challenge,
  getWhores,
  getContent,
}: SudoFlowProps) => {
  const dispatch = useDispatch();
  const { flowDone, flowInit } = challenge;
  const [whores, setWhores] = useState<DragWhoreProps[][]>([]);
  const [whore, setWhore] = useState<CurrentDragWhoreProps>(
    dragWhoreUtils.fake()
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [flowInit]);

  function bootstrap() {
    if (!flowInit) return;
    setTimeout(displayWhores, 500);
  }

  function displayWhores() {
    setWhores([...whores, getWhores("sudo", onDragWhoreCall)]);
  }

  function onDragWhoreCall(whore: DragWhoreProps) {
    setWhore({
      ...whore,
      show: true,
    });
  }

  function hideBubble() {
    if (!whore) return;
    if (whore.last) start();

    setWhore({
      ...whore,
      show: false,
    });
  }

  function start() {
    setTimeout(() => dispatch(challengeActions.async.start()), 100);
  }

  return (
    <>
      {whores.map((whores, i: number) => (
        <DragWhores
          key={i}
          whores={whores}
          paused={paused}
          clear={flowDone}
          dropZoneBorderColor="white"
          disabledDrop={!!whore.show}
          TemplateEl={DragWhoreSudoTemplateEl}
          draggableClassName="drag_whore_dropzone_sudo"
          droppableAcceptClassName="drag_whore_droppable_sudo"
          interactDraggable={interact(".drag_whore_dropzone_sudo")}
          interactDropZone={interact(".drag_whore_dropzone_sudo")}
        />
      ))}

      <BubbleHandler
        hide={hideBubble}
        show={!!whore.show}
        type={whore.bubbleType}
        content={getContent(whore)}
      />
    </>
  );
};

export default SudoInit;
