// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// firestore
import { serverTimestamp } from "firebase/firestore";

// svgs
import Printer2dPixelSvg from "svg/Printer2dPixelSvg";

interface IconProps {
  width?: string;
  height?: string;
}

const Icon = ({ width = "35", height = "35" }: IconProps) => {
  return <Printer2dPixelSvg width={width} height={height} />;
};

export default class Printer2dItemEntity extends AbstractShopItemEntity {
  readonly id = 6;
  readonly price = 2;
  readonly subTitle = "";
  readonly maxQuantity = 1;
  readonly available = false;
  readonly icon = (<Icon />);
  readonly title = "Impressora 2D";
  readonly bubbles = this.shopBubbles;
  readonly type = SpaceshipItemEnum.Printer2d;
  readonly image = (<Icon width="45" height="45" />);
  readonly capacity = AbstractShopItemEntity.capacityNone;
  readonly lifeCycles = AbstractShopItemEntity.lifeCyclesNone;

  get item(): ShopItemProps {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      price: this.price,
      bubbles: this.bubbles,
      subTitle: this.subTitle,
      capacity: this.capacity,
      available: this.available,
      lifeCycles: this.lifeCycles,
    };
  }

  get values(): PlayerShopItemsFirebaseProps {
    return {
      [this.getItemId(this.type)]: {
        t: this.type,
        c: this.capacity,
        l: this.lifeCycles,
        s: serverTimestamp(),
      },
    };
  }

  get resourcesPrice(): SpaceshipResourcesQtyProps {
    return {
      sand: 0,
      gold: 1,
      water: 1,
      silver: 1,
      copper: 2,
      estelin: 2,
      bauxite: 1,
      strongForce: 0,
    };
  }

  getIcon({ width, height }: IconProps): JSX.Element {
    return <Icon width={width} height={height} />;
  }

  private get shopBubbles(): ShopItemBubbleProps[] {
    return [
      {
        id: 1,
        itemId: this.id,
        question: "Quantos ciclos?",
        answer: `Caro primata, a impressora 2D foi especialmente projetada para ter ciclos de vidas infinitos. Definitivamente é um item ESSENCIAL para quem queira ser um EXPLORADOR e assim partir para o DESCONHECIDO...`,
      },
      {
        id: 2,
        itemId: this.id,
        question: "Como funciona?",
        answer: `Uma vez que você tenha as respectivas matéria primas, como por exemplo, minérios refinados, será possível imprimir e gerar novos itens. [A impressora 2D é essencial para o EXPLORADOR, uma vez que no espaço DESCONHECIDO não há lojas]`,
      },
      {
        id: 3,
        itemId: this.id,
        question:
          "Se as lojas já vendem os itens, porque eu preciso de uma impressora?",
        answer: `Mesmo que você tenha acesso as lojas, a impressora 2D imprime itens a partir de matérias primas mais acessíveis... Caso você deseje sair da zona de segurança e partir para o DESCONHECIDO, a impressora 2D é essencial já que não existem lojas, ou ao menos, não conhecemos nenhuma, fora de nossa zona de segurança.`,
      },
      {
        id: 5,
        itemId: this.id,
        question: "O que seria o DESCONHECIDO?",
        answer: `Hummmmm... Bem... São lugares que não conhecemos, ou seja, fora da nossa zona de segurança...`,
      },
    ];
  }
}
