export type borderColorType =
  | "red"
  | "purple"
  | "yellow"
  | "blue"
  | "white"
  | "green"
  | "orange"
  | "brown";

function getBorderColorClassName(color: borderColorType): string {
  if (color === "yellow") return "border_dashed_yellow";
  if (color === "green") return "border_dashed_green";
  if (color === "blue") return "border_dashed_blue";
  if (color === "white") return "border_dashed_white";
  if (color === "orange") return "border_dashed_orange";
  if (color === "purple") return "border_dashed_purple";
  if (color === "red") return "border_dashed_red";
  if (color === "brown") return "border_dashed_brown";
  if (color === "acai") return "border_dashed_acai";

  return "border_dashed_yellow";
}

const dropUtils = {
  getBorderColorClassName,
};

export default dropUtils;
