// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const ArrowRightPixelSvg = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 12.7 12.7"
    >
      <g>
        <path
          d="M7.938 2.647v2.115H.529v3.175h7.409v2.117h1.059V8.996h1.058V7.937h1.059V6.88h1.056V5.82h-1.057V4.762h-1.058v-1.06H8.997V2.648z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ArrowRightPixelSvg;
