// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { challengesActions } from "redux/slices/challengesSlice";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { ChallengesMergedProps } from "interfaces/challengesMerged";

// utils
import isEmpty from "lodash/isEmpty";
import stateUtils from "redux/utils/stateUtils";

const challengesMiddleware = createListenerMiddleware();

// challengesMerged/merge
challengesMiddleware.startListening({
  actionCreator: challengesActions.async.merge,
  effect: async ({ payload }, listenerApi) => {
    const { challengeSections } = stateUtils.get(listenerApi);
    const merged = merge(challengeSections, payload.hashes);

    listenerApi.dispatch(challengesActions.set(merged.all));
    listenerApi.dispatch(challengesActions.setHash(merged.hash));
  },
});

// private

function merge(
  sections: ChallengeSectionProps[],
  hashes: ChallengesCommonHashProps[]
): ChallengesMergedProps {
  if (isEmpty(sections)) return fake();

  let all: ChallengeCommonProps[] = [];
  const hash = sections.reduce((acc, section) => {
    const sectionId = section.id;

    all = hashes.reduce((acc, hash) => {
      const challengesFromSection = hash[sectionId] || [];
      acc.push(...challengesFromSection);

      return acc;
    }, [] as ChallengeCommonProps[]);

    acc[sectionId] = sort(all);

    return acc;
  }, {} as ChallengesCommonHashProps);

  return { all, hash };
}

function fake() {
  return {
    all: [],
    hash: {},
  };
}

function sort(challenges: ChallengeCommonProps[]): ChallengeCommonProps[] {
  return challenges.sort((a, b) => {
    if (a.index > b.index) return 1;
    if (a.index < b.index) return -1;

    return 0;
  });
}

export default challengesMiddleware;
