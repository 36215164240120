interface SvgProps {
  width?: string;
  height?: string;
}

const LevelUpBadgeIcon = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M411.826 100.174V33.391h-66.783V0H166.957v33.391h-66.783v66.783H66.783v178.087h33.391V512h66.783v-33.391h33.391V512h33.391v-33.391h44.522V512h33.391v-33.391h33.391V512h66.783V278.261h33.391V100.174z"
          fill="#f14c5d"
          data-original="#fb0023"
          opacity="1"
        ></path>
        <path
          d="M445.217 100.174h-33.391V33.391h-66.783V0H166.957v33.391h-66.783v66.783H66.783v178.087h33.391v66.782h66.783v33.392h178.086v-33.392h66.783v-66.782h33.391z"
          fill="#ffde46"
          data-original="#ffde46"
          opacity="1"
        ></path>
        <path
          d="M211.478 100.174v33.391h33.392v144.696h33.391V100.174zM166.957 0h178.087v33.391H166.957zM166.957 345.043h178.087v33.391H166.957zM133.565 66.783h33.392V33.391h-66.783v66.783h33.391zM66.783 100.174h33.391v178.087H66.783zM378.435 66.783v33.391h33.391V33.391h-66.783v33.392zM411.826 100.174h33.391v178.087h-33.391zM133.565 345.043h33.392v-33.391h-33.392v-33.391h-33.391V512h66.783v-33.391h-33.392z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M378.435 311.652h-33.392v33.391h33.392v133.566h-33.392V512h66.783V278.261h-33.391zM166.957 445.217h33.391v33.391h-33.391zM278.261 478.609h33.391V512h-33.391zM200.348 478.609h33.391V512h-33.391zM233.739 445.217h44.522v33.391h-44.522zM311.652 445.217h33.391v33.391h-33.391z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default LevelUpBadgeIcon;
