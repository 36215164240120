// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// firestore
import { serverTimestamp } from "firebase/firestore";

// svgs
import RefineMachinePixelSvg from "svg/RefineMachinePixelSvg";

interface IconProps {
  width?: string;
  height?: string;
}

const Icon = ({ width = "35", height = "35" }: IconProps) => {
  return <RefineMachinePixelSvg width={width} height={height} />;
};

export default class RefineMachineItemEntity extends AbstractShopItemEntity {
  readonly id = 1;
  readonly price = 2;
  readonly capacity = 5;
  readonly maxQuantity = 6;
  readonly lifeCycles = 100;
  readonly available = false;
  readonly icon = (<Icon />);
  readonly subTitle = "Modelo Básico";
  readonly bubbles = this.shopBubbles;
  readonly type = SpaceshipItemEnum.RefineMachine;
  readonly image = (<Icon width="45" height="45" />);
  readonly title = "Refinador a vácuo de minérios brutos";

  get item(): ShopItemProps {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      price: this.price,
      bubbles: this.bubbles,
      subTitle: this.subTitle,
      capacity: this.capacity,
      available: this.available,
      lifeCycles: this.lifeCycles,
    };
  }

  get values(): PlayerShopItemsFirebaseProps {
    return {
      [this.getItemId(this.type)]: {
        t: this.type,
        c: this.capacity,
        l: this.lifeCycles,
        s: serverTimestamp(),
      },
    };
  }

  get resourcesPrice(): SpaceshipResourcesQtyProps {
    return {
      sand: 1,
      gold: 1,
      water: 1,
      silver: 1,
      copper: 2,
      estelin: 1,
      bauxite: 2,
      strongForce: 1,
    };
  }

  getIcon({ width, height }: IconProps): JSX.Element {
    return <Icon width={width} height={height} />;
  }

  private get shopBubbles(): ShopItemBubbleProps[] {
    return [
      {
        id: 1,
        itemId: this.id,
        question: `Qual a capacidade? (${this.capacity})`,
        answer: `Caro primata, o refinador BÁSICO tem o INCRÍVEL espaço para ${this.capacity} minérios brutos serem processados ao MESMO tempo.`,
      },
      {
        id: 2,
        itemId: this.id,
        question: "É possível aumentar a capacidade?",
        answer: `Sim! A capacidade do refinador pode ser ampliada com a compra de novos refinadores... Com a quantidade máxima de ${
          this.maxQuantity
        } refinadores, a capacidade é ampliada para até ${
          this.capacity * this.maxQuantity
        } minérios brutos a serem refinados ao MESMO tempo.`,
      },
      {
        id: 3,
        itemId: this.id,
        question: `Quantos ciclos? (${this.lifeCycles})`,
        answer: `O ciclo de vida corresponde a cada vez que o refinador é utilizado... São INCRÍVEIS ${this.lifeCycles} ciclos de vida.`,
      },
      {
        id: 4,
        itemId: this.id,
        question: "Para que serve?",
        answer: `O refinador a vácuo de minérios brutos serve para transformar rochas espaciais em minérios refinados... Uma vez refinados, os minérios podem ser utilizados para diversos fins...`,
      },
    ];
  }
}
