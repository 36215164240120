// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const ErgoZarvoxQuizIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M361.41 60.24v30.11H150.59V60.24h-30.12v90.35h30.12v-30.12h210.82v30.12h30.12V60.24zM331.29 150.59h30.12v30.12h-30.12zM331.29 30.12h30.12v30.12h-30.12zM271.06 421.65h-30.12v-30.12h-30.12v90.35h30.12V512h30.12v-30.12h30.12v-90.35h-30.12zM180.71 180.71v210.82h30.11V271.06h90.36v120.47h30.11V180.71zm120.47 60.23h-90.36v-30.12h90.36z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M240.94 301.18h30.12v60.23h-30.12zM180.71 0h150.58v30.12H180.71zM150.59 150.59h30.12v30.12h-30.12zM150.59 30.12h30.12v30.12h-30.12z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ErgoZarvoxQuizIcon;
