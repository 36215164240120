// components
import PressButton from "components/pressButtons/PressButton";

// utils
import color from "styles/color";

interface PressButtonProps {
  onClick: () => void;
  description: JSX.Element;
  goTo?: JSX.Element;
}

const PressButtonContinue = ({
  onClick,
  goTo,
  description,
}: PressButtonProps) => {
  return (
    <PressButton
      goTo={goTo}
      onClick={onClick}
      color={color.yellow}
      description={description}
      title="Aperte para Continuar"
    />
  );
};

export default PressButtonContinue;
