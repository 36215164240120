// ionic-react
import { IonList } from "@ionic/react";

// formik
import { Formik, Form, FormikProps } from "formik";

// components
import FormikInput from "components/formik/FormikInput";
import FormikAutoFill from "components/formik/FormikAutoFill";
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// interfaces
import { UserSignProps } from "interfaces/user";

// utils
import * as yup from "yup";
import isEmpty from "lodash/isEmpty";
import color from "styles/color";

interface SignInProps {
  onSignedIn: (user: UserSignProps) => void;
  disabledInputs?: boolean;
}

const SignIn = ({ onSignedIn, disabledInputs }: SignInProps) => {
  function getSchemaValidation() {
    const email = yup
      .string()
      .required("Qual o seu email?")
      .email("Por favor, insira um email válido");

    const password = yup
      .string()
      .required("Qual a senha?")
      .min(8, "A senha deve ter no mínimo 8 caracteres");

    return yup.object({
      email,
      password,
    });
  }

  function getInitialValues(): UserSignProps {
    return {
      email: "",
      password: "",
    };
  }

  function hasEmptyValue({ email, password }: UserSignProps): boolean {
    return isEmpty(email) || isEmpty(password);
  }

  return (
    <Formik
      validationSchema={getSchemaValidation()}
      initialValues={getInitialValues()}
      onSubmit={onSignedIn}
    >
      {(props: FormikProps<UserSignProps>) => {
        return (
          <Form>
            <FormikAutoFill
              props={props}
              identifier="userSignInEmail"
              field="email"
            />
            <FormikAutoFill
              props={props}
              identifier="userSignInPassword"
              field="password"
            />

            <IonList>
              <FormikInput
                id="userSignInEmail"
                label="Seu Email Preferido"
                name="email"
                type="email"
                inputmode="email"
                autocomplete="email"
                autocapitalize="off"
                autocorrect="off"
                placeholder="seuemailpreferido@exemplo.com"
                disabled={disabledInputs}
              />

              <FormikInput
                id="userSignInPassword"
                label="Sua Senha Super Secreta"
                name="password"
                type="password"
                autocomplete="current-password"
                autocapitalize="off"
                autocorrect="off"
                placeholder="A senha deve ter no mínimo 8 caracteres"
                disabled={disabledInputs}
              />
            </IonList>

            <ButtonDuolingo
              type="submit"
              disabled={!isEmpty(props.errors) || hasEmptyValue(props.values)}
              className="submit_button"
              color={color.purple}
            >
              Continuar
            </ButtonDuolingo>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignIn;
