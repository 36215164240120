// icons
import MarketIcon from "icons/shopItems/MarketIcon";

// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreMarket = (_data: AttentionWhoreElProps) => {
  return <MarketIcon width="35" height="35" />;
};

export default IconWhoreMarket;
