// react
import { useState, useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// entities
import JarvisEntity from "entities/characters/JarvisEntity";

// icons
import IconWhoreAddPerson from "components/iconWhores/IconWhoreAddPerson";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import JarvisPresentation from "components/presentations/JarvisPresentation";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface JarvisPresentationHandlerProps {
  paused: boolean;
  callPresentation: () => void;
  wrapper: HTMLDivElement | null;
  finishPresentation: () => void;
  challenge: ChallengeCommonProps;
}

const JarvisPresentationHandler = ({
  paused,
  wrapper,
  challenge,
  callPresentation,
  finishPresentation,
}: JarvisPresentationHandlerProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const jarvis = useSelector(s.jarvis());
  const { flowDone, flowFinished } = challenge;
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [showJarvisPresentation, setShowPresentation] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePresentation, [auth, jarvis.name]);

  function handlePresentation() {
    if (hasJarvisName()) return;
    if (!auth.signed) return;
    if (!auth.user.genre) return;
    if (!auth.user.codename) return;

    setTimeout(displayWhores, 1000);
  }

  function hasJarvisName(): boolean {
    const jarvisName = new JarvisEntity({ name: jarvis.name });
    return jarvisName.has();
  }

  function displayWhores() {
    if (!wrapper) return;

    const whore = attentionWhoreUtils.get({
      payload: "",
      WhoreEl: IconWhoreAddPerson,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "blue",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setWhores([whore]);
  }

  function onAttentionWhoreCall() {
    freeze();
    callPresentation();
    setTimeout(() => setShowPresentation(true));
  }

  function onFinish() {
    unfreeze();
    setTimeout(finishPresentation);
  }

  function unfreeze() {
    setTimeout(() => dispatch(challengeFlowActions.unfreeze()));
  }

  function freeze() {
    setTimeout(() => dispatch(challengeFlowActions.freeze()), 800);
  }

  return (
    <>
      <AttentionWhores
        paused={paused}
        whores={whores}
        clear={flowDone && flowFinished}
      />

      {showJarvisPresentation && (
        <JarvisPresentation
          wrapper={wrapper}
          demo={auth.anonymous}
          codename={auth.user.codename}
          show={showJarvisPresentation}
          finishPresentation={onFinish}
        />
      )}
    </>
  );
};

export default JarvisPresentationHandler;
