// react
import { useState, useCallback, useRef } from "react";

// redux
import { useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// components
import { alertLeft, AlertEncyclopediaFactory } from "factories/AlertFactory";
import Board from "components/Board";
import FormHeader from "components/form/FormHeader";
import AvatarZarvox from "components/avatars/AvatarZarvox";
import { DropZoneAttentionWhore } from "components/DropZone";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import { CivilizationErgonianFooter } from "components/presentations/CivilizationFooter";

// entities
import {
  PlayerCheckpointZarvoxDataFirebaseEntity,
  PlayerCheckpointCivilizationErgonianDataFirebaseEntity,
} from "entities/data/PlayerCheckpointDataFirebaseEntity";

// icons
import IconWhoreQuiz from "components/iconWhores/IconWhoreQuiz";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// enums
import { BubbleType } from "enums/bubbleEnum";

// utils
import color from "styles/color";
import styled from "styled-components";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000;

  > .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-bottom: 50px;
    padding-bottom: 50px;

    .content {
      display: flex;
      align-items: center;
    }
  }
`;

interface JarvisPresentationProps {
  show: boolean;
  codename: string;
  wrapper: HTMLDivElement;
  finishPresentation: () => void;
  demo?: boolean;
}

const ZarvoxPresentation = ({
  show,
  wrapper,
  codename,
  finishPresentation,
}: JarvisPresentationProps) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const [isSavedName, setIsSavedName] = useState(false);
  const [animation, setAnimation] = useState("animate__bounceInUp");
  const [error, setError] = useState("");
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(getEmptyAttentionWhore());
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    wrapperRef.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function save() {
    alert();

    setError("");
    setIsSavedName(true);
    syncCheckpoints();

    setTimeout(() => {
      setWhores([
        first(),
        second(),
        third(),
        four(),
        five(),
        six(),
        seven(),
        eight(),
        nine(),
        ten(),
        eleven(),
      ]);
    }, 1000);
  }

  function syncCheckpoints() {
    const entityZarvox = new PlayerCheckpointZarvoxDataFirebaseEntity();
    const entityErgonians =
      new PlayerCheckpointCivilizationErgonianDataFirebaseEntity();

    dispatch(
      playerActions.async.updateCheckpoint({
        ...entityZarvox.values,
        ...entityErgonians.values,
      })
    );
  }

  // ATTENTION WHORES

  function first(): AttentionWhoreProps {
    const payload = `(Música clássica de auditório tocando...)`;
    return message(payload);
  }

  function second(): AttentionWhoreProps {
    const payload = `O nosso convidado de hoje veio de um mundo de três dimensões, inatingível por nossos sentidos, muito além de nossa imaginação... (enquanto Zarvox fala no idioma dos primatas, o público ouve quase que instantaneamente através de tradutores auriculares...)`;
    return message(payload);
  }

  function third(): AttentionWhoreProps {
    const payload = `Seja bem vindo ${codename}, mas antes de começarmos, devo explicar ao público as regras...`;
    return message(payload);
  }

  function four(): AttentionWhoreProps {
    const payload = `Para ganhar o nosso precioso pingente é preciso acertar 15 perguntas: 5 perguntas fáceis, 5 perguntas medianas e 5 perguntas difíceis...`;
    return message(payload);
  }

  function five(): AttentionWhoreProps {
    const payload = `A cada resposta acertada, você ganha um ponto para a respectiva categoria. Exemplo, se acertar uma pergunta fácil, ganha um ponto para a categoria fácil... Se acertar uma pergunta mediana, ganha um ponto para a categoria mediana... E assim por diante...`;
    return message(payload);
  }

  function six(): AttentionWhoreProps {
    const payload = `A cada resposta errada, existem duas situações...`;
    return message(payload);
  }

  function seven(): AttentionWhoreProps {
    const payload = `1) Caso você NÃO tenha atingido o valor máximo da categoria, isso é, 5 pontos, e errar a resposta, a contagem será zerada para a respectiva categoria...`;
    return message(payload);
  }

  function eight(): AttentionWhoreProps {
    const payload = `Isso é... se você tem 3 pontos na categoria fácil e errar uma pergunta fácil, a contagem será ZERADA somente para a categoria fácil...`;
    return message(payload);
  }

  function nine(): AttentionWhoreProps {
    const payload = `2) Por outro lado, caso já tenha atingido 5 pontos na categoria fácil e errar uma resposta da mesma... a contagem NÃO será zerada...`;
    return message(payload);
  }

  function ten(): AttentionWhoreProps {
    const payload = `As regras estão claras? Vale lembrar que as mesmas regras que se aplicam para a categoria fácil, se aplicam para as demais categorias...`;
    return message(payload);
  }

  function eleven(): AttentionWhoreProps {
    const payload = `Que comece o show de perguntas e respostas... (Música clássica de auditório tocando...)`;
    return message(payload, true);
  }

  function message(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapperRef.current) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreQuiz,
      call: onAttentionWhoreCall,
      bubbleType: BubbleType.Quiz,
      dropZoneBorderColor: "green",
      wrapperWidth: wrapperRef.current.clientWidth,
      wrapperHeight: wrapperRef.current.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function getEmptyAttentionWhore(): CurrentAttentionWhoreProps {
    return attentionWhoreUtils.fake();
  }

  // ATTENTION WHORES END

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });

    setTimeout(() => {
      attentionWhore.last && close();
    });
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
    setTimeout(() => finishPresentation());
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function alert() {
    if (!wrapper) return;

    alertLeft(wrapper, [
      AlertEncyclopediaFactory(
        `O personagem "Zarvox" foi adicionado na sua enciclopédia`
      ),
      AlertEncyclopediaFactory(
        `A civilização "Ergonia" foi adicionada na sua enciclopédia`
      ),
    ]);
  }

  return (
    <>
      {show && (
        <Content
          ref={handle}
          onAnimationEnd={onAnimationEnd}
          className={`animate__animated no-swipe ${animation}`}
        >
          <AttentionWhores
            whores={whores}
            disabledDrop={!!attentionWhore.show}
          />

          <FormHeader brand save={save} disabled={isSavedName} />

          <BubbleHandler
            hide={hideJarvisBubble}
            show={!!attentionWhore.show}
            content={attentionWhore.payload}
            type={attentionWhore.bubbleType}
          />

          <div className="body">
            <AvatarZarvox />

            <div className="content">
              <Board>
                <p>
                  <span className="purple">Graças</span> ao seu{" "}
                  <span className="yellow">conhecimento</span> do{" "}
                  <span className="brown">idioma dos primatas</span>,{" "}
                  <span className="green">Zarvox Sintar</span> foi selecionado{" "}
                  para apresentar o famoso show de{" "}
                  <span className="imp">perguntas</span> e{" "}
                  <span className="imp">respostas</span> "
                  <span className="orange">Quem Quer Ser O Primata</span>".
                </p>

                <p>
                  Sua <span className="red">missão</span> é garantir que somente
                  o <span className="brown">primata</span> mais{" "}
                  <span className="imp">capacitado</span> possa receber um{" "}
                  <span className="blue">item misterioso</span> dos{" "}
                  <span className="green">ergonianos</span>.
                </p>
              </Board>
            </div>
          </div>

          {error && <ErrorFeedback err={error} />}

          <CivilizationErgonianFooter />
          <DropZoneAttentionWhore />
        </Content>
      )}
    </>
  );
};

export default ZarvoxPresentation;
