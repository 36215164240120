// redux
import { playerActions } from "redux/slices/playerSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { explosionsActions } from "redux/slices/spaceInvaders/explosionsSlice";
import { strongForceBarrierActions } from "redux/slices/spaceInvaders/strongForceBarrierSlice";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
} from "enums/spaceInvaders/flyingObjectEnum";

// utils
import { listenerApiType } from "redux/utils/stateUtils";

const strongForceMiddleware = createListenerMiddleware();

strongForceMiddleware.startListening({
  actionCreator: strongForceBarrierActions.async.spaceshipHit,
  effect: async ({ payload }, listenerApi) => {
    explodeSpaceship(payload.spaceshipPosition, listenerApi);
    listenerApi.dispatch(playerActions.async.hit(payload.spaceship));
    listenerApi.dispatch(strongForceBarrierActions.hit(payload.directions));
  },
});

// private

function explodeSpaceship(
  { x, y }: { x: number; y: number },
  listenerApiType: listenerApiType
) {
  const types = [FlyingObjectType.NonExistent, FlyingObjectType.SpaceShip];
  const subtypes = [
    FlyingObjectSubType.NonExistent,
    FlyingObjectSubType.SpaceShip,
  ];

  listenerApiType.dispatch(
    explosionsActions.async.add({ x, y, types, subtypes })
  );
}

export default strongForceMiddleware;
