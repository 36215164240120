import { ApplicationErrorProps } from "interfaces/error";
export default class BadRequestError extends Error {
  static errorName = "BadRequestError";

  constructor(message: string = "BadRequestError error") {
    super(message);

    this.name = "BadRequestError";
    this.message = message;
  }

  toJSON(): ApplicationErrorProps {
    return {
      data: {
        name: this.name,
        message: this.message,
        type: BadRequestError.errorName,
        status: 0,
        stacktrace: this.stack,
      },
    };
  }
}
