class ZarvoxEntity {
  name: string;
  static defaultName = "Zarvox Sintar";
  static unknownName = "Voz Desconhecida";

  constructor({ name }: { name: string }) {
    this.name = name;
  }

  has(): boolean {
    return !!(this.name && this.name !== ZarvoxEntity.unknownName);
  }
}

export default ZarvoxEntity;
