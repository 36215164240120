// interfaces
import { InvadersInfoProps } from "interfaces/spaceInvaders/invader";

// utils
import { forever } from "async";
import { v4 as uuidv4 } from "uuid";
import spaceInvaderUtils from "utils/spaceInvaders/spaceInvaderUtils";

interface PausedRefProps {
  current: boolean;
}

const DELAY_TURBO = 250;
const DELAY_ANIMATION = 800;

export const InvaderMonsterFactory = (
  container: HTMLElement,
  invadersInfo: InvadersInfoProps,
  pausedRef: PausedRefProps
) => {
  const { index } = spaceInvaderUtils.getEntity(invadersInfo.subtype);

  const wrapper = document.createElement("div");
  const el = document.createElement("div");
  const el2 = document.createElement("div");

  wrapper.id = `${container.id}_${uuidv4()}`;
  wrapper.className = "absolute";

  el.className = `bg_space_invaders_invader_${index}  bg_size_26`;
  el2.className = `bg_space_invaders_invader_${index}_1 bg_size_26`;

  wrapper.appendChild(el);
  wrapper.appendChild(el2);

  animate(container, el, el2, pausedRef);
  invadersInfo.isMad && turbo(container, wrapper, pausedRef);

  return wrapper;
};

export const InvaderContainerFactory = () => {
  const el = document.createElement("div");

  el.id = uuidv4();
  el.className = "space_invaders_invader_container display_none";

  return el;
};

// private

function animate(
  wrapper: HTMLElement,
  el: HTMLElement,
  el2: HTMLElement,
  pausedRef: PausedRefProps
) {
  if (!el) return;
  if (!el2) return;

  el2.style.display = "none";

  const hide = {
    1: false,
    2: true,
  };

  forever(
    (next: () => void) => {
      if (pausedRef.current) return setTimeout(next, DELAY_ANIMATION);
      if (wrapper.classList.contains("container_removed")) return;

      el.style.display = hide[1] ? "none" : "block";
      el2.style.display = hide[2] ? "none" : "block";

      hide[1] = !hide[1];
      hide[2] = !hide[2];

      setTimeout(next, DELAY_ANIMATION);
    },
    (_err: unknown) => {}
  );
}

function turbo(
  wrapper: HTMLElement,
  el: HTMLElement,
  pausedRef: PausedRefProps
) {
  if (!el) return;

  let crazy = false;

  forever(
    (next: () => void) => {
      if (pausedRef.current) return setTimeout(next, DELAY_TURBO);
      if (wrapper.classList.contains("container_removed")) return;

      crazy
        ? el.classList.remove("space_invaders_invader_mad")
        : el.classList.add("space_invaders_invader_mad");

      crazy = !crazy;

      setTimeout(next, DELAY_TURBO);
    },
    (_err: unknown) => {}
  );
}
