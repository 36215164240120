// react
import { useCallback, useRef, useState, useEffect } from "react";

// components
import ButtonPlayPauseToggle from "components/buttons/ButtonPlayPauseToggle";
import ButtonDuolingoGeneric from "components/buttons/ButtonDuolingoGeneric";

// svgs
// import NerdflixSvg from "svg/NerdflixSvg";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  background: #181618;

  .underline {
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .nerdfy_dark {
    color: ${color.grey.strong};
  }

  .logo_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo_brand {
      font-family: "Bebas Neue", sans-serif;
      font-size: 150px;
      color: ${color.grey.middle};
      text-align: center;
    }
  }

  .space_button {
    color: ${color.red};
    border: 12px dashed ${color.red};
    padding: 30px;
    background: transparent;
    text-decoration: none;
    margin: 40px auto;
    display: block;
    text-align: center;
    font-size: 30px;

    &:hover {
      background-color: ${color.yellow};
      border-color: ${color.yellow};
      color: ${color.dark.bg};
    }
  }

  .star_wars_intro {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: ${color.yellow};
    overflow: hidden;
    position: relative;

    /* .intro,
    .flying_object,
    .wrapper_content > .content {
      animation-play-state: paused !important;
    } */

    .nerdflix_presentation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 30px;
      opacity: 0;

      -webkit-animation: nerdflix 4s ease-out;
      -moz-animation: nerdflix 4s ease-out;
      -ms-animation: nerdflix 4s ease-out;
      -o-animation: nerdflix 4s ease-out;
      animation: nerdflix 4s ease-out;

      h1 {
        font-size: 35px;
        margin: 20px;
      }

      h2 {
        font-size: 22px;
        margin: 20px;
        color: ${color.grey.stronger};
      }

      p {
        font-size: 23px;
        text-align: justify;
        margin: 20px 0;
        color: ${color.grey.stronger};
        line-height: 35px;
      }
    }

    .intro {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      padding: 0 25px;
      opacity: 0;
      max-width: 16em;
      font-size: 200%;
      z-index: 1;
      text-align: center;
      color: ${color.grey.strong};
      -webkit-animation: intro 3s ease-out;
      -moz-animation: intro 3s ease-out;
      -ms-animation: intro 3s ease-out;
      -o-animation: intro 3s ease-out;
      animation: intro 3s ease-out;
      animation-delay: 3s;
    }

    .flying_object {
      position: absolute;
      width: 2em;
      left: 50%;
      top: 20vh;
      margin-left: -1.3em;
      opacity: 0;
      z-index: 1;
      -webkit-animation: flyingObject 5s ease-out 2.5s;
      -moz-animation: flyingObject 5s ease-out 2.5s;
      -ms-animation: flyingObject 5s ease-out 2.5s;
      -o-animation: flyingObject 5s ease-out 2.5s;
      animation: flyingObject 5s ease-out 2.5s;
      animation-delay: 6s;
    }

    .wrapper_content {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      bottom: 80px;

      z-index: 3;
      width: 100%;
      font-size: 80px;
      text-align: justify;
      overflow: hidden;
      line-height: 30px;
      height: 50em;
      transform-origin: 50% 100%;
      transform: perspective(350px) rotateX(25deg);

      .content {
        top: 100%;
        position: absolute;
        animation-play-state: running;
        animation: scroll 350s linear 4s forwards;

        p {
          font-size: 30px;
          line-height: 40px;
        }

        > .title {
          color: ${color.purple};
          text-align: center;
          font-size: 32px;

          > span {
            color: ${color.grey.middle};
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      padding: 0 30px 0 0;

      ul {
        all: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        li {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
  }

  @keyframes nerdflix {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes intro {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes flyingObject {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(0.1);
      opacity: 0;
    }
  }

  @keyframes scroll {
    to {
      top: -20000px;
    }
  }

  @media screen and (max-width: 720px) {
    .star_wars_intro .wrapper_content {
      font-size: 35px;
    }

    .star_wars_intro .content {
      position: absolute;
      top: 100%;
      animation: scroll 150s linear 4s forwards;
    }
  }
`;

const DELAY_JUMP_BUTTON = 30000;
const DELAY_JUMP_BUTTON_DEMO = 10000;

interface IntroProps {
  close: () => void;
  demo?: boolean;
}

const Intro = ({ close, demo }: IntroProps) => {
  const wrapperContentRef = useRef<HTMLElement | null>(null);
  const wrapperIntroTextRef = useRef<HTMLElement | null>(null);
  const wrapperFlyingObjectRef = useRef<HTMLElement | null>(null);
  const wrapperIntroNerdflixRef = useRef<HTMLElement | null>(null);
  const [paused, setPaused] = useState(false);
  const [isJumpButtonDisabled, setIsJumpButtonDisabled] = useState(true);
  const handler = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setTimeout(() => {
      wrapperContentRef.current = node;
    });

    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(handleWrappers, []);
  useEffect(handleJumpButton, [demo]);

  function handleWrappers() {
    setTimeout(() => {
      wrapperIntroNerdflixRef.current =
        document.getElementById("introNerdflix");
      wrapperIntroTextRef.current = document.getElementById("introTextWrapper");
      wrapperFlyingObjectRef.current = document.getElementById(
        "introFlyingObjectWrapper"
      );
    });
  }

  function handleJumpButton() {
    setTimeout(
      () => {
        setIsJumpButtonDisabled(false);
      },
      demo ? DELAY_JUMP_BUTTON_DEMO : DELAY_JUMP_BUTTON
    );
  }

  function play() {
    setPaused(false);
    toggleAnimation("running");
  }

  function pause() {
    setPaused(true);
    toggleAnimation("paused");
  }

  function toggleAnimation(state: "running" | "paused") {
    wrapperIntroNerdflixRef.current?.style.setProperty(
      "animation-play-state",
      state
    );
    wrapperIntroTextRef.current?.style.setProperty(
      "animation-play-state",
      state
    );
    wrapperFlyingObjectRef.current?.style.setProperty(
      "animation-play-state",
      state
    );
    wrapperContentRef.current?.style.setProperty("animation-play-state", state);
  }

  function smallBreak() {
    return (
      <>
        <br />
        <br />
        <br />
      </>
    );
  }

  function bigBreak() {
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }

  return (
    <Wrapper
      className={`animate__animated no-swipe animate__bounceInUp pixelify_font_family`}
    >
      <div className="star_wars_intro">
        <div className="nerdflix_presentation" id="introNerdflix">
          <h1 className="nerdflix_color">Nerdflix</h1>
          <h2>apresenta...</h2>
        </div>

        <p className="intro" id="introTextWrapper">
          Em uma dimensão não muito distante...
        </p>

        <div className="flying_object" id="introFlyingObjectWrapper">
          <div className="space_invaders_asteroid bg_asteroid_mineral_silver bg_size_32"></div>
        </div>

        <div className="wrapper_content">
          <div className="content" ref={handler}>
            {bigBreak()}

            <p className="title">
              EPISÓDIO I
              <br />
              <span>A ajuda vem de dimensões superiores</span>
            </p>

            {smallBreak()}

            <p>
              Em um <span className="nerdfy_blue">mundo</span> de apenas{" "}
              <span className="nerdfy_white">duas dimensões</span>...
            </p>

            {smallBreak()}

            <p>
              Formado por <span className="nerdfy_white">pixels</span> e escrito
              em <span className="nerdfy_green">código binário</span>...
            </p>

            {smallBreak()}

            <p>
              Existia um reino <span className="nerdfy_white">próspero</span> e{" "}
              <span className="nerdfy_white">inovador</span>...
            </p>

            {smallBreak()}

            <p>
              Seus <span className="nerdfy_blue">habitantes</span>, os{" "}
              <span className="nerdfy_purple">nerds</span>, viviam em relativa{" "}
              <span className="nerdfy_white">paz</span> e{" "}
              <span className="nerdfy_white">harmonia</span>...
            </p>

            {bigBreak()}

            <p>
              <span className="nerdfy_orange">No entanto</span>...
            </p>

            {bigBreak()}

            <p>
              Essa <span className="nerdfy_blue">era</span> de{" "}
              <span className="nerdfy_white">paz</span> e{" "}
              <span className="nerdfy_white">harmonia</span>...
            </p>

            {smallBreak()}

            <p>
              Deu lugar a <span className="nerdfy_red">tempos sombrios</span>...
            </p>

            {bigBreak()}

            <p>
              <span className="nerdfy_orange">A partir</span> de um{" "}
              <span className="nerdfy_white">ÚNICO PIXEL</span>
              ...
            </p>

            {bigBreak()}

            <p>
              Um <span className="nerdfy_orange">misterioso VÍRUS</span>...
            </p>

            {smallBreak()}

            <p>
              Apelidado pelos <span className="nerdfy_purple">nerds</span> de{" "}
              <span className="nerdfy_white">"</span>
              <span className="nerdfy_red">Bit Sombrio</span>
              <span className="nerdfy_white">"</span>...
            </p>

            {smallBreak()}

            <p>
              <span className="nerdfy_red">Começou</span> a se{" "}
              <span className="nerdfy_orange">ESPALHAR</span>,{" "}
              <span className="nerdfy_orange">INFECTAR</span> e{" "}
              <span className="nerdfy_orange">CORROMPER</span> cada{" "}
              <span className="nerdfy_white">PIXEL</span> que encontrava pela
              frente...
            </p>

            {bigBreak()}

            <p>
              Após <span className="nerdfy_white">INÚMERAS</span> tentativas{" "}
              <span className="nerdfy_orange">fracassadas</span> de{" "}
              <span className="nerdfy_blue">conter</span> o{" "}
              <span className="nerdfy_red">vírus</span>...
            </p>

            {bigBreak()}

            <p>
              O <span className="nerdfy_red">medo</span> e a{" "}
              <span className="nerdfy_red">apatia</span> dão lugar ao que antes
              era <span className="nerdfy_blue">paz</span> e{" "}
              <span className="nerdfy_blue">harmonia</span>...
            </p>

            {bigBreak()}

            <p>
              Uma nova realidade <span className="nerdfy_orange">dura</span> e{" "}
              <span className="nerdfy_orange">sombria</span> se{" "}
              <span className="nerdfy_red">impôs</span>...
            </p>

            {smallBreak()}

            <p>
              E os <span className="nerdfy_purple">nerds</span>{" "}
              <span className="nerdfy_orange">começaram</span> a{" "}
              <span className="nerdfy_red">duvidar</span> de sua{" "}
              <span className="nerdfy_white">própria capacidade</span>...
            </p>

            {bigBreak()}

            <p>
              Como um <span className="nerdfy_blue">farol</span> na{" "}
              <span className="nerdfy_red">escuridão</span>...
            </p>

            {smallBreak()}

            <p>
              Um <span className="nerdfy_blue">último suspiro</span>...
            </p>

            {bigBreak()}

            <p>
              Um <span className="nerdfy_purple">brilhante EXPLORADOR</span>,
              conhecido como "<span className="nerdfy_white">Astrogildo</span>
              "...
            </p>

            {smallBreak()}

            <p>
              <span className="nerdfy_green">EMERGIU</span> como uma figura de{" "}
              <span className="nerdfy_blue">ESPERANÇA</span>...
            </p>

            {smallBreak()}

            <p>
              Sua <span className="nerdfy_blue">missão</span> não era simples...
            </p>

            {smallBreak()}

            <p>
              <span className="nerdfy_purple">Astrogildo</span> deveria{" "}
              <span className="nerdfy_blue">ENCONTRAR</span> e{" "}
              <span className="nerdfy_blue">NEUTRALIZAR</span> a fonte, isso é,
              o <span className="nerdfy_white">primeiro pixel</span>...
            </p>

            {smallBreak()}

            <p>
              O pixel <span className="nerdfy_orange">HOSPEDEIRO ZERO</span> do{" "}
              <span className="nerdfy_red">bit sombrio</span>...
            </p>

            {bigBreak()}

            <p>
              E assim, partiu <span className="nerdfy_purple">Astrogildo</span>
              ...
            </p>

            {bigBreak()}

            <p>
              <span className="nerdfy_white">Sozinho</span> em sua{" "}
              <span className="nerdfy_blue">HEROICA Jornada</span>...
            </p>

            {bigBreak()}

            <p>
              <span className="nerdfy_orange">Mas</span>...
            </p>

            {bigBreak()}

            <p>
              <span className="nerdfy_red">Infelizmente</span>...
            </p>

            {bigBreak()}

            <p>
              Por <span className="nerdfy_red">algum motivo</span> ainda{" "}
              <span className="nerdfy_orange">desconhecido</span>...
            </p>

            {bigBreak()}

            <p>
              O <span className="nerdfy_white">CONTATO</span> com{" "}
              <span className="nerdfy_purple">Astrogildo</span> foi{" "}
              <span className="nerdfy_red">perdido</span>...
            </p>

            {bigBreak()}

            <p>
              Assim como a <span className="nerdfy_blue">última esperança</span>{" "}
              dos <span className="nerdfy_purple">nerds</span>...
            </p>

            {bigBreak()}

            <p>
              <span className="nerdfy_white">Contrariando</span> o próprio{" "}
              <span className="nerdfy_blue">princípio de não intervenção</span>
              ...
            </p>

            {bigBreak()}

            <p>
              Um <span className="nerdfy_purple">Ser</span>, de{" "}
              <span className="nerdfy_blue">dimensão superior</span> e{" "}
              <span className="nerdfy_white">divina</span>...
            </p>

            {smallBreak()}

            <p>
              Apelidado pelos <span className="nerdfy_white">nerds</span> de "
              <span className="nerdfy_purple">O Todo Poderoso</span>"...
            </p>

            {bigBreak()}

            <p>
              Decidiu <span className="nerdfy_blue">intervir</span>...
            </p>

            {bigBreak()}

            <p>
              E com a <span className="nerdfy_green">ajuda</span> da{" "}
              <span className="underline">Voz Desconhecida</span>...
            </p>

            {smallBreak()}

            <p>
              Começou a recrutar <span className="nerdfy_green">primatas</span>{" "}
              da <span className="nerdfy_blue">terceira dimensão</span> com o
              intuito de <span className="nerdfy_white">reverter</span> a
              situação...
            </p>

            {bigBreak()}

            <p>E assim...</p>

            {bigBreak()}

            <p>
              Com a companhia <span className="nerdfy_white">divina</span> do{" "}
              <span className="nerdfy_purple">Todo Poderoso</span> e da{" "}
              <span className="underline">Voz Desconhecida</span>...
            </p>

            {smallBreak()}

            <p>
              Sua Jornada <span className="nerdfy_blue">inicia</span>...
            </p>

            {smallBreak()}

            <p>
              Em <span className="nerdfy_white">DEFESA</span> dos{" "}
              <span className="nerdfy_purple">nerds</span> e do{" "}
              <span className="nerdfy_purple">reino</span>...
            </p>

            {bigBreak()}

            <div className="logo_wrapper">
              <h1 className="logo_brand bg_nerdfy_gradient_mono">NERDFY</h1>
            </div>

            {bigBreak()}

            <button
              onClick={close}
              className="space_button blink pixelify_font_family"
            >
              APERTE PARA COMEÇAR
            </button>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <p>
              Assine a <span className="nerdflix_color">nerdflix</span> e tenha
              acesso a <span className="nerdfy_blue">conteúdos exclusivos</span>
              .
            </p>
          </div>
        </div>

        <div className="footer">
          <ul>
            <li>
              <ButtonPlayPauseToggle
                play={play}
                pause={pause}
                paused={paused}
              />
            </li>
            <li>
              <ButtonDuolingoGeneric
                label="Pular"
                onClick={close}
                disabled={isJumpButtonDisabled}
              />
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

/* h1.nerdflix {
  font-size: 35px;
  color: #DF0912;
  margin: 20px;
} */

/* h1.nerdsoft {
  font-size: 35px;
  color: #868686;
  margin: 20px;
} */

/* h1.nerdtel {
  font-size: 35px;
  color: #03c7fd;
  margin: 20px;
} */

/* h1.nerdbook {
  font-size: 35px;
  color: #3c5a98;
  margin: 20px;
} */

/* h1.nerdX {
  font-size: 35px;
  color: #015288;
  margin: 20px;
} */

export default Intro;
