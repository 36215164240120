import { memo } from "react";

interface AvatarErgoProps {
  avatar: number;
  customClassName?: string;
  onAnimationEnd?: () => void;
}

const AvatarErgo = ({
  avatar,
  onAnimationEnd,
  customClassName = "",
}: AvatarErgoProps) => {
  return (
    <div
      className={`nerdfy_avatar ergo_${avatar} ${customClassName}`}
      onAnimationEnd={onAnimationEnd}
    ></div>
  );
};

export default memo(AvatarErgo);
