interface SvgProps {
  width?: string;
  height?: string;
}

const OreSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 256 256"
    >
      <g>
        <path
          fill="#808285"
          d="M236.681 138.172 234.3 187.79a22.228 22.228 0 0 1-5.857 14.039 22.215 22.215 0 0 1-9.412 6.114l-.212.067-75.427 24.233a2.873 2.873 0 0 1-.302.089 22.171 22.171 0 0 1-7.366.961c-.123 0-.246 0-.369-.011l-77.182-4.169-.738-.045a22.14 22.14 0 0 1-7.981-2.247 22.267 22.267 0 0 1-9.836-9.59L31.1 201.113a22.27 22.27 0 0 1-2.146-14.788 22.158 22.158 0 0 1 3.868-8.841l26.525-35.825 5.913-7.981-1.967-9.736-2.984-14.833c-2.28-11.245 4.337-22.355 15.246-25.754l17.884-5.198 8.741-2.537 4.482-7.925 6.729-11.904 8.294-14.643c2.114-3.733 5.322-6.785 9.119-8.774 3.135-1.641 6.787-2.572 10.33-2.572h33.779c4.806 0 9.345 1.543 13.056 4.214a22.37 22.37 0 0 1 8.361 11.748l8.797 29.822.76 2.582 1.353 2.325L211.017 87l6.215 10.697 4.37 7.523 12.072 20.768a22.139 22.139 0 0 1 2.459 6.26 21.95 21.95 0 0 1 .548 5.924z"
          opacity="1"
          data-original="#808285"
        ></path>
        <path
          fill="#58595b"
          d="M228.444 201.829a22.215 22.215 0 0 1-9.412 6.114l-.212.067-75.729 24.323a22.197 22.197 0 0 1-7.366.961l-77.551-4.18-.738-.045a22.159 22.159 0 0 1-7.981-2.246l109.061-44.689z"
          opacity="1"
          data-original="#58595b"
        ></path>
        <path
          fill="#393939"
          d="m236.682 138.172-2.381 49.618a22.226 22.226 0 0 1-5.857 14.039l-69.928-19.695 77.618-49.886a21.95 21.95 0 0 1 .548 5.924z"
          opacity="1"
          data-original="#414042"
        ></path>
        <path
          fill="#58595b"
          d="m236.134 132.248-77.618 49.886-33.231-47.148 80.603-56.816 1.353 2.325 26.435 45.493a22.166 22.166 0 0 1 2.458 6.26z"
          opacity="1"
          data-original="#58595b"
        ></path>
        <path
          fill="#939598"
          d="m164.64 60.942 19.886-22.951c1.128-1.302 2.431-2.564 3.437-3.966l.005-.007a22.33 22.33 0 0 0-13.055-4.213h-33.779c-3.543 0-7.195.93-10.33 2.572-3.797 1.988-7.005 5.041-9.119 8.774l-8.294 14.643-6.729 11.905-4.482 7.925 25.888 27.531.035-.041 12.465-14.386c8.023-9.264 16.048-18.525 24.072-27.786z"
          opacity="1"
          data-original="#939598"
        ></path>
        <path
          fill="#58595b"
          d="m128.067 103.152-62.808 30.526-1.967-9.736-2.984-14.833c-2.28-11.245 4.337-22.355 15.246-25.754l17.884-5.198 8.741-2.537z"
          opacity="1"
          data-original="#58595b"
        ></path>
        <path
          fill="#939598"
          d="m125.284 134.986-1.576.838-94.754 50.501a22.158 22.158 0 0 1 3.868-8.841l26.525-35.825 5.913-7.981 59.656 1.297z"
          opacity="1"
          data-original="#939598"
        ></path>
        <path
          fill="#58595b"
          d="m205.887 78.17-77.819 24.982 59.901-69.134a22.37 22.37 0 0 1 8.361 11.748l8.797 29.822z"
          opacity="1"
          data-original="#58595b"
        ></path>
        <path
          fill="#ffa91a"
          d="M118.911 24.675a34.049 34.049 0 0 1-16.682 16.682c-2.649 1.218-2.649 4.948 0 6.165a34.049 34.049 0 0 1 16.682 16.682c1.218 2.649 4.948 2.649 6.165 0a34.049 34.049 0 0 1 16.682-16.682c2.649-1.218 2.649-4.948 0-6.165a34.049 34.049 0 0 1-16.682-16.682c-1.218-2.649-4.948-2.649-6.165 0z"
          opacity="1"
          data-original="#ffa91a"
        ></path>
        <path
          fill="#f79219"
          d="M141.758 41.357c-2.252-1.035-4.351-2.335-6.305-3.817a3.314 3.314 0 0 1-1.757 1.921 34.049 34.049 0 0 0-16.682 16.682 3.317 3.317 0 0 1-1.921 1.757c1.482 1.954 2.782 4.053 3.817 6.305 1.218 2.649 4.948 2.649 6.165 0a34.049 34.049 0 0 1 16.682-16.682c2.65-1.218 2.65-4.948.001-6.166z"
          opacity="1"
          data-original="#f79219"
        ></path>
        <path
          fill="#ffc91d"
          d="M216.441 79.547a23.6 23.6 0 0 1-11.565 11.565c-1.836.844-1.836 3.43 0 4.274a23.6 23.6 0 0 1 11.565 11.565c.844 1.836 3.43 1.836 4.274 0a23.6 23.6 0 0 1 11.565-11.565c1.836-.844 1.836-3.43 0-4.274a23.6 23.6 0 0 1-11.565-11.565c-.844-1.836-3.43-1.836-4.274 0z"
          opacity="1"
          data-original="#ffc91d"
        ></path>
        <path
          fill="#ffa91a"
          d="M232.28 91.112a23.525 23.525 0 0 1-4.371-2.646 2.298 2.298 0 0 1-1.218 1.332 23.6 23.6 0 0 0-11.565 11.565 2.298 2.298 0 0 1-1.332 1.218 23.406 23.406 0 0 1 2.646 4.371c.844 1.836 3.43 1.836 4.274 0a23.6 23.6 0 0 1 11.565-11.565c1.837-.845 1.837-3.431.001-4.275zM32.239 117.856a23.6 23.6 0 0 1-11.565 11.565c-1.836.844-1.836 3.43 0 4.274a23.6 23.6 0 0 1 11.565 11.565c.844 1.836 3.43 1.836 4.274 0a23.6 23.6 0 0 1 11.565-11.565c1.836-.844 1.836-3.43 0-4.274a23.6 23.6 0 0 1-11.565-11.565c-.844-1.836-3.43-1.836-4.274 0z"
          opacity="1"
          data-original="#ffa91a"
        ></path>
        <path
          fill="#f79219"
          d="M48.078 129.42a23.525 23.525 0 0 1-4.371-2.646 2.298 2.298 0 0 1-1.218 1.332 23.6 23.6 0 0 0-11.565 11.565 2.298 2.298 0 0 1-1.332 1.218 23.406 23.406 0 0 1 2.646 4.371c.844 1.836 3.43 1.836 4.274 0a23.6 23.6 0 0 1 11.565-11.565c1.837-.844 1.837-3.43.001-4.275z"
          opacity="1"
          data-original="#f79219"
        ></path>
        <path
          fill="#ffc91d"
          d="M159.472 98.509a23.6 23.6 0 0 1-11.565 11.565c-1.836.844-1.836 3.43 0 4.274a23.6 23.6 0 0 1 11.565 11.565c.844 1.836 3.43 1.836 4.274 0a23.6 23.6 0 0 1 11.565-11.565c1.836-.844 1.836-3.43 0-4.274a23.6 23.6 0 0 1-11.565-11.565c-.844-1.837-3.43-1.837-4.274 0z"
          opacity="1"
          data-original="#ffc91d"
        ></path>
        <path
          fill="#ffa91a"
          d="M175.311 110.073a23.525 23.525 0 0 1-4.371-2.646 2.298 2.298 0 0 1-1.218 1.332 23.6 23.6 0 0 0-11.565 11.565 2.298 2.298 0 0 1-1.332 1.218 23.406 23.406 0 0 1 2.646 4.371c.844 1.836 3.43 1.836 4.274 0a23.6 23.6 0 0 1 11.565-11.565c1.837-.844 1.837-3.43.001-4.275zM195.874 121.279a39.224 39.224 0 0 1-19.219 19.219c-3.052 1.403-3.052 5.7 0 7.103a39.22 39.22 0 0 1 19.219 19.219c1.403 3.052 5.7 3.052 7.103 0a39.224 39.224 0 0 1 19.219-19.219c3.052-1.403 3.052-5.7 0-7.103a39.224 39.224 0 0 1-19.219-19.219c-1.402-3.052-5.7-3.052-7.103 0z"
          opacity="1"
          data-original="#ffa91a"
        ></path>
        <path
          fill="#f79219"
          d="M222.197 140.498c-2.595-1.193-5.013-2.691-7.264-4.398a3.82 3.82 0 0 1-2.024 2.213 39.224 39.224 0 0 0-19.219 19.219 3.82 3.82 0 0 1-2.213 2.024c1.707 2.251 3.205 4.669 4.398 7.264 1.403 3.052 5.7 3.052 7.103 0a39.224 39.224 0 0 1 19.219-19.219c3.051-1.403 3.051-5.7 0-7.103z"
          opacity="1"
          data-original="#f79219"
        ></path>
        <path
          fill="#808285"
          d="M66.88 109.78c-.95 0-1.77-.671-1.96-1.591v-.01c-.22-1.08.48-2.14 1.56-2.36 1.07-.229 2.15.5 2.36 1.561.11.52 0 1.06-.29 1.5-.3.45-.75.75-1.27.86-.13.02-.27.04-.4.04zm.43-7.91c-.21 0-.41-.03-.61-.09a2.02 2.02 0 0 1-1.3-2.521c1.75-5.41 6.04-9.67 11.49-11.39l.05-.011 17.81-5.18c1.04-.3 2.18.33 2.48 1.36.31 1.06-.3 2.17-1.36 2.479L78.1 91.68v.01a13.632 13.632 0 0 0-8.89 8.801v.01a1.992 1.992 0 0 1-1.9 1.369z"
          opacity="1"
          data-original="#808285"
        ></path>
      </g>
    </svg>
  );
};

export default OreSvg;
