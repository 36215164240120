// redux
import { appActions } from "redux/slices/appSlice";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { BulletProps } from "interfaces/spaceInvaders/bullet";

const fire = createAction(
  "@async/bullet/fire",
  (payload: { x: number; y: number }) => ({
    payload,
  })
);

const initialState: BulletProps[] = [];

export const bulletsSlice = createSlice({
  name: "bullets",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<BulletProps>) => {
      return [...state, action.payload];
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const bulletsActions = {
  async: {
    fire,
  },
  ...bulletsSlice.actions,
};

export default bulletsSlice.reducer;
