// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { classRoomActions } from "redux/slices/classRoomSlice";

// entities
import ClassRoomEntity from "entities/ClassRoomEntity";

// utils
import stateUtils from "redux/utils/stateUtils";

const classRoomMiddleware = createListenerMiddleware();

classRoomMiddleware.startListening({
  actionCreator: classRoomActions.async.syncFlowParcialDone,
  effect: async (_action, listenerApi) => {
    const { challenges } = stateUtils.get(listenerApi);
    const classRoomEntity = new ClassRoomEntity({ challenges: challenges.all });
    const flowParcialDone = classRoomEntity.isFlowParcialDone();

    listenerApi.dispatch(classRoomActions.update({ flowParcialDone }));
  },
});

export default classRoomMiddleware;
