// react
import { useState, useEffect } from "react";

// components
import PlayerLife from "components/PlayerLife";
import ProgressStatus from "components/ProgressStatus";
import AvatarIconSpaceShip from "components/avatarIcons/AvatarIconSpaceShip";

// entities
import PlayerHpEntity from "entities/PlayerHpEntity";

// styles
import { AvatarProgressStyle } from "components/styles/AvatarStatusStyle";

interface SpaceshipHealthStatusProps {
  hp: number;
  life: number;
  icon?: boolean;
  loseLifeAnimation?: boolean;
  heartBeatAnimation?: boolean;
  onHeartBeatAnimationEnd?: () => void;
}

const SpaceshipHealthStatus = ({
  hp,
  life,
  icon,
  loseLifeAnimation = false,
  heartBeatAnimation = false,
  onHeartBeatAnimationEnd,
}: SpaceshipHealthStatusProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(handleProgress, [hp]);

  function handleProgress() {
    setTimeout(() => setProgress((hp || 0) / PlayerHpEntity.hpTotal));
  }

  return (
    <AvatarProgressStyle className="pixelify_font_family">
      {icon && (
        <li>
          <AvatarIconSpaceShip />
        </li>
      )}

      <li>
        <div className="separator"></div>
      </li>

      <li>
        <ProgressStatus progress={progress} />
      </li>

      <li>
        <div className="separator">|</div>
      </li>

      <li>
        <PlayerLife
          life={life}
          loseLifeAnimation={loseLifeAnimation}
          heartBeatAnimation={heartBeatAnimation}
          onHeartBeatAnimationEnd={onHeartBeatAnimationEnd}
        />
      </li>
    </AvatarProgressStyle>
  );
};

export default SpaceshipHealthStatus;
