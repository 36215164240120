export type peopleRangeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

const people = {
  nerds: {
    1: ["Agora sim me sinto seguro!", "Pronto para ir para casa!", "Uhuuull!!"],
    2: [
      "Agora é possível visitar a minha amada!",
      "Minha amada me espera do outro lado dessa via!",
      "O Todo-Poderoso é o meu pastor e nada me faltará!",
    ],
    3: [
      "Demorou eim?",
      "Finalmente!",
      "Não via a hora de poder utilizar essa via!",
      "Nada como poder viajar e curtir um solzinho numa Galáxia distante",
    ],
    4: [
      "Obrigado por me ajudar!",
      "Obrigado!",
      "Obrigado mesmo!",
      "Que O Todo-Poderoso te abençoe!",
      "O Todo-Poderoso vai te pagar em dobro!",
    ],
    5: [
      "Graças ao Todo-Poderoso posso transportar minhas muambas agora",
      "Todo-Poderoso seja louvado!",
      "Agora posso transportar minhas muambas!",
      "Todo-Poderoso é o caminho, a verdade e a vida",
      "O Todo-Poderoso é luz para os meus pés e luz para o meu caminho",
      "O Todo-Poderoso é o meu pastor e nada me faltará!",
      "O Todo-Poderoso é o meu escudo e fortaleza!",
      "O Todo-Poderoso é o meu ajudador!",
      "O Todo-Poderoso é o meu socorro bem presente na angústia",
      "O Todo-Poderoso é o meu socorro!",
      "As minha muambas estão salvas!",
      "O Todo-Poderoso salvou minhas muambas!",
      "Minhas muambas agora podem ser transportadas de forma segura!",
    ],
    6: [
      "Será que agora eu posso passar por essa via?",
      "Essa via está realmente segura?",
      "Essa via está realmente liberada?",
    ],
    7: [
      "Até que enfim é possível passar por essa via, só faltou o asfalto!",
      "Fez nada mais que a obrigação.",
      "Minha avó faria melhor!",
      "Bando de incompetentes!",
      "Porque não liberaram antes?",
    ],
    8: [
      "Oi??",
      "O que está acontecendo?",
      "Será que agora eu posso dormir em paz?",
      "Graças ao Todo-Poderoso vou conseguir dormir tranquilo agora!",
    ],
    9: ["Até que enfim! O Todo-Poderoso é Pai", "Porque demorou tanto?"],
  },
  ergo: {
    1: [
      "𐋃𐌇𐌔𐌋𐌆𐌃𐌋",
      "𐌐𐌋𐌈 𐌓𐌃𐌋𐌔𐌅 𐌃𐌇",
      "𐌐𐌋𐌈𐌓 𐌃𐌋𐌔",
      "𐌄𐌋𐌆𐌄𐌏𐌅𐌄𐌍𐌃𐌋𐌐𐌋 𐌋𐌐𐌋𐌈𐌓𐌃𐌋𐌔",
      "𐌄𐌋 𐌄𐌏𐌅𐌄𐌍 𐌃𐌋𐌐",
    ],
    2: [
      "𐌃𐌋𐌐𐌋𐌈𐌓𐌃𐌋𐌔𐌅𐌃𐌇𐌁𐌋𐌆𐌃𐌇 𐌅𐌃𐌇𐌁𐌋𐌆𐌃𐌇",
      "𐌃𐌋𐌔𐌅𐌃",
      "𐌋𐌐𐌋𐌈𐌓𐌃 𐌋𐌔𐌅𐌃𐌇𐌁𐌋𐌆𐌃𐌇",
      "𐌅𐌄𐌍 𐌃𐌋𐌐𐌋𐌈𐌓𐌃 𐌋𐌔𐌅𐌃𐌇",
    ],
    3: [
      "𐌃𐌇𐌁𐌋𐌆𐌃𐌇𐌄𐌋𐌆𐌄 𐌏𐌅𐌄𐌍𐌃",
      "𐌏𐌅𐌄𐌍𐌃 𐌋𐌐𐌋𐌈𐌓𐌃𐌋𐌔𐌅𐌃𐌇 𐌁𐌋𐌆𐌃𐌇𐌄𐌋 𐌆𐌄𐌏𐌅",
      "𐌋𐌆𐌄𐌏𐌅",
      "𐌃𐌇𐌄𐌋 𐌆𐌄𐌏𐌅𐌄𐌍𐌃𐌋 𐌐𐌋𐌈𐌓𐌃 𐌋𐌔𐌅𐌃",
    ],
    4: [
      "𐌋𐌔𐌅𐌃𐌇𐌁𐌋 𐌆𐌃𐌇𐌄𐌋𐌆𐌄 𐌏𐌅𐌄𐌍𐌃𐌋 𐌐𐌋𐌈𐌓𐌃𐌋𐌔𐌅𐌃𐌇𐌁𐌋𐌆𐌃𐌇𐌄𐌋𐌆𐌄𐌏",
      "𐌇𐌄𐌋𐌆𐌄𐌏",
      "𐌋𐌆𐌃𐌇𐌄𐌋𐌆𐌄𐌏 𐌃𐌇𐌁 𐌋𐌆𐌃𐌇𐌄𐌋 𐌆𐌄𐌏𐌅",
    ],
    5: [
      "𐌄𐌍𐌃𐌋𐌐 𐌋𐌈𐌓𐌃𐌋 𐌋𐌈𐌓𐌃𐌋𐌔𐌅𐌃𐌇𐌁",
      "𐌍𐌃𐌋𐌐𐌋𐌈𐌓𐌃 𐌇𐌄𐌋𐌆𐌄𐌏𐌅",
      "𐌃𐌇𐌄𐌋𐌆𐌄𐌏𐌅𐌄𐌍𐌃𐌋𐌐𐌋 𐌃𐌋𐌔𐌅",
      "𐌃𐌇𐌄𐌋 𐌆𐌄𐌏𐌅𐌄𐌍 𐌃𐌋𐌐𐌋𐌈𐌓𐌃𐌋 𐌔𐌅𐌃𐌇",
    ],
    6: [
      "𐌃𐌋𐌔𐌅𐌃𐌇𐌁𐌋 𐌄𐌍𐌃𐌋𐌐𐌋𐌈𐌓𐌃𐌋𐌔𐌅",
      "𐌏𐌅𐌄𐌍𐌃𐌋𐌐𐌋𐌈",
      "𐌃𐌋𐌔𐌅𐌃𐌇𐌁𐌋𐌆𐌃𐌇𐌄𐌋",
      "𐌋𐌆𐌃𐌇𐌄𐌋 𐌃𐌋𐌐𐌋𐌈𐌓𐌃𐌋",
    ],
    7: [
      "𐌃𐌇𐌁𐌋𐌆𐌃 𐌓𐌃𐌋𐌔𐌅𐌃𐌇",
      "𐌆𐌃𐌇𐌄 𐌋𐌆𐌄𐌏𐌅",
      "𐌈𐌓 𐌃𐌋𐌔𐌅 𐌃𐌇𐌁",
      "𐌄𐌍𐌃𐌋𐌐𐌋𐌈𐌓 𐌋𐌔𐌅𐌃𐌇𐌁𐌋𐌆𐌃𐌇𐌄𐌋𐌆𐌄",
    ],
    8: [
      "𐌋𐌐𐌋𐌈𐌓𐌃𐌋𐌔𐌅𐌃 𐌇𐌁𐌋𐌆𐌃 𐌇𐌄𐌋𐌆𐌄𐌏 𐌁𐌋𐌆𐌃𐌇𐌄𐌋𐌆𐌄𐌏𐌅",
      "𐌁𐌋𐌆𐌃𐌇",
      "𐌅𐌄𐌍𐌃𐌋𐌐𐌋𐌈𐌓 𐌆𐌃𐌇𐌄𐌋𐌆𐌄𐌏𐌅𐌄𐌍𐌃𐌋",
    ],
    9: [
      "𐌆𐌄𐌏𐌅𐌄𐌍𐌃 𐌏𐌅𐌄𐌍𐌃𐌋𐌐𐌋𐌈𐌓 𐌃𐌋𐌔𐌅𐌃𐌇𐌁𐌋𐌆𐌃𐌇 𐌄𐌋𐌆𐌄𐌏 𐌁𐌋𐌆𐌃𐌇𐌄𐌋𐌆𐌄𐌏𐌅𐌄𐌍𐌃",
      "𐌆𐌃𐌇𐌄𐌋𐌆𐌄𐌏𐌅𐌄",
      "𐌃𐌇𐌁𐌋 𐌆𐌃𐌇𐌄𐌋𐌆 𐌄𐌏",
    ],
  },
};

export default people;
