// react
import { useEffect } from "react";

// components
import { NotifyFactoryType } from "factories/NotifyFactory";

// interfaces
import { PlayerDegreeValueProps } from "constants/player";
import { PlayerProps } from "interfaces/player";

interface PlayerDegreeHandlerProps {
  player: PlayerProps;
  challengeDone: boolean;
  lastPlayerDegreeRef: React.MutableRefObject<
    PlayerDegreeValueProps | undefined
  >;
  notify(type: NotifyFactoryType, content: string): void;
  maxCheckpointRef: React.MutableRefObject<number>;
}

const PlayerNotifierLightSideDegreeHandler = ({
  player,
  notify,
  challengeDone,
  maxCheckpointRef,
  lastPlayerDegreeRef,
}: PlayerDegreeHandlerProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [challengeDone, player.degree, player.score]);

  function listen() {
    const current = player.degree;
    const last = lastPlayerDegreeRef.current;

    if (!last) return;
    if (!current) return;
    if (!current.lightSide) return;
    if (last.checkpoint === current.checkpoint) return;

    if (current.checkpoint > last.checkpoint) {
      if (last.lightSide) {
        if (
          maxCheckpointRef.current &&
          current.checkpoint >= maxCheckpointRef.current
        )
          regain(current.name);
        else gain(current.name);
      } else gainFromDarkSide(current.name);
    } else {
      if (last.lightSide && !current.lightSide) lostToDakSide(current.name);
      else lost(last.name);
    }

    updateLastPlayerRefValues();
    updateMaxCheckpointRefValues();
  }

  function gain(degree: string) {
    const content = `Você CONQUISTOU a fama "${degree}"`;
    notify(NotifyFactoryType.Success, content);
  }

  function regain(degree: string) {
    const content = `Você RECONQUISTOU a fama "${degree}"`;
    notify(NotifyFactoryType.Success, content);
  }

  function gainFromDarkSide(degree: string) {
    const content = `No seu caminho de virtude... Você RECONQUISTOU a fama "${degree}"`;
    notify(NotifyFactoryType.Success, content);
  }

  function lost(degree: string) {
    const content = `Você PERDEU a fama "${degree}"`;
    notify(NotifyFactoryType.Danger, content);
  }

  function lostToDakSide(degree: string) {
    const content = `No seu caminho de degeneração... Você PERDEU a fama "${degree}"`;
    notify(NotifyFactoryType.Danger, content);
  }

  function updateLastPlayerRefValues() {
    lastPlayerDegreeRef.current = player.degree;
  }

  function updateMaxCheckpointRefValues() {
    const { checkpoint } = player.degree;

    if (maxCheckpointRef.current >= checkpoint) return;
    maxCheckpointRef.current = checkpoint;
  }

  return null;
};

export default PlayerNotifierLightSideDegreeHandler;
