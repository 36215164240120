// react
import { useEffect, useState } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// entities
import SpaceshipMineralResourcesEntity, {
  SpaceshipMineralResourcesCounterProps,
} from "entities/spaceInvaders/spaceshipMineralResources/SpaceshipMineralResourcesEntity";

// interfaces
import { SpaceshipResourcesProps } from "interfaces/spaceInvaders/spaceshipResources";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";
import { SpaceshipResourceType } from "enums/spaceInvaders/spaceshipEnum";

// components
import OreSvg from "svg/OreSvg";
import WaterSvg from "svg/WaterSvg";
import StrongForceSvg from "svg/StrongForceSvg";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  opacity: 0.4;

  ul.nav_resources {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: 40px;

    li {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: ${color.grey.strong};
      padding: 0 10px;

      span {
        &.resource_value {
          margin-right: 10px;
        }

        &.resource {
          margin-top: 3px;
        }
      }
    }
  }
`;

const SPACESHIP_RESOURCES = SPACE_INVADERS.spaceshipResources;

const SpaceshipResourcesStatusBar = () => {
  const spaceshipResources = useSelector(s.spaceshipResources());
  const spaceshipCounter = useSelector(s.spaceshipItemsCounter());
  const [mineralStorageCapacity, setMineralStorageCapacity] = useState(0);

  useEffect(handleMineralStorageCapacity, [spaceshipCounter]);

  function handleMineralStorageCapacity() {
    if (!spaceshipCounter) return;
    if (!spaceshipCounter[SpaceshipItemEnum.MineralStorage]) return;
    if (!spaceshipCounter[SpaceshipItemEnum.MineralStorage].capacity) return;

    setMineralStorageCapacity(
      spaceshipCounter[SpaceshipItemEnum.MineralStorage].capacity
    );
  }

  function sumRawResources(): number {
    return countTotalResources(spaceshipResources).raw;
  }

  function sumRawShiftingResources(): number {
    return countTotalResources(spaceshipResources).shifting;
  }

  function sumTransformedResources(): number {
    return countTotalResources(spaceshipResources).transformed;
  }

  function countTotalResources(
    resource: SpaceshipResourcesProps
  ): SpaceshipMineralResourcesCounterProps {
    const entity = new SpaceshipMineralResourcesEntity(resource);
    return entity.count;
  }

  return (
    <Wrapper>
      <ul className="nav_resources">
        <li>
          <span className="resource_value">
            {sumRawResources()} + {sumRawShiftingResources()} +{" "}
            {sumTransformedResources()} / {mineralStorageCapacity}
          </span>{" "}
          <span className="resource">
            <OreSvg />
          </span>
        </li>
        <li>
          <span className="resource_value">
            {spaceshipResources.water.transformed} <span>/</span>{" "}
            {SPACESHIP_RESOURCES[SpaceshipResourceType.Water].max}
          </span>{" "}
          <span className="resource">
            <WaterSvg height="14" width="14" />
          </span>
        </li>
        <li>
          <span className="resource_value">
            {spaceshipResources.strongForce.transformed} <span>/</span>{" "}
            {
              SPACE_INVADERS.spaceshipResources[
                SpaceshipResourceType.StrongForce
              ].max
            }
          </span>{" "}
          <span className="resource">
            <StrongForceSvg height="15" width="15" />
          </span>
        </li>
      </ul>
    </Wrapper>
  );
};

export default SpaceshipResourcesStatusBar;
