// redux
import { useEffect } from "react";
import { collection } from "firebase/firestore";
import { classRoomActions } from "redux/slices/classRoomSlice";
import { useCollection } from "react-firebase-hooks/firestore";

// redux
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { challengesActions } from "redux/slices/challengesSlice";

// interfaces
import { ChallengeQuizFirebaseProps } from "interfaces/challengeQuizFirebase";

// components
import { hasPendingWritesDocType } from "firebaseSync/FirebaseSync";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// parsers
import challengeQuizFirebaseParser from "parsers/challengeQuizFirebaseParser";

interface ChallengeQuizzesFirebaseSyncProps {
  classRoomId: number;
  userId: number | string;
  isCachedData: (docs: hasPendingWritesDocType) => boolean;
}

const ChallengeQuizzesFirebaseSync = ({
  userId,
  classRoomId,
  isCachedData,
}: ChallengeQuizzesFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useCollection(
    collection(
      FirebaseCoreService.getDatabase(),
      `players/${userId}/classRooms/${classRoomId}/challengeQuizzes`
    )
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    const docs = snapshot?.docs;
    if (!docs) return;

    const quizzes = docs.reduce((acc, doc) => {
      if (!isCachedData(doc)) return acc;

      acc.push(doc.data() as ChallengeQuizFirebaseProps);
      return acc;
    }, [] as ChallengeQuizFirebaseProps[]);

    syncChallengeQuizzesFirebase(quizzes, dispatch);
  }

  return null;
};

export function syncChallengeQuizzesFirebase(
  quizzes: ChallengeQuizFirebaseProps[],
  dispatch: Dispatch
) {
  const mapped = quizzes.map(challengeQuizFirebaseParser.map);

  dispatch(challengesActions.update(mapped));
  dispatch(challengesActions.updateHash(mapped));
  dispatch(classRoomActions.async.syncFlowParcialDone());
}

export default ChallengeQuizzesFirebaseSync;
