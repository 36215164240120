// enums
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

interface LevelProps {
  active: boolean;
  customClassName?: string;
}

export function LevelEasyIcon({ active, customClassName }: LevelProps) {
  return (
    <div
      className={`${customClassName} nerdfy_level_icon nerdfy_bg_green ${
        !active && "nerdfy_level_icon_not_active"
      }`}
    ></div>
  );
}

export function LevelMediumIcon({ active, customClassName }: LevelProps) {
  return (
    <div
      className={`${customClassName} nerdfy_level_icon nerdfy_bg_yellow ${
        !active && "nerdfy_level_icon_not_active"
      }`}
    ></div>
  );
}

export function LevelHardIcon({ active, customClassName }: LevelProps) {
  return (
    <div
      className={`${customClassName} nerdfy_level_icon nerdfy_bg_red ${
        !active && "nerdfy_level_icon_not_active"
      }`}
    ></div>
  );
}

interface LevelIconHandlerProps {
  level: ChallengeQuizLevelType;
  customClassName?: string;
}

const LevelIconHandler = ({
  level,
  customClassName,
}: LevelIconHandlerProps) => {
  return (
    <>
      {level === ChallengeQuizLevelType.Easy && (
        <LevelEasyIcon customClassName={customClassName} active />
      )}

      {level === ChallengeQuizLevelType.Medium && (
        <LevelMediumIcon customClassName={customClassName} active />
      )}

      {level === ChallengeQuizLevelType.Hard && (
        <LevelHardIcon customClassName={customClassName} active />
      )}
    </>
  );
};

export default LevelIconHandler;
