interface SvgProps {
  width?: string;
  height?: string;
}

const NerdfyCivilizationPixelIcon = ({
  width = "18",
  height = "18",
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M481.88 150.59H512v-30.12h-30.12V90.36h-30.11v30.11h-30.12v30.12h30.12v60.24h-30.12v-30.12h-30.12v-30.12H331.3v30.12h-30.12v-60.24h-30.12V60.24h30.12V30.12h-30.12V0h-30.12v30.12h-30.12v30.12h30.12v60.23h-30.12v60.24h-30.11v-30.12h-60.24v30.12H90.35v30.12H60.24v-60.24h30.11v-30.12H60.24V90.36H30.12v30.11H0v30.12h30.12v60.24H0v90.35h30.12v90.35h30.12v30.12H30.12v30.12H0v30.12h30.12V512h451.76v-30.11H512v-30.12h-30.12v-30.12h-30.11v-30.12h30.11v-90.35H512v-90.35h-30.12z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          fill="#04a6df"
          d="M240.939 301.175h30.11v60.24h-30.11z"
          opacity="1"
          data-original="#04a6df"
        ></path>
        <g fill="#ea7098">
          <path
            d="M391.529 210.825v-30.12h-60.24v60.24h30.12v30.11h30.12v-30.11h30.11v-30.12zM120.469 180.705v30.12h-30.12v30.12h30.12v30.11h30.11v-30.11h30.12v-60.24z"
            fill="#ea7098"
            opacity="1"
            data-original="#ea7098"
          ></path>
        </g>
        <g fill="#ffc906">
          <path
            d="M451.759 240.945h-30.12v30.11h-30.11v30.12h-30.12v-30.12h-30.12v-30.11h-30.12v-60.24h-30.12v-60.24h-30.11v60.24h-30.12v60.24h-30.12v30.11h-30.12v30.12h-30.11v-30.12h-30.12v-30.11h-30.12v-30.12h-30.12v90.35h30.12v90.35h30.12v30.12h331.29v-30.12h30.12v-90.35h30.12v-90.35h-30.12zm-150.59 120.47h-30.12v30.11h-30.11v-30.11h-30.12v-60.24h30.12v-30.12h30.11v30.12h30.12zM451.759 120.465h30.12v30.12h-30.12zM30.109 451.765h451.77v30.12H30.109zM240.939 30.115h30.11v30.12h-30.11zM30.109 120.465h30.12v30.12h-30.12z"
            fill="#ffc906"
            opacity="1"
            data-original="#ffc906"
          ></path>
        </g>
        <g fill="#ffeeb4">
          <path
            d="M391.529 331.295h30.11v30.12h-30.11zM331.289 331.295h30.12v30.12h-30.12zM150.579 331.295h30.12v30.12h-30.12zM90.349 331.295h30.12v30.12h-30.12z"
            fill="#ffeeb4"
            opacity="1"
            data-original="#ffeeb4"
          ></path>
        </g>
        <g fill="#f58420">
          <path
            d="M451.759 210.825v30.12h-30.12v30.11h-30.11v30.12h-30.12v30.12h60.23v30.12h-30.11v30.11h-30.12v30.12h60.23v-30.12h30.12v-90.35h30.12v-90.35zM331.289 451.765h150.59v30.12h-150.59zM331.289 271.055h30.12v30.12h-30.12zM301.169 240.945h30.12v30.11h-30.12zM271.049 180.705h30.12v60.24h-30.12zM240.939 120.465h30.11v60.24h-30.11z"
            fill="#f58420"
            opacity="1"
            data-original="#f58420"
          ></path>
        </g>
        <g fill="#ba4899">
          <path
            d="M391.529 210.825h30.11v30.12h-30.11zM361.409 240.945h30.12v30.11h-30.12zM361.409 180.705h30.12v30.12h-30.12zM150.579 180.705h30.12v60.24h-30.12zM120.469 240.945h30.11v30.11h-30.11z"
            fill="#ba4899"
            opacity="1"
            data-original="#ba4899"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default NerdfyCivilizationPixelIcon;
