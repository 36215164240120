// react
import { IonRippleEffect } from "@ionic/react";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.9;
  background: ${color.dark.bg};

  .fake_btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      font-size: 30px;
      color: ${color.grey.middle};
      text-align: center;
      margin: 10px 0;

      &.desc {
        color: ${color.grey.middle};
      }

      &.dont_panic {
        color: ${color.grey.light};
      }
    }
  }
`;

interface ClassRoomStartButtonProps {
  loading: boolean;
  onClick: () => void;
}

const ClassRoomStartButton = ({
  loading,
  onClick,
}: ClassRoomStartButtonProps) => {
  function click() {
    setTimeout(onClick, 50);
  }

  return (
    <Wrapper onClick={click} className="ion-activatable pixelify_font_family">
      <IonRippleEffect style={{ color: color.red }} />

      <div className="fake_btn blink">
        <p className="bg_nerdfy_gradient">Aperte para Começar</p>
        <p className="desc">
          {loading ? "Carregando dados..." : "Estamos quase lá..."}
        </p>
        <p className="dont_panic">Don't Panic</p>
      </div>
    </Wrapper>
  );
};

export default ClassRoomStartButton;
