// interfaces
import { ClassRoomResourceProps, ClassRoomProps } from "interfaces/classRoom";

// constants
import { AWS } from "constants/aws";

function list(list: ClassRoomResourceProps[]): ClassRoomProps[] {
  if (!Array.isArray(list)) return [];
  return list.map((classRoom: ClassRoomResourceProps) => map(classRoom));
}

function map(data: ClassRoomResourceProps): ClassRoomProps {
  const { languages, creator } = data;
  const language = languages[0];
  const content = language.content || [];
  const requirements = language.requirements || [];
  const image = mapImageSrc(language.image);
  const values: ClassRoomProps = {
    content,
    requirements,
    image,
    id: data.id,
    name: language.name,
    description: language.description,
    languageId: language.language.id,
    language: language.language.name,
    languageAlias: language.language.alias,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    level: language.classroom_level.name,
    creator: creator.name,
    creatorFamilyName: creator.familyName,
    creatorImage: creator.image,
    creatorDescription: creator.description,

    // flows
    flowParcialDone: false,
  };

  return values;
}

function mapImageSrc(key: string): string {
  return `${AWS.s3.bucket.urlClassRoom}/imgs/${key}_thumbnail`;
}

const classRoomParser = {
  list,
  map,
};

export default classRoomParser;
