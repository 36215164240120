// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import {
  PlayerShopItemsFirebaseProps,
  PlayerShopItemsOptionalFirebaseProps,
} from "interfaces/shopFirebase";

// utils
import isEmpty from "lodash/isEmpty";

class PlayerShopItemsFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async set(
    userId: number | string,
    shopItems: PlayerShopItemsFirebaseProps
  ): Promise<void> {
    if (!userId) return Promise.reject();

    const path = `players/${userId}`;

    if (isEmpty(shopItems)) return Promise.reject();
    return this._firebase.set(path, { shopItems });
  }

  async update(
    userId: number | string,
    shopItems: PlayerShopItemsOptionalFirebaseProps
  ): Promise<void> {
    if (!userId) return Promise.reject();

    const path = `players/${userId}`;

    if (isEmpty(shopItems)) return Promise.reject();
    return this._firebase.set(path, { shopItems });
  }

  async remove(userId: number | string, deleteFields: string[]): Promise<void> {
    if (!userId) return Promise.reject();

    const path = `players/${userId}`;
    return this._firebase.deleteField(path, deleteFields);
  }
}

export default PlayerShopItemsFirebaseService;
