// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// components
import Icon, {
  farThumbsDown,
  faThumbsDown,
  farThumbsUp,
  faThumbsUp,
} from "components/Icon";

interface ThumbsProps {
  onClick: () => void;
  up?: boolean;
  is?: boolean;
  buttonClassName?: string;
}

const Thumbs = ({ onClick, up, is, buttonClassName }: ThumbsProps) => {
  return (
    <>
      {up ? (
        <>
          {is ? (
            <ButtonDuolingo onClick={onClick} className={buttonClassName}>
              <Icon icon={faThumbsUp} />
            </ButtonDuolingo>
          ) : (
            <ButtonDuolingo onClick={onClick} className={buttonClassName}>
              <Icon icon={farThumbsUp} />
            </ButtonDuolingo>
          )}
        </>
      ) : (
        <>
          {is ? (
            <ButtonDuolingo onClick={onClick} className={buttonClassName}>
              <Icon icon={faThumbsDown} />
            </ButtonDuolingo>
          ) : (
            <ButtonDuolingo onClick={onClick} className={buttonClassName}>
              <Icon icon={farThumbsDown} />
            </ButtonDuolingo>
          )}
        </>
      )}
    </>
  );
};

export default Thumbs;
