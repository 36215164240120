// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const PersonsPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M33.391 139.13h33.391v133.565H33.391zM267.13 139.13h33.391v133.565H267.13zM100.174 72.348h133.565v33.391H100.174zM66.783 105.739h33.391v33.391H66.783zM233.739 105.739h33.391v33.391h-33.391zM233.739 272.696v33.391h-33.391v33.391h66.782v-66.782zM33.391 339.478h33.391v33.391H33.391zM267.13 339.478h33.391v33.391H267.13zM300.522 372.87v66.782H33.391V372.87H0v100.173h333.913V372.87zM100.174 306.087v-33.391H66.783v66.782h66.782v-33.391zM445.217 105.739h33.391v133.565h-33.391zM278.261 38.957h133.565v33.391H278.261zM411.826 72.348h33.391v33.391h-33.391zM411.826 239.304v33.392h-33.391v33.391h66.782v-66.783zM445.217 306.087h33.391v33.391h-33.391zM478.609 339.478v66.783H367.304v33.391H512V339.478z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default PersonsPixelIcon;
