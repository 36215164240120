// firestore
import { increment } from "firebase/firestore";

// interfaces
import {
  SpaceshipResourcesProps,
  SpaceshipResourcesDataProps,
} from "interfaces/spaceInvaders/spaceshipResources";
import {
  SpaceshipResourcesFirebaseProps,
  SpaceshipResourcesShiftDataFirebaseProps,
  SpaceshipResourcesTransformDataFirebaseProps,
} from "interfaces/spaceInvaders/spaceshipResourcesFirebase";

// utils
import { serverTimestamp, FieldValue } from "firebase/firestore";
import { AllToOptional, AllToBooleanOptional } from "utils/transformTypeUtils";

interface SpaceshipResourcesFirebaseInputProps {
  [key: string]: {
    f: boolean;
    t: FieldValue;
    c: FieldValue;
  };
}

type SpaceshipResourcesConditionalProps =
  AllToBooleanOptional<SpaceshipResourcesProps>;

interface ConstructorProps {
  id: string;
  resources: SpaceshipResourcesConditionalProps;
}

class PlayerSpaceshipResourcesDataFirebaseEntity {
  private _id: string;
  private _resources: SpaceshipResourcesConditionalProps;

  constructor({ id: idResource, resources }: ConstructorProps) {
    this._id = idResource;
    this._resources = resources;
  }

  get data(): AllToOptional<SpaceshipResourcesFirebaseProps> {
    const d = {} as AllToOptional<SpaceshipResourcesFirebaseProps>;
    const { gold, sand, water, copper, silver, estelin, bauxite, strongForce } =
      this._resources;

    // minerals
    if (gold)
      d["gold"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(this._id);
    if (sand)
      d["sand"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(this._id);
    if (copper)
      d["copper"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(this._id);
    if (silver)
      d["silver"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(this._id);
    if (estelin)
      d["estelin"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(this._id);
    if (bauxite)
      d["bauxite"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(this._id);

    if (water)
      d["water"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(
        this._id,
        true
      );
    if (strongForce)
      d["strongForce"] = PlayerSpaceshipResourcesDataFirebaseEntity.map(
        this._id,
        true
      );

    return d;
  }

  static hasAvailableResource(
    resource: SpaceshipResourcesDataProps,
    max: number,
    incoming?: number
  ): boolean {
    if (!incoming) return false;
    return (
      PlayerSpaceshipResourcesDataFirebaseEntity.sumResources(resource) +
        incoming <=
      max
    );
  }

  static sumResources(resource: SpaceshipResourcesDataProps): number {
    return resource.raw + resource.shifting + resource.transformed;
  }

  static map(id: string, f = false): SpaceshipResourcesFirebaseInputProps {
    return {
      [id]: { f, ...PlayerSpaceshipResourcesDataFirebaseEntity.shift(true) },
    };
  }

  static transform(): SpaceshipResourcesTransformDataFirebaseProps {
    return {
      f: true,
    };
  }

  static shift(initialValue = false): SpaceshipResourcesShiftDataFirebaseProps {
    return {
      t: initialValue ? increment(0) : increment(1),
      c: serverTimestamp(),
    };
  }
}

export default PlayerSpaceshipResourcesDataFirebaseEntity;
