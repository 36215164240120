// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreAlert = (_data: AttentionWhoreElProps) => {
  return (
    <span className="jarvis_alert jarvis_alert_big nerdfy_red pixelify_font_family">
      !
    </span>
  );
};

export default IconWhoreAlert;
