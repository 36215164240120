// interfaces
import {
  FlyingObjectPositionProps,
  FlyingObjectProps,
} from "interfaces/spaceInvaders/flyingObject";

// entities
import AbstractFlyingObjectEntity from "entities/spaceInvaders/AbstractFlyingObjectEntity";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
  FlyingObjectHpType,
  FlyingObjectDamageType,
} from "enums/spaceInvaders/flyingObjectEnum";

// utils
import color from "styles/color";

export default class NonExistentEntity
  extends AbstractFlyingObjectEntity
  implements FlyingObjectProps
{
  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.type = FlyingObjectType.NonExistent;
    this.subtype = FlyingObjectSubType.NonExistent;
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.low;
    this.explosionColor = color.grey.middle;
  }
}
