// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { tagActions } from "redux/slices/tagSlice";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeCodeActions } from "redux/slices/challengeCodeSlice";

// interfaces
import { TagProps, SpecialTagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";
import { ChallengeFirebaseUpdateProps } from "interfaces/challengeFirebase";

// entities
import PlayerFirebaseEntity from "entities/PlayerFirebaseEntity";
import ChallengeFirebaseEntity from "entities/ChallengeFirebaseEntity";

// services
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";

// utils
import cloneDeep from "lodash/cloneDeep";
import codeUtils from "utils/code/codeUtils";
import stateUtils from "redux/utils/stateUtils";
import validatorUtils from "utils/code/validatorUtils";
import blacklistUtils from "utils/code/blacklistUtils";

const tagMiddleware = createListenerMiddleware();

// tag/add
tagMiddleware.startListening({
  actionCreator: tagActions.async.add,
  effect: async ({ payload }, listenerApi) => {
    const { tag, specialTags } = payload;
    const { challenge, challengeCode } = stateUtils.get(listenerApi);
    const code = updateCode(tag, specialTags, challengeCode);
    const currentCode = codeUtils.get(tag, specialTags, code);
    const finished = validatorUtils.finished(code);
    const frame = challenge.frame + 1;

    listenerApi.dispatch(challengeActions.update({ frame, currentCode }));
    listenerApi.dispatch(challengeCodeActions.update(code));

    if (finished) listenerApi.dispatch(challengeActions.async.finish());
  },
});

// tag/missed
tagMiddleware.startListening({
  actionCreator: tagActions.async.missed,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id: challengeId, classRoomId } = challenge;
    const userId = auth.user.id;
    const playerFirebaseEntity = new PlayerFirebaseEntity();
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();

    updatePlayerFirebase(userId, playerFirebaseEntity.getMissedValues());
    updateChallengeFirebase(
      userId,
      challengeId,
      classRoomId,
      challengeFirebaseEntity.getMissedValues()
    );
  },
});

// tag/help
tagMiddleware.startListening({
  actionCreator: tagActions.async.help,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id: challengeId, classRoomId } = challenge;
    const userId = auth.user.id;
    const playerFirebaseEntity = new PlayerFirebaseEntity();
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();

    updatePlayerFirebase(userId, playerFirebaseEntity.getHelpValues());
    updateChallengeFirebase(
      userId,
      challengeId,
      classRoomId,
      challengeFirebaseEntity.getHelpedValues()
    );
  },
});

// private

function updateCode(
  tag: TagProps,
  specialTags: SpecialTagProps[],
  code: ChallengeCodeProps
): ChallengeCodeProps {
  const { additional, steps, verified, finished } = code;
  const v = [...cloneDeep(verified), cloneDeep(tag)];
  const blacklist = blacklistUtils.get(v, specialTags, steps);
  const filteredSteps = blacklistUtils.filter(steps, blacklist);
  const filteredFinished = blacklistUtils.filter(finished, blacklist);

  return {
    additional,
    verified: v,
    steps: filteredSteps,
    finished: filteredFinished,
  };
}

// private

function updatePlayerFirebase(
  userId: number | string,
  player: PlayerFirebaseUpdateProps
) {
  if (!userId) return;

  const playerFirebaseService = new PlayerFirebaseService();
  playerFirebaseService.update(userId, player);
}

function updateChallengeFirebase(
  userId: number | string,
  challengeId: number,
  classRoomId: number,
  data: ChallengeFirebaseUpdateProps
) {
  if (!userId) return;
  if (!challengeId) return;
  if (!classRoomId) return;

  const challengeFirebaseService = new PlayerChallengeFirebaseService();
  challengeFirebaseService.update(challengeId, userId, classRoomId, data);
}

export default tagMiddleware;
