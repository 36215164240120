// react
import { useState, useEffect } from "react";

// enums
import { BubbleType } from "enums/bubbleEnum";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// icons
import IconWhoreInfo from "components/iconWhores/IconWhoreInfo";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface TipsHandlerProps {
  tip: string;
  clear: boolean;
  paused: boolean;
  onBubble(): void;
  onHideBubble(): void;
  wrapper: HTMLDivElement | null;
}

const TipsHandler = ({
  tip,
  clear,
  paused,
  wrapper,
  onBubble,
  onHideBubble,
}: TipsHandlerProps) => {
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [tip]);

  function listen() {
    if (!tip) return;
    if (!wrapper) return;

    const whore = attentionWhoreUtils.get({
      payload: tip,
      WhoreEl: IconWhoreInfo,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "blue",
      bubbleType: BubbleType.Info,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setWhores([...whores, whore]);
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    onBubble();
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    onHideBubble();
    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  return (
    <>
      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <AttentionWhores
        clear={clear}
        whores={whores}
        paused={paused}
        disabledDrop={!!attentionWhore.show}
      />
    </>
  );
};

export default TipsHandler;
