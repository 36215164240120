import { TagProps, SpecialTagProps } from "interfaces/tag";
import validatorUtils from "utils/code/validatorUtils";
import { ChallengeCodeStepProps } from "interfaces/challengeCode";

interface BlackListI {
  [key: string]: boolean;
}

const blacklistUtils = {
  get(
    verified: TagProps[],
    specialTags: SpecialTagProps[],
    steps: ChallengeCodeStepProps[]
  ): BlackListI {
    return steps.reduce(
      (acc: { [key: string]: boolean }, { data }, i: number) => {
        const validated = verified.every(
          (verifiedTag: TagProps, index: number) =>
            validatorUtils.is(verifiedTag, [data[index]], specialTags)
        );

        if (!validated) acc[i] = true;
        return acc;
      },
      {}
    );
  },
  filter(list: any[], blacklist: BlackListI) {
    return list.filter((_code: any, i: number) => !blacklist[i]);
  },
};

export default blacklistUtils;
