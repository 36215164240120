// services
import RequestCoreService from "services/core/RequestCoreService";

// interfaces
import { JarvisChatResourceProps } from "interfaces/jarvisChat";

class JarvisService {
  private _resource = "jarvis";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  async get(classRoomId: number): Promise<{ data: JarvisChatResourceProps }> {
    const resource = `${this._resource}/classroom/${classRoomId}`;
    return this._request.get(resource);
  }
}

export default JarvisService;
