// firestore
import { FieldValue, increment } from "firebase/firestore";

// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

interface LikeFieldValuesProps {
  up?: FieldValue;
  down?: FieldValue;
}

class GlobalChallengeThumbsFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  set(
    challengeId: number,
    classRoomId: number,
    { up, down }: { up?: boolean; down: boolean }
  ): Promise<void> {
    if (!challengeId) return Promise.reject();

    const path = `/classRooms/${classRoomId}/challenges/${challengeId}`;
    const data: LikeFieldValuesProps = {};

    if (up) data.up = increment(1);
    if (down) data.down = increment(1);

    return this._firebase.set(path, data);
  }
}

export default GlobalChallengeThumbsFirebaseService;
