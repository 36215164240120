// react
import { useState, useEffect, useRef } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// enums
import { ChallengeType } from "enums/challengeEnum";
import { NextChallengeDisabledType } from "enums/nextChallengeEnum";

// components
import ChallengeDoneButton, {
  LabelProps,
} from "handlers/challengeDoneButton/ChallengeDoneButton";
import { ChallengeCommonProps } from "interfaces/challenge";
import ChallengeBarrierButton from "handlers/challengeDoneButton/ChallengeBarrierButton";

function LabelIntroduction({ done, label }: LabelProps) {
  return (
    <p className={`blink nerdfy_purple ${done && "bg_nerdfy_gradient"}`}>
      {label}
    </p>
  );
}

function LabelExercise({ done, label }: LabelProps) {
  return (
    <p className={`blink nerdfy_orange ${done && "bg_nerdfy_gradient"}`}>
      {label}
    </p>
  );
}

function LabelQuiz({ done, label }: LabelProps) {
  return (
    <p className={`blink nerdfy_green ${done && "bg_nerdfy_gradient"}`}>
      {label}
    </p>
  );
}

function LabelBarrier({ label }: { label: string }) {
  return <p className={`blink nerdfy_white`}>{label}</p>;
}

export interface NextChallengeInfoProps {
  sectionName: string;
  challengeName: string;
  isNextChallengeDone: boolean;
  isNextChallengeSuccess: boolean;
  nextChallengeType: ChallengeType;
  isNextChallengeDisabled: boolean;
  nextChallengeAvatarInvader: number | undefined;
  nextChallengeDisabledType: NextChallengeDisabledType;
}

interface ChallengeDoneHandlerProps {
  callNextChallenge(data: ChallengeCommonProps): void;
}

const ChallengeDoneButtonHandler = ({
  callNextChallenge,
}: ChallengeDoneHandlerProps) => {
  const timeoutRef = useRef<any>();
  const disabledRef = useRef(false);
  const challenge = useSelector(s.challenge());
  const nextChallenge = useSelector(s.nextChallenge());
  const [isAvailable, setIsAvailable] = useState(false);
  const [info, setInfo] = useState<NextChallengeInfoProps>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleNextChallenge, [nextChallenge]);
  useEffect(handleAvailability, [challenge.flowDone, challenge.flowFinished]);

  function handleAvailability() {
    if (!challenge.flowDone) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(
      () => setIsAvailable(true),
      challenge.flowFinished ? 3500 : 0
    );
  }

  function handleNextChallenge() {
    if (!nextChallenge) return;

    setInfo({
      sectionName: nextChallenge.section.name,
      challengeName: nextChallenge.challenge.name,
      nextChallengeType: nextChallenge.challenge.type,
      isNextChallengeDisabled: nextChallenge.disabled,
      nextChallengeDisabledType: nextChallenge.disabledType,
      isNextChallengeDone: nextChallenge.challenge.flowDone,
      isNextChallengeSuccess: nextChallenge.challenge.flowSuccess,
      nextChallengeAvatarInvader: nextChallenge.challenge.avatarInvader,
    });
  }

  function go() {
    if (disabledRef.current) return;
    disabledRef.current = true;

    if (nextChallenge.disabled) return;
    if (!nextChallenge.challenge) return;
    if (!nextChallenge.section) return;

    setTimeout(() => {
      callNextChallenge(nextChallenge.challenge);
    }, 250);
  }

  function destroyComponent() {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }

  if (!info) return null;

  return (
    <>
      {isAvailable && info.isNextChallengeDisabled && (
        <ChallengeBarrierButton Label={LabelBarrier} info={info} />
      )}

      {isAvailable &&
        !info.isNextChallengeDisabled &&
        info.nextChallengeType === ChallengeType.Introduction && (
          <ChallengeDoneButton go={go} info={info} Label={LabelIntroduction} />
        )}

      {isAvailable &&
        !info.isNextChallengeDisabled &&
        info.nextChallengeType === ChallengeType.Exercise && (
          <ChallengeDoneButton go={go} info={info} Label={LabelExercise} />
        )}

      {isAvailable &&
        !info.isNextChallengeDisabled &&
        info.nextChallengeType === ChallengeType.Quiz && (
          <ChallengeDoneButton go={go} info={info} Label={LabelQuiz} />
        )}
    </>
  );
};

export default ChallengeDoneButtonHandler;
