// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
}

const InterrogationPixelIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M66.783 66.783h33.391v33.391H66.783zM166.957 0h178.087v33.391H166.957zM100.174 33.391h66.783v33.391h-66.783zM345.043 33.391h66.783v33.391h-66.783zM411.826 66.783h33.391v33.391h-33.391zM445.217 100.174h33.391v66.783h-33.391zM478.609 166.957H512v178.087h-33.391zM445.217 345.043h33.391v66.783h-33.391zM33.391 100.174h33.391v66.783H33.391zM0 166.957h33.391v178.087H0zM66.783 411.826h33.391v33.391H66.783zM33.391 345.043h33.391v66.783H33.391zM166.957 478.609h178.087V512H166.957z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M100.174 445.217h66.783v33.391h-66.783zM345.043 445.217h66.783v33.391h-66.783zM411.826 411.826h33.391v33.391h-33.391zM233.739 378.435h33.391v33.391h-33.391zM233.739 278.261h33.391v66.783h-33.391zM267.13 244.87h44.522v33.391H267.13zM311.652 133.565h33.391v111.304h-33.391zM189.217 100.174h122.435v33.391H189.217zM155.826 133.565h33.391v66.783h-33.391z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default InterrogationPixelIcon;
