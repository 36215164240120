// react
import { useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { eventsActions } from "redux/slices/eventsSlice";
import { storiesActions } from "redux/slices/storiesSlice";
import { classRoomActions } from "redux/slices/classRoomSlice";
import { jarvisChatsActions } from "redux/slices/jarvisChatsSlice";
import { challengesActions } from "redux/slices/challengesSlice";
import { challengeSectionsActions } from "redux/slices/challengeSectionsSlice";
import { programmingLanguagesActions } from "redux/slices/programmingLanguagesSlice";

// parsers
import storyParser from "parsers/storyParser";
import eventParser from "parsers/eventParser";
import challengeParser from "parsers/challengeParser";
import classRoomParser from "parsers/classRoomParser";
import jarvisChatParser from "parsers/jarvisChatParser";
import challengeQuizParser from "parsers/challengeQuizParser";
import challengeSectionParser from "parsers/challengeSectionParser";
import programmingLanguageParser from "parsers/programmingLanguageParser";

// firebase sync
import {
  syncPlayerFirebase,
  syncPlayerDegreeFirebase,
} from "firebaseSync/PlayerFirebaseSync";
import { syncChallengesFirebase } from "firebaseSync/ChallengesFirebaseSync";
import { syncJarvisChatsFirebase } from "firebaseSync/JarvisChatsFirebaseSync";
import { syncChallengeQuizzesFirebase } from "firebaseSync/ChallengeQuizzesFirebaseSync";

// interfaces
import { AuthProps } from "interfaces/auth";
import { StoryResourceProps } from "interfaces/story";
import { EventResourceProps } from "interfaces/event";
import { JarvisChatFirebaseProps } from "interfaces/jarvisChat";
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { JarvisChatResourceProps } from "interfaces/jarvisChat";
import { ClassRoomDtoResourceProps } from "interfaces/classRoom";
import { ChallengeFirebaseProps } from "interfaces/challengeFirebase";
import { ChallengeQuizFirebaseProps } from "interfaces/challengeQuizFirebase";

// services
import StoryService from "services/StoryService";
import EventService from "services/EventService";
import JarvisService from "services/JarvisService";
import ClassRoomDtoService from "services/ClassRoomDtoService";
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";
import PlayerJarvisChatsFirebaseService from "services/firebase/player/PlayerJarvisChatsFirebaseService";
import PlayerChallengeQuizFirebaseService from "services/firebase/player/PlayerChallengeQuizFirebaseService";

export interface FetchCallbackProps {
  storiesData: StoryResourceProps[];
  eventsData: EventResourceProps[];
  dtoData: ClassRoomDtoResourceProps;
  jarvisData: JarvisChatResourceProps[];
  playerFirebaseData: PlayerFirebaseProps;
  challengesFirebaseData: ChallengeFirebaseProps[];
  challengeQuizzesFirebaseData: ChallengeQuizFirebaseProps[];
  jarvisChatsFirebaseData: JarvisChatFirebaseProps[];
}

interface FetchClassRoomDataHandlerProps {
  auth: AuthProps;
  languageId: number;
  classRoomId: number;
  successCallback: () => void;
  errorCallback: (error: unknown) => void;
  onStart?: () => void;
}

const FetchClassRoomDataHandler = ({
  auth,
  onStart,
  languageId,
  classRoomId,
  errorCallback,
  successCallback,
}: FetchClassRoomDataHandlerProps) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(start, [auth]);

  function start() {
    if (!onStart) return;
    if (!auth.signed) return;

    onStart();
  }

  useEffect(() => {
    async function handlePromises() {
      try {
        const userId = auth.user.id;
        if (!userId) return;

        const storyResource = new StoryService();
        const eventResource = new EventService();
        const jarvisResource = new JarvisService();
        const classRoomDtoResource = new ClassRoomDtoService();
        const playerFirebaseResource = new PlayerFirebaseService();
        const challengeFirebaseResource = new PlayerChallengeFirebaseService();
        const jarvisChatFirebaseResource =
          new PlayerJarvisChatsFirebaseService();
        const challengeQuizzesFirebaseResource =
          new PlayerChallengeQuizFirebaseService();

        const promises = [
          classRoomDtoResource.get(languageId, classRoomId),
          jarvisResource.get(classRoomId),
          challengeFirebaseResource.getCollection(userId, classRoomId),
          playerFirebaseResource.get(userId),
          storyResource.get(languageId),
          eventResource.get(languageId),
          challengeQuizzesFirebaseResource.getCollection(userId, classRoomId),
          jarvisChatFirebaseResource.getCollection(userId, classRoomId),
        ];

        const [
          dto,
          jarvis,
          challengesFirebase,
          playerFirebase,
          stories,
          events,
          challengeQuizzesFirebase,
          jarvisChatsFirebase,
        ]: any = await Promise.all(promises);

        fetchCallback({
          dtoData: dto.data,
          jarvisData: jarvis.data,
          storiesData: stories.data,
          eventsData: events.data,
          playerFirebaseData: playerFirebase,
          challengesFirebaseData: challengesFirebase,
          challengeQuizzesFirebaseData: challengeQuizzesFirebase,
          jarvisChatsFirebaseData: jarvisChatsFirebase,
        });
      } catch (e: unknown) {
        errorCallback(e);
      }
    }

    handlePromises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, languageId, classRoomId]);

  function fetchCallback({
    dtoData,
    jarvisData,
    storiesData,
    eventsData,
    playerFirebaseData,
    challengesFirebaseData,
    challengeQuizzesFirebaseData,
    jarvisChatsFirebaseData,
  }: FetchCallbackProps) {
    try {
      const {
        classRoom: classRoomData,
        challenges: challengesData,
        sections: sectionsData,
        programmingLanguages: programmingLanguagesData,
        challengeQuizzes: challengeQuizzesData,
      } = dtoData;
      const classRoom = classRoomParser.map(classRoomData);
      const sections = challengeSectionParser.list(sectionsData);
      const programmingLanguages = programmingLanguageParser.list(
        programmingLanguagesData
      );
      const challenges = challengeParser.list(
        challengesData,
        programmingLanguagesData
      );
      const jarvis = jarvisChatParser.list(jarvisData);
      const quizzes = challengeQuizParser.list(challengeQuizzesData);
      const hashes = [
        challengeQuizParser.hash(
          challengeQuizParser.mix(quizzes, challengeQuizzesFirebaseData)
        ),
        challengeParser.hash(
          challengeParser.mix(challenges, challengesFirebaseData)
        ),
      ];

      dispatch(eventsActions.set(eventParser.list(eventsData)));
      dispatch(storiesActions.set(storyParser.list(storiesData)));
      dispatch(jarvisChatsActions.set(jarvis));
      dispatch(classRoomActions.set(classRoom));
      dispatch(challengeSectionsActions.set(sections));
      dispatch(programmingLanguagesActions.set(programmingLanguages));
      dispatch(challengesActions.async.merge({ hashes }));

      // sync firebase
      syncPlayerFirebase(playerFirebaseData, dispatch);
      syncPlayerDegreeFirebase(playerFirebaseData, dispatch);
      syncChallengesFirebase(challengesFirebaseData, dispatch);
      syncJarvisChatsFirebase(jarvisChatsFirebaseData, dispatch);
      syncChallengeQuizzesFirebase(challengeQuizzesFirebaseData, dispatch);

      dispatch(classRoomActions.async.syncFlowParcialDone());
      setTimeout(successCallback);
    } catch (e: unknown) {
      errorCallback(e);
    }
  }

  return null;
};

export default FetchClassRoomDataHandler;
