// firestore
import { DocumentReference } from "firebase/firestore";

// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// entities
import WalkieTalkieBroadCastEntity from "entities/WalkieTalkieBroadCastEntity";

// interfaces
import { WalkieTalkieBroadcastFirebaseProps } from "interfaces/walkieTalkie";

class WalkieTalkieFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async add(
    data: WalkieTalkieBroadcastFirebaseProps
  ): Promise<DocumentReference<any> | void> {
    if (!data.author) return Promise.reject();
    if (!data.userId) return Promise.reject();
    if (!data.message) return Promise.reject();
    if (!data.createdAt) return Promise.reject();
    if (!data.languageId) return Promise.reject();
    if (!data.classRoomId) return Promise.reject();
    if (!data.challengeId) return Promise.reject();
    if (!data.challengeSectionId) return Promise.reject();
    if (data.message.length > WalkieTalkieBroadCastEntity.maxWords)
      return Promise.reject();

    const path = `broadcast/chats/${data.languageId}`;
    return this._firebase.add(path, data);
  }
}

export default WalkieTalkieFirebaseService;
