// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { spaceshipResourcesActions } from "redux/slices/spaceInvaders/spaceshipResourcesSlice";

// services
import PlayerSpaceshipResourcesDataFirebaseService from "services/firebase/player/PlayerSpaceshipResourcesDataFirebaseService";

// interfaces
import {
  SpaceshipResourcesFirebaseProps,
  SpaceshipResourcesShiftFirebaseProps,
  SpaceshipResourcesUpdateFirebaseProps,
  SpaceshipResourcesTransformFirebaseProps,
  SpaceshipResourcesShiftDataFirebaseProps,
  SpaceshipResourcesTransformDataFirebaseProps,
} from "interfaces/spaceInvaders/spaceshipResourcesFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import PlayerSpaceshipResourcesDataFirebaseEntity from "entities/data/PlayerSpaceshipResourcesDataFirebaseEntity";

// enums
import { SpaceshipResourceType } from "enums/spaceInvaders/spaceshipEnum";

// constants
import SPACE_INVADERS from "constants/spaceInvaders/spaceInvaders";

// utils
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import stateUtils from "redux/utils/stateUtils";
import { AllToOptional, AllToBooleanOptional } from "utils/transformTypeUtils";

const spaceshipResourcesMiddleware = createListenerMiddleware();

spaceshipResourcesMiddleware.startListening({
  actionCreator: spaceshipResourcesActions.async.add,
  effect: async ({ payload }, listenerApi) => {
    const { gold, silver, bauxite, sand, copper, water, strongForce, estelin } =
      payload;
    const { Gold, Silver, Bauxite, Sand, Copper, Estelin, Water, StrongForce } =
      SpaceshipResourceType;
    const { spaceshipResources, auth } = stateUtils.get(listenerApi);
    const { spaceshipResources: sr } = SPACE_INVADERS;
    const resources: AllToBooleanOptional<SpaceshipResourcesQtyProps> = {};
    const { hasAvailableResource } = PlayerSpaceshipResourcesDataFirebaseEntity;

    resources.gold = hasAvailableResource(
      spaceshipResources.gold,
      sr[Gold].max,
      gold
    );
    resources.sand = hasAvailableResource(
      spaceshipResources.sand,
      sr[Sand].max,
      sand
    );
    resources.water = hasAvailableResource(
      spaceshipResources.water,
      sr[Water].max,
      water
    );
    resources.silver = hasAvailableResource(
      spaceshipResources.silver,
      sr[Silver].max,
      silver
    );
    resources.copper = hasAvailableResource(
      spaceshipResources.copper,
      sr[Copper].max,
      copper
    );
    resources.bauxite = hasAvailableResource(
      spaceshipResources.bauxite,
      sr[Bauxite].max,
      bauxite
    );
    resources.estelin = hasAvailableResource(
      spaceshipResources.estelin,
      sr[Estelin].max,
      estelin
    );
    resources.strongForce = hasAvailableResource(
      spaceshipResources.strongForce,
      sr[StrongForce].max,
      strongForce
    );

    const entity = new PlayerSpaceshipResourcesDataFirebaseEntity({
      resources,
      id: getUUID(),
    });

    // firebase
    updateSpaceshipResourceFirebase(auth.user.id, entity.data);
  },
});

spaceshipResourcesMiddleware.startListening({
  actionCreator: spaceshipResourcesActions.async.remove,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);
    const { deleteFields } = payload;

    if (isEmpty(deleteFields)) return;
    removeSpaceshipResourceFieldFirebase(auth.user.id, deleteFields);
  },
});

spaceshipResourcesMiddleware.startListening({
  actionCreator: spaceshipResourcesActions.async.transform,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;
    if (isEmpty(payload)) return;

    const resources = mapResources(
      payload,
      PlayerSpaceshipResourcesDataFirebaseEntity.transform
    );
    if (!resources) return;

    updateSpaceshipResourceFirebase(auth.user.id, resources);
  },
});

spaceshipResourcesMiddleware.startListening({
  actionCreator: spaceshipResourcesActions.async.shift,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;
    if (isEmpty(payload)) return;

    const resources = mapResources(
      payload,
      PlayerSpaceshipResourcesDataFirebaseEntity.shift
    );
    if (!resources) return;

    updateSpaceshipResourceFirebase(auth.user.id, resources);
  },
});

// private

function updateSpaceshipResourceFirebase(
  userId: number | string,
  resources:
    | AllToOptional<SpaceshipResourcesFirebaseProps>
    | AllToOptional<SpaceshipResourcesShiftFirebaseProps>
    | AllToOptional<SpaceshipResourcesTransformFirebaseProps>
) {
  if (!userId) return;

  const resource = new PlayerSpaceshipResourcesDataFirebaseService();
  resource.set(userId, resources);
}

function removeSpaceshipResourceFieldFirebase(
  userId: number | string,
  deleteFields: string[]
) {
  if (!userId) return;

  const resource = new PlayerSpaceshipResourcesDataFirebaseService();
  resource.remove(userId, deleteFields);
}

function mapResources(
  data: AllToOptional<SpaceshipResourcesUpdateFirebaseProps>,
  enrichResource: () =>
    | SpaceshipResourcesShiftDataFirebaseProps
    | SpaceshipResourcesTransformDataFirebaseProps
):
  | AllToOptional<SpaceshipResourcesShiftFirebaseProps>
  | AllToOptional<SpaceshipResourcesTransformFirebaseProps>
  | undefined {
  const resourceNames = Object.keys(data) as Array<
    keyof SpaceshipResourcesUpdateFirebaseProps
  >;
  if (isEmpty(resourceNames)) return;

  return resourceNames.reduce((acc, resourceName) => {
    const d = data[resourceName] as { [key: string]: boolean };
    if (isEmpty(d)) return acc;

    const ids = Object.keys(d) as Array<string>;

    acc[resourceName] = ids.reduce((acc2, id) => {
      if (!d[id]) return acc2;

      acc2[id] = enrichResource();
      return acc2;
    }, {} as { [key: string]: SpaceshipResourcesShiftDataFirebaseProps } | { [key: string]: SpaceshipResourcesTransformDataFirebaseProps });

    return acc;
  }, {} as AllToOptional<SpaceshipResourcesShiftFirebaseProps> | AllToOptional<SpaceshipResourcesTransformFirebaseProps>);
}

function getUUID(): string {
  return uuidv4().split("-")[0];
}

export default spaceshipResourcesMiddleware;
