// entities
import PlayerHpEntity from "entities/PlayerHpEntity";
import ChallengeScoreEntity from "entities/ChallengeScoreEntity";
import SpaceshipItemsEntity from "entities/SpaceshipItemsEntity";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";

// interfaces
import { ChallengeProps } from "interfaces/challenge";
import { xpTypeUpdate } from "interfaces/playerFirebase";

// utils
import { increment, FieldValue, deleteField } from "firebase/firestore";

interface DoneValuesProps {
  px: FieldValue;
  score: FieldValue;
}

interface HitValuesProps {
  hp: number;
  life: number;
  gameOver: boolean;
  xp?: FieldValue;
  killed?: FieldValue;
}

interface RestartValuesProps {
  hp: number;
  life: number;
  gunId: string;
  gameOver: boolean;
  shopItems: PlayerShopItemsFirebaseProps;
}

interface TryAgainValuesProps {
  life: number;
  hp: number;
}

interface GameOverValuesProps {
  gunId: "";
  px: FieldValue;
  xp: FieldValue;
  shopItems: FieldValue;
  resources: FieldValue;
}

class PlayerFirebaseEntity {
  hpTotal = PlayerHpEntity.hpTotal;
  lifeTotal = PlayerHpEntity.lifeTotal;
  killedHp = PlayerHpEntity.hpKilledValue;
  killedLife = PlayerHpEntity.lifeKilledValue;

  getXpValue(currentGunId: string): { xp: xpTypeUpdate } {
    return { xp: { [currentGunId]: increment(1) } };
  }

  getDoneValues(challenge: ChallengeProps): DoneValuesProps {
    const challengeScoreEntity = new ChallengeScoreEntity({
      type: challenge.type,
    });

    return {
      px: increment(1),
      score: increment(challengeScoreEntity.gained()),
    };
  }

  getHitValues(values: { hp: number; life: number }): HitValuesProps {
    const hp = this.getHp(values.hp);
    const life = this.getLife(values.life);
    const gameOver = this.isGameOver(life);
    let data: HitValuesProps = {
      hp,
      life,
      gameOver,
    };

    if (this.hasBeenKilled(hp)) data.killed = increment(1);
    if (gameOver) data = { ...data, ...this.getGameOverValues() };

    return data;
  }

  getRestartValues(): RestartValuesProps {
    const spaceshipItemsEntity = new SpaceshipItemsEntity();
    const { items, gunId } = spaceshipItemsEntity.initialItems;

    return {
      gunId,
      gameOver: false,
      hp: this.hpTotal,
      shopItems: items,
      life: this.lifeTotal,
    };
  }

  getTryAgainValues({ life }: { life: number }): TryAgainValuesProps {
    return {
      life,
      hp: this.hpTotal,
    };
  }

  getHelpValues(): { help: FieldValue } {
    return { help: increment(1) };
  }

  getMissedValues(): { missed: FieldValue } {
    return { missed: increment(1) };
  }

  private getGameOverValues(): GameOverValuesProps {
    return {
      gunId: "",
      xp: deleteField(),
      px: deleteField(),
      shopItems: deleteField(),
      resources: deleteField(),
    };
  }

  private getHp(hp: number): number {
    return hp <= 0 ? this.killedHp : hp;
  }

  private getLife(life: number): number {
    return life <= 0 ? this.killedLife : life;
  }

  private hasBeenKilled(hp: number): boolean {
    return hp === this.killedHp;
  }

  private isGameOver(life: number): boolean {
    return life === this.killedLife;
  }
}

export default PlayerFirebaseEntity;
