// react
import { useState } from "react";

// components
import FormHeader from "components/form/FormHeader";
import { DropZoneAttentionWhore } from "components/DropZone";
import SpaceshipFeatures from "components/presentations/spaceship/SpaceshipFeatures";

// constants
import {
  JARVIS_LEVELS,
  JARVIS_RADAR_INITIAL_VALUE,
  JARVIS_RELOAD_INITIAL_VALUE,
  JARVIS_BULLETS_INITIAL_VALUE,
} from "constants/jarvis";

// interfaces
import { DataCacheProps } from "components/presentations/Customization";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface CustomizeSpaceshipProps {
  goBack(): void;
  goForward(): void;
  cache: DataCacheProps;
}

const SpaceshipFeaturesHandler = ({
  cache,
  goBack,
  goForward,
}: CustomizeSpaceshipProps) => {
  const [jarvisPoints, setJarvisPoints] = useState(getJarvisPoints());
  // const [attentionWhore, setAttentionWhore] =
  //   useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  // const [attentionWhores, setAttentionWhores] = useState<AttentionWhoreProps[]>(
  //   []
  // );

  function proceed() {
    setTimeout(() => goForward());
  }

  // ATTENTION WHORES

  // function first(): AttentionWhoreProps {
  //   const payload =
  //     "O Todo Poderoso uma vez falou que o melhor filme de ação no mundo tridimensional é um com uma dupla de primatas de diferentes tons de pele...";
  //   return message(payload, wrapper);
  // }

  // function second(): AttentionWhoreProps {
  //   const payload =
  //     "Pois bem, como nesse filme... a partir de agora... somos uma dupla...";
  //   return message(payload, wrapper);
  // }

  // function third(): AttentionWhoreProps {
  //   const payload =
  //     "Para eu atirar, preciso que você mire no alvo a uma determinada distância (de acordo com a habilidade de Distância de Detecção)... de resto, deixa com o papai aqui...";
  //   return message(payload, wrapper);
  // }

  // function four(): AttentionWhoreProps {
  //   const payload =
  //     "E antes que eu me esqueça... O software da espaçonave é acoplado ao meu sistema... De um lado posso evoluir esse software... mas de outro, ele pode ser destruído junto com a espaçonave... zerado, isso é, volta ao modo de fábrica...";
  //   return message(payload, wrapper, true);
  // }

  // function message(
  //   payload: string,
  //   wrapper: HTMLDivElement | undefined,
  //   last?: boolean
  // ): AttentionWhoreProps {
  //   if (!wrapper) return attentionWhoreUtils.fake();

  //   return attentionWhoreUtils.get({
  //     last,
  //     payload,
  //     WhoreEl: IconWhoreMessage,
  //     call: onAttentionWhoreCall,
  //     dropZoneBorderColor: "yellow",
  //     bubbleType: BubbleType.Message,
  //     wrapperWidth: wrapper.clientWidth,
  //     wrapperHeight: wrapper.clientHeight,
  //   });
  // }

  // function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
  //   setAttentionWhore({ ...attentionWhore, show: true });
  // }

  // // ATTENTION WHORES END

  // function hideJarvisBubble() {
  //   if (!attentionWhore) return;

  //   setAttentionWhore({
  //     ...attentionWhore,
  //     show: false,
  //   });

  //   setTimeout(() => {
  //     attentionWhore.last && close();
  //   });
  // }

  function getJarvisPoints(): number {
    return (
      JARVIS_LEVELS.zero.totalPoints -
      (JARVIS_BULLETS_INITIAL_VALUE +
        JARVIS_RELOAD_INITIAL_VALUE +
        JARVIS_RADAR_INITIAL_VALUE)
    );
  }

  return (
    <Wrapper>
      {/* <AttentionWhores
        whores={attentionWhores}
        disabledDrop={!!attentionWhore.show}
      /> */}

      <FormHeader
        back={goBack}
        proceed={proceed}
        disabled={jarvisPoints !== 0}
      />

      {/* <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      /> */}

      <SpaceshipFeatures
        cache={cache}
        jarvisPoints={jarvisPoints}
        setJarvisPoints={setJarvisPoints}
        jarvisTotalPoints={JARVIS_LEVELS.zero.totalPoints}
      />

      <DropZoneAttentionWhore />
    </Wrapper>
  );
};

export default SpaceshipFeaturesHandler;
