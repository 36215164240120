// components
import Code from "components/Code";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  height: 100%;
`;

interface ChallengeCodeProps {
  language: string;
  code: string;
  wrap?: boolean;
}

const ChallengeCode = ({
  language,
  code,
  wrap = false,
}: ChallengeCodeProps) => {
  return (
    <Wrapper>
      <Code
        wrap={wrap}
        language={language}
        code={code}
        style={{ height: "100%", margin: 0 }}
        showLineNumbers={false}
        tomorrowStyle
      />
    </Wrapper>
  );
};

export default ChallengeCode;
