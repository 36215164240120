// components
import PressButton from "components/pressButtons/PressButton";

// utils
import color from "styles/color";

interface PressButtonProps {
  onClick: () => void;
}

const PressButtonGameOver = ({ onClick }: PressButtonProps) => {
  return (
    <PressButton
      onClick={onClick}
      color={color.red}
      title="Game Over. Deseja começar novamente?"
    />
  );
};

export default PressButtonGameOver;
