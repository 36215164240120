export enum StoryFlowType {
  None = 0,
  FlowStarted,
  FlowFinished,
}

export enum StoryWhoType {
  None = 0,
  Jarvis,
  God,
}
