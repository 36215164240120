// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const EyeIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M109.162 174.424h32.631v32.631h-32.631zM239.685 109.162h32.631v32.631h-32.631zM207.054 109.162h32.631v32.631h-32.631zM174.424 141.793h32.631v32.631h-32.631zM141.793 141.793h32.631v32.631h-32.631zM304.946 141.793h32.631v32.631h-32.631z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M337.576 141.793h32.631v32.631h-32.631zM272.315 109.162h32.631v32.631h-32.631zM239.685 174.424h32.631v32.631h-32.631zM207.054 207.054h32.631v32.631h-32.631z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M272.315 207.054h32.631v32.631h-32.631zM174.424 239.685h32.631v32.631h-32.631zM304.946 239.685h32.631v32.631h-32.631zM239.685 304.946h32.631v32.631h-32.631z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M207.054 272.315h32.631v32.631h-32.631zM272.315 272.315h32.631v32.631h-32.631zM43.901 239.685h32.631v32.631H43.901zM435.468 239.685h32.631v32.631h-32.631zM402.838 207.054h32.631v32.631h-32.631zM76.532 207.054h32.631v32.631H76.532zM370.207 174.424h32.631v32.631h-32.631zM402.837 337.576h-32.631v-32.631h32.631zM272.315 402.837h-32.631v-32.631h32.631zM304.946 402.837h-32.631v-32.631h32.631z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M337.576 370.207h-32.631v-32.631h32.631zM370.207 370.207h-32.631v-32.631h32.631zM207.054 370.207h-32.631v-32.631h32.631z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M174.424 370.207h-32.631v-32.631h32.631zM239.685 402.837h-32.631v-32.631h32.631zM109.162 304.946H76.531v-32.631h32.631zM435.468 304.946h-32.631v-32.631h32.631zM141.794 337.576h-32.631v-32.631h32.631z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default EyeIcon;
