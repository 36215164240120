// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// icons
import PlaceholderIcon from "icons/PlaceholderIcon";

// components
import SubHeaderWrapper from "components/header/SubHeaderWrapper";

// utils
import color from "styles/color";
import styled from "styled-components";

const Status = styled.div`
  display: flex;
  align-items: center;

  p {
    all: unset;
    font-size: 20px;
    color: ${color.grey.light};

    .level {
      display: inline-block;
      color: ${color.orange};
      font-weight: bold;
      margin-left: 10px;
    }
  }
`;

const PixelWidget = () => {
  const player = useSelector(s.player());

  return (
    <SubHeaderWrapper>
      <PlaceholderIcon width="18" height="18" />

      <Status className="pixelify_font_family">
        <p>
          <span className="separator">[</span>
          {player.px}
          <span className="separator">]</span>
          <span className="level animate__animated animate__heartBeat">px</span>
        </p>
      </Status>
    </SubHeaderWrapper>
  );
};

export default PixelWidget;
