// enums
import { SpaceshipPartType } from "enums/spaceInvaders/spaceshipEnum";

const TOTAL_LIFE_ENGINE = 1;
const TOTAL_LIFE_WING = 2;
const TOTAL_LIFE_EXTERNAL = 3;

class PlayerHpEntity {
  private _hp: number;
  private _life: number;
  private _hpToRecover: number;
  private _spaceshipPart: SpaceshipPartType;

  static hpTotal = 3;
  static hpKilledValue = -100;
  static hpNotSetValue = -1000;

  static lifeTotal = 3;
  static lifeKilledValue = -100;
  static lifeNotSetValue = -1000;

  constructor({ hp, life }: { hp: number; life: number }) {
    this._hp = hp;
    this._life = life;
  }

  get hp(): number {
    return this._hp;
  }

  get life(): number {
    return this._life;
  }

  get recoveredHp(): number {
    const hpToRecover = this._hpToRecover || this.hpToRecover;
    const playerHp = this._hp + hpToRecover;

    return playerHp > PlayerHpEntity.hpTotal
      ? PlayerHpEntity.hpTotal
      : playerHp;
  }

  get recoveredLife(): number {
    const spaceshipPart = this._spaceshipPart || this.spaceshipPart;

    return spaceshipPart === SpaceshipPartType.Engine
      ? TOTAL_LIFE_ENGINE
      : spaceshipPart === SpaceshipPartType.Wing
      ? TOTAL_LIFE_WING
      : TOTAL_LIFE_EXTERNAL;
  }

  get spaceshipPart(): SpaceshipPartType {
    if (this._life === TOTAL_LIFE_ENGINE) return SpaceshipPartType.Engine;
    if (this._life === TOTAL_LIFE_WING) return SpaceshipPartType.Wing;

    return SpaceshipPartType.External;
  }

  get nextSpaceshipPart(): SpaceshipPartType | undefined {
    const type = this.spaceshipPart;

    if (type === SpaceshipPartType.Engine) return SpaceshipPartType.Wing;
    if (type === SpaceshipPartType.Wing) return SpaceshipPartType.External;

    return undefined;
  }

  get needRecover(): boolean {
    return (
      this._hp < PlayerHpEntity.hpTotal || this._life < PlayerHpEntity.lifeTotal
    );
  }

  get hpToRecover(): number {
    return PlayerHpEntity.hpTotal - this._hp;
  }

  set spaceshipPart(type: SpaceshipPartType) {
    this._spaceshipPart = type;
  }

  set hpToRecover(hpToRecover: number) {
    this._hpToRecover = hpToRecover;
  }
}

export default PlayerHpEntity;
