// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import {
  ChallengeFirebaseProps,
  ChallengeFirebaseUpdateProps,
} from "interfaces/challengeFirebase";

class PlayerChallengeFirebaseService {
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async getCollection(
    userId: number | string,
    classRoomId: number
  ): Promise<ChallengeFirebaseProps[]> {
    const path = `players/${userId}/classRooms/${classRoomId}/challenges`;
    const response = await this._firebase.getByCollection(path);

    return response.docs.map((doc) => doc.data() as ChallengeFirebaseProps);
  }

  async update(
    id: number,
    userId: number | string,
    classRoomId: number,
    values: ChallengeFirebaseUpdateProps
  ): Promise<void> {
    if (!id) return Promise.reject();
    if (!userId) return Promise.reject();
    if (!classRoomId) return Promise.reject();

    const path = `players/${userId}/classRooms/${classRoomId}/challenges/${id}`;
    return this._firebase.set(path, values);
  }
}

export default PlayerChallengeFirebaseService;
