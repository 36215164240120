// redux
import { createSlice } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import { ChallengeFlowProps } from "interfaces/challengeFlow";

const initialState: ChallengeFlowProps = {
  paused: true,
  freezed: false,
};

export const challengeFlowSlice = createSlice({
  name: "challengeFlow",
  initialState,
  reducers: {
    pause: (state) => {
      state.paused = true;
    },
    unpause: (state) => {
      state.paused = false;
    },
    freeze: (state) => {
      state.freezed = true;
    },
    unfreeze: (state) => {
      state.freezed = false;
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const challengeFlowActions = challengeFlowSlice.actions;
export default challengeFlowSlice.reducer;
