// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const ReloadPixelSvg = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 12.7 12.7"
    >
      <g>
        <path
          d="M2.645.794v1.058h1.06V.794zm1.06 1.058v1.06h1.058v-1.06zm1.058 1.06v1.057h1.059V2.911zm-2.118-1.06H1.587v1.06h1.058v-1.06zm-1.058 1.06H.528v1.057h1.06V2.911zm5.29 0v1.057h2.118V2.911zm2.118 1.057v4.762h1.059V3.97zm-6.35 0v4.762h1.06V3.97zm1.06 4.762V9.79h2.117V8.73zm3.173 0V9.79h1.059V8.73zM7.937 9.79v1.059h1.058v-1.06zm1.058 1.059v1.058h1.059v-1.058zm1.059 0h1.058v-1.06h-1.058zm1.058-1.06h1.059V8.732h-1.059z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ReloadPixelSvg;
