// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// firestore
import { serverTimestamp } from "firebase/firestore";

// svgs
import MineralStoragePixelSvg from "svg/MineralStoragePixelSvg";

interface IconProps {
  width?: string;
  height?: string;
}

const Icon = ({ width = "35", height = "35" }: IconProps) => {
  return <MineralStoragePixelSvg width={width} height={height} />;
};

export default class MineralStorageItemEntity extends AbstractShopItemEntity {
  readonly id = 3;
  readonly price = 2;
  readonly capacity = 5;
  readonly subTitle = "";
  readonly maxQuantity = 6;
  readonly available = false;
  readonly icon = (<Icon />);
  readonly bubbles = this.shopBubbles;
  readonly title = "Armazém de minérios";
  readonly type = SpaceshipItemEnum.MineralStorage;
  readonly image = (<Icon width="45" height="45" />);
  readonly lifeCycles = AbstractShopItemEntity.lifeCyclesNone;

  get item(): ShopItemProps {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      price: this.price,
      bubbles: this.bubbles,
      subTitle: this.subTitle,
      capacity: this.capacity,
      available: this.available,
      lifeCycles: this.lifeCycles,
    };
  }

  get values(): PlayerShopItemsFirebaseProps {
    return {
      [this.getItemId(this.type)]: {
        t: this.type,
        c: this.capacity,
        l: this.lifeCycles,
        s: serverTimestamp(),
      },
    };
  }

  get resourcesPrice(): SpaceshipResourcesQtyProps {
    return {
      sand: 1,
      gold: 1,
      water: 1,
      silver: 0,
      copper: 2,
      estelin: 0,
      bauxite: 1,
      strongForce: 1,
    };
  }

  // get resourcesPrice(): SpaceshipResourcesQtyProps {
  //   return {
  //     sand: 0,
  //     gold: 0,
  //     water: 0,
  //     silver: 0,
  //     copper: 0,
  //     estelin: 0,
  //     bauxite: 1,
  //     strongForce: 0,
  //   };
  // }

  getIcon({ width, height }: IconProps): JSX.Element {
    return <Icon width={width} height={height} />;
  }

  private get shopBubbles(): ShopItemBubbleProps[] {
    return [
      {
        id: 1,
        itemId: this.id,
        question: `Qual a capacidade? (${this.capacity})`,
        answer: `Querido primata, cada armazém de minérios tem capacidade para armazenar até ${this.capacity} minérios.`,
      },
      {
        id: 2,
        itemId: this.id,
        question: "É possível aumentar a capacidade?",
        answer: `Com certeza! Assim como os refinadores, a capacidade de armazenamento de minérios pode ser ampliada com a compra de novos armazéns... Com a quantidade máxima de ${
          this.maxQuantity
        } armazéns, a capacidade máxima é ampliada para até ${
          this.capacity * this.maxQuantity
        } minérios a serem armazenados...`,
      },
      {
        id: 3,
        itemId: this.id,
        question: "Quantos ciclos?",
        answer: `O número de ciclos é tão GIGANTESCO que é praticamente infinito... Isso significa que você jamais terá que se preocupar em adquirir outro armazém de minérios. [a não ser, é claro, que você goste explodir a própria espaçonave]`,
      },
      {
        id: 4,
        itemId: this.id,
        question: "Para que serve?",
        answer: `Hmmm, bem... o armazém de minérios tem como finalidade... armazenar minérios...`,
      },
      {
        id: 4,
        itemId: this.id,
        question: "E porque eu iria querer armazenar minérios?",
        answer: `Os minérios uma vez refinados são matérias primas para a construção e manutenção de novos itens, assim como reparo de sua espaçonave... caso você deseja ser um EXPLORADOR, será ESSENCIAL ter a máxima quantidade possível de armazéns de minérios.`,
      },
    ];
  }
}
