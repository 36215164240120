// interfaces
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import {
  SpaceshipItemProps,
  SpaceshipItemsProps,
} from "interfaces/spaceInvaders/spaceshipItem";
import {
  PlayerShopItemsFirebaseProps,
  PlayerShopItemFirebaseProps,
} from "interfaces/shopFirebase";

// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// utils
import shopItemUtils from "utils/shopItemUtils";

function all(player: PlayerFirebaseProps): SpaceshipItemProps[] {
  const { shopItems } = player;

  if (!shopItems) return [];
  return mapItems(shopItems);
}

function hash(player: PlayerFirebaseProps): SpaceshipItemsProps {
  const { shopItems } = player;

  if (!shopItems) return {};
  return hashShopItems(shopItems);
}

function hashShopItems(
  shopItems: PlayerShopItemsFirebaseProps
): SpaceshipItemsProps {
  return Object.keys(shopItems).reduce((acc, key) => {
    acc[key] = mapItem(key, shopItems[key]);
    return acc;
  }, {} as SpaceshipItemsProps);
}

function mapItems(
  shopItems: PlayerShopItemsFirebaseProps
): SpaceshipItemProps[] {
  return Object.keys(shopItems).reduce((acc, key) => {
    acc.push(mapItem(key, shopItems[key]));
    return acc;
  }, [] as SpaceshipItemProps[]);
}

function mapItem(
  id: string,
  item: PlayerShopItemFirebaseProps
): SpaceshipItemProps {
  const { c, t, l } = item;

  return {
    id,
    type: t,
    capacity: c,
    lifeCycles: l,
    title: getTitle(t),
    timestamp: getCreatedAt(item),
  };
}

function getCreatedAt(item: PlayerShopItemFirebaseProps): number {
  const c = item.s as { seconds: number } | undefined;

  if (!c) return Date.now();
  if (!c.seconds) return Date.now();

  return c.seconds;
}

function getTitle(type: SpaceshipItemEnum): string {
  const Entity = shopItemUtils.getEntity(type);
  const entity = new Entity();

  return entity.title;
}

const spaceshipItemsParser = {
  all,
  hash,
};

export default spaceshipItemsParser;
