// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { ChallengeCommonProps } from "interfaces/challenge";

// enums
import { BubbleType } from "enums/bubbleEnum";

// components
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// icons
import IconWhoreAlert from "components/iconWhores/IconWhoreAlert";
import IconWhoreWizardHat from "components/iconWhores/IconWhoreWizardHat";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface JarvisBubbleIntroHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
}

const JarvisBubbleIntroHandler = ({
  paused,
  wrapper,
  challenge,
}: JarvisBubbleIntroHandlerProps) => {
  const dispatch = useDispatch();
  const alreadyStarted = useRef(false);
  const checkpoints = useSelector(s.playerCheckpoints());
  const [whoresWizard, setWhoresWizard] = useState<AttentionWhoreProps[]>([]);
  const [whoresMessage, setWhoresMessage] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const { Jarvis, QuestionBubbleIntro } = PlayerCheckpointEnum;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [checkpoints]);

  function bootstrap() {
    if (alreadyStarted.current) return;

    setTimeout(() => {
      if (!checkpoints[Jarvis]) setWhoresMessage([first()]);
      if (!checkpoints[QuestionBubbleIntro])
        setWhoresWizard([oneWizard(), twoWizard()]);

      alreadyStarted.current = true;
    });
  }

  function first() {
    return getMessage(
      `Não se aproxime da BARREIRA ROSA acima! Não sabemos muito bem por que ela surgiu ou por que afeta apenas as espaçonaves, mas teremos que eliminá-la pixel por pixel daqui em diante...`,
      true
    );
  }

  function oneWizard() {
    return getMessageToWizard(
      `Os desafios são divididos em três modos: Introdução, Batalha e Quiz. No modo atual (introdução), um desafio de programação será exibido após a leitura de todas as falas destacadas em laranja.`
    );
  }

  function twoWizard() {
    return getMessageToWizard(
      `Fique atento aos botões na tela... Você pode pausar o desafio, alterar os armamentos da espaçonave, exibir novamente a descrição do desafio, entre outras ações...`
    );
  }

  function getMessage(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreAlert,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "red",
      wrapperWidth: wrapper.clientWidth,
      bubbleType: BubbleType.JarvisAlert,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function getMessageToWizard(
    payload: string,
    last?: boolean
  ): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: IconWhoreWizardHat,
      dropZoneBorderColor: "blue",
      bubbleType: BubbleType.Narrator,
      call: onAttentionWhoreWizardCall,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function onAttentionWhoreWizardCall(attentionWhore: AttentionWhoreProps) {
    if (attentionWhore.last) syncCheckpoint();
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function syncCheckpoint() {
    dispatch(
      playerActions.async.updateCheckpoint({
        [QuestionBubbleIntro]: true,
      })
    );
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  return (
    <>
      <AttentionWhores
        paused={paused}
        whores={whoresWizard}
        clear={challenge.flowDone}
        disabledDrop={!!attentionWhore.show}
      />
      <AttentionWhores
        paused={paused}
        whores={whoresMessage}
        clear={challenge.flowDone}
        disabledDrop={!!attentionWhore.show}
      />

      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <DropZoneAttentionWhore />
    </>
  );
};

export default JarvisBubbleIntroHandler;
