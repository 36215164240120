// components
import PlayerProfileMap from "components/playerProfile/PlayerProfileMap";

interface PlayerProfileMapSlideProps {
  youAreHereClick: (info: string) => void;
  astrogildoClick: (info: string) => void;
}

const PlayerProfileMapSlide = ({
  youAreHereClick,
  astrogildoClick,
}: PlayerProfileMapSlideProps) => {
  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_purple">Mapa</h1>
      </div>

      <PlayerProfileMap
        astrogildoClick={astrogildoClick}
        youAreHereClick={youAreHereClick}
      />
    </div>
  );
};

export default PlayerProfileMapSlide;
