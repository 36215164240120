// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { bulletsActions } from "redux/slices/spaceInvaders/bulletsSlice";
import { spaceshipMachineGunActions } from "redux/slices/spaceInvaders/spaceshipMachineGunSlice";

// utils
import stateUtils from "redux/utils/stateUtils";

// services
import PlayerSpaceshipDataFirebaseService from "services/firebase/player/PlayerSpaceshipDataFirebaseService";

// enums
import { SpaceshipMachineGunEnum } from "enums/spaceInvaders/spaceshipMachineGunEnum";

// TODO: THIS CONST IS GLOBAL (WARNING)
export const spaceshipPosition = { x: 0, y: 0 };

const spaceshipMachineGunMiddleware = createListenerMiddleware();

spaceshipMachineGunMiddleware.startListening({
  actionCreator: spaceshipMachineGunActions.async.fill,
  effect: async (_, listenerApi) => {
    const { spaceshipFeatures } = stateUtils.get(listenerApi);

    listenerApi.dispatch(
      spaceshipMachineGunActions.update({
        bullets: spaceshipFeatures.bulletsRaw,
      })
    );
  },
});

spaceshipMachineGunMiddleware.startListening({
  actionCreator: spaceshipMachineGunActions.async.reload,
  effect: async ({ payload }, listenerApi) => {
    payload.state.current += 1;

    listenerApi.dispatch(
      spaceshipMachineGunActions.update({
        bullets: payload.state.current,
      })
    );
  },
});

spaceshipMachineGunMiddleware.startListening({
  actionCreator: spaceshipMachineGunActions.async.target,
  effect: async ({ payload }, listenerApi) => {
    const { spaceshipMachineGun } = stateUtils.get(listenerApi);

    if (spaceshipMachineGun.machineGun === SpaceshipMachineGunEnum.None) {
      payload.state.current = false;

      listenerApi.dispatch(
        spaceshipMachineGunActions.update({
          targetVisible: false,
        })
      );

      return;
    }

    payload.state.current = payload.visible;

    listenerApi.dispatch(
      spaceshipMachineGunActions.update({
        targetVisible: payload.visible,
      })
    );
  },
});

spaceshipMachineGunMiddleware.startListening({
  actionCreator: spaceshipMachineGunActions.async.fire,
  effect: async (_, listenerApi) => {
    const { spaceshipMachineGun } = stateUtils.get(listenerApi);

    if (spaceshipMachineGun.machineGun === SpaceshipMachineGunEnum.None) return;

    const x = spaceshipPosition.x;
    const y = spaceshipPosition.y;

    listenerApi.dispatch(bulletsActions.async.fire({ x, y }));
  },
});

spaceshipMachineGunMiddleware.startListening({
  actionCreator: spaceshipMachineGunActions.async.removeBullets,
  effect: async ({ payload }, listenerApi) => {
    const { spaceshipMachineGun } = stateUtils.get(listenerApi);

    if (spaceshipMachineGun.machineGun === SpaceshipMachineGunEnum.None) return;

    payload.state.current -= 1;

    listenerApi.dispatch(
      spaceshipMachineGunActions.update({
        bullets: payload.state.current,
      })
    );
  },
});

spaceshipMachineGunMiddleware.startListening({
  actionCreator: spaceshipMachineGunActions.async.updateMachineGun,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);

    if (!auth.signed) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    const resource = new PlayerSpaceshipDataFirebaseService();
    resource.update(auth.user.id, { machineGun: payload.machineGun });
  },
});

export default spaceshipMachineGunMiddleware;
