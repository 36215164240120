// react
import { IonicSlides } from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// interfaces
import { ItemProps } from "interfaces/item";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";
import { DotProgressRefProps } from "components/SlideDotsProgressVertical";

// components
import PlayerProfileSideMenu from "components/playerProfile/PlayerProfileSideMenu";
import PlayerProfileMapSlide from "components/playerProfile/PlayerProfileMapSlide";
import PlayerProfileItemsSlide from "components/playerProfile/PlayerProfileItemsSlide";
import PlayerProfileStatusSlide from "components/playerProfile/PlayerProfileStatusSlide";
import PlayerProfileSummarySlide from "components/playerProfile/PlayerProfileSummarySlide";
import PlayerProfileMonstersSlide from "components/playerProfile/PlayerProfileMonstersSlide";
import PlayerProfileCompaniesSlide from "components/playerProfile/PlayerProfileCompaniesSlide";
import PlayerProfileResourcesSlide from "components/playerProfile/PlayerProfileResourcesSlide";
import PlayerProfileCharactersSlide from "components/playerProfile/PlayerProfileCharactersSlide";
import PlayerProfileCivilizationsSlide from "components/playerProfile/PlayerProfileCivilizationsSlide";

// entities
import {
  InvaderOneEntity,
  InvaderSixEntity,
  InvaderTwoEntity,
  InvaderFiveEntity,
  InvaderFourEntity,
  InvaderThreeEntity,
  InvaderSevenEntity,
} from "entities/spaceInvaders/InvaderEntity";

// enums
import { BubbleType } from "enums/bubbleEnum";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";
import PlayerProfileAlertsHandler from "components/playerProfile/PlayerProfileAlertsHandler";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// utils
import color from "styles/color";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;

    h1,
    h2,
    h3 {
      margin: 0;
      font-size: 23px;
      text-align: center;
    }

    p,
    li {
      font-size: 21px;
    }

    .opacity {
      opacity: 0.5;
    }

    > .content_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 70px;
      padding: 0 15px;
    }

    .slide {
      flex: 1;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 70px;
      }

      .header_nerdbook {
        height: 70px;
        padding: 0 20px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 5px;

        .body_header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 150px !important;

          .margin_top {
            margin: 6px 0;
          }
        }

        .summary_context {
          .done_icon_wrapper {
            display: inline-block;
            margin-left: 5px;
          }

          ol {
            all: unset;
            display: flex;
            flex-direction: column;
            margin: 0 20px;

            li {
              color: ${color.grey.middle};
              margin: 10px 0;
              padding: 0;
            }
          }
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }

        &.player_profile > ul {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 20px;

          > li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 50px;
          }
        }

        .slides_body {
          flex: 1;
          display: flex;
          flex-direction: column;

          .slide_body {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              padding: 0 20px;
              margin: 10px 0;
              font-size: 22px;
              text-align: justify;
              color: ${color.grey.middle};

              &.none_desc {
                color: ${color.grey.stronger};
              }
            }

            .invader_one_color {
              color: ${InvaderOneEntity.color};
            }

            .invader_two_color {
              color: ${InvaderTwoEntity.color};
            }

            .invader_three_color {
              color: ${InvaderThreeEntity.color};
            }

            .invader_four_color {
              color: ${InvaderFourEntity.color};
            }

            .invader_five_color {
              color: ${InvaderFiveEntity.color};
            }

            .invader_six_color {
              color: ${InvaderSixEntity.color};
            }

            .invader_seven_color {
              color: ${InvaderSevenEntity.color};
            }
          }
        }
      }
    }

    .items_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 5px 10px 5px;

      ul.items {
        all: unset;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          margin: 3px 8px;
          opacity: 0.2;

          &.active {
            opacity: 1;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
`;

const BubbleOneSubHeader = ({ content }: { content: string }) => {
  return <p className="bubble_question_subheader nerdfy_yellow">{content}</p>;
};

const BubbleTwoSubHeader = ({ content }: { content: string }) => {
  return <p className="bubble_question_subheader nerdfy_green">{content}</p>;
};

export const NoneDescription = () => (
  <p className="none_desc">Descrição ainda não disponível...</p>
);

export interface ListItemProps {
  item: ItemProps;
  shadow: ItemProps;
}

export interface ListItemsProps {
  items: ListItemProps[];
  checkpoints: PlayerCheckpointsStateProps;
  activeIndex?: number;
}

export const ListItems = ({
  items,
  checkpoints,
  activeIndex,
}: ListItemsProps) => {
  const [i, setItems] = useState<ItemProps[]>([]);

  useEffect(mix, [items, checkpoints]);

  function mix() {
    const _items = items
      .reduce((acc, { item, shadow }) => {
        if (!checkpoints[item.checkpointIndex]) {
          acc.push(shadow);
          return acc;
        }

        acc.push(item);
        return acc;
      }, [] as ItemProps[])
      .sort((a, b) => a.index - b.index);

    setItems(_items);
  }

  return (
    <div className="items_wrapper">
      <ul className="items">
        {i.map((item, index) => (
          <li key={index} className={`${activeIndex === index && "active"}`}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

interface PlayerProfileProps {
  isActive: boolean;
  closeModal(): void;
  slideTo(page: number): void;
}

const PlayerProfile = ({
  slideTo,
  isActive,
  closeModal,
}: PlayerProfileProps) => {
  const checkpoints = useSelector(s.playerCheckpoints());
  const swiperRef = useRef<SwiperProps | undefined>();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dotsProgressRef = useRef<DotProgressRefProps>({ 0: true });
  const checkpointsRef = useRef<PlayerCheckpointsStateProps | null>(null);
  const [bubbleOne, setBubbleOne] = useState("");
  const [bubbleTwo, setBubbleTwo] = useState("");
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  const handlerWrapperRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setTimeout(() => {
      wrapperRef.current = node;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(listenCheckpoints, [checkpoints]);

  function listenCheckpoints() {
    if (isEmpty(checkpoints)) return;
    checkpointsRef.current = checkpoints;
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;

    swiperRef.current = swiper;
    listenToSlideChange(swiperRef.current);
  }

  function go(index: number) {
    swiperRef.current?.slideTo(index);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex, realIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
      updateRealDotPositionRef(realIndex);
    });
  }

  function updateRealDotPositionRef(index: number) {
    if (dotsProgressRef.current[index]) return;
    dotsProgressRef.current = { ...dotsProgressRef.current, [index]: true };
  }

  function hideOneBubble() {
    setBubbleOne("");
  }

  function hideTwoBubble() {
    setBubbleTwo("");
  }

  function youAreHereClick(info: string) {
    setBubbleOne(info);
  }

  function astrogildoClick(info: string) {
    setBubbleTwo(info);
  }

  return (
    <Wrapper ref={handlerWrapperRef}>
      <PlayerProfileSideMenu goTo={go} slideActiveIndex={slideActiveIndex} />

      <div className="content">
        <Swiper
          direction={"vertical"}
          initialSlide={0}
          slidesPerView={1}
          onSwiper={handleSwiper}
          noSwipingClass="no_swipe"
          modules={[IonicSlides]}
        >
          <SwiperSlide>
            <PlayerProfileStatusSlide closeModal={closeModal} />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileSummarySlide />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileItemsSlide />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileResourcesSlide />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileMapSlide
              youAreHereClick={youAreHereClick}
              astrogildoClick={astrogildoClick}
            />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileMonstersSlide checkpoints={checkpoints} />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileCivilizationsSlide checkpoints={checkpoints} />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileCompaniesSlide checkpoints={checkpoints} />
          </SwiperSlide>

          <SwiperSlide>
            <PlayerProfileCharactersSlide checkpoints={checkpoints} />
          </SwiperSlide>
        </Swiper>
      </div>

      <PlayerProfileAlertsHandler start={isActive} wrapperRef={wrapperRef} />

      <BubbleHandler
        show={!!bubbleOne}
        content={bubbleOne}
        hide={hideOneBubble}
        type={BubbleType.Info}
        subHeader={<BubbleOneSubHeader content="Você está aqui" />}
      />

      <BubbleHandler
        hide={hideTwoBubble}
        show={!!bubbleTwo}
        content={bubbleTwo}
        type={BubbleType.Info}
        subHeader={
          <BubbleTwoSubHeader content="Última mensagem de Astrogildo" />
        }
      />
    </Wrapper>
  );
};

export default PlayerProfile;
