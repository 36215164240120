// react
import React from "react";

// utils
import styled from "styled-components";
import color from "styles/color";

const LoadingStyled = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: auto;

  ${(data: any) =>
    data &&
    data.absolute &&
    `
    position: absolute;
  `};

  &.spinner {
    width: 100px;
    z-index: 9501;
    text-align: center;

    > div {
      width: 10px;
      height: 10px;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Mask = styled.div`
  width: 100%;
  height: 100%;
  background: ${color.grey.middle};
  z-index: 9500;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;

  ${(data: any) =>
    data &&
    data.absolute &&
    `
    position: absolute;
  `};
`;

const HideMask = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9500;
  position: fixed;
  top: 0;
  left: 0;

  ${(data: any) =>
    data &&
    data.absolute &&
    `
    position: absolute;
  `};
`;

interface Props {
  loading: boolean;
  absolute?: boolean;
  hideMask?: boolean;
}

const Loading = ({ loading, absolute, hideMask }: Props) => {
  const spread = { absolute };

  return (
    <>
      {loading && (
        <>
          <LoadingStyled className="spinner" {...spread}>
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </LoadingStyled>

          {!hideMask && <Mask className="" {...spread} />}
          {hideMask && <HideMask className="" {...spread} />}
        </>
      )}
    </>
  );
};

export default React.memo(Loading);
