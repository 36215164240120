// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
}

const SpaceshipIcon = ({
  width = "18",
  height = "18",
  color = c.grey.stronger,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M496 320v-16h-16V184h-16v120h-16v16h16v16a16 16 0 0 0-13.835 8h-84.33A16 16 0 0 0 352 336h-32c-.353 0-.7.03-1.045.053l-14.973-224.585h-.007a7.964 7.964 0 0 0-.13-1.037l-11.039-55.192a51.7 51.7 0 0 0-34.277-38.828 7.986 7.986 0 0 0-5.058 0 51.7 51.7 0 0 0-34.277 38.828l-11.039 55.193a7.964 7.964 0 0 0-.13 1.037l-14.98 224.585c-.347-.023-.692-.053-1.045-.053h-32a16 16 0 0 0-13.835 8h-84.33A16 16 0 0 0 48 336v-16h16v-16H48V184H32v120H16v16h16v16a16.019 16.019 0 0 0-16 16v72a8 8 0 0 0 8 8h8v16h16v-16h8a8 8 0 0 0 8-8v-1.87l67.884 40.73a8 8 0 0 0 6.646.729L152 459.1V472a8 8 0 0 0 8 8h8v16h16v-16h8a8 8 0 0 0 8-8v-36.686l26.343 26.343A8 8 0 0 0 232 464h48a8 8 0 0 0 5.657-2.343L312 435.314V472a8 8 0 0 0 8 8h8v16h16v-16h8a8 8 0 0 0 8-8v-12.9l13.47 4.49a8 8 0 0 0 6.646-.729L448 422.13V424a8 8 0 0 0 8 8h8v16h16v-16h8a8 8 0 0 0 8-8v-72a16.019 16.019 0 0 0-16-16v-16Zm-16 31.99V368h-16v-16ZM352 416h-32v-32h32Zm-72.155-1.569A8 8 0 0 0 272 408h-32a8 8 0 0 0-7.845 6.431l-5.068 25.342L208 420.686v-68.417L223.485 120H240a8 8 0 0 0 7.59-5.47L253.766 96h4.468l6.176 18.53A8 8 0 0 0 272 120h16.515L304 352.269v68.417l-19.087 19.087ZM270.241 448h-28.482l4.8-24h18.882ZM160 416v-32h32v32Zm192-64.01V368h-32v-16ZM234.884 58.376A35.675 35.675 0 0 1 256 32.542a35.675 35.675 0 0 1 21.116 25.834L286.241 104h-8.475l-6.176-18.53A8 8 0 0 0 264 80h-16a8 8 0 0 0-7.59 5.47L234.234 104h-8.475ZM192 352v16h-32v-16Zm-144 0v16H32v-16Zm-16 64v-32h16v32Zm104.961 31.247L64 403.47V360h40v20.687l-13.657 13.656 11.314 11.314 16-16A8 8 0 0 0 120 384v-24h24v64a8 8 0 0 0 8 8v10.234ZM168 464v-32h16v32Zm160 0v-32h16v32Zm47.039-16.753L360 442.234V432a8 8 0 0 0 8-8v-64h24v24a8 8 0 0 0 2.343 5.657l16 16 11.314-11.314L408 380.687V360h40v43.47ZM464 416v-32h16v32Z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M256 216a24.028 24.028 0 0 0-24 24v80a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-80a24.028 24.028 0 0 0-24-24Zm-8 24a8 8 0 0 1 16 0v40h-16Zm0 72v-16h16v16ZM256 344a24 24 0 1 0 24 24 24.027 24.027 0 0 0-24-24Zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8ZM248 136h16v64h-16zM248 48h16v16h-16z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default SpaceshipIcon;
