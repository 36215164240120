// interfaces
import { ChallengeProps } from "interfaces/challenge";

// entities
import ChallengeScoreEntity from "entities/ChallengeScoreEntity";

// utils
import codeUtils from "utils/code/codeUtils";
import { increment, serverTimestamp, FieldValue } from "firebase/firestore";

interface FlowInitValuesProps {
  id: number;
  type: number;
  languageId: number;
  classRoomId: number;
  userId: string | number;
  challengeSectionId: number;

  // timestamp
  initOn: FieldValue;
}

interface FlowStartedValuesProps {
  // timestamp
  startedOn: FieldValue;
}

interface FlowFinishedValuesProps {
  // timestamp
  finishedOn: FieldValue;
}

interface FlowDoneValuesProps {
  frame: number;
  pxGained: number;
  currentCode: string;
  scoreGained: number;

  // flow
  flowDone: true;
  flowSuccess: true;

  // timestamp
  doneOn: FieldValue;
  successOn: FieldValue;
}

class ChallengeFirebaseEntity {
  getKilledValues(): { killed: FieldValue } {
    return { killed: increment(1) };
  }

  getMissedValues(): { missed: FieldValue } {
    return { missed: increment(1) };
  }

  getHelpedValues(): { help: FieldValue } {
    return { help: increment(1) };
  }

  getFlowInitValues(
    userId: string | number,
    challenge: ChallengeProps
  ): FlowInitValuesProps {
    return {
      userId,
      id: challenge.id,
      type: challenge.type,
      initOn: serverTimestamp(),
      languageId: challenge.languageId,
      classRoomId: challenge.classRoomId,
      challengeSectionId: challenge.challengeSectionId,
    };
  }

  getFlowStartedValues(): FlowStartedValuesProps {
    return {
      startedOn: serverTimestamp(),
    };
  }

  getFlowFinishedValues(): FlowFinishedValuesProps {
    return {
      finishedOn: serverTimestamp(),
    };
  }

  getDoneValues(challenge: ChallengeProps): FlowDoneValuesProps {
    const { type, code, currentCode } = challenge;
    const challengeScoreEntity = new ChallengeScoreEntity({ type });

    return {
      pxGained: 1,
      flowDone: true,
      flowSuccess: true,
      doneOn: serverTimestamp(),
      frame: challenge.frame + 1,
      successOn: serverTimestamp(),
      scoreGained: challengeScoreEntity.gained(),
      currentCode: codeUtils.simple.getFinished(currentCode, code),
    };
  }
}

export default ChallengeFirebaseEntity;
