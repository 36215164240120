// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { tagActions } from "redux/slices/tagSlice";
import { useDispatch, useSelector } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";
import { challengeActions } from "redux/slices/challengeSlice";
import { invadersActions } from "redux/slices/spaceInvaders/invadersSlice";

// components
import ChallengeExercise from "components/challenge/ChallengeExercise";
import ChallengeIntroduction from "components/challenge/ChallengeIntroduction";

// factories
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// interfaces
import { TipProps } from "interfaces/tip";
import { TagProps, SpecialTagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { ChallengeCommonProps, ChallengeProps } from "interfaces/challenge";

// enums
import { ChallengeType } from "enums/challengeEnum";
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

// utils
import blinkUtils from "utils/blinkUtils";
import tipUtils from "utils/code/tipUtils";
import tagStepUtils from "utils/code/tagStepUtils";
import validatorUtils from "utils/code/validatorUtils";

interface ChallengeCommonHandlerProps {
  handlerWrapperRef: (node: HTMLDivElement) => void;
  handlerNotifiersRef: (node: HTMLDivElement) => void;
  reOpenChallenge: (data: ChallengeCommonProps) => void;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  blinkElRef: React.MutableRefObject<HTMLDivElement | null>;
  wrapperNotifiersRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface ChallengeHandlerProps extends ChallengeCommonHandlerProps {
  currentChallengeRef: React.MutableRefObject<ChallengeCommonProps | null>;
}

export interface ChallengeTypeHandlerCommonProps
  extends ChallengeCommonHandlerProps {
  paused: boolean;
  code: ChallengeCodeProps;
  challenge: ChallengeProps;
  onSuccessTag(tag: TagProps): void;
  isTagValid(tag: TagProps): boolean;
}

const Challenge = ({
  wrapperRef,
  blinkElRef,
  reOpenChallenge,
  handlerWrapperRef,
  currentChallengeRef,
  handlerNotifiersRef,
  wrapperNotifiersRef,
}: ChallengeHandlerProps) => {
  const dispatch = useDispatch();
  const code = useSelector(s.challengeCode());
  const challenge = useSelector(s.challenge());
  const { paused } = useSelector(s.challengeFlow());
  const { tips, flowDone, specialTags } = challenge;
  const specialTagsRef = useRef<SpecialTagProps[]>([]);
  const currentStepTags = useRef<TagProps[]>([]);
  const tipsRef = useRef<TipProps[]>([]);
  const currentStepTagIndex = useRef(0);
  const challengeCodeRef = useRef<ChallengeCodeProps | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToCurrentChallenge, [challenge]);
  useEffect(handleCurrentStepTags, [code]);
  useEffect(handleChallengeCode, [code]);
  useEffect(handleCurrentStepTags, [code]);
  useEffect(handleSpecialTags, [specialTags]);
  useEffect(clearCurrentStepTagsIndex, [flowDone]);
  useEffect(listenToTips, [tips]);

  function listenToCurrentChallenge() {
    currentChallengeRef.current = challenge;
  }

  function listenToTips() {
    tipsRef.current = tips;
  }

  function handleCurrentStepTags() {
    currentStepTags.current = getCurrentStepsTags();
  }

  function handleChallengeCode() {
    challengeCodeRef.current = code;
  }

  function handleSpecialTags() {
    specialTagsRef.current = specialTags;
  }

  function clearCurrentStepTagsIndex() {
    if (flowDone) currentStepTagIndex.current = 0;
  }

  function successTag(tag: TagProps) {
    handleTips(tag);

    currentStepTags.current = getCurrentStepsTags();
    currentStepTagIndex.current += 1;

    const specialTags = getSpecialTags(tag, currentStepTags.current);

    updateCurrentSpecialStepTags(specialTags, currentStepTags.current);
    dispatch(tagActions.async.add({ tag, specialTags }));
  }

  function handleTips(tag: TagProps) {
    setTimeout(() => {
      const tips = tipsRef.current;
      const found = tipUtils.find(tips, tag.value);

      if (!found) return;
      tipsRef.current = tipUtils.remove(tips, found.id);

      dispatch(
        challengeActions.update({
          tip: found.tip,
        })
      );
    });
  }

  function getCurrentStepsTags(): TagProps[] {
    const steps =
      (challengeCodeRef.current && challengeCodeRef.current.steps) || [];

    return tagStepUtils.getCurrentByIndex(steps, currentStepTagIndex.current);
  }

  function updateCurrentSpecialStepTags(
    specialTags: SpecialTagProps[],
    steps: TagProps[]
  ) {
    if (!isCurrentStepSpecial(steps)) return;

    dispatch(
      challengeActions.update({
        specialTags,
      })
    );
  }

  function getSpecialTags(tag: TagProps, steps: TagProps[]): SpecialTagProps[] {
    if (!isCurrentStepSpecial(steps)) return specialTagsRef.current;
    return [...specialTagsRef.current, { tag, step: getCurrentStep(steps) }];
  }

  function isCurrentStepSpecial(steps: TagProps[]): boolean {
    return !!getCurrentStep(steps).special;
  }

  function getCurrentStep(steps: TagProps[]): TagProps {
    return steps[0];
  }

  function isTagValid(tag: TagProps): boolean {
    return validatorUtils.is(
      tag,
      currentStepTags.current,
      specialTagsRef.current
    );
  }

  function alertLostScore(
    bounds: { left: string; top: string },
    length: number
  ) {
    if (!bounds) return;
    if (!wrapperRef.current) return;

    alertAnimate({
      bounds,
      factories: [
        AlertFactory({
          content: `-${length} s`,
          type: AlertFactoryType.Danger,
        }),
      ],
      wrapper: wrapperRef.current,
    });
  }

  function onFailTag(length: number, subtype: FlyingObjectSubType) {
    blink();

    dispatch(tagActions.async.missed());
    dispatch(playerActions.async.incScore(-length));
    dispatch(
      invadersActions.async.add({
        length,
        subtype,
        isMad: true,
      })
    );
  }

  function blink() {
    if (!blinkElRef.current) return;
    blinkUtils.blinkPurple(blinkElRef.current, 100);
  }

  return (
    <>
      {challenge.type === ChallengeType.Introduction && (
        <ChallengeIntroduction
          code={code}
          paused={paused}
          onFailTag={onFailTag}
          challenge={challenge}
          wrapperRef={wrapperRef}
          blinkElRef={blinkElRef}
          isTagValid={isTagValid}
          onSuccessTag={successTag}
          alertLostScore={alertLostScore}
          reOpenChallenge={reOpenChallenge}
          handlerWrapperRef={handlerWrapperRef}
          wrapperNotifiersRef={wrapperNotifiersRef}
          handlerNotifiersRef={handlerNotifiersRef}
        />
      )}

      {challenge.type === ChallengeType.Exercise && (
        <ChallengeExercise
          code={code}
          paused={paused}
          onFailTag={onFailTag}
          challenge={challenge}
          wrapperRef={wrapperRef}
          blinkElRef={blinkElRef}
          isTagValid={isTagValid}
          onSuccessTag={successTag}
          alertLostScore={alertLostScore}
          reOpenChallenge={reOpenChallenge}
          handlerWrapperRef={handlerWrapperRef}
          wrapperNotifiersRef={wrapperNotifiersRef}
          handlerNotifiersRef={handlerNotifiersRef}
        />
      )}
    </>
  );
};

export default Challenge;
