// react
import { useState } from "react";

// components
import Loading from "components/Loading";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import { DataCacheProps } from "components/presentations/Customization";
import PlayerNaming from "components/presentations/player/PlayerNaming";

// services
import UserService from "services/UserService";

// utils
import errorUtils from "utils/errorUtils";

interface PlayerNamingWrapperProps {
  goForward(): void;
  cache: DataCacheProps;
  demo?: boolean;
  codename?: string;
}

const PlayerNamingWrapper = ({
  demo,
  cache,
  goForward,
  codename = "",
}: PlayerNamingWrapperProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function onNameChange(codename: string) {
    if (demo) return;
    cache.codename = codename;
  }

  async function save() {
    if (!cache.codename) return;

    try {
      setLoading(true);
      setError("");

      const resource = new UserService();
      await resource.updateCodename({ codename: cache.codename });

      setTimeout(() => goForward());
    } catch (error: unknown) {
      const { message } = errorUtils.handle.general(error);
      setError(message);
    } finally {
      setLoading(false);
    }
  }

  function isValidName(name: string): boolean {
    if (!name) return false;
    if (hasNerdfyPrefix(name)) return false;

    return !!name.match(/^[a-z][a-zA-Z0-9_]{2,16}$/);
  }

  function hasNerdfyPrefix(name: string): boolean {
    return !!name.match(/^(nerdfy)/);
  }

  function isContinueAvailable(): boolean {
    if (demo) return true;
    return isValidName(cache.codename) && !loading;
  }

  return (
    <div className="d_flex_column_1 relative">
      <Loading loading={loading} />
      <FormHeader
        brand
        save={demo ? goForward : save}
        disabled={!isContinueAvailable()}
      />

      <PlayerNaming codename={codename} onChange={onNameChange} />
      {!demo && error && <ErrorFeedback err={error} />}
    </div>
  );
};

export default PlayerNamingWrapper;
