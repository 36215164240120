// enums
import { ChallengeType } from "enums/challengeEnum";

// parsers
import challengeQuizFirebaseParser from "parsers/challengeQuizFirebaseParser";

// interfaces
import {
  ChallengeQuizProps,
  ChallengeQuizHashProps,
  ChallengeQuizResourceProps,
} from "interfaces/challengeQuiz";
import { ChallengeQuizFirebaseProps } from "interfaces/challengeQuizFirebase";

function mix(
  quizzes: ChallengeQuizProps[],
  quizzesFirebase: ChallengeQuizFirebaseProps[]
): ChallengeQuizProps[] {
  return quizzes.reduce((acc, quiz) => {
    const found = quizzesFirebase.find((c) => c.id === quiz.id);

    if (found) acc.push({ ...quiz, ...challengeQuizFirebaseParser.map(found) });
    else acc.push(quiz);

    return acc;
  }, [] as ChallengeQuizProps[]);
}

function hash(quizzes: ChallengeQuizProps[]): ChallengeQuizHashProps {
  return quizzes.reduce(
    (acc: { [key: string]: ChallengeQuizProps[] }, quiz) => {
      if (!acc[quiz.challengeSectionId]) acc[quiz.challengeSectionId] = [];

      acc[quiz.challengeSectionId].push(quiz);
      return acc;
    },
    {}
  );
}

function list(quizzes: ChallengeQuizResourceProps[]): ChallengeQuizProps[] {
  return quizzes.map(map).sort((a, b) => {
    if (a.index > b.index) return 1;
    if (a.index < b.index) return -1;

    return 0;
  });
}

function map(data: ChallengeQuizResourceProps): ChallengeQuizProps {
  const id = data.id;
  const type = ChallengeType.Quiz;

  return {
    id,
    type,
    gameOver: false,
    name: data.name,
    index: data.index,
    level: data.level,
    multi: !!data.multi,
    answers: data.answers,
    uuid: `${type}_${id}`,
    correct: data.correct,
    generic: !!data.generic,
    question: data.question,
    languageId: data.languageId,
    classRoomId: data.classroomId,
    challengeSectionId: data.challengeSectionId,

    // flow
    flowDone: false,
    flowInit: false,
    flowFailed: false,
    flowStarted: false,
    flowSuccess: false,
    flowFinished: false,

    // feedback
    up: false,
    down: false,
    feedback: "",

    // gained
    scoreGained: 0,
    levelPointGained: 0,
  };
}

const challengeQuizParse = {
  mix,
  map,
  hash,
  list,
};

export default challengeQuizParse;
