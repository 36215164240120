// interfaces
import { InvaderBulletProps } from "interfaces/spaceInvaders/invaderBullet";
import { FlyingObjectPositionProps } from "interfaces/spaceInvaders/flyingObject";

// entities
import AbstractFlyingObjectEntity from "entities/spaceInvaders/AbstractFlyingObjectEntity";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
  FlyingObjectHpType,
  FlyingObjectDamageType,
} from "enums/spaceInvaders/flyingObjectEnum";

export class InvaderOneBulletEntity
  extends AbstractFlyingObjectEntity
  implements InvaderBulletProps
{
  static index = 1;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.explosionColor = "#69b2a5";
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.low;
    this.type = FlyingObjectType.InvaderBullet;
    this.subtype = FlyingObjectSubType.InvaderOneBullet;
  }
}

export class InvaderTwoBulletEntity
  extends AbstractFlyingObjectEntity
  implements InvaderBulletProps
{
  static index = 2;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.explosionColor = "#637FDA";
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.low;
    this.type = FlyingObjectType.InvaderBullet;
    this.subtype = FlyingObjectSubType.InvaderTwoBullet;
  }
}

export class InvaderThreeBulletEntity
  extends AbstractFlyingObjectEntity
  implements InvaderBulletProps
{
  static index = 3;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.explosionColor = "#c25fbb";
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.low;
    this.type = FlyingObjectType.InvaderBullet;
    this.subtype = FlyingObjectSubType.InvaderThreeBullet;
  }
}

export class InvaderFourBulletEntity
  extends AbstractFlyingObjectEntity
  implements InvaderBulletProps
{
  static index = 4;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.explosionColor = "#d2cc69";
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.low;
    this.type = FlyingObjectType.InvaderBullet;
    this.subtype = FlyingObjectSubType.InvaderFourBullet;
  }
}

export class InvaderFiveBulletEntity
  extends AbstractFlyingObjectEntity
  implements InvaderBulletProps
{
  static index = 5;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.hp = FlyingObjectHpType.low;
    this.explosionColor = "#f14c5d";
    this.damage = FlyingObjectDamageType.low;
    this.type = FlyingObjectType.InvaderBullet;
    this.subtype = FlyingObjectSubType.InvaderFiveBullet;
  }
}

export class InvaderSixBulletEntity
  extends AbstractFlyingObjectEntity
  implements InvaderBulletProps
{
  static index = 6;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.type = FlyingObjectType.InvaderBullet;
    this.subtype = FlyingObjectSubType.InvaderSixBullet;
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.middle;
    this.explosionColor = "#956840";
  }
}

export class InvaderSevenBulletEntity
  extends AbstractFlyingObjectEntity
  implements InvaderBulletProps
{
  static index = 7;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.explosionColor = "#6dc75b";
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.low;
    this.type = FlyingObjectType.InvaderBullet;
    this.subtype = FlyingObjectSubType.InvaderSevenBullet;
  }
}
