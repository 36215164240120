// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
}

const SpaceshipSvg = ({
  width = "18",
  height = "18",
  color = c.red,
}: SvgProps) => {
  return (
    <svg
      id="Flat"
      viewBox="0 0 512 512"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m296 112 16 240h24v72h-24l-32 32h-48l-32-32h-24v-72h24l16-240 40-64z"
        fill="#e4e4e3"
      />
      <circle cx="256" cy="368" fill="#b3b1b3" r="16" />
      <path d="m232 456 8-40h32l8 40z" fill={color} />
      <path
        d="m227.039 56.807-11.039 55.193h24l8-24h16l8 24h24l-11.039-55.193a43.593 43.593 0 0 0 -28.961-32.807 43.593 43.593 0 0 0 -28.961 32.807z"
        fill="#b3b1b3"
      />
      <path d="m248 48h16v16h-16z" fill="#e4e4e3" />
      <path
        d="m208 256h80a16 16 0 0 1 16 16 16 16 0 0 1 -16 16h-80a0 0 0 0 1 0 0v-32a0 0 0 0 1 0 0z"
        fill="#0093b9"
        transform="matrix(0 -1 1 0 -16 528)"
      />
      <path d="m240 280h32v16h-32z" fill="#067a98" />
      <rect fill={color} height="64" rx="8" width="16" x="248" y="136" />
      <path d="m480 360h-16v-168a8 8 0 0 1 8-8 8 8 0 0 1 8 8z" fill="#e4e4e3" />
      <path d="m448 304h48v16h-48z" fill="#cacac9" />
      <path d="m464 416h16v32h-16z" fill="#b3b1b3" />
      <path d="m328 456h16v40h-16z" fill="#b3b1b3" />
      <path d="m328 352h144l-16 56-80 48-24-8z" fill="#e4e4e3" />
      <path
        d="m432 368h72a8 8 0 0 1 8 8v16a8 8 0 0 1 -8 8h-72a0 0 0 0 1 0 0v-32a0 0 0 0 1 0 0z"
        fill="#cacac9"
        transform="matrix(0 -1 1 0 88 856)"
      />
      <path d="m456 368h32v16h-32z" fill={color} />
      <path
        d="m404.686 400-10.343-10.343a8 8 0 0 1 -2.343-5.657v-32h16v28.687l8 8a8 8 0 0 1 0 11.313 8 8 0 0 1 -11.314 0z"
        fill={color}
      />
      <path d="m320 399.999h32v72h-32z" fill="#cacac9" />
      <path
        d="m296 360h72a8 8 0 0 1 8 8v32a8 8 0 0 1 -8 8h-72a0 0 0 0 1 0 0v-48a0 0 0 0 1 0 0z"
        fill="#b3b1b3"
        transform="matrix(0 -1 1 0 -48 720)"
      />
      <path d="m312 368h48v16h-48z" fill="#969595" />
      <path d="m32 360h16v-168a8 8 0 0 0 -8-8 8 8 0 0 0 -8 8z" fill="#e4e4e3" />
      <path d="m16 304h48v16h-48z" fill="#cacac9" />
      <path d="m32 416h16v32h-16z" fill="#b3b1b3" />
      <path d="m168 456h16v40h-16z" fill="#b3b1b3" />
      <path d="m184 352h-144l16 56 80 48 24-8z" fill="#e4e4e3" />
      <path
        d="m0 368h72a8 8 0 0 1 8 8v16a8 8 0 0 1 -8 8h-72a0 0 0 0 1 0 0v-32a0 0 0 0 1 0 0z"
        fill="#cacac9"
        transform="matrix(0 -1 1 0 -344 424)"
      />
      <path d="m24 368h32v16h-32z" fill={color} />
      <path
        d="m107.314 400 10.343-10.343a8 8 0 0 0 2.343-5.657v-32h-16v28.687l-8 8a8 8 0 0 0 0 11.313 8 8 0 0 0 11.314 0z"
        fill={color}
      />
      <path d="m160 399.999h32v72h-32z" fill="#cacac9" />
      <path
        d="m136 360h72a8 8 0 0 1 8 8v32a8 8 0 0 1 -8 8h-72a0 0 0 0 1 0 0v-48a0 0 0 0 1 0 0z"
        fill="#b3b1b3"
        transform="matrix(0 -1 1 0 -208 560)"
      />
      <path d="m152 368h48v16h-48z" fill="#969595" />
    </svg>
  );
};

export default SpaceshipSvg;
