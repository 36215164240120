// icons
import SunPixelSvg from "svg/SunPixelSvg";
import NerdfyCivilizationPixelIcon from "icons/NerdfyCivilizationPixelIcon";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 20px;
  background: ${color.grey.dark};

  div,
  h2 {
    opacity: 0.5;
  }

  h2 {
    font-size: 1.4em;
    margin: 0;
    padding: 0;
  }
`;

interface AvatarPresentationFooterProps {
  label: string;
  icon: JSX.Element;
  customClassName: string;
}

const CivilizationFooter = ({
  icon,
  label,
  customClassName,
}: AvatarPresentationFooterProps) => {
  return (
    <Wrapper>
      <div>{icon}</div>
      <h2 className={`pixelify_font_family ${customClassName}`}>{label}</h2>
    </Wrapper>
  );
};

export const CivilizationNerdFooter = () => (
  <CivilizationFooter
    label="Nerdfy"
    customClassName="nerdfy_yellow"
    icon={<NerdfyCivilizationPixelIcon width="25" height="25" />}
  />
);

export const CivilizationErgonianFooter = () => (
  <CivilizationFooter
    label="Ergonia"
    customClassName="nerdfy_green"
    icon={<NerdfyCivilizationPixelIcon width="25" height="25" />}
  />
);

export const CivilizationRaviansFooter = () => (
  <CivilizationFooter
    label="Raviania"
    customClassName="nerdfy_orange"
    icon={<SunPixelSvg width="25" height="25" />}
  />
);
