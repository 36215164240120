// react
import { useLayoutEffect, useState } from "react";

// redux
import { useSelector } from "react-redux";

// redux
import { selector as s } from "redux/selectors";

// components
import SubHeaderWrapper from "components/header/SubHeaderWrapper";
import SpaceshipHealthStatus from "components/SpaceshipHealthStatus";
import AvatarIconSpaceShip from "components/avatarIcons/AvatarIconSpaceShip";

const SpaceshipStatusWidget = () => {
  const player = useSelector(s.player());
  const [lifeAnimation, setLifeAnimation] = useState(false);

  useLayoutEffect(handleLifeAnimation, []);

  function handleLifeAnimation() {
    setTimeout(() => setLifeAnimation(true), 500);
  }

  return (
    <SubHeaderWrapper>
      <AvatarIconSpaceShip />

      <SpaceshipHealthStatus
        hp={player.hp}
        life={player.life}
        heartBeatAnimation={lifeAnimation}
      />
    </SubHeaderWrapper>
  );
};

export default SpaceshipStatusWidget;
