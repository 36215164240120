// redux
import { shopActions } from "redux/slices/shopSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { spaceshipResourcesActions } from "redux/slices/spaceInvaders/spaceshipResourcesSlice";

// entities
import ShopQuestionDataFirebaseEntity from "entities/data/ShopQuestionDataFirebaseEntity";
import SpaceshipResourcesEntity from "entities/spaceshipResources/SpaceshipResourcesEntity";
import ShopItemDataFirebaseServiceEntity from "entities/data/ShopItemDataFirebaseServiceEntity";

// services
import ShopItemsFirebaseService from "services/firebase/ShopItemsFirebaseService";
import ShopQuestionsFirebaseService from "services/firebase/ShopQuestionsFirebaseService";
import PlayerShopItemsFirebaseService from "services/firebase/player/PlayerShopItemsDataFirebaseService";

// utils
import { isEmpty } from "lodash";
import stateUtils from "redux/utils/stateUtils";
import shopItemUtils from "utils/shopItemUtils";

const shopMiddleware = createListenerMiddleware();

shopMiddleware.startListening({
  actionCreator: shopActions.async.addShopItemAsRead,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);
    const userId = auth.user.id;
    const entity = new ShopItemDataFirebaseServiceEntity({
      id: payload.id,
    });

    if (!userId) return;

    const firebaseEntity = new ShopItemsFirebaseService();
    firebaseEntity.set(userId, entity.readValues);
  },
});

shopMiddleware.startListening({
  actionCreator: shopActions.async.addShopQuestionAsRead,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);
    const userId = auth.user.id;
    const entity = new ShopQuestionDataFirebaseEntity({
      id: payload.id,
      itemId: payload.itemId,
    });

    if (!userId) return;

    const firebaseEntity = new ShopQuestionsFirebaseService();
    firebaseEntity.set(userId, entity.readValues);
  },
});

shopMiddleware.startListening({
  actionCreator: shopActions.async.buy,
  effect: async ({ payload }, listenerApi) => {
    const { type } = payload;
    const { auth, spaceshipResources } = stateUtils.get(listenerApi);
    const userId = auth.user.id;

    const Entity = shopItemUtils.getEntity(type);
    const itemEntity = new Entity();
    const firebaseEntity = new PlayerShopItemsFirebaseService();
    const entity = new SpaceshipResourcesEntity({
      resources: spaceshipResources,
    });
    const mapped = entity.mapByQuantity(
      entity.transformedValues,
      itemEntity.resourcesPrice
    );
    const deleteFields = entity.stringifyDeleteFields(mapped);

    if (!userId) return;
    if (isEmpty(itemEntity.values)) return;
    if (!isEmpty(mapped) && isEmpty(deleteFields)) return;

    listenerApi.dispatch(spaceshipResourcesActions.async.remove(deleteFields));
    firebaseEntity.set(userId, itemEntity.values);
  },
});

export default shopMiddleware;
