// interfaces
import { TagProps, SpecialTagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";

// utils
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import validatorUtils from "utils/code/validatorUtils";

function get(
  tags: TagProps[],
  specialTags: SpecialTagProps[],
  { verified, steps }: ChallengeCodeProps
): TagProps | undefined {
  const bestStep = { ...steps[0] };

  if (!bestStep) return undefined;
  if (isEmpty(bestStep.data)) return undefined;

  const step = bestStep.data[verified.length];
  if (!step) return;

  if (step.special)
    if (step.type?.regexp)
      return special.regex.get(clone(tags), specialTags, step);
  return simple.get(step);
}

const simple = {
  get(step: TagProps): TagProps | undefined {
    return { ...step } || undefined;
  },
};

const special = {
  regex: {
    get(
      tags: TagProps[],
      specialTags: SpecialTagProps[],
      step: TagProps
    ): TagProps | undefined {
      if (isEmpty(tags)) return undefined;

      const { regex } = validatorUtils.special;
      const tag = tags.find((tag) => regex.is(tag, step));

      if (!tag) return undefined;
      if (!regex.validate(tag, step, specialTags)) {
        const _tags = tags.filter((t) => t.value !== tag.value);
        return this.get(_tags, specialTags, step);
      }

      return { ...tag };
    },
  },
};

const tagGuesserUtils = {
  get,
};

export default tagGuesserUtils;
