// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${color.grey.dark};
`;

const NerdXFooter = () => {
  return (
    <Wrapper>
      <div className="bg_nerdX bg_brands" />
    </Wrapper>
  );
};

export default NerdXFooter;
