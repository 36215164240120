// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { walkieTalkieActions } from "redux/slices/walkieTalkieSlice";

// entities
import WalkieTalkieBroadcastDataFirebaseEntity from "entities/data/WalkieTalkieBroadcastDataFirebaseEntity";

// services
import WalkieTalkieFirebaseService from "services/firebase/walkieTalkie/WalkieTalkieFirebaseService";

// interfaces

// utils
import stateUtils from "redux/utils/stateUtils";

const challengesMiddleware = createListenerMiddleware();

challengesMiddleware.startListening({
  actionCreator: walkieTalkieActions.async.broadcast,
  effect: async ({ payload }, listenerApi) => {
    const { message } = payload;
    const { auth, challenge } = stateUtils.get(listenerApi);
    const service = new WalkieTalkieFirebaseService();
    const entity = new WalkieTalkieBroadcastDataFirebaseEntity({
      challenge,
      message,
      user: auth.user,
    });

    service.add(entity.data);
  },
});

export default challengesMiddleware;
