// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// enums
import { BubbleType } from "enums/bubbleEnum";

// entities
import NarratorEntity from "entities/characters/NarratorEntity";

// components
import BubbleGod from "handlers/bubble/BubbleGod";
import BubbleRavian from "handlers/bubble/BubbleRavian";
import BubbleJarvis from "handlers/bubble/BubbleJarvis";
import BubbleNarrator from "handlers/bubble/BubbleNarrator";
import BubbleSpeeches from "handlers/bubble/BubbleSpeeches";
import BubbleJarvisInfo from "handlers/bubble/BubbleJarvisInfo";
import BubbleZarvoxQuiz from "handlers/bubble/BubbleZarvoxQuiz";
import BubbleJarvisAlert from "handlers/bubble/BubbleJarvisAlert";

interface BubbleHandlerProps {
  hide(): void;
  show: boolean;
  content: string;
  type: BubbleType;
  finishedTyping?(): void;
  footer?: React.ReactNode;
  subHeader?: React.ReactNode;
  onAnimationEnd?(e: React.AnimationEvent<HTMLDivElement>): void;
}

const BubbleHandler = ({
  hide,
  show,
  type,
  content,
  footer,
  subHeader,
  finishedTyping,
  onAnimationEnd,
}: BubbleHandlerProps) => {
  const npc = useSelector(s.npc());

  return (
    <>
      {show && type === BubbleType.God && (
        <BubbleGod
          hide={hide}
          footer={footer}
          content={content}
          name={npc.god.name}
          subHeader={subHeader}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
        />
      )}

      {show && type === BubbleType.Message && (
        <BubbleJarvis
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
        />
      )}

      {show && type === BubbleType.Info && (
        <BubbleJarvisInfo
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          finishedTyping={finishedTyping}
          onAnimationEnd={onAnimationEnd}
        />
      )}

      {show && type === BubbleType.Quiz && (
        <BubbleZarvoxQuiz
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.zarvox.name}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
        />
      )}

      {show && type === BubbleType.Market && (
        <BubbleRavian
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name="Vendedor Raviano"
          finishedTyping={finishedTyping}
          onAnimationEnd={onAnimationEnd}
        />
      )}

      {show && type === BubbleType.Speech && (
        <BubbleSpeeches
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
        />
      )}

      {show && type === BubbleType.Narrator && (
        <BubbleNarrator
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
          name={NarratorEntity.defaultName}
        />
      )}

      {show && type === BubbleType.JarvisAlert && (
        <BubbleJarvisAlert
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
        />
      )}
    </>
  );
};

export default BubbleHandler;
