// interfaces
import { AsteroidProps } from "interfaces/spaceInvaders/asteroid";
import { FlyingObjectPositionProps } from "interfaces/spaceInvaders/flyingObject";

// entities
import AbstractFlyingObjectEntity from "entities/spaceInvaders/AbstractFlyingObjectEntity";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
  FlyingObjectHpType,
  FlyingObjectDamageType,
} from "enums/spaceInvaders/flyingObjectEnum";

export class AsteroidMineralGoldEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  isMineral: boolean;

  static index = 1;
  static storage = 5;
  static key = "gold";
  static alias = "ouro";
  static turnsToTransform = 3;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.isMineral = true;
    this.explosionColor = "#FFD475";
    this.hp = FlyingObjectHpType.high;
    this.type = FlyingObjectType.Asteroid;
    this.key = AsteroidMineralGoldEntity.key;
    this.damage = FlyingObjectDamageType.high;
    this.name = AsteroidMineralGoldEntity.alias;
    this.subtype = FlyingObjectSubType.AsteroidMineralGold;
  }

  set storage(value: number) {
    AsteroidMineralGoldEntity.storage = value;
  }

  get storage(): number {
    return AsteroidMineralGoldEntity.storage;
  }
}

export class AsteroidMineralSilverEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  isMineral: boolean;

  static index = 2;
  static key = "silver";
  static alias = "prata";
  static turnsToTransform = 2;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.isMineral = true;
    this.explosionColor = "#585966";
    this.hp = FlyingObjectHpType.middle;
    this.type = FlyingObjectType.Asteroid;
    this.key = AsteroidMineralSilverEntity.key;
    this.damage = FlyingObjectDamageType.high;
    this.name = AsteroidMineralSilverEntity.alias;
    this.subtype = FlyingObjectSubType.AsteroidMineralSilver;
  }
}

export class AsteroidMineralSandEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  isMineral: boolean;

  static index = 3;
  static key = "sand";
  static alias = "areia";
  static turnsToTransform = 2;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.isMineral = true;
    this.explosionColor = "#C7B19A";
    this.hp = FlyingObjectHpType.middle;
    this.type = FlyingObjectType.Asteroid;
    this.key = AsteroidMineralSandEntity.key;
    this.name = AsteroidMineralSandEntity.alias;
    this.damage = FlyingObjectDamageType.middle;
    this.subtype = FlyingObjectSubType.AsteroidMineralSand;
  }
}

export class AsteroidMineralBauxiteEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  storageMax: number;
  isMineral: boolean;

  static index = 4;
  static key = "bauxite";
  static alias = "bauxita";
  static turnsToTransform = 2;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.storageMax = 5;
    this.isMineral = true;
    this.explosionColor = "#D19A6E";
    this.hp = FlyingObjectHpType.middle;
    this.type = FlyingObjectType.Asteroid;
    this.damage = FlyingObjectDamageType.middle;
    this.key = AsteroidMineralBauxiteEntity.key;
    this.name = AsteroidMineralBauxiteEntity.alias;
    this.subtype = FlyingObjectSubType.AsteroidMineralBauxite;
  }

  set storage(value: number) {
    this.storageMax = value;
  }

  get storage(): number {
    return this.storageMax;
  }
}

export class AsteroidMineralCopperEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  isMineral: boolean;

  static index = 5;
  static key = "copper";
  static alias = "cobre";
  static turnsToTransform = 2;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.isMineral = true;
    this.explosionColor = "#C56D4A";
    this.hp = FlyingObjectHpType.middle;
    this.type = FlyingObjectType.Asteroid;
    this.key = AsteroidMineralCopperEntity.key;
    this.damage = FlyingObjectDamageType.middle;
    this.name = AsteroidMineralCopperEntity.alias;
    this.subtype = FlyingObjectSubType.AsteroidMineralCopper;
  }
}

export class AsteroidWaterEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  isMineral: boolean;

  static index = 6;
  static key = "water";
  static alias = "água";

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.isMineral = false;
    this.explosionColor = "#4A8DC6";
    this.key = AsteroidWaterEntity.key;
    this.hp = FlyingObjectHpType.middle;
    this.name = AsteroidWaterEntity.alias;
    this.type = FlyingObjectType.Asteroid;
    this.damage = FlyingObjectDamageType.middle;
    this.subtype = FlyingObjectSubType.AsteroidWater;
  }
}

export class AsteroidStrongForceEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  isMineral: boolean;

  static index = 7;
  static key = "strongForce";
  static alias = "força forte";

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.isMineral = false;
    this.explosionColor = "#7D6599";
    this.hp = FlyingObjectHpType.high;
    this.type = FlyingObjectType.Asteroid;
    this.key = AsteroidStrongForceEntity.key;
    this.damage = FlyingObjectDamageType.middle;
    this.name = AsteroidStrongForceEntity.alias;
    this.subtype = FlyingObjectSubType.AsteroidStrongForce;
  }
}

export class AsteroidMineralEstelinEntity
  extends AbstractFlyingObjectEntity
  implements AsteroidProps
{
  key: string;
  name: string;
  isMineral: boolean;

  static index = 8;
  static key = "estelin";
  static alias = "estelin";
  static turnsToTransform = 3;

  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.isMineral = true;
    this.explosionColor = "#324566";
    this.hp = FlyingObjectHpType.high;
    this.type = FlyingObjectType.Asteroid;
    this.damage = FlyingObjectDamageType.middle;
    this.key = AsteroidMineralEstelinEntity.key;
    this.name = AsteroidMineralEstelinEntity.alias;
    this.subtype = FlyingObjectSubType.AsteroidMineralEstelin;
  }
}
