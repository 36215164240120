// enums
import { SpaceshipItemEnum } from "enums/spaceInvaders/spaceshipItemEnum";

// interfaces
import { ShopItemProps, ShopItemBubbleProps } from "interfaces/shop";
import { PlayerShopItemsFirebaseProps } from "interfaces/shopFirebase";
import { SpaceshipResourcesQtyProps } from "interfaces/spaceInvaders/spaceshipResources";

// entities
import AbstractShopItemEntity from "entities/shopItems/AbstractShopItemEntity";

// firestore
import { serverTimestamp } from "firebase/firestore";

// svgs
import LaserPixelSvg from "svg/LaserPixelSvg";

interface IconProps {
  width?: string;
  height?: string;
}

const Icon = ({ width = "35", height = "35" }: IconProps) => {
  return <LaserPixelSvg width={width} height={height} />;
};

export default class LaseItemEntity extends AbstractShopItemEntity {
  readonly id = 4;
  readonly price = 2;
  readonly maxQuantity = 1;
  readonly available = false;
  readonly icon = (<Icon />);
  readonly title = "Canhão de Laser";
  readonly bubbles = this.shopBubbles;
  readonly subTitle = "Modelo simples";
  readonly type = SpaceshipItemEnum.LaserGun;
  readonly image = (<Icon width="45" height="45" />);
  readonly capacity = AbstractShopItemEntity.capacityNone;
  readonly lifeCycles = AbstractShopItemEntity.lifeCyclesNone;

  get item(): ShopItemProps {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      price: this.price,
      bubbles: this.bubbles,
      subTitle: this.subTitle,
      capacity: this.capacity,
      available: this.available,
      lifeCycles: this.lifeCycles,
    };
  }

  get values(): PlayerShopItemsFirebaseProps {
    return {
      [this.getItemId(this.type)]: {
        t: this.type,
        c: this.capacity,
        l: this.lifeCycles,
        s: serverTimestamp(),
      },
    };
  }

  get resourcesPrice(): SpaceshipResourcesQtyProps {
    return {
      sand: 0,
      gold: 0,
      water: 0,
      silver: 0,
      copper: 0,
      estelin: 0,
      bauxite: 0,
      strongForce: 0,
    };
  }

  getIcon({ width, height }: IconProps): JSX.Element {
    return <Icon width={width} height={height} />;
  }

  private get shopBubbles(): ShopItemBubbleProps[] {
    return [];
  }
}
