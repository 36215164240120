// react
import { useRef, useState } from "react";
import { IonicSlides } from "@ionic/react";

// entities
import {
  ItemInvaderOneEntity,
  ItemInvaderTwoEntity,
  ItemInvaderSixEntity,
  ItemInvaderFourEntity,
  ItemInvaderFiveEntity,
  ItemInvaderSevenEntity,
  ItemInvaderThreeEntity,
} from "entities/items/ItemEntity";
import {
  InvaderOneEntity,
  InvaderSixEntity,
  InvaderTwoEntity,
  InvaderFiveEntity,
  InvaderFourEntity,
  InvaderThreeEntity,
  InvaderSevenEntity,
} from "entities/spaceInvaders/InvaderEntity";

// components
import SlideDotsProgress from "components/SlideDotsProgress";

// interfaces
import {
  ListItems,
  ListItemProps,
  NoneDescription,
} from "components/playerProfile/PlayerProfile";
import { PlayerCheckpointsStateProps } from "interfaces/playerCheckpoint";

// enums
import { PlayerCheckpointEnum } from "enums/playerCheckpointEnum";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface PlayerProfileMonstersSlideProps {
  checkpoints: PlayerCheckpointsStateProps;
}

const PlayerProfileMonstersSlide = ({
  checkpoints,
}: PlayerProfileMonstersSlideProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  function getMonsters(): ListItemProps[] {
    const one = new ItemInvaderOneEntity();
    const two = new ItemInvaderTwoEntity();
    const three = new ItemInvaderThreeEntity();
    const four = new ItemInvaderFourEntity();
    const five = new ItemInvaderFiveEntity();
    const six = new ItemInvaderSixEntity();
    const seven = new ItemInvaderSevenEntity();

    return [one, two, three, four, five, six, seven];
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;

    swiperRef.current = swiper;
    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
    });
  }

  return (
    <div className="slide">
      <div className="header">
        <h1 className="nerdfy_orange">Criaturas Espaciais</h1>
      </div>

      <div className="body">
        <ListItems
          items={getMonsters()}
          checkpoints={checkpoints}
          activeIndex={slideActiveIndex}
        />

        <SlideDotsProgress len={7} position={slideActiveIndex} />

        <div className="slides_body no-swipe">
          <Swiper
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            slidesPerView={1}
            initialSlide={0}
          >
            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.InvaderOne] ? (
                  <>
                    <p>
                      <span className="invader_one_color">
                        {InvaderOneEntity.defaultName}
                      </span>{" "}
                      é uma criatura{" "}
                      <span className="nerdfy_green">colossal</span> e{" "}
                      <span className="nerdfy_pink">imponente</span>, oriunda
                      dos confins mais{" "}
                      <span className="nerdfy_orange">profundos</span> do
                      espaço.
                    </p>

                    <p>
                      Possui uma <span className="nerdfy_brown">aparência</span>{" "}
                      com características{" "}
                      <span className="nerdfy_white">cósmicas únicas</span>,
                      tornando-a um ser tanto{" "}
                      <span className="nerdfy_blue">fascinante</span> quanto{" "}
                      <span className="nerdfy_red">aterrorizante</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.InvaderTwo] ? (
                  <>
                    <p>
                      <span className="invader_two_color">
                        {InvaderTwoEntity.defaultName}
                      </span>{" "}
                      é uma criatura{" "}
                      <span className="nerdfy_blue">majestosa</span> que se move{" "}
                      <span className="nerdfy_pink">silenciosamente</span>{" "}
                      através das nebulosas do espaço.
                    </p>

                    <p>
                      É uma{" "}
                      <span className="nerdfy_green">maravilha cósmica</span>{" "}
                      para qualquer{" "}
                      <span className="nerdfy_orange">viajante espacial</span>{" "}
                      que tenha a <span className="nerdfy_red">felicidade</span>{" "}
                      de cruzar seu caminho.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.InvaderThree] ? (
                  <>
                    <p>
                      <span className="invader_three_color">
                        {InvaderThreeEntity.defaultName}
                      </span>{" "}
                      é uma criatura cósmica que possui uma{" "}
                      <span className="nerdfy_orange">implacável</span> e{" "}
                      [quase] <span className="nerdfy_blue">impenetrável</span>{" "}
                      carapaça...
                    </p>

                    <p>
                      Assemelha-se a um{" "}
                      <span className="nerdfy_pink">caranguejo colossal</span>,
                      com adaptações{" "}
                      <span className="nerdfy_white">evolutivas</span> que lhe
                      conferem um certo poder{" "}
                      <span className="nerdfy_red">destrutivo</span> e uma{" "}
                      <span className="nerdfy_green">resistência</span> sem
                      igual.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.InvaderFour] ? (
                  <>
                    <p>
                      <span className="invader_four_color">
                        {InvaderFourEntity.defaultName}
                      </span>{" "}
                      é uma <span className="nerdfy_orange">entidade</span>{" "}
                      lendária, conhecida tanto por sua{" "}
                      <span className="nerdfy_red">aparência intimadora</span>{" "}
                      quanto por sua{" "}
                      <span className="nerdfy_green">inteligência</span>{" "}
                      superior.
                    </p>

                    <p>
                      Possui uma{" "}
                      <span className="nerdfy_pink">
                        cabeça desproporcionalmente grande
                      </span>
                      , cheia de <span className="nerdfy_blue">complexos</span>{" "}
                      órgãos neurais.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.InvaderFive] ? (
                  <>
                    <p>
                      <span className="invader_five_color">
                        {InvaderFiveEntity.defaultName}
                      </span>{" "}
                      é uma criatura{" "}
                      <span className="nerdfy_orange">estranha</span>,
                      remanescente das{" "}
                      <span className="nerdfy_green">lendas</span> de{" "}
                      <span className="nerdfy_yellow">dragões</span> antigos...
                    </p>

                    <p>
                      <span className="nerdfy_blue">Mistura</span> as
                      características de um{" "}
                      <span className="nerdfy_pink">dragão tradicional</span>{" "}
                      com certos elementos do{" "}
                      <span className="nerdfy_white">cosmos</span>...
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.InvaderSix] ? (
                  <>
                    <p>
                      <span className="invader_six_color">
                        {InvaderSixEntity.defaultName}
                      </span>{" "}
                      é uma criatura{" "}
                      <span className="nerdfy_pink">colossal</span> que possui{" "}
                      <span className="nerdfy_blue">grandes presas</span> que
                      uma vez <span className="nerdfy_red">cravadas</span> em
                      sua <span className="nerdfy_white">presa</span>... não
                      existe <span className="nerdfy_yellow">escapatória</span>.
                    </p>

                    <p>
                      Também conhecida por seus{" "}
                      <span className="nerdfy_brown">
                        excrementos gosmentos
                      </span>
                      , <span className="nerdfy_green">pegajosos</span> e
                      altamente{" "}
                      <span className="nerdfy_orange">corrosivos</span> e{" "}
                      <span className="nerdfy_yellow">tóxicos</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide_body">
                {checkpoints[PlayerCheckpointEnum.InvaderSeven] ? (
                  <>
                    <p>
                      <span className="invader_seven_color">
                        {InvaderSevenEntity.defaultName}
                      </span>{" "}
                      é uma <span className="nerdfy_orange">criatura</span>{" "}
                      espacial única, que se assemelha a um{" "}
                      <span className="nerdfy_green">grilo gigante</span>...
                    </p>

                    <p>
                      Conhecida por sua{" "}
                      <span className="nerdfy_pink">incrível</span> capacidade
                      de <span className="nerdfy_blue">saltar</span> entre
                      planetas usando um método de locomoção que combina{" "}
                      <span className="nerdfy_red">saltos físicos</span> com{" "}
                      <span className="nerdfy_white">teletransporte</span>.
                    </p>
                  </>
                ) : (
                  NoneDescription()
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfileMonstersSlide;
