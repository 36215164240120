// enums
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

export const AsteroidFactory = (id: string, subtype: FlyingObjectSubType) => {
  const className = getAsteroidClassName(subtype);
  const el = document.createElement("div");

  el.id = id;
  el.className = `space_invaders_asteroid ${className} bg_size_32 rotate`;

  return el;
};

// private

function getAsteroidClassName(subtype: FlyingObjectSubType) {
  if (subtype === FlyingObjectSubType.AsteroidMineralGold)
    return "bg_asteroid_mineral_gold";
  if (subtype === FlyingObjectSubType.AsteroidMineralSilver)
    return "bg_asteroid_mineral_silver";
  if (subtype === FlyingObjectSubType.AsteroidMineralSand)
    return "bg_asteroid_mineral_sand";
  if (subtype === FlyingObjectSubType.AsteroidMineralBauxite)
    return "bg_asteroid_mineral_bauxite";
  if (subtype === FlyingObjectSubType.AsteroidMineralCopper)
    return "bg_asteroid_mineral_copper";
  if (subtype === FlyingObjectSubType.AsteroidWater) return "bg_asteroid_water";
  if (subtype === FlyingObjectSubType.AsteroidStrongForce)
    return "bg_asteroid_strong_force";
  if (subtype === FlyingObjectSubType.AsteroidMineralEstelin)
    return "bg_asteroid_mineral_estelin";

  return "bg_asteroid_mineral_copper";
}
