// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { jarvisChatsActions } from "redux/slices/jarvisChatsSlice";

// components
import DragWhores from "components/dragWhores/DragWhores";
import JarvisAnswerBubble from "handlers/jarvisQuestionBubble/JarvisAnswerBubble";

// handlers
import QuestionsHandler from "handlers/challengeButtons/questions/QuestionsHandler";
import JarvisBubbleIntroHandler from "handlers/jarvisQuestionBubble/JarvisBubbleIntroHandler";

// svgs
import DonePixelSvg from "icons/DonePixelIcon";

// interfaces
import {
  JarvisChatProps,
  JarvisChatFirebaseProps,
} from "interfaces/jarvisChat";
import { DragWhoreProps } from "interfaces/dragWhore";
import { ChallengeCommonProps } from "interfaces/challenge";
import { DragWhoreTemplateElProps } from "components/dragWhores/DragWhore";

// utils
import isEmpty from "lodash/isEmpty";
import dragWhoreUtils from "utils/dragWhoreUtils";

const DragWhoreJarvisQuestionTemplateEl = ({
  whore,
}: DragWhoreTemplateElProps) => {
  const payload = whore.payload as JarvisChatProps;

  return payload.answerRead ? (
    <p className={`nerdfy_orange pixelify_font_family jarvis_at_question_read`}>
      {payload.question}{" "}
      <span>
        <DonePixelSvg />
      </span>
    </p>
  ) : (
    <p className={`nerdfy_orange pixelify_font_family`}>{payload.question}</p>
  );
};

interface CurrentJarvisProps {
  id: number;
  answer: string;
  question: string;
  answerShow: boolean;
  questionsShow: boolean;
  hidingAnimation: boolean;

  // answer
  answerRead: boolean;
  answerThumbsUp: boolean;
  answerThumbsDown: boolean;
}

interface JarvisQuestionBubbleHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
  finished?(): void;
}

const JarvisQuestionBubbleHandler = ({
  paused,
  wrapper,
  finished,
  challenge,
}: JarvisQuestionBubbleHandlerProps) => {
  const dispatch = useDispatch();
  const jarvisChats = useSelector(s.jarvisChats());
  const whoresLenRef = useRef(0);
  const [displayButton, setDisplayButton] = useState(0);
  const [whores, setWhores] = useState<DragWhoreProps[]>([]);
  const [currentJarvis, setCurrentJarvis] = useState<CurrentJarvisProps>(
    getCurrentJarvisEmptyValues()
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [jarvisChats]);

  function bootstrap() {
    if (!isEmpty(whores)) return;
    if (isEmpty(jarvisChats)) return;

    start(filterByAnswerRead(jarvisChats), 500);
  }

  function refillQuestions() {
    start(filter(jarvisChats), 50);
  }

  function start(w: JarvisChatProps[], delay = 0) {
    whoresLenRef.current = w.length;

    if (whoresLenRef.current === 0) {
      handleFinishedWhores();
      return;
    }

    setTimeout(displayWhores.bind(null, w), delay);
  }

  function displayWhores(w: JarvisChatProps[]) {
    setWhores([...whores, ...mapWhores(w)]);
  }

  function mapWhores(whores: JarvisChatProps[]): DragWhoreProps[] {
    return whores.map((d: JarvisChatProps) => getContent(d));
  }

  function filter(chats: JarvisChatProps[]) {
    return chats.filter((data) => data.challengeId === challenge.id);
  }

  function filterByAnswerRead(chats: JarvisChatProps[]) {
    return chats.filter(
      (data) => !data.answerRead && data.challengeId === challenge.id
    );
  }

  function getCurrentJarvisEmptyValues(config?: {
    questionsShow: boolean;
  }): CurrentJarvisProps {
    return {
      id: 0,
      answer: "",
      question: "",
      answerShow: false,
      answerRead: false,
      answerThumbsUp: false,
      hidingAnimation: false,
      answerThumbsDown: false,
      questionsShow: !!config?.questionsShow,
    };
  }

  function hideJarvisBubble() {
    decWhoresLen();
    handleFinishedWhores();

    setCurrentJarvis({
      ...currentJarvis,
      answerShow: false,
      hidingAnimation: true,
    });
  }

  function handleFinishedWhores() {
    if (whoresLenRef.current > 0) return;

    finish();
    setDisplayButton(displayButton + 1);
  }

  function finish() {
    if (!finished) return;
    setTimeout(finished);
  }

  function decWhoresLen() {
    const len = whoresLenRef.current;
    if (len > 0) whoresLenRef.current -= 1;
  }

  function syncAnswerThumbsWithFirebase(
    thumbsUp: boolean,
    thumbsDown: boolean
  ) {
    const jarvisId = currentJarvis.id;
    if (!jarvisId) return;

    const dataFirebase: JarvisChatFirebaseProps = {
      id: jarvisId,
      up: thumbsUp,
      down: thumbsDown,
    };

    dispatch(jarvisChatsActions.async.setAnswerThumbs(dataFirebase));
  }

  function setJarvisAnswerAsRead() {
    if (!currentJarvis.id) return;

    const dataFirebase: JarvisChatFirebaseProps = {
      id: currentJarvis.id,
      read: true,
    };

    setCurrentJarvis({ ...currentJarvis, answerRead: true });
    dispatch(jarvisChatsActions.async.setAnswerAsRead(dataFirebase));
  }

  function onJarvisAnswerBubbleCloseAnimationEnd() {
    setCurrentJarvis(getCurrentJarvisEmptyValues());
  }

  function getContent(data: JarvisChatProps, last = false): DragWhoreProps {
    if (!wrapper) return dragWhoreUtils.fake();
    const { clientWidth, clientHeight } = wrapper;

    return dragWhoreUtils.get({
      last,
      payload: data,
      call: onDragWhoreCall,
      wrapperWidth: clientWidth,
      wrapperHeight: clientHeight,
    });
  }

  function onDragWhoreCall(whore: DragWhoreProps) {
    callJarvisBubble(whore.payload as JarvisChatProps);
  }

  function callJarvisBubble(jarvis: JarvisChatProps) {
    setCurrentJarvis({
      ...jarvis,
      answerShow: true,
      questionsShow: true,
      hidingAnimation: false,
      answerRead: !!jarvis.answerRead,
      answerThumbsUp: !!jarvis.answerUp,
      answerThumbsDown: !!jarvis.answerDown,
    });

    const id = jarvis.id;
    const questionClicked = true;

    dispatch(
      jarvisChatsActions.async.setQuestionAsClicked({ id, questionClicked })
    );
  }

  return (
    <>
      {whores.length > 0 && (
        <JarvisBubbleIntroHandler
          wrapper={wrapper}
          paused={paused}
          challenge={challenge}
        />
      )}

      <QuestionsHandler
        challenge={challenge}
        display={displayButton}
        refillQuestions={refillQuestions}
      />

      <DragWhores
        whores={whores}
        paused={paused}
        dropZoneBorderColor="orange"
        disabledDrop={currentJarvis.answerShow}
        TemplateEl={DragWhoreJarvisQuestionTemplateEl}
      />

      <JarvisAnswerBubble
        hide={hideJarvisBubble}
        answer={currentJarvis.answer}
        read={currentJarvis.answerRead}
        show={currentJarvis.answerShow}
        question={currentJarvis.question}
        finishedAnswer={setJarvisAnswerAsRead}
        thumbsUp={currentJarvis.answerThumbsUp}
        syncThumbs={syncAnswerThumbsWithFirebase}
        thumbsDown={currentJarvis.answerThumbsDown}
        onCloseAnimationEnd={onJarvisAnswerBubbleCloseAnimationEnd}
      />
    </>
  );
};

export default JarvisQuestionBubbleHandler;
