// redux
import { createSlice, createAction } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import {
  JarvisChatProps,
  JarvisChatUpdateProps,
  JarvisChatFirebaseProps,
} from "interfaces/jarvisChat";

const initialState: JarvisChatProps[] = [];

const setAnswerThumbs = createAction(
  "@async/jarvisChats/firebase/answer/thumbs",
  (payload: JarvisChatFirebaseProps) => ({ payload })
);

const setAnswerAsRead = createAction(
  "@async/jarvisChats/firebase/answer/read",
  (payload: JarvisChatFirebaseProps) => ({ payload })
);

const setQuestionAsClicked = createAction(
  "@async/jarvisChats/firebase/question/clicked",
  (payload: JarvisChatFirebaseProps) => ({ payload })
);

export const jarvisChatsSlice = createSlice({
  name: "jarvisChats",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<JarvisChatProps[]>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<JarvisChatUpdateProps[]>) => {
      return updateAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

function updateAll(
  jarvis: JarvisChatProps[],
  jarvisToUpdate: JarvisChatUpdateProps[]
): JarvisChatProps[] {
  return jarvis.map((data) => updateOne(data, jarvisToUpdate));
}

function updateOne(
  jarvis: JarvisChatProps,
  jarvisToUpdate: JarvisChatUpdateProps[]
): JarvisChatProps {
  const found = jarvisToUpdate.find((data) => data.id === jarvis.id);
  if (found) return { ...jarvis, ...found };

  return jarvis;
}

export const jarvisChatsActions = {
  async: {
    setAnswerAsRead,
    setAnswerThumbs,
    setQuestionAsClicked,
  },
  ...jarvisChatsSlice.actions,
};

export default jarvisChatsSlice.reducer;
