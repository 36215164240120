// enums
import { SpaceshipColorType } from "enums/spaceInvaders/spaceshipEnum";

// utils
import c from "styles/color";

function getClassNameColor(color: SpaceshipColorType): string {
  if (color === SpaceshipColorType.Red) return "nerdfy_red";
  if (color === SpaceshipColorType.Green) return "nerdfy_green";
  if (color === SpaceshipColorType.Blue) return "nerdfy_blue";
  if (color === SpaceshipColorType.Purple) return "nerdfy_purple";
  if (color === SpaceshipColorType.Orange) return "nerdfy_orange";
  if (color === SpaceshipColorType.Yellow) return "nerdfy_yellow";

  return "nerdfy_green";
}

function getColor(color: SpaceshipColorType): string {
  if (color === SpaceshipColorType.Red) return c.red;
  if (color === SpaceshipColorType.Green) return c.green;
  if (color === SpaceshipColorType.Blue) return c.blue;
  if (color === SpaceshipColorType.Purple) return c.purple;
  if (color === SpaceshipColorType.Orange) return c.orange;
  if (color === SpaceshipColorType.Yellow) return c.yellow;

  return c.green;
}

const spaceshipUtils = {
  getColor,
  getClassNameColor,
};

export default spaceshipUtils;
