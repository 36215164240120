// interfaces
import { BulletProps } from "interfaces/spaceInvaders/bullet";
import { FlyingObjectPositionProps } from "interfaces/spaceInvaders/flyingObject";

// entities
import AbstractFlyingObjectEntity from "entities/spaceInvaders/AbstractFlyingObjectEntity";

// enums
import {
  FlyingObjectType,
  FlyingObjectSubType,
  FlyingObjectHpType,
  FlyingObjectDamageType,
} from "enums/spaceInvaders/flyingObjectEnum";

// utils
import color from "styles/color";

export default class BulletEntity
  extends AbstractFlyingObjectEntity
  implements BulletProps
{
  constructor(position: FlyingObjectPositionProps) {
    super(position);

    this.position = position;
    this.type = FlyingObjectType.Bullet;
    this.subtype = FlyingObjectSubType.Bullet;
    this.hp = FlyingObjectHpType.low;
    this.damage = FlyingObjectDamageType.low;
    this.explosionColor = color.white;
  }
}
