// handlers
import EventsHandler from "handlers/EventsHandler";
import StoriesHandler from "handlers/StoriesHandler";
import FeedbackHandler from "handlers/feedback/FeedbackHandler";
import PlayerHpRecoverHandler from "handlers/PlayerHpRecoverHandler";
import AmateurRadioHandler from "handlers/amateurRadio/AmateurRadioHandler";
import SpaceshipResourcesHandler from "handlers/spaceshipResources/SpaceshipResourcesHandler";
import PlayerNotifierDegreeHandler from "handlers/playerNotifierDegree/PlayerNotifierDegreeHandler";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ChallengeCommonHandlersHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
  wrapperNotifiers: HTMLDivElement | null;
}

const ChallengeCommonHandlersHandler = ({
  paused,
  wrapper,
  challenge,
  wrapperNotifiers,
}: ChallengeCommonHandlersHandlerProps) => {
  return (
    <>
      <FeedbackHandler wrapper={wrapper} challenge={challenge} />
      <SpaceshipResourcesHandler wrapper={wrapper} challenge={challenge} />
      <EventsHandler paused={paused} wrapper={wrapper} challenge={challenge} />
      <StoriesHandler paused={paused} wrapper={wrapper} challenge={challenge} />

      <AmateurRadioHandler
        paused={paused}
        wrapper={wrapper}
        challenge={challenge}
      />

      <PlayerHpRecoverHandler
        wrapper={wrapper}
        challenge={challenge}
        wrapperNotifiers={wrapperNotifiers}
      />

      <PlayerNotifierDegreeHandler
        challenge={challenge}
        wrapper={wrapperNotifiers}
      />
    </>
  );
};

export default ChallengeCommonHandlersHandler;
