interface SvgProps {
  width?: string;
  height?: string;
}

const LaserPixelSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M512 210.82v30.12h-30.12v30.12h-30.12v-90.35h30.12v30.11zM391.53 180.71v90.35h-60.24v30.12h-30.11v30.11h-60.24v-30.11h-30.12v30.11h-30.11v60.24h30.11v60.23H90.35v-30.11H60.24V301.18H30.12v-30.12H0v-90.35h30.12v-30.12h30.12V60.24h60.23v30.11h30.12v30.12h60.23v30.12h30.12v-30.12h60.24v30.12h30.11v30.12zM481.88 331.29H512v30.12h-30.12v30.12h-30.12v-30.12h-30.11v-30.12h30.11v-30.11h30.12zM421.65 120.47h-30.12v30.12h-30.12v-30.12h-30.12V90.35h30.12V60.24h30.12v30.11h30.12z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <g fill="#c68ef4">
          <path
            d="m316.23 195.76 15.06 15.06v30.12L316.23 256l-15.05-15.06v-30.12zM451.76 331.29h30.12v30.12h-30.12zM135.53 346.35l15.06 15.06v30.12l-15.06 15.06h-30.12l-15.06-15.06v-30.12l15.06-15.06z"
            fill="#c68ef4"
            opacity="1"
            data-original="#c68ef4"
          ></path>
        </g>
        <g fill="#fc0">
          <path
            d="m256 165.65 15.06 15.06v90.35L256 286.12l-15.06-15.06v-90.35zM361.41 90.35h30.12v30.12h-30.12zM481.88 210.82v30.12h-30.12l-15.06-15.06 15.06-15.06zM391.53 210.82l15.06 15.06-15.06 15.06h-30.12v-30.12z"
            fill="#ffcc00"
            opacity="1"
            data-original="#ffcc00"
          ></path>
        </g>
        <path
          fill="#5f6c75"
          d="M90.35 90.35h30.12v30.12H90.35z"
          opacity="1"
          data-original="#5f6c75"
        ></path>
        <g fill="#dbe1e8">
          <path
            d="M210.82 180.71v90.35l-15.05 15.06h-30.12l-15.06-15.06v-30.12h30.12v-30.12h-30.12v-30.11l15.06-15.06h30.12zM75.3 165.65l15.05 15.06v30.11H60.24v30.12h30.11v30.12l-15.06 15.06-15.05-15.06-30.12-30.12v-60.23h30.12z"
            fill="#dbe1e8"
            opacity="1"
            data-original="#dbe1e8"
          ></path>
        </g>
        <g fill="#a1aab7">
          <path
            d="M30.12 240.94h30.12v30.12H30.12zM60.24 271.06h150.58v30.12H60.24z"
            fill="#a1aab7"
            opacity="1"
            data-original="#a1aab7"
          ></path>
        </g>
        <path
          fill="#f7f9fa"
          d="M60.24 150.59h150.58v30.12H60.24z"
          opacity="1"
          data-original="#f7f9fa"
        ></path>
        <path
          fill="#5f6c75"
          d="M391.53 210.82h60.23v30.12h-60.23z"
          opacity="1"
          data-original="#5f6c75"
        ></path>
        <path
          fill="#fff69e"
          d="M240.94 150.59h30.12v30.12h-30.12z"
          opacity="1"
          data-original="#fff69e"
        ></path>
        <g fill="#ff9e22">
          <path
            d="M331.29 331.29h30.12v30.12h-30.12zM240.94 271.06h30.12v30.12h-30.12zM481.88 120.47H512v30.12h-30.12z"
            fill="#ff9e22"
            opacity="1"
            data-original="#ff9e22"
          ></path>
        </g>
        <g fill="#5f5ace">
          <path
            d="M90.35 331.29h60.24v30.12H90.35zM90.35 391.53h90.36v30.12H90.35zM301.18 240.94h30.11v30.12h-30.11z"
            fill="#5f5ace"
            opacity="1"
            data-original="#5f5ace"
          ></path>
        </g>
        <path
          fill="#f9c1dc"
          d="M301.18 180.71h30.11v30.11h-30.11z"
          opacity="1"
          data-original="#f9c1dc"
        ></path>
        <path
          fill="#b3d141"
          d="M90.35 210.82h60.24v30.12H90.35z"
          opacity="1"
          data-original="#b3d141"
        ></path>
        <g fill="#5f5ace">
          <path
            d="M331.29 421.65h30.12v30.11h-30.12zM301.18 391.53h30.11v30.12h-30.11z"
            fill="#5f5ace"
            opacity="1"
            data-original="#5f5ace"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LaserPixelSvg;
