// entities
import { InvaderOneEntity } from "entities/spaceInvaders/InvaderEntity";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const SpaceInvaderOneSvg = ({
  width = "20",
  height = "20",
  color = InvaderOneEntity.color,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 544.68 544.68"
    >
      <g>
        <path
          d="M361.08 278.461a6.123 6.123 0 0 1 6.119-6.121H526.32a6.122 6.122 0 0 0 6.119-6.12V156.06a6.122 6.122 0 0 0-6.119-6.12h-42.84a6.123 6.123 0 0 1-6.121-6.12V94.86a6.122 6.122 0 0 0-6.119-6.12H428.4a6.123 6.123 0 0 1-6.121-6.12V39.78c0-3.378-2.74-6.12-6.119-6.12H128.52a6.123 6.123 0 0 0-6.12 6.12v42.84a6.123 6.123 0 0 1-6.12 6.12H73.44a6.123 6.123 0 0 0-6.12 6.12v48.96a6.123 6.123 0 0 1-6.12 6.12H18.36a6.123 6.123 0 0 0-6.12 6.12v110.16a6.123 6.123 0 0 0 6.12 6.12h159.12a6.123 6.123 0 0 1 6.12 6.121v42.84a6.122 6.122 0 0 0 6.12 6.119h165.239a6.123 6.123 0 0 0 6.121-6.119v-42.84zm-122.4-73.44a6.123 6.123 0 0 1-6.12 6.12h-97.92a6.123 6.123 0 0 1-6.12-6.12v-48.96a6.123 6.123 0 0 1 6.12-6.12h97.92a6.123 6.123 0 0 1 6.12 6.12v48.96zm73.439 6.119a6.122 6.122 0 0 1-6.119-6.12v-48.96a6.122 6.122 0 0 1 6.119-6.12h97.92a6.123 6.123 0 0 1 6.121 6.12v48.96a6.123 6.123 0 0 1-6.121 6.12h-97.92zM177.48 327.42h-48.96a6.123 6.123 0 0 0-6.12 6.121V382.5a6.123 6.123 0 0 0 6.12 6.121h48.96a6.123 6.123 0 0 0 6.12-6.121v-48.959a6.123 6.123 0 0 0-6.12-6.121zM116.28 388.621H67.32a6.121 6.121 0 0 0-6.12 6.119v48.961a6.122 6.122 0 0 0 6.12 6.119h48.96a6.122 6.122 0 0 0 6.12-6.119V394.74a6.121 6.121 0 0 0-6.12-6.119zM55.08 449.82H6.12A6.122 6.122 0 0 0 0 455.939V504.9a6.121 6.121 0 0 0 6.12 6.119h48.96c3.378 0 6.12-2.74 6.12-6.119v-48.961a6.122 6.122 0 0 0-6.12-6.119zM422.279 333.541a6.121 6.121 0 0 0-6.119-6.121h-48.961a6.123 6.123 0 0 0-6.119 6.121V382.5a6.123 6.123 0 0 0 6.119 6.121h48.961a6.121 6.121 0 0 0 6.119-6.121v-48.959zM483.48 394.74a6.121 6.121 0 0 0-6.121-6.119H428.4a6.121 6.121 0 0 0-6.121 6.119v48.961a6.123 6.123 0 0 0 6.121 6.119h48.959a6.123 6.123 0 0 0 6.121-6.119V394.74zM538.561 449.82H489.6a6.12 6.12 0 0 0-6.119 6.119V504.9a6.12 6.12 0 0 0 6.119 6.119h48.961a6.12 6.12 0 0 0 6.119-6.119v-48.961a6.122 6.122 0 0 0-6.119-6.119z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default SpaceInvaderOneSvg;
