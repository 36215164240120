// components
import ErgoPlaceholderIcon from "icons/placeholders/ErgoPlaceholderIcon";

interface AvatarIconProps {
  width?: string;
  color?: string;
  height?: string;
  gradient?: boolean;
}

const AvatarIconErgoPlaceholder = ({
  color,
  gradient,
  width = "28",
  height = "28",
}: AvatarIconProps) => {
  return (
    <ErgoPlaceholderIcon
      width={width}
      color={color}
      height={height}
      gradient={gradient}
    />
  );
};

export default AvatarIconErgoPlaceholder;
