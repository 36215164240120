// redux
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction } from "@reduxjs/toolkit";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesUpdateProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { ChallengesMergedProps } from "interfaces/challengesMerged";

const merge = createAction(
  "@async/challenges/merge",
  ({ hashes }: { hashes: ChallengesCommonHashProps[] }) => {
    return {
      payload: {
        hashes,
      },
    };
  }
);

const initialState: ChallengesMergedProps = {
  all: [],
  hash: {},
};

export const challengesSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ChallengeCommonProps[]>) => {
      state.all = action.payload;
    },
    setHash: (state, action: PayloadAction<ChallengesCommonHashProps>) => {
      state.hash = action.payload;
    },
    update: (state, action: PayloadAction<ChallengesUpdateProps[]>) => {
      state.all = updateAll(state.all, action.payload);
    },
    updateHash: (state, action: PayloadAction<ChallengesUpdateProps[]>) => {
      state.hash = updateAllHash(state.hash, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

function updateAll(
  challenges: ChallengeCommonProps[],
  challengesToUpdate: ChallengesUpdateProps[]
): ChallengeCommonProps[] {
  return challenges.map((challenge) => {
    const found = challengesToUpdate.find((c) => c.uuid === challenge.uuid);

    if (found) return { ...challenge, ...found };
    return challenge;
  });
}

function updateAllHash(
  challenges: ChallengesCommonHashProps,
  challengesToUpdate: ChallengesUpdateProps[]
): ChallengesCommonHashProps {
  return challengesToUpdate.reduce((acc, data) => {
    const { challengeSectionId } = data;

    if (!challengeSectionId) return acc;

    return {
      ...acc,
      [challengeSectionId]: updateOne(acc[challengeSectionId], data),
    };
  }, challenges);
}

function updateOne(
  challenges: ChallengeCommonProps[],
  challengeToUpdate: ChallengesUpdateProps
): ChallengeCommonProps[] {
  if (!challenges) return [];

  return challenges.map((challenge) => {
    if (challenge.uuid === challengeToUpdate.uuid)
      return { ...challenge, ...challengeToUpdate };
    return challenge;
  });
}

export const challengesActions = {
  async: {
    merge,
  },
  ...challengesSlice.actions,
};

export default challengesSlice.reducer;
