// enums
import {
  SpaceshipPartType,
  SpaceshipResourceType,
} from "enums/spaceInvaders/spaceshipEnum";

interface SpaceInvaderProps {
  bullet: {
    averageSize: number;
  };
  spaceship: {
    width: number;
    height: number;
  };
  spaceshipResources: {
    [key in SpaceshipResourceType]: {
      max: number;
    };
  };
  spaceshipRecoverHp: {
    [key in SpaceshipPartType]: {
      [key in SpaceshipResourceType]: number;
    };
  };
  strongForceBarrier: {
    top: number;
  };
  invaders: {
    length: number;
  };
  invader: {
    width: number;
    height: number;
  };
}

const SPACE_INVADERS: SpaceInvaderProps = {
  bullet: {
    averageSize: 6,
  },
  spaceship: {
    width: 42,
    height: 42,
  },
  spaceshipResources: {
    [SpaceshipResourceType.Gold]: {
      max: 5,
    },
    [SpaceshipResourceType.Silver]: {
      max: 5,
    },
    [SpaceshipResourceType.Sand]: {
      max: 5,
    },
    [SpaceshipResourceType.Bauxite]: {
      max: 5,
    },
    [SpaceshipResourceType.Copper]: {
      max: 5,
    },
    [SpaceshipResourceType.Water]: {
      max: 5,
    },
    [SpaceshipResourceType.StrongForce]: {
      max: 5,
    },
    [SpaceshipResourceType.Estelin]: {
      max: 5,
    },
  },
  // by one hp
  spaceshipRecoverHp: {
    [SpaceshipPartType.External]: {
      [SpaceshipResourceType.Gold]: 0,
      [SpaceshipResourceType.Silver]: 0,
      [SpaceshipResourceType.Bauxite]: 1,
      [SpaceshipResourceType.Sand]: 1,
      [SpaceshipResourceType.Copper]: 2,
      [SpaceshipResourceType.Water]: 1,
      [SpaceshipResourceType.StrongForce]: 1,
      [SpaceshipResourceType.Estelin]: 0,
    },
    [SpaceshipPartType.Wing]: {
      [SpaceshipResourceType.Gold]: 0,
      [SpaceshipResourceType.Silver]: 2,
      [SpaceshipResourceType.Bauxite]: 1,
      [SpaceshipResourceType.Sand]: 1,
      [SpaceshipResourceType.Copper]: 1,
      [SpaceshipResourceType.Water]: 2,
      [SpaceshipResourceType.StrongForce]: 2,
      [SpaceshipResourceType.Estelin]: 0,
    },
    [SpaceshipPartType.Engine]: {
      [SpaceshipResourceType.Gold]: 1,
      [SpaceshipResourceType.Silver]: 2,
      [SpaceshipResourceType.Bauxite]: 1,
      [SpaceshipResourceType.Sand]: 1,
      [SpaceshipResourceType.Copper]: 1,
      [SpaceshipResourceType.Water]: 2,
      [SpaceshipResourceType.StrongForce]: 2,
      [SpaceshipResourceType.Estelin]: 1,
    },
  },
  strongForceBarrier: {
    top: 92,
  },
  invaders: {
    length: 7,
  },
  invader: {
    width: 26,
    height: 26,
  },
};

export default SPACE_INVADERS;
