// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  height?: string;
  primaryColor?: string;
  secondaryColor?: string;
}

const AddPersonPixelIcon = ({
  width = "18",
  height = "18",
  primaryColor = c.yellow,
  secondaryColor = c.blue,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M478.609 345.043v-33.391h-33.392v-33.391H345.043v33.391h-33.391v33.391h-33.391v-66.782h33.391V244.87h33.391V100.174h-33.391V66.783h-33.391V33.391H244.87V0H100.174v33.391H66.783v33.392H33.391v33.391H0V244.87h33.391v33.391h33.392v66.782H33.391v33.392H0v66.782h311.652v33.392h33.391V512h100.174v-33.391h33.392v-33.392H512V345.043z"
          fill={primaryColor}
          data-original="#ffda44"
          opacity="1"
        ></path>
        <path
          d="M66.783 311.652v33.391H33.391v33.392H0v66.782h66.783v-66.782h33.391v-66.783zM0 211.478v33.392h33.391v33.391h33.392v-66.783H33.391zM311.652 100.174V66.783h-33.391V33.391H244.87V0H100.174v33.391H66.783v33.392H33.391v33.391H0v77.913h66.783v-77.913h33.391V66.783H244.87v33.391h33.391v33.391h66.782v-33.391z"
          fill="#ffffff"
          data-original="#ffffff"
        ></path>
        <path
          d="M512 345.043h-33.391v-33.391h-33.392v-33.391H345.043v33.391h-33.391v33.391h-33.391v100.174h33.391v33.392h33.391V512h100.174v-33.391h33.392v-33.392H512z"
          fill={secondaryColor}
          data-original="#f490c0"
          opacity="1"
        ></path>
        <path
          d="M0 100.174h33.391V244.87H0zM311.652 100.174h33.391V244.87h-33.391zM100.174 0H244.87v33.391H100.174zM33.391 66.783h33.391v33.391H33.391zM66.783 33.391h33.391v33.391H66.783zM244.87 33.391h33.391v33.391H244.87zM278.261 66.783h33.391v33.391h-33.391zM33.391 244.87h33.391v33.391H33.391zM278.261 244.87h33.391v33.391h-33.391zM244.87 311.652h-33.392v33.391h66.783v-66.782H244.87zM33.391 345.043h33.391v33.391H33.391zM133.565 345.043v-33.391h-33.391v-33.391H66.783v66.782zM411.826 345.043h-33.391v33.392h-33.392v33.391h33.392v33.391h33.391v-33.391h33.391v-33.391h-33.391zM478.609 345.043H512v100.174h-33.391zM345.043 478.609h100.174V512H345.043zM345.043 278.261h100.174v33.391H345.043z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M311.652 345.043h-33.391v66.783H33.391v-33.391H0v66.782h311.652zM311.652 311.652h33.391v33.391h-33.391zM445.217 311.652h33.391v33.391h-33.391zM445.217 445.217h33.391v33.391h-33.391zM311.652 445.217h33.391v33.391h-33.391z"
          fill="#393939"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default AddPersonPixelIcon;
