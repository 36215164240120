// interfaces
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// components
import LevelUpHandler from "handlers/levelUp/LevelUpHandler";
import SudoHandler from "handlers/challengeButtons/sudo/SudoHandler";
import ChallengeBattle from "components/challenge/battle/ChallengeBattle";
import ChallengeCommonHandlersHandler from "handlers/ChallengeCommonHandlersHandler";

// enums
import { FlyingObjectSubType } from "enums/spaceInvaders/flyingObjectEnum";

// utils
import numberUtils from "utils/numberUtils";

interface ChallengeExerciseProps extends ChallengeTypeHandlerCommonProps {
  onFailTag(length: number, subtype: FlyingObjectSubType): void;
  alertLostScore(bounds: { left: string; top: string }, length: number): void;
}

const ChallengeExercise = ({
  code,
  paused,
  onFailTag,
  challenge,
  wrapperRef,
  blinkElRef,
  isTagValid,
  onSuccessTag,
  alertLostScore,
  reOpenChallenge,
  handlerWrapperRef,
  wrapperNotifiersRef,
  handlerNotifiersRef,
}: ChallengeExerciseProps) => {
  function failTag(bounds: { top: string; left: string }) {
    const length = numberUtils.randomInterval(2, 6);

    alertLostScore(bounds, length);
    onFailTag(length, challenge.avatarSubtype);
  }

  return (
    <>
      <ChallengeBattle
        code={code}
        paused={paused}
        onFailedTag={failTag}
        challenge={challenge}
        wrapperRef={wrapperRef}
        isTagValid={isTagValid}
        blinkElRef={blinkElRef}
        onSuccessTag={onSuccessTag}
        reOpenChallenge={reOpenChallenge}
        handlerWrapperRef={handlerWrapperRef}
        handlerNotifiersRef={handlerNotifiersRef}
        wrapperNotifiersRef={wrapperNotifiersRef}
      />

      <SudoHandler
        paused={paused}
        challenge={challenge}
        wrapper={wrapperRef.current}
      />

      <ChallengeCommonHandlersHandler
        paused={paused}
        challenge={challenge}
        wrapper={wrapperRef.current}
        wrapperNotifiers={wrapperNotifiersRef.current}
      />

      <LevelUpHandler
        paused={paused}
        challenge={challenge}
        wrapper={wrapperRef.current}
      />
    </>
  );
};

export default ChallengeExercise;
