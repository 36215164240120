// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";
import styled from "styled-components";

const CloseButtonWrapper = styled(ButtonDuolingo)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 34px;
  height: 34px;
  background: ${color.grey.dark};
  box-shadow: 0 4px 0 ${color.red};

  > .outside {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 0.8;
    background: ${color.red};

    .inside {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${color.grey.dark};
      opacity: 0.7;
    }
  }
`;

interface CloseButtonProps {
  onClick(): void;
  disabled?: boolean;
  className?: string;
}

const CloseButton = ({ onClick, disabled, className }: CloseButtonProps) => {
  return (
    <CloseButtonWrapper
      onClick={onClick}
      disabled={disabled}
      className={`no-swipe ${className}`}
    >
      <div className="outside">
        <div className="inside"></div>
      </div>
    </CloseButtonWrapper>
  );
};

export default CloseButton;
