// react
import { useState, useEffect } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// entities
import AstrogildoEntity from "entities/characters/AstrogildoEntity";

// components
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div<{ pixel: number; spaceshipColor: string }>`
  .pixels_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 800;

    .pixel_spaceship {
      position: absolute;
      top: ${({ pixel }) => pixel}px;
      left: 150px;
      background: ${({ spaceshipColor }) => spaceshipColor};
      width: 1px;
      height: 1px;

      .pointer {
        all: unset;
        display: flex;
        position: absolute;
        top: -60px;
        left: 3px;
        opacity: 0.8;

        .pointer_btn {
          display: flex;
          padding: 5px;

          .rotate_180 {
            transform: rotate(180deg);
          }

          p {
            all: unset;
            font-size: 18px;
            margin-left: 5px;
            color: ${color.yellow};
          }
        }
      }
    }

    .pixel_astrogildo {
      position: absolute;
      top: 480px;
      left: 150px;
      background: ${color.white};
      width: 1px;
      height: 1px;

      .pointer {
        all: unset;
        display: flex;
        position: absolute;
        top: -80px;
        right: 10px;
        opacity: 0.8;

        .pointer_btn {
          display: flex;
          padding: 5px;

          div {
            display: flex;
            flex-direction: column;
          }

          p {
            all: unset;
            font-size: 18px;
            color: ${color.green};
          }
        }
      }
    }
  }

  .bg {
    position: fixed;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .container {
    position: relative;
    text-align: center;

    .planets div {
      display: inline-block;
      position: absolute;
    }

    .two {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${color.blue};
      top: -5px;
      left: 70px;
    }

    .seven {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${color.brown};
      top: -8px;
      left: -8px;
    }

    .three {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: ${color.brown};
      top: 2px;
      right: -12px;
    }

    .four {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #b58579;
      top: -18px;
      left: -18px;
    }

    .five {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: ${color.green};
      top: 5px;
      left: 100px;
    }

    .six {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #7dfffd;
      top: -70px;
      left: -50px;
    }

    .sun {
      width: 20px;
      height: 20px;
      border-radius: 80px;
      top: 50px;
      left: 120px;
      display: inline-block;
      position: absolute;
      box-shadow: 0 0 120px #f1da36, 0 0 60px #f2ad00, 0 0 10px #c96800,
        0 0 200px #feff8f;
      background: radial-gradient(
        center,
        ellipse cover,
        #fcf3a1 0%,
        #f1da36 100%
      );
      box-shadow: 0 0 120px #f1da36, 0 0 60px #f2ad00, 0 0 10px #c96800,
        0 0 200px #feff8f;
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        #fcf3a1 0%,
        #f1da36 100%
      );
      background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop(0%, #fcf3a1),
        color-stop(100%, #f1da36)
      );
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        #fcf3a1 0%,
        #f1da36 100%
      );
      background: -o-radial-gradient(
        center,
        ellipse cover,
        #fcf3a1 0%,
        #f1da36 100%
      );
      background: -ms-radial-gradient(
        center,
        ellipse cover,
        #fcf3a1 0%,
        #f1da36 100%
      );
    }

    .sun {
      animation: spin 5s infinite linear;
    }

    .slow {
      animation: spin 10s infinite linear;
    }

    .fast {
      animation: spin 10s infinite linear;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const SPACESHIP_PIXEL_TOP = 166;
const ASTROGILDO_PIXEL_TOP = 480;

interface PlayerProfileMapProps {
  youAreHereClick: (info: string) => void;
  astrogildoClick: (info: string) => void;
}

const PlayerProfileMap = ({
  youAreHereClick,
  astrogildoClick,
}: PlayerProfileMapProps) => {
  const { px } = useSelector(s.player());
  const { color: spaceshipColor } = useSelector(s.spaceshipFeatures());
  const [pixel, setPixel] = useState(SPACESHIP_PIXEL_TOP);

  useEffect(handlePixels, [px]);

  function handlePixels() {
    if (!px) return;
    setPixel(SPACESHIP_PIXEL_TOP + px);
  }

  function _youAreHereClick() {
    youAreHereClick(
      `Você está distante ${
        ASTROGILDO_PIXEL_TOP - SPACESHIP_PIXEL_TOP - px
      } pixels do LOCAL onde foi enviada a ÚLTIMA MENSAGEM de Astrogildo.`
    );
  }

  function _astrogildoClick() {
    astrogildoClick(
      `Local onde foi enviada a última mensagem recebida de Astrogildo: '${AstrogildoEntity.lastMessage}'. Será que ele está bem?`
    );
  }

  return (
    <>
      <Wrapper pixel={pixel} spaceshipColor={spaceshipColor}>
        <div className="pixels_mask">
          <div className="pixel_spaceship">
            <div className="pointer">
              <ButtonRippleEffect
                color={color.yellow}
                className="pointer_btn blink"
                onClick={_youAreHereClick}
              >
                <p>Você está aqui</p>
              </ButtonRippleEffect>
            </div>
          </div>

          <div className="pixel_astrogildo">
            <div className="pointer">
              <ButtonRippleEffect
                color={color.green}
                className="pointer_btn blink"
                onClick={_astrogildoClick}
              >
                <p>Última mensagem de Astrogildo</p>
              </ButtonRippleEffect>
            </div>
          </div>
        </div>

        <div className="bg"></div>
        <div className="container">
          <div className="planets sun">
            <div className="one ring"></div>

            <div className="two fast">
              <div className="seven"></div>
            </div>

            <div className="three slow">
              <div className="four"></div>
            </div>

            <div className="three slow">
              <div className="five"></div>
            </div>

            <div className="three fast">
              <div className="six"></div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default PlayerProfileMap;
